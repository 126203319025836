import React from "react";
import { Route, Switch } from "react-router-dom";
import Upload from "./Upload";

function StockCount({ match }) {
  const { path } = match;
  return (
    <Switch>
      <Route exact path={path} component={Upload} />
    </Switch>
  );
}

export { StockCount };
