import { Button, Col, Form, Row, message, Select } from 'antd';
import React, { useState } from 'react';
import { reportService } from '_services';

function StrRmBinWiseBalanceSearch(props) {
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
    if (values.materialType) {
      searchParams += `&stockTypeId=${values.materialType}`;
    }
    if (values.lotNumber) {
      searchParams += `&lotNumberId=${values.lotNumber}`;
    }
    if (values.bin) {
      searchParams += `&stockBinId=${values.bin}`;
    }
    if (values.materialSupplier) {
      searchParams += `&supplierId=${values.materialSupplier}`;
    }
    if (values.subLocationId) {
      searchParams += `&subLocationId=${values.subLocationId}`;
    }
    if (values.zoneId) {
      searchParams += `&zoneId=${values.zoneId}`;
    }
    if (values.sbuId) {
      searchParams += `&sbuId=${values.sbuId}`;
    }

    reportService
      .getBinWiseBalance(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='sbuId' label='SBU'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {props.businessUnits.length > 0 &&
                props.businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='zoneId' label='Zone'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {props.zones.length > 0 &&
                props.zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='subLocationId' label='Sub-Location'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              disabled={!selectedZoneId}
            >
              {props.subLocations.length > 0 &&
                props.subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='bin' label='Stock Bin'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Material Bin'
            >
              {props.stockBins.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='materialType' label='Material Type'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Material Type'
            >
              {props.stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='lotNumber' label='Lot No'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Lot No'
            >
              {props.lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='materialSupplier' label='Material Supplier'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Material Supplier'
            >
              {props.suppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  ({t.code}) {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}></Col>
      </Row>
    </Form>
  );
}

export default StrRmBinWiseBalanceSearch;
