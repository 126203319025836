import { CloseOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  Tag,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import {
  binService,
  companyService,
  materialDetailService,
} from "_services";

const PrymBinTransfer = ({ history, match, location }) => {
  const { store, storeLocation, stockItems } = location;
  if (!store) {
    history.push(".");
  }
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(stockItems);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const warehouseLocations = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setStoreAddresses(warehouseLocations);
          if (warehouseLocations.length === 1) {
            form.setFieldsValue({ storeLocation: warehouseLocations[0] });
          }
        });
        form.setFieldsValue({
          store: x.content[0],
          //storeLocation: y.content[0],
        });
      }
      setStores(x.content);
    });
    binService
      .search(`?isParent=1&storeLocationId=${storeLocation.id}`)
      .then((y) => {
        setStoreBins(y.content);
      });
  }, []);

  const handleRemove = (item) => {
    const stockItems = [...items];
    if (stockItems.includes(item)) {
      stockItems.splice(stockItems.indexOf(item), 1);
      setItems(stockItems);
    }
  };

  const onCancel = () => {
    history.push(".");
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        let stockItems = items.filter(
          (item) =>
            store.id === item.owner.id &&
            storeLocation.id === item.stockBin.storeLocation.id
        );
        if (stockItems.length > 0 && stockItems.length === items.length) {
          values.stockItems = stockItems.map((item) => ({ id: item.id }));
          //console.log("Values: ", JSON.stringify(values));
          setLoading(true);
          materialDetailService
            .transfer(values)
            .then(() => {
              setLoading(false);
              message.success("Bin transfer success!!");
              history.push(".");
            })
            .catch((error) => {
              setLoading(false);
              message.error(`${error}`);
            });
        } else {
          message.error(
            "Sorry, Bin transfer can be possible within same store location only!"
          );
        }
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const columns = [
    {
      title: "Pack Serial",
      dataIndex: "packSerialNo",
      key: "packSerialNo",
      width: "16%",
    },
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    },
    {
      title: "Description",
      key: "stockTypeName",
      //width: "30%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    },
    {
      title: "Type of Wash",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber.number;
      },
    },
    {
      title: "Owner",
      key: "owner",
      render: (text, record) => {
        return record.owner.code;
      },
    },
    {
      title: "Location",
      key: "storeLocation",
      render: (text, record) => {
        return record.stockBin.storeLocation.code;
      },
    },
    {
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    },
    {
      title: "Last Modified",
      dataIndex: "lastModifiedAt",
      key: "lastModifiedAt",
    },
    {
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (status) => {
        return (
          <Tag
            color={`${status == "AVAILABLE"
                ? "green"
                : status == "REQUESTED"
                  ? "geekblue"
                  : "orange"
              }`}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    },
    {
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to remove?"
            onConfirm={() => handleRemove(record)}
          >
            <Button size="small" icon={<CloseOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Bin Transfer</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            key="1"
            title="Are you sure to update the changes?"
            onConfirm={handleFormSumbit}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Trasfer
            </Button>
          </Popconfirm>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name={["store", "id"]} label="Store">
                  <Select placeholder="Please select" disabled>
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["storeLocation", "id"]}
                  label="Store Location"
                >
                  <Select placeholder="Please select" disabled>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["stockBin", "id"]}
                  label="Store Bin"
                  rules={[{ required: true, message: "Store Bin is required" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Please select"
                  >
                    {storeBins.length > 0 &&
                      storeBins.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.code}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Table
              rowKey="id"
              pagination={false}
              columns={columns}
              dataSource={items}
              size="small"
            />
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
};

export default PrymBinTransfer;
