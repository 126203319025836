import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import {
  extraService,
  materialTypeService,
  packingTypeService,
  stockClassificationService,
  supplierService,
} from "_services";

function Add({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [packingTypes, setPackingTypes] = useState([]);
  const [stockClasses, setStockClasses] = useState([]);
  const [materialSuppliers, setMaterialSuppliers] = useState([]);
  const [qualityTypes, setQualityTypes] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    packingTypeService.getSuggestion().then((x) => {
      setPackingTypes(x.content);
    });
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
    getMaterialSuppliers();
    getStockQualityTypes();
  }, []);

  const getMaterialSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setMaterialSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getStockQualityTypes = () => {
    extraService
      .getQualityTypes()
      .then((x) => {
        setQualityTypes(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        materialTypeService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
            //message.error(`${error}`);
          });
      })
      .catch((errorInfo) => { });
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-types">Material Type Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-types/add">New Material Type</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[{ required: true, message: "Code is required" }]}
                >
                  <Input placeholder="Code" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["stockClass", "id"]}
                  label="Stock Classification"
                  rules={[
                    {
                      required: true,
                      message: "Stock classification is required",
                    },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {stockClasses.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={["supplier", "id"]} label="Material Supplier" rules={[
                  {
                    required: true,
                    message: "Material Supplier is required",
                  },
                ]}>
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Material Supplier"
                  >
                    {materialSuppliers.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.name} ({t.code})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["packingType", "id"]}
                  label="Packing Type"
                  rules={[
                    { required: true, message: "Packing Type is required" },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {packingTypes.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={["qualityType", "id"]} label="Quality Type" rules={[
                  {
                    required: false,
                    message: "Quality Type is required",
                  },
                ]}>
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Quality Type"
                  >
                    {qualityTypes.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.name} ({t.code})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
