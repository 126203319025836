import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  stockIssueService,
  vehicleService,
} from "_services";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TabMenu from "./TabMenu";
import { Auth } from "_helpers";

function StrRmSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [loadingSummaryPdf, setLoadingSummaryPdf] = useState(false);
  const [loadingDetailedPdf, setLoadingDetailedPdf] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDetailsUpdate, setLoadingDetailsUpdate] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [stockIssue, setStockIssue] = useState({});
  const [stockIssueLines, setStockIssueLines] = useState([]);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [issuedStocks, setIssuedStocks] = useState([]);
  const [receivedStocks, setReceivedStocks] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);
  const [error, setError] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setLoading(true);
    stockIssueService
      .getById(id)
      .then((x) => {
        let stockIssue = x.content;
        /* if (["LOADING"].includes(stockIssue.orderStage)) {
          history.push(`/stock-issue/${id}/loading`);
        } */
        if (["READY"].includes(stockIssue.orderStage)) {
          history.push(`/stock-issue/${id}/ready`);
        }
        stockIssue.vehicle = stockIssue.vehicle && stockIssue.vehicle.number;
        stockIssue.issuedDate = moment(stockIssue.issuedDate);
        stockIssue.deliveredDate = stockIssue.deliveredDate
          ? moment(stockIssue.deliveredDate)
          : moment();
        stockIssue.issuedLines.forEach((line, i) => {
          line.key = line.id;
          line.readyQuantity = line.requestLine.requestedItems.length;
          line.readyNetWeight = line.requestLine.requestedItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
          if (line.requestLine.measurementType.name === "BOX") {
            line.requestQuantity = line.requestLine.totalItems;
          } else if (line.requestLine.measurementType.name === "KG") {
            line.requestQuantity = line.requestLine.netWeight;
          } else if (line.requestLine.measurementType.name === "CONE") {
            line.requestQuantity = line.requestLine.qty;
          }

          for (const item of line.issuedItems) {
            if (["READY", "ISSUED", "DELIVERED"].includes(item.mode)) {
              line.issuedQuantity = line.issuedQuantity
                ? line.issuedQuantity + 1
                : 1;
              line.issuedNetWeight = line.issuedNetWeight
                ? line.issuedNetWeight + item.netWeight
                : item.netWeight;
              line.issuedGrossWeight = line.issuedGrossWeight
                ? line.issuedGrossWeight + item.grossWeight
                : item.grossWeight;
            } else if (item.mode === "DELIVERED" || item.mode === "COMPLETE") {
              line.issuedQuantity = line.issuedQuantity
                ? line.issuedQuantity + 1
                : 1;
              line.quantity = line.issuedQuantity;
              line.issuedNetWeight = line.issuedNetWeight
                ? line.issuedNetWeight + item.netWeight
                : item.netWeight;
              line.issuedGrossWeight = line.issuedGrossWeight
                ? line.issuedGrossWeight + item.grossWeight
                : item.grossWeight;
              line.receivedQuantity = line.receivedQuantity + 1;
              line.receivedGrossWeight = line.issuedGrossWeight;
              line.receivedNetWeight = line.issuedNetWeight;
            }
          }
          setIssuedStocks([...issuedStocks, ...line.issuedItems]);

          if (!stockIssueLines.map((line) => line.key).includes(line.key)) {
            stockIssueLines.push(line);
          }
        });
        stockIssue.sbuName = stockIssue.subLocation?.zone?.sbu?.name;
        stockIssue.zoneName = stockIssue.subLocation?.zone?.name;
        stockIssue.subLocationName = stockIssue.subLocation?.name;

        //console.log("Issue Lines", stockIssue);
        setStockIssue(stockIssue);
        setStockIssueLines([].concat(stockIssueLines));
        setSelectedCustomer(stockIssue.customer);
        onFill(stockIssue);

        if (stockIssueLines.length > 0) {
          handleTabChange(stockIssueLines[0].key);
        }

        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingUpdate(true);
        delete values.issueNumber;
        delete values.issuedDate;
        delete values.store;
        delete values.vehicleNumber;
        delete values.stockItem;

        values.issuedLines = stockIssueLines.map((line) => ({
          issuedItems: line.issuedItems
            .filter((item) => item.added)
            .map((item) => ({
              id: item.id,
              mode: "DELIVERED",
            })),
        }));
        values.deliveredDate = moment(values.deliveredDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log("Update: ", values);
        stockIssueService
          .deliveryUpdate(id, values)
          .then(() => {
            setLoadingUpdate(false);
            message.success("Delivery success!");
            history.push("..");
          })
          .catch((error) => {
            setLoadingUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((errorInfo) => {});
  };

  const onSelectChange = (rowKeys) => {
    setSelectedRowKeyMap(selectedRowKeyMap.set(activeTabKey, rowKeys));
    updateStockItemStatus();
  };

  const updateStockItemStatus = () => {
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    setSelectedRowKeys(allSelectedKeys);
    const totalIssuedStocks = stockIssueLines.flatMap(
      (item) => item.issuedItems
    );
    let unLoadedStocks = [];
    for (let item of totalIssuedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.added = true;
        item.mode = "UNLOADED";
        unLoadedStocks.push(item);
      } else {
        item.added = false;
        item.mode = "ISSUED";
      }
    }
    setReceivedStocks(unLoadedStocks);

    stockIssueLines.forEach((line) => {
      line.receivedQuantity = 0;
      line.receivedNetWeight = 0;
      line.receivedGrossWeight = 0;
      line.addedAll = false;
      unLoadedStocks.forEach((stock) => {
        if (
          line.requestLine.stockType.id === stock.stockType.id &&
          line.requestLine.lotNumber &&
          stock.lotNumber &&
          line.requestLine.lotNumber.id === stock.lotNumber.id
        ) {
          line.receivedQuantity += 1;
          line.receivedNetWeight += stock.netWeight;
          line.receivedGrossWeight += stock.grossWeight;
        }
      });
      // console.log(
      //   "Line Qty:",
      //   line.quantity,
      //   ", Got Qty:",
      //   line.receivedQuantity
      // );
      if (line.quantity === line.receivedQuantity) {
        line.addedAll = true;
      }
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.mode === "DELIVERED",
    }),
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    stockIssueService
      .printExcel(id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Issue_${stockIssue.issueNumber}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const handleUpdateDetails = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingDetailsUpdate(true);
        const payLoad = {
          vehicle: values.vehicle,
        };
        stockIssueService
          .updateDetails(id, payLoad)
          .then(() => {
            message.success("Successfully updated!");
            setLoadingDetailsUpdate(false);
            history.push(`${path}`.replace(":id", id));
          })
          .catch((error) => {
            setLoadingDetailsUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleStatusUpdate = () => {
    setLoadingStatusUpdate(true);
    let newStatus = stockIssue.orderStage;
    if (stockIssue.orderStage === "OPEN") {
      newStatus = "LOADING";
    } else if (stockIssue.orderStage === "LOADING") {
      newStatus = "ISSUED";
    } else if (stockIssue.orderStage === "ISSUED") {
      newStatus = "CONFIRMATION";
    } else if (stockIssue.orderStage === "CONFIRMATION") {
      newStatus = "COMPLETE";
    }
    const statusUpdatePayload = {
      requestStatus: newStatus,
    };
    stockIssueService
      .updateStatus(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingStatusUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const handlePrintPdf = (summaryFlag) => {
    if (summaryFlag) {
      setLoadingSummaryPdf(true);
    } else {
      setLoadingDetailedPdf(true);
    }

    stockIssueService.printPdf(id, summaryFlag).then((response) => {
      /* const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Delivery-${summaryFlag ? "Summary_" : "Detailed_"}${
          stockIssue.issueNumber
        }.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); */
      if (summaryFlag) {
        setLoadingSummaryPdf(false);
      } else {
        setLoadingDetailedPdf(false);
      }
    });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleAllUnloadChange = (e) => {
    if (e.target.checked) {
      stockIssueLines.forEach((line, index) => {
        setSelectedRowKeyMap(
          selectedRowKeyMap.set(
            line.key,
            line.issuedItems.map((item) => item.id)
          )
        );
      });

      updateStockItemStatus();
    } else {
      setSelectedRowKeyMap(new Map());
      const totalIssuedStocks = stockIssueLines.flatMap(
        (item) => item.issuedItems
      );
      for (let item of totalIssuedStocks) {
        item.added = false;
        item.mode = "ISSUED";
      }
      setReceivedStocks([]);
      stockIssueLines.forEach((line) => {
        line.receivedQuantity = 0;
        line.receivedNetWeight = 0;
        line.receivedGrossWeight = 0;
        line.addedAll = false;
      });
    }
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      handleTabChange(selectedIssueLineKeys[0]);
    },
  };

  const getTabContent = (stockIssueLines) => {
    let tabPanes = [];
    stockIssueLines.forEach((line) => {
      if (line.issuedItems && line.issuedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.requestLine.stockType.code} / ${
              line.requestLine.lotNumber
                ? line.requestLine.lotNumber.number
                : ""
            } (${line.issuedItems.length})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => (record.added ? "ant-tag-green" : "")}
              //rowSelection={rowSelection}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 5}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{line.issuedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (
      Auth.hasRole(Auth.getProfile(), ["CAU"]) &&
      ["ISSUED", "CONFIRMATION", "COMPLETE"].includes(stockIssue.orderStage)
    ) {
      buttons.push(
        <Tooltip
          key="tp-summary-pdf"
          placement="topLeft"
          title="Generate Delivery PDFs"
        >
          <Button
            key="summary-pdf"
            icon={<FilePdfOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handlePrintPdf(true);
            }}
            loading={loadingSummaryPdf}
          ></Button>
        </Tooltip>
      );
      /* buttons.push(
        <Tooltip
          key="tp-detailed-excel"
          placement="topLeft"
          title="Detaild Excel"
        >
          <Button
            key="excel"
            icon={<FileExcelOutlined />}
            onClick={handleExportExcel}
            loading={loadingExcel}
          ></Button>
        </Tooltip>
      ); */
    }
    if (
      Auth.hasRole(Auth.getProfile(), ["CAU"]) &&
      ["OPEN", "LOADING", "READY"].includes(stockIssue.orderStage)
    ) {
      /* buttons.push(
        <Popconfirm
          title="Are you sure to update?"
          onConfirm={handleUpdateDetails}
          key="con-update"
        >
          <Button
            key="btn-complete"
            htmlType="button"
            loading={loadingDetailsUpdate}
          >
            Update
          </Button>
        </Popconfirm>
      ); */
    }
    /* buttons.push(
      <Popconfirm
        title="Are you sure to confirm this delivery?"
        onConfirm={handleFormSumbit}
        key="update-confirm"
      >
        {stockIssue.orderStatus !== "ISSUED" && (
          <Button
            key="update"
            htmlType="submit"
            type="primary"
            loading={loadingUpdate}
            disabled={
              stockIssueLines.flatMap((item) => item.issuedItems).length !=
              receivedStocks.length
            }
          >
            Delivery Confirm
          </Button>
        )}
      </Popconfirm>
    ); */
    if (
      Auth.hasRole(Auth.getProfile(), ["CAU"]) &&
      stockIssue.orderStage !== "COMPLETE"
    ) {
      let statusButtonText = "";
      if (stockIssue.orderStage === "OPEN") {
        statusButtonText = "Start Loading";
      } else if (stockIssue.orderStage === "LOADING") {
        statusButtonText = "Complete";
      } else if (stockIssue.orderStage === "ISSUED") {
        statusButtonText = "Get Confirmation";
      } else if (stockIssue.orderStage === "CONFIRMATION") {
        statusButtonText = "Confirmed";
      }
      buttons.push(
        <Popconfirm
          title={`Are you sure to ${statusButtonText} it?`}
          onConfirm={handleStatusUpdate}
          key="con-complete"
        >
          <Button
            key="btn-complete"
            htmlType="button"
            loading={loadingStatusUpdate}
          >
            {statusButtonText}
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getLineColumns = () => {
    const lineColumns = [];
    lineColumns.push({
      title: "Request No.",
      key: "requestNumber",
      render: (text, record) => {
        return record.requestLine.issueRequest.requestNumber;
      },
    });
    lineColumns.push({
      title: "MRN No.",
      key: "mrnNumber",
      render: (text, record) => {
        return record.requestLine.mrnNumber;
      },
    });
    lineColumns.push({
      title: "Req. Time",
      key: "requestTime",
      render: (text, record) => {
        return record.requestLine.requestTime;
      },
    });
    lineColumns.push({
      title: "Material",
      key: "stockTypeCode",
      render: (text, record) => {
        return record.requestLine.stockType.code;
      },
    });
    lineColumns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record, index) => {
        return (
          record.requestLine.lotNumber && record.requestLine.lotNumber.number
        );
      },
    });
    lineColumns.push({
      title: "Status",
      key: "lineStage",
      dataIndex: "lineStage",
      render: (text) => {
        let color = "";
        if (text === "OPEN") {
          color = "#87d068";
        }
        if (text === "LOADING") {
          color = "#2db7f5";
        }
        if (text === "READY") {
          color = "#108ee9";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "CONFIRMATION") {
          color = "#ffa940";
        }
        if (text === "COMPLETE") {
          color = "#ffd666";
        }
        if (text === "CANCEL") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    lineColumns.push({
      title: "Req. Meas.",
      key: "measurementType",
      align: "right",
      render: (text, record) => {
        return (
          record.requestLine.measurementType &&
          record.requestLine.measurementType.name
        );
      },
    });
    lineColumns.push({
      title: "Req. Qty",
      dataIndex: "requestQuantity",
      key: "requestQuantity",
      align: "right",
      render: (text, record) => {
        if (["BOX", "CONE"].includes(record.requestLine.measurementType.name)) {
          return text;
        } else {
          return !isNaN(text) && Number(text).toFixed(2);
        }
      },
    });
    lineColumns.push({
      title: "Ready Box",
      dataIndex: "readyQuantity",
      key: "readyQuantity",
      align: "right",
    });
    lineColumns.push({
      title: "Ready Net.W",
      dataIndex: "readyNetWeight",
      key: "readyNetWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    lineColumns.push({
      title: "Issued Box",
      dataIndex: "issuedQuantity",
      key: "issuedQuantity",
      align: "right",
      render: (text, record) => {
        return record.issuedQuantity;
      },
    });
    lineColumns.push({
      title: "Issued Net.W",
      dataIndex: "issuedNetWeight",
      key: "issueNetWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });

    return lineColumns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "LOT",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Prod Date",
      dataIndex: "productionDate",
      key: "productionDate",
      width: "8%",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "B/N",
      dataIndex: "tempNumber",
      key: "tempNumber",
      align: "right",
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Net Weight(Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "ASSIGNED") {
          color = "#2db7f5";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "UNLOADED") {
          color = "#87d068";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag
                  color={`${
                    ["ISSUED", "COMPLETE"].includes(stockIssue.orderStage)
                      ? "#2db7f5"
                      : stockIssue.orderStage === "CONFIRMATION"
                      ? "#ffa940"
                      : "#87d068"
                  }`}
                >
                  {stockIssue.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} status={stockIssue.orderStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="issueNumber"
                    label="Issue No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issuedDate"
                    label="Issue Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="vehicle" label="Vehicle">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sealNumber" label="Seal No:">
                    <Input placeholder="Seal No:" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact Number">
                    <Input placeholder="Contact Number" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverName" label="Driver Name">
                    <Input placeholder="Driver Name" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverNic" label="Driver NIC">
                    <Input placeholder="Driver NIC" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>

              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={stockIssueLines}
                rowSelection={{
                  type: "radio",
                  ...issueLineRowSelection,
                }}
                size="small"
                rowClassName={(record) =>
                  record.addedAll ? "ant-tag-green" : ""
                }
                summary={(pageData) => {
                  let totalReadyQuantity = 0;
                  let totalReadyNetWeight = 0;
                  let totalIssuedQuantity = 0;
                  let totalIssuedNetWeight = 0;

                  pageData.forEach(
                    ({
                      readyQuantity,
                      readyNetWeight,
                      issuedQuantity,
                      issuedNetWeight,
                    }) => {
                      totalReadyQuantity += readyQuantity;
                      totalReadyNetWeight += readyNetWeight;
                      totalIssuedQuantity += issuedQuantity;
                      totalIssuedNetWeight += issuedNetWeight;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={9}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalReadyQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalReadyNetWeight) &&
                              Number(totalReadyNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalIssuedQuantity) && totalIssuedQuantity}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalIssuedNetWeight) &&
                              Number(totalIssuedNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
              {stockIssueLines.length > 0 && (
                <>
                  <Divider orientation="left">Stock Items</Divider>
                  <Tabs onChange={handleTabChange} activeKey={activeTabKey}>
                    {getTabContent(stockIssueLines)}
                  </Tabs>
                </>
              )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmSummary;
