import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth, CompanyTypes } from "_helpers";
import { companyService } from "_services";
import TabMenu from "./TabMenu";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setIsLoading(true);
    companyService
      .getById(id)
      .then((x) => {
        setIsLoading(false);
        setCompany(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        companyService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setIsLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/companies");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
        >
          <Button key="1" type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies">Company Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                Company: {company.code}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu="summary" id={id} />
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[{ required: true, message: "Code is required" }]}
                >
                  <Input
                    placeholder="Code"
                    disabled={!Auth.hasRole(Auth.getProfile(), ["SAU"])}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input
                    placeholder="Name"
                    disabled={!Auth.hasRole(Auth.getProfile(), ["SAU"])}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="companyType"
                  label="Type"
                  rules={[
                    { required: true, message: "Company type is required" },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    disabled={!Auth.hasRole(Auth.getProfile(), ["SAU"])}
                  >
                    {CompanyTypes.map((t) => (
                      <Option value={t.value} key={t.value}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="emails"
                  label="Emails"
                  rules={[{ required: true, message: "Emails are required" }]}
                >
                  <Input
                    placeholder="Emails"
                    disabled={!Auth.hasRole(Auth.getProfile(), ["SAU"])}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
