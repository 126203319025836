import decode from "jwt-decode";

export const Auth = {
  setSession,
  clearSession,
  getToken,
  getResetToken,
  getBearerToken,
  getProfile,
  isTokenExpired,
  loggedIn,
  hasRole,
  setTitle,
  getTitle,
};

// Sets the user details in localStorage
function setSession(authResult) {
  localStorage.setItem("access_token", authResult.access_token);
  localStorage.setItem("refresh_token", authResult.refresh_token);
}

function clearSession() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

function getToken() {
  // Retrieves the user token from localStorage
  return localStorage.getItem("access_token");
}

function getResetToken() {
  return localStorage.getItem("refresh_token");
}

function getBearerToken() {
  return "Bearer " + this.getToken();
}

function getProfile() {
  // Using jwt-decode npm package to decode the token
  return decode(getToken());
}

function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}

function loggedIn() {
  const token = this.getToken(); // GEtting token from localstorage
  return !!token;
}

function hasRole(user, roles) {
  return roles.some((role) => user.user_type === role);
}

function setTitle(firstName, lastName) {
  localStorage.setItem("title", `${firstName} ${lastName}`);
}

function getTitle() {
  return localStorage.getItem("title");
  /* const profile = getProfile();

  if (hasRole(profile, ["SUPER_ADMIN"])) {
    return "Admin";
  } else if (hasRole(profile, ["DATA_ENTRY_ADMIN"])) {
    return "Data-admin";
  } else if (hasRole(profile, ["PO_ADMIN"])) {
    return "PO-admin";
  } else if (hasRole(profile, ["DELIVERY_ADMIN"])) {
    return "Delivery-admin";
  }
  return "User"; */
}
