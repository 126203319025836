import {
    Breadcrumb,
    PageHeader,
    Table,
    Tooltip,
    Typography,
    message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import {
    reportService,
    materialTypeService,
    lotNumberService,
} from "_services";
import { Auth } from "_helpers";
import { Button } from "antd";
import BinWiseBalanceSearch from "./BinWiseBalanceSearch";


const SupplierWiseBalance = () => {
    const [loading, setLoading] = useState(false);
    const [stockTypes, setStockTypes] = useState([]);
    const [lotNumberList, setLotNumberList] = useState([]);
    const [items, setItems] = useState([]);
    const { Title } = Typography;
    const loggedInCustomerCode = Auth.getProfile().comp_code;
    const precision = ["PRYM"].includes(Auth.getProfile().comp_code) ? 3 : 2;

    useEffect(() => {
        getStockTypes();
        getLotNumbers();
        getItems();
    }, []);

    const getStockTypes = () => {
        materialTypeService
            .getSuggestion()
            .then((x) => {
                setStockTypes(x.content);
            })
            .catch((error) => {
                message.error(`${error}`);
            });
    };

    const getLotNumbers = () => {
        lotNumberService
            .getSuggestion()
            .then((x) => {
                setLotNumberList(x.content);
            })
            .catch((errorInfo) => {
                message.error(`${errorInfo}`);
            });
    };

    const getItems = () => {
        setLoading(true);
        let searchParams = "";
        searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
        reportService
            .getBinWiseBalance(searchParams)
            .then((data) => {
                if (data.content.length > 0) {
                    let index = 0;
                    data.content.forEach((item) => {
                        item.key = index++;
                    });
                    setItems(data.content);
                }

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleSearchResults = (results) => {
        setItems(results);
        setLoading(false);
    };

    const getColumns = () => {
        const columns = [];
        columns.push({
            title: "Material Type",
            dataIndex: "stockType",
            key: "stockType",
        });
        columns.push({
            title: "Description",
            key: "stockTypeName",
            width: "30%",
            ellipsis: {
                showTitle: false,
            },
            render: (text, record) => {
                return (
                    <Tooltip placeholder="topLeft" title={record.stockTypeName}>
                        {record.stockTypeName}
                    </Tooltip>
                );
            },
        });
        columns.push({
            title: `${["SILUETA"].includes(loggedInCustomerCode)
                    ? "Vendor Batch"
                    : "Lot/Batch Number"
                }`,
            dataIndex: "lotNumber",
            key: "lotNumber",
        });
        if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
            columns.push({
                title: "Customer",
                dataIndex: "supplier",
                key: "supplier",
            });
        }
        columns.push({
            title: `${loggedInCustomerCode &&
                    ["PRYM", "SILUETA"].includes(loggedInCustomerCode)
                    ? "Rolls/Box"
                    : "Units"
                }`,
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
            width: "7%"
        });
        if (loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)) {
            columns.push({
                title: "Gross Qty",
                dataIndex: "grossQuantity",
                key: "grossQuantity",
                align: "right",
                render: (text, record) => {
                    return text && Number(text).toFixed(precision);
                },
            });
        }
        columns.push({
            title: `Net ${loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)
                    ? "(Kg)"
                    : "Measure"
                }`,
            dataIndex: "netWeight",
            key: "netWeight",
            align: "right",
            render: (text, record) => {
                return text && Number(text).toFixed(precision);
            },
        });
        columns.push({
            title: `Gross ${loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)
                    ? "(Kg)"
                    : "Measure"
                }`,
            dataIndex: "grossWeight",
            key: "grossWeight",
            align: "right",
            render: (text, record) => {
                return text && Number(text).toFixed(precision);
            },
        });
        if (loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)) {
            columns.push({
                title: "Gross (Sq.m)",
                dataIndex: "grossValueSqm",
                key: "grossValueSqm",
                align: "right",
                render: (text, record) => {
                    return text && Number(text).toFixed(precision);
                },
            });
        }

        return columns;
    };

    return (
        <>
            <PageHeader
                title={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/dashboard">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/reports">Reports</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Bin Wise Balance</Breadcrumb.Item>
                    </Breadcrumb>
                }
            >
                <Page inner>
                    <>
                        <BinWiseBalanceSearch
                            showSearchLoading={setLoading}
                            onSearchResults={handleSearchResults}
                            stockTypes={stockTypes}
                            lotNumberList={lotNumberList}
                        />
                        <Table
                            bordered
                            loading={loading}
                            size="small"
                            columns={getColumns()}
                            dataSource={items}
                            pagination={false}
                            summary={(pageData) => {
                                let totalQuantity = 0;
                                let totalNetWeight = 0;
                                let totalGrossWeight = 0;
                                let totalGrossQuantity = 0;
                                let totalGrossSqm = 0;

                                pageData.forEach(
                                    ({
                                        quantity,
                                        netWeight,
                                        grossWeight,
                                        grossQuantity,
                                        grossValueSqm,
                                    }) => {
                                        totalQuantity += quantity;
                                        totalNetWeight += netWeight;
                                        totalGrossWeight += grossWeight;
                                        totalGrossQuantity += grossQuantity;
                                        totalGrossSqm += grossValueSqm;
                                    }
                                );

                                return (
                                    <>
                                        <div style={{ width: "100%" }} >
                                            <Button type="primary">Export to Excel</Button>
                                        </div>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell
                                                colSpan={
                                                    loggedInCustomerCode &&
                                                        ["PRYM"].includes(loggedInCustomerCode)
                                                        ? getColumns().length - 5
                                                        : getColumns().length - 3
                                                }
                                            >
                                                <Typography.Text strong>Total</Typography.Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell align="right">
                                                <Typography.Text strong>
                                                    {totalQuantity}
                                                </Typography.Text>
                                            </Table.Summary.Cell>
                                            {loggedInCustomerCode &&
                                                ["PRYM"].includes(loggedInCustomerCode) && (
                                                    <Table.Summary.Cell align="right">
                                                        <Typography.Text strong>
                                                            {Number(totalGrossQuantity).toFixed(precision)}
                                                        </Typography.Text>
                                                    </Table.Summary.Cell>
                                                )}
                                            <Table.Summary.Cell align="right">
                                                <Typography.Text strong>
                                                    {totalNetWeight &&
                                                        Number(totalNetWeight).toFixed(precision)}
                                                </Typography.Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell align="right">
                                                <Typography.Text strong>
                                                    {totalGrossWeight &&
                                                        Number(totalGrossWeight).toFixed(precision)}
                                                </Typography.Text>
                                            </Table.Summary.Cell>
                                            {loggedInCustomerCode &&
                                                ["PRYM"].includes(loggedInCustomerCode) && (
                                                    <Table.Summary.Cell align="right">
                                                        <Typography.Text strong>
                                                            {totalGrossSqm &&
                                                                Number(totalGrossSqm).toFixed(precision)}
                                                        </Typography.Text>
                                                    </Table.Summary.Cell>
                                                )}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </>
                </Page>
            </PageHeader>
        </>
    );
};

export default SupplierWiseBalance;
