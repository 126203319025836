import {
  Button,
  InputNumber,
  Select,
  Space,
  message,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Auth, Utils } from "_helpers";

const TableForm = ({
  value,
  onChange,
  materialTypes,
  lotNumbers,
  measurementTypes,
}) => {
  //console.log("Values: ", value);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(value);
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const precision = ["PRYM"].includes(Auth.getProfile().comp_code) ? 3 : 2;
  const { Option } = Select;
  const { Text } = Typography;

  const getRowByKey = (key, newData) =>
    (newData || data)?.filter((item) => item.key === key)[0];

  const handleAddOrderLine = () => {
    const newData = data?.map((item) => ({ ...item })) || [];

    const defaultMeasurementType = measurementTypes.find(
      (item) => item.name === "m"
    );
    const newLine =
      defaultMeasurementType === null
        ? { key: `NEW_TEMP_ID_${index}`, quantity: null }
        : {
            key: `NEW_TEMP_ID_${index}`,
            quantity: null,
            measurementType: defaultMeasurementType,
          };
    newData.push(newLine);

    setIndex(index + 1);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const handleRemoveOrderLine = (key) => {
    const newData = data?.filter((item) => item.key !== key);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const handleSaveOrderLine = (e, key) => {
    e.persist();
    const target = getRowByKey(key) || {};
    //console.log("Targe:", target);
    if (
      !target.stockType ||
      !target.lotNumber ||
      !target.totalItems ||
      !target.measurementType
    ) {
      message.error("Please complete the requested item information.");
      e.target.focus();
      return;
    } else {
      handleAddOrderLine();
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const newData = [...data];
    const target = getRowByKey(key, newData);
    if (target) {
      if (["stockType", "lotNumber", "measurementType"].includes(fieldName)) {
        target[fieldName] = { id: value };
      } else {
        target[fieldName] = value;
      }

      setData(newData);
    }
  };

  const columns = [
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.stockType &&
        a.stockType.code &&
        b.stockType &&
        b.stockType.code &&
        a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return (
          <Select
            value={record.stockType && record.stockType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "stockType", record.key)}
          >
            {materialTypes.length > 0 &&
              materialTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.code}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Lot/Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lotNumber.number.localeCompare(b.lotNumber.number),
      render: (text, record) => {
        return (
          <Select
            value={record.lotNumber && record.lotNumber.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
          >
            {lotNumbers &&
              lotNumbers.length > 0 &&
              lotNumbers.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.number}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Rolls/Box",
      dataIndex: "totalItems",
      key: "totalItems",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "totalItems", record.key)}
            min={0}
            placeholder="Rolls/Box"
          />
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            min={0}
            placeholder="Qty"
          />
        );
      },
    },
    {
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.measurementType && record.measurementType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) =>
              handleFieldChange(e, "measurementType", record.key)
            }
          >
            {measurementTypes.length > 0 &&
              measurementTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.name}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Are you sure to remove?"
              onConfirm={() => handleRemoveOrderLine(record.key)}
              key="remove-confirm"
            >
              <Button
                size="small"
                icon={<CloseOutlined />}
                //onClick={() => handleRemoveOrderLine(record.key)}
              ></Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveOrderLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {data.length === 0 && (
        <Button
          style={{
            width: "100%",
            marginTop: 16,
            marginBottom: 8,
            background: "rgb(190, 200, 200)",
          }}
          type="dashed"
          onClick={handleAddOrderLine}
          icon={<PlusOutlined />}
        >
          Add Item
        </Button>
      )}
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        summary={(pageData) => {
          let rollSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let boxSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let spoolSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let boxPcsSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let boxNosSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let boxConeSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let bundlesSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          let defaultSummary = {
            totalItems: 0,
            totalQty: 0,
            uom: "",
            measurementType: null,
          };
          const groupByPackingType = Utils.groupBy(
            pageData,
            (line) =>
              line.stockType &&
              line.stockType.packingType &&
              line.stockType.packingType.name
          );

          groupByPackingType.forEach((value, key) => {
            if (key === "ROLL") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                rollSummary.totalItems += totalItems;
                rollSummary.totalQty += quantity;
                rollSummary.measurementType = measurementType;
              });
            } else if (key === "BOX") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                boxSummary.totalItems += totalItems;
                boxSummary.totalQty += quantity;
                boxSummary.measurementType = measurementType;
              });
            } else if (key === "SPOOL") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                spoolSummary.totalItems += totalItems;
                spoolSummary.totalQty += quantity;
                spoolSummary.measurementType = measurementType;
              });
            } else if (key === "BOX_PCS") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                boxPcsSummary.totalItems += totalItems;
                boxPcsSummary.totalQty += quantity;
                boxPcsSummary.measurementType = measurementType;
              });
            } else if (key === "BOX_NOS") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                boxNosSummary.totalItems += totalItems;
                boxNosSummary.totalQty += quantity;
                boxNosSummary.measurementType = measurementType;
              });
            } else if (key === "BOX_CONE") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                boxConeSummary.totalItems += totalItems;
                boxConeSummary.totalQty += quantity;
                boxConeSummary.measurementType = measurementType;
              });
            } else if (key === "BUNDLES") {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                bundlesSummary.totalItems += totalItems;
                bundlesSummary.totalQty += quantity;
                bundlesSummary.measurementType = measurementType;
              });
            } else {
              value.forEach(({ totalItems, quantity, measurementType }) => {
                defaultSummary.totalItems += totalItems;
                defaultSummary.totalQty += quantity;
                defaultSummary.measurementType = measurementType;
              });
            }
          });
          measurementTypes.forEach((item) => {
            if (item.id === rollSummary.measurementType) {
              rollSummary.uom = item.name;
            } else if (item.id === boxSummary.measurementType) {
              boxSummary.uom = item.name;
            } else if (item.id === spoolSummary.measurementType) {
              spoolSummary.uom = item.name;
            } else if (item.id === boxPcsSummary.measurementType) {
              boxPcsSummary.uom = item.name;
            } else if (item.id === boxNosSummary.measurementType) {
              boxNosSummary.uom = item.name;
            } else if (item.id === boxConeSummary.measurementType) {
              boxConeSummary.uom = item.name;
            } else if (item.id === bundlesSummary.measurementType) {
              bundlesSummary.uom = item.name;
            } else {
              defaultSummary.uom = item.name;
            }
          });

          return (
            <>
              {rollSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - ROLL</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(rollSummary.totalItems) && rollSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(rollSummary.totalQty) && rollSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{rollSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {boxSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - BOX</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxSummary.totalItems) && boxSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxSummary.totalQty) && boxSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{boxSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {spoolSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - SPOOL</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(spoolSummary.totalItems) &&
                        spoolSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(spoolSummary.totalQty) && spoolSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{spoolSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {boxPcsSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - BOX(PCS)</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxPcsSummary.totalItems) &&
                        boxPcsSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxPcsSummary.totalQty) && boxPcsSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{boxPcsSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {boxNosSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - BOX(NOS)</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxNosSummary.totalItems) &&
                        boxNosSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxNosSummary.totalQty) && boxNosSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{boxNosSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {boxConeSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - BOX(CONE)</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxConeSummary.totalItems) &&
                        boxConeSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(boxConeSummary.totalQty) &&
                        boxConeSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{boxConeSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {bundlesSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total - BUNDLES</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(bundlesSummary.totalItems) &&
                        bundlesSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(bundlesSummary.totalQty) &&
                        bundlesSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{bundlesSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
              {defaultSummary.totalItems > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(defaultSummary.totalItems) &&
                        defaultSummary.totalItems}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text strong>
                      {!isNaN(defaultSummary.totalQty) &&
                        defaultSummary.totalQty}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong>{defaultSummary.uom}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default TableForm;
