import { Button, Col, Form, Row, Input, message, Select } from 'antd';
import React, { useState } from 'react';
import { stockCheckService } from '_services';

function Search(props) {
  const [loading, setLoading] = useState(false);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (values.stockBinCode) {
      setLoading(true);
      stockCheckService
        .getLocationByBinCode(props.stockCheckId, values.stockBinCode)
        .then((data) => {
          setLoading(false);
          props.onSearchResults(data.content, false);
          form.resetFields();
        })
        .catch((error) => {
          form.resetFields();
          setLoading(false);
          message.error(`${error}`);
          props.onSearchResults(null, true);
        });
    } else {
      props.onSearchResults(null, true);
    }

    /* props.showSearchLoading(true);
    if (values.stockBinCode) {
        props.onSearchResults(values.stockBinCode);
    } else {
        props.onSearchResults(null);
    } */
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
      {/* <Col span={5}>
          <Form.Item name='sbuId' label='SBU'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {props.businessUnits.length > 0 &&
                props.businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> */}
        {/* <Col span={5}>
          <Form.Item name='zoneId' label='Zone'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {props.zones.length > 0 &&
                props.zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col> */}
        {/* <Col span={5}>
          <Form.Item name='subLocationId' label='Sub-Location'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              disabled={!selectedZoneId}
            >
              {props.subLocations.length > 0 &&
                props.subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={5}>
          <Form.Item name='stockBinCode' label='Scan/Enter Stock Bin Code:'>
            <Input placeholder='Bin Code' allowClear autoFocus />
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={5}></Col>
        <Col span={5}></Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit' loading={loading}>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
