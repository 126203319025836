import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Tag,
  Space,
  Popconfirm,
} from "antd";
import { CloseOutlined, EditOutlined, FormOutlined, UndoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { materialRequestService } from "_services";
import Search from "./Search";
import { Auth } from "_helpers";

const PrymList = ({ match, history }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState(
    "?orderStages=OPEN,VERIFICATION_INPROGRESS,VERIFIED,VERIFICATION_FAILED,IN_PROGRESS"
  );

  useEffect(() => {
    getMaterialRequests(pageNumber, pageSize);
  }, []);

  const getMaterialRequests = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    materialRequestService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getMaterialRequests(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    materialRequestService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getMaterialRequests(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleUndo = (id) => {
    setSelectedId(id);
    setLoadingUndo(true);
    const payload = {
      orderStage: "OPEN",
    };
    materialRequestService
      .updateStatus(id, payload)
      .then(() => {
        setLoadingUndo(false);
        message.success("Successfully opened!");
        window.location.reload();
      })
      .catch((error) => {
        setLoadingUndo(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Link to={`${path}/add`} key="btn-add">
          <Button>Add</Button>
        </Link>
      );
      buttons.push(
        <Link to={`${path}/upload`} key="btn-upload">
          <Button>Upload</Button>
        </Link>
      );
    }
    /* buttons.push(
      <Link to={`${path}/add-with-items`} key="btn-add-with-items">
        <Button>Add with items</Button>
      </Link>
    ); */
    
    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Request No",
      dataIndex: "requestNumber",
      key: "requestNumber",
      render: (text, record) => {
        return (
          <Link to={`/material-requests/${record.id}/summary`}>{text}</Link>
        );
      },
    });
    columns.push({
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text, record) => {
        return moment(text).format("YYYY-MM-DD");
      },
    });
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      columns.push({
        title: "Customer",
        key: "customer",
        render: (text, record) => {
          return record.customer.name;
        },
      });
    }
    columns.push({
      title: "No. Lines",
      key: "numberOfLines",
      render: (text, record) => record.requestLines.length,
    });
    columns.push({
      title: "Total Box",
      key: "totalItems",
      align: "right",
      render: (text, record) =>
        record.requestLines &&
        record.requestLines.length > 0 &&
        record.requestLines
          .map((item) => item.totalItems)
          .reduce((prev, next) => prev + next),
    });
    columns.push({
      title: "Total Quantity",
      key: "totalQty",
      align: "right",
      render: (text, record) =>
        record.requestLines &&
        record.requestLines.length > 0 &&
        Number(
          record.requestLines
            .map((item) => item.quantity)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    });
    columns.push({
      title: "Status",
      dataIndex: "orderStage",
      key: "orderStage",
      render: (orderStatus) => {
        let color = "";
        if (["COMPLETE", "VERIFIED"].includes(orderStatus)) {
          color = "#87d068";
        }
        if (orderStatus === "OPEN") {
          color = "#2db7f5";
        }
        if (orderStatus === "VERIFICATION_INPROGRESS") {
          color = "#d4b106";
        }
        if (["IN_PROGRESS", "ACCEPTED"].includes(orderStatus)) {
          color = "#faad14";
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    });
    columns.push({
      title: "Deliveries",
      dataIndex: "issues",
      key: "issues",
      width: "20%",
      render: (issues) => (
        <span>
          {issues.map((issue, index) => (
            <Space key={index}>
              <Link
                to={`/stock-issue/${issue.id}/${
                  ["PROCESSING"].includes(issue.orderStage) ? "edit" : "summary"
                }`}
              >
                {issue.issueNumber}
              </Link>
              <span>({moment(issue.issuedDate).format("YYYY-MM-DD")})</span>
            </Space>
          ))}
        </span>
      ),
    });
    columns.push({
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "8%",
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.createdBy;
      }, */
    });
    columns.push({
      title: "Modified By",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      width: "8%",
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.modifiedBy;
      }, */
    });
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
              ["VERIFIED", "VERIFICATION_INPROGRESS", "IN_PROGRESS"].includes(
                record.orderStage
              ) && (
                <Button
                  icon={<FormOutlined />}
                  title="Verify"
                  type="link"
                  style={{ marginRight: 10 }}
                  href={`/material-requests/${record.id}/verify`}
                ></Button>
              )}
            {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
              ["OPEN", "VERIFICATION_INPROGRESS"].includes(
                record.orderStage
              ) && (
                <Button
                  icon={<EditOutlined />}
                  title="Edit"
                  type="link"
                  style={{ marginRight: 10 }}
                  href={`/material-requests/${record.id}/edit`}
                ></Button>
              )}
            {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
              ["VERIFIED", "VERIFICATION_INPROGRESS"].includes(
                record.orderStage
              ) && (
                <Button
                  icon={<UndoOutlined />}
                  title="Undo"
                  type="link"
                  style={{ marginRight: 10 }}
                  onClick={() => handleUndo(record.id)}
                  loading={record.id === selectedId && loadingUndo}
                ></Button>
              )}
            {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
              record.orderStage === "OPEN" && (
                <Popconfirm
                  title="Are you sure to delete this item?"
                  onConfirm={() => handleDelete(record.id)}
                >
                  <CloseOutlined />
                </Popconfirm>
              )}
          </span>
        );
      },
    });

    return columns;
  };

  const getRowColor = (record) => {
    let rowColor = "";
    if (record && record.orderStage === "VERIFICATION_INPROGRESS") {
      rowColor = "mr-row-verification-inprogress";
    } else if (record && record.orderStage === "VERIFIED") {
      rowColor = "mr-row-verified";
    }

    return rowColor;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            rowClassName={(record) => getRowColor(record)}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default PrymList;
