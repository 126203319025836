import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Tag,
  Popconfirm,
  Typography,
  Tooltip,
  Divider,
  Tabs,
  Modal,
  Checkbox,
  Space,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  materialRequestService,
  sbuService,
  stockIssueService,
  subLocationService,
  vehicleService,
  zoneService,
} from "_services";
import { Auth } from "_helpers";
import { DeleteOutlined, PlusOutlined, ScanOutlined } from "@ant-design/icons";
import RequestLineSelection from "./RequestLineSelection";

function StrRmAddWithLines({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [verifiedLineLoading, setVerifiedLineLoading] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState(Date());
  //const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [addedStocks, setAddedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [error, setError] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();
  const [stockSearchForm] = Form.useForm();

  const [stockIssue, setStockIssue] = useState({
    store: { id: null },
    customer: { id: null },
    issuedDate: moment(),
    vehicleNumber: null,
    driverName: null,
    contactNumber: null,
    driverNic: null,
    sealNumber: null,
    dispatchNumber: null,
    deliveryRemark: null,
    remark: null,
    issuedLines: [],
    requestedLines: [],
  });
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);

  const [addedRequestLineIds, setAddedRequestLineIds] = useState([]);
  const [verifiedRequestLines, setVerifiedRequestLines] = useState([]);
  const [isAddRequestLineWindowVisible, setIsAddRequestLineWindowVisible] =
    useState(false);
  const [isBulkScanVisible, setIsBulkScanVisible] = useState(false);
  const [bulkScannedBarcodes, setBulkScannedBarcodes] = useState([]);
  const [issuedLines, setIssuedLines] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        setSelectedStore(x.content[0]);
        form.setFieldsValue({
          store: x.content[0],
        });
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const grantedStoreAddresses = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setStoreAddresses(grantedStoreAddresses);
          if (grantedStoreAddresses.length === 1) {
            form.setFieldsValue({ storeAddress: grantedStoreAddresses[0] });
          }
        });
        stockIssue.store.id = x.content[0].id;
      }
      setStores(x.content);
    });
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      if (x.content.length === 1) {
        stockIssue.customer.id = x.content[0].id;
        form.setFieldsValue({
          customer: x.content[0],
        });
      }
      setCustomers(x.content);
    });

    getVehicles();
    getBusinessUnits();
    getZones();
    getSubLocations();
    onFill(stockIssue);
  }, []);

  const getVehicles = () => {
    vehicleService
      .getSuggestion()
      .then((x) => {
        setVehicles(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onSearchAndAddStockItems = (values) => {
    if (values) {
      setLoadingStockItem(true);
      materialDetailService
        .search(
          `?subLocationId=${form.getFieldValue(
            "subLocationId"
          )}&serialNos=${encodeURIComponent(values)}`
        )
        .then((data) => {
          if (data.content) {
            // console.log("Response:", data.content);
            let alreadyLoadedBarcodes = [];
            let successfullyLoadedBarcodes = [];
            let notFoundStocks = [];
            for (const stock of data.content) {
              // console.log("Stock:", stock);
              //let stock = data.content[0];
              let matchedIssueLine = null;

              const deliveryItem = issuedLines
                .flatMap((line) => line.issuedItems)
                .find((item) => item.id === stock.id);
              if (deliveryItem) {
                // console.log("DeliveryItem:", deliveryItem);
                if (deliveryItem.loaded) {
                  alreadyLoadedBarcodes.push(deliveryItem.serialNo);
                  //message.info("Already loaded this stock item");
                } else {
                  for (const line of issuedLines) {
                    //console.log("Issued Line:", line);
                    if (
                      line.issuedItems &&
                      line.issuedItems.find((item) => item.id === stock.id)
                    ) {
                      handleTabChange(line.key);
                      matchedIssueLine = line;
                    }
                  }
                  const lineKey = matchedIssueLine.key;
                  if (selectedRowKeyMap.has(lineKey)) {
                    selectedRowKeyMap.set(lineKey, [
                      ...selectedRowKeyMap.get(lineKey),
                      stock.id,
                    ]);
                  } else {
                    selectedRowKeyMap.set(lineKey, [stock.id]);
                  }
                  updateStockItemStatus();
                  successfullyLoadedBarcodes.push(deliveryItem.serialNo);
                  //message.success("Successfully loaded to the delivery");
                }
              } else {
                notFoundStocks.push(stock.serialNo);
                //message.error("Sorry, No item found against the added stocks");
              }
              form.setFieldsValue({ stockItem: null });
            }

            if (notFoundStocks.length > 0) {
              message.error(
                `Sorry, No items (${notFoundStocks.toString()}) found against the added stocks`
              );
            }
            if (alreadyLoadedBarcodes.length > 0) {
              message.info(
                `Already loaded these (${alreadyLoadedBarcodes.toString()}) stock items`
              );
            }
            if (successfullyLoadedBarcodes.length > 0) {
              message.success(
                `Successfully loaded these items (${successfullyLoadedBarcodes.toString()}) to the delivery`
              );
            }
          } else {
            message.error(
              "Sorry, No item found against the barcode or barcodes."
            );
          }
          setBulkScannedBarcodes([]);
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  const handleFormSumbit = (isComplete) => {
    form
      .validateFields()
      .then((values) => {
        //const validated = validateDelivery();
        //if (validated.isValid) {
        if (isComplete) {
          setDeliveryLoading(true);
        } else {
          setIsLoading(true);
        }

        delete values.stockItem;
        delete values.materialRequest;

        //values.orderStage = isComplete ? "ISSUED" : "PROCESSING";
        values.issuedDate = moment(values.issuedDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        values.issuedLines = issuedLines.map((line) => ({
          requestLine: { id: line.requestLine.id },
          quantity: line.issuedItems.filter((item) => item.added).length,
          stockType: { id: line.stockType.id },
          lotNumber: { id: line.lotNumber && line.lotNumber.id },
          issuedItems: line.issuedItems,
          /* issuedItems: line.issuedItems
            .filter((item) => (isComplete ? item.loaded : item.added))
            .map((item) => ({
              id: item.id,
              mode: isComplete ? "ISSUED" : item.mode,
            })), */
        }));
        const subLocation = subLocations.find(
          (item) => item.id === values.subLocationId
        );
        values.subLocation = {
          id: subLocation.id,
          shortCode: subLocation.shortCode,
        };

        // console.log("OUT: ", values);
        //console.log("OUT: ", JSON.stringify(values));
        stockIssueService
          .create(values)
          .then((data) => {
            setIsLoading(false);
            history.push(`/stock-issue/${data.content.id}/summary`);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onSelectChange = (rowKeys) => {
    //console.log("RowKeys:", rowKeys);
    setSelectedRowKeyMap(
      selectedRowKeyMap.set(activeTabKey.toString(), rowKeys)
    );
    updateStockItemStatus();
  };

  const onBulkScanAndAdd = (value) => {
    if (value) {
      if (bulkScannedBarcodes.includes(value)) {
        return message.error("Barcode is already scanned!");
      }
      setBulkScannedBarcodes([...bulkScannedBarcodes, value]);
      form.setFieldsValue({ itemScan: "" });
    }
  };

  const handleBulkScannedAdd = () => {
    onSearchAndAddStockItems(bulkScannedBarcodes.toString());
    setIsBulkScanVisible(false);
  };

  const handleAllLoadChange = (e) => {
    if (e.target.checked) {
      issuedLines.forEach((line, index) => {
        setSelectedRowKeyMap(
          selectedRowKeyMap.set(
            line.key,
            line.issuedItems.map((item) => item.id)
          )
        );
      });
      updateStockItemStatus();
    } else {
      setSelectedRowKeyMap(new Map());
      const totalIssuedStocks = issuedLines.flatMap((item) => item.issuedItems);
      for (let item of totalIssuedStocks) {
        item.loaded = false;
        item.mode = "READY";
      }
      setAddedStocks([]);
      issuedLines.forEach((line) => {
        line.issuedQuantity = 0;
        line.issuedNetWeight = 0;
        line.issuedGrossWeight = 0;
      });
    }
  };

  const updateStockItemStatus = () => {
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    //console.log("all row keys:", allSelectedKeys);
    setSelectedRowKeys(allSelectedKeys);
    const totalRequestedStocks = issuedLines.flatMap(
      (item) => item.issuedItems
    );
    let loadedStocks = [];
    for (let item of totalRequestedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.loaded = true;
        item.mode = "LOADED";
        loadedStocks.push(item);
      } else {
        item.loaded = false;
        item.mode = "READY";
      }
    }
    setAddedStocks(loadedStocks);

    issuedLines.forEach((line) => {
      line.issuedQuantity = 0;
      line.issuedNetWeight = 0;
      line.issuedGrossWeight = 0;
      loadedStocks.forEach((stock) => {
        // console.log("LineKey:", line);
        // console.log("StockKey: ", `${stock.requestLineId}_${stock.stockType.id}_${stock.lotNumber ? stock.lotNumber.id : ''}`);
        if (
          line.key ===
          `${stock.requestLineId}_${stock.stockType.id}_${
            line.lotNumber ? stock.lotNumber.id : ""
          }`
        ) {
          line.issuedQuantity += 1;
          line.issuedNetWeight += stock.netWeight;
          line.issuedGrossWeight += stock.grossWeight;
        }
      });
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      //console.log("Selected:", selectedIssueLineKeys);
      handleTabChange(selectedIssueLineKeys[0]);
    },
  };

  const onCancel = () => {
    history.push(".");
  };

  const handleTabChange = (activeKey) => {
    //console.log("Active:", activeKey);
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
    // console.log("Active:", activeKey);
  };

  const handleLineDelete = (item) => {
    const tmpIssuedlines = [...issuedLines];
    //const tmpAddedRequestLineIds = [...addedRequestLineIds];
    if (tmpIssuedlines.includes(item)) {
      tmpIssuedlines.splice(tmpIssuedlines.indexOf(item), 1);
      addedRequestLineIds.splice(
        addedRequestLineIds.indexOf(item.requestLine.id),
        1
      );
      setIssuedLines(tmpIssuedlines);
      //setAddedRequestLineIds(tmpAddedRequestLineIds);
      console.log("Added Ids: ", addedRequestLineIds);
    }
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Request No:",
      dataIndex: "requestNumber",
      key: "requestNumber",
      // with: '10%',
    });
    columns.push({
      title: "Department",
      key: "departmentCode",
      // width: '8%',
      render: (text, record) => {
        return record.requestLine.departmentCode;
      },
    });
    columns.push({
      title: "MRN No.",
      key: "mrnNumber",
      // width: '5%',
      render: (text, record) => {
        return record.requestLine.mrnNumber;
      },
    });
    columns.push({
      title: "Req. Time",
      key: "requestTime",
      // width: '8%',
      render: (text, record) => {
        return record.requestLine.requestTime;
      },
    });
    columns.push({
      title: "Material",
      key: "stockTypeCode",
      // width: '8%',
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    /* columns.push({
      title: 'Description',
      key: 'stockTypeName',
      width: '8%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder='topLeft' title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    }); */
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      // width: '15%',
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Ready. Box",
      key: "verifiedBox",
      align: "right",
      // width: '5%',
      render: (text, record, index) => {
        return record.requestLine.requestedItems.length;
      },
    });
    columns.push({
      title: "Ready. Net Weight",
      key: "verifiedNetWeight",
      align: "right",
      // width: '8%',
      render: (text, record) => {
        return (
          record.requestLine.requestedItems.length &&
          Number(
            record.requestLine.requestedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next)
          ).toFixed(2)
        );
      },
    });
    columns.push({
      title: "Loaded Net Weight",
      dataIndex: "issuedNetWeight",
      key: "grossWeight",
      align: "right",
      // width: '8%',
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Loaded Box",
      dataIndex: "issuedQuantity",
      key: "quantity",
      align: "right",
      // width: '5%',
    });
    columns.push({
      title: "",
      key: "operation",
      width: "2%",
      render: (text, record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Are you sure to delete this line?"
              onConfirm={() => handleLineDelete(record)}
              key="pop-line-delete"
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>
            {/* <Button
              key='btn-line-add'
              type='dashed'
              onClick={(e) => {
                e.preventDefault();
                handleAddLineItems(record);
              }}
              icon={<PlusCircleOutlined />}
            ></Button>
            <Button
              key='btn-line-remove'
              type='dashed'
              onClick={() => {
                handleRemoveLineItems(record);
              }}
              icon={<MinusCircleOutlined />}
            ></Button> */}
          </Space>
        );
      },
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    /* columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
    }); */
    columns.push({
      title: "Prod Date",
      dataIndex: "productionDate",
      key: "productionDate",
      // width: "8%",
    });
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      // width: "8%",
    });
    /* columns.push({
      title: 'Inspect.St',
      dataIndex: 'inspectionStatus',
      key: 'inspectionStatus',
      width: '5%',
    }); */
    /* columns.push({
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: "7%",
      render: (text, record) => {
        return record.supplier && record.supplier.name;
      },
    }); */
    columns.push({
      title: "Bin",
      key: "stockBin",
      // width: "7%",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "Pallet",
      dataIndex: "palletNo",
      key: "palletNo",
      align: "right",
    });
    columns.push({
      title: "B/N",
      dataIndex: "tempNumber",
      key: "tempNumber",
      width: "3%",
      align: "right",
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "10%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    /* columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    }); */
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "LOADED") {
          color = "#87d068";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    /* columns.push({
      title: '',
      key: 'operation',
      width: '2%',
      render: (text, record) => {
        return (
          <Space size='small'>
            <Button
              key='btn-item-add'
              type='dashed'
              onClick={() => {
                handleAddLineItem(record);
              }}
              icon={<PlusCircleOutlined title='Add Item' />}
            ></Button>
            <Button
              key='btn-item-remove'
              type='dashed'
              onClick={() => {
                handleRemoveLineItem(record);
              }}
              icon={<MinusCircleOutlined title='Remove Item' />}
            ></Button>
          </Space>
        );
      },
    }); */

    return columns;
  };

  const handleRequestLineSelection = (requestLineIds) => {
    setAddedRequestLineIds(requestLineIds);
  };

  const handleAddRequestedLines = () => {
    let addedLines = verifiedRequestLines.filter((line) =>
      addedRequestLineIds.includes(line.id)
    );
    if (addedLines) {
      const newlyAdded = addedLines.filter((item) => !item.added);
      if (newlyAdded) {
        for (var i = 0; i < newlyAdded.length; i++) {
          var requestLine = newlyAdded[i];
          requestLine.added = true;
          const issuedLine = {
            key: `${requestLine.id}_${requestLine.stockType.id}_${
              requestLine.lotNumber ? requestLine.lotNumber.id : ""
            }`,
            stockType: requestLine.stockType,
            lotNumber: requestLine.lotNumber,
            requestLine: requestLine,
            measurementType: requestLine.measurementType,
            requestNumber: requestLine.requestNumber,
            requestedQuantity: requestLine.requestedItems.length,
            //requestedTotalQuantity: requestLine.quantity,
            requestedNetMeasure:
              requestLine.requestedItems.length > 0 &&
              requestLine.requestedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next),
            issuedItems: requestLine.requestedItems,
            /* issuedItems: requestLine.requestedItems
              .filter((item) => item.mode === 'READY')
              .map((t) => ({
                ...t,
                added: true,
                loaded: false,
                mode: 'READY',
                requestLineId: requestLine.id,
              })), */
            issuedQuantity: 0,
            issuedNetWeight: 0,
            issuedGrossWeight: 0,
          };
          issuedLines.push(issuedLine);
        }
        handleTabChange(issuedLines[issuedLines.length - 1].key);
      }
      //setIssuedLines(...issuedLines, ...issuedLines.filter(({ id: id1}) => !addedLines.some(({ id: id2}) => id1 === id2)));
    }
    setIsAddRequestLineWindowVisible(false);
  };

  const getTabContent = (stockIssueLines) => {
    let tabPanes = [];
    // console.log("IssueLines:", stockIssueLines);
    stockIssueLines.forEach((line, index) => {
      if (line.issuedItems && line.issuedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType.code} / ${
              line.lotNumber ? line.lotNumber.number : ""
            } (${line.issuedItems.length})`}
            key={`${line.key}`}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => (record.loaded ? "ant-tag-green" : "")}
              //rowSelection={rowSelection}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                  //totalQuantity += quantity ? quantity : 1;
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 5}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong style={{ paddingLeft: 12 }}>
                          {line.issuedItems.length}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const getBulkScanModalContent = () => {
    return (
      <Form form={form} name="bulk-scan-form">
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="itemScan">
              <Input.Search
                placeholder="Enter valid barcode"
                allowClear
                enterButton={<ScanOutlined />}
                onSearch={onBulkScanAndAdd}
                style={{ marginBottom: 20 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Space size={[8, 16]} wrap>
              {bulkScannedBarcodes.map((item) => (
                <Button key={item} type="primary" ghost>
                  {item}
                </Button>
              ))}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  };

  const getVerifiedRequestLines = () => {
    if (form.getFieldValue("subLocationId") === undefined) {
      message.error("Please select Sub-Locaiton to proceed...");
    } else {
      let searchParams = `?subLocationId=${form.getFieldValue(
        "subLocationId"
      )}&lineStages=READY`;
      setVerifiedLineLoading(true);
      materialRequestService
        .searchLine(searchParams)
        .then((data) => {
          setVerifiedLineLoading(false);
          if (data.content.length > 0) {
            data.content.forEach((line) => {
              if (addedRequestLineIds.includes(line.id)) {
                line.added = true;
              }
            });
            setVerifiedRequestLines(data.content);
            setIsAddRequestLineWindowVisible(true);
          } else {
            message.info("Sorry, There's no verified request lines available!");
          }
        })
        .catch((error) => {
          setVerifiedLineLoading(false);
          message.error(`${error}`);
        });
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    /* buttons.push(
      <Button
        key="scan"
        icon={<ScanOutlined />}
        onClick={() => setIsBulkScanVisible(true)}
        title="Bulk Scan"
        loading={loadingStockItem}
      ></Button>
    ); */
    buttons.push(
      <Popconfirm
        title="Are you sure to issue these stocks?"
        onConfirm={(e) => {
          e.preventDefault();
          setIsComplete(false);
          handleFormSumbit();
        }}
        key="save-confirm"
        disabled={issuedLines.flatMap((item) => item.issuedItems).length === 0}
      >
        <Button
          key="btn-save"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={
            issuedLines.flatMap((item) => item.issuedItems).length === 0
          }
        >
          Save
        </Button>
      </Popconfirm>
    );
    /* buttons.push(
      <Popconfirm
        title="Are you sure to confirm and complete this delivery?"
        onConfirm={(e) => {
          e.preventDefault();
          setIsComplete(true);
          handleFormSumbit(true);
        }}
        key="complete-confirm"
        disabled={
          issuedLines
            .flatMap((line) => line.issuedItems)
            .filter((item) => item.loaded).length === 0
        }
      >
        <Button
          key="btn-save"
          type="primary"
          htmlType="submit"
          loading={deliveryLoading}
          disabled={
            issuedLines
              .flatMap((line) => line.issuedItems)
              .filter((item) => item.loaded).length === 0
          }
        >
          Deliver
        </Button>
      </Popconfirm>
    ); */
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue/add">Issue Stocks</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["store", "id"]}
                  label="Store"
                  rules={[{ required: false, message: "Store is required" }]}
                >
                  <Select placeholder="Please select">
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["customer", "id"]}
                  label="Customer"
                  rules={[{ required: false, message: "Customer is required" }]}
                >
                  <Select placeholder="Please select" allowClear>
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="issuedDate"
                  label="Issue Date"
                  rules={[
                    { required: false, message: "Issue date is required" },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder="Issue date"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    onChange={setSelectedIssueDate}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sbuId" label="SBU">
                  <Select
                    placeholder="Please select"
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    onChange={(e) => {
                      form.setFieldsValue({ zoneId: null });
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedSbuId(e);
                    }}
                    onClear={() => {
                      form.setFieldsValue({ zoneId: null });
                      form.setFieldsValue({ subLocationId: null });
                    }}
                  >
                    {businessUnits.length > 0 &&
                      businessUnits.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["storeAddress", "id"]}
                  label="Store Location"
                  rules={[
                    {
                      required: true,
                      message: "Store location is required",
                    },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.address}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="driverName"
                  label="Driver Name"
                  rules={[
                    {
                      required: isComplete,
                      message: "Driver Name is required",
                    },
                  ]}
                >
                  <Input placeholder="Driver Name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactNumber"
                  label="Contact Number"
                  rules={[
                    {
                      required: true,
                      message: "Contact Number is required",
                    },
                  ]}
                >
                  <Input placeholder="Contact Number" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="zoneId" label="Zone">
                  <Select
                    placeholder="Please select"
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    onChange={(e) => {
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedZoneId(e);
                    }}
                    onClear={() => {
                      form.setFieldsValue({ subLocationId: null });
                    }}
                    disabled={!selectedSbuId}
                  >
                    {zones.length > 0 &&
                      zones
                        .filter((item) => item.sbu.id === selectedSbuId)
                        .map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="sealNumber"
                  label="Seal No:"
                  rules={[
                    {
                      required: isComplete,
                      message: "Seal number is required",
                    },
                  ]}
                >
                  <Input placeholder="Seal No:" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["vehicle", "id"]}
                  label="Vehicle"
                  rules={[
                    { required: true, message: "Vehicle is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Vehicle"
                  >
                    {vehicles.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="driverNic"
                  label="Driver NIC"
                  rules={[
                    { required: isComplete, message: "Driver NIC is required" },
                  ]}
                >
                  <Input placeholder="Driver NIC" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="subLocationId"
                  label="Sub-Location"
                  rules={[
                    {
                      required: true,
                      message: "Please select Sub-Location",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    disabled={!selectedZoneId}
                  >
                    {subLocations.length > 0 &&
                      subLocations
                        .filter((item) => item.zone.id === selectedZoneId)
                        .map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}></Row>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item name="remark" label="Remark">
                <TextArea />
              </Form.Item>
            </Col>
            <Button
              style={{
                width: "100%",
                marginTop: 5,
                marginBottom: 15,
                color: "white",
                backgroundColor: "#13c2c2",
              }}
              type="dashed"
              onClick={getVerifiedRequestLines}
              icon={<PlusOutlined />}
              loading={verifiedLineLoading}
            >
              Add Request Line
            </Button>

            {/* issuedLines.length > 0 &&
              issuedLines.map((item) => item.issuedItems) &&
              issuedLines.map((item) => item.issuedItems).length > 0 && (
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item name="stockItem">
                      <Input.Search
                        loading={loadingStockItem}
                        placeholder="Enter valid barcode"
                        allowClear
                        enterButton={<ScanOutlined />}
                        onSearch={onSearchAndAddStockItems}
                        style={{ marginBottom: 20 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) */}

            {issuedLines.length > 0 && (
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={issuedLines}
                //expandable={{ expandedRowRender, defaultExpandAllRows: true }}
                rowSelection={{
                  type: "radio",
                  ...issueLineRowSelection,
                }}
                rowClassName={(record) =>
                  record.requestLine.requestedItems.length ===
                  record.issuedQuantity
                    ? "ant-tag-green"
                    : record.issuedQuantity > 0
                    ? "ant-tag-gold"
                    : ""
                }
                size="small"
                summary={(pageData) => {
                  let totalRequestedQuantity = 0;
                  let totalRequestedQuantityWithoutStocks = 0;
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;
                  let totalRequestedNetMeasure = 0;

                  pageData.forEach(
                    ({
                      requestedQuantity,
                      requestedTotalQuantity,
                      issuedQuantity,
                      issuedNetWeight,
                      issuedGrossWeight,
                      requestedNetMeasure,
                    }) => {
                      totalRequestedQuantity += requestedQuantity;
                      totalRequestedQuantityWithoutStocks +=
                        requestedTotalQuantity;
                      totalQuantity += issuedQuantity;
                      totalNetWeight += issuedNetWeight;
                      totalGrossWeight += issuedGrossWeight;
                      totalRequestedNetMeasure += requestedNetMeasure;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={getLineColumns().length - 4}
                        >
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalRequestedQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalRequestedQuantityWithoutStocks) &&
                              Number(
                                totalRequestedQuantityWithoutStocks
                              ).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalNetWeight) &&
                              Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
          </Form>
          {issuedLines.length > 0 &&
            issuedLines.map((item) => item.issuedItems) &&
            issuedLines
              .map((item) => item.issuedItems)
              .reduce((prev, next) => prev + next).length > 0 && (
              <>
                <Divider orientation="left">Stock Items</Divider>
                <Tabs
                  //defaultActiveKey={issuedLines[0].key}
                  onChange={handleTabChange}
                  activeKey={activeTabKey}
                >
                  {getTabContent(issuedLines)}
                </Tabs>
              </>
            )}
          <Modal
            title={`Pending Requested Lines (${verifiedRequestLines.length})`}
            style={{ top: 48 }}
            visible={isAddRequestLineWindowVisible}
            okText={`Add Lines (${addedRequestLineIds.length})`}
            onOk={handleAddRequestedLines}
            okButtonProps={{ disabled: addedRequestLineIds.length === 0 }}
            onCancel={() => setIsAddRequestLineWindowVisible(false)}
            destroyOnClose={true}
            width={1800}
          >
            <RequestLineSelection
              verifiedLines={verifiedRequestLines}
              selectedIds={addedRequestLineIds}
              //customer={selectedCustomer}
              onSelectChange={handleRequestLineSelection}
            />
          </Modal>
          <Modal
            title="Bulk Scan"
            visible={isBulkScanVisible}
            okText={`Add All (${bulkScannedBarcodes.length})`}
            onOk={handleBulkScannedAdd}
            onCancel={() => setIsBulkScanVisible(false)}
            width={1300}
            destroyOnClose={true}
          >
            {getBulkScanModalContent()}
          </Modal>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmAddWithLines;
