import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Form,
  PageHeader,
  Popconfirm,
  Table,
  message,
  Row,
  Col,
  Input,
  Tooltip,
  Tag,
} from "antd";
import Page from "_components/Page";
import { binService, materialDetailService } from "_services";
import { CloseOutlined } from "@ant-design/icons";

const SiluetaTransfer = ({ match, history }) => {
  const { id } = match.params;
  const { path } = match;
  const [items, setItems] = useState([]);
  const [bin, setBin] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    binService
      .getById(id)
      .then((x) => {
        setLoading(false);
        let selectedBin = x.content;
        console.log("Bin: ", selectedBin);
        setBin(selectedBin);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const onSearch = (values) => {
    if (!values) {
      return;
    } else {
      if (values.barcode && values.barcode.split("|").length === 3) {
        setLoading(true);
        const searchParams = `?barcode=${encodeURIComponent(values.barcode)}`;
        materialDetailService
          .getByBarcode(searchParams)
          .then((data) => {
            if (data && data.content) {
              const stock = data.content;
              if (items.find((item) => item.id === stock.id)) {
                message.info("Already loaded this stock item");
              } else {
                const newList = [...items, stock];
                setItems(newList);
              }
            } else {
              message.error("Sorry, No item found against the barcode.");
            }
            setLoading(false);
            form.resetFields();
          })
          .catch((error) => {
            message.error(`${error}`);
            setLoading(false);
            form.resetFields();
          });
      } else {
        message.error("Please enter valid Stock barcode.");
        form.resetFields();
        return;
      }
    }
  };

  const handleStockTransfer = () => {
    let payload = {
      store: { id: bin.store.id },
      storeLocation: { id: bin.storeLocation.id },
      stockBin: { id: bin.id },
      stockItems: items.map((item) => ({ id: item.id })),
    };
    //console.log("PL: ", payload);
    setLoadingTransfer(true);
    materialDetailService
      .transfer(payload)
      .then(() => {
        setLoadingTransfer(false);
        message.success("Bin transfer success!!");
        history.push("/stock-transfer");
      })
      .catch((error) => {
        setLoadingTransfer(false);
        message.error(`${error}`);
      });
  };

  const handleRemove = (item) => {
    const stockItems = [...items];
    if (stockItems.includes(item)) {
      stockItems.splice(stockItems.indexOf(item), 1);
      setItems(stockItems);
    }
  };

  const onCancel = () => {
    history.push("/stock-transfer");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        key="1"
        title="Are you sure to transfer there stocks?"
        onConfirm={handleStockTransfer}
        disabled={items.length === 0}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loadingTransfer}
          disabled={items.length === 0}
        >
          Trasfer
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return (
          <>
            <Link to={`/material-details/${record.id}/summary`}>
              {record.stockType.code}
            </Link>
            <Input value={record.id} hidden />
          </>
        );
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "20%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Vendor Batch",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Roll No:",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "10%",
    });
    /* if (loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Plant",
        dataIndex: "plant",
        key: "plant",
        width: "4%",
        render: (text, record) => {
          return record.plant && record.plant.name;
        },
      });
    } */
    /* columns.push({
      title: "Invoice No:",
      key: "preReceiptNumber",
      width: "10%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber
        );
      },
    }); */
    /* if (loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Location",
        key: "storeLocation",
        width: "6%",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.stockBin.storeLocation.address}
            >
              {record.stockBin.storeLocation.address}
            </Tooltip>
          );
        },
      });
    } */
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "10%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    /* if (loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Pallet No",
        dataIndex: "palletNo",
        key: "palletNo",
        width: "4%",
      });
    } */
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "10%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "10%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    /* columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "4%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    }); */
    columns.push({
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to remove?"
            onConfirm={() => handleRemove(record)}
          >
            <Button size="small" icon={<CloseOutlined />} />
          </Popconfirm>
        );
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-transfer">Stock Transfer</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>Bin: {bin.code}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Transfer</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Form
            form={form}
            onFinish={onSearch}
            layout="vertical"
            className="search-form"
          >
            <Row gutter={24}>
              <Col span={18}>
                <Form.Item name="barcode" label="Stock Item Barcode">
                  <Input
                    autoFocus
                    placeholder="Stock Item Barcode"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <div>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => {
                      form.resetFields();
                      onSearch({});
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            dataSource={items}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default SiluetaTransfer;
