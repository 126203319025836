import React from 'react';
import './index.css';
import './number.scss';
const NumberInfo = ({
  theme,
  title,
  categoryTitle,
  categoryTitle1,
  categoryTitle2,
  categoryTitle3,
  totalItems,
  totalNetWeight,
  totalGrossWeight,
  totalQuantity,
  status,
  numberTitle,
  iconImage,
  suffix,
  gap,
  ...rest
}) => (
  <div>
    <div>
      {title && (
        <div
          className={numberTitle}
          title={typeof title === 'string' ? title : ''}
        >
          <div>{title}</div>
          <div>
            {' '}
            <img src={iconImage} alt='{iconImage}' />
          </div>
        </div>
      )}
      <div className='numberValue'>
        <div>
          <span className='suffix'>{totalItems?.toLocaleString()}</span>
          <div className='title'>{categoryTitle}</div>
        </div>
        <div>
          <span className='suffix'>{totalNetWeight && totalNetWeight.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}</span>
          <div className='title'>{categoryTitle1}</div>
        </div>
        <div>
          <span className='suffix'>{totalGrossWeight && totalGrossWeight.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}</span>
          <div className='title'>{categoryTitle2}</div>
        </div>
        <div>
          <span className='suffix'>{totalQuantity && totalQuantity.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}</span>
          <div className='title'>{categoryTitle3}</div>
        </div>
      </div>
    </div>
  </div>
);

export default NumberInfo;
