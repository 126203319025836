import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Popconfirm,
  Space,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  measurementTypeService,
  departmentService,
  machineService,
  sbuService,
  zoneService,
  subLocationService,
  supplierService,
} from "_services";
import { Auth, Utils, PriorityStatuses } from "_helpers";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

let lineIndex = 0;

function StrRmAdd({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedSbuCode, setSelectedSbuCode] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        deliveryLocation: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setCustomers([data.content]);
      setCustomerAddresses(officeAddresses);
      setIsLoading(false);
      getSuppliers();
    });

    /* companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      if (x.content.length === 1) {
        let companyId = x.content[0].id;
        setSelectedCustomer(x.content[0]);
        companyService.getById(companyId).then((data) => {
          const officeAddress = data.content.addresses.filter(
            (item) => item.addressType === "OFFICE"
          );
          form.setFieldsValue({
            customer: x.content[0],
            deliveryLocation: officeAddress[0],
          });
          setAddresses(officeAddress);
        });
      }
      setCustomers(x.content);
    }); */
    materialTypeService.getSuggestion("").then((x) => {
      setMaterialTypes(x.content);
    });
    lotNumberService.getSuggestion("").then((x) => {
      setLotNumbers(x.content);
    });
    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content);
    });
    departmentService.getSuggestion().then((data) => {
      setDepartments(data.content);
    });
    machineService.getSuggestion().then((data) => {
      setMachines(data.content);
    });
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFieldChange = (value, fieldName, key) => {
    const lines = [...materialRequestLines];
    const newRequestLines = lines.map((line) => {
      const newLine = Object.assign({}, line);
      if (newLine.key === key) {
        if (fieldName === "stockType") {
          newLine[fieldName] = materialTypes.find((item) => item.id === value);
          return newLine;
        } else if (fieldName === "department") {
          newLine[fieldName] = departments.find((item) => item.id === value);
          return newLine;
        } else if (fieldName === "lotNumber") {
          newLine[fieldName] = lotNumbers.find((item) => item.id === value);
          return newLine;
        } else if (fieldName === "measurementType") {
          newLine[fieldName] = measurementTypes.find(
            (item) => item.id === value
          );
          return newLine;
        } else if (fieldName === "machine") {
          newLine[fieldName] = machines.find((item) => item.id === value);
          return newLine;
        } else if (fieldName === "supplier") {
          newLine[fieldName] = suppliers.find((item) => item.id === value);
          return newLine;
        } else {
          newLine[fieldName] = value;
          return newLine;
        }
      }
      return newLine;
    });
    //console.log("Lines: ", groupByLines);
    setMaterialRequestLines(newRequestLines);
  };

  const handleAddRequestLine = () => {
    if (form.getFieldValue("subLocationId") === undefined) {
      message.error("Please select Sub-Locaiton to proceed...");
    } else {
      const defaultMeasurementType = measurementTypes.find(
        (item) => item.name === "BOX"
      );

      const newLine =
        defaultMeasurementType === null
          ? { requestedItems: [] }
          : {
              requestedItems: [],
              measurementType: defaultMeasurementType.id,
            };
      newLine.key = lineIndex;
      const lines = [...materialRequestLines, newLine];
      //console.log("Lines:", lines);
      setMaterialRequestLines(lines);
      lineIndex += 1;
    }
  };

  const handleRemoveRequestLine = (line) => {
    //console.log("Remove:", materialRequestLines);
    const lines = [...materialRequestLines];
    if (lines.includes(line)) {
      lines.splice(lines.indexOf(line), 1);
      setMaterialRequestLines(lines);
    }
  };

  const handleSaveRequestLine = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    //console.log("Target:", target);
    if (selectedSbuCode === "GP") {
      if (
        !target.supplier ||
        !target.department ||
        !target.mrnNumber ||
        !target.requestTime ||
        !target.stockType ||
        !target.lotNumber ||
        !target.quantity ||
        !target.measurementType
      ) {
        message.error("Please complete the requested line details.");
        e.target.focus();
        return;
      }
    } else {
      if (
        !target.department ||
        !target.mrnNumber ||
        !target.requestTime ||
        !target.stockType ||
        !target.lotNumber ||
        !target.quantity ||
        !target.measurementType
      ) {
        message.error("Please complete the requested line details.");
        e.target.focus();
        return;
      }
    }
    handleAddRequestLine();
  };

  const getRowBykey = (key, newData) => {
    return (materialRequestLines || newData).filter(
      (item) => item.key === key
    )[0];
  };

  const validateRequestLines = (lines) => {
    let validLines = false;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (selectedSbuCode === "GP") {
          if (
            line.supplier &&
            line.department &&
            line.mrnNumber &&
            line.requestTime &&
            line.stockType &&
            line.lotNumber &&
            line.quantity &&
            line.measurementType
          ) {
            validLines = true;
          }
        } else {
          if (
            line.department &&
            line.mrnNumber &&
            line.requestTime &&
            line.stockType &&
            line.lotNumber &&
            line.quantity &&
            line.measurementType
          ) {
            validLines = true;
          }
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateRequestLines(materialRequestLines)) {
          setIsLoading(true);
          //delete values.stockType;
          //delete values.lotNumber;

          /* values.requestNumber = `${
          selectedCustomer && selectedCustomer.code
        }-${moment(selectedRequestDate).format("MM")}-${values.requestNumber}`; */
          /* values.requestNumber = `${selectedCustomer && selectedCustomer.code
            }-MRN-${values.requestNumber}`; */
          values.requestDate = moment(values.requestDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let requestLines = [];

          materialRequestLines.forEach((line) => {
            let requestLine = {};
            requestLine.department = { id: line.department.id };
            requestLine.stockType = { id: line.stockType.id };
            if (line.lotNumber) {
              requestLine.lotNumber = line.lotNumber;
            }
            if (line.machine) {
              requestLine.machine = { id: line.machine.id };
            }
            if (line.measurementType.id) {
              requestLine.measurementType = line.measurementType;
            } else {
              requestLine.measurementType = { id: line.measurementType };
            }
            requestLine.mrnNumber = line.mrnNumber;
            requestLine.requestTime = moment(line.requestTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            requestLine.quantity = line.quantity;
            requestLine.totalItems = line.totalItems;
            if (line.priorityStatus) {
              requestLine.priorityStatus = line.priorityStatus;
            }
            if (line.supplier) {
              requestLine.supplier = { id: line.supplier.id };
            }
            requestLine.requestedItems = line.requestedItems.map((item) => ({
              id: item.id,
            }));
            requestLine.width = line.width;
            requestLine.colorCode = line.colorCode;
            requestLines.push(requestLine);
          });
          values.requestLines = requestLines;

          delete values.department;
          delete values.mrnNumber;
          delete values.requestTime;
          delete values.stockType;
          delete values.lotNumber;
          delete values.measurementType;
          delete values.quantity;
          const subLocation = subLocations.find(
            (item) => item.id === values.subLocationId
          );

          const payload = {
            requestDate: values.requestDate,
            customer: values.customer,
            deliveryLocation: values.deliveryLocation,
            store: values.store,
            storeAddress: values.storeAddress,
            contactPersonName: values.contactPersonName,
            contactNumber: values.contactNumber,
            remark: values.remark,
            requestLines: values.requestLines,
            subLocation: {
              id: subLocation.id,
              shortCode: subLocation.shortCode,
            },
          };

          // console.log("MR: ", payload);
          // console.log("MR: ", JSON.stringify(values));

          materialRequestService
            .create(payload)
            .then(() => {
              setIsLoading(false);
              history.push(".");
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error);
            });
        } else {
          message.error(
            "Please complete all the request lines with valid details"
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  const getLineColumns = () => {
    let columns = [];
    if (selectedSbuCode === "GP") {
      columns.push({
        title: "Supplier",
        dataIndex: "supplier",
        key: "supplier",
        width: "5%",
        render: (text, record) => {
          return (
            <Form.Item
              name={`supplier[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: true, message: "Required." }]}
              hasFeedback
            >
              <Select
                value={record.supplier && record.supplier.id}
                placeholder="Please select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(e) => handleFieldChange(e, "supplier", record.key)}
              >
                {suppliers.length > 0 &&
                  suppliers
                    .filter((item) => item.code.includes("GP_"))
                    .map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          );
        },
      });
    }
    columns.push({
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`department[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <Select
              value={record.department && record.department.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "department", record.key)}
            >
              {departments.length > 0 &&
                departments.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "MRN No.",
      dataIndex: "mrnNumber",
      key: "mrnNumber",
      width: "5%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`mrnNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              value={text}
              onChange={(e) => handleFieldChange(e, "mrnNumber", record.key)}
              min={0}
              placeholder="MRN No"
            />
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      width: "17%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`requestTime[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <DatePicker
              value={moment(text)}
              onChange={(e) => handleFieldChange(e, "requestTime", record.key)}
              placeholder="Request date"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockTypeCode",
      width: selectedSbuCode === "GP" ? "10%" : "30%",
      render: (text, record) => {
        return (
          <Form.Item
            //name={["stockType", record.key, "id"]}
            name={`stockType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "stockType", record.key)}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: selectedSbuCode === "GP" ? "10%" : "20%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`lotNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
            >
              {lotNumbers.length > 0 &&
                lotNumbers.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Measurement",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`measurementType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              value={record.measurementType && record.measurementType.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "measurementType", record.key)
              }
            >
              {measurementTypes &&
                measurementTypes.length > 0 &&
                measurementTypes
                  .filter((item) => ["BOX", "CONE", "KG"].includes(item.name))
                  .map((t) => (
                    <Option value={t.id} key={t.id}>
                      {t.name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <Form.Item
            name={`quantity[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              value={text}
              onChange={(e) => handleFieldChange(e, "quantity", record.key)}
              min={0}
              placeholder="Box"
            />
          </Form.Item>
        );
      },
    });
    if (selectedSbuCode !== "GP") {
      columns.push({
        title: "Priority Status",
        dataIndex: "priorityStatus",
        key: "priorityStatus",
        width: "4%",
        render: (text, record) => {
          return (
            <Form.Item
              name={`priorityStatus[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: false, message: "Required." }]}
              hasFeedback
            >
              <Select
                value={text}
                placeholder="Please select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(e) =>
                  handleFieldChange(e, "priorityStatus", record.key)
                }
              >
                {PriorityStatuses.map((t) => (
                  <Option value={t.value} key={t.value}>
                    {t.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        },
      });

      columns.push({
        title: "Machine",
        dataIndex: "machine",
        key: "machine",
        width: "4%",
        render: (text, record) => {
          return (
            <Form.Item
              name={`totalItems[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: false, message: "Required." }]}
              hasFeedback
            >
              <Select
                value={record.machine && record.machine.id}
                placeholder="Please select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(e) => handleFieldChange(e, "machine", record.key)}
              >
                {machines &&
                  machines.length > 0 &&
                  machines.map((t) => (
                    <Option value={t.id} key={t.id}>
                      {t.code}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          );
        },
      });
    }
    if (selectedSbuCode === "GP") {
      columns.push({
        title: "Width",
        dataIndex: "width",
        key: "width",
        width: "10%",
        render: (text, record) => {
          return (
            <Form.Item
              name={`width[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: false, message: "Required." }]}
              hasFeedback
            >
              <Input
                value={text}
                onChange={(e) =>
                  handleFieldChange(e.target.value, "width", record.key)
                }
                min={0}
                placeholder="Width"
                allowClear
              />
            </Form.Item>
          );
        },
      });
      columns.push({
        title: "C.Code",
        dataIndex: "colorCode",
        key: "colorCode",
        width: "2%",
        render: (text, record) => {
          return (
            <Form.Item
              name={`colorCode[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: false, message: "Required." }]}
              hasFeedback
            >
              <InputNumber
                value={text}
                onChange={(e) => handleFieldChange(e, "colorCode", record.key)}
                min={0}
                placeholder="Color Code"
                allowClear
              />
            </Form.Item>
          );
        },
      });
    }
    /* columns.push({
      title: "Boxes",
      key: "totalItems",
      align: "right",
      render: (text, record) => {
        return (
          <Form.Item
            name={`totalItems[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Total Items"
              min={record._id ? record.totalItems : 0}
              onChange={(e) => handleFieldChange(e, "totalItems", record.key)}
            />
          </Form.Item>
        );
      },
    }); */
    /* columns.push({
      title: "Net Weight",
      key: "quantity",
      align: "right",
      render: (text, record) => {
        return (
          <Form.Item
            name={`quantity[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            hasFeedback
          >
            <InputNumber
              placeholder="Quantity"
              min={record._id ? record.quantity : 0}
              onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            />
          </Form.Item>
        );
      },
    }); */
    /* columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`measurementType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "measurementType", record.key)
              }
            >
              {measurementTypes.length > 0 &&
                measurementTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    }); */
    columns.push({
      title: "",
      key: "operation",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveRequestLine(record);
            }}
            icon={<CloseOutlined />}
            style={{ marginLeft: 5 }}
          ></Button>
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSaveRequestLine(e, record.key);
            }}
            style={{ padding: 5, fontSize: 12 }}
          >
            +More
          </Button>
        </Space>
      ),
    });

    return columns;
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to add the changes?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
        disabled={materialRequestLines.length === 0}
      >
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={materialRequestLines.length === 0}
        >
          Save
        </Button>
      </Popconfirm>
    );

    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests/add">New Material Request</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["store", "id"]}
                  label="Store"
                  rules={[{ required: true, message: "Store is required" }]}
                >
                  <Select placeholder="Please select" allowClear>
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["customer", "id"]}
                  label="Customer"
                  rules={[{ required: true, message: "Customer is required" }]}
                >
                  <Select
                    placeholder="Please select"
                    //onChange={getAddresses}
                  >
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="requestDate"
                  label="Request Date"
                  rules={[
                    { required: true, message: "Request date is required" },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder="Request date"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sbuId" label="SBU">
                  <Select
                    placeholder="Please select"
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    onChange={(e) => {
                      form.setFieldsValue({ zoneId: null });
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedSbuId(e);
                      const selectedSbu = businessUnits.find(
                        (item) => item.id === e
                      );
                      setSelectedSbuCode(selectedSbu.code);
                    }}
                    onClear={() => {
                      form.setFieldsValue({ zoneId: null });
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedSbuCode(null);
                    }}
                  >
                    {businessUnits.length > 0 &&
                      businessUnits.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["storeAddress", "id"]}
                  label="Delivery From"
                  rules={[
                    {
                      required: true,
                      message: "Delivery address is required",
                    },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["deliveryLocation", "id"]}
                  label="Deliver To"
                  rules={[
                    { required: true, message: "Delivery from is required" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {customerAddresses.length > 0 &&
                      customerAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactPersonName"
                  label="Contact Person Name"
                  rules={[
                    { required: true, message: "Contact persion is required" },
                  ]}
                >
                  <Input placeholder="Contact persion" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="zoneId" label="Zone">
                  <Select
                    placeholder="Please select"
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    onChange={(e) => {
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedZoneId(e);
                    }}
                    onClear={() => {
                      form.setFieldsValue({ subLocationId: null });
                    }}
                    disabled={!selectedSbuId}
                  >
                    {zones.length > 0 &&
                      zones
                        .filter((item) => item.sbu.id === selectedSbuId)
                        .map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}></Col>
              <Col span={6}>
                <Form.Item name="remark" label="Remark">
                  <TextArea />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactNumber"
                  label="Contact No:"
                  rules={[
                    { required: true, message: "Contact No. is required" },
                  ]}
                >
                  <Input placeholder="Contact number" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="subLocationId"
                  label="Sub-Location"
                  rules={[
                    {
                      required: true,
                      message: "Please select Sub-Location",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    disabled={!selectedZoneId}
                  >
                    {subLocations.length > 0 &&
                      subLocations
                        .filter((item) => item.zone.id === selectedZoneId)
                        .map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {materialRequestLines.length > 0 && (
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                size="small"
                summary={(pageData) => {
                  let coneSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let kgSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let defaultSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  const groupByPackingType = Utils.groupBy(
                    pageData,
                    (line) =>
                      line.stockType &&
                      line.stockType.packingType &&
                      line.stockType.packingType.name
                  );

                  groupByPackingType.forEach((value, key) => {
                    if (key === "CONE") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          coneSummary.totalItems += totalItems;
                          coneSummary.totalQty += quantity;
                          coneSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "BOX") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          boxSummary.totalItems += totalItems;
                          boxSummary.totalQty += quantity;
                          boxSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "KG") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          kgSummary.totalItems += totalItems;
                          kgSummary.totalQty += quantity;
                          kgSummary.measurementType = measurementType;
                        }
                      );
                    } else {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          defaultSummary.totalItems += totalItems;
                          defaultSummary.totalQty += quantity;
                          defaultSummary.measurementType = measurementType;
                        }
                      );
                    }
                  });
                  measurementTypes.forEach((item) => {
                    if (item.id === coneSummary.measurementType) {
                      coneSummary.uom = item.name;
                    }
                    if (item.id === boxSummary.measurementType) {
                      boxSummary.uom = item.name;
                    }
                    if (item.id === kgSummary.measurementType) {
                      kgSummary.uom = item.name;
                    }
                    if (item.id === defaultSummary.measurementType) {
                      defaultSummary.uom = item.name;
                    }
                  });

                  return (
                    <>
                      {coneSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - CONE</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(coneSummary.totalItems) &&
                                coneSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(coneSummary.totalQty) &&
                                coneSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{coneSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {boxSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - BOX</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxSummary.totalItems) &&
                                boxSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxSummary.totalQty) &&
                                boxSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{boxSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {kgSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - Kg</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(kgSummary.totalItems) &&
                                kgSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(kgSummary.totalQty) && kgSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{kgSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {defaultSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(defaultSummary.totalItems) &&
                                defaultSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(defaultSummary.totalQty) &&
                                defaultSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{defaultSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                    </>
                  );
                }}
              />
            )}
            {materialRequestLines.length === 0 && (
              <Button
                style={{
                  width: "100%",
                  marginTop: 16,
                  marginBottom: 8,
                  color: "white",
                  backgroundColor: "#13c2c2",
                }}
                type="dashed"
                onClick={handleAddRequestLine}
                icon={<PlusOutlined />}
              >
                Add Item
              </Button>
            )}
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmAdd;
