import {
  Button,
  Col,
  Form,
  Row,
  Input,
  message,
  Select,
  DatePicker,
} from "antd";
import React from "react";
import moment from "moment";
import { StockIssueOrderStatus } from "_helpers";
import { stockIssueService } from "_services";

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.issueNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `issueNumber=${encodeURIComponent(values.issueNumber)}`;
    }
    if (values.storeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeId=${encodeURIComponent(values.storeId)}`;
    }
    if (values.orderStage) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `orderStage=${encodeURIComponent(values.orderStage)}`;
    }
    if (values.dateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.dateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.dateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
    }
    stockIssueService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], 0, "");
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="issueNumber" label="Issue Number">
            <Input.Search placeholder="Issue Number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="dateRange" label="Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="orderStage" label="Status">
            <Select allowClear>
              {StockIssueOrderStatus.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
