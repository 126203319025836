import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Tooltip,
  message,
  Popconfirm,
  Tag,
  Space,
} from "antd";
import {
  CloseOutlined,
  ShoppingCartOutlined,
  EditOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { materialRequestService } from "_services";
import Search from "./Search";
import { Auth } from "_helpers";

const List = ({ match, history }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState("?orderStage=OPEN");

  useEffect(() => {
    //setSearchParams("?orderStage=OPEN");
    getMaterialRequests(pageNumber, pageSize);
  }, []);

  const getMaterialRequests = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    materialRequestService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getMaterialRequests(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    materialRequestService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getMaterialRequests(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onCreateDelivery = (materialRequestId) => {
    history.push(`/stock-issue/add?mrn=${materialRequestId}`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Link to={`${path}/add`} key="btn-add">
        <Button>Add</Button>
      </Link>
    );
    buttons.push(
      <Link to={`${path}/upload`} key="btn-upload">
        <Button>Upload</Button>
      </Link>
    );
    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Request No",
      dataIndex: "requestNumber",
      key: "requestNumber",
      render: (text, record) => {
        return (
          <Link to={`/material-requests/${record.id}/summary`}>{text}</Link>
        );
      },
    });
    columns.push({
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text, record) => {
        return moment(text).format("YYYY-MM-DD");
      },
      /* defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.requestDate).unix() - moment(b.requestDate).unix(), */
    });
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      columns.push({
        title: "Customer",
        key: "customer",
        render: (text, record) => {
          return record.customer.name;
        },
      });
    }
    /* columns.push({
      title: "Delivery Address",
      key: "deliveryLocation",
      render: (text, record) => {
        return record.deliveryLocation.address;
      },
    }); */
    /* columns.push({
      title: "Last Modified",
      dataIndex: "lastModifiedAt",
      key: "lastModifiedAt",
    }); */
    columns.push({
      title: "No. Lines",
      key: "numberOfLines",
      render: (text, record) => record.requestLines.length,
    });
    columns.push({
      title: "Total Items",
      key: "totalItems",
      render: (text, record) =>
        record.requestLines &&
        record.requestLines
          .map((item) => item.totalItems)
          .reduce((prev, next) => prev + next),
    });
    /* columns.push({
      title: "Total Qty",
      key: "totalQty",
      render: (text, record) =>
        record.requestLines &&
        record.requestLines
          .map((item) => item.quantity)
          .reduce((prev, next) => prev + next),
    }); */
    columns.push({
      title: "Status",
      dataIndex: "orderStage",
      key: "orderStage",
      render: (orderStatus) => {
        let color = "";
        if (orderStatus === "COMPLETE") {
          color = "#87d068";
        }
        if (orderStatus === "OPEN") {
          color = "#2db7f5";
        }
        if (["IN_PROGRESS", "ACCEPTED"].includes(orderStatus)) {
          color = "#108ee9";
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    });
    columns.push({
      title: "Deliveries",
      dataIndex: "issues",
      key: "issues",
      width: "20%",
      render: (issues) => (
        <span>
          {issues.map((issue, index) => (
            <Space key={index}>
              <Link to={`/stock-issue/${issue.id}/summary`}>
                {issue.issueNumber}
              </Link>
              <span>({moment(issue.issuedDate).format("YYYY-MM-DD")})</span>
            </Space>
          ))}
        </span>
      ),
    });
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            {["OPEN", "IN_PROGRESS"].includes(record.orderStage) &&
              Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) && (
                <Tooltip placeholder="topLeft" title="Create Delivery">
                  <Button
                    icon={<ShoppingCartOutlined />}
                    title="Create Delivery"
                    type="link"
                    style={{ marginRight: 10 }}
                    onClick={() => onCreateDelivery(record.id)}
                  ></Button>
                </Tooltip>
              )}
            {["OPEN"].includes(record.orderStage) && (
              <Button
                icon={<EditOutlined />}
                title="Edit"
                type="link"
                style={{ marginRight: 10 }}
                href={`/material-requests/${record.id}/edit`}
              ></Button>
            )}
            {record.orderStage === "OPEN" && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
