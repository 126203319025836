import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { binService, companyService } from "_services";
import { Auth } from "_helpers";

function Search(props) {
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setAddresses(storeLocationAddresses);
          form.setFieldsValue({
            storeId: x.content[0].id,
            storeLocationId: storeLocationAddresses[0].id,
          });
          if (storeLocationAddresses.length === 1) {
            onFinish({});
          }
        });
      }
      setCompanies(x.content);
    });
  }, []);

  /* const getCompanyAddresses = (companyId) => {
    if (companyId) {
      setAddressLoading(true);
      addressService.getAllByCompany(companyId).then((x) => {
        setAddresses(x.content);
        setAddressLoading(false);
      });
    } else {
      form.resetFields(["storeLocationId"]);
      setAddresses([]);
    }
  }; */

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "?isParent=1";
    /* if (values.storeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeId=${encodeURIComponent(values.storeId)}`;
    } */
    if (values.storeLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeLocationId=${encodeURIComponent(
        values.storeLocationId
      )}`;
    }
    if (values.code) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    binService
      .getSuggestion(searchParams)
      .then((data) => {
        if (storeBins.length === 0) {
          setStoreBins(data.content);
        }
        props.onSearchResults(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  const handleStoreLocationChange = () => {
    setStoreBins([]);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        {/* <Col span={6}>
          <Form.Item name="storeId" label="Store">
            <Select
              placeholder="Please select"
              //onChange={getCompanyAddresses}
              allowClear
            >
              {companies.length > 0 &&
                companies.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <Form.Item name="storeLocationId" label="Store Location">
            <Select
              placeholder="Please select"
              allowClear
              onChange={handleStoreLocationChange}
            >
              {addresses.length > 0 &&
                addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          {storeBins.length > 0 && (
            <Form.Item name="code" label="Bin">
              <Select
                placeholder="Please select"
                allowClear
                optionFilterProp="children"
                showSearch
              >
                {storeBins.length > 0 &&
                  storeBins.map((t) => (
                    <Select.Option value={t.code} key={t.id}>
                      {t.code}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col span={6}></Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                //form.resetFields();
                form.resetFields(["code"]);
                onFinish(form.getFieldsValue());
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
