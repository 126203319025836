import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Spin,
  Typography,
  Tag,
  Table,
  Divider,
  Space,
  Badge,
  Popconfirm,
} from "antd";
import { ScanOutlined } from "@ant-design/icons";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import { stockIssueService } from "_services";

function StrRmBulkLoading({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [order, setOrder] = useState({});
  const [error, setError] = useState(null);
  const [orderLines, setOrderLines] = useState([]);
  const [assignedStocks, setAssignedStocks] = useState([]);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [scannedBarcodeResults, setScannedBarcodeResults] = useState([]);
  const [previouslyScannedBarcodes, setPreviouslyScannedBarcodes] = useState(
    []
  );
  const [loadingScanUpdate, setLoadingScanUpdate] = useState(false);
  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const orderData = await stockIssueService.getById(id);

      if (orderData) {
        let order = orderData.content;
        if (!["LOADING"].includes(order.orderStage)) {
          history.push(`/stock-issue/${id}/summary`);
        }

        order.issuedLines.forEach((line, i) => {
          line.key = line.id;

          for (const item of line.issuedItems) {
            if (item.mode === "ISSUE_SCANNED") {
              previouslyScannedBarcodes.push(item.serialNo.toLowerCase());
            }
          }
        });
        setOrder(order);
        setOrderLines([].concat(order.issuedLines));
        onFill(order);
        const dispatchItems = order.issuedLines.flatMap(
          (item) => item.issuedItems
        );
        setAssignedStocks([...assignedStocks, ...dispatchItems]);
        setLoading(false);
      }
    };

    fetchAlldatas();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleScanUpdate = () => {
    setLoadingScanUpdate(true);
    const scanUpdatePayload = {
      serialNumbers: scannedBarcodes,
    };
    stockIssueService
      .updateScannedItems(order.id, scanUpdatePayload)
      .then((data) => {
        message.success("Verified!");
        setLoadingScanUpdate(false);
        setScannedBarcodes([]);
        setOrderLines([].concat(data.content.issuedLines));
        setScannedBarcodeResults([
          ...scannedBarcodeResults,
          ...data.content.scanResults,
        ]);
      })
      .catch((error) => {
        setLoadingScanUpdate(false);
        setError(error);
      });
  };

  const handleStatusUpdate = () => {
    setLoadingStatusUpdate(true);
    const statusUpdatePayload = {
      requestStatus: "READY",
    };
    stockIssueService
      .updateStatus(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingStatusUpdate(false);
        history.push(`/stock-issue/${id}/ready`);
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const handleItemScan = (barcode) => {
    if (barcode) {
      if (
        scannedBarcodes.includes(barcode.toLowerCase()) ||
        scannedBarcodeResults
          .map((item) => item.serialNumber.toLowerCase())
          .includes(barcode) ||
        previouslyScannedBarcodes.includes(barcode.toLowerCase())
      ) {
        form.setFieldsValue({ itemScan: "" });
        return message.error("Barcode is already scanned!");
      }
      if (scannedBarcodes.length > 49) {
        form.setFieldsValue({ itemScan: "" });
        return message.info(
          `You have exceed the maximum scan records(${scannedBarcodes.length}). Please update to proceed further.`
        );
      }

      const matchingStock = assignedStocks.find(
        (item) => item.serialNo.toLowerCase() === barcode.toLowerCase()
      );
      if (matchingStock) {
        setScannedBarcodes([...scannedBarcodes, barcode.toLowerCase()]);
      } else {
        message.error(
          "Sorry, No item found against the barcode. Please check the ready items on request line."
        );
      }
      form.setFieldsValue({ itemScan: "" });
    } else {
      form.setFieldsValue({ itemScan: "" });
      message.error("Please enter valid barcode.");
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    let statusButtonText = "Complete Loading";
    buttons.push(
      <Popconfirm
        title={`Are you sure to ${statusButtonText} it?`}
        onConfirm={handleStatusUpdate}
        key="con-complete"
        disabled={
          order.subLocation?.zone?.sbu?.code !== "GP" &&
          orderLines.flatMap((item) => item.issuedItems).length >
            orderLines
              .flatMap((item) => item.issuedItems)
              .filter((item) => item.mode === "ISSUE_SCANNED").length
        }
      >
        <Button
          key="btn-complete"
          type="primary"
          htmlType="button"
          loading={loadingStatusUpdate}
          disabled={
            order.subLocation?.zone?.sbu?.code !== "GP" &&
            orderLines.flatMap((item) => item.issuedItems).length >
              orderLines
                .flatMap((item) => item.issuedItems)
                .filter((item) => item.mode === "ISSUE_SCANNED").length
          }
        >
          {statusButtonText}
        </Button>
      </Popconfirm>
    );
    if (order.orderStage === "LOADING") {
      buttons.push(
        <Button
          key="pdf"
          onClick={handleScanUpdate}
          loading={loadingScanUpdate}
          disabled={
            orderLines.flatMap((item) => item.issuedItems).length ===
            orderLines
              .flatMap((item) => item.issuedItems)
              .filter((item) => item.mode === "ISSUE_SCANNED").length
          }
        >
          Update Scan
        </Button>
      );
    }
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getLineColumns = () => {
    let lineColumns = [];
    lineColumns.push({
      title: "Request No.",
      key: "requestNumber",
      render: (text, record) => {
        return record.requestLine.issueRequest.requestNumber;
      },
    });
    lineColumns.push({
      title: "MRN No.",
      key: "mrnNumber",
      render: (text, record) => {
        return record.requestLine.mrnNumber;
      },
    });
    lineColumns.push({
      title: "Req. Time",
      key: "requestTime",
      render: (text, record) => {
        return record.requestLine.requestTime;
      },
    });
    lineColumns.push({
      title: "Material",
      key: "stockTypeCode",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    lineColumns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    lineColumns.push({
      title: "Ready Boxes",
      dataIndex: "totalReadyItems",
      key: "totalReadyItems",
      align: "right",
    });
    lineColumns.push({
      title: "Ready Net.W",
      dataIndex: "totalReadyNetWeight",
      key: "totalReadyNetWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    lineColumns.push({
      title: "Scan Boxes",
      dataIndex: "totalScannedItems",
      key: "totalScannedItems",
      align: "right",
    });
    lineColumns.push({
      title: "Scan Net.W",
      dataIndex: "totalScannedNetWeight",
      key: "totalScannedNetWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });

    return lineColumns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {order.issueNumber} ::{" "}
                <Tag
                  color={`${
                    order.orderStage === "OPEN"
                      ? "#2db7f5"
                      : order.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {order.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="validate" id={id} status={order.orderStage} />
          <Spin tip="Loading..." spinning={loading}>
            {orderLines.length > 0 && (
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={orderLines}
                style={{ paddingBottom: 20 }}
                rowClassName={(record) =>
                  record.totalReadyNetWeight === record.totalScannedNetWeight
                    ? "ant-tag-green"
                    : record.totalScannedNetWeight > 0
                    ? "ant-tag-blue"
                    : ""
                }
                size="small"
                summary={(pageData) => {
                  let readyItems = 0;
                  let readyNetWeight = 0;
                  let scannedItems = 0;
                  let scannedNetWeight = 0;

                  pageData.forEach(
                    ({
                      totalReadyItems,
                      totalReadyNetWeight,
                      totalScannedItems,
                      totalScannedNetWeight,
                    }) => {
                      readyItems += totalReadyItems;
                      readyNetWeight += totalReadyNetWeight;
                      scannedItems += totalScannedItems;
                      scannedNetWeight += totalScannedNetWeight;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{!isNaN(readyItems) && readyItems}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(readyNetWeight) &&
                              Number(readyNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(scannedItems) && scannedItems}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(scannedNetWeight) &&
                              Number(scannedNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={23}>
                  <Form.Item name="itemScan">
                    <Input.Search
                      placeholder="Enter valid barcode"
                      allowClear
                      enterButton={<ScanOutlined />}
                      onSearch={handleItemScan}
                      style={{ marginBottom: 20 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Space>
                    <Badge
                      count={scannedBarcodes.length}
                      overflowCount={1000}
                      showZero
                      color="#faad14"
                    />
                  </Space>
                </Col>
              </Row>
            </Form>
            <Space size={[8, 16]} wrap>
              {scannedBarcodes.map((barcode, index) => (
                <Button key={index}>{barcode}</Button>
              ))}
            </Space>
            {scannedBarcodeResults.length > 0 && (
              <div>
                <Divider orientation="left">Scan Results</Divider>
                <Space size={[8, 16]} wrap>
                  {scannedBarcodeResults.map((item, index) => (
                    <Button
                      key={item.serialNumber}
                      style={{
                        background: item.verified ? "#87d068" : "#ff4d4f",
                      }}
                    >
                      {item.serialNumber}
                    </Button>
                  ))}
                </Space>
              </div>
            )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmBulkLoading;
