import { Button, Col, Form, Row, message, Select, DatePicker } from "antd";
import React from "react";
import { reportService } from "_services";
import { Auth } from "_helpers";

function BinWiseBalanceSearch(props) {
    const [form] = Form.useForm();
    const loggedInCustomerCode = Auth.getProfile().comp_code;

    const onFinish = (values) => {
        props.showSearchLoading(true);
        let searchParams = "";
        searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
        if (values.materialType) {
            searchParams += `&materialType=${values.materialType}`;
        }
        if (values.lotNumber) {
            searchParams += `&lotNumber=${values.lotNumber}`;
        }

        reportService
            .getBinWiseBalance(searchParams)
            .then((data) => {
                props.onSearchResults(data.content);
            })
            .catch((error) => {
                message.error(`${error}`);
                props.onSearchResults([]);
            });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="search-form"
        >
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="materialType" label="Material Type">
                        <Select
                            showSearch
                            allowClear
                            showArrow={false}
                            optionFilterProp="children"
                            placeholder="Material Type"
                        >
                            {props.stockTypes.map((t) => (
                                <Select.Option value={t.id} key={t.id}>
                                    {t.code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="lotNumber"
                        label={`${["SILUETA"].includes(loggedInCustomerCode)
                            ? "Vendor Batch"
                            : "Lot No"
                            }`}>
                        <Select
                            showSearch
                            allowClear
                            showArrow={false}
                            optionFilterProp="children"
                            placeholder={`${["SILUETA"].includes(loggedInCustomerCode)
                                ? "Vendor Batch"
                                : "Lot No"
                                }`}
                        >
                            {props.lotNumberList.map((t) => (
                                <Select.Option value={t.id} key={t.id}>
                                    {t.number}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>

                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                        <Button type="primary" htmlType="submit">
                            Search
                        </Button>
                        <Button
                            style={{ margin: "0 8px" }}
                            onClick={() => {
                                form.resetFields();
                                onFinish({});
                            }}
                        >
                            Clear
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default BinWiseBalanceSearch;
