import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React from "react";
import { supplierService } from "_services";

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.code) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.name) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    supplierService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="code" label="Supplier code">
            <Input placeholder="Code" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="Supplier name">
            <Input placeholder="Supplier name" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}></Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
