import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Spin,
  Form,
  Row,
  Col,
  Popconfirm,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { binService, companyService } from "_services";
import TabMenu from "./TabMenu";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [bin, setBin] = useState({});
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [stockBins, setStockBins] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    binService
      .getById(id)
      .then((x) => {
        setLoading(false);
        let selectedBin = x.content;
        setBin(selectedBin);
        onFill(selectedBin);

        binService
          .getSuggestion(
            `?isParent=1&storeLocationId=${selectedBin.storeLocation.id}`
          )
          .then((data) => {
            setStockBins(
              data.content.filter((item) => item.id !== selectedBin.id)
            );
          });
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().company_id
          ? Auth.getProfile().company_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const warehouseLocations = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setAddresses(warehouseLocations);
        });
        /* addressService.getAllByCompany(companyId).then((y) => {
          setAddresses(y.content);
        }); */
      }
      setCompanies(x.content);
    });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        // if (!values.parent) {
        //   delete values.parent;
        // }
        //console.log("Bin Update:", values);
        setLoadingUpdate(true);
        values.parent = values.parent && values.parent.id ? values.parent : null;
        // console.log("Bin Update:", values);
        binService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setLoadingUpdate(false);
            history.push(`${path}`.replace(":id", id));
          })
          .catch((error) => {
            setLoadingUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/bins");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Popconfirm
          key="update-confirm"
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
        >
          <Button type="primary" htmlType="submit" loading={loadingUpdate}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="canclel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/bins">Bin Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>Bin: {bin.code}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu="summary" id={id} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              form={form}
              name="form-create"
              layout="vertical"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="code"
                    label="Code"
                    rules={[{ required: true, message: "Code is required" }]}
                  >
                    <Input
                      disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["store", "id"]} label="Store">
                    <Select disabled>
                      {companies.length > 0 &&
                        companies.map((t) => (
                          <Select.Option
                            value={t.id}
                            key={t.id}
                            disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
                          >
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["parent", "id"]} label="Parent Bin">
                    <Select
                      placeholder="Please select"
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                      disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
                    >
                      {stockBins.length > 0 &&
                        stockBins.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: "Name is required" }]}
                  >
                    <Input
                      disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["storeLocation", "id"]} label="Location">
                    <Select disabled>
                      {addresses.length > 0 &&
                        addresses.map((t) => (
                          <Select.Option
                            value={t.id}
                            key={t.id}
                            disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
                          >
                            {t.address}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name="allowedStockQuantity" label="Allowed Quantity">
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={16}></Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
