import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/bins`;

export const binService = {
  getAll,
  getSuggestion,
  getParentById,
  addSubBins,
  removeParent,
  printBarcode,
  downloadExcel,
  addItems,
  getStockItems,
  search,
  searchWithPaging,
  getById,
  create,
  update,
  delete: _delete,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function searchWithPaging(params) {
  return fetchWrapper.get(`${baseUrl}/paging${params}`);
}

function getSuggestion(params) {
  return fetchWrapper.get(`${baseUrl}/suggestion${params}`);
}

function getParentById(parentId) {
  return fetchWrapper.get(`${baseUrl}/parent/${parentId}`);
}

function getStockItems(binId, params) {
  return fetchWrapper.get(`${baseUrl}/${binId}/items${params}`);
}

function addSubBins(parentBinId, params) {
  return fetchWrapper.put(`${baseUrl}/parent/${parentBinId}/sub-bins`, params);
}

function removeParent(parentBinId, binId) {
  return fetchWrapper.delete(
    `${baseUrl}/parent/${parentBinId}/sub-bins/${binId}`
  );
}

function printBarcode(params) {
  return fetchWrapper.get(`${baseUrl}/printBarcode${params}`, {
    responseType: "blob",
  });
}

function downloadExcel(params) {
  return fetchWrapper.get(`${baseUrl}/excel${params}`, {
    responseType: "blob",
  });
}

function addItems(params) {
  return fetchWrapper.post(`${baseUrl}/assign`, params);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
