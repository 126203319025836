import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Spin,
  Form,
  Row,
  Col,
  Popconfirm,
  Checkbox,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { Auth } from '_helpers';
import { binLocationService } from '_services';
import TabMenu from './TabMenu';

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [binLocation, setBinLocation] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    binLocationService
      .getById(id)
      .then((x) => {
        setLoading(false);
        let selectedBinLocation = x.content;
        setBinLocation(selectedBinLocation);
        onFill(selectedBinLocation);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        //console.log("Bin Update:", values);
        setLoadingUpdate(true);
        values.parent =
          values.parent && values.parent.id ? values.parent : null;
        // console.log("Bin Update:", values);
        binLocationService
          .update(id, values)
          .then(() => {
            message.success('Successfully updated!');
            setLoadingUpdate(false);
            history.push(`${path}`.replace(':id', id));
          })
          .catch((error) => {
            setLoadingUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    // history.push('/bins');
    history.push('..');
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Popconfirm
          key='update-confirm'
          title='Are you sure to update the changes?'
          onConfirm={handleFormSumbit}
        >
          <Button type='primary' htmlType='submit' loading={loadingUpdate}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key='canclel' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/bin-locations'>Bin Location Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(':id', id)}>
                Bin Location: {binLocation.name} ({binLocation.availableStockQuantity})
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu='summary' id={id} />
          <Spin tip='Loading...' spinning={loading}>
            <Form
              form={form}
              name='form-create'
              layout='vertical'
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name='name'
                    label='Location Name'
                    rules={[{ required: true, message: 'Name is required' }]}
                  >
                    <Input
                      disabled={
                        !Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {/* <Form.Item
                    name='availableForReporting'
                    label='Available for reporting'
                    valuePropName='checked'
                  >
                    <Checkbox
                      disabled={
                        !Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])
                      }
                    />
                  </Form.Item> */}
                </Col>
                <Col span={8}></Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
