import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/dashboard`;

export const dashboardService = {
  getSbusWithDashboard,
};

function getSbusWithDashboard() {
  return fetchWrapper.get(`${baseUrl}`);
}
