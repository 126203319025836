import React from "react";
import { Route, Switch } from "react-router-dom";
import Summary from "./Summary";

function StockReceipt({ match }) {
  const { path } = match;
  const { id } = match.params;
  return (
    <Switch>
      <Route exact path={path} component={Summary} />
      {/* <Route path={`${path}/${id}`} component={Summary} /> */}
    </Switch>
  );
}

export { StockReceipt };
