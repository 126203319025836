import { Table, Tag, Tooltip, Input, Button, Space } from "antd";
import React, { useState, useRef } from "react";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Auth } from "_helpers";
import ExportExcel from "_helpers/ExportExcel";

const PrymStockDetails = (props) => {
  //console.log("Stocks:", props.stocks);
  //console.log("CustomerCode:", props.customerCode);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //const searchInput = useRef<Input>(null);
  const searchInput = useRef(null);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? dataIndex === "runningNo"
          ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
          : record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            searchInput && searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handlePrintExel = () => {
    const columns = [
      {
        title: "Material Type",
        dataIndex: "stockTypeCode",
      },
      {
        title: "Description",
        dataIndex: "stockTypeName",
      },
    ];
    const data = props.stocks.map((item) => {
      const container = {};
      container["stockTypeCode"] = item.stockType.code;
      container["stockTypeName"] = item.stockType.name;

      return container;
    });
    ExportExcel(
      columns,
      data,
      `${"Stock Bin Report - "}${props.stocks[0].stockBin.code}`,
      `${props.stocks[0].stockBin.code}`
    );
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "8%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "6%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (
      props.customerCode &&
      ["PRYM", "SILUETA"].includes(props.customerCode)
    ) {
      columns.push({
        title: "Roll/Box No",
        dataIndex: "runningNo",
        key: "runningNo",
        width: `${["PRYM"].includes(props.customerCode) ? "4%" : "6%"}`,
        //defaultSortOrder: "descend",
        //sorter: (a, b) => parseInt(a.runningNo) < parseInt(b.runningNo),
        ...getColumnSearchProps("runningNo"),
      });
    }
    columns.push({
      title: "Invoice No:",
      key: "invoiceNumber",
      width: "8%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber
        );
      },
    });
    columns.push({
      title: "Invoice Date",
      //dataIndex: "createdAt",
      key: "orderDate",
      width: "8%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(record.stockPreReceiptLine.stockPreReceipt.orderDate).format(
            "YYYY-MM-DD"
          )
        );
      },
      /* defaultSortOrder: "ascend",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(), */
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "6%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    if (props.customerCode && ["STR-RM"].includes(props.customerCode)) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        width: "5%",
      });
    }
    if (props.customerCode && ["PRYM"].includes(props.customerCode)) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        width: "5%",
      });
    }
    columns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
      ...getColumnSearchProps("netWeight"),
    });
    columns.push({
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
      ...getColumnSearchProps("grossWeight"),
    });
    if (props.customerCode && ["PRYM"].includes(props.customerCode)) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        width: "5%",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
        ...getColumnSearchProps("grossValueSqm"),
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    columns.push({
      title: "Due Days",
      key: "dueDays",
      width: "3%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(moment()).diff(
            record.stockPreReceiptLine.stockPreReceipt.orderDate,
            "days"
          )
        );
      },
    });
    /* const columns = [
      {
        title: "Pack Serial",
        dataIndex: "packSerialNo",
        key: "packSerialNo",
      },
    ]; */
    return columns;
  };

  return (
    <div>
      <Table
        bordered
        rowKey="id"
        size="small"
        columns={getColumns()}
        dataSource={props.stocks}
        pagination={{
          showTotal: (total) => `Total ${total} items`,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
        }}
        footer={() => {
          return (
            <div>
              <Button
                type="primary"
                onClick={handlePrintExel}
                style={{
                  marginBottom: 20,
                }}
                icon={<FileExcelOutlined />}
              >
                Export to Excel
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default PrymStockDetails;
