import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.dark.css';
import LoginLayoutRoute from './layouts/LoginLayoutRoute';
import AppLayoutRoute from './layouts/AppLayoutRoute'; 
import './App.css';
import { Homes } from 'pages/home/Index';
import Login from 'pages/login/Index';  
import { Companies } from 'pages/companies/Index';
import { People } from 'pages/people/Index';
import { Roles } from 'pages/roles/Index';
import { StockClassifications } from 'pages/stock-classifications/Index';
import { PackingTypes } from 'pages/packing-types/Index';
import { MeasurementTypes } from 'pages/measurement-types/Index';
import { Bins } from 'pages/bins/Index';
import { BinLocations } from 'pages/bin-locations/Index';
import { LotNumbers } from 'pages/lotnumbers/Index';
import { MaterialTypes } from 'pages/material-types/Index';
import { MaterialDetails } from 'pages/material-details/Index';
import { MaterialRequests } from 'pages/material-requests/Index';
import { MaterialRequestLines } from "pages/material-request-lines/Index";
import { PurchaseOrders } from 'pages/purchase-orders/Index';
import { StockReceipt } from 'pages/stock-receipt/Index';
import { StockIssue } from 'pages/stock-issue/Index';
import { Stores } from 'pages/stores/Index';
import { Reports } from 'pages/reports/Index';
import { Account } from 'pages/account/Index';
import { StockTransfer } from 'pages/stock-transfer/Index';
import { StockCount } from 'pages/stock-count/Index';
import { StockCheck } from 'pages/stock-check/Index';
import { Suppliers } from 'pages/suppliers/Index';
import { Departments } from 'pages/departments/Index';
import { Machines } from 'pages/machines/Index';
import { Vehicles } from 'pages/vehicles/Index';
import { Sbu } from 'pages/sbu/Index';
import { Zones } from 'pages/zones/Index';
import { SubLocations } from 'pages/sub-locations/Index';
import { Compare } from 'pages/compare/Index';
import { Reason } from 'pages/reason/Index';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/dashboard' />
        </Route>

        <LoginLayoutRoute path='/login' component={Login} />

        <AppLayoutRoute path='/dashboard' component={Homes} />
        <AppLayoutRoute path='/companies' component={Companies} />
        <AppLayoutRoute path='/people' component={People} />
        <AppLayoutRoute path='/roles' component={Roles} />
        <AppLayoutRoute path='/suppliers' component={Suppliers} />
        <AppLayoutRoute path='/departments' component={Departments} />
        <AppLayoutRoute path='/machines' component={Machines} />
        <AppLayoutRoute path='/vehicles' component={Vehicles} />
        <AppLayoutRoute path='/sbu' component={Sbu} />
        <AppLayoutRoute path='/zones' component={Zones} />
        <AppLayoutRoute path='/sub-locations' component={SubLocations} />
        <AppLayoutRoute
          path='/stock-classifications'
          component={StockClassifications}
        />
        <AppLayoutRoute path='/packing-types' component={PackingTypes} />
        <AppLayoutRoute
          path='/measurement-types'
          component={MeasurementTypes}
        />
        <AppLayoutRoute path='/reasons' component={Reason} />
        <AppLayoutRoute path='/bins' component={Bins} />
        <AppLayoutRoute path='/bin-locations' component={BinLocations} />
        <AppLayoutRoute path='/lotnumbers' component={LotNumbers} />
        <AppLayoutRoute path='/material-types' component={MaterialTypes} />
        <AppLayoutRoute path='/material-details' component={MaterialDetails} />
        <AppLayoutRoute
          path='/material-requests'
          component={MaterialRequests}
        />
        <AppLayoutRoute
          path='/material-request-lines'
          component={MaterialRequestLines}
        />
        <AppLayoutRoute path='/packing-lists' component={PurchaseOrders} />
        <AppLayoutRoute path='/stock-receipt/:id' component={StockReceipt} />
        <AppLayoutRoute path='/stock-issue' component={StockIssue} />
        <AppLayoutRoute path='/stores' component={Stores} />
        <AppLayoutRoute path='/stock-transfer' component={StockTransfer} />
        <AppLayoutRoute path='/stock-count' component={StockCount} />
        <AppLayoutRoute path='/stock-check' component={StockCheck} />
        <AppLayoutRoute path='/reports' component={Reports} />
        <AppLayoutRoute path='/compare' component={Compare} />

        <AppLayoutRoute path='/account' component={Account} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
