import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, PageHeader, Popconfirm, Table } from "antd";
import Page from "_components/Page";
import TabMenu from "../TabMenu";
import { Auth } from "_helpers";
import { binService } from "_services";

const Add = ({ match, history }) => {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [bin, setBin] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setLoading(true);

    binService
      .getById(id)
      .then((data) => {
        const parentBin = data.content;
        setBin(data.content);

        let searchParams = `?isParent=1&storeId=${parentBin.store.id}&storeLocationId=${parentBin.storeLocation.id}`;
        binService
          .search(searchParams)
          .then((data) => {
            setItems(data.content.filter((bin) => bin.id !== id));
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const addSubBins = () => {
    const subBinList = selectedRowKeys.map((item) => ({
      id: item,
    }));
    const payload = { subBins: subBinList };
    // console.log("Add Subbins: ", JSON.stringify(payload));
    setUpdating(true);
    binService
      .addSubBins(id, payload)
      .then((data) => {
        setUpdating(false);
        history.push(".");
      })
      .catch((error) => {
        setError(error);
        setUpdating(false);
      });
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.parent !== null || record.id === id,
    }),
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Available Stocks",
      dataIndex: "availableStockQuantity",
      key: "availableStockQuantity",
    },
  ];

  const getAdditionalButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Popconfirm
          key="update-confirm"
          title="Are you sure to update the changes?"
          onConfirm={addSubBins}
        >
          <Button
            key="add"
            type="primary"
            htmlType="submit"
            disabled={selectedRowKeys.length === 0}
            loading={updating}
          >
            Add to {bin.code}
          </Button>
        </Popconfirm>
      );
    }
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/bins">
              <span>Bin Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bins/${bin.id}/summary`}>
              <span>Bin: {bin.code}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bins/${bin.id}/sub-bins`}>
              <span>Sub Bins</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Add Sub Bins</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="sub-bins" id={id} />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={items}
            rowSelection={rowSelection}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default Add;
