import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { lotNumberService, materialTypeService } from "_services";
import TabMenu from "./TabMenu";

function Transfer({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [stockType, setStockType] = useState({});
  const [lotNumbers, setLotNumbers] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getStockTypes();
    getLotNumbers();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumbers(x.content);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        const transferTo = stockTypes.find(
          (item) => item.id === values.transferToStockType
        );
        materialTypeService
          .transfer(id, {
            transferId: transferTo.id,
            lotNumberId: values.lotNumberId,
          })
          .then(() => {
            message.success("Successfully transfered!");
            setIsLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/material-types");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={handleFormSumbit}
        >
          Transfer
        </Button>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  useEffect(() => {
    setIsLoading(true);
    materialTypeService
      .getById(id)
      .then((x) => {
        setIsLoading(false);
        setStockType(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        setError(error);
      });
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-types">Material Type Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>Material Type : {stockType.code}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="transfer" id={id} />
          <Form
            form={form}
            name="form-transfer"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="lotNumberId"
                  label="Lot Number"
                  rules={[
                    { required: true, message: "Lot Number is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Material Type"
                  >
                    {lotNumbers.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="transferToStockType"
                  label="Transfer To:"
                  rules={[
                    { required: true, message: "Transfer To is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear={true}
                    showArrow={false}
                    placeholder="Transfer to Material Type"
                  >
                    {stockTypes.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Transfer;
