import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React from "react";
import { CompanyTypes } from "_helpers";
import { companyService } from "_services";

function CompanySearch(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.code) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.name) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    if (values.companyType) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `companyType=${encodeURIComponent(values.companyType)}`;
    }
    companyService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" className="search-form">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="code" label="Code">
            <Input placeholder="Code" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="Name">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="companyType" label="Type">
            <Select placeholder="Please select">
              {CompanyTypes.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default CompanySearch;
