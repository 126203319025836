import React from "react";
import { Route, Switch } from "react-router-dom";
import Add from "./Add";
import List from "./List";
import Summary from "./Summary";
import { Addresses } from "./addresses/Index";
import { StoreAddresses } from "./store-addresses/Index";

function Companies({ match }) {
  const { path } = match;
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/add`} component={Add} />
      <Route path={`${path}/:id/summary`} component={Summary} />
      <Route path={`${path}/:id/addresses`} component={Addresses} />
      <Route path={`${path}/:id/store-addresses`} component={StoreAddresses} />
    </Switch>
  );
}

export { Companies };
