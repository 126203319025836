import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tooltip,
  Tag,
  Tabs,
  Spin,
  Popconfirm,
  Space,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  materialDetailService,
  measurementTypeService,
} from "_services";
import { CloseOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";
import TableForm from "./components/TableForm";

let lineIndex = 0;

function Edit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequest, setMaterialRequest] = useState({});
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const materialRequestData = await materialRequestService.getById(id);

      if (materialRequestData) {
        let materialRequest = materialRequestData.content;
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = i;
        });
        const mrCustomer = await companyService.getById(
          materialRequest.customer.id
        );
        const officeAddresses = mrCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );

        //console.log("MR:", materialRequest);
        setMaterialRequest(materialRequest);
        setAddresses(officeAddresses);
        onFill(materialRequest);
      }

      setCustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setLoading(false);
    };

    fetchAlldatas();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const validateRequestLines = (lines) => {
    let validLines = true;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (
          !line.stockType ||
          !line.lotNumber ||
          !line.quantity ||
          !line.measurementType
        ) {
          validLines = false;
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateRequestLines(values.requestLines)) {
          setUpdating(true);

          values.requestDate = moment(values.requestDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          values.requestLines.forEach((line) => {
            delete line.requestedItems;
            delete line.key;
          });

          //console.log("MR: ", values);
          //console.log("MR: ", JSON.stringify(values));

          materialRequestService
            .update(materialRequest.id, values)
            .then(() => {
              setUpdating(false);
              message.success("Successfully updated!");
              //history.push(`${path}`.replace(":id", id));
              window.location.reload();
            })
            .catch((error) => {
              setUpdating(false);
              setError(error);
            });
        } else {
          message.error(
            "Please complete all the request lines with valid details"
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const onCreateDelivery = () => {
    history.push(`/stock-issue/add?mrn=${id}`);
  };

  const onAcceptRequest = () => {
    setAccepting(true);
    const payload = {
      orderStage: "ACCEPTED",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setAccepting(false);
        message.success("Successfully accepted!");
        history.push("/material-requests");
      })
      .catch((error) => {
        setAccepting(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (
      ["SILUETA"].includes(loggedInCustomerCode) &&
      ["OPEN"].includes(materialRequest && materialRequest.orderStage)
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to modify the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={
            materialRequest.requestLines &&
            materialRequest.requestLines.length === 0
          }
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={updating}
            disabled={
              materialRequest.requestLines &&
              materialRequest.requestLines.length === 0
            }
          >
            Update
          </Button>
        </Popconfirm>
      );
    } else if (
      !["SILUETA"].includes(loggedInCustomerCode) &&
      ["OPEN"].includes(materialRequest && materialRequest.orderStage)
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to add the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={
            materialRequest &&
            materialRequest.requestLines.flatMap((item) => item.requestedItems)
              .length === 0
          }
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={
              materialRequest &&
              materialRequest.requestLines.flatMap(
                (item) => item.requestedItems
              ).length === 0
            }
          >
            Update
          </Button>
        </Popconfirm>
      );
    }
    if (["OPEN"].includes(materialRequest && materialRequest.orderStage)) {
      buttons.push(
        <Button
          key="accepted"
          htmlType="button"
          type="primary"
          loading={accepting}
          onClick={onAcceptRequest}
        >
          Accept
        </Button>
      );
    }
    buttons.push(
      <Button
        key="delivery"
        htmlType="button"
        type="primary"
        onClick={onCreateDelivery}
      >
        Create Delivery
      </Button>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag
                  color={`${
                    materialRequest.orderStage === "OPEN"
                      ? "#2db7f5"
                      : ["IN_PROGRESS", "ACCEPTED"].includes(
                          materialRequest.orderStage
                        )
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      //onChange={getAddresses}
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person Name"
                    rules={[
                      {
                        required: true,
                        message: "Contact persion is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact persion" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[
                      { required: true, message: "Request date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Request date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="requestNumber" label="Request No">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["deliveryLocation", "id"]}
                    label="Deliver To"
                    rules={[
                      { required: true, message: "Delivery from is required" },
                    ]}
                  >
                    <Select placeholder="Please select">
                      {addresses.length > 0 &&
                        addresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact No:"
                    rules={[
                      { required: true, message: "Contact No. is required" },
                    ]}
                  >
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>

              {materialRequest.orderStage === "OPEN" &&
                materialTypes.length > 0 &&
                form.getFieldValue("requestLines") && (
                  <Form.Item name="requestLines">
                    <TableForm
                      materialTypes={materialTypes}
                      lotNumbers={lotNumbers}
                      measurementTypes={measurementTypes}
                    />
                  </Form.Item>
                )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Edit;
