import {
    Button,
    DatePicker,
    Form,
    PageHeader,
    Row,
    Select,
    Typography,
    Breadcrumb,
    Popconfirm,
    Col,
    Input,
    message,
    Spin,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import { CSVLink } from "react-csv";
  import moment from "moment";
  import Page from "_components/Page";
  import { Auth } from "_helpers";
  import { companyService, extraService } from "_services";
  
  const Upload = ({ history }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);
    const [storeAddresses, setStoreAddresses] = useState([]);
    const [customers, setcustomers] = useState([]);
    const [customerAddresses, setcustomerAddresses] = useState([]);
    const [selectedcustomer, setSelectedcustomer] = useState(null);
    const [stores, setStores] = useState([]);
    const [selectedOrderDate, setSelectedOrderDate] = useState(Date());
    const [base64File, setBase64File] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [error, setError] = useState(null);
  
    const [form] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;
    const loggedInCustomerCode = Auth.getProfile().comp_code;
    const isLoggedInCustomer = () => {
      return Auth.hasRole(Auth.getProfile(), ["CAU"]);
    };
  
    useEffect(() => {
      setIsLoading(true);
      companyService.getSuggestion("?companyType=STORE").then((x) => {
        if (x.content.length === 1) {
          setSelectedStore(x.content[0]);
          form.setFieldsValue({
            store: x.content[0],
          });
        }
        setStores(x.content);
        setIsLoading(false);
      });
  
      let companyId = Auth.getProfile().comp_id;
      setIsLoading(true);
      companyService.getById(companyId).then((data) => {
        const grantedStoreAddresses = data.content.addresses.filter(
          (item) => item.addressType === "WAREHOUSE"
        );
        const officeAddresses = data.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );
        form.setFieldsValue({
          storeAddress:
            grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
          customer: data.content,
          customerAddress: officeAddresses[0],
        });
        setStoreAddresses(grantedStoreAddresses);
        setcustomers([data.content]);
        setcustomerAddresses(officeAddresses);
        setSelectedcustomer(data.content);
        setIsLoading(false);
      });
    }, []);
  
    const getStoreAddresses = (companyId) => {
      form.resetFields(["storeAddress"]);
  
      companyService.getById(companyId).then((x) => {
        setSelectedStore(x.content);
        setStoreAddresses(
          x.content.addresses.filter(
            (address) => address.addressType === "WAREHOUSE"
          )
        );
      });
    };
  
    const handleFormSumbit = () => {
      form
        .validateFields()
        .then((values) => {
          console.log("Values: ", values);
          setIsLoading(true);
          // const formData = new FormData();
          // formData.append("file", values.file);
          /* let formData = new FormData();
          Object.keys(values).map((item) => {
            formData.append(item, values[item]);
          }); */
  
          const payload = {
            packingListNo: `${selectedcustomer && selectedcustomer.code}-${selectedStore && selectedStore.code
              }-${values.preReceiptNumber}`,
            customerId: values.customer.id,
            storeId: values.store.id,
            invoiceDate: moment(values.orderDate).format("YYYY-MM-DD"),
            deliverFrom: values.customerAddress.id,
            deliveryTo: values.storeAddress.id,
            base64FileString: base64File,
            originalFileName: originalFileName,
            remark: values.remark,
          };
          /* const payload = {
            packingListNo: "",
            customerId: "",
            storeId: "",
            invoiceDate: "",
            deliverFrom: "",
            deliveryTo: "",
            file: base64File,
          }; */
  
          values.invoiceDate = moment(values.orderDate).format("YYYY-MM-DD");
          extraService
            .uploadPackingList(payload)
            .then(() => {
              message.success("Successfully uploaded!");
              setIsLoading(false);
              history.push(".");
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error);
            });
          //}
        })
        .catch((error) => {
          //message.error(`${error}`);
        });
    };
  
    const onCancel = () => {
      history.push("/packing-lists");
    };
  
    const onFileChange = (e) => {
      if (e.target.files[0]) {
        setOriginalFileName(e.target.files[0].name);
        let reader = new FileReader();
        reader.onload = function (e) {
          setBase64File(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  
    return (
      <div>
        <PageHeader
          title={
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/packing-lists">Packing List Search</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Packing List Upload</Breadcrumb.Item>
            </Breadcrumb>
          }
          extra={[
            <Popconfirm
              title="Are you sure to add the changes?"
              onConfirm={handleFormSumbit}
              key="add-confirm"
            >
              <Button
                key="1"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Save
              </Button>
            </Popconfirm>,
            <Button key="2" htmlType="button" onClick={onCancel}>
              Cancel
            </Button>,
          ]}
        >
          <Page inner error={error}>
            <Spin tip="Loading..." spinning={isLoading}>
              <Form
                layout="vertical"
                form={form}
                name="form-create"
                requiredMark={false}
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      name={["customer", "id"]}
                      label="Customer"
                      rules={[
                        { required: true, message: "Customer is required" },
                      ]}
                    >
                      <Select placeholder="Please select" disabled allowClear>
                        {customers.length > 0 &&
                          customers.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={["store", "id"]}
                      label="Store"
                      rules={[{ required: true, message: "Store is required" }]}
                    >
                      <Select
                        placeholder="Please select"
                        allowClear
                        onChange={getStoreAddresses}
                      >
                        {stores.length > 0 &&
                          stores.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="orderDate"
                      label="Invoice Date"
                      rules={[
                        { required: true, message: "Invoice date is required" },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        placeholder="Invoice date"
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                        onChange={setSelectedOrderDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="preReceiptNumber"
                      label="Packing List No"
                      rules={[
                        {
                          required: true,
                          message: "Packing list no is required",
                        },
                      ]}
                    >
                      <Input
                        prefix={`${selectedcustomer && selectedcustomer.code}-${selectedStore && selectedStore.code
                          }-`}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      name={["customerAddress", "id"]}
                      label="Deliver From"
                      rules={[
                        { required: true, message: "Delivery from is required" },
                      ]}
                    >
                      <Select
                        placeholder="Please select"
                        allowClear
                        disabled={isLoggedInCustomer()}
                      >
                        {customerAddresses.length > 0 &&
                          customerAddresses.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.address}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={["storeAddress", "id"]}
                      label="Delivery To"
                      rules={[
                        {
                          required: true,
                          message: "Delivery address is required",
                        },
                      ]}
                    >
                      <Select placeholder="Please select" allowClear>
                        {storeAddresses.length > 0 &&
                          storeAddresses.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.address}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="remark" label="Remark">
                      <TextArea />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      name="file"
                      label="Packing List File"
                      rules={[
                        {
                          required: true,
                          message: "Valid excel file is required",
                        },
                      ]}
                      valuePropName="file"
                    >
                      <Input type="file" name="file" onChange={onFileChange} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingTop: 40 }}>
                    <>
                      {["SILUETA"].includes(loggedInCustomerCode) && (
                        <CSVLink
                          key="1"
                          data={[]}
                          filename={"Sample-StockIn-File.csv"}
                          headers={[
                            { label: "Material", key: "material" },
                            { label: "Material Desc", key: "materialDesc" },
                            { label: "Vendor Batch", key: "vendorBatch" },
                            { label: "Batch", key: "batch" },
                            { label: "Qty", key: "Qty" },
                            { label: "BNS Location", key: "bNSLocation" },
                            { label: "Location", key: "location" },
                            { label: "Plant", key: "plant" },
                            { label: "UOM", key: "uOM" },
                          ]}
                        >
                          Sample Upload File
                        </CSVLink>
                      )}
                      {["STR_GR"].includes(loggedInCustomerCode) && (
                        <CSVLink
                          key="1"
                          data={[]}
                          filename={"Sample-StockIn-File.csv"}
                          headers={[
                            { label: "Item Code", key: "itemCode" },
                            { label: "Batch", key: "batch" },
                            { label: "Net Weight", key: "netWeight" },
                            { label: "Gross Weight", key: "grossWeight" },
                            { label: "Box Serial", key: "serialNo" },
                            { label: "Bin Code", key: "binCode" },
                            { label: "CH BN", key: "chinaBatchNo" },
                            { label: "UOM", key: "uom" },
                            { label: "Stock Class", key: "stockClass" },
                            { label: "Packing Type", key: "packingType" },
                          ]}
                        >
                          Sample Upload File
                        </CSVLink>
                      )}
                    </>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                </Row>
              </Form>
            </Spin>
          </Page>
        </PageHeader>
      </div>
    );
  };
  
  export default Upload;
  