import {
  Button,
  Col,
  Form,
  Row,
  Input,
  message,
  DatePicker,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { OrderStatus, ReceiptStatus } from "_helpers";
import {
  lotNumberService,
  materialTypeService,
  purchaseOrderService,
  sbuService,
  subLocationService,
  supplierService,
  zoneService,
} from "_services";

function Search(props) {
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);

  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getStockTypes();
    getLotNumbers();
    getSuppliers();
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.purchaseOrderNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `preReceiptNumber=${encodeURIComponent(
        values.purchaseOrderNumber
      )}`;
    }
    if (values.orderStage) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `orderStages=${encodeURIComponent(
        values.orderStage.toString()
      )}`;
    }
    if (values.orderDateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.orderDateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.orderDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
    }
    if (values.stockTypeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockTypeId=${encodeURIComponent(values.stockTypeId)}`;
    }
    if (values.lotNumberId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `lotNumberId=${encodeURIComponent(values.lotNumberId)}`;
    }
    if (values.supplierId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `supplierId=${encodeURIComponent(values.supplierId)}`;
    }
    if (values.subLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `subLocationId=${encodeURIComponent(
        values.subLocationId
      )}`;
    }
    if (values.zoneId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `zoneId=${encodeURIComponent(values.zoneId)}`;
    }
    if (values.sbuId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `sbuId=${encodeURIComponent(values.sbuId)}`;
    }
    if (values.refNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `refNumber=${encodeURIComponent(values.refNumber)}`;
    }
    if (values.packingStatus) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `packingStatus=${encodeURIComponent(values.packingStatus.toString())}`;
    }
    purchaseOrderService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="purchaseOrderNumber" label="Receipt Number">
            <Input.Search placeholder="Order Number" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="orderDateRange" label="Receipt Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="orderStage" label="Status">
            <Select allowClear mode="multiple">
              {ReceiptStatus.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="stockTypeId" label="Material Type">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Type"
            >
              {stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="lotNumberId" label="Lot Number">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Lot Number"
            >
              {lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="supplierId" label="Supplier">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Supplier"
            >
              {suppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="packingStatus" label="Packing Status">
            <Select
              placeholder="Packing Status"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="NEW" key="NEW">
                NEW
              </Option>
              <Option value="RETURN" key="RETURN">
                RETURN
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="sbuId" label="SBU">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {businessUnits.length > 0 &&
                businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="zoneId" label="Zone">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {zones.length > 0 &&
                zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="subLocationId" label="Sub-Location">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              disabled={!selectedZoneId}
            >
              {subLocations.length > 0 &&
                subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="refNumber" label="Ref. Number">
            <Input.Search placeholder="Ref. Number" allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
