import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/document`;

export const documentService = {
  downloadDocument,
};

function downloadDocument(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`, {
    responseType: "blob",
  });
}
