import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tag,
  Spin,
  Popconfirm,
  Space,
  Badge,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { materialDetailService, materialRequestLineService } from "_services";
import { ScanOutlined, UndoOutlined } from "@ant-design/icons";
import TabMenu from "./TabMenu";

function StrVerify({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const [loadingScanUpdate, setLoadingScanUpdate] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [materialRequestLine, setMaterialRequestLine] = useState({});

  const [availableStocks, setAvailableStocks] = useState([]);
  const [error, setError] = useState(null);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [scannedPackSerialNos, setScannedPackSerialNos] = useState([]);
  const [scannedItems, setScannedItems] = useState([]);
  const [form] = Form.useForm();

  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const materialRequestLineData = await materialRequestLineService.getById(
        id
      );

      if (materialRequestLineData) {
        let materialRequestLine = materialRequestLineData.content;
        if (materialRequestLine.lineStage !== "PREPARE") {
          history.push(
            `/material-request-lines/${materialRequestLine.id}/summary`
          );
        }

        materialRequestLine.requestTime = moment(
          materialRequestLine.requestTime
        );
        materialRequestLine.stockTypeCode = materialRequestLine.stockType.code;
        materialRequestLine.lotNumberNumber =
          materialRequestLine.lotNumber?.number;
        materialRequestLine.measurementTypeName =
          materialRequestLine.measurementType?.name;
        materialRequestLine.departmentCode =
          materialRequestLine.department?.code;
        materialRequestLine.supplierName = materialRequestLine.supplier?.name;
        materialRequestLine.colorCodeName = materialRequestLine.colorCode
          ? `COM${materialRequestLine.colorCode}`
          : "";
        if (materialRequestLine.uom === "BOX") {
          materialRequestLine.quantity = materialRequestLine.totalItems;
        } else if (materialRequestLine.uom === "CONE") {
          materialRequestLine.quantity = materialRequestLine.qty;
        } else if (materialRequestLine.uom === "KG") {
          materialRequestLine.quantity = materialRequestLine.quantity;
        }

        // Load available stocks for scanning
        getStocks(
          materialRequestLine.issueRequest.subLocation.id,
          materialRequestLine.stockType.id,
          materialRequestLine.lotNumber?.id,
          materialRequestLine.width,
          materialRequestLine.colorCode
        );

        setMaterialRequestLine(materialRequestLine);
        onFill(materialRequestLine);
        const scannedSerialNumbers = materialRequestLine.requestedItems
          .filter((item) => item.mode === "REQUEST_SCANNED")
          .map((item) => item.serialNo);
        const scannedPackSerialNumbers = materialRequestLine.requestedItems
          .filter((item) => item.mode === "REQUEST_SCANNED")
          .filter((item) => item.packSerialNo != null)
          .map((item) => item.packSerialNo);
        setScannedBarcodes([...scannedBarcodes, ...scannedSerialNumbers]);
        setScannedPackSerialNos([
          ...scannedPackSerialNos,
          ...scannedPackSerialNumbers,
        ]);
        setScannedItems([
          ...scannedItems,
          ...materialRequestLine.requestedItems.filter(
            (item) => item.mode === "REQUEST_SCANNED"
          ),
        ]);
      }

      setLoading(false);
    };

    fetchAlldatas();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push(`/material-request-lines/${materialRequestLine.id}/summary`);
  };

  const handleStockItemUnVerified = (stockItemId) => {
    setLoadingScanUpdate(true);
    const scanUpdatePayload = {
      refId: materialRequestLine.id,
      type: "REQUEST_LINE",
      isCancelled: 1,
      stockItems: [
        { id: stockItemId, requestedLine: { id: materialRequestLine.id } },
      ],
    };
    materialDetailService
      .updateScannedItems(scanUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingScanUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingScanUpdate(false);
        setError(error);
      });
  };

  const handleScanUpdate = () => {
    setLoadingScanUpdate(true);
    const scanUpdatePayload = {
      refId: materialRequestLine.id,
      type: "REQUEST_LINE",
      stockItems: scannedItems
        .filter((item) => item.mode === "AVAILABLE")
        .map((item) => ({
          id: item.id,
          requestedLine: { id: item.requestedLine.id },
        })),
    };
    materialDetailService
      .updateScannedItems(scanUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingScanUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingScanUpdate(false);
        setError(error);
      });
  };

  const handleStatusUpdate = () => {
    setLoadingStatusUpdate(true);
    const statusUpdatePayload = {
      requestStatus: "READY",
    };
    materialRequestLineService
      .updateStatus(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingStatusUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (materialRequestLine.lineStage === "PREPARE") {
      let statusButtonText = "Complete Scan";
      buttons.push(
        <Popconfirm
          title={`Are you sure to ${statusButtonText} it?`}
          onConfirm={handleStatusUpdate}
          key="con-complete"
          disabled={
            scannedItems.filter((item) => item.mode === "AVAILABLE").length > 0
          }
        >
          <Button
            key="btn-complete"
            type="primary"
            htmlType="button"
            loading={loadingStatusUpdate}
            disabled={
              scannedItems.filter((item) => item.mode === "AVAILABLE").length >
              0
            }
          >
            {statusButtonText}
          </Button>
        </Popconfirm>
      );
    }
    if (materialRequestLine.lineStage === "PREPARE") {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleScanUpdate}
          key="scan-confirm"
          disabled={
            scannedItems.filter((item) => item.mode === "AVAILABLE").length ===
            0
          }
        >
          <Button
            key="scan"
            type="primary"
            loading={loadingScanUpdate}
            disabled={
              scannedItems.filter((item) => item.mode === "AVAILABLE")
                .length === 0
            }
          >
            Update Scan
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      //width: "6%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      //width: "6%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Prod Date",
      dataIndex: "productionDate",
      key: "productionDate",
      //width: "5%",
    });
    columns.push({
      title: "Box #",
      dataIndex: "tempNumber",
      key: "tempNumber",
      width: "3%",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      //width: "5%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Pallet",
      dataIndex: "palletNo",
      key: "palletNo",
      align: "right",
      //width: "3%",
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      //width: "3%",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      //width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "6%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUEST_SCANNED") {
          color = "#2db7f5";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "LOADED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "2%",
      render: (text, record) => {
        return (
          <span>
            {["PREPARE"].includes(materialRequestLine.lineStage) &&
              ["REQUEST_SCANNED"].includes(record.mode) && (
                <Popconfirm
                  title="Are you sure to cancel this scan?"
                  onConfirm={() => handleStockItemUnVerified(record.id)}
                >
                  <UndoOutlined title="UnVerify" />
                </Popconfirm>
              )}
          </span>
        );
      },
    });

    return columns;
  };

  const getStocks = (subLocationId, stockTypeId, lotId, width, colorCode) => {
    let searchParams = `?subLocationId=${subLocationId}&stockTypeId=${stockTypeId}&modes=AVAILABLE&pageNumber=1&pageSize=1000`;
    searchParams = lotId
      ? `${searchParams}&lotNumberId=${lotId}`
      : searchParams;
    searchParams = colorCode
      ? `${searchParams}&colorCode=${colorCode}`
      : searchParams;

    setIsAvailableStocksLoading(true);
    materialDetailService
      .searchAvailable(searchParams)
      .then((data) => {
        setIsAvailableStocksLoading(false);
        if (data.content.length > 0) {
          setAvailableStocks(data.content);
        }
      })
      .catch((error) => {
        setIsAvailableStocksLoading(false);
        message.error(`${error}`);
      });
  };

  const handleItemScan = (barcode) => {
    if (barcode) {
      if (["REJECTED", "CANCEL"].includes(materialRequestLine.lineStage)) {
        form.setFieldsValue({ itemScan: "" });
        return message.error("Line is already rejected/cancelled!");
      }
      let scannedItem = scannedBarcodes.find(
        (item) => item.toLowerCase() === barcode.toLowerCase()
      );
      if (scannedItem) {
        form.setFieldsValue({ itemScan: "" });
        return message.error("Barcode is already scanned!");
      } else {
        scannedItem = scannedPackSerialNos.find(
          (item) => item.toLowerCase() === barcode.toLowerCase()
        );
        if (scannedItem) {
          form.setFieldsValue({ itemScan: "" });
          return message.error("Barcode is already scanned!");
        }
      }

      setLoadingStockItem(true);
      const matchingStock = availableStocks.find(
        (item) =>
          item.serialNo.toLowerCase() === barcode.toLowerCase() ||
          (item.packSerialNo &&
            item.packSerialNo.toLowerCase() === barcode.toLowerCase())
      );
      if (matchingStock) {
        matchingStock.requestedLine = materialRequestLine;
        materialRequestLine.requestedItems = [
          ...materialRequestLine.requestedItems,
          matchingStock,
        ];

        if (
          matchingStock.supplier &&
          matchingStock.supplier.code === "GP_STR"
        ) {
          setScannedBarcodes([...scannedBarcodes, matchingStock.packSerialNo]);
        } else {
          setScannedBarcodes([...scannedBarcodes, matchingStock.serialNo]);
        }

        setScannedItems([...scannedItems, matchingStock]);

        materialRequestLine["netWeight"] = materialRequestLine.requestedItems
          .map((item) => item.netWeight)
          .reduce((prev, next) => prev + next);
        materialRequestLine["grossWeight"] = materialRequestLine.requestedItems
          .map((item) => item.grossWeight)
          .reduce((prev, next) => prev + next);
      } else {
        message.error(
          "Sorry, No item found against the barcode & selected request line."
        );
      }
      setLoadingStockItem(false);
      form.setFieldsValue({ itemScan: "" });
    } else {
      form.setFieldsValue({ itemScan: "" });
      message.error("Please enter valid barcode.");
    }
  };

  const getRowColor = (record) => {
    let color = "";
    if (record) {
      const text = record.lineStage;
      if (text === "OPEN") {
        color = "#87d068";
      }
      if (text === "UPDATE") {
        color = "#2db7f5";
      }
      if (text === "VERIFICATION") {
        color = "#108ee9";
      }
      if (text === "PREPARE") {
        color = "#f50";
      }
      if (text === "READY") {
        color = "#faad14";
      }
      if (text === "ASSIGNED") {
        color = "#ffd666";
      }
      if (text === "PROCESSING") {
        color = "#ffd666";
      }
      if (text === "COMPLETE") {
        color = "#ffd666";
      }
      if (text === "REJECTED") {
        color = "#ffd666";
      }
      if (text === "CANCEL") {
        color = "#ffd666";
      }
    }

    return color;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequestLine?.issueRequest?.requestNumber} ::{" "}
                {materialRequestLine.mrnNumber} ::{" "}
                <Tag color={getRowColor(materialRequestLine)}>
                  {materialRequestLine.lineStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu
            menu="prepare"
            id={id}
            status={materialRequestLine.lineStage}
          />
          <Spin tip="Loading..." spinning={loading || availableStocksLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={3}>
                  <Form.Item name="mrnNumber" label="MRN No.">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="departmentCode" label="Department">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="stockTypeCode" label="Material">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="lotNumberNumber" label="Lot No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="requestTime" label="Req. Time">
                    <DatePicker
                      placeholder="Req. Time"
                      style={{ width: "100%" }}
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="measurementTypeName" label="Measurement">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="quantity" label="Quantity">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="priorityStatus" label="Priority Status">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={3}>
                  <Form.Item name="supplierName" label="Supplier">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="width" label="Width">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="colorCodeName" label="Color Code">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
              </Row>

              <Row gutter={24}>
                <Col span={23}>
                  <Form.Item name="itemScan">
                    <Input.Search
                      loading={loadingStockItem}
                      placeholder="Enter valid barcode"
                      allowClear
                      enterButton={<ScanOutlined />}
                      onSearch={handleItemScan}
                      style={{ marginBottom: 20 }}
                      disabled={["REJECTED", "CANCEL"].includes(
                        materialRequestLine.lineStage
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Space>
                    <Badge
                      count={scannedBarcodes.length}
                      showZero
                      color="#faad14"
                      overflowCount={1000}
                    />
                  </Space>
                </Col>
              </Row>
            </Form>

            {materialRequestLine.requestedItems &&
              materialRequestLine.requestedItems.length > 0 && (
                <>
                  <Divider orientation="left">Verified Stock Items</Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getStockItemColumns()}
                    dataSource={materialRequestLine.requestedItems}
                    pagination={false}
                    summary={(pageData) => {
                      let totalQuantity = 0;
                      let totalNetWeight = 0;
                      let totalGrossWeight = 0;

                      pageData.forEach(
                        ({ quantity, netWeight, grossWeight }) => {
                          totalQuantity += quantity;
                          totalNetWeight += netWeight;
                          totalGrossWeight += grossWeight;
                        }
                      );

                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getStockItemColumns().length - 7}
                            >
                              <Text strong>Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {materialRequestLine.requestedItems.length}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell align="right">
                              <Text strong>{totalQuantity}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {Number(totalNetWeight).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {Number(totalGrossWeight).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </>
              )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrVerify;
