import React, { Component } from "react";
import "./LeftMenu.scss";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import { MenuOutlined } from "@ant-design/icons";
import dash from "img/leftMenu/hier.svg";
import hier from "img/leftMenu/hier.svg";
import mat from "img/leftMenu/mat.svg";
import stock from "img/leftMenu/stock.svg";
import report from "img/leftMenu/report.svg";
import hierM from "img/leftMenu/mobilw/1.svg";
import matM from "img/leftMenu/mobilw/2.svg";
import stockM from "img/leftMenu/mobilw/stock.svg";
import reportM from "img/leftMenu/mobilw/report.svg";
import MediaQuery from "react-responsive";
import MenuList from "./MenuListItem";

const subMenu = [
  { label: "Company", link: "/companies", iconStat: "icon_1" },
  { label: "People", link: "/people", iconStat: "icon_2" },
  {
    label: "Stock Classifications",
    link: "/stock-classifications",
    iconStat: "icon_3",
  },
  { label: "Packing Types", link: "/packing-types", iconStat: "icon_4" },
  { label: "Supplier", link: "/suppliers", iconStat: "icon_5" },
  { label: "Department", link: "/departments", iconStat: "icon_6" },
  { label: "Machine", link: "/machines", iconStat: "icon_7" },
  { label: "Vehicle", link: "/vehicles", iconStat: "icon_8" },
];
const subMenu2 = [
  { label: "Material Types", link: "/material-types", iconStat: "icon_1" },
  { label: "Lot/Batch Number", link: "/lotnumbers", iconStat: "icon_2" },
  { label: "Bin Locations", link: "/bin-locations", iconStat: "icon_3" },
  { label: "Material Bins", link: "/bins", iconStat: "icon_4" },
  { label: "Material Details", link: "/material-details", iconStat: "icon_5" },
];
const subMenu3 = [
  { label: "Stock Receipt", link: "/packing-lists", iconStat: "icon_1" },
  { label: "Material Request", link: "/material-request", iconStat: "icon_2" },
  {
    label: "Pending MRNs",
    link: "/material-requests/pending",
    iconStat: "icon_3",
  },
  { label: "Stock Issue", link: "/stock-issue", iconStat: "icon_4" },
  { label: "Store View", link: "/stores", iconStat: "icon_5" },
  { label: "Stock Transfer", link: "/stock-transfer", iconStat: "icon_6" },
  { label: "Stock Check", link: "/stock-check", iconStat: "icon_7" },
];
const subMenu4 = [
  {
    label: "Available Stocks",
    link: "/reports/available-stocks",
    iconStat: "icon_1",
  },
  {
    label: "Bin Wise Balance",
    link: "/reports/bin-wise-balance",
    iconStat: "icon_2",
  },
  {
    label: "Supplier Wise Balance",
    link: "/reports/supplier-wise-balance",
    iconStat: "icon_3",
  },
  {
    label: "Stock In-Out Summary",
    link: "/reports/inout-summary",
    iconStat: "icon_4",
  },
];
class LeftMenuSAU extends Component {
  items;

  constructor(props) {
    super(props);
  }

  state = {
    show: true,
    show2: true,
    show3: true,
    show4: true,
  };
  clickHandle = () => {
    this.setState({
      // isSwitchOn: !this.state.isSwitchOn,
      show: true,
      show2: false,
      show3: false,
      show4: false,
      // isSwitchOn: true,
      // show: true,
    });
  };
  clickHandle2 = () => {
    this.setState({
      // isSwitchOn: !this.state.isSwitchOn,
      show2: true,
      show: false,
      show3: false,
      show4: false,
    });
  };
  clickHandle3 = () => {
    this.setState({
      // isSwitchOn: !this.state.isSwitchOn,
      show3: true,
      show: false,
      show2: false,
      show4: false,
    });
  };
  clickHandle4 = () => {
    this.setState({
      // isSwitchOn: !this.state.isSwitchOn,
      show4: true,
      show: false,
      show2: false,
      show3: false,
    });
  };
  clickHandleNew = () => {
    this.setState({
      // show: !this.state.show,
      // show2: !this.state.show2,
      // show3: !this.state.show3,
      // show4: !this.state.show4,
      show: false,
      show2: false,
      show3: false,
      show4: false,
    });
  };

  render() {
    return (
      <div>
        <MediaQuery query="(min-device-width: 1024px)">
          <div className="LeftMenu" data-testid="LeftMenu">
            <Menu
              key="mainMenu"
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              theme="dark"
              overflowedIndicator={<MenuOutlined />}
            >
              <Menu.Item key="1" className="dasboardw">
                <Link to="/">
                  <img className="dasboard" src={dash} />
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>
              <img className="menuIcon" src={hier} />
              <SubMenu key="hierarchy" title="Hierarchy">
                <Menu.Item key="2">
                  <Link to="/companies">
                    <span>Company</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/people">
                    <span>People</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/stock-classifications">
                    <span>Stock Classifications</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/packing-types">
                    <span>Packing Types</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/suppliers">
                  <Link to="/suppliers">
                    <span>Supplier</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/departments">
                  <Link to="/departments">
                    <span>Department</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/machines">
                  <Link to="/machines">
                    <span>Machine</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/vehicles">
                  <Link to="/vehicles">
                    <span>Vehicle</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/sbu">
                  <Link to="/sbu">
                    <span>SBU</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/zones">
                  <Link to="/zones">
                    <span>Zones</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/sub-locations">
                  <Link to="/sub-locations">
                    <span>Sub-Locations</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/roles">
                    <span>Roles</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link to="/measurement-types">
                    <span>Measurement Types</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              <img className="menuIcon" src={mat} />
              <SubMenu key="material" title="Materials">
                <Menu.Item key="mt">
                  <Link to="/material-types">
                    <span>Material Types</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="lotNumber">
                  <Link to="/lotnumbers">
                    <span>Lot/Batch Number</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="bins">
                  <Link to="/bins">
                    <span>Material Bins</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="md">
                  <Link to="/material-details">
                    <span>Material Details</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              <img className="menuIcon" src={stock} />
              <SubMenu key="purchase" title="Stocks">
                <Menu.Item key="st-in">
                  <Link to="/packing-lists">
                    <span>Stock Receipt</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="st-req">
                  <Link to="/material-requests">
                    <span>Material Request</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="st-pending-req">
                  <Link to="/material-requests/pending">
                    <span>Pending MRNs</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="st-out">
                  <Link to="/stock-issue">
                    <span>Stock Issue</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="stores">
                  <Link to="/stores">
                    <span>Store View</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="st-bin-transfer">
                  <Link to="/stock-transfer">
                    <span>Stock Transfer</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="st-check">
                  <Link to="/stock-check">
                    <span>Stock Checking</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              <img className="menuIcon" src={report} />
              <SubMenu key="reports" title="Reports">
                <Menu.Item key="rep-ast">
                  <Link to="/reports/available-stocks">
                    <span>Available Stocks</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="rep-bin-wise">
                  <Link to="/reports/bin-wise-balance">
                    <span>Bin Wise Balance</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="rep-supplier-wise">
                  <Link to="/reports/supplier-wise-balance">
                    <span>Supplier Wise Balance</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="inout-summary">
                  <Link to="/reports/inout-summary">
                    <span>Stock In-Out Summary</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1023px)">
          <div className="mobileMenu">
            <div className="footerIcon">
              {/*<button className={this.state.isSwitchOn ? 'active' : ''}*/}
              <button onClick={this.clickHandle}>
                <img src={hierM} />
              </button>
            </div>
            <div className="footerIcon">
              <button onClick={this.clickHandle2}>
                <img src={stockM} />
              </button>
            </div>
            <div className="footerIcon">
              <button onClick={this.clickHandle3}>
                <img src={matM} />
              </button>
            </div>
            <div className="footerIcon">
              <button onClick={this.clickHandle4}>
                <img src={reportM} />
              </button>
            </div>
          </div>
          <div>
            {this.state.show ? (
              <div className="mobileSubMenu" onClick={this.clickHandleNew}>
                <MenuList data={subMenu} />
              </div>
            ) : null}

            {this.state.show2 ? (
              <div className="mobileSubMenu" onClick={this.clickHandleNew}>
                <MenuList data={subMenu2} />
              </div>
            ) : null}

            {this.state.show3 ? (
              <div className="mobileSubMenu" onClick={this.clickHandleNew}>
                <MenuList data={subMenu3} />
              </div>
            ) : null}
            {this.state.show4 ? (
              <div className="mobileSubMenu" onClick={this.clickHandleNew}>
                <MenuList data={subMenu4} />
              </div>
            ) : null}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default LeftMenuSAU;
