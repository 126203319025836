import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { lotNumberService, stockClassificationService } from "_services";

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 16 },
};

function Add({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [stockClasses, setStockClasses] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  useEffect(() => {
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("PL:", JSON.stringify(values));
        setIsLoading(true);
        lotNumberService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((errorInfo) => {});
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/lotnumbers">
                {["SILUETA"].includes(loggedInCustomerCode)
                  ? "Vendor Batch"
                  : "Lot/Batch Number"}{" "}
                Search
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/lotnumbers/add">
                New{" "}
                {["SILUETA"].includes(loggedInCustomerCode)
                  ? "Vendor Batch"
                  : "Lot/Batch Number"}{" "}
                Number
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="number"
                  label={`${
                    ["SILUETA"].includes(loggedInCustomerCode)
                      ? "Vendor Batch"
                      : "Lot/Batch Number"
                  }`}
                  rules={[{ required: true, message: "Number is required." }]}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["stockClass", "id"]}
                  label="Stock Classification"
                  rules={[
                    {
                      required: true,
                      message: "Stock Classification is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    filterOption={true}
                    notFoundContent={null}
                    placeholder="Please select"
                  >
                    {stockClasses.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
