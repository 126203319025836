import { fetchWrapper } from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/stocksIssue`;

export const stockIssueService = {
  getAll,
  getSuggestion,
  search,
  getById,
  printPdf,
  printExcel,
  exportExcel,
  create,
  update,
  updateDetails,
  confirm,
  deliveryUpdate,
  updateStatus,
  delete: _delete,
  deleteLineItem,
  deleteLine,
  getHistory,
  getDocuments,
  updateScannedItems,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function getSuggestion(params) {
  return fetchWrapper.get(`${baseUrl}/suggestion${params}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function printPdf(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/printPdf`, {
    responseType: 'blob',
  });
}

function printExcel(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/printExcel`, {
    responseType: 'blob',
  });
}

function exportExcel(params) {
  return fetchWrapper.get(`${baseUrl}/export-excel${params}`, {
    responseType: 'blob',
  });
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function updateDetails(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/vehicle`, params);
}

function confirm(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/confirm`, params);
}

function deliveryUpdate(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/delivered`, params);
}

function updateStatus(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/updateStatus`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function deleteLineItem(id, stockItemId) {
  return fetchWrapper.delete(`${baseUrl}/${id}/lineItem/${stockItemId}`);
}

function deleteLine(id, lineId) {
  return fetchWrapper.delete(`${baseUrl}/${id}/line/${lineId}`);
}

function getHistory(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/history`);
}

function getDocuments(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/documents`);
}

function updateScannedItems(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/scan`, params);
}
