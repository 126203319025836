import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Tag,
  Popconfirm,
  Typography,
  Tooltip,
  Divider,
  Tabs,
  Modal,
  Spin,
  Checkbox,
  Space,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  materialRequestService,
  stockIssueService,
  vehicleService,
} from "_services";
import { Auth, Utils } from "_helpers";
import ExportExcel from "_helpers/ExportExcel";
import {
  CloseOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  ScanOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import RequestLineSelection from "./RequestLineSelection";

function PrymEditWithLines({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [verifiedLineLoading, setVerifiedLineLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [materialRequests, setMaterialRequests] = useState([]);
  const [stockIssue, setStockIssue] = useState({});
  const [selectedIssueDate, setSelectedIssueDate] = useState(Date());
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchMRLoading, setSearchMRLoading] = useState(false);
  const [loadedStocks, setLoadedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [availableStocks, setAvailableStocks] = useState([]);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  const [showRequestLines, setShowRequestLines] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [issuedLines, setIssuedLines] = useState([]);
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);
  const [isBulkScanVisible, setIsBulkScanVisible] = useState(false);
  const [bulkScannedBarcodes, setBulkScannedBarcodes] = useState([]);
  const [verifiedRequestLines, setVerifiedRequestLines] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [isAddRequestLineWindowVisible, setIsAddRequestLineWindowVisible] =
    useState(false);
  const [addedRequestLineIds, setAddedRequestLineIds] = useState([]);

  useEffect(() => {
    getMaterialRequests();
    getStockIssue(id);
    getStores();
    getCustomers();
    getVehicles();
  }, []);

  const getStockIssue = (issueId) => {
    setIsLoading(true);
    stockIssueService
      .getById(issueId)
      .then((data) => {
        if (data) {
          let persistedStockIssue = data.content;

          persistedStockIssue.issuedDate = moment(
            persistedStockIssue.issuedDate
          );
          persistedStockIssue.deliveredDate = persistedStockIssue.deliveredDate
            ? moment(persistedStockIssue.deliveredDate)
            : moment();
          persistedStockIssue.issuedLines.forEach((line, i) => {
            line.key = `${line.id}_${line.stockType.id}_${
              line.lotNumber ? line.lotNumber.id : ""
            }`;
            line.deliveredQuantity = 0;

            line.requestNumber = line.requestLine.issueRequest.requestNumber;
            line.requestedQuantity = line.requestLine.totalItems;
            line.requestedNetMeasure = line.requestLine.quantity;
            line.requestedTotalQuantity = line.requestLine.quantity;
            line.isNew = false;
            line.issuedItems = line.issuedItems.map((t) => ({
              ...t,
              issuedLineId: line.id,
              isNew: t.mode === "REQUESTED" ? true : false,
              added: ["READY", "LOADED"].includes(t.mode) ? true : false,
              loaded: t.mode === "LOADED" ? true : false,
            }));
            line.requestedItems = line.requestLine.requestedItems;
            line.measurementType = line.requestLine.measurementType;

            let loadedItems = line.issuedItems.filter(
              (item) => item.mode === "LOADED"
            );
            line.issuedLineItems = loadedItems && loadedItems.length;
            line.issuedQuantity =
              loadedItems.length > 0 &&
              loadedItems
                .map((item) => item.quantity)
                .reduce((prev, next) => prev + next);
            line.issuedGrossWeight =
              loadedItems.length > 0 &&
              loadedItems
                .map((item) => item.grossWeight)
                .reduce((prev, next) => prev + next);
            setAvailableStocks([...availableStocks, ...line.issuedItems]);
            setSelectedRowKeyMap(
              selectedRowKeyMap.set(
                line.key,
                loadedItems.map((item) => item.id)
              )
            );

            if (!issuedLines.map((line) => line.key).includes(line.key)) {
              issuedLines.push(line);
            }
          });

          setStockIssue(persistedStockIssue);

          setIssuedLines([].concat(issuedLines));
          setSelectedCustomer(persistedStockIssue.customer);
          onFill(persistedStockIssue);

          if (issuedLines.length > 0) {
            handleTabChange(issuedLines[0].key);
            showMaterialRequestLinesByRequestNumber(
              persistedStockIssue.issuedLines[0].requestLine.issueRequest
                .requestNumber
            );
          }
          //console.log("Issued Lines", issuedLines);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsLoading(false);
        //setError(error);
      });
  };

  const getStores = () => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      let companyId = Auth.getProfile().comp_id
        ? Auth.getProfile().comp_id
        : x.content[0].id;
      companyService.getById(companyId).then((data) => {
        const grantedStoreAddresses = data.content.addresses.filter(
          (item) => item.addressType === "WAREHOUSE"
        );
        setStoreAddresses(grantedStoreAddresses);
      });
      setStores(x.content);
    });
  };

  const getCustomers = () => {
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
  };

  const getMaterialRequests = () => {
    materialRequestService.getSuggestion("").then((x) => {
      setMaterialRequests(
        x.content.filter((item) =>
          ["VERIFIED", "IN_PROGRESS"].includes(item.orderStage)
        )
      );
    });
  };

  const getVerifiedRequestLines = () => {
    let searchParams = `?lineStages=VERIFIED,IN_PROGRESS`;
    setVerifiedLineLoading(true);
    materialRequestService
      .searchLine(searchParams)
      .then((data) => {
        setVerifiedLineLoading(false);
        if (data.content.length > 0) {
          /*  data.content
            .filter((line) => addedRequestLineIds.includes(line.id))
            .map((item) => ({ ...item, added: false })); */
          data.content.forEach((line) => {
            if (addedRequestLineIds.includes(line.id)) {
              line.added = true;
            }
          });
          setVerifiedRequestLines(data.content);
          setIsAddRequestLineWindowVisible(true);
        } else {
          message.info("Sorry, There's no verified request lines available!");
        }
      })
      .catch((error) => {
        setVerifiedLineLoading(false);
        message.error(`${error}`);
      });
  };

  const getVehicles = () => {
    vehicleService
      .getSuggestion()
      .then((x) => {
        setVehicles(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = (isComplete) => {
    form
      .validateFields()
      .then((values) => {
        const issuedItems = issuedLines.flatMap((line) => line.issuedItems);
        if (issuedItems && issuedItems.length === 0) {
          setError("Delivery should have at least one delivery item");
        } else {
          if (isComplete) {
            setLoadingDelivery(true);
          } else {
            setUpdating(true);
          }

          delete values.stockItem;
          delete values.materialRequest;

          values.issuedDate = moment(values.issuedDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          values.orderStage = isComplete ? "ISSUED" : "PROCESSING";
          values.issuedLines = issuedLines.map((line) => ({
            id: line.id,
            requestLine: { id: line.requestLine.id },
            quantity: line.issuedItems.filter((item) => item.added).length,
            stockType: { id: line.stockType.id },
            lotNumber: { id: line.lotNumber && line.lotNumber.id },
            issuedItems: line.issuedItems
              .filter((item) => (isComplete ? item.loaded : true))
              .map((item) => ({
                id: item.id,
                mode: item.mode,
              })),
          }));

          // console.log('OUT: ', values);
          //console.log("OUT: ", JSON.stringify(values));
          if (isComplete) {
            stockIssueService
              .confirm(id, values)
              .then((data) => {
                setLoadingDelivery(false);
                if (data.content.orderStage === "PROCESSING") {
                  message.success("Successfully updated!");
                  window.location.reload();
                } else {
                  message.success("Successfully completed!");
                  history.push(`/stock-issue/${id}/summary`);
                }
              })
              .catch((error) => {
                setLoadingDelivery(false);
                setError(error);
              });
          } else {
            stockIssueService
              .update(id, values)
              .then(() => {
                setUpdating(false);
                message.success("Successfully updated!");
                window.location.reload();
              })
              .catch((error) => {
                setUpdating(false);
                setError(error);
              });
          }
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onSelectChange = (rowKeys) => {
    setSelectedRowKeyMap(selectedRowKeyMap.set(activeTabKey, rowKeys));
    updateStockItemStatus();
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
  };

  const showMaterialRequestLinesByRequestNumber = (requestNumber) => {
    setShowRequestLines(false);
    if (requestNumber) {
      setSearchMRLoading(true);
      materialRequestService
        .search(`?requestNumber=${requestNumber}`)
        .then((data) => {
          setSearchMRLoading(false);
          //console.log("MRN:", data.content[0]);
          setSelectedRequest(data.content[0]);
          form.setFieldsValue({
            materialRequest: data.content[0].id,
          });
          setShowRequestLines(true);
        })
        .catch((error) => {
          setSearchMRLoading(false);
          message.error(`${error}`);
        });
    }
  };

  const onSearchAndAddStockItems = (values) => {
    console.log("BarcodeValues: ", values);
    if (values) {
      setLoadingStockItem(true);
      materialDetailService
        .search(`?serialNos=${encodeURIComponent(values)}`)
        .then((data) => {
          if (data.content) {
            // console.log("Response:", data.content);
            let alreadyLoadedBarcodes = [];
            let successfullyLoadedBarcodes = [];
            let notFoundStocks = [];
            for (const stock of data.content) {
              // console.log("Stock:", stock);
              //let stock = data.content[0];
              let matchedIssueLine = null;

              const deliveryItem = issuedLines
                .flatMap((line) => line.issuedItems)
                .find((item) => item.id === stock.id);
              if (deliveryItem) {
                // console.log("DeliveryItem:", deliveryItem);
                if (deliveryItem.loaded) {
                  alreadyLoadedBarcodes.push(deliveryItem.serialNo);
                  //message.info("Already loaded this stock item");
                } else {
                  for (const line of issuedLines) {
                    //console.log("Issued Line:", line);
                    if (
                      line.issuedItems &&
                      line.issuedItems.find((item) => item.id === stock.id)
                    ) {
                      handleTabChange(line.key);
                      matchedIssueLine = line;
                    }
                  }
                  const lineKey = `${stock.issuedLine.id}_${
                    matchedIssueLine.stockType.id
                  }_${
                    matchedIssueLine.lotNumber
                      ? matchedIssueLine.lotNumber.id
                      : ""
                  }`;
                  if (selectedRowKeyMap.has(lineKey)) {
                    selectedRowKeyMap.set(lineKey, [
                      ...selectedRowKeyMap.get(lineKey),
                      stock.id,
                    ]);
                  } else {
                    selectedRowKeyMap.set(lineKey, [stock.id]);
                  }
                  updateStockItemStatus();
                  successfullyLoadedBarcodes.push(deliveryItem.serialNo);
                  //message.success("Successfully loaded to the delivery");
                }
              } else {
                notFoundStocks.push(stock.serialNo);
                //message.error("Sorry, No item found against the added stocks");
              }
              form.setFieldsValue({ stockItem: null });
            }

            if (notFoundStocks.length > 0) {
              message.error(
                `Sorry, No items (${notFoundStocks.toString()}) found against the added stocks`
              );
            }
            if (alreadyLoadedBarcodes.length > 0) {
              message.info(
                `Already loaded these (${alreadyLoadedBarcodes.toString()}) stock items`
              );
            }
            if (successfullyLoadedBarcodes.length > 0) {
              message.success(
                `Successfully loaded these items (${successfullyLoadedBarcodes.toString()}) to the delivery`
              );
            }
          } else {
            message.error(
              "Sorry, No item found against the barcode or barcodes."
            );
          }
          setBulkScannedBarcodes([]);
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      //console.log("Selected:", selectedIssueLineKeys);
      handleTabChange(selectedIssueLineKeys[0]);
    },
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
    //console.log("Active:", activeTabKey);
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleRemoveLine = (line) => {
    if (line.isNew) {
      const tmpIssuedLines = [...issuedLines];
      if (tmpIssuedLines.includes(line)) {
        tmpIssuedLines.splice(tmpIssuedLines.indexOf(line), 1);

        addedRequestLineIds.splice(
          addedRequestLineIds.indexOf(line.requestLine.id),
          1
        );
        setIssuedLines(tmpIssuedLines);
      }
    } else {
      setIsLoading(true);
      stockIssueService
        .deleteLine(id, line.id)
        .then((x) => {
          setIsLoading(false);
          message.success("Successfully removed!");
          window.location.reload();
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error);
        });
    }
  };

  const handleRemoveLineStock = (stock) => {
    if (stock.isNew) {
      const tmpIssuedLines = [...issuedLines];
      const allIssuedItems = tmpIssuedLines.flatMap((item) => item.issuedItems);
      if (allIssuedItems) {
        allIssuedItems.forEach((item) => {
          if (item.id === stock.id) {
            item.added = false;
            item.mode = "REQUESTED";
          }
        });
      }
      setIssuedLines(tmpIssuedLines);
    } else {
      stockIssueService
        .deleteLineItem(id, stock.id)
        .then((x) => {
          message.success("Successfully removed!");
          window.location.reload();
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const handleAddLineStock = (stock) => {
    const tmpIssuedLines = [...issuedLines];
    const allIssuedItems = tmpIssuedLines.flatMap((item) => item.issuedItems);
    if (allIssuedItems) {
      allIssuedItems.forEach((item) => {
        if (item.id === stock.id) {
          item.added = true;
          item.mode = "READY";
        }
      });
    }
    setIssuedLines(tmpIssuedLines);
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    stockIssueService.printPdf(id, false).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Delivery-${stockIssue.issueNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handlePrintExcel = () => {
    const columns = [
      {
        title: "Material",
        dataIndex: "stockTypeCode",
      },
      {
        title: "Material Desc",
        dataIndex: "stockTypeName",
      },
      {
        title: "Plant",
        dataIndex: "plant",
      },
      {
        title: "Batch",
        dataIndex: "runningNo",
      },
      {
        title: "Vendor Batch",
        dataIndex: "lotNumber",
      },
      {
        title: "Quantity",
        dataIndex: "grossWeight",
      },
      {
        title: "Location",
        dataIndex: "storeLocation",
      },
      {
        title: "Bin",
        dataIndex: "stockBin",
      },
      {
        title: "Status",
        dataIndex: "mode",
      },
    ];
    const totalIssuedStocks = issuedLines.flatMap((item) => item.issuedItems);
    const data = totalIssuedStocks.map((item) => {
      const container = {};
      container.stockTypeName = item.stockType.name;
      container.plant = item.plant.name;
      container.lotNumber = item.lotNumber && item.lotNumber.number;
      container.runningNo = item.runningNo;
      container.mode = item.mode;
      container.grossWeight = item.grossWeight;
      container.storeLocation = item.stockBin.storeLocation.code;
      container.stockBin = item.stockBin.code;

      return container;
    });
    ExportExcel(
      columns,
      data,
      `Stock-Delivery-${stockIssue.issueNumber}`,
      `${stockIssue.issueNumber}`
    );
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Request No:",
      dataIndex: "requestNumber",
      key: "requestNumber",
      with: "10%",
    });
    columns.push({
      title: "Req. Time",
      key: "requestTime",
      width: "8%",
      render: (text, record) => {
        return record.requestLine.requestTime;
      },
    });
    columns.push({
      title: "Material",
      key: "stockTypeCode",
      width: "8%",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    /* columns.push({
      title: 'Description',
      key: 'stockTypeName',
      width: '8%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder='topLeft' title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    }); */
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "15%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Veri. Roll/Box",
      key: "verifiedBox",
      align: "right",
      width: "5%",
      render: (text, record, index) => {
        return record.requestLine.requestedItems.length;
      },
    });
    columns.push({
      title: "Veri. Qty",
      key: "verifiedQuantity",
      align: "right",
      width: "8%",
      render: (text, record) => {
        return (
          record.requestLine.requestedItems.length &&
          Number(
            record.requestLine.requestedItems
              .map((item) => item.quantity)
              .reduce((prev, next) => prev + next)
          ).toFixed(2)
        );
      },
    });
    columns.push({
      title: "Loaded Roll/Box",
      dataIndex: "issuedLineItems",
      key: "issuedLineItems",
      align: "right",
      width: "5%",
      render: (text, record) => {
        return !isNaN(text) && Number(text);
      },
    });
    columns.push({
      title: "Loaded Quantity",
      dataIndex: "issuedQuantity",
      key: "grossWeight",
      align: "right",
      width: "8%",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    columns.push({
      title: "",
      key: "operation",
      width: "3%",
      render: (text, record, index) => (
        <Popconfirm
          title="Are you sure to remove this line?"
          onConfirm={() => handleRemoveLine(record)}
        >
          <CloseOutlined title="Remove Line" />
        </Popconfirm>
      ),
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "8%",
    });
    columns.push({
      title: "Bin",
      key: "stockBin",
      width: "7%",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "B/N",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "3%",
      align: "right",
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "10%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross (Sq.m)",
      dataIndex: "grossValueSqm",
      key: "grossValueSqm",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "LOADED") {
          color = "#87d068";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "operation",
      width: "3%",
      render: (text, record, index) => {
        if (record.added) {
          return (
            <Popconfirm
              title="Are you sure to remove this item?"
              onConfirm={() => handleRemoveLineStock(record)}
            >
              <CloseOutlined title="Remove Item" />
            </Popconfirm>
          );
        } else {
          return (
            <Popconfirm
              title="Are you sure to add this item?"
              onConfirm={() => handleAddLineStock(record)}
            >
              <PlusOutlined title="Add Item" />
            </Popconfirm>
          );
        }
      },
    });

    return columns;
  };

  const handleRequestLineSelection = (requestLineIds) => {
    setAddedRequestLineIds(requestLineIds);
  };

  const handleAddRequestedLines = () => {
    let addedLines = verifiedRequestLines.filter((line) =>
      addedRequestLineIds.includes(line.id)
    );
    if (addedLines) {
      const newlyAdded = addedLines.filter((item) => !item.added);
      if (newlyAdded) {
        for (var i = 0; i < newlyAdded.length; i++) {
          var requestLine = newlyAdded[i];
          requestLine.added = true;
          const issuedLine = {
            key: `${requestLine.id}_${requestLine.stockType.id}_${
              requestLine.lotNumber ? requestLine.lotNumber.id : ""
            }`,
            stockType: requestLine.stockType,
            lotNumber: requestLine.lotNumber,
            requestLine: requestLine,
            measurementType: requestLine.measurementType,
            requestNumber: requestLine.requestNumber,
            requestedQuantity: requestLine.requestedItems.length,
            //requestedTotalQuantity: requestLine.quantity,
            requestedNetMeasure:
              requestLine.requestedItems.length > 0 &&
              requestLine.requestedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next),
            issuedItems: requestLine.requestedItems
              .filter((item) => item.mode === "REQUESTED")
              .map((t) => ({
                ...t,
                isNew: true,
                added: true,
                loaded: false,
                mode: "READY",
                requestLineId: requestLine.id,
              })),
            issuedLineItems: 0,
            issuedQuantity: 0,
            issuedNetWeight: 0,
            issuedGrossWeight: 0,
            isNew: true,
          };
          issuedLines.push(issuedLine);
        }
        handleTabChange(issuedLines[issuedLines.length - 1].key);
      }
      //setIssuedLines(...issuedLines, ...issuedLines.filter(({ id: id1}) => !addedLines.some(({ id: id2}) => id1 === id2)));
    }
    setIsAddRequestLineWindowVisible(false);
  };

  const getTabContent = (stockIssueLines) => {
    let tabPanes = [];
    stockIssueLines.forEach((line, index) => {
      if (line.issuedItems && line.issuedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType && line.stockType.code} / ${
              line.lotNumber ? line.lotNumber.number : ""
            } (${line.issuedQuantity})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => getItemRowColor(record)}
              rowSelection={rowSelection}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossSqm = 0;

                pageData.forEach(
                  ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                    totalQuantity += quantity ? quantity : 1;
                    //totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5}>
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong style={{ paddingLeft: 12 }}>
                          {line.issuedItems.length}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {!isNaN(totalGrossSqm) &&
                            Number(totalGrossSqm).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const handleAllLoadChange = (e) => {
    if (e.target.checked) {
      issuedLines.forEach((line, index) => {
        setSelectedRowKeyMap(
          selectedRowKeyMap.set(
            line.key,
            line.issuedItems.map((item) => item.id)
          )
        );
      });
      updateStockItemStatus();
    } else {
      setSelectedRowKeyMap(new Map());
      const totalIssuedStocks = issuedLines.flatMap((item) => item.issuedItems);
      for (let item of totalIssuedStocks) {
        item.loaded = false;
        item.mode = "READY";
      }
      //setLoadedStocks([]);
      issuedLines.forEach((line) => {
        line.issuedQuantity = 0;
        line.issuedLineItems = 0;
      });
    }
  };

  const updateStockItemStatus = () => {
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    setSelectedRowKeys(allSelectedKeys);
    const totalRequestedStocks = stockIssue.issuedLines.flatMap(
      (item) => item.issuedItems
    );
    let loadedStocks = [];
    for (let item of totalRequestedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.loaded = true;
        item.mode = "LOADED";
        loadedStocks.push(item);
      } else {
        item.loaded = false;
        item.mode = "READY";
      }
    }
    //setLoadedStocks(loadedStocks);

    stockIssue.issuedLines.forEach((line) => {
      // console.log("Loaded Stocks:", loadedStocks);
      // console.log("line:", line);
      line.issuedQuantity = 0;
      line.issuedLineItems = 0;
      loadedStocks.forEach((stock) => {
        // console.log("Loaded Stocks:", loadedStocks);
        // console.log("line:", line);
        if (
          line.key ===
          `${stock.issuedLineId}_${stock.stockType.id}_${
            line.lotNumber ? stock.lotNumber.id : ""
          }`
        ) {
          line.issuedQuantity += stock.quantity;
          line.issuedLineItems += 1;
        }
      });
    });
  };

  const onBulkScanAndAdd = (value) => {
    if (value) {
      if (bulkScannedBarcodes.includes(value)) {
        return message.error("Barcode is already scanned!");
      }
      setBulkScannedBarcodes([...bulkScannedBarcodes, value]);
      form.setFieldsValue({ itemScan: "" });
    }
  };

  const handleBulkScannedAdd = () => {
    onSearchAndAddStockItems(bulkScannedBarcodes.toString());
    setIsBulkScanVisible(false);
  };

  const getBulkScanModalContent = () => {
    return (
      <Form form={form} name="bulk-scan-form">
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="itemScan">
              <Input.Search
                placeholder="Enter valid barcode"
                allowClear
                enterButton={<ScanOutlined />}
                onSearch={onBulkScanAndAdd}
                style={{ marginBottom: 20 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Space size={[8, 16]} wrap>
              {bulkScannedBarcodes.map((item) => (
                <Button key={item} type="primary" ghost>
                  {item}
                </Button>
              ))}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  };

  const getActionButtons = () => {
    let buttons = [];
    if (['PROCESSING', "ISSUED", "COMPLETE"].includes(stockIssue.orderStage)) {
      buttons.push(
        <Button
          key="pdf"
          icon={<FilePdfOutlined />}
          onClick={handlePrintPdf}
          loading={loadingPdf}
          title="Export to Pdf"
        ></Button>
      );
      buttons.push(
        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          onClick={handlePrintExcel}
          title="Export to Excel"
        ></Button>
      );
    }
    if (["OPEN", "PROCESSING", "IN_PROGRESS"].includes(stockIssue.orderStage)) {
      buttons.push(
        <Popconfirm
          title="Are you sure to complete this delivery with these loaded stocks?"
          onConfirm={(e) => {
            e.preventDefault();
            setIsComplete(true);
            handleFormSumbit(true);
          }}
          key="confirm"
          disabled={
            issuedLines
              .flatMap((line) => line.issuedItems)
              .filter((item) => item.loaded).length === 0
          }
        >
          <Button
            key="confirm"
            type="dashed"
            htmlType="submit"
            loading={loadingDelivery}
            disabled={
              issuedLines
                .flatMap((line) => line.issuedItems)
                .filter((item) => item.loaded).length === 0
            }
          >
            Deliver
          </Button>
        </Popconfirm>
      );

      buttons.push(
        <Popconfirm
          title="Are you sure to save these stocks?"
          onConfirm={(e) => {
            e.preventDefault();
            setIsComplete(false);
            handleFormSumbit();
          }}
          key="update-confirm"
        >
          <Button key="1" type="primary" htmlType="submit" loading={updating}>
            Update
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const handleConfirm = () => {
    setIsComplete(true);
    const requestedQuantity = stockIssue.issuedLines
      .flatMap((line) => line.requestedQuantity)
      .reduce((prev, next) => prev + next);
    const issuedQuantity = stockIssue.issuedLines
      .flatMap((line) => line.issuedQuantity)
      .reduce((prev, next) => prev + next);
    if (issuedQuantity != requestedQuantity) {
      message.error("Loaded items should equal to requested items.");
    } else {
      history.push(`/stock-issue/${id}/confirm`);
    }
  };

  const getOrderColor = (order) => {
    let orderColor = "";
    if (order && order.orderStage === "OPEN") {
      orderColor = "#87d068";
    } else if (order && order.orderStage === "PROCESSING") {
      orderColor = "#faad14";
    } else if (order && order.orderStage === "ISSUED") {
      orderColor = "#108ee9";
    } else if (order && order.orderStage === "COMPLETE") {
      orderColor = "#f50";
    }

    return orderColor;
  };

  const getLineRowColor = (record) => {
    let rowColor = "";
    if (
      record &&
      record.requestLine.requestedItems.length <= record.issuedQuantity
    ) {
      rowColor = "ant-tag-green";
    } else if (record && record.issuedQuantity > 0) {
      rowColor = "ant-tag-gold";
    }

    return rowColor;
  };

  const getItemRowColor = (record) => {
    let rowColor = "";
    if (record && record.added) {
      rowColor = "ant-tag-silver";
    } else if (record && record.added && record.loaded) {
      rowColor = "ant-tag-green";
    } else if (record && !record.added) {
      rowColor = "ant-tag-darkgrey";
    }

    return rowColor;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag color={getOrderColor(stockIssue)}>
                  {stockIssue.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: false, message: "Store is required" }]}
                  >
                    <Select placeholder="Please select">
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: false, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issuedDate"
                    label="Issue Date"
                    rules={[
                      { required: false, message: "Issue date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      onChange={setSelectedIssueDate}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="issueNumber" label="Issue Number">
                    <Input placeholder="Issue Number" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["storeAddress", "id"]}
                    label="Store Location"
                    rules={[
                      {
                        required: false,
                        message: "Store location is required",
                      },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.address}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverName"
                    label="Driver Name"
                    rules={[
                      {
                        required: isComplete,
                        message: "Driver Name is required",
                      },
                    ]}
                  >
                    <Input placeholder="Driver Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact Number"
                    rules={[
                      {
                        required: isComplete,
                        message: "Contact Number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverNic"
                    label="Driver NIC"
                    rules={[
                      { required: false, message: "Driver NIC is required" },
                    ]}
                  >
                    <Input placeholder="Driver NIC" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="sealNumber"
                    label="Seal No:"
                    rules={[
                      {
                        required: isComplete,
                        message: "Seal number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Seal No:" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["vehicle", "id"]}
                    label="Vehicle"
                    rules={[{ required: true, message: "Vehicle is required" }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp="children"
                      placeholder="Vehicle"
                    >
                      {vehicles.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="dispatchNumber"
                    label="Dispatch No:"
                    rules={[
                      {
                        required: false,
                        message: "Dispatch number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Dispatch No:" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="remark" label="Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>

              <Button
                style={{
                  width: "100%",
                  marginTop: 5,
                  marginBottom: 15,
                  color: "white",
                  backgroundColor: "#13c2c2",
                }}
                type="dashed"
                onClick={getVerifiedRequestLines}
                icon={<PlusOutlined />}
                loading={verifiedLineLoading}
              >
                Add Request Line
              </Button>

              {issuedLines.length > 0 && (
                <Table
                  bordered
                  pagination={false}
                  columns={getLineColumns()}
                  dataSource={issuedLines}
                  rowSelection={{
                    type: "radio",
                    ...issueLineRowSelection,
                  }}
                  rowClassName={(record) => getLineRowColor(record)}
                  size="small"
                  summary={(pageData) => {
                    let rollSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let boxSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let spoolSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let boxPcsSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let boxNosSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let boxConeSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let boxStripSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let boxRibnSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let bundlesSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    let defaultSummary = {
                      totalItems: 0,
                      totalQty: 0,
                      totalVerifiedItems: 0,
                      totalVerifiedQty: 0,
                      uom: "",
                      measurementType: null,
                    };
                    const groupByPackingType = Utils.groupBy(
                      pageData,
                      (line) =>
                        line.stockType &&
                        line.stockType.packingType &&
                        line.stockType.packingType.name
                    );

                    groupByPackingType.forEach((value, key) => {
                      if (key === "ROLL") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            rollSummary.totalItems += issuedLineItems;
                            rollSummary.totalQty += issuedQuantity;
                            rollSummary.measurementType = measurementType;
                            rollSummary.totalVerifiedItems += requestedQuantity;
                            rollSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BOX") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            boxSummary.totalItems += issuedLineItems;
                            boxSummary.totalQty += issuedQuantity;
                            boxSummary.measurementType = measurementType;
                            boxSummary.totalVerifiedItems += requestedQuantity;
                            boxSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "SPOOL") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            spoolSummary.totalItems += issuedLineItems;
                            spoolSummary.totalQty += issuedQuantity;
                            spoolSummary.measurementType = measurementType;
                            spoolSummary.totalVerifiedItems +=
                              requestedQuantity;
                            spoolSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BOX_PCS") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            boxPcsSummary.totalItems += issuedLineItems;
                            boxPcsSummary.totalQty += issuedQuantity;
                            boxPcsSummary.measurementType = measurementType;
                            boxPcsSummary.totalVerifiedItems +=
                              requestedQuantity;
                            boxPcsSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BOX_NOS") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            boxNosSummary.totalItems += issuedLineItems;
                            boxNosSummary.totalQty += issuedQuantity;
                            boxNosSummary.measurementType = measurementType;
                            boxNosSummary.totalVerifiedItems +=
                              requestedQuantity;
                            boxNosSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BOX_CONE") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            boxConeSummary.totalItems += issuedLineItems;
                            boxConeSummary.totalQty += issuedQuantity;
                            boxConeSummary.measurementType = measurementType;
                            boxConeSummary.totalVerifiedItems +=
                              requestedQuantity;
                            boxConeSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BOX_STRP") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            boxStripSummary.totalItems += issuedLineItems;
                            boxStripSummary.totalQty += issuedQuantity;
                            boxStripSummary.measurementType = measurementType;
                            boxStripSummary.totalVerifiedItems +=
                              requestedQuantity;
                            boxStripSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BOX_RIBN") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            boxRibnSummary.totalItems += issuedLineItems;
                            boxRibnSummary.totalQty += issuedQuantity;
                            boxRibnSummary.measurementType = measurementType;
                            boxRibnSummary.totalVerifiedItems +=
                              requestedQuantity;
                            boxRibnSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else if (key === "BUNDLES") {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            bundlesSummary.totalItems += issuedLineItems;
                            bundlesSummary.totalQty += issuedQuantity;
                            bundlesSummary.measurementType = measurementType;
                            bundlesSummary.totalVerifiedItems +=
                              requestedQuantity;
                            bundlesSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      } else {
                        value.forEach(
                          ({
                            requestedQuantity,
                            requestedTotalQuantity,
                            issuedQuantity,
                            issuedLineItems,
                            measurementType,
                          }) => {
                            defaultSummary.totalItems += issuedLineItems;
                            defaultSummary.totalQty += issuedQuantity;
                            defaultSummary.measurementType = measurementType;
                            defaultSummary.totalVerifiedItems +=
                              requestedQuantity;
                            defaultSummary.totalVerifiedQty +=
                              requestedTotalQuantity;
                          }
                        );
                      }
                    });

                    /* let totalRequestedQuantity = 0;
                    let totalRequestedQuantityWithoutStocks = 0;
                    let totalIssuedQuantity = 0;
                    let totalIssuedItems = 0;

                    pageData.forEach(
                      ({
                        requestedQuantity,
                        requestedTotalQuantity,
                        issuedQuantity,
                        issuedLineItems,
                      }) => {
                        totalRequestedQuantity += requestedQuantity;
                        totalRequestedQuantityWithoutStocks +=
                          requestedTotalQuantity;
                        totalIssuedQuantity += issuedQuantity;
                        totalIssuedItems += issuedLineItems;
                      }
                    ); */

                    return (
                      <>
                      {rollSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - ROLL</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(rollSummary.totalVerifiedItems) &&
                                  rollSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(rollSummary.totalVerifiedQty) &&
                                  Number(
                                    rollSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(rollSummary.totalItems) &&
                                  rollSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(rollSummary.totalQty) &&
                                  Number(rollSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {rollSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {boxSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BOX</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxSummary.totalVerifiedItems) &&
                                  boxSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxSummary.totalVerifiedQty) &&
                                  Number(
                                    boxSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxSummary.totalItems) &&
                                  boxSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxSummary.totalQty) &&
                                  Number(boxSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {boxSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {spoolSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - SPOOL</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(spoolSummary.totalVerifiedItems) &&
                                  spoolSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(spoolSummary.totalVerifiedQty) &&
                                  Number(
                                    spoolSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(spoolSummary.totalItems) &&
                                  spoolSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(spoolSummary.totalQty) &&
                                  Number(spoolSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {spoolSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {boxPcsSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BOX(PCS)</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxPcsSummary.totalVerifiedItems) &&
                                  boxPcsSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxPcsSummary.totalVerifiedQty) &&
                                  Number(
                                    boxPcsSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxPcsSummary.totalItems) &&
                                  boxPcsSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxPcsSummary.totalQty) &&
                                  Number(boxPcsSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {boxPcsSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {boxNosSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BOX(NOS)</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxNosSummary.totalVerifiedItems) &&
                                  boxNosSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxNosSummary.totalVerifiedQty) &&
                                  Number(
                                    boxNosSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxNosSummary.totalItems) &&
                                  boxNosSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxNosSummary.totalQty) &&
                                  Number(boxNosSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {boxNosSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {boxConeSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BOX(CONE)</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxConeSummary.totalVerifiedItems) &&
                                  boxConeSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxConeSummary.totalVerifiedQty) &&
                                  Number(
                                    boxConeSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxConeSummary.totalItems) &&
                                  boxConeSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxConeSummary.totalQty) &&
                                  Number(boxConeSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {boxConeSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {boxStripSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BOX(STRP)</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxStripSummary.totalVerifiedItems) &&
                                  boxStripSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxStripSummary.totalVerifiedQty) &&
                                  Number(
                                    boxStripSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxStripSummary.totalItems) &&
                                  boxStripSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxStripSummary.totalQty) &&
                                  Number(boxStripSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {boxStripSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {boxRibnSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BOX(RIBN)</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxRibnSummary.totalVerifiedItems) &&
                                  boxRibnSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxRibnSummary.totalVerifiedQty) &&
                                  Number(
                                    boxRibnSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxRibnSummary.totalItems) &&
                                  boxRibnSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(boxRibnSummary.totalQty) &&
                                  Number(boxRibnSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {boxRibnSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {bundlesSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total - BUNDLES</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(bundlesSummary.totalVerifiedItems) &&
                                  bundlesSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(bundlesSummary.totalVerifiedQty) &&
                                  Number(
                                    bundlesSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(bundlesSummary.totalItems) &&
                                  bundlesSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(bundlesSummary.totalQty) &&
                                  Number(bundlesSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {bundlesSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                        {defaultSummary.totalVerifiedItems > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={getLineColumns().length - 5}
                            >
                              <Text strong>Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(defaultSummary.totalVerifiedItems) &&
                                  defaultSummary.totalVerifiedItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(defaultSummary.totalVerifiedQty) &&
                                  Number(
                                    defaultSummary.totalVerifiedQty
                                  ).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(defaultSummary.totalItems) &&
                                  defaultSummary.totalItems}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(defaultSummary.totalQty) &&
                                  Number(defaultSummary.totalQty).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <Text strong>
                                {defaultSummary.measurementType.name}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                />
              )}
            </Form>
            {issuedLines.length > 0 &&
              issuedLines.map((item) => item.issuedItems) &&
              issuedLines
                .map((item) => item.issuedItems)
                .reduce((prev, next) => prev + next).length > 0 && (
                <>
                  <Divider orientation="left">
                    Stock Items -{" "}
                    <Checkbox onChange={handleAllLoadChange}>Load All</Checkbox>
                  </Divider>
                  <Tabs onChange={handleTabChange} activeKey={activeTabKey}>
                    {getTabContent(issuedLines)}
                  </Tabs>
                </>
              )}
            <Modal
              title={`Pending Requested Lines (${verifiedRequestLines.length})`}
              style={{ top: 48 }}
              visible={isAddRequestLineWindowVisible}
              okText={`Add Lines (${addedRequestLineIds.length})`}
              onOk={handleAddRequestedLines}
              okButtonProps={{ disabled: addedRequestLineIds.length === 0 }}
              onCancel={() => setIsAddRequestLineWindowVisible(false)}
              destroyOnClose={true}
              width={1800}
            >
              <RequestLineSelection
                verifiedLines={verifiedRequestLines}
                selectedIds={addedRequestLineIds}
                //customer={selectedCustomer}
                onSelectChange={handleRequestLineSelection}
              />
            </Modal>
            <Modal
              title="Bulk Scan"
              visible={isBulkScanVisible}
              okText={`Add All (${bulkScannedBarcodes.length})`}
              onOk={handleBulkScannedAdd}
              onCancel={() => setIsBulkScanVisible(false)}
              width={1300}
              destroyOnClose={true}
            >
              {getBulkScanModalContent()}
            </Modal>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymEditWithLines;
