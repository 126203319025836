import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tooltip,
  Card,
  Statistic,
  Progress,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { reportService } from "_services";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";

function ComparisonSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [stockComparison, setStockComparison] = useState({});
  const [newItems, setNewItems] = useState([]);
  const [notFoundItems, setNotFoundItems] = useState([]);
  const [foundItems, setFoundItems] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [error, setError] = useState(null);
  const { Text } = Typography;
  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    reportService
      .getComparisonById(id)
      .then((x) => {
        let result = x.content;
        result.createdAt = moment(result.createdAt);

        result.sbuName = result.sbu?.name;
        result.zoneName = result.zone?.name;
        result.subLocationName = result.subLocation?.name;
        setStockComparison(result);
        // console.log("SC: ", result);

        if (result.stockComparisonResultDetails.length > 0) {
          setNewItems(
            result.stockComparisonResultDetails.filter(
              (item) => item.status === "NEW"
            )
          );
          setNotFoundItems(
            result.stockComparisonResultDetails.filter(
              (item) => item.status === "NOT_FOUND"
            )
          );
          setFoundItems(
            result.stockComparisonResultDetails.filter(
              (item) => item.status === "FOUND"
            )
          );
        }

        console.log("Found: ", foundItems);
        onFill(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        setError(errorInfo);
        // message.error(`${errorInfo}`);
      });
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    reportService
      .exportComparisonExcel(stockComparison.id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Comparison_${stockComparison.code}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const onCancel = () => {
    history.push("/reports/comparisons");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="excel"
        icon={<FileExcelOutlined />}
        onClick={handleExportExcel}
        loading={loadingExcel}
      ></Button>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getComparisonResultColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "materialCode",
      key: "materialCode",
    });
    columns.push({
      title: "Lot No:",
      dataIndex: "lotNumberCode",
      key: "lotNumberCode",
    });
    columns.push({
      title: "BNS Material",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "BNS Lot No:",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        let color = "";
        if (text === "NEW") {
          color = "#87d068";
        }
        if (text === "FOUND") {
          color = "#fa8c16";
        }
        if (text === "NOT_FOUND") {
          color = "#ffa940";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Variance",
      dataIndex: "variance",
      key: "variance",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  const getSummaryContent = (pageData) => {
    let totalQuantity = 0;
    let totalNetWeight = 0;
    let totalVariance = 0;

    pageData.forEach(({ quantity, netWeight, variance }) => {
      totalQuantity += quantity && quantity;
      totalNetWeight += netWeight && netWeight;
      totalVariance += variance;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={5}>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalQuantity).toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalVariance).toFixed(2)}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/comparisons">Comparison Report Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockComparison.code} ::{" "}
                <Tag
                  color={`${
                    stockComparison.status === "COMPLETE"
                      ? "#87d068"
                      : stockComparison.status === "IN_PROGRESS"
                      ? "#faad14"
                      : "#2db7f5"
                  }`}
                >
                  {stockComparison.status}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip={loadingMessage} spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="code"
                    label="Stock Comparison Code"
                    rules={[{ required: false }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="createdAt"
                    label="Date"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      placeholder="Date"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="compareWith" label="Compare With">
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp="children"
                      placeholder="Compare With"
                      disabled
                    >
                      <Option value="FMIS" key="FMIS">
                        FMIS
                      </Option>
                      <Option value="EPICO" key="EPICO">
                        EPICO
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="sbuName" label="SBU">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="zoneName" label="Zone">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="subLocationName" label="Sub-Location">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Statistic
                    title={`${stockComparison.compareWith} Quantity`}
                    value={Number(stockComparison.quantity).toFixed(2)}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="BNS Quantity"
                    value={Number(stockComparison.netWeight).toFixed(2)}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Variance"
                    value={Number(stockComparison.variance).toFixed(2)}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                {notFoundItems.length > 0 && (
                  <Col span={12}>
                    <Divider orientation="left">
                      (+) {stockComparison.compareWith} Only -{" "}
                      {notFoundItems.length}
                    </Divider>
                    <Table
                      bordered
                      size="small"
                      rowKey="id"
                      columns={getComparisonResultColumns()}
                      dataSource={notFoundItems}
                      pagination={false}
                      scroll={{ x: 400 }}
                      summary={(pageData) => getSummaryContent(pageData)}
                    />
                  </Col>
                )}
                {newItems.length > 0 && (
                  <Col span={12}>
                    <Divider orientation="left">
                      (-) BNS Only - {newItems.length}
                    </Divider>
                    <Table
                      bordered
                      size="small"
                      rowKey="id"
                      columns={getComparisonResultColumns()}
                      dataSource={newItems}
                      pagination={false}
                      scroll={{ x: 400 }}
                      summary={(pageData) => getSummaryContent(pageData)}
                    />
                  </Col>
                )}
              </Row>
              {foundItems.length > 0 && (
                <>
                  <Divider orientation="left">
                    Matched - {foundItems.length}
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getComparisonResultColumns()}
                    dataSource={foundItems}
                    pagination={false}
                    summary={(pageData) => getSummaryContent(pageData)}
                  />
                </>
              )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default ComparisonSummary;
