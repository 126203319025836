import React from "react";
import StrLeftMenuSAU from "./str/LeftMenuSAU";
import StrLeftMenuCAU from "./str/LeftMenuCAU";
import StrLeftMenuCA from "./str/LeftMenuCA";
import PrymLeftMenuSAU from "./prym/LeftMenuSAU";
import PrymLeftMenuCAU from "./prym/LeftMenuCAU";
import PrymLeftMenuCA from "./prym/LeftMenuCA";
import SiluetaLeftMenuSAU from "./silueta/LeftMenuSAU";
import SiluetaLeftMenuCAU from "./silueta/LeftMenuCAU";
import SiluetaLeftMenuCA from "./silueta/LeftMenuCA";
import LeftMenuSAU from "./LeftMenuSAU";
import { Auth } from "_helpers";

function LeftMenu() {
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "STR") {
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      return <StrLeftMenuSAU />;
    } else if (Auth.hasRole(Auth.getProfile(), ["CAU"])) {
      return <StrLeftMenuCAU />;
    } else if (Auth.hasRole(Auth.getProfile(), ["CU"])) {
      return <StrLeftMenuCA />;
    } else {
      return <StrLeftMenuCA />;
    }
  } else if (loggedInCustomerCode === "PRYM") {
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      return <PrymLeftMenuSAU />;
    } else if (Auth.hasRole(Auth.getProfile(), ["CAU"])) {
      return <PrymLeftMenuCAU />;
    } else if (Auth.hasRole(Auth.getProfile(), ["CU"])) {
      return <PrymLeftMenuCA />;
    } else {
      return <PrymLeftMenuCA />;
    }
  } else if (loggedInCustomerCode === "SILUETA") {
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      return <SiluetaLeftMenuSAU />;
    } else if (Auth.hasRole(Auth.getProfile(), ["CAU"])) {
      return <SiluetaLeftMenuCAU />;
    } else if (Auth.hasRole(Auth.getProfile(), ["CU"])) {
      return <SiluetaLeftMenuCA />;
    } else {
      return <SiluetaLeftMenuCA />;
    }
  } else {
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      return <LeftMenuSAU />;
    }
  }
}

export { LeftMenu };
