import React from "react";
import { Radio } from "antd";
import { useHistory } from "react-router-dom";

function TabMenu(props) {
  let history = useHistory();

  const handleMenuChange = (e) => {
    history.push(`/packing-lists/${props.id}/${e.target.value}`);
  };

  return (
    <div>
      <Radio.Group
        value={props.menu}
        onChange={handleMenuChange}
        style={{ marginBottom: 10 }}
      >
        <Radio.Button value="summary" style={{ marginRight: 5 }}>
          Summary
        </Radio.Button>
        <Radio.Button value="grn" style={{ marginRight: 5 }}>
          GRN Details
        </Radio.Button>
      </Radio.Group>
    </div>
  );
}

export default TabMenu;
