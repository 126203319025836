import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React from "react";
import { userService } from "_services";

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.username) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `username=${encodeURIComponent(values.username)}`;
    }
    if (values.firstName) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `firstName=${encodeURIComponent(values.firstName)}`;
    }
    userService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="username" label="User name">
            <Input placeholder="Username" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="firstName" label="First name">
            <Input placeholder="First name" />
          </Form.Item>
        </Col>
        <Col span={6}></Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
