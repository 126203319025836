import {
  Descriptions,
  Table,
  Tag,
  Tooltip,
  Typography,
  Input,
  Button,
  Space,
} from "antd";
import React, { useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const RequestLineSelection = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(props.selectedIds);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //const [selectedStocks, setSelectedStocks] = useState([]);
  const searchInput = useRef(null);
  //console.log("SelectedIds:", selectedStocks);

  const { Text } = Typography;

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedItems = props.verifiedLines.filter((item) =>
      selectedRowKeys.includes(item.id)
    );
    //console.log("SelectStocks:", selectedItems);
    //setSelectedStocks(selectedItems);
    props.onSelectChange(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.added,
    }),
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? dataIndex === "runningNo"
          ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
          : record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            searchInput && searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Request Number",
      dataIndex: "requestNumber",
      key: "requestNumber",
      width: "12%",
      ...getColumnSearchProps("requestNumber"),
    });
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      width: "8%",
    });
    columns.push({
      title: "Material",
      dataIndex: "stockTypeCode",
      key: "stockTypeCode",
      width: "10%",
      ...getColumnSearchProps("stockTypeCode"),
    });
    /* columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    }); */
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumberCode",
      key: "lotNumberCode",
      width: "15%",
      ...getColumnSearchProps("lotNumberCode"),
    });
    columns.push({
      title: "Req.Box",
      dataIndex: "totalItems",
      key: "totalItems",
      width: "4%",
      align: "right",
    });
    columns.push({
      title: "Req.Quantity",
      key: "reqQuantity",
      width: "6%",
      align: "right",
      render: (text, record) => {
        return !isNaN(record.quantity) && Number(record.quantity).toFixed(2);
      },
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    /* columns.push({
      title: "Req.Cone",
      dataIndex: "qty",
      key: "qty",
      width: "4%",
      align: "right",
    }); */
    columns.push({
      title: "Veri. Box",
      key: "verifiedBox",
      width: "5%",
      align: "right",
      render: (text, record) => {
        return record.requestedItems && record.requestedItems.length;
      },
    });
    columns.push({
      title: "Veri. Quantity",
      key: "verifyQuantity",
      width: "8%",
      align: "right",
      render: (text, record) => {
        return (
          record.requestedItems.length &&
          record.requestedItems
            .map((item) => item.quantity)
            .reduce((prev, next) => prev + next)
        );
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "lineStage",
      key: "lineStage",
      width: "8%",
      render: (text) => {
        let color = "#87d068";
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  return (
    <div>
      <Table
        bordered
        rowKey="id"
        size="small"
        columns={getColumns()}
        dataSource={props.verifiedLines}
        pagination={false}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default RequestLineSelection;
