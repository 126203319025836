import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tooltip,
  Card,
  Statistic,
  Progress,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { stockCheckService } from "_services";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { StockCheckStatus, StockCheckTypes } from "_helpers";
import TabMenu from "../TabMenu";

function SiluetaSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingUnverifiedItems, setLoadingUnverifiedItems] = useState(false);
  const [loadingVerifiedItems, setLoadingVerifiedItems] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingPallets, setLoadingPallets] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [verifyingBin, setVerifyingBin] = useState(false);
  const [verifyingPallet, setVerifyingPallet] = useState(false);
  const [verifyingItem, setVerifyingItem] = useState(false);
  const [stockCheck, setStockCheck] = useState({});
  const [verifiedStocks, setVerifiedStocks] = useState([]);
  const [unverifiedStocks, setUnverifiedStocks] = useState([]);
  const [binMap, setBinMap] = useState(new Map());
  const [palletMap, setPalletMap] = useState(new Map());
  const [selectedBin, setSelectedBin] = useState(null);
  const [selectedPallet, setSelectedPallet] = useState(null);
  const [availableStocks, setAvailableStocks] = useState(0);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [countedStocks, setCountedStocks] = useState(0);
  const [countedQuantity, setCountedQuantity] = useState(0);
  const [positiveAdjStocks, setPositiveAdjStocks] = useState(0);
  const [positiveAdjQuantity, setPositiveAdjQuantity] = useState(0);
  const [negativeAdjStocks, setNegativeAdjStocks] = useState(0);
  const [negativeAdjQuantity, setNegativeAdjQuantity] = useState(0);
  const [positiveAdjustments, setPositiveAdjustments] = useState([]);
  const [negativeAdjustments, setNegativeAdjustments] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [pendingLocations, setPendingLocations] = useState([]);
  const [completedLocations, setCompletedLocations] = useState([]);
  const [error, setError] = useState(null);
  const { Text } = Typography;

  useEffect(() => {
    setLoading(true);
    stockCheckService
      .getById(id)
      .then((x) => {
        let result = x.content;
        result.startDate = moment(result.startDate);
        result.completeDate = result.completeDate
          ? moment(result.completeDate)
          : moment();

        result.sbuName = result.subLocation?.zone?.sbu?.name;
        result.zoneName = result.subLocation?.zone?.name;
        result.subLocationName = result.subLocation?.name;
        setStockCheck(result);
        // console.log('SC: ', result);
        if (result.locations.length > 0) {
          setAvailableStocks(
            result.locations
              .map((item) => item.expectedItemQty)
              .reduce((prev, next) => prev + next)
          );
          setAvailableQuantity(
            result.locations
              .map((item) => item.expectedQty)
              .reduce((prev, next) => prev + next)
          );
          setCountedStocks(
            result.locations
              .map((item) => item.countedItemQty)
              .reduce((prev, next) => prev + next)
          );
          setCountedQuantity(
            result.locations
              .map((item) => item.countedQty)
              .reduce((prev, next) => prev + next)
          );
          setNegativeAdjStocks(
            result.locations
              .map((item) => item.negativeAdjItemQty)
              .reduce((prev, next) => prev + next)
          );
          setNegativeAdjQuantity(
            result.locations
              .map((item) => item.negativeAdjNetWeight)
              .reduce((prev, next) => prev + next)
          );
          setPositiveAdjStocks(
            result.locations
              .map((item) => item.positiveAdjItemQty)
              .reduce((prev, next) => prev + next)
          );
          setPositiveAdjQuantity(
            result.locations
              .map((item) => item.positiveAdjNetWeight)
              .reduce((prev, next) => prev + next)
          );
          setPendingLocations(
            result.locations.filter((item) => item.checkStatus !== "COMPLETE")
          );
          setCompletedLocations(
            result.locations.filter((item) => item.checkStatus === "COMPLETE")
          );
        }

        if (result.adjustments.length > 0) {
          setPositiveAdjustments(
            result.adjustments
              .filter((item) => item.adjustmentType === "POSITIVE")
              .map((item) => {
                return {
                  ...item.stockItem,
                  adjustmentType: item.adjustmentType,
                  movedFromBin: item.movedFromBin,
                };
              })
          );
          setNegativeAdjustments(
            result.adjustments
              .filter((item) => item.adjustmentType === "NEGATIVE")
              .map((item) => {
                return {
                  ...item.stockItem,
                  adjustmentType: item.adjustmentType,
                  movedFromBin: item.movedFromBin,
                };
              })
          );
        }
        /* if (result.stockCheckType === 'I') {
          if (result.stockCheckItems) {
            const unverified = result.stockCheckItems.filter(
              (item) => item.mode === 'UNVERIFIED'
            );
            const verified = result.stockCheckItems.filter(
              (item) => item.mode === 'VERIFIED'
            );

            setUnverifiedStocks(unverified);
            setVerifiedStocks(verified);
          }
        } else if (result.stockCheckType === 'P') {
          if (result.stockCheckPalletSummaries) {
            const unverified = result.stockCheckPalletSummaries.filter(
              (item) => item.mode === 'UNVERIFIED'
            );
            const verified = result.stockCheckPalletSummaries.filter(
              (item) => item.mode === 'VERIFIED'
            );

            setUnverifiedStocks(unverified);
            setVerifiedStocks(verified);
          }
        } else if (result.stockCheckType === 'B') {
          if (result.stockCheckBinSummaries) {
            const unverified = result.stockCheckBinSummaries.filter(
              (item) => item.mode === 'UNVERIFIED'
            );
            const verified = result.stockCheckBinSummaries.filter(
              (item) => item.mode === 'VERIFIED'
            );

            setUnverifiedStocks(unverified);
            setVerifiedStocks(verified);
          }
        } else {
          if (result.stockCheckBinSummaries) {
            const unverified = result.stockCheckBinSummaries.filter(
              (item) => item.mode === 'UNVERIFIED'
            );
            const verified = result.stockCheckBinSummaries.filter(
              (item) => item.mode === 'VERIFIED'
            );

            setUnverifiedStocks(unverified);
            setVerifiedStocks(verified);
          }
        } */

        onFill(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        setError(errorInfo);
        // message.error(`${errorInfo}`);
      });

    /* companyService.getSuggestion('?companyType=STORE').then((x) => {
      setStores(x.content);
    });
    companyService.getSuggestion('?companyType=CUSTOMER').then((x) => {
      setCustomers(x.content);
    });
    getStockTypes();
    getLotNumbers();
    getSuppliers();
    getStoreBins(); */
  }, []);

  /* const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  /* const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  /* const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  /* const getStoreBins = () => {
    binService
      .getSuggestion('')
      .then((y) => {
        setStoreBins(y.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onSearchAndVerify = (type, value) => {
    if (value) {
      if (type === "Bin") {
        setVerifyingBin(true);
      } else if (type === "Pallet") {
        setVerifyingPallet(true);
      } else {
        setVerifyingItem(true);
      }
      stockCheckService
        .verify(type, stockCheck.id, `?serialNo=${encodeURIComponent(value)}`)
        .then((data) => {
          if (data.content && data.content.serialNo === value) {
            const checkedItem = data.content;
            setLoadingUnverifiedItems(true);
            setLoadingVerifiedItems(true);

            const tmpUnverified = [...unverifiedStocks];
            tmpUnverified.forEach((item) => {
              if (item.id === checkedItem.id) {
                tmpUnverified.splice(tmpUnverified.indexOf(item), 1);
                setUnverifiedStocks(tmpUnverified);
                setVerifiedStocks([...verifiedStocks, checkedItem]);
              }
            });
            if (tmpUnverified.includes(checkedItem)) {
            }
            setLoadingUnverifiedItems(false);
            setLoadingVerifiedItems(false);

            message.success(`${type} Successfully verified!`);

            form.setFieldsValue({ verifyBin: null });
            form.setFieldsValue({ verifyPallet: null });
            form.setFieldsValue({ verifyItem: null });
          } else {
            message.error(`Sorry, No ${type} found against the barcode.`);
          }
          if (type === "Bin") {
            setVerifyingBin(false);
          } else if (type === "Pallet") {
            setVerifyingPallet(false);
          } else {
            setVerifyingItem(false);
          }
        })
        .catch((error) => {
          if (type === "Bin") {
            setVerifyingBin(false);
          } else if (type === "Pallet") {
            setVerifyingPallet(false);
          } else {
            setVerifyingItem(false);
          }
          setError(error);
        });
    } else {
      message.error(`Please enter valid ${type} barcode.`);
    }
  };

  const getItemsByBin = (expanded, record) => {
    setSelectedBin(record.id);
    setLoadingPallets(true);
    stockCheckService
      .getByBinId(stockCheck.id, record.id)
      .then((data) => {
        setBinMap(binMap.set(record.id, data.content));
        setLoadingPallets(false);
      })
      .catch((error) => {
        setLoadingPallets(false);
        setError(error);
      });
  };

  const getItemsByPallet = (expanded, record) => {
    setSelectedPallet(record.id);
    setLoadingItems(true);
    stockCheckService
      .getByPalletId(stockCheck.id, record.id)
      .then((data) => {
        setPalletMap(palletMap.set(record.id, data.content));
        setLoadingItems(false);
      })
      .catch((error) => {
        setLoadingItems(false);
        setError(error);
      });
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    stockCheckService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Check-${stockCheck.requestNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handlePrintSummaryPdf = () => {
    setLoadingPdf(true);
    stockCheckService.printSummaryPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Check-Summary_${stockCheck.requestNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    stockCheckService
      .exportExcel(stockCheck.id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Stock-Check_${stockCheck.code}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingMessage("Completion is in progress. Please wait...");
        setLoading(true);
        const payload = {
          checkStatus: "COMPLETE",
          checkedBy: values.checkedBy,
          completeDate: moment(values.completeDate).format("YYYY-MM-DD"),
        };
        // console.log('Update: ', payload);
        stockCheckService
          .update(id, payload)
          .then(() => {
            setLoading(false);
            message.success("Stock check completion success!");
            setLoadingMessage("Loading...");
            window.location.reload();
          })
          .catch((error) => {
            setLoading(false);
            setError(error);
            setLoadingMessage("Loading...");
          });
      })
      .catch((errorInfo) => {});
  };

  const handleAdjustment = () => {
    setLoadingMessage("Adjustment is in progress. Please wait...");
    setLoading(true);
    stockCheckService
      .performAdjustment(id)
      .then(() => {
        setLoading(false);
        message.success("Stock adjustment completed successfuly!");
        setLoadingMessage("Loading...");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        setLoadingMessage("Loading...");
      });
  };

  const handleSignOff = () => {
    setLoadingMessage("Stock signoff is in progress. Please wait...");
    setLoading(true);
    stockCheckService
      .performSignOff(id)
      .then(() => {
        setLoading(false);
        message.success("Stock signoff completed successfuly!");
        setLoadingMessage("Loading...");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setLoadingMessage("Loading...");
        setError(error);
      });
  };

  const onCancel = () => {
    history.push("/stock-check");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="excel"
        icon={<FileExcelOutlined />}
        onClick={handleExportExcel}
        loading={loadingExcel}
      ></Button>
    );
    buttons.push(
      <Popconfirm
        title="Are you sure to signoff?"
        onConfirm={handleSignOff}
        key="signoff-confirm"
      >
        {stockCheck.checkStatus === "COMPLETE" && (
          <Button key="signoff" htmlType="button" type="primary">
            Signoff
          </Button>
        )}
      </Popconfirm>
    );
    /* buttons.push(
      <Popconfirm
        title="Are you sure to perform adjustment?"
        onConfirm={handleAdjustment}
        key="adjust-confirm"
      >
        {stockCheck.checkStatus !== "COMPLETE" && (
          <Button key="adjust" htmlType="button" type="primary">
            Adjust
          </Button>
        )}
      </Popconfirm>
    ); */
    buttons.push(
      <Popconfirm
        title="Are you sure to complete this check?"
        onConfirm={handleFormSumbit}
        key="update-complete"
      >
        {stockCheck.checkStatus !== "COMPLETE" && (
          <Button key="update" htmlType="submit" type="primary">
            Complete
          </Button>
        )}
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      //width: "18%",
      //fixed: "left",
    });
    columns.push({
      title: "Box No:",
      dataIndex: "runningNo",
      key: "runningNo",
      //width: "4%",
      //fixed: "left",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      //width: "7%",
      //fixed: "left",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "Moved From",
      dataIndex: "movedFromBin",
      key: "movedFromBin",
      //width: "7%",
      //fixed: "left",
      render: (text, record) => {
        return record.movedFromBin && record.movedFromBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      //width: "8%",
      //fixed: "left",
      render: (text) => {
        let color = "";
        if (text === "VERIFIED") {
          color = "#87d068";
        }
        if (text === "UNVERIFIED") {
          color = "#fa8c16";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "V.Batch",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      //width: "4%",
      //fixed: "left",
    });
    columns.push({
      title: "Adjustment",
      dataIndex: "adjustmentType",
      key: "adjustmentType",
      //width: "8%",
      //fixed: "left",
      render: (text) => {
        let color = "";
        if (text === "POSITIVE") {
          color = "#87d068";
        }
        if (text === "NEGATIVE") {
          color = "#fa8c16";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  const getPalletItemColumns = () => {
    let palletColumns = [];
    palletColumns.push({
      title: "Pallet Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      // width: '15%',
      // fixed: 'left',
    });
    palletColumns.push({
      title: "Pallet No:",
      dataIndex: "pallet",
      key: "pallet",
      // width: '4%',
      // fixed: 'left',
    });
    /* palletColumns.push({
      title: 'Bin',
      dataIndex: 'stockBin',
      key: 'stockBin',
      // width: '7%',
      // fixed: 'left',
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    }); */
    palletColumns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      // width: '8%',
      // fixed: 'left',
      render: (text) => {
        let color = "";
        if (text === "VERIFIED") {
          color = "#87d068";
        }
        if (text === "UNVERIFIED") {
          color = "#fa8c16";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    palletColumns.push({
      title: "Total Box",
      dataIndex: "numberOfBox",
      key: "numberOfBox",
      align: "right",
      // width: '10%',
    });
    palletColumns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      // width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    palletColumns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      // width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return palletColumns;
  };

  const getBinItemColumns = () => {
    let binColumns = [];
    binColumns.push({
      title: "Bin Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      // width: '15%',
      // fixed: 'left',
    });
    binColumns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      // width: '7%',
      // fixed: 'left',
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    binColumns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      // width: '8%',
      // fixed: 'left',
      render: (text) => {
        let color = "";
        if (text === "VERIFIED") {
          color = "#87d068";
        }
        if (text === "UNVERIFIED") {
          color = "#fa8c16";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    binColumns.push({
      title: "Total Box",
      dataIndex: "numberOfBox",
      key: "numberOfBox",
      align: "right",
      // width: '10%',
    });
    binColumns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      // width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    binColumns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      // width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return binColumns;
  };

  const locationColumns = [
    {
      title: "Bin Code",
      key: "code",
      render: (text, record) => (
        <Link
          to={`/stock-check/${id}/locations/${record.id}/summary`}
          target="_blank"
        >
          {record.stockBin.code}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "checkStatus",
      key: "checkStatus",
      render: (checkStatus) => {
        let color = "";
        if (["COMPLETE"].includes(checkStatus)) {
          color = "#87d068";
        }
        if (checkStatus === "OPEN") {
          color = "#2db7f5";
        }
        if (["IN_PROGRESS"].includes(checkStatus)) {
          color = "#faad14";
        }
        return <Tag color={color}>{checkStatus}</Tag>;
      },
    },
    {
      title: "Available Stocks",
      dataIndex: "expectedItemQty",
      key: "expectedItemQty",
      align: "right",
    },
    {
      title: "Available Quantity",
      dataIndex: "expectedQty",
      key: "expectedQty",
      align: "right",
      render: (text, record) => Number(text).toFixed(2),
    },
  ];

  const getColumnContent = () => {
    if (stockCheck && stockCheck.stockCheckType === "I") {
      return getStockItemColumns();
    } else if (stockCheck && stockCheck.stockCheckType === "P") {
      return getPalletItemColumns();
    } else if (stockCheck && stockCheck.stockCheckType === "B") {
      return getBinItemColumns();
    } else {
      return getBinItemColumns();
    }
  };

  const getSummaryContent = (pageData) => {
    let totalQuantity = 0;
    let totalItems = 0;

    pageData.forEach(({ quantity }) => {
      totalQuantity += quantity;
      totalItems += 1;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={6}>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{totalItems}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalQuantity).toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell />
        </Table.Summary.Row>
      </>
    );
  };

  const getLocationSummaryContent = (pageData) => {
    let totalItems = 0;
    let totalQuantity = 0;

    pageData.forEach(({ expectedItemQty, expectedQty }) => {
      totalItems += expectedItemQty;
      totalQuantity += expectedQty;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{totalItems}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalQuantity).toFixed(2)}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const expandedBinRender = (record) => {
    return (
      <Table
        rowKey="id"
        columns={getPalletItemColumns()}
        dataSource={
          binMap.get(record.id) &&
          binMap.get(record.id).length > 0 &&
          binMap.get(record.id)
        }
        pagination={false}
        loading={selectedBin === record.id && loadingPallets}
        expandable={{
          expandedRowRender: (record) => expandedPalletRender(record),
          onExpand: getItemsByPallet,
        }}
      />
    );
  };

  const expandedPalletRender = (record) => {
    return (
      <Table
        rowKey="id"
        columns={getStockItemColumns()}
        dataSource={
          palletMap.get(record.id) &&
          palletMap.get(record.id).length > 0 &&
          palletMap.get(record.id)
        }
        pagination={false}
        loading={selectedPallet === record.id && loadingItems}
      />
    );
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-check">Stock Check Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockCheck.code} ::{" "}
                <Tag
                  color={`${
                    stockCheck.checkStatus === "COMPLETE"
                      ? "#87d068"
                      : stockCheck.checkStatus === "IN_PROGRESS"
                      ? "#faad14"
                      : "#2db7f5"
                  }`}
                >
                  {stockCheck.checkStatus}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} />
          <Spin tip={loadingMessage} spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="code"
                    label="Stock Check Code"
                    rules={[{ required: false }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      placeholder="Start date"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="checkStatus" label="Stock Check Status">
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp="children"
                      placeholder="Stock Check Status"
                      disabled
                    >
                      {StockCheckStatus.map((t) => (
                        <Select.Option value={t.value} key={t.value}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sbuName" label="SBU">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="stockCheckType" label="Stock Check Type">
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp="children"
                      placeholder="Stock Check Type"
                      disabled
                    >
                      {StockCheckTypes.map((t) => (
                        <Select.Option value={t.value} key={t.value}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="completeDate"
                    label="Complete Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Complete date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="checkedBy"
                    label="Complete By"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Complete By" allowClear={true} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="zoneName" label="Zone">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={18}></Col>
                <Col span={6}>
                  <Form.Item name="subLocationName" label="Sub-Location">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              {availableStocks > 0 && (
                <Progress
                  percent={parseInt(
                    ((countedStocks + negativeAdjStocks) * 100) /
                      availableStocks
                  )}
                />
              )}
              <Row gutter={24}>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="Expected Items"
                      value={availableStocks}
                      valueStyle={{ color: "#3f8600" }}
                    />
                    <Statistic
                      title="Expected Quantity"
                      value={Number(availableQuantity).toFixed(2)}
                      valueStyle={{ color: "#3f8600" }}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="Counted Items"
                      value={countedStocks ? countedStocks : 0}
                      valueStyle={{ color: "#3f8600" }}
                    />
                    <Statistic
                      title="Counted Quantity"
                      value={Number(countedQuantity).toFixed(2)}
                      valueStyle={{ color: "#3f8600" }}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="(+)Adjustment Items"
                      value={positiveAdjustments.length}
                      valueStyle={{ color: "#3f8600" }}
                    />
                    <Statistic
                      title="(+)Adjustment Quantity"
                      value={
                        positiveAdjustments.length > 0
                          ? Number(
                              positiveAdjustments
                                .map((item) => item.quantity)
                                .reduce((prev, next) => prev + next)
                            ).toFixed(2)
                          : 0
                      }
                      valueStyle={{ color: "#3f8600" }}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="(-)Adjustment Items"
                      value={negativeAdjustments.length}
                      valueStyle={{ color: "#3f8600" }}
                    />
                    <Statistic
                      title="(-)Adjustment Quantity"
                      value={
                        negativeAdjustments.length > 0
                          ? Number(
                              negativeAdjustments
                                .map((item) => item.quantity)
                                .reduce((prev, next) => prev + next)
                            ).toFixed(2)
                          : 0
                      }
                      valueStyle={{ color: "#3f8600" }}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={24}>
                {positiveAdjustments.length > 0 && (
                  <Col span={12}>
                    <Divider orientation="left">
                      (+)Adjustments - {positiveAdjustments.length}
                    </Divider>
                    <Table
                      bordered
                      size="small"
                      rowKey="id"
                      columns={getStockItemColumns()}
                      dataSource={positiveAdjustments}
                      pagination={false}
                      //scroll={{ x: 1200 }}
                      summary={(pageData) => getSummaryContent(pageData)}
                    />
                  </Col>
                )}
                {negativeAdjustments.length > 0 && (
                  <Col span={12}>
                    <Divider orientation="left">
                      (-)Adjustments - {negativeAdjustments.length}
                    </Divider>
                    <Table
                      bordered
                      size="small"
                      rowKey="id"
                      columns={getStockItemColumns()}
                      dataSource={negativeAdjustments}
                      pagination={false}
                      //scroll={{ x: 1300 }}
                      summary={(pageData) => getSummaryContent(pageData)}
                    />
                  </Col>
                )}
              </Row>
            </Form>
            <Row gutter={24}>
              {pendingLocations.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left" style={{ marginTop: 20 }}>
                    Pending Locations - {pendingLocations.length}
                  </Divider>
                  <Table
                    bordered
                    rowKey="id"
                    size="small"
                    columns={locationColumns}
                    dataSource={pendingLocations}
                    pagination={false}
                    //scroll={{ x: 1300 }}
                    summary={(pageData) => getLocationSummaryContent(pageData)}
                  />
                </Col>
              )}
              {completedLocations.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left" style={{ marginTop: 20 }}>
                    Completed Locations - {completedLocations.length}
                  </Divider>
                  <Table
                    bordered
                    rowKey="id"
                    size="small"
                    columns={locationColumns}
                    dataSource={completedLocations}
                    pagination={false}
                    //scroll={{ x: 1300 }}
                    summary={(pageData) => getLocationSummaryContent(pageData)}
                  />
                </Col>
              )}
            </Row>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default SiluetaSummary;
