import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Table,
  Tag,
  Typography,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import Page from "_components/Page";
import { materialDetailService } from "_services";
import MaterailDetailSearch from "./Search";
import {
  BarcodeOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Auth, InspectionStatuses } from "_helpers";

const StrRmList = ({ history, match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingBarcodes, setLoadingBarcodes] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalGrossMeasure, setTotalGrossMeasure] = useState(0);
  const [searchParams, setSearchParams] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const { Text } = Typography;

  useEffect(() => {
    //getItems(pageNumber, pageSize);
  }, []);

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    materialDetailService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        const grossMeasures = Object.values(data.pagination.totalGross);
        const grossMeasureSum =
          grossMeasures.length > 0 &&
          grossMeasures.reduce((prev, next) => prev + next);
        setTotalGrossMeasure(grossMeasureSum);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const handleSearchResults = (results, pagination, searchParams) => {
    setItems(results);
    if (pagination) {
      setTotalRecords(pagination.totalRecords);
      const grossMeasures = Object.values(pagination.totalGross);
      const grossMeasureSum =
        grossMeasures.length > 0 &&
        grossMeasures.reduce((prev, next) => prev + next);
      setTotalGrossMeasure(grossMeasureSum);
    }
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    //console.log("Page ChANGE:", pageNumber, ", pageSize:", pageSize);
    getItems(pageNumber, pageSize);
  };

  const onSelectChange = (selectedRowKeys) => {
    //console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedItems(
      items
        .filter((item) => selectedRowKeys.includes(item.id))
        .map((item) => ({
          id: item.id,
          preReceiptNumber:
            item.stockPreReceiptLine &&
            item.stockPreReceiptLine.stockPreReceipt &&
            item.stockPreReceiptLine.stockPreReceipt.preReceiptNumber,
          serialNo: item.serialNo,
          runningNo: item.runningNo,
          lotNumber: item.lotNumber.number,
          itemCode: item.stockType.code,
          itemDesc: item.stockType.name,
          quantity: item.quantity,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
          palletNo: item.palletNo,
          supplier: item.supplier && item.supplier.code,
          productionDate: item.productionDate,
          receivedDate: item.orderDate,
          inspectionStatus: item.inspectionStatus,
          binCode: item.stockBin && item.stockBin.code,
          tempNumber: item.tempNumber,
        }))
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
    width: "5%",
    /* selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ], */
  };

  /* const handleDelete = (id) => {
    materialDetailService
      .delete(id)
      .then((x) => {
        getItems(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  /*const handleBinTransfer = () => {
    const stockItems = items.filter(
      (item) =>
        selectedRowKeys.includes(item.id) &&
        ["AVAILABLE"].includes(item.mode)
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/bin-transfer`,
        store: stockItems[0].owner,
        storeLocation: stockItems[0].stockBin.storeLocation,
        stockItems: stockItems,r
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  };*/

  /* const handleMaterialTypeTransfer = () => {
    const stockItems = items.filter(
      (item) => selectedRowKeys.includes(item.id) && item.mode === "AVAILABLE"
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/material-type-transfer`,
        stockItems: stockItems,
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  }; */

  /* const handleLotNumberTransfer = () => {
    const stockItems = items.filter(
      (item) => selectedRowKeys.includes(item.id) && item.mode === "AVAILABLE"
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/lot-transfer`,
        stockItems: stockItems,
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  }; */

  const handleGenerateBarcodes = () => {
    if (selectedRowKeys.length > 0) {
      const payload = {
        stockItems: selectedRowKeys.map((item) => ({
          id: item,
        })),
      };
      //console.log("Barcode PL:", payload);
      setLoadingBarcodes(true);
      materialDetailService
        .generateAndDownloadBarcodes(payload)
        .then((response) => {
          /* var blob=new Blob([response], {type:"application/pdf"});
          var link=document.createElement('a');
          link.href=window.URL.createObjectURL(blob);
          link.download="Report_"+new Date()+".pdf";
          link.click(); */

          const url = window.webkitURL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Barcodes_${moment(new Date()).format("YYYY-MM-DD hh:mm")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoadingBarcodes(false);
        })
        .catch((error) => {
          setLoadingBarcodes(false);
          message.error(error);
        });
    }
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    materialDetailService
      .exportExcel(searchParams)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Details_${moment(new Date()).format("YYYY-MM-DD hh:mm")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (selectedRowKeys.length > 0) {
      buttons.push(
        <span style={{ marginLeft: 8 }}>
          Selected {selectedRowKeys.length} items
        </span>
      );
    }
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          disabled={items.length === 0}
          onClick={handleExportExcel}
          loading={loadingExcel}
        ></Button>
      );
      /* buttons.push(
        <CSVLink
          key='download'
          data={selectedItems}
          filename={'stock-details.csv'}
          headers={[
            { label: 'Invoice Number', key: 'preReceiptNumber' },
            { label: 'Material Code', key: 'itemCode' },
            { label: 'Lot No', key: 'lotNumber' },
            { label: 'Box Number', key: 'runningNo' },
            { label: 'Net Weight', key: 'netWeight' },
            { label: 'Gross Weight', key: 'grossWeight' },
            { label: 'Cones', key: 'quantity' },
            { label: 'Production Date', key: 'productionDate' },
            { label: 'Pallet No', key: 'palletNo' },
            { label: 'Bin Code', key: 'binCode' },
            { label: 'Temp Number', key: 'tempNumber' },
          ]}
        >
          <Button
            icon={<DownloadOutlined />}
            disabled={selectedRowKeys.length === 0}
          ></Button>
        </CSVLink>
      ); */
      /* buttons.push(
        <Button
          key="update"
          icon={<UploadOutlined />}
          href={`${path}/update-via-upload`}
        ></Button>
      ); */
      buttons.push(
        <Button
          key="barcode"
          icon={<BarcodeOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={handleGenerateBarcodes}
          loading={loadingBarcodes}
        ></Button>
      );
      /*buttons.push(
        <Button
          key="bin-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleBinTransfer}
        >
          Bin Transfer
        </Button>
      );*/
      /* buttons.push(
        <Button
          key="material-type-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleMaterialTypeTransfer}
        >
          MT Transfer
        </Button>
      ); */
      /* buttons.push(
        <Button
          key="lotNumber-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleLotNumberTransfer}
        >
          Lot Transfer
        </Button>
      ); */
    }
    return buttons;
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Sub Location",
      key: "subLocation",
      width: "8%",
      fixed: "left",
      render: (text, record) => {
        return (
          <>
            <Link to={`/material-details/${record.id}/summary`}>
              {record.stockBin.subLocation &&
                record.stockBin.subLocation.shortCode}
            </Link>
            <Input value={record.id} hidden />
          </>
        );
      },
    });
    columns.push({
      title: "Material Code",
      dataIndex: "stockType",
      key: "stockType",
      width: "8%",
      fixed: "left",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    /* columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    }); */
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      fixed: "left",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.lotNumber) {
          return (
            <Tooltip placeholder="topLeft" title={record.lotNumber.number}>
              {record.lotNumber.number}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Receipt No:",
      key: "preReceiptNumber",
      width: "12%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt
        ) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={
                record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber
              }
            >
              <Link
                to={`/packing-lists/${record.stockPreReceiptLine.stockPreReceipt.id}/summary`}
                target="_blank"
              >
                {record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber}
              </Link>
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "MRN No:",
      key: "mrnNumber",
      width: "8%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.requestedLine && record.requestedLine.issueRequest) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={`${
                record.requestedLine.mrnNumber
                  ? record.requestedLine.mrnNumber
                  : ""
              } (${record.requestedLine.issueRequest.requestNumber})`}
            >
              <Link
                to={`/material-requests/${record.requestedLine.issueRequest.id}/summary`}
                target="_blank"
              >
                {`${
                  record.requestedLine.mrnNumber
                    ? record.requestedLine.mrnNumber
                    : ""
                } (${record.requestedLine.issueRequest.requestNumber})`}
              </Link>
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Delivery No:",
      key: "deliveryNumber",
      width: "10%",
      render: (text, record) => {
        if (
          record.issuedLine &&
          record.issuedLine.stockIssue &&
          record.issuedLine.stockIssue.issueNumber
        ) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.issuedLine.stockIssue.issueNumber}
            >
              <Link
                to={`/stock-issue/${record.issuedLine.stockIssue.id}/summary`}
                target="_blank"
              >
                {record.issuedLine.stockIssue.issueNumber}
              </Link>
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Rec. Date",
      key: "receivedDate",
      dataIndex: "receivedDate",
      width: "15%",
    });
    columns.push({
      title: "Prod Date",
      key: "productionDate",
      dataIndex: "productionDate",
      width: "9%",
    });
    columns.push({
      title: "Supplier",
      key: "supplier",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.supplier?.name}
            >
              {record.supplier?.code}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Width",
      key: "width",
      dataIndex: "width",
      width: "5%",
    });
    columns.push({
      title: "Color Code",
      key: "colorCode",
      dataIndex: "colorCode",
      width: "5%",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "6%",
      fixed: "right",
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={
              record.stockBin.subLocation &&
              record.stockBin.subLocation.shortCode
            }
          >
            {record.stockBin.code}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Plt No",
      dataIndex: "palletNo",
      key: "palletNo",
      width: "4%",
      fixed: "right",
    });
    columns.push({
      title: "Box No:",
      dataIndex: "tempNumber",
      key: "tempNumber",
      width: "5%",
      fixed: "right",
    });
    /* columns.push({
      title: "Invoice Date",
      key: "createdAt",
      width: "7%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(record.stockPreReceiptLine.stockPreReceipt.orderDate).format(
            "YYYY-MM-DD"
          )
        );
      },
    }); */
    /* columns.push({
      title: "Insp.Status",
      key: "inspectionStatus",
      dataIndex: "inspectionStatus",
      width: "10%",
      fixed: "right",
      render: (text) => {
        let color = "";
        if (text === "UR") {
          color = "#87d068";
        }
        if (text === "D") {
          color = "#ffa940";
        }
        if (text === "R") {
          color = "#f50";
        }
        if (text === "W") {
          color = "#ffa940";
        }
        if (text === "Q") {
          color = "#ffa940";
        }
        if (text === "S") {
          color = "#ffa940";
        }
        return (
          <Tag color={color}>
            {InspectionStatuses.find((status) => status.value === text)
              ? InspectionStatuses.find((status) => status.value === text).name
              : ""}
          </Tag>
        );
      },
    }); */
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "8%",
      fixed: "right",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUEST_SCANNED") {
          color = "#2db7f5";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      width: "5%",
      fixed: "right",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "6%",
      fixed: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    /* columns.push({
      title: 'Gross.W',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      align: 'right',
      width: '6%',
      fixed: 'right',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    }); */
    /* columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "4%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    }); */
    /* columns.push({
      title: 'Due Days',
      key: 'dueDays',
      width: '5%',
      fixed: 'right',
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(moment()).diff(
            record.stockPreReceiptLine.stockPreReceipt.orderDate,
            'days'
          )
        );
      },
    }); */

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <MaterailDetailSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
            loggedInCustomerCode={loggedInCustomerCode}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            dataSource={items}
            rowSelection={rowSelection}
            scroll={{ x: 1500 }}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: true,
              onChange: handlePaginateChange,
            }}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={getColumns().length - 2}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong style={{ paddingLeft: 12 }}>
                        {totalRecords}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    {/* <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell> */}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrRmList;
