import { CloseOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  PageHeader,
  Popconfirm,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { companyService } from "_services";
import CompanySearch from "./Search";

const List = ({ match }) => {
  const { path } = match;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState("");

  useEffect(() => {
    getItems(pageNumber, pageSize);
  }, []);

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    companyService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);

        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
        setLoading(false);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getItems(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    companyService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getItems(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      buttons.push(
        <Link to={`${path}/add`} key="1">
          <Button>Add</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => (
        <Link to={`/companies/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "companyType",
      key: "companyType",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDelete(record.id)}
            disabled={!Auth.hasRole(Auth.getProfile(), ["SAU"])}
          >
            {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) && (
              <CloseOutlined />
            )}
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies">Company Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) && (
            <CompanySearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
            />
          )}
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
