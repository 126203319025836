import React, {Component} from 'react';
import {Avatar, Badge, Layout,} from 'antd';
import AppHeader from '_components/AppHeader';
//import logo from "../logo.svg";
import logo from 'logo.svg';
import './layout.scss';
import { LeftMenu } from '_components/LeftMenu';
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import MediaQuery from 'react-responsive';

const {Header, Sider, Content, Footer} = Layout;

class AppLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    render() {

        return (

            <Layout className='layout' style={{minHeight: '100%'}}>
                <MediaQuery query="(min-device-width: 1024px)">
                    <Sider collapsed={this.state.collapsed}>
                        <div className="logo">
                            <a href='/'>
                                <img src={logo} alt='log'/>
                                {/*<h1>FingerTips</h1>*/}
                            </a>
                        </div>
                        <LeftMenu></LeftMenu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{padding: 0}}>
                            <div className='appHeader'>
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: () => this.setState({collapsed: !this.state.collapsed})
                                })}

                                <AppHeader/>

                            </div>

                        </Header>
                        <Content
                            className="site-layout-background"
                            style={{
                                minHeight: 280,
                            }}
                        >
                            {this.props.children}
                        </Content>

                        <Footer style={{textAlign: 'center'}}>
                            Fingertips Admin © 2022 Bottleneck Solutions
                        </Footer>
                    </Layout>
                </MediaQuery>
                <MediaQuery query="(max-device-width: 1023px)">
                    <div className='mobile-header'>
                        <div className="logo">
                            <a href='/'>
                                <img src={logo} alt='log'/>
                                {/*<h1>FingerTips</h1>*/}
                            </a>
                        </div>
                        <Header className="site-layout-background" style={{padding: 0}}>
                            <div className='appHeader'>
                                <AppHeader/>
                            </div>
                        </Header>
                    </div>
                    <Layout className="site-layout">

                        <Content
                            className="site-layout-background"
                            style={{
                                minHeight: 280,
                            }}
                        >
                            <div className='mobileScroll'>{this.props.children}</div>
                            <LeftMenu/>
                        </Content>
                    </Layout>
                </MediaQuery>
            </Layout>

        )
            ;
    }
}

export default AppLayout;
