import {
  Breadcrumb,
  Button,
  message,
  PageHeader,
  Popconfirm,
  Table,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { lotNumberService } from "_services";
import LotNumberSearch from "./Search";
import { Auth } from "_helpers";

const List = ({ match }) => {
  const { path } = match;
  const [lotNumbers, setLotNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState("");
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const [error, setError] = useState(null);

  useEffect(() => {
    getLotNumbers(pageNumber, pageSize);
  }, []);

  const getLotNumbers = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    lotNumberService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setLotNumbers(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setLotNumbers(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getLotNumbers(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    lotNumberService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getLotNumbers(pageNumber, pageSize);
      })
      .catch((error) => {
        setError(error);
        //message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Link to={`${path}/add`} key="1">
          <Button>Add</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "number",
      key: "number",
      render: (text, record) => (
        <Link to={`/lotnumbers/${record.id}/summary`}>{text ? text : "_"}</Link>
      ),
    },
    {
      title: "Stock Class",
      dataIndex: "stockClass",
      key: "stockClass",
      render: (text, record) => text && text.name,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) && (
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() => handleDelete(record.id)}
            >
              <CloseOutlined />
            </Popconfirm>
          )
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/lotnumbers">
                {`${
                  ["SILUETA"].includes(loggedInCustomerCode)
                    ? "Vendor Batch"
                    : "Lot/Batch Number"
                }`}{" "}
                Search
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <LotNumberSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={lotNumbers}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
