import axios from "axios";
import { Auth } from "./auth";
//import API_URL from "../apiAdapter";
//import Auth from "../modules/Auth";

const baseUrl = process.env.REACT_APP_API_URL;
const API_URL = baseUrl;

const API = axios.create({
  baseURL: API_URL
});

// Add a request interceptor for Authorization token header
API.interceptors.request.use(config => {
  if (Auth.loggedIn()) {
    config.headers.Authorization = Auth.getBearerToken();
  }
  return config;
});

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

API.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { config, response } = error;
    const originalRequest = config;
    if (response && response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        axios
          .post(
            `${API_URL}/oauth/token`,
            `grant_type=refresh_token&refresh_token=${Auth.getResetToken()}`,
            {
              auth: { username: "SL", password: "Sl43&$^fdgd*+!!@#Agdo4Ged" }
            }
          )
          .then(response => {
            Auth.setSession(response.data);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(response.data.access_token);
          })
          .catch(error => {
            Auth.clearSession();
            return Promise.reject(error);
          });
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(access_token => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    // If error is due to the other reasons, we just throw it back to the axios;
    return Promise.reject(error);
  }
);

const onAccessTokenFetched = access_token => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
};

const addSubscriber = callback => {
  subscribers.push(callback);
};

export default API;
