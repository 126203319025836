import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import {
  companyService,
  materialRequestService,
  reasonService,
} from "_services";
import { CloseOutlined } from "@ant-design/icons";

function StrRmSummaryWithItems({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingStartVerification, setLoadingStartVerification] =
    useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [materialRequest, setMaterialRequest] = useState({});
  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [selectedMrLineKeys, setSelectedMrLineKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [rejectedLines, setRejectedLines] = useState([]);
  const [error, setError] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setLoading(true);
    materialRequestService
      .getById(id)
      .then((x) => {
        // console.log("MR:", x.content);
        let materialRequest = x.content;
        if (materialRequest.orderStage === "PREPARE") {
          history.push(`/material-requests/${materialRequest.id}/prepare`);
        }
        //console.log("Items: ", materialRequest.requestLines.flatMap(item => item.requestedItems))
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = item.id;
          item.requestedQuantity = item.requestedItems.length;
          if (item.requestedItems && item.requestedItems.length > 0) {
            item.netWeight = item.requestedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next);
            item.grossWeight = item.requestedItems
              .map((item) => item.grossWeight)
              .reduce((prev, next) => prev + next);

            item.requestedItems.forEach((stock) => {
              stock.stockType = item.stockType;
              stock.lotNumber = item.lotNumber;
            });
          }
        });
        materialRequest.sbuName = materialRequest.subLocation?.zone?.sbu?.name;
        materialRequest.zoneName = materialRequest.subLocation?.zone?.name;
        materialRequest.subLocationName = materialRequest.subLocation?.name;
        materialRequest.subLocation = materialRequest.subLocation;

        //console.log("MR: ", materialRequest);
        setMaterialRequest(materialRequest);
        setMaterialRequestLines(materialRequest.requestLines);
        getCustomerAddresses(materialRequest.customer.id);
        onFill(materialRequest);
        if (activeTabKey === null) {
          handleTabChange(materialRequest.requestLines[0].key);
        }
        setLoading(false);
        //console.log("FormVal: ", form.getFieldValue("purchaseOrderLines"));
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });

    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
    getRequestReasons();
  }, []);

  const getCustomerAddresses = (companyId) => {
    companyService.getById(companyId).then((data) => {
      const officeAddress = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      setCustomerAddresses(officeAddress);
    });
  };

  const getRequestReasons = () => {
    reasonService
      .search("?type=REQUEST")
      .then((x) => {
        setReasons(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        /* values.requestLines.forEach((item) => {
          if (!item.requestedQuantity) {
            message.error("Please fill in complete order line.");
            setLoading(false);
            return;
          }
        }); */
        /* values.requestDate = moment(values.requestDate).format(
          'YYYY-MM-DD HH:mm:ss'
        ); */
        const payload = { remark: values.remark };
        //console.log("Update: ", values);
        materialRequestService
          .update(id, payload)
          .then(() => {
            message.success("Successfully updated!");
            setLoading(false);
            window.location.reload();
          })
          .catch((error) => {
            setLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((errorInfo) => {});
  };

  const handleReject = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingReject(true);

        const payload = { reason: values.reason, orderStage: "REJECTED" };
        materialRequestService
          .reject(id, payload)
          .then(() => {
            message.success("Successfully rejected!");
            setLoadingReject(false);
            window.location.reload();
          })
          .catch((error) => {
            setLoadingReject(false);
            setError(error);
          });
      })
      .catch((errorInfo) => {});
  };

  const handleRejectLines = () => {
    if (rejectedLines.length > 0) {
      setLoadingReject(true);

      const payload = {
        requestLines: rejectedLines.map((line) => {
          return {
            id: line.id,
            reason: {
              id: line.reason,
            },
          };
        }),
      };
      console.log("Update: ", payload);
      materialRequestService
        .rejectLines(id, payload)
        .then(() => {
          message.success("Successfully rejected requested lines!");
          setLoadingReject(false);
          window.location.reload();
        })
        .catch((error) => {
          setLoadingReject(false);
          message.error(`${error}`);
        });
    }
  };

  /* const handleDelete = () => {
    if (
      !materialRequest.remark ||
      (materialRequest.remark && materialRequest.remark.trim().length === 0)
    ) {
      return message.error(
        "Please enter valid reason for deletion and save to proceed!"
      );
    } else {
      setLoadingDelete(true);
      materialRequestService
        .delete(id)
        .then((x) => {
          setLoadingDelete(false);
          message.success("Successfully deleted!");
          history.push("/material-requests");
        })
        .catch((error) => {
          setLoadingDelete(false);
          message.error(`${error}`);
        });
    }
  }; */

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    materialRequestService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Request-${materialRequest.requestNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handlePrintAvailableStockPdf = () => {
    setLoadingPdf(true);
    materialRequestService
      .printAvailableStockPdf(id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Request-Availability-${materialRequest.requestNumber}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingPdf(false);
      })
      .catch((error) => {
        setLoadingPdf(false);
        setError(error);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCreateDelivery = () => {
    history.push(`/stock-issue/add?mrn=${id}`);
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const onStartVerification = () => {
    setLoadingStartVerification(true);
    const payload = {
      orderStage: "VERIFICATION_INPROGRESS",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setLoadingStartVerification(false);
        message.success("Successfully started verificaiton!");
        history.push(`/material-requests/${id}/verify`);
      })
      .catch((error) => {
        setLoadingStartVerification(false);
        message.error(`${error}`);
      });
  };

  const handleUndo = (id) => {
    setLoadingUndo(true);
    const payload = {
      orderStage: "OPEN",
    };
    materialRequestService
      .updateStatus(id, payload)
      .then(() => {
        setLoadingUndo(false);
        message.success("Successfully opened!");
        window.location.reload();
      })
      .catch((error) => {
        setLoadingUndo(false);
        setError(error);
      });
  };

  const handleStockItemDelete = (stockItemId) => {
    materialRequestService
      .delete(id, stockItemId)
      .then((x) => {
        message.success("Successfully deleted!");
        handleTabChange(activeTabKey);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleRequestComplete = () => {
    var isValid = true;
    const emptyLines = materialRequestLines.filter(
      (item) => item.requestedItems.length === 0
    );
    if (emptyLines && emptyLines.length > 0) {
      for (var i = 0; i < emptyLines.length; i++) {
        if (!emptyLines[i].reason) {
          message.error(
            "Please fill valid reason for empty line cancellation!"
          );
          isValid = false;
          return;
        }
      }
    }
    if (isValid) {
      setLoadingComplete(true);
      const payload = {
        orderStage: "COMPLETE",
      };
      if (emptyLines.length > 0) {
        payload.requestLines = emptyLines.map((item) => {
          return {
            id: item.id,
            reason: item.reason,
          };
        });
      }
      materialRequestService
        .updateStatus(materialRequest.id, payload)
        .then(() => {
          setLoadingComplete(false);
          message.success("Successfully completed!");
          window.location.reload();
        })
        .catch((error) => {
          setLoadingComplete(false);
          setError(error);
        });
    }
  };

  const handleStatusUpdate = () => {
    setLoadingStatusUpdate(true);
    let newStatus = materialRequest.orderStage;
    if (materialRequest.orderStage === "OPEN") {
      newStatus = "VERIFICATION";
    } else if (materialRequest.orderStage === "VERIFICATION") {
      newStatus = "PREPARE";
    } else if (materialRequest.orderStage === "PREPARE") {
      newStatus = "READY";
    } else if (materialRequest.orderStage === "PROCESSING") {
      newStatus = "COMPLETE";
    }
    const statusUpdatePayload = {
      requestStatus: newStatus,
    };
    materialRequestService
      .updateStatus(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingStatusUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (
      ["OPEN", "UPDATE", "VERIFICATION", "PREPARE"].includes(
        materialRequest && materialRequest.orderStage
      )
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to reject this request?"
          onConfirm={handleReject}
          key="add-confirm"
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={loadingReject}
          >
            Reject
          </Button>
        </Popconfirm>
      );
    }
    if (["VERIFICATION"].includes(materialRequest.orderStage)) {
      /* buttons.push(
        <Button
          key="availability-pdf"
          title="Print Detailed Pick List"
          icon={<FilePdfOutlined />}
          onClick={handlePrintAvailableStockPdf}
          loading={loadingPdf}
        ></Button>
      ); */
    }
    /* buttons.push(
      <Button
        key="pdf"
        title="Print Request"
        icon={<FilePdfOutlined />}
        onClick={handlePrintPdf}
        loading={loadingPdf}
      ></Button>
    ); */
    if (
      ["VERIFIED", "VERIFICATION_INPROGRESS"].includes(
        materialRequest && materialRequest.orderStage
      )
    ) {
      /* buttons.push(
        <Popconfirm
          title="Are you sure to undo verification?"
          onConfirm={() => handleUndo(materialRequest.id)}
          key="start-verify"
        >
          <Button
            key="btn-undo-verify"
            icon={<UndoOutlined />}
            title="Undo"
            loading={loadingUndo}
          ></Button>
        </Popconfirm>
      ); */
    }
    /* buttons.push(
        <Popconfirm
          title="Are you sure to start verification?"
          onConfirm={onStartVerification}
          key="start-verify"
        >
          <Button
            key="btn-start-verify"
            htmlType="submit"
            loading={loadingStartVerification}
            type="primary"
          >
            Start Verification
          </Button>
        </Popconfirm>
      ); */
    /* buttons.push(
        <Button
          key="btn-update"
          htmlType="submit"
          loading={loading}
          onClick={handleFormSumbit}
          type="primary"
        >
          Update
        </Button>
      ); */
    {
      rejectedLines.length > 0 &&
        buttons.push(
          <Popconfirm
            title="Are you sure to reject reason updated lines?"
            onConfirm={handleRejectLines}
            key="reject"
          >
            <Button
              key="btn-reject"
              htmlType="submit"
              loading={loadingReject}
              type="primary"
            >
              Reject Lines
            </Button>
          </Popconfirm>
        );
    }
    if (materialRequest.orderStage !== "COMPLETE") {
      let statusButtonText = "";
      if (materialRequest.orderStage === "OPEN") {
        statusButtonText = "Start Verification";
      } else if (materialRequest.orderStage === "VERIFICATION") {
        statusButtonText = "Start Scan";
      } else if (materialRequest.orderStage === "PREPARE") {
        statusButtonText = "Complete Scan";
      }
      buttons.push(
        <Popconfirm
          title={`Are you sure to ${statusButtonText} it?`}
          onConfirm={handleStatusUpdate}
          key="con-complete"
        >
          <Button
            key="btn-complete"
            htmlType="button"
            loading={loadingStatusUpdate}
          >
            {statusButtonText}
          </Button>
        </Popconfirm>
      );
    }
    /* if (
      ["OPEN", "UPDATE"].includes(materialRequest && materialRequest.orderStage)
    ) {
      buttons.push(
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={handleDelete}
            key="delete"
          >
            <Button
              key="btn-delete"
              htmlType="submit"
              loading={loadingDelete}
              type="primary"
            >
              Delete
            </Button>
          </Popconfirm>
        );
    } */
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const handleFieldChange = (value, fieldName, key) => {
    const items = [...materialRequestLines];
    const newItems = items.map((item) => {
      const newItem = Object.assign({}, item);
      if (newItem.key === key) {
        newItem[fieldName] = value;

        if (fieldName === "reason") {
          if (value) {
            setRejectedLines([...rejectedLines, newItem]);
          } else {
            const rejectedLineItems = [...rejectedLines];
            const foundItem = rejectedLineItems.find(
              (item) => item.id === newItem.id
            );
            if (foundItem) {
              rejectedLineItems.splice(rejectedLineItems.indexOf(foundItem), 1);
              setRejectedLines(rejectedLineItems);
            }
          }
        }
        return newItem;
      }
      return newItem;
    });
    //setMaterialRequestLines(newItems);
  };

  const getLineColumns = () => {
    let columns = [];
    if (materialRequest.subLocation?.shortCode.includes("GP")) {
      columns.push({
        title: "Supplier",
        key: "supplier",
        render: (text, record) => {
          return record.supplier && record.supplier.name;
        },
      });
    }
    columns.push({
      title: "Department",
      key: "deparment",
      render: (text, record) => {
        return record.department && record.department.code;
      },
    });
    columns.push({
      title: "MRN No.",
      dataIndex: "mrnNumber",
      key: "mrnNumber",
    });
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      width: "12%",
    });
    columns.push({
      title: "Material",
      key: "stockTypeCode",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Status | Priority",
      key: "lineStage",
      dataIndex: "lineStage",
      render: (text, record) => {
        let color = "";
        if (text === "OPEN") {
          color = "#87d068";
        }
        if (text === "UPDATE") {
          color = "#2db7f5";
        }
        if (text === "VERIFICATION") {
          color = "#108ee9";
        }
        if (text === "PREPARE") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "ASSIGNED") {
          color = "#ffd666";
        }
        if (text === "PROCESSING") {
          color = "#ffd666";
        }
        if (text === "COMPLETE") {
          color = "#ffd666";
        }
        if (text === "REJECTED") {
          color = "#ffd666";
        }
        if (text === "CANCEL") {
          color = "#ffd666";
        }
        return (
          <>
            <Tag color={color}>{text}</Tag> | {record.priorityStatus}
          </>
        );
      },
    });
    columns.push({
      title: "Box",
      dataIndex: "totalItems",
      key: "totalItems",
      align: "right",
    });
    columns.push({
      title: "Net Weight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        if (record.netWeight) {
          return (
            !isNaN(record.netWeight) && Number(record.netWeight).toFixed(2)
          );
        } else if (record.qty) {
          return "";
        } else if (record.totalItems) {
          return "";
        } else {
          return !isNaN(record.quantity) && Number(record.quantity).toFixed(2);
        }
      },
    });
    if (materialRequest.subLocation?.zone?.sbu?.code !== "GP") {
      columns.push({
        title: "Cone",
        dataIndex: "qty",
        key: "qty",
        align: "right",
      });
    } else {
      columns.push({
        title: "Width",
        dataIndex: "width",
        key: "width",
        align: "right",
      });
      columns.push({
        title: "C.Code",
        dataIndex: "colorCode",
        key: "colorCode",
        align: "right",
      });
    }
    if (materialRequest.orderStage !== "OPEN") {
      columns.push({
        title: "Veri. Box",
        key: "verifiedBox",
        align: "right",
        render: (text, record) => {
          return record.requestedItems.length;
        },
      });
      columns.push({
        title: "Veri. Net.W",
        key: "quantity",
        align: "right",
        render: (text, record) => {
          return (
            record.requestedItems.length &&
            Number(
              record.requestedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next)
            ).toFixed(2)
          );
        },
      });
    }
    if (["OPEN", "VERIFICATION"].includes(materialRequest.orderStage)) {
      columns.push({
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        align: "right",
        render: (text, record) => {
          if (
            record.requestedItems.length === 0 &&
            record.lineStage !== "REJECTED"
          ) {
            return (
              <Form.Item
                name={`reason[${record.key}]`}
                key={record.key}
                style={{ marginBottom: "0" }}
                rules={[{ required: false, message: "Required." }]}
                hasFeedback
              >
                <Select
                  placeholder="Please select"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => handleFieldChange(e, "reason", record.key)}
                >
                  {reasons.map((t) => (
                    <Option value={t.id} key={t.id}>
                      {t.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            );
          } else {
            return <span></span>;
          }
        },
      });
    }

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    /* columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
    }); */
    columns.push({
      title: "Prod Date",
      dataIndex: "productionDate",
      key: "productionDate",
      width: "8%",
    });
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "8%",
    });
    columns.push({
      title: "Inspect.St",
      dataIndex: "inspectionStatus",
      key: "inspectionStatus",
      width: "5%",
    });
    columns.push({
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: "7%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType.supplier) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.stockType.supplier.name}
            >
              {record.stockType.supplier.code}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "7%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Pallet",
      dataIndex: "palletNo",
      key: "palletNo",
      align: "right",
    });
    columns.push({
      title: "B/No",
      dataIndex: "tempNumber",
      key: "tempNumber",
      width: "4%",
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "LOADED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "2%",
      render: (text, record) => {
        return (
          <span>
            {["REQUESTED"].includes(record.mode) && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleStockItemDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const mrLineRowSelection = {
    selectedRowKeys: selectedMrLineKeys,
    onChange: (selectedMrLineKeys) => {
      handleTabChange(selectedMrLineKeys[0]);
    },
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedMrLineKeys([activeKey.toString()]);
  };

  const getTabContent = (requestedLines) => {
    let tabPanes = [];
    requestedLines.forEach((line, index) => {
      if (line.requestedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType.code} / ${
              line.lotNumber ? line.lotNumber.number : ""
            } (${line.requestedItems && line.requestedItems.length})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.requestedItems}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                  //totalQuantity += quantity ? quantity : 1;
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 6}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{line.requestedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag
                  color={`${
                    materialRequest.orderStage === "OPEN"
                      ? "#2db7f5"
                      : ["IN_PROGRESS", "ACCEPTED"].includes(
                          materialRequest.orderStage
                        )
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} status={materialRequest.orderStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getCustomerAddresses}
                      disabled
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Request date"
                      style={{ width: "100%" }}
                      disabled={
                        !["UPDATE"].includes(materialRequest.orderStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestNumber"
                    label="Request No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sbuName" label="SBU">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["deliveryLocation", "id"]}
                    label="Deliver To"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact No:">
                    <Input
                      placeholder="Contact number"
                      disabled={
                        !["UPDATE"].includes(materialRequest.orderStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactPersonName" label="Contact Person">
                    <Input
                      placeholder="Contact number"
                      disabled={
                        !["UPDATE"].includes(materialRequest.orderStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="zoneName" label="Zone">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="remark"
                    label="Remark"
                    rules={[
                      { required: false, message: "Remark/Note is required!" },
                    ]}
                  >
                    <TextArea
                      disabled={
                        !["UPDATE"].includes(materialRequest.orderStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["reason", "id"]}
                    label="Reason for Reject"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                    >
                      {reasons.length > 0 &&
                        reasons.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item name="subLocationName" label="Sub-Location">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                rowClassName={(record) =>
                  ["COMPLETE", "VERIFIED"].includes(record.lineStage)
                    ? "ant-tag-green"
                    : "ant-tag-gold"
                }
                rowSelection={{
                  type: "radio",
                  ...mrLineRowSelection,
                }}
                size="small"
                summary={(pageData) => {
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalCone = 0;
                  let totalVerifiedBox = 0;
                  let totalVerifiedNetWeight = 0;

                  pageData.forEach(
                    ({
                      totalItems,
                      quantity,
                      requestedItems,
                      qty,
                      netWeight,
                    }) => {
                      totalQuantity += totalItems;
                      totalNetWeight += netWeight;
                      totalCone += qty;
                      totalVerifiedBox += requestedItems.length;
                      totalVerifiedNetWeight +=
                        requestedItems.length > 0 &&
                        requestedItems
                          .map((item) => item.netWeight)
                          .reduce((prev, next) => prev + next);
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={
                            materialRequest.orderStage === "OPEN"
                              ? getLineColumns().length - 3
                              : getLineColumns().length - 4
                          }
                        >
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {totalNetWeight &&
                              Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalCone}</Text>
                        </Table.Summary.Cell>
                        {materialRequest.orderStage !== "OPEN" && (
                          <>
                            <Table.Summary.Cell align="right">
                              <Text strong>{totalVerifiedBox}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {totalVerifiedNetWeight &&
                                  Number(totalVerifiedNetWeight).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                          </>
                        )}
                        {materialRequest.orderStage === "OPEN" && (
                          <Table.Summary.Cell></Table.Summary.Cell>
                        )}
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
              {materialRequestLines.length > 0 &&
                materialRequestLines.flatMap((item) => item.requestedItems)
                  .length > 0 && (
                  <>
                    <Divider orientation="left">Verified Stock Items</Divider>
                    <Tabs
                      onChange={handleTabChange}
                      activeKey={activeTabKey}
                      type="card"
                    >
                      {getTabContent(materialRequestLines)}
                    </Tabs>
                  </>
                )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmSummaryWithItems;
