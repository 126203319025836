import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import {
  binLocationService,
  binService,
  companyService,
  lotNumberService,
  materialTypeService,
  sbuService,
  subLocationService,
  supplierService,
  zoneService,
} from '_services';
import { Auth } from '_helpers';

function StrRmSearch(props) {
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    companyService.getSuggestion('?companyType=STORE').then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === 'WAREHOUSE'
          );
          setAddresses(storeLocationAddresses);
          form.setFieldsValue({
            storeId: x.content[0].id,
            storeLocationId: storeLocationAddresses[0].id,
          });
          if (storeLocationAddresses.length === 1) {
            // onFinish({});
          }
        });
      }
      setCompanies(x.content);
    });
    getStockTypes();
    getLotNumbers();
    getSuppliers();
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search('')
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '?isParent=1';
    /* if (values.storeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeId=${encodeURIComponent(values.storeId)}`;
    } */
    if (values.storeLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `storeLocationId=${encodeURIComponent(
        values.storeLocationId
      )}`;
    }
    if (values.code) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.stockTypeId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `stockTypeId=${encodeURIComponent(values.stockTypeId)}`;
    }
    if (values.lotNumberId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `lotNumberId=${encodeURIComponent(values.lotNumberId)}`;
    }
    if (values.supplierId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `supplierId=${encodeURIComponent(values.supplierId)}`;
    }
    if (values.subLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `subLocationId=${encodeURIComponent(
        values.subLocationId
      )}`;
    }
    if (values.zoneId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `zoneId=${encodeURIComponent(values.zoneId)}`;
    }
    if (values.sbuId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `sbuId=${encodeURIComponent(values.sbuId)}`;
    }
    binService
      .getSuggestion(searchParams)
      .then((data) => {
        if (storeBins.length === 0) {
          setStoreBins(data.content);
        }
        props.onSearchResults(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  const handleStoreLocationChange = () => {
    setStoreBins([]);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item name='storeLocationId' label='Store Location'>
            <Select
              placeholder='Please select'
              allowClear
              onChange={handleStoreLocationChange}
            >
              {addresses.length > 0 &&
                addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='sbuId' label='SBU'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {businessUnits.length > 0 &&
                businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='zoneId' label='Zone'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {zones.length > 0 &&
                zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='subLocationId' label='Sub-Location'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              disabled={!selectedZoneId}
            >
              {subLocations.length > 0 &&
                subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          {storeBins.length > 0 && (
            <Form.Item name='code' label='Bin'>
              <Select
                placeholder='Please select'
                allowClear
                optionFilterProp='children'
                showSearch
              >
                {storeBins.length > 0 &&
                  storeBins.map((t) => (
                    <Select.Option value={t.code} key={t.id}>
                      {t.code}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                // form.resetFields();
                form.resetFields(['code']);
                form.resetFields(['binLocationId']);
                form.resetFields(['stockTypeId']);
                form.resetFields(['lotNumberId']);
                form.resetFields(['supplierId']);
                onFinish(form.getFieldsValue());
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item name='stockTypeId' label='Material Type'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Material Type'
            >
              {stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='lotNumberId' label='Lot Number'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Lot Number'
            >
              {lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='supplierId' label='Supplier'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Supplier'
            >
              {suppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row>
    </Form>
  );
}

export default StrRmSearch;
