import {
  Button,
  InputNumber,
  Select,
  Space,
  message,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { CloseOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Auth } from "_helpers";

const TableForm = ({
  value,
  onChange,
  stockClass,
  materialTypes,
  lotNumbers,
  measurementTypes,
}) => {
  //console.log("Values: ", lotNumbers);
  const [loading, setLoading] = useState(false);
  //const [stockItemLoading, setStockItemLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(value);
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const precision = ["PRYM"].includes(Auth.getProfile().comp_code) ? 3 : 2;
  //const [receivedTotalNetMeasure, setReceivedTotalNetMeasure] = useState(null);
  // const [receivedTotalGrossMeasure, setReceivedTotalGrossMeasure] = useState(
  //   null
  // );

  /* for (const line of value) {
    line.key = index;
    setIndex(index + 1);
  }
  setData(value);
  console.log("Values: ", data); */
  //const [materialTypes, setMaterialTypes] = useState([]);
  //const [materialTypeId, setMaterialTypeId] = useState(null);
  //const [lotNumbers, setLotNumbers] = useState([]);
  //const [lotNumberId, setLotNumberId] = useState(null);
  //const [stockClasses, setStockClasses] = useState([]);
  //const [packingTypes, setPackingTypes] = useState([]);

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    /* materialTypeService.getSuggestion().then((x) => {
      setMaterialTypes(x.content);
    });
    lotNumberService.getSuggestion().then((x) => {
      setLotNumbers(x.content);
    });
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
    packingTypeService.getSuggestion().then((data) => {
      setPackingTypes(data.content);
    });
    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content);
    }); */
    /* const fetchStocks = async () => {
      setStockItemLoading(true);
      for (const item of data) {
        const result = await materialDetailService.getSuggestion(
          `?purchaseOrderLineId=${item.id}`
        );
        item.stockItems = result.content;
      }
      setStockItemLoading(false);
      setData(data);
    };

    fetchStocks(); */
  }, []);

  const getRowByKey = (key, newData) =>
    (newData || data)?.filter((item) => item.key === key)[0];
  /* const getRowBykey = (key, newData) => {
    return (data || newData).filter((item) => item.key === key)[0];
  }; */

  const handleAddOrderLine = () => {
    const newData = data?.map((item) => ({ ...item })) || [];

    newData.push({
      key: `NEW_TEMP_ID_${index}`,
      //stockType: materialTypes.find((item) => item.id === materialTypeId),
      //lotNumber: lotNumbers.find((item) => item.id === lotNumberId),
      quantity: null,
      netWeight: null,
      grossWeight: null,
    });

    setIndex(index + 1);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
    //setMaterialTypeId(null);
    //setLotNumberId(null);
  };

  const handleRemoveOrderLine = (key) => {
    const newData = data?.filter((item) => item.key !== key);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const handleSaveOrderLine = (e, key) => {
    e.persist();
    const target = getRowByKey(key) || {};
    //console.log("Targe:", target);
    if (
      !target.stockType ||
      !target.quantity ||
      //!target.netWeight ||
      !target.measurementType
    ) {
      message.error("Please complete the packing list item information.");
      e.target.focus();
      return;
    } else {
      handleAddOrderLine();
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const newData = [...data];
    const target = getRowByKey(key, newData);
    if (target) {
      if (["stockType", "lotNumber", "measurementType"].includes(fieldName)) {
        target[fieldName] = { id: value };
      } else {
        target[fieldName] = value;
      }

      setData(newData);
    }
  };

  const columns = [
    /* {
      title: "Stock Class",
      dataIndex: "stockClass",
      key: "stockClass",
      width: "10%",
      render: (text, record) => {
        return (
          <>
            <Select
              value={record.stockClass && record.stockClass.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "stockClass", record.key)}
              disabled={status !== "OPEN"}
            >
              {stockClasses.length > 0 &&
                stockClasses.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
            
          </>
        );
      },
    }, */
    /* {
      title: "Packing Type",
      dataIndex: "packingType",
      key: "packingType",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.packingType && record.packingType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "packingType", record.key)}
            disabled={status !== "OPEN"}
          >
            {packingTypes.length > 0 &&
              packingTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.name}
                </Option>
              ))}
          </Select>
        );
      },
    }, */
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.stockType &&
        a.stockType.code &&
        b.stockType &&
        b.stockType.code &&
        a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return (
          <Select
            value={record.stockType && record.stockType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "stockType", record.key)}
          >
            {materialTypes.length > 0 &&
              materialTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.code}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lotNumber.number.localeCompare(b.lotNumber.number),
      render: (text, record) => {
        return (
          <Select
            value={record.lotNumber && record.lotNumber.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
          >
            {lotNumbers &&
              lotNumbers.length > 0 &&
              lotNumbers.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.number}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Units",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            min={0}
            placeholder="Units"
          />
        );
      },
    },
    {
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      width: "10%",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "netWeight", record.key)}
            placeholder="Net Measure"
          />
        );
      },
    },
    {
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      width: "10%",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "grossWeight", record.key)}
            placeholder="Gross Measure"
          />
        );
      },
    },
    {
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.measurementType && record.measurementType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) =>
              handleFieldChange(e, "measurementType", record.key)
            }
          >
            {measurementTypes.length > 0 &&
              measurementTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.name}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Are you sure to remove?"
              onConfirm={() => handleRemoveOrderLine(record.key)}
              key="remove-confirm"
            >
              <Button
                size="small"
                icon={<CloseOutlined />}
                //onClick={() => handleRemoveOrderLine(record.key)}
              ></Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveOrderLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  /* const columnsFABRIC = [
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      render: (text, record) => {
        return (
          <>
            <Input value={record.stockType.id} hidden />
            <span>{record.stockType.code}</span>
            <Progress
              size="small"
              percent={parseInt(
                record.goodsReceivedLines &&
                  record.goodsReceivedLines.length > 0 &&
                  (record.goodsReceivedLines
                    .map((obj) => obj.acceptedAtQA)
                    .reduce((prev, next) => prev + next) /
                    record.quantity) *
                    100
              )}
            />
          </>
        );
      },
    },
    {
      title: "Units",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            min={0}
            placeholder="Units"
            disabled={status !== "OPEN"}
          />
        );
      },
    },
    {
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "netWeight", record.key)}
            placeholder="Net Measure"
            disabled={status !== "OPEN"}
          />
        );
      },
    },
    {
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "grossWeight", record.key)}
            placeholder="Gross Measure"
            disabled={status !== "OPEN"}
          />
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Button
            size="small"
            icon={<CloseOutlined />}
            onClick={() => remove(record.key)}
            disabled={status !== "OPEN"}
          ></Button>
        );
      },
    },
  ];
 */

  return (
    <>
      {data.length === 0 && (
        <Button
          style={{
            width: "100%",
            marginTop: 16,
            marginBottom: 8,
            background: "rgb(190, 200, 200)",
          }}
          type="dashed"
          onClick={handleAddOrderLine}
          icon={<PlusOutlined />}
        >
          Add Item
        </Button>
      )}
      <Table
        bordered
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        summary={(pageData) => {
          let totalQuantity = 0;
          let totalNetWeight = 0;
          let totalGrossWeight = 0;
          let measurementTypes = [];

          pageData.forEach(
            ({ quantity, netWeight, grossWeight, measurementType }) => {
              totalQuantity += quantity;
              totalNetWeight += netWeight;
              totalGrossWeight += grossWeight;
              if (
                typeof measurementType === "object" &&
                measurementType !== null
              ) {
                measurementTypes.push(measurementType.id);
              } else {
                measurementTypes.push(measurementType);
              }
            }
          );
          const uniqueMeasurementTypes = [...new Set(measurementTypes)];

          return (
            <>
              {uniqueMeasurementTypes.length === 1 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {totalQuantity}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {Number(totalNetWeight).toFixed(precision)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {Number(totalGrossWeight).toFixed(precision)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} />
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default TableForm;
