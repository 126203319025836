import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Img from 'img/leftMenu/mobilw/icon.svg';

const ListItem = props => {
    return (<div >
        <Link to={props.link} >
            <div className={props.iconStat}>
                <img src={Img}/></div>
            <span>{props.label}</span>
        </Link>

    </div>)
}

ListItem.defaultProps = {
    status: 0
}


class MenuList extends Component {
    render() {
        return (
            <div >
                {this.props.data.map(item => <ListItem key={item.id} {...item}/>)}
            </div>
        );
    }
}

export default MenuList;