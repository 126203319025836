import { Button, Col, Form, Row, message, Input, Select } from "antd";
import React from "react";
import { stockCheckService } from "_services";

function ItemComparisonSearch(props) {
  const [form] = Form.useForm();
  const { Option } = Select;

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";

    if (values.code) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `&code=${values.code}`;
    }
    if (values.mode) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `&mode=${values.mode}`;
    }
    if (values.status) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `&status=${values.status}`;
    }

    stockCheckService
      .searchStockCheckUpload(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="code" label="Bin Code">
            <Input placeholder="Bin Code" allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="mode" label="Stock Status">
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="AVAILABLE" key="AVAILABLE">
                AVAILABLE
              </Option>
              <Option value="REQUESTED" key="REQUESTED">
                REQUESTED
              </Option>
              <Option value="READY" key="READY">
                READY
              </Option>
              <Option value="ISSUED" key="ISSUED">
                ISSUED
              </Option>
              <Option value="WRITEOFF" key="WRITEOFF">
              WRITEOFF
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="status" label="Comparison Status">
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="MATCHED" key="MATCHED">
                MATCHED
              </Option>
              <Option value="PRESENT_NOT_BIN" key="PRESENT_NOT_BIN">
                PRESENT_NOT_BIN
              </Option>
              <Option value="NOT_IN_SYSTEM" key="NOT_IN_SYSTEM">
                NOT_IN_SYSTEM
              </Option>
              <Option
                value="NOT_BIN_AND_NOT_SYSTEM"
                key="NOT_BIN_AND_NOT_SYSTEM"
              >
                NOT_BIN_AND_NOT_SYSTEM
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default ItemComparisonSearch;
