import React from "react";

const Footer = ({ links, copyright }) => {
  return (
    <footer>
      <div>Fingertips Admin © 2022 Bottleneck Solutions - {process.env.REACT_APP_VERSION}</div>
    </footer>
  );
};

export default Footer;
