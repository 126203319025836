import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Spin,
  Form,
  Row,
  Col,
  Popconfirm,
} from "antd";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { userService } from "_services";
import TabMenu from "../TabMenu";

const Summary = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [user, setUser] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    userService
      .getByUserName(Auth.getProfile().user_name)
      .then((x) => {
        setLoading(false);
        setUser(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setUpdating(true);
        userService
          .update(user.id, values)
          .then(() => {
            message.success("Successfully updated!");
            setUpdating(false);
          })
          .catch((error) => {
            setUpdating(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/dashboard");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to update the changes?"
        onConfirm={handleFormSumbit}
      >
        <Button key="1" type="primary" htmlType="submit" loading={updating}>
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>User Profile</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu="profile" />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              form={form}
              name="form-create"
              layout="vertical"
              requiredMark={false}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="username"
                    label="Username"
                    rules={[{ required: true, message: "Code is required" }]}
                  >
                    <Input placeholder="Username" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="contactNo"
                    label="Contact Number"
                    rules={[
                      { required: true, message: "Contact Number is required" },
                    ]}
                  >
                    <Input placeholder="Contact Number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { required: true, message: "First Name is required" },
                    ]}
                  >
                    <Input placeholder="FirstName" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="address" label="Address">
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { required: true, message: "Last Name is required" },
                    ]}
                  >
                    <Input placeholder="LasttName" />
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default Summary;
