import {
  Button,
  Col,
  Form,
  Row,
  Input,
  message,
  DatePicker,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  MaterialRequestStatus,
  StockCheckStatus,
  StockCheckTypes,
} from '_helpers';
import {
  binService,
  lotNumberService,
  materialRequestService,
  materialTypeService,
  sbuService,
  stockCheckService,
  subLocationService,
  supplierService,
  zoneService,
} from '_services';

function Search(props) {
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getBusinessUnits();
    getZones();
    getSubLocations();
    // getStockTypes();
    // getLotNumbers();
    // getSuppliers();
    // getStockBins();
  }, []);

  /* const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  /* const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  }; */

  /* const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  /* const getStockBins = () => {
    binService
      .getSuggestion('')
      .then((y) => {
        setStoreBins(y.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search('')
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    if (values.requestNumber) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `code=${encodeURIComponent(values.requestNumber)}`;
    }
    if (values.customerId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `customerId=${encodeURIComponent(values.customerId)}`;
    }
    if (values.deliveryLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `deliveryLocationId=${encodeURIComponent(
        values.deliveryLocationId
      )}`;
    }
    if (values.stockCheckType) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `stockCheckType=${encodeURIComponent(
        values.stockCheckType
      )}`;
    }
    if (values.checkStatus) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `checkStatus=${encodeURIComponent(values.checkStatus)}`;
    }
    if (values.dateRange) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.dateRange[0]).startOf('day').format('YYYY-MM-DD')
      )}&toDate=${moment(values.dateRange[1])
        .startOf('day')
        .format('YYYY-MM-DD')}`;
    }
    if (values.sbuId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `sbuId=${encodeURIComponent(values.sbuId)}`;
    }
    if (values.zoneId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `zoneId=${encodeURIComponent(values.zoneId)}`;
    }
    if (values.subLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `subLocationId=${encodeURIComponent(
        values.subLocationId
      )}`;
    }
    /* if (values.stockTypeId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `stockTypeId=${encodeURIComponent(values.stockTypeId)}`;
    }
    if (values.lotNumberId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `lotNumberId=${encodeURIComponent(values.lotNumberId)}`;
    }
    if (values.supplierId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `supplierId=${encodeURIComponent(values.supplierId)}`;
    }
    if (values.stockBinId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `stockBinId=${encodeURIComponent(values.stockBinId)}`;
    } */
    stockCheckService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='code' label='Stock Check Code'>
            <Input placeholder='Stock Check Code' allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='dateRange' label='Date'>
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='stockCheckType' label='Stock Check Type'>
            <Select allowClear>
              {StockCheckTypes.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='checkStatus' label='Stock Check Status'>
            <Select allowClear>
              {StockCheckStatus.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='sbuId' label='SBU'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {businessUnits.length > 0 &&
                businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='zoneId' label='Zone'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {zones.length > 0 &&
                zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='subLocationId' label='Sub-Location'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              disabled={!selectedZoneId}
            >
              {subLocations.length > 0 &&
                subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={9}></Col>
      </Row>
      {/* <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='stockTypeId' label='Material Type'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Material Type'
            >
              {stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='lotNumberId' label='Lot Number'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Lot Number'
            >
              {lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='supplierId' label='Supplier'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Supplier'
            >
              {suppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='stockBinId' label='Stock Bin'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Bin'
            >
              {storeBins.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}></Col>
      </Row> */}
    </Form>
  );
}

export default Search;
