import React from "react";
import { Radio, Steps } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

function TabMenu(props) {
  let history = useHistory();

  const handleMenuChange = (e) => {
    history.push(`/packing-lists/${props.id}/${e.target.value}`);
  };

  return (
    <div>
      <Steps style={{marginBottom: 20}} size="small"
        items={[
          {
            title: "Open",
            status: props.status === 'OPEN' ? "process" : "finish",
            icon: props.status === 'OPEN' ? <LoadingOutlined /> : false,
          },
          {
            title: "Update Details",
            status: props.status === 'UPDATE' ? "process" : props.status === 'OPEN' ? "wait" : "finish",
            icon: props.status === 'UPDATE' ? <LoadingOutlined /> : false,
          },
          {
            title: "Barcoding",
            status: props.status === 'BARCODING' ? "process" : ['UPDATE', "OPEN"].includes(props.status) ? "wait" : "finish",
            icon: props.status === 'BARCODING' ? <LoadingOutlined /> : false,
          },
          {
            title: "Validation",
            status: props.status === 'VALIDATE' ? "process" : ['UPDATE', "OPEN", "BARCODING"].includes(props.status) ? "wait" : "finish",
            icon: props.status === 'VALIDATE' ? <LoadingOutlined /> : false,
          },
          {
            title: "Bin Movement",
            status: props.status === 'BIN_MOVE' ? "process" : ['UPDATE', "OPEN", "BARCODING", "VALIDATE"].includes(props.status) ? "wait" : "finish",
            icon: props.status === 'BIN_MOVE' ? <LoadingOutlined /> : false,
          },
          {
            title: "Complete",
            status: props.status === 'COMPLETE' ? "finish" : "wait",
            icon: false,
          },
        ]}
      />
      <Radio.Group
        value={props.menu}
        onChange={handleMenuChange}
        style={{ marginBottom: 10 }}
      >
        <Radio.Button value="summary" style={{ marginRight: 5 }}>
          Summary
        </Radio.Button>
        <Radio.Button value="validate" style={{ marginRight: 5 }}>
          Validate
        </Radio.Button>
        <Radio.Button value="grn" style={{ marginRight: 5 }}>
          GRN Details
        </Radio.Button>
        <Radio.Button value="release" style={{ marginRight: 5 }}>
          Urgent Release
        </Radio.Button>
        <Radio.Button value="history" style={{ marginRight: 5 }}>
          History
        </Radio.Button>
        <Radio.Button value="documents" style={{ marginRight: 5 }}>
          Documents
        </Radio.Button>
      </Radio.Group>
    </div>
  );
}

export default TabMenu;
