import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tooltip,
  Tag,
  Tabs,
  Spin,
  Popconfirm,
  Space,
  Modal,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  materialDetailService,
  measurementTypeService,
} from "_services";
import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import StockSelection from "./StockSelection";
import { Utils } from "_helpers";

let lineIndex = 0;

function PrymVerify({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [stockItemLoading, setStockItemLoading] = useState(false);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const [loadingCompleteVerification, setLoadingCompleteVerification] =
    useState(false);
  const [accepting, setAccepting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequest, setMaterialRequest] = useState({});
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);

  const [availableStocks, setAvailableStocks] = useState([]);
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [selectedStockTypeId, setSelectedStockTypeId] = useState(null);
  const [selectedLotNumberId, setSelectedLotNumberId] = useState(null);
  const [selectedLineKey, setSelectedLineKey] = useState(null);
  const [selectedMrLineKeys, setSelectedMrLineKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [activeRequestLine, setActiveRequestLine] = useState(null);
  const [enableVerify, setEnableVerify] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const materialRequestData = await materialRequestService.getById(id);

      if (materialRequestData) {
        let materialRequest = materialRequestData.content;
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = item.id;
        });
        const mrCustomer = await companyService.getById(
          materialRequest.customer.id
        );
        const officeAddresses = mrCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );

        materialRequest.requestLines.forEach((line) => {
          line.stockTypeObj = line.stockType;
          line.lotNumberObj = line.lotNumber;
          lineIndex++;
        });
        // console.log("MR:", materialRequest);
        setMaterialRequest(materialRequest);
        setMaterialRequestLines([].concat(materialRequest.requestLines));
        setAddresses(officeAddresses);
        onFill(materialRequest);
        if (activeTabKey === null && materialRequest.requestLines) {
          handleTabChange(materialRequest.requestLines[0].key);
        }
      }

      setCustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setLoading(false);
    };

    fetchAlldatas();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const validateRequestLines = (lines) => {
    let validLines = true;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (!line.stockType || !line.quantity || !line.quantity > 0) {
          validLines = false;
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        //if (validateRequestLines(values.requestLines)) {
        setUpdating(true);
        // console.log(", requestLines:", materialRequestLines);

        values.requestDate = moment(values.requestDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        values.requestLines = materialRequestLines.map((line) => ({
          id: line.id,
          stockType: { id: line.stockType.id },
          lotNumber: line.lotNumber ? { id: line.lotNumber.id } : null,
          requestTime: line.requestTime,
          quantity: line.quantity || line.netWeight || line.qty,
          totalItems: line.totalItems,
          measurementType: { id: line.measurementType.id },
          requestedItems: line.requestedItems.map((item) => ({
            id: item.id,
          })),
        }));
        //console.log("MR: ", JSON.stringify(values));

        materialRequestService
          .update(materialRequest.id, values)
          .then(() => {
            setUpdating(false);
            message.success("Successfully updated!");
            //history.push(`${path}`.replace(":id", id));
            window.location.reload();
            setEnableVerify(false);
          })
          .catch((error) => {
            setUpdating(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const validateVerifiationCompletion = () => {
    let validation = true;
    materialRequestLines.forEach((line) => {
      if (
        line.requestedItems.length === 0 ||
        line.totalItems > line.requestedItems.length ||
        line.quantity >
          line.requestedItems
            .map((item) => item.quantity)
            .reduce((prev, next) => prev + next)
      ) {
        validation = false;
      }
    });

    return validation;
  };

  const onCompleteVerification = () => {
    // Removed validation as per request
    if (validateVerifiationCompletion()) {
      setLoadingCompleteVerification(true);
      const payload = {
        orderStage: "VERIFIED",
      };
      materialRequestService
        .updateStatus(materialRequest.id, payload)
        .then(() => {
          setLoadingCompleteVerification(false);
          message.success("Successfully completed verificaiton!");
          history.push(`/material-requests/${id}/summary`);
        })
        .catch((error) => {
          setLoadingCompleteVerification(false);
          message.error(`${error}`);
        });
    } else {
      message.error("Sorry, some of the request lines are not yet verified!");
    }
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const handleStockItemUnVerified = (stockItemId) => {
    setLoading(true);
    materialRequestService
      .deleteLineItem(id, stockItemId)
      .then((x) => {
        setLoading(false);
        message.success("Successfully unverified!");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const handleRequestLineUnVerified = (requestLineId) => {
    setLoading(true);
    materialRequestService
      .updateLine(id, requestLineId, { lineStage: "VERIFICATION_INPROGRESS" })
      .then((x) => {
        setLoading(false);
        message.success("Successfully unverified!");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const handleRequestLineVerified = (requestLineId) => {
    setLoading(true);
    materialRequestService
      .updateLine(id, requestLineId, { lineStage: "VERIFIED" })
      .then((x) => {
        setLoading(false);
        message.success("Successfully verified!");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const handleRequestLineDelete = (requestLineId) => {
    setLoading(true);
    materialRequestService
      .deleteLine(id, requestLineId)
      .then((x) => {
        setLoading(false);
        message.success("Successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (
      ["VERIFICATION_INPROGRESS"].includes(
        materialRequest && materialRequest.orderStage
      )
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={
            materialRequest &&
            materialRequest.requestLines.flatMap((item) => item.requestedItems)
              .length === 0
          }
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={updating}
            disabled={
              materialRequest &&
              materialRequest.requestLines.flatMap(
                (item) => item.requestedItems
              ).length === 0
            }
          >
            Update
          </Button>
        </Popconfirm>
      );
      buttons.push(
        <Popconfirm
          title="Are you sure to complete verification?"
          onConfirm={onCompleteVerification}
          key="verify-success"
          disabled={enableVerify}
        >
          <Button
            key="btn-verify-success"
            htmlType="submit"
            loading={loadingCompleteVerification}
            type="primary"
            disabled={enableVerify}
          >
            Verified
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      width: "12%",
    });
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "15%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box/Roll",
      dataIndex: "totalItems",
      key: "totalBox",
      width: "6%",
      align: "right",
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "8%",
      align: "right",
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    columns.push({
      title: "Veri. Box",
      key: "verifiedBox",
      width: "6%",
      align: "right",
      render: (text, record) => {
        return record.requestedItems.length;
      },
    });
    columns.push({
      title: "Veri. Quantity",
      key: "verifyQuantity",
      width: "8%",
      align: "right",
      render: (text, record) => {
        return (
          record.requestedItems.length &&
          record.requestedItems
            .map((item) => item.quantity)
            .reduce((prev, next) => prev + next)
        );
      },
    });
    columns.push({
      title: "",
      key: "operation",
      width: "18%",
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleSearchAndAddStocks(record);
              }}
              icon={<SearchOutlined />}
              loading={availableStocksLoading && record.key === activeTabKey}
            >
              Stocks
            </Button>
            <Popconfirm
              title="Are you sure to delete this line?"
              onConfirm={() => handleRequestLineDelete(record.id)}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>
            <Popconfirm
              title="Are you sure to unverify this line?"
              onConfirm={() => handleRequestLineUnVerified(record.id)}
            >
              <UndoOutlined title="UnVerify" />
            </Popconfirm>
            <Popconfirm
              title="Are you sure, you save the changes before verify this line?"
              onConfirm={() => handleRequestLineVerified(record.id)}
            >
              <CheckOutlined title="Verify" />
            </Popconfirm>
          </Space>
        );
      },
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "5%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "12%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "5%",
    });
    columns.push({
      title: "Box #",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "3%",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "5%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "3%",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross (Sq.m)",
      dataIndex: "grossValueSqm",
      key: "grossValueSqm",
      align: "right",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "4%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "LOADED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "2%",
      render: (text, record) => {
        return (
          <span>
            {["REQUESTED"].includes(record.mode) && (
              <Popconfirm
                title="Are you sure to unverified this item?"
                onConfirm={() => handleStockItemUnVerified(record.id)}
              >
                <UndoOutlined title="UnVerify" />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const mrLineRowSelection = {
    selectedRowKeys: selectedMrLineKeys,
    onChange: (selectedMrLineKeys) => {
      //onTabChange(selectedMrLineKeys[0]);
      handleTabChange(selectedMrLineKeys[0]);
    },
  };

  const getTabContent = (requestedLines) => {
    let tabPanes = [];
    requestedLines.forEach((line, index) => {
      if (line.requestedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockTypeObj && line.stockTypeObj.code} / ${
              line.lotNumberObj && line.lotNumberObj.number
                ? line.lotNumberObj.number
                : ""
            } (${line.requestedItems && line.requestedItems.length})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.requestedItems}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 6}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{line.requestedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const handleSearchAndAddStocks = (line) => {
    //const stockTypeId = form.getFieldValue("stockType[" + line.key + "]");
    //const lotNumberId = form.getFieldValue("lotNumber[" + line.key + "]");
    const stockTypeId = line.stockType.id;
    const lotNumberId = line.lotNumber && line.lotNumber.id;
    setSelectedStockTypeId(stockTypeId);
    setSelectedLotNumberId(lotNumberId);
    setSelectedLineKey(line.key);
    handleTabChange(line.key);
    setEnableVerify(false);
    // console.log("Enable: ", enableVerify);

    if (stockTypeId) {
      /* let requestLine = null;
            data.forEach((item) => {
              if (item.hasOwnProperty("lotNumber")) {
                if (
                  item.stockType === materialTypeId &&
                  item.lotNumber === lotNumberId
                ) {
                  requestLine = item;
                }
              } else if (item.stockType === materialTypeId) {
                requestLine = item;
              }
            }); */
      if (line.requestedItems) {
        setSelectedStockIds(
          line.requestedItems
            .filter((item) => ["AVAILABLE"].includes(item.mode))
            .map((item) => item.id)
        );
        //console.log("SSI:", selectedStockIds);
        //selectedStockIds = line.requestedItems.map((item) => item.id);
      }
      getStocks(stockTypeId, lotNumberId);
    } else {
      message.error("Please select valid material type!");
    }
  };

  const getStocks = (stockTypeId, lotId) => {
    //let searchParams = `?stockTypeId=${materialTypeId}&lotNumberId=${lotNumberId}&status=AVAILABLE&pageNumber=1&pageSize=100`;
    let searchParams = `?stockTypeId=${stockTypeId}&modes=AVAILABLE&pageNumber=1&pageSize=1000`;
    searchParams = lotId
      ? `${searchParams}&lotNumberId=${lotId}`
      : searchParams;

    setIsAvailableStocksLoading(true);
    materialDetailService
      .searchAvailable(searchParams)
      .then((data) => {
        setIsAvailableStocksLoading(false);
        if (data.content.length > 0) {
          //console.log("Selected Items: ", selectedStockIds);
          //console.log("Stocks: ", data.content);
          setAvailableStocks(data.content);
          setIsStockSelectionVisible(true);
          //setMaterialTypeId(stockTypeId);
          //setLotNumberId(lotId);
        } else {
          message.info(
            "Sorry, There's no available stocks for the given criteria!"
          );
        }
      })
      .catch((error) => {
        setIsAvailableStocksLoading(false);
        message.error(`${error}`);
      });
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected: ", selectedStockIds);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    console.log("MR: ", form.getFieldValue("requestLines"));
    setEnableVerify(true);
    setStockItemLoading(true);
    if (selectedStocks) {
      let requestLine;
      if (selectedLotNumberId) {
        requestLine = form
          .getFieldValue("requestLines")
          .find(
            (line) =>
              line.id === activeTabKey &&
              line.stockType.id === selectedStockTypeId &&
              line.lotNumber &&
              line.lotNumber.id === selectedLotNumberId
          );
      } else {
        requestLine = form
          .getFieldValue("requestLines")
          .find(
            (line) =>
              line.id === activeTabKey &&
              line.stockType.id === selectedStockTypeId
          );
      }
      const stockTypeObj = materialTypes.find(
        (item) => item.id === selectedStockTypeId
      );
      const lotNumberObj = lotNumbers.find(
        (item) => item.id === selectedLotNumberId
      );
      if (requestLine) {
        requestLine["stockTypeObj"] = stockTypeObj;
        requestLine["lotNumberObj"] = lotNumberObj;
        requestLine["stockType"] = stockTypeObj;
        requestLine["lotNumber"] = lotNumberObj;
        requestLine["requestedItems"] = [
          ...requestLine.requestedItems.filter((item) =>
            ["REQUESTED"].includes(item.mode)
          ),
          ...selectedStocks,
        ];
        /* requestLine["netWeight"] =
                    selectedStocks.length > 0 &&
                    selectedStocks
                        .map((item) => item.netWeight)
                        .reduce((prev, next) => prev + next); */
        requestLine["grossWeight"] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);

        setMaterialRequestLines(form.getFieldValue("requestLines"));
        materialRequest.requestLines = form.getFieldValue("requestLines");
        handleTabChange(requestLine.id);
      }
    }
    form.setFieldsValue({ stockType: null, lotNumber: null });
    setSelectedStockTypeId(null);
    setSelectedLotNumberId(null);
    setIsStockSelectionVisible(false);
    setStockItemLoading(false);
    setSelectedStockIds([]);
    //console.log("requestLines:", materialRequestLines);
  };

  const handleLineDelete = () => {
    setMaterialRequestLines(form.getFieldValue("requestLines"));
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedMrLineKeys([activeKey.toString()]);
    setActiveRequestLine(
      materialRequestLines.find((line) => line.key === activeKey)
    );
  };

  const getRequestStageColor = (orderStage) => {
    let color = "#87d068";
    if (orderStage === "OPEN") {
      color = "#2db7f5";
    } else if (["IN_PROGRESS", "ACCEPTED"].includes(orderStage)) {
      color = "#108ee9";
    } else if (orderStage === "VERIFICATION_INPROGRESS") {
      color = "#d4b106";
    }

    return color;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag color={getRequestStageColor(materialRequest.orderStage)}>
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      //onChange={getAddresses}
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person Name"
                    rules={[
                      {
                        required: true,
                        message: "Contact persion is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact persion" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[
                      { required: true, message: "Request date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Request date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["deliveryLocation", "id"]}
                    label="Deliver To"
                    rules={[
                      {
                        required: true,
                        message: "Delivery from is required",
                      },
                    ]}
                  >
                    <Select placeholder="Please select">
                      {addresses.length > 0 &&
                        addresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact No:"
                    rules={[
                      { required: true, message: "Contact No. is required" },
                    ]}
                  >
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="requestNumber" label="Request No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="remark" label="Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>

              {materialRequest &&
                materialRequest.requestLines &&
                materialRequest.requestLines.length > 0 && (
                  <Table
                    bordered
                    columns={getLineColumns()}
                    dataSource={materialRequest.requestLines}
                    pagination={false}
                    rowClassName={(record) =>
                      ["VERIFIED", "COMPLETED"].includes(record.lineStage)
                        ? "ant-tag-green"
                        : ""
                    }
                    rowSelection={{
                      type: "radio",
                      ...mrLineRowSelection,
                    }}
                    size="small"
                    summary={(pageData) => {
                      let rollSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let boxSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let spoolSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let boxPcsSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let boxNosSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let boxConeSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let boxStripSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let boxRibnSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let bundlesSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      let defaultSummary = {
                        totalItems: 0,
                        totalQty: 0,
                        totalVerifiedItems: 0,
                        totalVerifiedQty: 0,
                        uom: "",
                        measurementType: null,
                      };
                      const groupByPackingType = Utils.groupBy(
                        pageData,
                        (line) =>
                          line.stockType &&
                          line.stockType.packingType &&
                          line.stockType.packingType.name
                      );
                      groupByPackingType.forEach((value, key) => {
                        if (key === "ROLL") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              rollSummary.totalItems += totalItems;
                              rollSummary.totalQty += quantity;
                              rollSummary.measurementType = measurementType;
                              rollSummary.totalVerifiedItems +=
                                requestedItems.length;
                              rollSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BOX") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              boxSummary.totalItems += totalItems;
                              boxSummary.totalQty += quantity;
                              boxSummary.measurementType = measurementType;
                              boxSummary.totalVerifiedItems +=
                                requestedItems.length;
                              boxSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "SPOOL") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              spoolSummary.totalItems += totalItems;
                              spoolSummary.totalQty += quantity;
                              spoolSummary.measurementType = measurementType;
                              spoolSummary.totalVerifiedItems +=
                                requestedItems.length;
                              spoolSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BOX_PCS") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              boxPcsSummary.totalItems += totalItems;
                              boxPcsSummary.totalQty += quantity;
                              boxPcsSummary.measurementType = measurementType;
                              boxPcsSummary.totalVerifiedItems +=
                                requestedItems.length;
                              boxPcsSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BOX_NOS") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              boxNosSummary.totalItems += totalItems;
                              boxNosSummary.totalQty += quantity;
                              boxNosSummary.measurementType = measurementType;
                              boxNosSummary.totalVerifiedItems +=
                                requestedItems.length;
                              boxNosSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BOX_CONE") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              boxConeSummary.totalItems += totalItems;
                              boxConeSummary.totalQty += quantity;
                              boxConeSummary.measurementType = measurementType;
                              boxConeSummary.totalVerifiedItems +=
                                requestedItems.length;
                              boxConeSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BOX_STRP") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              boxStripSummary.totalItems += totalItems;
                              boxStripSummary.totalQty += quantity;
                              boxStripSummary.measurementType = measurementType;
                              boxStripSummary.totalVerifiedItems +=
                                requestedItems.length;
                              boxStripSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BOX_RIBN") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              boxRibnSummary.totalItems += totalItems;
                              boxRibnSummary.totalQty += quantity;
                              boxRibnSummary.measurementType = measurementType;
                              boxRibnSummary.totalVerifiedItems +=
                                requestedItems.length;
                              boxRibnSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else if (key === "BUNDLES") {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              bundlesSummary.totalItems += totalItems;
                              bundlesSummary.totalQty += quantity;
                              bundlesSummary.measurementType = measurementType;
                              bundlesSummary.totalVerifiedItems +=
                                requestedItems.length;
                              bundlesSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        } else {
                          value.forEach(
                            ({
                              totalItems,
                              quantity,
                              measurementType,
                              requestedItems,
                            }) => {
                              defaultSummary.totalItems += totalItems;
                              defaultSummary.totalQty += quantity;
                              defaultSummary.measurementType = measurementType;
                              defaultSummary.totalVerifiedItems +=
                                requestedItems.length;
                              defaultSummary.totalVerifiedQty +=
                                requestedItems.length > 0 &&
                                requestedItems
                                  .map((item) => item.quantity)
                                  .reduce((prev, next) => prev + next);
                            }
                          );
                        }
                      });

                      return (
                        <>
                          {rollSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - ROLL</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(rollSummary.totalItems) &&
                                    rollSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(rollSummary.totalQty) &&
                                    rollSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {rollSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {rollSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {rollSummary.totalVerifiedQty &&
                                    Number(
                                      rollSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {boxSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BOX</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxSummary.totalItems) &&
                                    boxSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxSummary.totalQty) &&
                                    boxSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {boxSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxSummary.totalVerifiedQty &&
                                    Number(boxSummary.totalVerifiedQty).toFixed(
                                      2
                                    )}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {spoolSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - SPOOL</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(spoolSummary.totalItems) &&
                                    spoolSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(spoolSummary.totalQty) &&
                                    spoolSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {spoolSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {spoolSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {spoolSummary.totalVerifiedQty &&
                                    Number(
                                      spoolSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {boxPcsSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BOX(PCS)</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxPcsSummary.totalItems) &&
                                    boxPcsSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxPcsSummary.totalQty) &&
                                    boxPcsSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {boxPcsSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxPcsSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxPcsSummary.totalVerifiedQty &&
                                    Number(
                                      boxPcsSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {boxNosSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BOX(NOS)</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxNosSummary.totalItems) &&
                                    boxNosSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxNosSummary.totalQty) &&
                                    boxNosSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {boxNosSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxNosSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxNosSummary.totalVerifiedQty &&
                                    Number(
                                      boxNosSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {boxConeSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BOX(CONE)</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxConeSummary.totalItems) &&
                                    boxConeSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxConeSummary.totalQty) &&
                                    boxConeSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {boxConeSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxConeSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxConeSummary.totalVerifiedQty &&
                                    Number(
                                      boxConeSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {boxStripSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BOX(STRP)</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxStripSummary.totalItems) &&
                                    boxStripSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxStripSummary.totalQty) &&
                                    boxStripSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {boxStripSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxStripSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxStripSummary.totalVerifiedQty &&
                                    Number(
                                      boxStripSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {boxRibnSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BOX(RIBN)</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxRibnSummary.totalItems) &&
                                    boxRibnSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(boxRibnSummary.totalQty) &&
                                    boxRibnSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {boxRibnSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxRibnSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxRibnSummary.totalVerifiedQty &&
                                    Number(
                                      boxRibnSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {bundlesSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total - BUNDLES</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(bundlesSummary.totalItems) &&
                                    bundlesSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(bundlesSummary.totalQty) &&
                                    bundlesSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {bundlesSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {bundlesSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {bundlesSummary.totalVerifiedQty &&
                                    Number(
                                      bundlesSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                          {defaultSummary.totalItems > 0 && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={5}>
                                <Text strong>Total</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(defaultSummary.totalItems) &&
                                    defaultSummary.totalItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {!isNaN(defaultSummary.totalQty) &&
                                    defaultSummary.totalQty}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong>
                                  {defaultSummary.measurementType.name}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {defaultSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {defaultSummary.totalVerifiedQty &&
                                    Number(
                                      defaultSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          )}
                        </>
                      );
                    }}
                  />
                )}
            </Form>
            <Modal
              title={`Stocks (${availableStocks.length}) - Req. Box=${
                activeRequestLine && activeRequestLine.totalItems
                  ? activeRequestLine.totalItems
                  : "-"
              }, Req. Quantity=${
                activeRequestLine && activeRequestLine.quantity
                  ? activeRequestLine.quantity
                  : "-"
              } `}
              style={{ top: 48 }}
              visible={isStockSelectionVisible}
              okText={`Add Stocks (${selectedStockIds.length})`}
              onOk={handleAddStocks}
              //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
              onCancel={() => {
                setIsStockSelectionVisible(false);
                setSelectedStockIds([]);
              }}
              width={1300}
              destroyOnClose={true}
            >
              <StockSelection
                stocks={availableStocks}
                selectedIds={selectedStockIds}
                onSelectChange={handleStockSelection}
              />
            </Modal>
            {materialRequestLines.length > 0 &&
              materialRequestLines.flatMap((item) => item.requestedItems)
                .length > 0 && (
                <>
                  <Divider orientation="left">Verified Stock Items</Divider>
                  <Tabs
                    onChange={handleTabChange}
                    activeKey={activeTabKey}
                    type="card"
                  >
                    {getTabContent(materialRequestLines)}
                  </Tabs>
                </>
              )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymVerify;
