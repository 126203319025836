import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { AddressTypes } from "_helpers";
import { addressService, companyService } from "_services";
import TabMenu from "../TabMenu";

function Add({ history, match }) {
  const { id } = match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setIsLoading(true);
    companyService.getById(id).then((x) => {
      setCompany(x.content);
      setIsLoading(false);
    });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        console.log("PL:", JSON.stringify(values));
        addressService
          .create(id, values)
          .then(() => {
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {message.error(`${error}`);});
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies">Company Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies/add">Company: {company.code}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies/add">Addresses</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies/add">New Address</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <TabMenu menu="addresses" id={id} />
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[{ required: true, message: "Code is required" }]}
                >
                  <Input placeholder="Code" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[{ required: true, message: "Address is required" }]}
                >
                  <Input placeholde="Address" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="area" label="Area">
                  <Input placeholder="Area" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="addressType"
                  label="Type"
                  rules={[
                    { required: true, message: "Address type is required" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {AddressTypes.map((t) => (
                      <Option value={t.value} key={t.value}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
