import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  PageHeader,
  Popconfirm,
  Table,
  message,
  Tooltip,
  Typography,
} from "antd";
import Page from "_components/Page";
import { binService } from "_services";
import Search from "../Search";
import {
  BarcodeOutlined,
  CloseOutlined,
  EditOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { Auth } from "_helpers";

const SiluetaList = ({ match }) => {
  const { path } = match;
  const [stockBins, setStockBins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBarcodes, setLoadingBarcodes] = useState(false);
  const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState("");
  const { Text } = Typography;

  useEffect(() => {
    //getStockBins(pageNumber, pageSize);
  }, []);

  const getStockBins = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    binService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setStockBins(data.content);
        setLoading(false);
        //setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setStockBins(results);
    //setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getStockBins(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    binService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getStockBins(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Link to={`${path}/add`} key="1">
          <Button>Add</Button>
        </Link>
      );
    }
    buttons.push(
      <Button
        key="barcode"
        icon={<BarcodeOutlined />}
        disabled={stockBins.length === 0}
        onClick={handleGenerateBarcodes}
        loading={loadingBarcodes}
      ></Button>
    );
    buttons.push(
      <Tooltip
        key="tp-download-excel"
        placeholder="topLeft"
        title="Export Excel"
      >
        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          disabled={stockBins.length === 0}
          onClick={handleDownloadExcel}
          loading={loadingDownloadExcel}
        ></Button>
      </Tooltip>
    );
    return buttons;
  };

  const handleGenerateBarcodes = () => {
    if (stockBins.length > 0) {
      setLoadingBarcodes(true);
      binService
        .printBarcode(`${searchParams}`)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `BinBarcodes.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoadingBarcodes(false);
        })
        .catch((error) => {
          setLoadingBarcodes(false);
        });
    }
  };

  const handleDownloadExcel = () => {
    setLoadingDownloadExcel(true);
    binService
      .downloadExcel(`${searchParams}`)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Stock-Bins.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingDownloadExcel(false);
      })
      .catch((error) => {
        setLoadingDownloadExcel(false);
        message.error(error);
      });
  };

  const columns = [
    {
      title: "SBU",
      dataIndex: "sbuName",
      key: "sbuName",
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      key: "zoneName",
    },
    {
      title: "Sub-Location",
      dataIndex: "subLocationName",
      key: "subLocationName",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => (
        <Link to={`/bins/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: "Available Stocks",
      dataIndex: "availableStocks",
      key: "availableStocks",
      align: "right",
    },
    /*     {
      title: "Available N.Weight",
      dataIndex: "availableNetWeight",
      key: "availableNetWeight",
      align: "right",
    }, */
    /* {
      title: "Available G.Weight",
      dataIndex: "availableGrossWeight",
      key: "availableGrossWeight",
      align: "right",
    }, */
    {
      title: "Available Quantity",
      dataIndex: "availableQuantity",
      key: "availableQuantity",
      align: "right",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "8%",
    },
    {
      title: "Modified By",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      width: "8%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            {Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) && (
              <Button
                icon={<EditOutlined />}
                title="Edit"
                type="link"
                style={{ marginRight: 10 }}
                href={`/bins/${record.id}/summary`}
                key="btn-edit"
              ></Button>
            )}
            {Auth.hasRole(Auth.getProfile(), ["SAU"]) && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleDelete(record.id)}
                disabled={Auth.hasRole(Auth.getProfile(), ["SAU"])}
                key="pop-close"
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/bins">Bin Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={stockBins}
            pagination={false}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={3}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text strong>{stockBins.length}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      {stockBins.length > 0 &&
                        stockBins
                          .map((item) => item.availableStocks)
                          .reduce((prev, next) => prev + next)}
                    </Table.Summary.Cell>
                    {/* <Table.Summary.Cell align="right">
                      {stockBins.length > 0 &&
                        Number(
                          stockBins
                            .map((item) => item.availableNetWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                    </Table.Summary.Cell> */}
                    {/* <Table.Summary.Cell align="right">
                      {stockBins.length > 0 &&
                        Number(
                          stockBins
                            .map((item) => item.availableGrossWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                    </Table.Summary.Cell> */}
                    <Table.Summary.Cell align="right">
                      {stockBins.length > 0 &&
                        Number(
                          stockBins
                            .map((item) => item.availableQuantity)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
            /* pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: true,
              onChange: handlePaginateChange,
            }} */
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default SiluetaList;
