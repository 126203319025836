import React from "react";
import { Route, Switch } from "react-router-dom";
import Add from "./Add";

import StrRmAddWithLines from "./str_rm/AddWithLines";
import StrRmSummary from "./str_rm/Summary";
import StrRmConfirm from "./str_rm/Confirm";
import StrRmEditWithLines from "./str_rm/EditWithLines";
import StrRmList from "./str_rm/List";
import StrRmLoading from "./str_rm/Loading";
import StrRmBulkLoading from "./str_rm/BulkLoading";
import StrRmHistory from "./str_rm/History";
import StrRmDocument from "./str_rm/Document";
import StrRmReady from "./str_rm/Ready";
import StrAdjustmentUpload from "./str_rm/AdjustmentUpload";

import PrymAddWithLines from "./prym/AddWithLines";
import PrymEditWithLines from "./prym/EditWithLines";
import PrymList from "./prym/List";
import PrymSummary from "./prym/Summary";
import PrymEdit from "./prym/Edit";
import PrymConfirm from "./prym/Confirm";
import PrymAdd from "./prym/Add";

import List from "./List";
import Summary from "./Summary";
import Edit from "./Edit";
import Confirm from "./Confirm";
import Upload from "./Upload";

import SiluetaAdd from "./silueta/Add";
import SiluetaSummary from "./silueta/Summary";
import SiluetaEdit from "./silueta/Edit";
import SiluetaConfirm from "./silueta/Confirm";

import StrGrSummary from "./str_gr/Summary";
import StrGrEdit from "./str_gr/Edit";
import StrGrConfirm from "./str_gr/Confirm";
import StrGrAdd from "./str_gr/Add";

import { Auth } from "_helpers";

function StockIssue({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={SiluetaAdd} />
        <Route path={`${path}/:id/summary`} component={SiluetaSummary} />
        <Route path={`${path}/:id/edit`} component={SiluetaEdit} />
        <Route path={`${path}/:id/confirm`} component={SiluetaConfirm} />
        <Route path={`${path}/upload`} component={Upload} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR_GR") {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={StrGrAdd} />
        <Route path={`${path}/:id/summary`} component={StrGrSummary} />
        <Route path={`${path}/:id/edit`} component={StrGrEdit} />
        <Route path={`${path}/:id/confirm`} component={StrGrConfirm} />
        <Route path={`${path}/upload`} component={Upload} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrRmList} />
        <Route path={`${path}/add`} component={StrRmAddWithLines} />
        <Route path={`${path}/:id/summary`} component={StrRmSummary} />
        {/* <Route path={`${path}/:id/edit`} component={StrRmEditWithLines} /> */}
        <Route path={`${path}/:id/confirm`} component={StrRmConfirm} />
        <Route path={`${path}/:id/loading`} component={StrRmBulkLoading} />
        <Route path={`${path}/:id/ready`} component={StrRmReady} />
        <Route path={`${path}/:id/history`} component={StrRmHistory} />
        <Route path={`${path}/:id/documents`} component={StrRmDocument} />
        <Route path={`${path}/upload`} component={Upload} />
        <Route path={`${path}/adjustment-upload`} component={StrAdjustmentUpload} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymList} />
        <Route path={`${path}/add`} component={PrymAddWithLines} />
        <Route path={`${path}/:id/summary`} component={PrymSummary} />
        <Route path={`${path}/:id/edit`} component={PrymEditWithLines} />
        <Route path={`${path}/:id/confirm`} component={PrymConfirm} />
        <Route path={`${path}/upload`} component={Upload} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/:id/summary`} component={Summary} />
        <Route path={`${path}/:id/edit`} component={Edit} />
        <Route path={`${path}/:id/confirm`} component={Confirm} />
        <Route path={`${path}/upload`} component={Upload} />
      </Switch>
    );
  }
}

export { StockIssue };
