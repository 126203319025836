import {
  Form,
  InputNumber,
  Input,
  List,
  Badge,
  Progress,
  Spin,
  Modal,
} from "antd";
//import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { binService } from "_services";
import StockSubBinSelection from "./StockSubBinSelection";
import { Auth } from "_helpers";

const StockBinSelection = (props) => {
  const orderLine = props.orderLine;
  const receiptLines = props.receiptLines;
  //const [receiptLines, setReceiptLines] = useState(props.receiptLines);
  //console.log("StockBins--:", props.stockBins);
  /* orderLineAccepted =  receiptLines &&
    receiptLines.length > 0 &&
    receiptLines
      .filter((obj) => obj.orderLineId === orderLine.id)
      .map((obj) => obj.accepted)
      .reduce((prev, next) => prev + next); */
  //console.log("orderLineAccepted--:", orderLineAccepted);
  const binStocks = receiptLines.filter(
    (item) => item.orderLineId === orderLine.id
  );
  const [receiptBins] = useState(binStocks);
  const [loading, setLoading] = useState(false);
  const [stockBins, setStockBins] = useState(props.stockBins);
  const [searchParams, setSearchParams] = useState("");
  const [showAddQuantity, setShowAddQuantity] = useState(false);
  const [selectedBin, setSelectedBin] = useState(null);
  const [loadingSubBins, setLoadingSubBins] = useState(false);
  const [showSubBins, setShowSubBins] = useState(false);
  const [errorQuantity, setErrorQuantity] = useState(false);
  const [form] = Form.useForm();

  /* useEffect(() => {
    getStockBins(searchParams);
  }, []); */

  /* const getStockBins = (searchValues) => {
    setLoading(true);
    binService
      .getSuggestion(searchValues)
      .then((data) => {
        data.content.map((obj) => ({
          ...obj,
          totalStocks: parseInt(obj.availableStockQuantity),
        }));
        setStockBins(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }; */

  const onSearch = (value) => {
    /* let searchParams = "";
    if (value) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `code=${encodeURIComponent(value)}`;
    }
    setLoading(true);
    setSearchParams(searchParams);
    getStockBins(searchParams); */
    if (value) {
      setStockBins(
        props.stockBins.filter(
          (obj) =>
            obj.code.includes(value.toUpperCase()) ||
            obj.name.includes(value.toUpperCase())
        )
      );
    } else {
      setStockBins(props.stockBins);
    }
  };

  /* const handlePaginateChange = (pageNumber, pageSize) => {
    getStockBins(pageNumber, pageSize);
  }; */

  const handleAcceptedQuantity = (binId, value) => {
    //console.log("BinId: ", binId, ", Qty: ", value);
    let receipt = receiptBins.find((item) => item.binId === binId);
    if (receipt) {
      receipt.accepted = value;
    }
    props.onQuantityChange(orderLine.id, binId, value);
  };

  const getModalContent = () => {
    let quantity = null;
    if (binStocks && selectedBin) {
      let receipt = binStocks.find((item) => item.binId === selectedBin.id);
      if (receipt) {
        quantity = receipt.accepted;
      }
    }

    form.setFieldsValue({ quantity: quantity });

    return (
      <Form form={form} name="stock-add-form" layout="inline">
        <Form.Item
          name="quantity"
          rules={[{ required: true, message: "Please input valid quantity!" }]}
        >
          <InputNumber min={0} placeholder="Quantity" />
        </Form.Item>
      </Form>
    );
  };

  /* const getSubBinModalContent = () => {
    return (
      <div>
        <Progress
          percent={parseInt(
            receiptLines.length > 0 &&
              receiptLines.filter((obj) => obj.orderLineId === orderLine.id)
                .length > 0 &&
              (receiptLines
                .filter((obj) => obj.orderLineId === orderLine.id)
                .map((obj) => obj.accepted)
                .reduce((prev, next) => prev + next) /
                props.orderLine.quantity) *
                100
          )}
        />
        <Row>
          <Col>
            <Input
              type="number"
              addonAfter={
                <PlusOutlined
                  style={{ cursor: "pointer" }}
                  onClick={handleAddParentBinQuantity}
                />
              }
              onChange={onParentBinQuantityChange}
              value={parentBinQuantity}
              min={0}
            />
          </Col>
        </Row>
        <List
          loading={false}
          itemLayout="horizontal"
          dataSource={selectedBin.stockBins}
          renderItem={(bin) => (
            <List.Item>
              <Skeleton title={false} loading={false}>
                <List.Item.Meta title={bin.code} />
                <div>
                  <Input
                    value={bin.totalStocks}
                    disabled
                    addonAfter={<PlusOutlined />}
                    addonBefore={<MinusOutlined />}
                    style={{ width: "50%" }}
                  />
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    );
  }; */

  /* const setSelectedParentBinQuantity = (bin) => {
    let newlyAddedParentQuantity = 0;
    const modifiedBins = bin.stockBins.filter((bin) => bin.isNewlyAdded);
    if (modifiedBins.length > 0) {
      newlyAddedParentQuantity = modifiedBins
        .map((bin) => bin.totalStocks)
        .reduce((prev, next) => prev + next);
    }
    setParentBinQuantity(newlyAddedParentQuantity);
  }; */

  /* const onParentBinQuantityChange = (e) => {
    e.target.value && setParentBinQuantity(e.target.value);
  }; */

  /* const handleAddParentBinQuantity = () => {
    let quantity = parentBinQuantity;
    for (const bin of selectedBin.stockBins) {
      if (bin.isNewlyAdded) {
        bin.totalStocks = 0;
        delete bin.isNewlyAdded;
        handleSubBinQuantityChange(selectedBin.id, orderLine.id, bin.id, 0);
      }
    }

    for (const bin of selectedBin.stockBins) {
      if (parseInt(quantity) !== 0 && parseInt(bin.totalStocks) === 0) {
        quantity--;
        bin.totalStocks = 1;
        bin.isNewlyAdded = true;
        handleSubBinQuantityChange(selectedBin.id, orderLine.id, bin.id, 1);
      }
    }
    setSelectedParentBinQuantity(selectedBin);
  }; */

  const handleShowAddQuantity = (bin) => {
    setSelectedBin(null);
    if (!bin.checkedSubBins) {
      //console.log("------Checking sub bins---(", bin.id, ")");
      setLoadingSubBins(true);
      binService
        .getParentById(bin.id)
        .then((data) => {
          bin.checkedSubBins = true;
          if (data.content.length > 0) {
            bin.stockBins = data.content;
            bin.hasSubBins = true;
            bin.stockBins.forEach((subBin) => {
              if (
                Auth.getProfile().comp_code &&
                ["STR-GR"].includes(Auth.getProfile().comp_code) &&
                subBin.totalStocks === 1
              ) {
                subBin.vacant = false;
              } else {
                subBin.vacant = true;
              }
            });
            //console.log("Loaded SubBins:", bin.stockBins);
            setSelectedBin(bin);
            setShowSubBins(true);
          } else {
            bin.hasSubBins = false;
            if (!bin.hasOwnProperty("addedQuantity")) {
              bin.addedQuantity = 0;
            }
            setSelectedBin(bin);
            setShowAddQuantity(true);
          }
          setLoadingSubBins(false);
        })
        .catch((error) => {
          setLoadingSubBins(false);
        });
    } else {
      //console.log("------Already Checked sub bins---(", bin.id, ")");
      setSelectedBin(bin);
      if (bin.hasSubBins) {
        setShowSubBins(true);
      } else {
        setShowAddQuantity(true);
      }
    }
  };

  const handleAddQuantity = () => {
    //selectedBin.availableStockQuantity += addedQuantity;
    form
      .validateFields()
      .then((values) => {
        let bin = stockBins.find((item) => item.id === selectedBin.id);

        if (bin) {
          //console.log("SelBID: ", selectedBin.id, ", AllBins: ", bin);

          let total = values.quantity;
          //console.log("Added:", values.quantity);
          if (props.receiptLines.length > 0) {
            //console.log("Total2:", props.receiptLines, ", id: ", bin.id);
            let totalAccepted = 0;
            props.receiptLines.forEach((obj) => {
              //console.log("Receipt:", obj, ", OL:", orderLine.id);
              if (obj.binId === bin.id && obj.orderLineId !== orderLine.id) {
                totalAccepted += obj.accepted;
              }
            });
            //console.log("Total3:", totalAccepted);
            total =
              totalAccepted === 0 ? total : totalAccepted + values.quantity;
            //console.log("Total:", total, ", Prev:", bin.availableStockQuantity);
            /* let totalAccepted = props.receiptLines
              .filter((obj) => obj.binId == bin.id)
              .map((obj) => obj.accepted); */
            //.reduce((prev, next) => prev + next);
          }

          bin.totalStocks = total + bin.availableStockQuantity;
          handleAcceptedQuantity(selectedBin.id, values.quantity);
          setSelectedBin(null);
          setShowAddQuantity(false);
        }
        //setStockBins(stockBins);
        form.resetFields();
      })
      .catch((error) => {});
  };

  const handleAddQuantityCancel = () => {
    form.resetFields();
    setSelectedBin(null);
    setShowAddQuantity(false);
  };

  const handleSubBinQuantityChange = (
    parentBinId,
    orderLineId,
    binId,
    quantity
  ) => {
    /* console.log(
      "LineId: ",
      orderLineId,
      ", binId: ",
      binId,
      ", Qty: ",
      quantity
    ); */
    const parentBin = stockBins.find((item) => item.id === parentBinId);
    parentBin.totalStocks = parentBin.stockBins
      .map((item) => item.totalStocks)
      .reduce((prev, next) => prev + next);
    //handleAcceptedQuantity(binId, quantity);

    //setReceiptLines(props.receiptLines);
    //console.log("ReceiptLines:", receiptLines);
    props.onQuantityChange(orderLineId, binId, quantity);
    /* let receiptLine = receiptLines.find(
      (item) => item.orderLineId === orderLineId && item.binId === binId
    );
    if (receiptLine) {
      receiptLine.accepted = quantity;
      props.onAddQuantity(quantity);
    } else {
      let receiptLine = {
        orderLineId: orderLineId,
        binId: binId,
        accepted: quantity,
      };
      receiptLines.push(receiptLine);
      onAddQuantity(quantity);
    }
    setReceiptLines(receiptLines);
    if (orderLine) {
      orderLine.totalAccepted = receiptLines
        .filter((item) => item.orderLineId === orderLine.id)
        .map((item) => item.accepted)
        .reduce((accumulator, currentValue) => accumulator + currentValue);

      if (orderLine.totalAccepted > orderLine.quantity) {
        setErrorQuantity(true);
      } else {
        setErrorQuantity(false);
      }
    } */
  };
  /* const availableStockBinQuantity = (e, bin) => {
    console.log("Select:", e);
    return parseInt(bin.availableStockQuantity);
  }; */

  /* const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "50%",
    },
    {
      title: "Quantity in Bin",
      dataIndex: "availableStockQuantity",
      key: "availableStockQuantity",
      width: "25%",
    },
    {
      title: "Quantity",
      key: "addedQuantity",
      width: "25%",
      render: (text, record) => {
        let quantity = null;
        if (receiptBins) {
          let receipt = receiptBins.find((item) => item.binId === record.id);
          if (receipt) {
            quantity = receipt.accepted;
          }
        }
        if (quantity) {
          return (
            <InputNumber
              value={quantity}
              min={1}
              onChange={(e) => handleAcceptedQuantity(record.id, e)}
            />
          );
        } else {
          return (
            <InputNumber
              min={1}
              onChange={(e) => handleAcceptedQuantity(record.id, e)}
            />
          );
        }
      },
    },
  ]; */

  return (
    <div>
      <Input.Search
        allowClear
        onSearch={onSearch}
        style={{ width: "100%", marginBottom: 10 }}
        placeholder="Enter Bin code and search"
      />
      <Progress
        percent={parseInt(
          props.receiptLines.length > 0 &&
            props.receiptLines.filter(
              (obj) => obj.orderLineId === props.orderLine.id
            ).length > 0 &&
            (props.receiptLines
              .filter((obj) => obj.orderLineId === props.orderLine.id)
              .map((obj) => obj.accepted)
              .reduce((prev, next) => prev + next) /
              props.orderLine.quantity) *
              100
        )}
      />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 12,
          xxl: 3,
        }}
        style={{ padding: 10 }}
        loading={loading}
        dataSource={stockBins}
        renderItem={(bin) => (
          <List.Item>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleShowAddQuantity(bin);
              }}
            >
              <Badge count={bin.totalStocks} overflowCount={1000}>
                <span
                  className="head-example"
                  style={{
                    width: 90,
                    height: 30,
                    padding: 6,
                    backgroundColor: "rgb(238, 238, 238)",
                    borderRadius: 4,
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <b>{bin.code}</b>
                </span>
              </Badge>
            </a>
          </List.Item>
        )}
      />
      <Spin tip="Loading..." spinning={loadingSubBins}>
        <Modal
          title="Add Quantity"
          visible={showAddQuantity}
          onOk={handleAddQuantity}
          onCancel={handleAddQuantityCancel}
        >
          {getModalContent()}
        </Modal>
        <Modal
          title={
            <div>
              Bins - [
              {props.receiptLines.length > 0 &&
                props.receiptLines.filter(
                  (obj) => obj.orderLineId === orderLine.id
                ).length > 0 &&
                props.receiptLines
                  .filter((obj) => obj.orderLineId === orderLine.id)
                  .map((obj) => obj.accepted)
                  .reduce((prev, next) => prev + next)}
              /{orderLine && orderLine.quantity}]
            </div>
          }
          style={{ top: 48, height: 150 }}
          visible={showSubBins}
          onOk={() => {
            setShowSubBins(false);
          }}
          onCancel={() => {
            setShowSubBins(false);
          }}
          class="bin-selection"
        >
          {/* selectedBin && getSubBinModalContent() */}
          <StockSubBinSelection
            onQuantityChange={handleSubBinQuantityChange}
            orderLine={orderLine}
            receiptLines={receiptLines}
            parentBin={selectedBin}
          />
        </Modal>
      </Spin>

      {/* <Table
        bordered
        loading={loading}
        rowKey="id"
        size="small"
        columns={columns}
        dataSource={stockBins}
        pagination={{
          current: pageNumber,
          pageSize: pageSize,
          total: totalRecords,
          onChange: handlePaginateChange,
        }}
        scroll={{ y: 340 }}
      /> */}
    </div>
  );
};

export default StockBinSelection;
