import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, message, Checkbox } from 'antd';
import {
  binService,
  companyService,
  sbuService,
  subLocationService,
  zoneService,
} from '_services';
import { Auth } from '_helpers';

function Search(props) {
  const [addressLoading, setAddressLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    companyService.getSuggestion('?companyType=STORE').then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const warehouseLocations = data.content.addresses.filter(
            (item) => item.addressType === 'WAREHOUSE'
          );
          setAddresses(warehouseLocations);
          if (warehouseLocations.length === 1) {
            form.setFieldsValue({ storeLocationId: warehouseLocations[0].id });
          }
        });
        form.setFieldsValue({
          storeId: x.content[0].id,
          //storeLocation: y.content[0],
        });
      }
      setCompanies(x.content);
    });

    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
        if (x.content.length === 1) {
          form.setFieldsValue({ sbuId: x.content[0].id });
          setSelectedSbuId(x.content[0].id);
        }
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search('')
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  /* const getCompanyAddresses = (companyId) => {
    if (companyId) {
      setAddressLoading(true);
      addressService.getAllByCompany(companyId).then((x) => {
        setAddresses(x.content);
        setAddressLoading(false);
      });
    } else {
      form.resetFields(["storeLocationId"]);
      setAddresses([]);
    }
  }; */

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '?isParent=1';
    if (values.code) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.name) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    if (values.storeId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `storeId=${encodeURIComponent(values.storeId)}`;
    }
    if (values.storeLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `storeLocationId=${encodeURIComponent(
        values.storeLocationId
      )}`;
    }
    if (values.sbuId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `sbuId=${encodeURIComponent(values.sbuId)}`;
    }
    if (values.zoneId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `zoneId=${encodeURIComponent(values.zoneId)}`;
    }
    if (values.subLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `subLocationId=${encodeURIComponent(
        values.subLocationId
      )}`;
    }
    if (values.isEmpty) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `isEmpty=${encodeURIComponent(values.isEmpty)}`;
    }
    binService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, null, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='sbuId' label='SBU'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              <Select.Option value='NONE' key='none'>
                ---NONE---
              </Select.Option>
              {businessUnits.length > 0 &&
                businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='zoneId' label='Zone'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              <Select.Option value='NONE' key='none'>
                ---NONE---
              </Select.Option>
              {zones.length > 0 &&
                zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='subLocationId' label='Sub-Location'>
            <Select
              placeholder='Please select'
              allowClear
              optionFilterProp='children'
              showSearch
              disabled={!selectedZoneId}
            >
              <Select.Option value='NONE' key='none'>
                ---NONE---
              </Select.Option>
              {subLocations.length > 0 &&
                subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='storeId' label='Store'>
            <Select
              placeholder='Please select'
              //onChange={getCompanyAddresses}
              allowClear
            >
              {companies.length > 0 &&
                companies.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name='code' label='Code'>
            <Input placeholder='Code' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='name' label='Name'>
            <Input placeholde='Name' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='isEmpty' valuePropName='checked'>
            <Checkbox>Empty Bin</Checkbox>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name='storeLocationId' label='Store Location'>
            <Select
              placeholder='Please select'
              loading={addressLoading}
              allowClear
            >
              {addresses.length > 0 &&
                addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}></Col>
      </Row>
    </Form>
  );
}

export default Search;
