import React from "react";
import { Route, Switch } from "react-router-dom";
import Upload from "./Upload";
import StrUpload from "./str/Upload";
import { Auth } from "_helpers";

function Compare({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrUpload} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={Upload} />
      </Switch>
    );
  }
}

export { Compare };
