import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Tag,
  Spin,
  Popconfirm,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  materialTypeService,
  lotNumberService,
  materialRequestService,
  measurementTypeService,
  departmentService,
  machineService,
  sbuService,
  zoneService,
  subLocationService,
  supplierService,
} from "_services";
import RequestTableForm from "./components/RequestTableForm";
import TabMenu from "./TabMenu";

function StrRmEdit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [accepting, setAccepting] = useState(false);
  //const [customers, setCustomers] = useState([]);
  //const [addresses, setAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequest, setMaterialRequest] = useState({});
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedSbuCode, setSelectedSbuCode] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [form] = Form.useForm();

  const { Option } = Select;
  const { TextArea } = Input;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      /* const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      ); */
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const departmentData = await departmentService.getSuggestion();
      const machineData = await machineService.getSuggestion();
      const materialRequestData = await materialRequestService.getById(id);

      if (materialRequestData) {
        let materialRequest = materialRequestData.content;
        if (
          !["OPEN", "VERIFICATION", "PREPARE"].includes(
            materialRequest.orderStage
          )
        ) {
          history.push(`/material-requests/${materialRequest.id}/summary`);
        }
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((line, i) => {
          line.key = i;
          line.quantity = line.netWeight || line.totalItems || line.qty;
        });
        /* const mrCustomer = await companyService.getById(
          materialRequest.customer.id
        );
        const officeAddresses = mrCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        ); */
        materialRequest.subLocationId = materialRequest.subLocation?.id;
        materialRequest.zoneId = materialRequest.subLocation?.zone?.id;
        materialRequest.sbuId = materialRequest.subLocation?.zone?.sbu?.id;
        materialRequest.sbuCode = materialRequest.subLocation?.zone?.sbu?.code;

        //console.log("MR:", materialRequest);
        setMaterialRequest(materialRequest);
        //setAddresses(officeAddresses);
        setSelectedZoneId(materialRequest.zoneId);
        setSelectedSbuId(materialRequest.sbuId);
        setSelectedSbuCode(materialRequest.sbuCode);
        onFill(materialRequest);
      }

      //setCustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setDepartments(departmentData.content);
      setMachines(machineData.content);
      setLoading(false);
    };

    fetchAlldatas();
    getBusinessUnits();
    getZones();
    getSubLocations();
    getSuppliers();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const validateRequestLines = (lines) => {
    let validLines = true;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (selectedSbuCode === "GP") {
          if (
            line.supplier &&
            line.department &&
            line.mrnNumber &&
            line.requestTime &&
            line.stockType &&
            line.lotNumber &&
            line.quantity &&
            line.measurementType
          ) {
            validLines = true;
          }
        } else {
          if (
            !line.department ||
            !line.mrnNumber ||
            !line.requestTime ||
            !line.stockType ||
            !line.quantity ||
            !line.measurementType
          ) {
            validLines = false;
          }
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        // console.log("Values:", values);
        if (validateRequestLines(values.requestLines)) {
          setUpdating(true);

          values.requestDate = moment(values.requestDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          values.requestLines.forEach((line) => {
            delete line.requestedItems;
            delete line.key;
          });
          values.subLocation = { id: values.subLocationId };

          // console.log("MR: ", values);
          //console.log("MR: ", JSON.stringify(values));s

          materialRequestService
            .update(materialRequest.id, values)
            .then(() => {
              setUpdating(false);
              message.success("Successfully updated!");
              window.location.reload();
            })
            .catch((error) => {
              setUpdating(false);
              setError(error);
            });
        } else {
          message.error(
            "Please complete all the request lines with valid details"
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const onCreateDelivery = () => {
    history.push(`/stock-issue/add?mrn=${id}`);
  };

  const onAcceptRequest = () => {
    setAccepting(true);
    const payload = {
      orderStage: "ACCEPTED",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setAccepting(false);
        message.success("Successfully accepted!");
        history.push("/material-requests");
      })
      .catch((error) => {
        setAccepting(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to add the changes?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
        disabled={
          materialRequest.requestLines &&
          materialRequest.requestLines.length === 0
        }
      >
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={updating}
          disabled={
            materialRequest.requestLines &&
            materialRequest.requestLines.length === 0
          }
        >
          Update
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag
                  color={`${
                    materialRequest.orderStage === "OPEN"
                      ? "#2db7f5"
                      : ["IN_PROGRESS", "ACCEPTED"].includes(
                          materialRequest.orderStage
                        )
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <TabMenu menu="edit" id={id} status={materialRequest.orderStage} />
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item name="requestNumber" label="Request No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person"
                    rules={[
                      {
                        required: true,
                        message: "Contact persion is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact persion" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact No:"
                    rules={[
                      { required: true, message: "Contact No. is required" },
                    ]}
                  >
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[
                      { required: true, message: "Request date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Request date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="sbuId" label="SBU">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={20}>
                  <Form.Item name="remark" label="Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="zoneId" label="Zone">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      //disabled={!selectedSbuId}
                      disabled
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={20}></Col>
                <Col span={4}>
                  <Form.Item
                    name="subLocationId"
                    label="Sub-Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub-Location",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      //disabled={!selectedZoneId}
                      disabled
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {materialTypes.length > 0 &&
                form.getFieldValue("requestLines") && (
                  <Form.Item name="requestLines">
                    <RequestTableForm
                      materialTypes={materialTypes}
                      lotNumbers={lotNumbers}
                      measurementTypes={measurementTypes}
                      departments={departments}
                      machines={machines}
                      suppliers={suppliers}
                      sbu={selectedSbuCode}
                    />
                  </Form.Item>
                )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmEdit;
