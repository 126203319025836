import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  InputNumber,
  Typography,
  Space,
  Popconfirm,
} from "antd";
import { CloseOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import Page from "_components/Page";
import {
  purchaseOrderService,
  companyService,
  materialTypeService,
  lotNumberService,
  measurementTypeService,
} from "_services";
import { Auth } from "_helpers";

let lineIndex = 0;

function PrymAdd({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setcustomerAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumbers, setLotNumbers] = useState([]);
  //const [packingTypes, setPackingTypes] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [lotNumberId, setLotNumberId] = useState(null);
  const [orderLines, setOrderLines] = useState([]);
  const [selectedcustomer, setSelectedcustomer] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedOrderDate, setSelectedOrderDate] = useState(Date());
  const [error, setError] = useState(null);
  //const [stockClasses, setStockClasses] = useState([]);
  //const [selectedStockClass, setSelectedStockClass] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Text } = Typography;
  const precision = 2;

  //let lineIndex = 0;
  const isLoggedInCustomer = () => {
    return Auth.hasRole(Auth.getProfile(), ["CAU"]);
  };

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        setSelectedStore(x.content[0]);
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
    });
    let companyId = Auth.getProfile().comp_id;
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        customerAddress: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setcustomers([data.content]);
      setcustomerAddresses(officeAddresses);
      setSelectedcustomer(data.content);
    });

    getMaterialTypes();
    getLotNumbers();

    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content);
    });
  }, []);

  const getStoreAddresses = (companyId) => {
    form.resetFields(["storeAddress"]);

    companyService.getById(companyId).then((x) => {
      setSelectedStore(x.content);
      setStoreAddresses(x.content.addresses.filter(address => address.addressType === 'WAREHOUSE'));
    });
  };

  const getcustomerAddresses = (companyId) => {
    companyService.getById(companyId).then((x) => {
      setcustomerAddresses(x.content.addresses.filter(address => address.addressType === 'OFFICE'));
    });
  };

  const getMaterialTypes = () => {
    materialTypeService.getSuggestion().then((x) => {
      setMaterialTypes(x.content);
    });
  };

  const getLotNumbers = () => {
    lotNumberService.getSuggestion().then((x) => {
      setLotNumbers(x.content);
    });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        //console.log("Values: ", selectedcustomer.code);
        setIsLoading(true);
        //values.store = { id: values.store };
        //values.storeAddress = { id: values.storeAddress };
        //values.customer = { id: values.customer };
        //values.customerAddress = { id: values.customerAddress };
        if (["STR-GR", "PRYM", "SILUETA"].includes(selectedcustomer.code)) {
          values.preReceiptNumber = `${selectedcustomer && selectedcustomer.code
            }-${selectedStore && selectedStore.code}-${values.preReceiptNumber}`;
        } else {
          values.preReceiptNumber = `${selectedcustomer && selectedcustomer.code
            }-${selectedStore && selectedStore.code}-${moment(
              selectedOrderDate
            ).format("MM")}-${values.preReceiptNumber}`;
        }
        values.orderDate = moment(values.orderDate).format("YYYY-MM-DD");
        values.stockPreReceiptLines = orderLines.map((item, index) => {
          const newItem = Object.assign({}, item);
          newItem.stockClass = { id: item.stockClass };
          newItem.stockType = { id: item.stockType };
          newItem.packingType = { id: item.packingType };
          newItem.measurementType = { id: item.measurementType };
          if (newItem.lotNumber) {
            newItem.lotNumber = { id: item.lotNumber };
          } else {
            delete newItem.lotNumber;
          }
          if (!newItem.netWeight) {
            delete newItem.netWeight;
          }

          delete newItem.key;

          return newItem;
        });

        //values.delete('stockType');
        delete values.stockType;
        delete values.lotNumber;
        delete values.netWeight;
        delete values.grossWeight;
        delete values.quantity;

        //values.stockType.splice(0, values.stockType.length);
        // values.lotNumber.length = 0;
        // values.netWeight = 0;
        // values.grossWeight = 0;
        // values.quantity = 0;
        //console.log("PO: ", JSON.stringify(values));
        purchaseOrderService
          .create(values)
          .then(() => {
            message.success("Successfully created!");
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  const getRowBykey = (key, newData) => {
    return (orderLines || newData).filter((item) => item.key === key)[0];
  };

  const handleAddOrderLine = () => {
    const defaultMeasurementType = measurementTypes.find(
      (item) => item.name === "Kg"
    );
    const newOrderLine = defaultMeasurementType === null ? {} : { measurementType: defaultMeasurementType.id };
    newOrderLine.key = lineIndex;
    const lines = [...orderLines, newOrderLine];
    setOrderLines(lines);
    lineIndex += 1;
    //form.setFieldsValue({ stockType: null, lotNumber: null });
  };

  const handleSaveOrderLine = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    if (
      //!target.stockClass ||
      //!target.packingType ||
      !target.stockType ||
      !target.quantity ||
      //!target.netWeight ||
      !target.measurementType
    ) {
      message.error("Please complete the packing list item information.");
      e.target.focus();
      return;
    }
    handleAddOrderLine();
  };

  const handleRemoveOrderLine = (orderLine) => {
    const lines = [...orderLines];
    if (lines.includes(orderLine)) {
      lines.splice(lines.indexOf(orderLine), 1);
      setOrderLines(lines);
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const lines = [...orderLines];
    const newOrderLines = lines.map((line) => {
      const newLine = Object.assign({}, line);
      if (newLine.key === key) {
        newLine[fieldName] = value;
        return newLine;
      }
      return newLine;
    });
    setOrderLines(newOrderLines);
  };

  /* const isEnableStockAdd = () => {
    if (selectedStockClass) {
      if (selectedStockClass.name === "FABRIC") {
        return !form.getFieldValue("stockType");
      } else {
        return (
          !form.getFieldValue("stockType") || !form.getFieldValue("lotNumber")
        );
      }
    }
    return false;
  }; */

  const orderLineColumns = [
    {
      title: (
        <>
          <span style={{ marginRight: 5 }}>Material</span>
          <Button
            icon={<ReloadOutlined />}
            onClick={(e) => {
              e.preventDefault();
              getMaterialTypes();
            }}
          />
        </>
      ),
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`stockType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "stockType", record.key)}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: (
        <>
          <span style={{ marginRight: 5 }}>Lot No</span>
          <Button
            icon={<ReloadOutlined />}
            onClick={(e) => {
              e.preventDefault();
              getLotNumbers();
            }}
          />
        </>
      ),
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "25%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`lotNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: false, message: "Required." }]}
            hasFeedback
          >
            {/* <>
                <Input value={record.lotNumber.id} hidden />
                <span>{record.lotNumber.number}</span>
              </> */}
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
            >
              {lotNumbers.length > 0 &&
                lotNumbers.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: "Box",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`quantity[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Box"
              min={record._id ? record.receivedUnits : 0}
              onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      aligh: "right",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`netWeight[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: false, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Net Measure"
              onChange={(e) => handleFieldChange(e, "netWeight", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      aligh: "right",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`grossWeight[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
          >
            <InputNumber
              placeholder="Gross Measure"
              onChange={(e) => handleFieldChange(e, "grossWeight", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`measurementType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "measurementType", record.key)
              }
            >
              {measurementTypes.length > 0 &&
                measurementTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              size="small"
              icon={<CloseOutlined />}
              onClick={() => handleRemoveOrderLine(record)}
              style={{ padding: 5, fontSize: 9 }}
            ></Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveOrderLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Packing List Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists/add">New Packing List</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            title="Are you sure to add the changes?"
            onConfirm={handleFormSumbit}
            key="add-confirm"
          >
            <Button
              key="1"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              //onClick={handleFormSumbit}
              disabled={orderLines.length == 0}
            >
              Save
            </Button>
          </Popconfirm>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["customer", "id"]}
                  label="Customer"
                  rules={[{ required: true, message: "Customer is required" }]}
                >
                  <Select
                    placeholder="Please select"
                    onChange={getcustomerAddresses}
                    disabled={isLoggedInCustomer()}
                    allowClear
                  >
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["store", "id"]}
                  label="Store"
                  rules={[{ required: true, message: "Store is required" }]}
                >
                  <Select
                    placeholder="Please select"
                    onChange={getStoreAddresses}
                    allowClear
                  >
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="orderDate"
                  label="Invoice Date"
                  rules={[
                    { required: true, message: "Invoice date is required" },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder="Invoice date"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    onChange={setSelectedOrderDate}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="preReceiptNumber"
                  label="Packing List No"
                  rules={[
                    { required: true, message: "Packing list no is required" },
                  ]}
                >
                  {/* selectedcustomer && selectedcustomer.code === "STR-GR" && (
                    <Input
                      prefix={`${selectedcustomer && selectedcustomer.code}-${
                        selectedStore && selectedStore.code
                      }-`}
                    />
                  ) */}
                  <Input
                    prefix={`${selectedcustomer && selectedcustomer.code}-${selectedStore && selectedStore.code
                      }-`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["customerAddress", "id"]}
                  label="Deliver From"
                  rules={[
                    { required: true, message: "Delivery from is required" },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    allowClear
                    disabled={isLoggedInCustomer()}
                  >
                    {customerAddresses.length > 0 &&
                      customerAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["storeAddress", "id"]}
                  label="Delivery To"
                  rules={[
                    { required: true, message: "Delivery address is required" },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}>
                {selectedcustomer && selectedcustomer.code === "ORT" && (
                  <Form.Item name="poNumber" label="PO NO:">
                    <Input allowClear placeholder="PO NO:" />
                  </Form.Item>
                )}
              </Col>
            </Row>
            {orderLines.length === 0 && (
              <Button
                style={{
                  width: "100%",
                  marginTop: 16,
                  marginBottom: 8,
                  background: "rgb(190, 200, 200)",
                }}
                type="dashed"
                onClick={handleAddOrderLine}
                icon={<PlusOutlined />}
              >
                Add Item
              </Button>
            )}
            {/* <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="stockType" label="Material Type">
                  <Select
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => setMaterialTypeId(e)}
                  >
                    <Option value={null}></Option>
                    {materialTypes.length > 0 &&
                      materialTypes.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.code}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="lotNumber" label="Lot/Batch Number">
                  <Select
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => setLotNumberId(e)}
                  >
                    <Option value={null}></Option>
                    {lotNumbers.length > 0 &&
                      lotNumbers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.number}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<span></span>}>
                  <Button
                    type="primary"
                    onClick={handleAddOrderLine}
                    disabled={isEnableStockAdd()}
                  >
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row> */}

            {orderLines.length > 0 && (
              <Table
                bordered
                pagination={false}
                columns={orderLineColumns}
                dataSource={orderLines}
                size="small"
                summary={(pageData) => {
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;
                  let measurementTypes = [];

                  pageData.forEach(
                    ({ quantity, netWeight, grossWeight, measurementType }) => {
                      totalQuantity += quantity;
                      totalNetWeight += netWeight;
                      totalGrossWeight += grossWeight;
                      measurementTypes.push(measurementType);
                    }
                  );
                  const uniqueMeasurementTypes = [...new Set(measurementTypes)];

                  return (
                    <>
                      {uniqueMeasurementTypes.length === 1 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalQuantity) && totalQuantity}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalNetWeight) &&
                                Number(totalNetWeight).toFixed(precision)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalGrossWeight) &&
                                Number(totalGrossWeight).toFixed(precision)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} />
                        </Table.Summary.Row>
                      )}
                    </>
                  );
                }}
              />
            )}
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymAdd;
