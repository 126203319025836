import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Popconfirm,
  Tag,
  Modal,
} from "antd";
import {
  CloseOutlined,
  FileExcelOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Page from "_components/Page";
import { extraService, purchaseOrderService } from "_services";
import Search from "./Search";
import ExportExcel from "_helpers/ExportExcel";
import "../style.css";

const SiluetaList = ({ match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingBarcode, setLoadingBarcode] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState("?orderStage=OPEN");
  const [barcodeContent, setBarcodeContent] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const componentRef = useRef();

  useEffect(() => {
    getPurchaseOrders(pageNumber, pageSize);
  }, []);

  const getPurchaseOrders = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    purchaseOrderService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getPurchaseOrders(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    purchaseOrderService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getPurchaseOrders(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handlePrintBarcode = (stockPreReceipt) => {
    setSelectedItem(stockPreReceipt);
    setLoadingBarcode(true);
    /* purchaseOrderService
      .printBarcode(stockPreReceipt.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Barcodes_${stockPreReceipt.preReceiptNumber}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBarcode(false);
      })
      .catch(() => {
        setLoadingBarcode(false);
      }); */
    extraService
      .getBarcode()
      .then((respose) => {
        setBarcodeContent(respose.content);
        setIsModalVisible(true);
        setLoadingBarcode(false);
      })
      .catch(() => {
        setLoadingBarcode(false);
      });
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    purchaseOrderService
      .exportExcel(searchParams)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Receipt_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const handleOk = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="excel"
        icon={<FileExcelOutlined />}
        onClick={handleExportExcel}
        loading={loadingExcel}
      ></Button>
    );
    /* buttons.push(
      <Link to={`${path}/add`} key="btn-add">
        <Button>Add</Button>
      </Link>
    ); */
    /* buttons.push(
      <Link to={`${path}/upload-variance`} key="btn-varience-upload">
        <Button>Upload Varience</Button>
      </Link>
    ); */
    buttons.push(
      <Link to={`${path}/upload`} key="btn-upload">
        <Button>Upload</Button>
      </Link>
    );
    /* buttons.push(<ReactToPrint
      trigger={() => <button>Print this out!</button>}
      content={() => componentRef.current}
    />) */
    return buttons;
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "preReceiptNumber",
      key: "preReceiptNumber",
      render: (text, record) => (
        <Link to={`/packing-lists/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: "Received Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Ref.Number",
      key: "refNumber",
      dataIndex: "refNumber",
    },
    {
      title: "Inv. Boxes/Rolls",
      key: "numberOfItems",
      align: "right",
      render: (text, record) =>
        record.stockPreReceiptLines
          .map((item) => item.quantity)
          .reduce((prev, next) => prev + next),
    },
    {
      title: "Inv. Net.W",
      key: "totalNetWeight",
      align: "right",
      render: (text, record) =>
        Number(
          record.stockPreReceiptLines
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    },
    {
      title: "Inv. Gross.W",
      key: "totalGrossWeight",
      align: "right",
      render: (text, record) =>
        Number(
          record.stockPreReceiptLines
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    },
    {
      title: "Status",
      dataIndex: "orderStage",
      key: "orderStage",
      render: (orderStatus) => {
        let color = "";
        if (orderStatus === "COMPLETE") {
          color = "#87d068";
        }
        if (orderStatus === "OPEN") {
          color = "#2db7f5";
        }
        if (orderStatus === "IN_PROGRESS") {
          color = "#108ee9";
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            {record.orderStage === "OPEN" && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            )}
            <Button
              icon={<FileExcelOutlined />}
              title="Export to Excel"
              type="link"
              style={{ marginRight: 10 }}
              href={`/material-requests/${record.id}/edit`}
            ></Button>
            <Button
              icon={<BarcodeOutlined />}
              title="Print Barcodes"
              type="link"
              style={{ marginRight: 10 }}
              loading={loadingBarcode && selectedItem.id === record.id}
              onClick={() => handlePrintBarcode(record)}
            ></Button>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Packing List Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
          <Modal
            title="Print Barcode"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Print"
          >
            <div ref={componentRef}>
              <img src={barcodeContent} />
            </div>
          </Modal>
        </Page>
      </PageHeader>
    </div>
  );
};

export default SiluetaList;
