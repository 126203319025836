import { fetchWrapper } from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/stock-transfer`;

export const stockTransferService = {
    search,
    getById,
};

function search(params) {
    return fetchWrapper.get(`${baseUrl}${params}`);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}
