import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Tag,
  Tooltip,
  Table,
  Divider,
  Tabs,
  Drawer,
  Space,
  InputNumber,
} from "antd";
import {
  BarcodeOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import {
  addressService,
  companyService,
  lotNumberService,
  materialDetailService,
  materialTypeService,
  measurementTypeService,
  purchaseOrderService,
  sbuService,
  subLocationService,
  supplierService,
  zoneService,
} from "_services";

function SiluetaSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [newLineForm] = Form.useForm();
  const [addEditStockItemForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingUpdateStocks, setLoadingUpdateStocks] = useState(false);
  const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);
  const [loadingDownloadCsv, setLoadingDownloadCsv] = useState(false);
  const [loadingDetailsUpdate, setLoadingDetailsUpdate] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingLineItems, setLoadingLineItems] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [loadingNewLine, setLoadingNewLine] = useState(false);
  const [loadingAddEditStockItem, setLoadingAddEditStockItem] = useState(false);
  const [loadingLineDelete, setLoadingLineDelete] = useState(false);
  const [order, setOrder] = useState({});
  //const [orderLines, setOrderLines] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setcustomerAddresses] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [selectedGrnLineKeys, setSelectedGrnLineKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [lineItemMap, setLineItemMap] = useState(new Map());
  const [customer, setCustomer] = useState(null);
  const [newLineDrawerVisible, setNewLineDrawerVisible] = useState(false);
  const [addEditStockItemDrawerVisible, setAddEditStockItemDrawerVisible] =
    useState(false);
  const [addEditMode, setAddEditMode] = useState("Add");
  const [isNewStockItems, setIsNewStockItems] = useState(false);
  const [activeLineId, setActiveLineId] = useState(null);
  const [error, setError] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const storeData = await companyService.getSuggestion(
        "?companyType=STORE"
      );
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const purchaseOrderData = await purchaseOrderService.getById(id);

      if (purchaseOrderData) {
        let purchaseOrder = purchaseOrderData.content;
        purchaseOrder.store = purchaseOrder.store.id;
        purchaseOrder.storeAddress = purchaseOrder.storeAddress.id;
        purchaseOrder.customer = purchaseOrder.customer.id;
        purchaseOrder.customerAddress = purchaseOrder.customerAddress.id;
        purchaseOrder.receivedDate = moment(purchaseOrder.receivedDate);
        purchaseOrder.subLocationId = purchaseOrder.subLocation?.id;
        purchaseOrder.zoneId = purchaseOrder.subLocation?.zone?.id;
        purchaseOrder.sbuId = purchaseOrder.subLocation?.zone?.sbu?.id;
        purchaseOrder.stockPreReceiptLines.forEach((orderItem, i) => {
          orderItem.key = orderItem.id;
        });
        const poCustomer = await companyService.getById(purchaseOrder.customer);
        const grantedStoreAddresses = poCustomer.content.addresses.filter(
          (item) => item.addressType === "WAREHOUSE"
        );
        const officeAddresses = poCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );

        setOrder(purchaseOrder);
        //console.log("Line Date:", purchaseOrder.stockPreReceiptLines);
        //getStoreAddresses(purchaseOrder.store);
        //getcustomerAddresses(purchaseOrder.customer);
        setCustomer(poCustomer.content);
        setStoreAddresses(grantedStoreAddresses);
        setcustomerAddresses(officeAddresses);
        setSelectedZoneId(purchaseOrder.zoneId);
        setSelectedSbuId(purchaseOrder.sbuId);
        onFill(purchaseOrder);
        if (activeTabKey === null) {
          handleTabChange(purchaseOrder.stockPreReceiptLines[0].key);
        }
      }

      setStores(storeData.content);
      setcustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setLoading(false);
    };

    fetchAlldatas();
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getStoreAddresses = (companyId) => {
    addressService.getAllByCompany(companyId).then((x) => {
      setStoreAddresses(x.content);
    });
  };

  const getcustomerAddresses = (companyId) => {
    addressService.getAllByCompany(companyId).then((x) => {
      setcustomerAddresses(x.content);
    });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
        if (x.content.length === 1) {
          form.setFieldsValue({ sbuId: x.content[0].id });
          setSelectedSbuId(x.content[0].id);
        }
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    purchaseOrderService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Stock-in-${order.preReceiptNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handleDownloadExcelStockDetails = () => {
    setLoadingDownloadExcel(true);
    purchaseOrderService
      .downloadExcelStockDetails(id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Barcodes_${order.preReceiptNumber}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingDownloadExcel(false);
      })
      .catch((error) => {
        setLoadingDownloadExcel(false);
        message.error(error);
      });
  };

  const handleDownloadCsvStockDetails = () => {
    setLoadingDownloadCsv(true);
    purchaseOrderService
      .downloadCsvStockDetails(id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Update_Stock_Details_${order.preReceiptNumber}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingDownloadCsv(false);
      })
      .catch((error) => {
        setLoadingDownloadCsv(false);
        message.error(error);
      });
  };

  const handleFormSumbit = () => {
    newLineForm
      .validateFields()
      .then((values) => {
        setLoadingNewLine(true);

        if (!values.id) {
          values.measurementType = {
            id: measurementTypes.find((item) => item.name === "Kg").id,
          };
          values.stockClass = {
            id: materialTypes.find((item) => item.id === values.stockType.id)
              .stockClass.id,
          };
          values.packingType = {
            id: materialTypes.find((item) => item.id === values.stockType.id)
              .packingType.id,
          };
          let payload = {
            id: id,
            preReceiptNumber: order.preReceiptNumber,
            actionType: "ADD_NEW_LINE",
            stockPreReceiptLines: [values],
          };
          // console.log("Update: ", payload);
          purchaseOrderService
            .update(id, payload)
            .then(() => {
              message.success("Successfully updated!");
              setLoadingNewLine(false);
              newLineForm.resetFields();
              onCloseNewLineDrawer();
              window.location.reload();
            })
            .catch((error) => {
              setLoadingNewLine(false);
              setError(error);
              newLineForm.resetFields();
              onCloseNewLineDrawer();
            });
        } else {
          purchaseOrderService
            .updateLine(id, activeTabKey, values)
            .then(() => {
              message.success("Successfully updated!");
              setLoadingNewLine(false);
              newLineForm.resetFields();
              onCloseNewLineDrawer();
              window.location.reload();
              //history.push(`${path}`.replace(":id", id));
            })
            .catch((error) => {
              setLoadingNewLine(false);
              setError(error);
              newLineForm.resetFields();
              onCloseNewLineDrawer();
            });
        }
      })
      .catch((errorInfo) => {});
  };

  const handleUpdateDetails = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingDetailsUpdate(true);
        const payLoad = {
          id: id,
          preReceiptNumber: order.preReceiptNumber,
          receivedDate: moment(values.receivedDate).format("YYYY-MM-DD"),
          supplier: values.supplier,
          packingStatus: values.packingStatus,
          subLocation: { id: values.subLocationId },
        };
        purchaseOrderService
          .update(id, payLoad)
          .then(() => {
            message.success("Successfully updated!");
            setLoadingDetailsUpdate(false);
            history.push(`${path}`.replace(":id", id));
          })
          .catch((error) => {
            setLoadingDetailsUpdate(false);
            setError(error);
          });
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleAddEditStockItem = () => {
    addEditStockItemForm
      .validateFields()
      .then((values) => {
        setLoadingAddEditStockItem(true);

        if (isNewStockItems) {
          let payload = {
            preReceiptLine: { id: activeLineId },
            numberOfBox: values.numberOfBox,
            packingStatus: values.packingStatus,
          };
          // console.log("Create: ", payload);
          materialDetailService
            .create(payload)
            .then(() => {
              message.success("Successfully created!");
              setLoadingAddEditStockItem(false);
              addEditStockItemForm.resetFields();
              onCloseAddEditStockItemDrawer();
              window.location.reload();
            })
            .catch((error) => {
              setLoadingAddEditStockItem(false);
              setError(error);
              addEditStockItemForm.resetFields();
              onCloseAddEditStockItemDrawer();
            });
        } else {
          // console.log("UPdate: ", values);
          materialDetailService
            .update(values.id, values)
            .then(() => {
              message.success("Successfully updated!");
              setLoadingAddEditStockItem(false);
              addEditStockItemForm.resetFields();
              onCloseAddEditStockItemDrawer();
              window.location.reload();
            })
            .catch((error) => {
              setLoadingAddEditStockItem(false);
              setError(error);
              addEditStockItemForm.resetFields();
              onCloseAddEditStockItemDrawer();
            });
        }
      })
      .catch((errorInfo) => {});
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onComplete = () => {
    setLoadingStatusUpdate(true);
    const statusUpdatePayload = {
      preReceiptNumber: order.preReceiptNumber,
      orderStage: "COMPLETE",
    };
    purchaseOrderService
      .update(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully completed!");
        setLoadingStatusUpdate(false);
        order.orderStage = "COMPLETE";
        history.push(`${path}`.replace(":id", id));
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const onCancel = () => {
    history.push("/packing-lists");
  };

  const handleStockReceipt = () => {
    history.push(`/stock-receipt/${id}`);
  };

  const handleLineEdit = (lineId) => {
    const receiptLine = order.stockPreReceiptLines.find(
      (line) => line.id === lineId
    );
    newLineForm.setFieldsValue(receiptLine);
    setAddEditMode("Edit");
    setNewLineDrawerVisible(true);
  };

  const handleLineDelete = (lineId) => {
    setLoadingLineDelete(true);
    purchaseOrderService
      .deleteLine(id, lineId)
      .then((x) => {
        setLoadingLineDelete(false);
        message.success("Successfully line deleted!");
        window.location.reload();
      })
      .catch((error) => {
        setLoadingLineDelete(false);
        setError(error);
      });
  };

  const handleStockItemEdit = (stockItemId) => {
    const stock = lineItemMap
      .get(activeTabKey)
      .find((item) => item.id === stockItemId);
    addEditStockItemForm.setFieldsValue(stock);
    setIsNewStockItems(false);
    setAddEditMode("Edit");
    setAddEditStockItemDrawerVisible(true);
  };

  const handleStockItemDelete = (stockItemId) => {
    materialDetailService
      .delete(stockItemId)
      .then((x) => {
        message.success("Successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleStockItemFormSumbit = () => {
    const payload = {
      stockItems: stockItems
        .filter((item) => item.quantity || item.netWeight || item.grossWeight)
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
        })),
    };
    setLoadingUpdateStocks(true);
    //console.log("PL: ", payload);
    materialDetailService
      .bulkUpdate(payload)
      .then((data) => {
        message.success("Successfully updated the stock item details.");
        setLoadingUpdateStocks(false);
      })
      .catch((error) => {
        setLoadingUpdateStocks(false);
        message.error(error);
      });
  };

  const showNewLineDrawer = () => {
    setAddEditMode("Add");
    setNewLineDrawerVisible(true);
  };

  const onCloseNewLineDrawer = () => {
    if (!loadingNewLine) {
      newLineForm.resetFields();
      setNewLineDrawerVisible(false);
    }
  };

  const showAddNewStockItemDrawer = (receiptLineId) => {
    const receiptLine = order.stockPreReceiptLines.find(
      (line) => line.id === receiptLineId
    );
    addEditStockItemForm.setFieldsValue(receiptLine);
    setActiveLineId(receiptLineId);
    setIsNewStockItems(true);
    setAddEditMode("Add");
    setAddEditStockItemDrawerVisible(true);
  };

  const onCloseAddEditStockItemDrawer = () => {
    if (!loadingAddEditStockItem) {
      addEditStockItemForm.resetFields();
      setAddEditStockItemDrawerVisible(false);
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="pdf"
        icon={<FilePdfOutlined />}
        onClick={handlePrintPdf}
        loading={loadingPdf}
      ></Button>
    );
    buttons.push(
      <Tooltip
        key="tp-download-details"
        placeholder="topLeft"
        title="Download Stock Details"
      >
        <Button
          key="btn-download-details"
          icon={<DownloadOutlined />}
          onClick={handleDownloadCsvStockDetails}
          loading={loadingDownloadCsv}
        ></Button>
      </Tooltip>
    );
    buttons.push(
      <Tooltip
        key="tp-upload-details"
        placeholder="topLeft"
        title="Upload Stock Details"
      >
        <Button
          key="btn-upload-details"
          icon={<UploadOutlined />}
          href={`/material-details/update-via-upload`}
          target="_blank"
        ></Button>
      </Tooltip>
    );
    buttons.push(
      <Tooltip
        key="tp-download-full-details"
        placeholder="topLeft"
        title="Download Barcode Details"
      >
        <Button
          key="barcode"
          icon={<BarcodeOutlined />}
          onClick={handleDownloadExcelStockDetails}
          loading={loadingDownloadExcel}
        ></Button>
      </Tooltip>
    );
    buttons.push(
      <Tooltip key="tp-update" placeholder="topLeft" title="Update Details">
        <Button
          key="update"
          icon={<CheckOutlined />}
          onClick={handleUpdateDetails}
          loading={loadingDetailsUpdate}
        ></Button>
      </Tooltip>
    );
    if (order.orderStage !== "COMPLETE") {
      buttons.push(
        <Popconfirm
          title="Are you sure to complete it?"
          onConfirm={onComplete}
          key="con-complete"
          //disabled={addedStocks.length === 0}
        >
          <Button
            key="btn-complete"
            htmlType="button"
            loading={loadingStatusUpdate}
          >
            Complete
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      key: "stockTypeCode",
      width: "15%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Inv. Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Inv. Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Inv. Gross.W",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Rec’d. Quantity",
      dataIndex: "receivedQuantity",
      key: "receivedQuantity",
      align: "right",
    });
    columns.push({
      title: "Rec’d. Net.W",
      dataIndex: "receivedNetWeight",
      key: "receivedNetWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Rec’d. Gross.W",
      dataIndex: "receivedGrossWeight",
      key: "receivedGrossWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Diff Net.W",
      key: "diffNetWeight",
      align: "right",
      render: (text, record) => {
        return (
          <b>
            {Number(record.receivedNetWeight - record.netWeight).toFixed(2)}
          </b>
        );
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "6%",
      render: (text, record) => {
        return (
          <span>
            {["OPEN"].includes(record.lineStage) && (
              <Button
                icon={<EditOutlined />}
                title="Edit"
                type="link"
                style={{ marginRight: 10 }}
                onClick={() => handleLineEdit(record.id)}
              ></Button>
            )}
            {["OPEN"].includes(record.lineStage) && (
              <Popconfirm
                title="Are you sure to delete this line?"
                onConfirm={() => handleLineDelete(record.id)}
              >
                <CloseOutlined
                  loading={loadingLineDelete && record.key === activeTabKey}
                />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    /* columns.push({
      title: 'Box Serial',
      dataIndex: 'serialNo',
      key: 'serialNo',
    }); */
    /* columns.push({
      title: 'Prod Date',
      dataIndex: 'productionDate',
      key: 'productionDate',
      width: '8%',
    }); */
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "8%",
    });
    columns.push({
      title: "Bin",
      key: "stockBin",
      width: "5%",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    /* columns.push({
      title: 'Pallet',
      dataIndex: 'palletNo',
      key: 'palletNo',
      align: 'right',
      width: '5%',
    }); */
    columns.push({
      title: "Box/Roll No:",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "4%",
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "5%",
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "7%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "7%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "7%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "LOADED") {
          color = "#87d068";
        }
        if (text === "READY") {
          color = "#87d068";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "6%",
      render: (text, record) => {
        return (
          <span>
            {["AVAILABLE", "REQUESTED", "READY"].includes(record.mode) && (
              <Button
                icon={<EditOutlined />}
                title="Edit"
                type="link"
                style={{ marginRight: 10 }}
                onClick={() => handleStockItemEdit(record.id)}
              ></Button>
            )}
            {["AVAILABLE", "REQUESTED", "READY"].includes(record.mode) && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleStockItemDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const grnLineRowSelection = {
    selectedRowKeys: selectedGrnLineKeys,
    onChange: (selectedGrnLineKeys) => {
      handleTabChange(selectedGrnLineKeys[0]);
    },
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedGrnLineKeys([activeKey.toString()]);
    if (!lineItemMap.get(activeKey)) {
      setLoadingLineItems(true);
      materialDetailService
        .getSuggestion(`?purchaseOrderLineId=${activeKey}`)
        .then((result) => {
          setLineItemMap(lineItemMap.set(activeKey, result.content));
          setLoadingLineItems(false);
        })
        .catch(() => {
          setLoadingLineItems(false);
        });
    }
  };

  const getTabContent = (grnLines) => {
    let tabPanes = [];
    grnLines.forEach((line, index) => {
      tabPanes.push(
        <Tabs.TabPane
          tab={`${line.stockType.code} / ${
            line.lotNumber ? line.lotNumber.number : ""
          } (${line.receivedQuantity})`}
          key={line.id}
        >
          <Spin tip="Loading..." spinning={loadingLineItems}>
            {lineItemMap.get(line.id) && (
              <Table
                bordered
                size="small"
                rowKey="id"
                columns={getStockItemColumns()}
                dataSource={lineItemMap.get(line.id)}
                pagination={false}
                summary={(pageData) => {
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;
                  let totalItems = 0;

                  pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                    totalQuantity += quantity ? quantity : 1;
                    totalItems += 1;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                  });

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={getStockItemColumns().length - 6}
                        >
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong style={{ paddingLeft: 12 }}>
                            {totalItems}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong style={{ paddingLeft: 12 }}>
                            {totalQuantity}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {Number(totalGrossWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2} />
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
            <Button
              style={{
                width: "100%",
                marginTop: 16,
                marginBottom: 8,
                background: "rgb(190, 200, 200)",
              }}
              type="dashed"
              onClick={() => showAddNewStockItemDrawer(line.id)}
              icon={<PlusOutlined />}
            >
              Add Stock Item/s
            </Button>
          </Spin>
        </Tabs.TabPane>
      );
    });

    return tabPanes;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Packing List Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {order.preReceiptNumber} ::{" "}
                <Tag
                  color={`${
                    order.orderStage === "OPEN"
                      ? "#2db7f5"
                      : order.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {order.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="customer"
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getcustomerAddresses}
                      disabled
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="store"
                    label={<Text strong>Store</Text>}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getStoreAddresses}
                      disabled
                    >
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="receivedDate"
                    label="Received Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Received Date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="preReceiptNumber"
                    label="Packing List No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="customerAddress"
                    label="Deliver From"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="storeAddress"
                    label="Delivery To"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select">
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item
                    name="packingStatus"
                    label="Packing Status"
                    rules={[
                      {
                        required: true,
                        message: "Please select Packing Status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="NEW" key="NEW">
                        NEW
                      </Option>
                      <Option value="RETURN" key="RETURN">
                        RETURN
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="sbuId" label="SBU">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="zoneId" label="Zone">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled={!selectedSbuId}
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="subLocationId"
                    label="Sub-Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub-Location",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      disabled={!selectedZoneId}
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              {order &&
                order.stockPreReceiptLines &&
                order.stockPreReceiptLines.length > 0 && (
                  <>
                    <Row gutter={24}>
                      <Table
                        bordered
                        pagination={false}
                        columns={getLineColumns()}
                        dataSource={order.stockPreReceiptLines}
                        //expandable={{ expandedRowRender, defaultExpandAllRows: true }}
                        rowSelection={{
                          type: "radio",
                          ...grnLineRowSelection,
                        }}
                        rowClassName={(record) =>
                          record.netWeight === record.receivedNetWeight
                            ? "ant-tag-green"
                            : record.receivedNetWeight > 0
                            ? "ant-tag-red"
                            : ""
                        }
                        size="small"
                        summary={(pageData) => {
                          let totalQuantity = 0;
                          let totalNetWeight = 0;
                          let totalGrossWeight = 0;
                          let totalReceivedQuantity = 0;
                          let totalReceivedNetWeight = 0;
                          let totalReceivedGrossWeight = 0;

                          pageData.forEach(
                            ({
                              quantity,
                              netWeight,
                              grossWeight,
                              receivedQuantity,
                              receivedNetWeight,
                              receivedGrossWeight,
                            }) => {
                              totalQuantity += quantity;
                              totalNetWeight += netWeight;
                              totalGrossWeight += grossWeight;
                              totalReceivedQuantity += receivedQuantity;
                              totalReceivedNetWeight += receivedNetWeight;
                              totalReceivedGrossWeight += receivedGrossWeight;
                            }
                          );

                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={4}>
                                  <Text strong>Total</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>{totalQuantity}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>
                                    {!isNaN(totalNetWeight) &&
                                      Number(totalNetWeight).toFixed(2)}
                                  </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>
                                    {!isNaN(totalGrossWeight) &&
                                      Number(totalGrossWeight).toFixed(2)}
                                  </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>
                                    {!isNaN(totalReceivedQuantity) &&
                                      totalReceivedQuantity}
                                  </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>
                                    {!isNaN(totalReceivedNetWeight) &&
                                      Number(totalReceivedNetWeight).toFixed(2)}
                                  </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>
                                    {!isNaN(totalReceivedGrossWeight) &&
                                      Number(totalReceivedGrossWeight).toFixed(
                                        2
                                      )}
                                  </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  <Text strong>
                                    {!isNaN(totalReceivedNetWeight) &&
                                      Number(
                                        totalReceivedNetWeight - totalNetWeight
                                      ).toFixed(2)}
                                  </Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell />
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </Row>
                    <Button
                      style={{
                        width: "100%",
                        marginTop: 16,
                        marginBottom: 8,
                        background: "rgb(190, 200, 200)",
                      }}
                      type="dashed"
                      onClick={showNewLineDrawer}
                      icon={<PlusOutlined />}
                    >
                      Add New Line
                    </Button>
                    <Divider orientation="left">Stock Items</Divider>
                    <Tabs
                      onChange={handleTabChange}
                      activeKey={activeTabKey}
                      type="card"
                    >
                      {getTabContent(order.stockPreReceiptLines)}
                    </Tabs>
                  </>
                )}
            </Form>
          </Spin>
          <Drawer
            title={`${addEditMode} Receipt Line`}
            width={360}
            onClose={onCloseNewLineDrawer}
            visible={newLineDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              name="new-line"
              layout="vertical"
              hideRequiredMark
              form={newLineForm}
            >
              <Form.Item hidden name="id">
                <Input type="hidden" />
              </Form.Item>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={["stockType", "id"]}
                    label="Material"
                    rules={[
                      { required: true, message: "Please select material" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {materialTypes.length > 0 &&
                        materialTypes.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={["lotNumber", "id"]}
                    label="Lot No"
                    rules={[
                      { required: true, message: "Please select lot no" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {lotNumbers.length > 0 &&
                        lotNumbers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.number}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="quantity"
                    label="Box"
                    rules={[
                      { required: true, message: "Please enter total box" },
                    ]}
                  >
                    <InputNumber placeholder="Box" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="netWeight"
                    label="Net Weight"
                    rules={[
                      { required: true, message: "Please enter net weight" },
                    ]}
                  >
                    <InputNumber placeholder="Net Weight" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="grossWeight"
                    label="Gross Weight"
                    rules={[
                      { required: true, message: "Please enter gross weight" },
                    ]}
                  >
                    <InputNumber placeholder="Gross Weight" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button onClick={onCloseNewLineDrawer} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button
                onClick={handleFormSumbit}
                type="primary"
                loading={loadingNewLine}
              >
                Submit
              </Button>
            </div>
          </Drawer>
          <Drawer
            title={`${addEditMode} Stock Item`}
            width={360}
            onClose={onCloseAddEditStockItemDrawer}
            visible={addEditStockItemDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              name="addEdit-stock"
              layout="vertical"
              hideRequiredMark
              form={addEditStockItemForm}
            >
              <Form.Item hidden name="id">
                <Input type="hidden" />
              </Form.Item>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={["stockType", "id"]}
                    label="Material"
                    rules={[
                      { required: true, message: "Please select material" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      disabled
                    >
                      {materialTypes.length > 0 &&
                        materialTypes.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={["lotNumber", "id"]}
                    label="Lot No"
                    rules={[
                      { required: true, message: "Please select lot no" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      disabled
                    >
                      {lotNumbers.length > 0 &&
                        lotNumbers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.number}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  {isNewStockItems && (
                    <Form.Item name="quantity" label="Invoiced Box">
                      <InputNumber placeholder="Box" disabled />
                    </Form.Item>
                  )}
                  {!isNewStockItems && (
                    <Form.Item name="productionDate" label="Production Date">
                      <Input placeholder="Production Date" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  {isNewStockItems && (
                    <Form.Item name="receivedQuantity" label="Received Box">
                      <InputNumber placeholder="Box" disabled />
                    </Form.Item>
                  )}
                  {!isNewStockItems && (
                    <Form.Item
                      name="quantity"
                      label="Cone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid code quantity",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Cone" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              {!isNewStockItems && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="netWeight"
                      label="Net Weight"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid Net Weight",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Net Weight" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {!isNewStockItems && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="grossWeight"
                      label="Gross Weight"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid Gross Weight",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Gross Weight" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {!isNewStockItems && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="tempNumber"
                      label="Box Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid box number",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Box Number" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="packingStatus"
                    label="Packing Status"
                    rules={[
                      {
                        required: true,
                        message: "Please select Packing Status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="NEW" key="NEW">
                        NEW
                      </Option>
                      <Option value="RETURN" key="RETURN">
                        RETURN
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {isNewStockItems && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="numberOfBox"
                      label="Number of New Box"
                      rules={[
                        { required: true, message: "Please enter # of box" },
                      ]}
                    >
                      <InputNumber placeholder="Number of Box" min={1} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button
                onClick={onCloseAddEditStockItemDrawer}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddEditStockItem}
                type="primary"
                loading={loadingAddEditStockItem}
              >
                Submit
              </Button>
            </div>
          </Drawer>
        </Page>
      </PageHeader>
    </div>
  );
}

export default SiluetaSummary;
