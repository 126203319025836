import { fetchWrapper } from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/reports`;

export const reportService = {
  getAvailableStocks,
  exportExcel,
  getBinWiseBalance,
  getSupplierWiseBalance,
  getStockInOutSummary,
  exportSupplierWisePdf,
  exportSupplierWiseSummaryPdf,
  exportBinWisePdf,
  exportBinWiseSummaryPdf,
  exportStockInOutSummaryExcel,
  exportBarcodeExcel,
  getRequestDeliveryLines,
  exportRequestDeliveryLinesExcel,
  searchComparisons,
  getComparisonById,
  exportComparisonExcel,
};

function getAvailableStocks(params) {
  return fetchWrapper.get(`${baseUrl}/availableStocks${params}`);
}

function exportExcel(params) {
  return fetchWrapper.get(`${baseUrl}/availableStocks/export-excel${params}`, {
    responseType: 'blob',
  });
}

function exportBarcodeExcel(params) {
  return fetchWrapper.get(`${baseUrl}/availableStocks/export-barcode-excel${params}`, {
    responseType: 'blob',
  });
}

function getBinWiseBalance(params) {
  return fetchWrapper.get(`${baseUrl}/bin${params}`);
}

function getSupplierWiseBalance(params) {
  return fetchWrapper.get(`${baseUrl}/supplier${params}`);
}

function getStockInOutSummary(params) {
  return fetchWrapper.get(`${baseUrl}/stock-inout-summary${params}`);
}

function exportSupplierWisePdf(params) {
  return fetchWrapper.get(`${baseUrl}/supplier/pdf${params}`, {
    responseType: 'blob',
  });
}

function exportSupplierWiseSummaryPdf(params) {
  return fetchWrapper.get(`${baseUrl}/supplier/pdf/summary${params}`, {
    responseType: 'blob',
  });
}

function exportBinWisePdf(params) {
  return fetchWrapper.get(`${baseUrl}/bin/pdf${params}`, {
    responseType: 'blob',
  });
}

function exportBinWiseSummaryPdf(params) {
  return fetchWrapper.get(`${baseUrl}/bin/pdf/summary${params}`, {
    responseType: 'blob',
  });
}

function exportStockInOutSummaryExcel(params) {
  return fetchWrapper.get(`${baseUrl}/stock-inout-summary/excel${params}`, {
    responseType: 'blob',
  });
}

function getRequestDeliveryLines(params) {
  return fetchWrapper.get(`${baseUrl}/request-delivery-line${params}`);
}

function exportRequestDeliveryLinesExcel(params) {
  return fetchWrapper.get(`${baseUrl}/request-delivery-line/excel${params}`, {
    responseType: 'blob',
  });
}

function searchComparisons(params) {
  return fetchWrapper.get(`${baseUrl}/comparisons${params}`);
}

function getComparisonById(comparisonId) {
  return fetchWrapper.get(`${baseUrl}/comparisons/${comparisonId}/summary`);
}

function exportComparisonExcel(comparisonId) {
  return fetchWrapper.get(`${baseUrl}/comparisons/${comparisonId}/summary/excel`, {
    responseType: 'blob',
  });
}