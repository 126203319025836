import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { companyService, materialRequestService } from "_services";
import {
  CloseOutlined,
  FilePdfOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Auth, ReasonForCancel, Utils } from "_helpers";

function PrymSummaryWithItems({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingStartVerification, setLoadingStartVerification] =
    useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [materialRequest, setMaterialRequest] = useState({});
  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [selectedMrLineKeys, setSelectedMrLineKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [error, setError] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setLoading(true);
    materialRequestService
      .getById(id)
      .then((x) => {
        console.log("MR:", x.content);
        let materialRequest = x.content;
        //console.log("Items: ", materialRequest.requestLines.flatMap(item => item.requestedItems))
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = item.id;
          item.requestedQuantity = item.requestedItems.length;
          if (item.requestedItems && item.requestedItems.length > 0) {
            item.netWeight = item.requestedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next);
            item.grossWeight = item.requestedItems
              .map((item) => item.grossWeight)
              .reduce((prev, next) => prev + next);

            item.requestedItems.forEach((stock) => {
              stock.stockType = item.stockType;
              stock.lotNumber = item.lotNumber;
            });
          }
        });

        //console.log("MR: ", materialRequest);
        setMaterialRequest(materialRequest);
        setMaterialRequestLines(materialRequest.requestLines);
        getCustomerAddresses(materialRequest.customer.id);
        onFill(materialRequest);
        if (activeTabKey === null) {
          handleTabChange(materialRequest.requestLines[0].key);
        }
        setLoading(false);
        //console.log("FormVal: ", form.getFieldValue("purchaseOrderLines"));
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });

    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
  }, []);

  const getCustomerAddresses = (companyId) => {
    companyService.getById(companyId).then((data) => {
      const officeAddress = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      setCustomerAddresses(officeAddress);
    });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        /* values.requestLines.forEach((item) => {
          if (!item.requestedQuantity) {
            message.error("Please fill in complete order line.");
            setLoading(false);
            return;
          }
        }); */
        /* values.requestDate = moment(values.requestDate).format(
          'YYYY-MM-DD HH:mm:ss'
        ); */
        const payload = { remark: values.remark };
        //console.log("Update: ", values);
        materialRequestService
          .update(id, payload)
          .then(() => {
            message.success("Successfully updated!");
            setLoading(false);
            window.location.reload();
          })
          .catch((error) => {
            setLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((errorInfo) => {});
  };

  const handleDelete = () => {
    if (
      !materialRequest.remark ||
      (materialRequest.remark && materialRequest.remark.trim().length === 0)
    ) {
      return message.error(
        "Please enter valid reason for deletion and save to proceed!"
      );
    } else {
      setLoadingDelete(true);
      materialRequestService
        .delete(id)
        .then((x) => {
          setLoadingDelete(false);
          message.success("Successfully deleted!");
          history.push("/material-requests");
        })
        .catch((error) => {
          setLoadingDelete(false);
          message.error(`${error}`);
        });
    }
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    materialRequestService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Request-${materialRequest.requestNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handlePrintAvailableStockPdf = () => {
    setLoadingPdf(true);
    materialRequestService
      .printAvailableStockPdf(id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Request-Availability-${materialRequest.requestNumber}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingPdf(false);
      })
      .catch((error) => {
        setLoadingPdf(false);
        setError(error);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCreateDelivery = () => {
    history.push(`/stock-issue/add?mrn=${id}`);
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const onStartVerification = () => {
    setLoadingStartVerification(true);
    const payload = {
      orderStage: "VERIFICATION_INPROGRESS",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setLoadingStartVerification(false);
        message.success("Successfully started verificaiton!");
        history.push(`/material-requests/${id}/verify`);
      })
      .catch((error) => {
        setLoadingStartVerification(false);
        message.error(`${error}`);
      });
  };

  const handleUndo = (id) => {
    setLoadingUndo(true);
    const payload = {
      orderStage: "OPEN",
    };
    materialRequestService
      .updateStatus(id, payload)
      .then(() => {
        setLoadingUndo(false);
        message.success("Successfully opened!");
        window.location.reload();
      })
      .catch((error) => {
        setLoadingUndo(false);
        setError(error);
      });
  };

  const handleStockItemDelete = (stockItemId) => {
    materialRequestService
      .delete(id, stockItemId)
      .then((x) => {
        message.success("Successfully deleted!");
        handleTabChange(activeTabKey);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleRequestComplete = () => {
    var isValid = true;
    const emptyLines = materialRequestLines.filter(
      (item) => item.requestedItems.length === 0
    );
    if (emptyLines && emptyLines.length > 0) {
      for (var i = 0; i < emptyLines.length; i++) {
        if (!emptyLines[i].reason) {
          message.error(
            "Please fill valid reason for empty line cancellation!"
          );
          isValid = false;
          return;
        }
      }
    }
    if (isValid) {
      setLoadingComplete(true);
      const payload = {
        orderStage: "COMPLETE",
      };
      if (emptyLines.length > 0) {
        payload.requestLines = emptyLines.map((item) => {
          return {
            id: item.id,
            reason: item.reason,
          };
        });
      }
      materialRequestService
        .updateStatus(materialRequest.id, payload)
        .then(() => {
          setLoadingComplete(false);
          message.success("Successfully completed!");
          window.location.reload();
        })
        .catch((error) => {
          setLoadingComplete(false);
          setError(error);
        });
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="availability-pdf"
        title="Print Detailed Pick List"
        icon={<FilePdfOutlined />}
        onClick={handlePrintAvailableStockPdf}
        loading={loadingPdf}
      ></Button>
    );
    buttons.push(
      <Button
        key="pdf"
        title="Print Request"
        icon={<FilePdfOutlined />}
        onClick={handlePrintPdf}
        loading={loadingPdf}
      ></Button>
    );
    if (
      Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
      ["VERIFIED", "VERIFICATION_INPROGRESS"].includes(
        materialRequest && materialRequest.orderStage
      )
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to undo verification?"
          onConfirm={() => handleUndo(materialRequest.id)}
          key="start-verify"
        >
          <Button
            key="btn-undo-verify"
            icon={<UndoOutlined />}
            title="Undo"
            loading={loadingUndo}
          ></Button>
        </Popconfirm>
      );
    }
    if (
      Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
      ["OPEN"].includes(materialRequest && materialRequest.orderStage)
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to start verification?"
          onConfirm={onStartVerification}
          key="start-verify"
        >
          <Button
            key="btn-start-verify"
            htmlType="submit"
            loading={loadingStartVerification}
            type="primary"
          >
            Start Verification
          </Button>
        </Popconfirm>
      );
      buttons.push(
        <Button
          key="btn-update"
          htmlType="submit"
          loading={loading}
          onClick={handleFormSumbit}
          type="primary"
        >
          Update
        </Button>
      );
      buttons.push(
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={handleDelete}
          key="delete"
        >
          <Button
            key="btn-delete"
            htmlType="submit"
            loading={loadingDelete}
            type="primary"
          >
            Delete
          </Button>
        </Popconfirm>
      );
    }
    if (
      Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
      ["IN_PROGRESS"].includes(materialRequest && materialRequest.orderStage)
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to complete?"
          onConfirm={handleRequestComplete}
          key="verify-success"
        >
          <Button
            key="btn-verify-success"
            htmlType="submit"
            loading={loadingComplete}
          >
            Complete
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const handleFieldChange = (value, fieldName, key) => {
    const items = [...materialRequestLines];
    const newItems = items.map((item) => {
      const newItem = Object.assign({}, item);
      if (newItem.key === key) {
        newItem[fieldName] = value;
        return newItem;
      }
      return newItem;
    });
    setMaterialRequestLines(newItems);
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      //width: "15%",
    });
    columns.push({
      title: "Material",
      key: "stockTypeCode",
      //width: "15%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      //width: "20%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      //width: "15%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box/Rolls",
      dataIndex: "totalItems",
      key: "totalItems",
      //width: "4%",
      align: "right",
    });
    columns.push({
      title: "Quantity",
      key: "quantity",
      //width: "6%",
      align: "right",
      render: (text, record) => {
        return !isNaN(record.quantity) && Number(record.quantity).toFixed(2);
      },
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    if (materialRequest.orderStage !== "OPEN") {
      columns.push({
        title: "Veri. Box",
        key: "verifiedBox",
        //width: "6%",
        align: "right",
        render: (text, record) => {
          return record.requestedItems.length;
        },
      });
      columns.push({
        title: "Veri. Quantity",
        key: "quantity",
        //width: "6%",
        align: "right",
        render: (text, record) => {
          return (
            record.requestedItems.length &&
            Number(
              record.requestedItems
                .map((item) => item.quantity)
                .reduce((prev, next) => prev + next)
            ).toFixed(2)
          );
        },
      });
    }
    if (materialRequest.orderStage === "IN_PROGRESS") {
      columns.push({
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        //width: "8%",
        align: "right",
        render: (text, record) => {
          if (record.requestedItems.length === 0) {
            return (
              <Form.Item
                name={`reason[${record.key}]`}
                key={record.key}
                style={{ marginBottom: "0" }}
                rules={[{ required: true, message: "Required." }]}
                hasFeedback
              >
                <Select
                  placeholder="Please select"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => handleFieldChange(e, "reason", record.key)}
                >
                  {ReasonForCancel.map((t) => (
                    <Option value={t.value} key={t.value}>
                      {t.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            );
          } else {
            return <span></span>;
          }
        },
      });
    }

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "8%",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "7%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "B/No",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "4%",
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross (Sq.m)",
      dataIndex: "grossValueSqm",
      key: "grossValueSqm",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "LOADED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "2%",
      render: (text, record) => {
        return (
          <span>
            {["REQUESTED"].includes(record.mode) && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleStockItemDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const mrLineRowSelection = {
    selectedRowKeys: selectedMrLineKeys,
    onChange: (selectedMrLineKeys) => {
      handleTabChange(selectedMrLineKeys[0]);
    },
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedMrLineKeys([activeKey.toString()]);
  };

  const getTabContent = (requestedLines) => {
    let tabPanes = [];
    requestedLines.forEach((line, index) => {
      if (line.requestedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType.code} / ${
              line.lotNumber ? line.lotNumber.number : ""
            } (${line.requestedItems && line.requestedItems.length})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.requestedItems}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossSqm = 0;

                pageData.forEach(
                  ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                    //totalQuantity += quantity ? quantity : 1;
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 7}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{line.requestedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {!isNaN(totalGrossSqm) &&
                            Number(totalGrossSqm).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag
                  color={`${
                    materialRequest.orderStage === "OPEN"
                      ? "#2db7f5"
                      : ["IN_PROGRESS", "ACCEPTED"].includes(
                          materialRequest.orderStage
                        )
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getCustomerAddresses}
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Request date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestNumber"
                    label="Request No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactPersonName" label="Contact Person">
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["deliveryLocation", "id"]}
                    label="Deliver To"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select">
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="remark"
                    label="Remark"
                    rules={[
                      { required: true, message: "Remark/Note is required!" },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact No:">
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
              </Row>
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                rowClassName={(record) =>
                  ["COMPLETE", "VERIFIED"].includes(record.lineStage)
                    ? "ant-tag-green"
                    : "ant-tag-gold"
                }
                rowSelection={{
                  type: "radio",
                  ...mrLineRowSelection,
                }}
                size="small"
                summary={(pageData) => {
                  let rollSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let spoolSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxPcsSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxNosSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxConeSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxStripSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxRibnSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let bundlesSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let defaultSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    totalVerifiedItems: 0,
                    totalVerifiedQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  const groupByPackingType = Utils.groupBy(
                    pageData,
                    (line) =>
                      line.stockType &&
                      line.stockType.packingType &&
                      line.stockType.packingType.name
                  );
                  groupByPackingType.forEach((value, key) => {
                    if (key === "ROLL") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          rollSummary.totalItems += totalItems;
                          rollSummary.totalQty += quantity;
                          rollSummary.measurementType = measurementType;
                          rollSummary.totalVerifiedItems +=
                            requestedItems.length;
                          rollSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BOX") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          boxSummary.totalItems += totalItems;
                          boxSummary.totalQty += quantity;
                          boxSummary.measurementType = measurementType;
                          boxSummary.totalVerifiedItems +=
                            requestedItems.length;
                          boxSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "SPOOL") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          spoolSummary.totalItems += totalItems;
                          spoolSummary.totalQty += quantity;
                          spoolSummary.measurementType = measurementType;
                          spoolSummary.totalVerifiedItems +=
                            requestedItems.length;
                          spoolSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BOX_PCS") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          boxPcsSummary.totalItems += totalItems;
                          boxPcsSummary.totalQty += quantity;
                          boxPcsSummary.measurementType = measurementType;
                          boxPcsSummary.totalVerifiedItems +=
                            requestedItems.length;
                          boxPcsSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BOX_NOS") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          boxNosSummary.totalItems += totalItems;
                          boxNosSummary.totalQty += quantity;
                          boxNosSummary.measurementType = measurementType;
                          boxNosSummary.totalVerifiedItems +=
                            requestedItems.length;
                          boxNosSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BOX_CONE") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          boxConeSummary.totalItems += totalItems;
                          boxConeSummary.totalQty += quantity;
                          boxConeSummary.measurementType = measurementType;
                          boxConeSummary.totalVerifiedItems +=
                            requestedItems.length;
                          boxConeSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BOX_STRP") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          boxStripSummary.totalItems += totalItems;
                          boxStripSummary.totalQty += quantity;
                          boxStripSummary.measurementType = measurementType;
                          boxStripSummary.totalVerifiedItems +=
                            requestedItems.length;
                          boxStripSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BOX_RIBN") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          boxRibnSummary.totalItems += totalItems;
                          boxRibnSummary.totalQty += quantity;
                          boxRibnSummary.measurementType = measurementType;
                          boxRibnSummary.totalVerifiedItems +=
                            requestedItems.length;
                          boxRibnSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else if (key === "BUNDLES") {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          bundlesSummary.totalItems += totalItems;
                          bundlesSummary.totalQty += quantity;
                          bundlesSummary.measurementType = measurementType;
                          bundlesSummary.totalVerifiedItems +=
                            requestedItems.length;
                          bundlesSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    } else {
                      value.forEach(
                        ({
                          totalItems,
                          quantity,
                          measurementType,
                          requestedItems,
                        }) => {
                          defaultSummary.totalItems += totalItems;
                          defaultSummary.totalQty += quantity;
                          defaultSummary.measurementType = measurementType;
                          defaultSummary.totalVerifiedItems +=
                            requestedItems.length;
                          defaultSummary.totalVerifiedQty +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );
                    }
                  });

                  /* let totalBox = 0;
                  let totalQuantity = 0;
                  let totalVerifiedBox = 0;
                  let totalVerifiedQuantity = 0;

                  pageData.forEach(
                    ({ totalItems, quantity, requestedItems }) => {
                      totalBox += totalItems;
                      totalQuantity += quantity;
                      totalVerifiedBox += requestedItems.length;
                      totalVerifiedQuantity +=
                        requestedItems.length > 0 &&
                        requestedItems
                          .map((item) => item.quantity)
                          .reduce((prev, next) => prev + next);
                    }
                  ); */

                  return (
                    <>
                      {rollSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - ROLL</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(rollSummary.totalItems) &&
                                rollSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(rollSummary.totalQty) &&
                                rollSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {rollSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {rollSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {rollSummary.totalVerifiedQty &&
                                    Number(
                                      rollSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {boxSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BOX</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxSummary.totalItems) &&
                                boxSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxSummary.totalQty) &&
                                boxSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {boxSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxSummary.totalVerifiedQty &&
                                    Number(boxSummary.totalVerifiedQty).toFixed(
                                      2
                                    )}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {spoolSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - SPOOL</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(spoolSummary.totalItems) &&
                                spoolSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(spoolSummary.totalQty) &&
                                spoolSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {spoolSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {spoolSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {spoolSummary.totalVerifiedQty &&
                                    Number(
                                      spoolSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {boxPcsSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BOX(PCS)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxPcsSummary.totalItems) &&
                                boxPcsSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxPcsSummary.totalQty) &&
                                boxPcsSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {boxPcsSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxPcsSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxPcsSummary.totalVerifiedQty &&
                                    Number(
                                      boxPcsSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {boxNosSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BOX(NOS)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxNosSummary.totalItems) &&
                                boxNosSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxNosSummary.totalQty) &&
                                boxNosSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {boxNosSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxNosSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxNosSummary.totalVerifiedQty &&
                                    Number(
                                      boxNosSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {boxConeSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BOX(CONE)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxConeSummary.totalItems) &&
                                boxConeSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxConeSummary.totalQty) &&
                                boxConeSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {boxConeSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxConeSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxConeSummary.totalVerifiedQty &&
                                    Number(
                                      boxConeSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {boxStripSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BOX(STRP)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxStripSummary.totalItems) &&
                                boxStripSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxStripSummary.totalQty) &&
                                boxStripSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {boxStripSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxStripSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxStripSummary.totalVerifiedQty &&
                                    Number(
                                      boxStripSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {boxRibnSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BOX(RIBN)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxRibnSummary.totalItems) &&
                                boxRibnSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxRibnSummary.totalQty) &&
                                boxRibnSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {boxRibnSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxRibnSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {boxRibnSummary.totalVerifiedQty &&
                                    Number(
                                      boxRibnSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {bundlesSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total - BUNDLES</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(bundlesSummary.totalItems) &&
                                bundlesSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(bundlesSummary.totalQty) &&
                                bundlesSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {bundlesSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {bundlesSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {bundlesSummary.totalVerifiedQty &&
                                    Number(
                                      bundlesSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                      {defaultSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(defaultSummary.totalItems) &&
                                defaultSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(defaultSummary.totalQty) &&
                                defaultSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {defaultSummary.measurementType.name}
                            </Text>
                          </Table.Summary.Cell>
                          {materialRequest.orderStage !== "OPEN" && (
                            <>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {defaultSummary.totalVerifiedItems}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {defaultSummary.totalVerifiedQty &&
                                    Number(
                                      defaultSummary.totalVerifiedQty
                                    ).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                            </>
                          )}
                          {materialRequest.orderStage === "IN_PROGRESS" && (
                            <Table.Summary.Cell />
                          )}
                        </Table.Summary.Row>
                      )}
                    </>
                  );
                }}
              />
              {materialRequestLines.length > 0 &&
                materialRequestLines.flatMap((item) => item.requestedItems)
                  .length > 0 && (
                  <>
                    <Divider orientation="left">Verified Stock Items</Divider>
                    <Tabs
                      onChange={handleTabChange}
                      activeKey={activeTabKey}
                      type="card"
                    >
                      {getTabContent(materialRequestLines)}
                    </Tabs>
                  </>
                )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymSummaryWithItems;
