import {
  Button,
  Col,
  Form,
  Row,
  Input,
  message,
  DatePicker,
  Select,
} from "antd";
import React from "react";
import moment from "moment";
import { MaterialRequestStatus } from "_helpers";
import { materialRequestService } from "_services";

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.requestNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `requestNumber=${encodeURIComponent(
        values.requestNumber
      )}`;
    }
    if (values.customerId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `customerId=${encodeURIComponent(values.customerId)}`;
    }
    if (values.deliveryLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `deliveryLocationId=${encodeURIComponent(
        values.deliveryLocationId
      )}`;
    }
    if (values.orderStage) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `orderStage=${encodeURIComponent(values.orderStage)}`;
    }
    if (values.requestDateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.requestDateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.requestDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
    }
    materialRequestService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="requestNumber" label="Request Number">
            <Input.Search placeholder="Request Number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="requestDateRange" label="Request Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="orderStage" label="Status">
            <Select allowClear>
              {MaterialRequestStatus.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
