import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Popconfirm,
} from "antd";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { userService } from "_services";
import TabMenu from "../TabMenu";

const ChangePassword = ({ history }) => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setUpdating(true);
        values.mode = 0;
        values.userName = Auth.getProfile().user_name;
        userService
          .changePassword(values)
          .then(() => {
            message.success("Successfully updated!");
            setUpdating(false);
            Auth.clearSession();
            history.push("/");
          })
          .catch((error) => {
            setUpdating(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/dashboard");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to update the changes?"
        onConfirm={handleFormSumbit}
        key="confirm"
      >
        <Button key="1" type="primary" htmlType="submit" loading={updating}>
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Change Password</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="change-password" />
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  rules={[
                    { required: true, message: "Old Password is required" },
                  ]}
                >
                  <Input placeholder="Old Password" type="password" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(
                        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                      ),
                      message:
                        "New Password is required with minimum eight characters, at least one letter, one number and one special character",
                    },
                  ]}
                >
                  <Input placeholder="New Password" type="password" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="newPasswordConfirm"
                  label="Confirm New Password"
                  dependencies={["newPassword"]}
                  rules={[
                    {
                      required: true,
                      message: "Confirm New Password is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Confirm New Password" type="password" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
};

export { ChangePassword };
