import { fetchWrapper } from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/locations`;

export const binLocationService = {
  getAll,
  getSuggestion,
  search,
  searchAging,
  getById,
  create,
  update,
  delete: _delete,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function getSuggestion(params) {
  return fetchWrapper.get(`${baseUrl}/suggestion${params}`);
}

function searchAging(params) {
  return fetchWrapper.get(`${baseUrl}/aging${params}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
