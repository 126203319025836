import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}`;

export const extraService = {
  getPlants,
  uploadPackingList,
  uploadSummaryPackingList,
  uploadMaterialRequest,
  uploadStockIssue,
  uploadStockCount,
  uploadStockDetailsUpdate,
  uploadStockComparison,
  uploadStockAdjustment,
  testEmail,
  getBarcode,
  getQualityTypes,
  uploadImages,
  getUploadedImages,
  searchUploadedImages,
  updateUploadedImage,
  downloadImageDetails,
  deleteUploadedImages,
  getFailedUploadedImages,
  updateFailedUploadedImage,
  deleteFailedUploadedImage,
  deleteAllFailedUploadedImages,
  uploadBulkTransfer,
};

function getPlants() {
  return fetchWrapper.get(`${baseUrl}/plants/suggestion`);
}

function uploadPackingList(params) {
  return fetchWrapper.post(`${baseUrl}/packinList/upload`, params);
}

function uploadSummaryPackingList(params) {
  return fetchWrapper.post(`${baseUrl}/stockReceipt/uploadSummary`, params);
}

function uploadMaterialRequest(params) {
  return fetchWrapper.post(`${baseUrl}/stockIssueRequest/upload`, params);
}

function uploadStockIssue(params) {
  return fetchWrapper.post(`${baseUrl}/stockIssue/upload`, params);
}

function uploadStockCount(params) {
  return fetchWrapper.post(`${baseUrl}/stockCount/upload`, params);
}

function uploadStockDetailsUpdate(params) {
  return fetchWrapper.post(`${baseUrl}/stockUpdate/upload`, params);
}

function uploadStockComparison(params) {
  return fetchWrapper.post(`${baseUrl}/stockComparison/upload`, params);
}

function getBarcode() {
  return fetchWrapper.get(`${baseUrl}/barcodes`);
}

function testEmail(params) {
  return fetchWrapper.post(`${baseUrl}/testEmail`, {}, params);
}

function getQualityTypes() {
  return fetchWrapper.get(`${baseUrl}/quality-type/suggestion`);
}

function uploadStockAdjustment(params) {
  return fetchWrapper.post(`${baseUrl}/stockAdjustment/upload`, params);
}

function uploadImages(params) {
  return fetchWrapper.post(`${baseUrl}/analyze/multiple`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function getUploadedImages() {
  return fetchWrapper.get(`${baseUrl}/labels`);
}

function getFailedUploadedImages() {
  return fetchWrapper.get(`${baseUrl}/issueLabels`);
}

function updateUploadedImage(id, params) {
  return fetchWrapper.put(`${baseUrl}/labels/${id}`, params);
}

function updateFailedUploadedImage(id, params) {
  return fetchWrapper.put(`${baseUrl}/issueLabels/${id}`, params);
}

function searchUploadedImages(searchParams) {
  return fetchWrapper.get(`${baseUrl}/labels${searchParams}`);
}

function downloadImageDetails() {
  return fetchWrapper.get(`${baseUrl}/labels/export-csv`, {
    responseType: "blob",
  });
}

function deleteUploadedImages() {
  return fetchWrapper.delete(`${baseUrl}/labels`);
}

function deleteFailedUploadedImage(id) {
  return fetchWrapper.delete(`${baseUrl}/issueLabels/${id}`);
}

function deleteAllFailedUploadedImages() {
  return fetchWrapper.delete(`${baseUrl}/issueLabels`);
}

function uploadBulkTransfer(params) {
  return fetchWrapper.post(`${baseUrl}/bulk-transfer/upload`, params);
}

/* , {
  headers: {
    "Content-Type": "multipart/form-data",
  },
} */
