import {
  Button,
  Col,
  Form,
  message,
  Row,
  Select,
  Input,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Auth, StockStatuses } from "_helpers";
import {
  lotNumberService,
  companyService,
  materialDetailService,
  materialTypeService,
  binService,
  extraService,
} from "_services";

function MaterailDetailSearch(props) {
  const [form] = Form.useForm();
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stores, setStores] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [plants, setPlants] = useState([]);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  useEffect(() => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
    companyService
      .getSuggestion("?companyType=STORE")
      .then((x) => {
        if (x.content.length === 1) {
          let companyId = Auth.getProfile().comp_id
            ? Auth.getProfile().comp_id
            : x.content[0].id;
          /* binService.search(`?storeId=${companyId}`).then((y) => {
            form.setFieldsValue({
              storeId: companyId,
            });
            setStoreBins(y.content);
          }); */
          companyService.getById(companyId).then((data) => {
            const storeLocationAddresses = data.content.addresses.filter(
              (item) => item.addressType === "WAREHOUSE"
            );
            setAddresses(storeLocationAddresses);
            /* form.setFieldsValue({
              storeId: x.content[0].id,
              storeLocationId: storeLocationAddresses[0].id,
            }); */
            if (storeLocationAddresses.length === 1) {
              form.setFieldsValue({
                storeLocationId: storeLocationAddresses[0].id,
              });
            }
          });
          binService.getSuggestion("").then((y) => {
            /* form.setFieldsValue({
              storeId: companyId,
            }); */
            setStoreBins(y.content);
          });
        }
        setStores(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });

    getPlants();
  }, []);

  const getPlants = () => {
    extraService
      .getPlants()
      .then((x) => {
        setPlants(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.packSerialNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `packSerialNo=${encodeURIComponent(values.packSerialNo)}`;
    }
    if (values.serialNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `serialNo=${encodeURIComponent(values.serialNo)}`;
    }
    if (values.stockTypeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockTypeId=${encodeURIComponent(values.stockTypeId)}`;
    }
    if (values.storeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeId=${encodeURIComponent(values.storeId)}`;
    }
    if (values.storeLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeLocationId=${encodeURIComponent(
        values.storeLocationId
      )}`;
    }
    if (values.purchaseOrderNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `purchaseOrderNumber=${encodeURIComponent(
        values.purchaseOrderNumber
      )}`;
    }
    if (values.mode) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `mode=${encodeURIComponent(values.mode)}`;
    }
    if (values.lotNumberId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `lotNumberId=${encodeURIComponent(values.lotNumberId)}`;
    }
    if (values.stockBinId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockBinId=${encodeURIComponent(values.stockBinId)}`;
    }
    if (values.dateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.dateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.dateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
    }
    if (values.runningNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `runningNo=${encodeURIComponent(values.runningNo)}`;
    }
    if (values.plantId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `plantId=${encodeURIComponent(values.plantId)}`;
    }
    if (values.palletNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `palletNo=${encodeURIComponent(values.palletNo)}`;
    }
    materialDetailService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, data.pagination, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  const onClear = () => {
    props.onSearchResults([], { totalRecords: 0, totalGross: [] }, "");
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="purchaseOrderNumber" label="Invoice No">
            <Input placeholder="Invoice No" allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="storeLocationId" label="Store Location">
            <Select placeholder="Please select" allowClear>
              {addresses.length > 0 &&
                addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="dateRange" label="Invoice Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
          {/* <Form.Item name="storeId" label="Store">
            <Select showSearch allowClear showArrow={false} placeholder="Store">
              {stores.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
        </Col>
        <Col span={5}>
          <Form.Item name="mode" label="Status">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Please select"
            >
              {StockStatuses.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item name="packSerialNo" label="Pack Serial No">
            <Input placeholder="Pack Serial" />
          </Form.Item> */}
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                //onFinish({});
                onClear();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="stockTypeId" label="Material Type">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Type"
            >
              {stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="stockBinId" label="Stock Bin">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Bin"
            >
              {storeBins.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          {props.loggedInCustomerCode &&
            ["SILUETA"].includes(props.loggedInCustomerCode) && (
              <Form.Item name="plantId" label="Plant">
                <Select
                  showSearch
                  allowClear
                  showArrow={false}
                  optionFilterProp="children"
                  placeholder="Plant"
                >
                  {plants.map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
        </Col>
        <Col span={5}></Col>
        <Col span={4}></Col>
      </Row>

      <Row gutter={24}>
        <Col span={5}>
          <Form.Item
            name="lotNumberId"
            label={`${
              ["SILUETA"].includes(loggedInCustomerCode)
                ? "Vendor Batch"
                : "Lot/Batch Number"
            }`}
          >
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder={`${
                ["SILUETA"].includes(loggedInCustomerCode)
                  ? "Vendor Batch"
                  : "Lot/Batch Number"
              }`}
            >
              {lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          {props.loggedInCustomerCode &&
            ["PRYM", "SILUETA"].includes(props.loggedInCustomerCode) && (
              <Form.Item name="runningNo" label="Roll No">
                <Input placeholder="Roll No" allowClear={true} />
              </Form.Item>
            )}
            {props.loggedInCustomerCode &&
            ["STR_GR"].includes(props.loggedInCustomerCode) && (
              <Form.Item name="serialNo" label="Box Serial">
                <Input placeholder="Box Serial" allowClear={true} />
              </Form.Item>
            )}
        </Col>
        <Col span={5}>
          {props.loggedInCustomerCode &&
            ["SILUETA"].includes(props.loggedInCustomerCode) && (
              <Form.Item name="palletNo" label="Pallet No">
                <Input placeholder="Pallet No" allowClear={true} />
              </Form.Item>
            )}
        </Col>
        <Col span={5}></Col>
        <Col span={4}></Col>
      </Row>
    </Form>
  );
}

export default MaterailDetailSearch;
