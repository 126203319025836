import {
  Breadcrumb,
  PageHeader,
  Table,
  Tag,
  Steps,
  Spin,
  Statistic,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { materialRequestService } from "_services";
import LineSearch from "./LineSearch";

const PrymPendingLineList = ({ match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState(
    "?lineStages=OPEN,VERIFICATION_INPROGRESS,VERIFIED,VERIFICATION_FAILED,IN_PROGRESS"
  );
  const [time, setTime] = useState(Date.now());
  const { Step } = Steps;
  const { Countdown } = Statistic;
  const { Title } = Typography;

  useEffect(() => {
    getRequestLines();

    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getRequestLines = () => {
    setLoading(true);
    materialRequestService
      .searchLine(`${searchParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, searchParams) => {
    setItems(results);
    setSearchParams(searchParams);
    setLoading(false);
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Time Spent(hrs)",
      key: "time",
      //width: "3%",
      render: (text, record) => {
        if (record.requestTime) {
          return (
            <Statistic
              value={moment().diff(moment(record.requestTime), "hours")}
            />
          );
        }
      },
    });
    columns.push({
      title: "Request Number",
      key: "requestNumber",
      //width: "12%",
      render: (text, record) => {
        return record.issueRequest && record.issueRequest.requestNumber;
      },
    });
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      //width: "12%",
      render: (text, record) => {
        return <Title level={5}>{record.requestTime}</Title>;
      },
    });
    columns.push({
      title: "Material",
      key: "stockTypeCode",
      //width: "10%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      //width: "15%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box",
      dataIndex: "totalItems",
      key: "totalItems",
      //width: "4%",
      align: "right",
    });
    columns.push({
      title: "Quantity",
      key: "quantity",
      //width: "6%",
      align: "right",
      render: (text, record) => {
        return !isNaN(record.quantity) && Number(record.quantity).toFixed(2);
      },
    });
    columns.push({
      title: "Created By",
      key: "createdUserId",
      //width: "6%",
      render: (text, record) => {
        return (
          <span style={{ fontWeight: "bold" }}>
            {record.issueRequest && record.issueRequest.createdBy}
          </span>
        );
      },
    });
    columns.push({
      title: "Modified By",
      key: "lastModifiedUserId",
      //width: "6%",
      render: (text, record) => {
        return (
          <span style={{ fontWeight: "bold" }}>
            {record.issueRequest && record.issueRequest.modifiedBy}
          </span>
        );
      },
    });

    return columns;
  };

  const getRowColor = (record) => {
    let rowColor = "ant-tag-green";
    let timeSpent = moment().diff(moment(record.requestTime), "hours");
    if (timeSpent >= 6) {
      rowColor = "ant-tag-red";
    }

    return rowColor;
  };

  const getCurrentStep = (lineStage) => {
    let curretStep = 0;
    if (lineStage === "OPEN") {
      curretStep = 0;
    } else if (lineStage === "VERIFICATION_INPROGRESS") {
      curretStep = 1;
    } else if (lineStage === "VERIFIED") {
      curretStep = 2;
    } else if (lineStage === "IN_PROGRESS") {
      curretStep = 3;
    } else if (lineStage === "COMPLETE") {
      curretStep = 4;
    }

    return curretStep;
  };

  const getPrecentage = (line) => {
    let percentage = 0;
    if (line.lineStage === "VERIFICATION_INPROGRESS") {
      percentage = 1;
    } else if (line.lineStage === "VERIFIED") {
      percentage = 100;
    } else if (line.lineStage === "IN_PROGRESS") {
      percentage = Math.round(
        (line.requestedItems.filter((item) => item.mode === "LOADED").length /
          line.requestedItems.length) *
          100
      );
    } else if (line.lineStage === "COMPLETE") {
      percentage = 100;
    }

    return percentage;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests/pending">
                Pending Material Request Lines
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner error={error}>
          <LineSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Spin tip="Loading..." spinning={loading}>
            {items.length > 0 && (
              <Table
                bordered
                rowKey="id"
                size="small"
                columns={getColumns()}
                rowClassName={(record) => getRowColor(record)}
                dataSource={items}
                pagination={false}
                defaultExpandAllRows={true}
                expandable={{
                  expandedRowRender: (record) => (
                    <Steps
                      size="small"
                      current={getCurrentStep(record.lineStage)}
                      percent={getPrecentage(record)}
                    >
                      <Step title="Open" />
                      <Step title="Verification" />
                      <Step title="Verified" />
                      <Step title="In Progress" />
                      <Step title="Complete" />
                    </Steps>
                  ),
                }}
              />
            )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default PrymPendingLineList;
