import { CloseOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  PageHeader,
  Popconfirm,
  Table,
  message,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { Auth } from '_helpers';
import { sbuService, zoneService } from '_services';
import Search from './Search';

const List = ({ match }) => {
  const { path } = match;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState('');

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    setLoading(true);
    zoneService
      .search(`${searchParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, searchParams) => {
    setItems(results);
    setSearchParams(searchParams);
    setLoading(false);
  };

  const handleDelete = (id) => {
    sbuService
      .delete(id)
      .then((x) => {
        message.success('Successfully deleted!');
        getItems();
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['CAU'])) {
      buttons.push(
        <Link to={`${path}/add`} key='add'>
          <Button>Add</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: 'SBU',
      dataIndex: 'sbu',
      key: 'sbu',
      render: (text, record) => text && text.name,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <Link to={`/zones/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Short Code',
      dataIndex: 'shortCode',
    },
    {
      title: 'Classification',
      dataIndex: 'stockClassification',
      key: 'stockClassification',
      render: (text, record) => text && text.name,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <Popconfirm
            title='Are you sure to delete this item?'
            onConfirm={() => handleDelete(record.id)}
            disabled={!Auth.hasRole(Auth.getProfile(), ['SAU'])}
          >
            {Auth.hasRole(Auth.getProfile(), ['SAU']) && <CloseOutlined />}
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/zones'>Zones Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={items}
            pagination={false}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
