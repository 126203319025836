import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Popconfirm,
  Tag,
  Space,
} from 'antd';
import {
  CloseOutlined,
  EditOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Page from '_components/Page';
import { stockIssueService } from '_services';
import StrRmSearch from './Search';
import { Auth } from '_helpers';

const StrRmList = ({ history, match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState(
    `${
      Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])
        ? '?orderStages=OPEN,LOADING,READY,ISSUED,CONFIRMATION'
        : '?orderStage=ISSUED'
    }`
  );

  useEffect(() => {
    //setSearchParams("?orderStage=OPEN");
    getIssuedStocks(pageNumber, pageSize);
  }, []);

  const getIssuedStocks = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf('?') === -1 ? '?' : '&';
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    stockIssueService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getIssuedStocks(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    stockIssueService
      .delete(id)
      .then((x) => {
        getIssuedStocks(pageNumber, pageSize);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    stockIssueService
      .exportExcel(searchParams)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Stock-Issues_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const handleEdit = (id) => {
    history.push(`/stock-issue/${id}/edit`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key='excel'
        icon={<FileExcelOutlined />}
        onClick={handleExportExcel}
        loading={loadingExcel}
      ></Button>
    );
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Link to={`${path}/add`} key='1'>
          <Button>Add</Button>
        </Link>
      );
      buttons.push(
        <Link to={`${path}/adjustment-upload`} key='btn-upload'>
          <Button>Adjustment</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: 'Issue No',
      dataIndex: 'issueNumber',
      key: 'issueNumber',
      render: (text, record) => {
        if (record.orderStage === 'PROCESSING') {
          return (
            <Link
              to={`/stock-issue/${record.id}/edit`}
              disabled={Auth.hasRole(Auth.getProfile(), ['CU'])}
            >
              {text}
            </Link>
          );
        } else {
          return <Link to={`/stock-issue/${record.id}/summary`}>{text}</Link>;
        }
      },
    },
    {
      title: 'Issued Date Time',
      dataIndex: 'issuedDate',
      key: 'issuedDate',
      render: (text, record) => {
        return moment(text).format('YYYY-MM-DD HH:mm');
      },
    },
    /* {
      title: "Last Modified",
      dataIndex: "lastModifiedAt",
      key: "lastModifiedAt",
    }, */
    {
      title: 'No. Lines',
      key: 'numberOfLines',
      render: (text, record) => record.issuedLines.length,
    },
    {
      title: 'No. Items',
      key: 'numberOfItems',
      render: (text, record) =>
        record.issuedLines.flatMap((item) => item.issuedItems).length,
    },
    {
      title: 'Status',
      dataIndex: 'orderStage',
      key: 'orderStage',
      render: (orderStatus) => {
        let color = '';
        if (orderStatus === 'COMPLETE') {
          color = '#87d068';
        }
        if (orderStatus === 'ISSUED') {
          color = '#87d068';
        }
        if (orderStatus === 'OPEN') {
          color = '#2db7f5';
        }
        if (orderStatus === 'LOADING') {
          color = '#108ee9';
        }
        if (orderStatus === 'READY') {
          color = '#108ee9';
        }
        if (orderStatus === 'CONFIRMATION') {
          color = '#108ee9';
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    },
    {
      title: 'Material Requests',
      dataIndex: 'requests',
      key: 'requests',
      width: '20%',
      render: (requests) => (
        <span>
          {requests.map((request, index) => (
            <Space key={index}>
              <Link to={`/material-requests/${request.id}/summary`}>
                {request.requestNumber}
              </Link>
              <span>({moment(request.requestDate).format('YYYY-MM-DD')})</span>
            </Space>
          ))}
        </span>
      ),
    },
    {
      title: 'Vehicle',
      key: 'vehicle',
      width: '5%',
      render: (text, record) => {
        return record.vehicle && record.vehicle.number;
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '8%',
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.createdBy;
      }, */
    },
    {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: '8%',
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.modifiedBy;
      }, */
    },
    /* {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <span>
            {Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU']) &&
              ['OPEN', 'PROCESSING'].includes(record.orderStage) && (
                <Button onClick={() => handleEdit(record.id)} type='link'>
                  <EditOutlined />
                </Button>
              )}
            {Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU']) &&
              ['OPEN', 'PROCESSING'].includes(record.orderStage) && (
                <Popconfirm
                  title='Are you sure to delete this item?'
                  onConfirm={() => handleDelete(record.id)}
                >
                  <CloseOutlined />
                </Popconfirm>
              )}
            {Auth.hasRole(Auth.getProfile(), ['U']) &&
              record.orderStage === 'ISSUED' && (
                <Link to={`/stock-issue/${record.id}/summary`}>Confirm</Link>
              )}
          </span>
        );
      },
    }, */
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/stock-issue'>Stock Delivery Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <StrRmSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrRmList;
