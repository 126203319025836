import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { sbuService } from '_services';

function Add({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        console.log('Val:', JSON.stringify(values));
        sbuService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push('.');
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        // message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push('.');
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/sbu'>SBU Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/sub/add'>New SBU</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key='1'
            htmlType='submit'
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key='2' htmlType='button' onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name='form-create'
            layout='vertical'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name='code'
                  label='Code'
                  rules={[{ required: true, message: 'Code is required' }]}
                >
                  <Input placeholder='Code' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='name'
                  label='Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder='Name' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='description'
                  label='Description'
                  rules={[
                    { required: true, message: 'Description is required' },
                  ]}
                >
                  <Input placeholder='Description' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='shortCode'
                  label='Short Code'
                  rules={[
                    { required: true, message: 'Short Code is required' },
                  ]}
                >
                  <Input placeholder='Short Code' />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
