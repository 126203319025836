import React from 'react';
import { Button, Col, Form, Input, Row, message } from 'antd';
import { binLocationService } from '_services';

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    if (values.name) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    binLocationService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, null, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='name' label='Location Name'>
            <Input placeholde='Location Name' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
