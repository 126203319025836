import {
  Breadcrumb,
  PageHeader,
  Table,
  Typography,
  message,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import {
  reportService,
  sbuService,
  zoneService,
  subLocationService,
} from "_services";
import ComparisonSearch from "./ComparisonSearch";
import moment from "moment";

const ComparisonList = ({ match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const precision = 2;

  useEffect(() => {
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchQuery.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    reportService
      .searchComparisons(`${searchQuery}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchQuery(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getItems(pageNumber, pageSize);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Link to={`/reports/comparison-upload`} key="btn-upload">
        <Button>Upload</Button>
      </Link>
    );

    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Compr Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => {
        return (
          <Link to={`/reports/comparisons/${record.id}`}>{text}</Link>
        );
      },
    });
    columns.push({
      title: "Compare With",
      dataIndex: "compareWith",
      key: "compareWith",
    });
    columns.push({
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      //width: "6%",
      render: (text, record) => {
        return moment(text).format("YYYY-MM-DD");
      },
    });
    columns.push({
      title: "Sub Location Code",
      dataIndex: "subLocation",
      key: "subLocation",
      render: (text, record) => {
        return text && text.shortCode;
      },
    });
    columns.push({
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      //width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });
    columns.push({
      title: "Total BNS Quantity",
      dataIndex: "netWeight",
      //key: 'netWeight',
      align: "right",
      //width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });
    columns.push({
      title: "Variance",
      dataIndex: "variance",
      key: "variance",
      align: "right",
      //width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Comparison Reports</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <>
            <ComparisonSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              subLocations={subLocations}
              zones={zones}
              businessUnits={businessUnits}
            />

            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={{
                current: pageNumber,
                pageSize: pageSize,
                total: totalRecords,
                showTotal: (total) => `Total ${total} items`,
                showSizeChanger: true,
                onChange: handlePaginateChange,
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default ComparisonList;
