import {
  Breadcrumb,
  PageHeader,
  Table,
  Tooltip,
  Typography,
  message,
  Row,
  Col,
  Statistic,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
//import { PDFDownloadLink } from "@react-pdf/renderer";
import Page from "_components/Page";
//import { PdfAvailableStocks } from "./PdfAvailableStocks";
import {
  reportService,
  companyService,
  materialTypeService,
  lotNumberService,
} from "_services";
import { Auth } from "_helpers";
import AvailableStockSearch from "../AvailableStockSearch";
import ExportExcel from "_helpers/ExportExcel";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";

/* const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
}); */

const SiluetaAvailableStocks = () => {
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const { Title } = Typography;

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setAddresses(storeLocationAddresses);
          getItems(storeLocationAddresses.map((item) => item.id).join());
          /* form.setFieldsValue({
            storeId: x.content[0].id,
            storeLocationId: storeLocationAddresses[0].id,
          }); */
        });
      }
      //setCompanies(x.content);
    });
    getStockTypes();
    getLotNumbers();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getItems = (storeLocationIds) => {
    setLoading(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    searchParams += `fromDate=${encodeURIComponent(
      moment("2021-01-01").format("YYYY-MM-DD")
    )}&toDate=${moment().format(
      "YYYY-MM-DD"
    )}&storeLocations=${storeLocationIds}`;
    setSearchQuery(searchParams);
    reportService
      .getAvailableStocks(searchParams)
      .then((data) => {
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
          });
          setItems(data.content);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (
    results,
    fromDate,
    toDate,
    isFilterEnabled,
    searchParams
  ) => {
    setItems(results);
    setFromDate(fromDate);
    setToDate(toDate);
    setDateFilter(isFilterEnabled);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  /* const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  ); */

  const handlePrintExel = () => {
    ExportExcel(
      getColumns(),
      items,
      `${"Stock Availabiltiy Report - "}${moment().format("YYYY-MM-DD")}`,
      `${moment().format("YYYY-MM-DD")}`
    );
    /* const excel = new Excel();
    excel
      .addSheet(`${moment().format("YYYY-MM-DD")}`)
      .addColumns(getColumns())
      .addDataSource(items, {
        str2Percent: true,
      })
      .saveAs(
        `${"Stock Availabiltiy Report - "}${moment().format("YYYY-MM-DD")}.xlsx`
      ); */
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    reportService
      .exportExcel(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Available-Stock-Report_${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      dataIndex: "stockTypeName",
      width: "30%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockTypeName}>
            {record.stockTypeName}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Vendor Batch",
      dataIndex: "lotNumber",
      key: "lotNumber",
    });
    columns.push({
      title: "Rolls",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "7%",
    });
    columns.push({
      title: "Net Measure(m)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Measure(m)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Available Stocks</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <AvailableStockSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              addresses={addresses}
              stockTypes={stockTypes}
              lotNumberList={lotNumberList}
            />
            {!dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: {moment().format("YYYY-MM-DD")}
              </Title>
            )}
            {dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: From:{moment(fromDate).format("YYYY-MM-DD")} - To:
                {moment(toDate).format("YYYY-MM-DD")}
              </Title>
            )}
            {items && items.length > 0 && (
              <div
                style={{
                  background: "#ECECEC",
                  padding: "15px",
                  marginBottom: 15,
                }}
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Total Stocks"
                        value={items
                          .map((item) => item.quantity)
                          .reduce((prev, next) => prev + next)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Total Stock Quantity"
                        value={Number(
                          items
                            .map((item) => item.grossWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={9}></Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={handleExportExcel}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FileExcelOutlined />}
                      loading={loadingExcel}
                    >
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossQuantity = 0;
                let totalGrossSqm = 0;

                pageData.forEach(
                  ({
                    quantity,
                    netWeight,
                    grossWeight,
                    grossQuantity,
                    grossValueSqm,
                  }) => {
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossQuantity += grossQuantity;
                    totalGrossSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={getColumns().length - 3}>
                        <Typography.Text strong>Total</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalQuantity}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalNetWeight && Number(totalNetWeight).toFixed(2)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalGrossWeight &&
                            Number(totalGrossWeight).toFixed(2)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default SiluetaAvailableStocks;
