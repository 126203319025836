import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tooltip,
  Tag,
  Tabs,
  Spin,
  Popconfirm,
  Space,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  materialDetailService,
  measurementTypeService,
} from "_services";
import Modal from "antd/lib/modal/Modal";
import StockSelection from "./StockSelection";
import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";

let lineIndex = 0;

function Add({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [stockItemLoading, setStockItemLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [lotNumberId, setLotNumberId] = useState(null);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRequestDate, setSelectedRequestDate] = useState(Date());
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [availableStocks, setAvailableStocks] = useState([]);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [requestedStocks, setRequestedStocks] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      if (x.content.length === 1) {
        let companyId = x.content[0].id;
        setSelectedCustomer(x.content[0]);
        companyService.getById(companyId).then((data) => {
          const officeAddress = data.content.addresses.filter(
            (item) => item.addressType === "OFFICE"
          );
          form.setFieldsValue({
            customer: x.content[0],
            deliveryLocation: officeAddress[0],
          });
          setAddresses(officeAddress);
        });
        /* addressService.getAllByCompany(companyId).then((y) => {
          form.setFieldsValue({
            customer: x.content[0],
            deliveryLocation: y.content[0],
          });
          setAddresses(y.content);
        }); */
      }
      setCustomers(x.content);
    });
    materialTypeService.getSuggestion("").then((x) => {
      setMaterialTypes(x.content);
    });
    lotNumberService.getSuggestion("").then((x) => {
      setLotNumbers(x.content);
    });
    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content);
    });
  }, []);

  /* const getAddresses = (companyId) => {
    addressService.getAllByCompany(companyId).then((x) => {
      setAddresses(x.content);
    });
  }; */

  const handleFieldChange = (value, fieldName, key) => {
    const lines = [...materialRequestLines];
    const newRequestLines = lines.map((line) => {
      const newLine = Object.assign({}, line);
      if (newLine.key === key) {
        newLine[fieldName] = value;
        return newLine;
      }
      return newLine;
    });
    //console.log("Lines: ", newRequestLines);
    setMaterialRequestLines(newRequestLines);
  };

  const getStocks = (stockTypeId, lotId) => {
    //let searchParams = `?stockTypeId=${materialTypeId}&lotNumberId=${lotNumberId}&status=AVAILABLE&pageNumber=1&pageSize=100`;
    let searchParams = `?stockTypeId=${stockTypeId}&mode=AVAILABLE&pageNumber=1&pageSize=100`;
    searchParams = lotId
      ? `${searchParams}&lotNumberId=${lotId}`
      : searchParams;

    setIsAvailableStocksLoading(true);
    materialDetailService
      .search(searchParams)
      .then((data) => {
        setIsAvailableStocksLoading(false);
        if (data.content.length > 0) {
          setAvailableStocks(data.content);
          setIsStockSelectionVisible(true);
          setMaterialTypeId(stockTypeId);
          setLotNumberId(lotId);
        } else {
          message.info(
            "Sorry, There's no available stocks for the given criteria!"
          );
        }
      })
      .catch((error) => {
        setIsAvailableStocksLoading(false);
        message.error(`${error}`);
      });
  };

  const handleShowStocks = () => {
    let requestLine = materialRequestLines.find(
      (item) =>
        item.stockType.id === materialTypeId &&
        item.lotNumber.id === lotNumberId
    );
    if (requestLine) {
      setSelectedStockIds(requestLine.requestedItems.map((item) => item.id));
    }
    getStocks();
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected: ", selectedStockIds);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    //console.log("SelectedST: ", materialRequestLines);
    setStockItemLoading(true);
    if (selectedStocks) {
      let requestLine = null;
      materialRequestLines.forEach((item) => {
        //console.log("Item: ", item);
        if (item.hasOwnProperty("lotNumber")) {
          if (
            item.stockType === materialTypeId &&
            item.lotNumber === lotNumberId
          ) {
            requestLine = item;
          }
        } else if (item.stockType === materialTypeId) {
          requestLine = item;
        }
      });
      const stockTypeObj = materialTypes.find(
        (item) => item.id === materialTypeId
      );
      const lotNumberObj = lotNumbers.find((item) => item.id === lotNumberId);
      if (requestLine) {
        requestLine["stockTypeObj"] = stockTypeObj;
        requestLine["lotNumberObj"] = lotNumberObj;
        requestLine["requestedItems"] = selectedStocks;
        requestLine["quantity"] = selectedStocks.length;
        requestLine["netWeight"] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
        requestLine["grossWeight"] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);
      } else {
        const newLine = {
          stockTypeObj: stockTypeObj,
          lotNumberObj: lotNumberObj,
          stockType: stockTypeObj.id,
          lotNumber: lotNumberObj && lotNumberObj.id,
          requestedItems: selectedStocks,
          quantity: selectedStocks.length,
          netWeight: selectedStocks
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next),
          grossWeight: selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next),
        };
        newLine.key = lineIndex;
        const lines = [...materialRequestLines, newLine];
        setMaterialRequestLines(lines);
        lineIndex += 1;
        setMaterialTypeId(null);
        setLotNumberId(null);
        form.setFieldsValue({ stockType: null, lotNumber: null });
        console.log("RL2: ", lines);
      }
      setRequestedStocks([...requestedStocks, ...selectedStocks]);
      //console.log("RequestedStocks:", requestedStocks);
    }
    form.setFieldsValue({ stockType: null, lotNumber: null });
    setIsStockSelectionVisible(false);
    setStockItemLoading(false);
    setSelectedStockIds([]);
    //console.log("requestLines:", materialRequestLines);
  };

  const handleSearchAndAddStocks = (line) => {
    //let materialTypes = form.getFieldValue(eval(`stockType[${line.key}]`));

    //let lotNumbers = form.getFieldsValue(["lotNumber"]);
    // let selectedMaterialType =
    //   form.getFieldValue("stockType") &&
    //   form.getFieldValue("stockType")[line.key];
    // let selectedLotNumber =
    //   form.getFieldValue("lotNumber") &&
    //   form.getFieldValue("lotNumber")[line.key];
    const selectedStockTypeId = form.getFieldValue(
      "stockType[" + line.key + "]"
    );
    const selectedLotNumberId = form.getFieldValue(
      "lotNumber[" + line.key + "]"
    );
    //console.log("MT:", form.getFieldValue("stockType[" + line.key + "]"));
    //console.log("FormValues:", form.getFieldsValue(), ",1:", selectedStockTypeId, ", 2:", selectedLotNumberId);
    //console.log("FormSTValues:", materialTypes);
    setMaterialTypeId(selectedStockTypeId);
    setLotNumberId(selectedLotNumberId);
    //console.log("MT:", materialTypeId);

    if (selectedStockTypeId) {
      let requestLine = null;
      materialRequestLines.forEach((item) => {
        if (item.hasOwnProperty("lotNumber")) {
          if (
            item.stockType === materialTypeId &&
            item.lotNumber === lotNumberId
          ) {
            requestLine = item;
          }
        } else if (item.stockType === materialTypeId) {
          requestLine = item;
        }
      });
      if (requestLine && requestLine.requestedItems) {
        setSelectedStockIds(requestLine.requestedItems.map((item) => item.id));
      }
      //console.log("STs:", materialRequestLines);
      /* let requestLine = materialRequestLines.find(
        (item) =>
          item.stockType.id === materialTypeId &&
          item.lotNumber.id === lotNumberId
      );
       */
      getStocks(selectedStockTypeId, selectedLotNumberId);
    } else {
      message.error("Please select a valid Item");
    }
  };

  const handleAddRequestLine = () => {
    //console.log("Index:", lineIndex);
    const defaultMeasurementType = measurementTypes.find(
      (item) => item.name === 'm'
    );
    
    const newLine =
      defaultMeasurementType === null
        ? { requestedItems: [] }
        : {
            requestedItems: [],
            measurementType: defaultMeasurementType.id,
          };
    newLine.key = lineIndex;
    const lines = [...materialRequestLines, newLine];
    //console.log("Lines:", lines);
    setMaterialRequestLines(lines);
    lineIndex += 1;
  };

  const handleRemoveRequestLine = (line) => {
    //console.log("Remove:", materialRequestLines);
    const lines = [...materialRequestLines];
    if (lines.includes(line)) {
      lines.splice(lines.indexOf(line), 1);
      setMaterialRequestLines(lines);
    }
  };

  const handleSaveRequestLine = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    //console.log("Target:", target);
    if (
      !target.stockType ||
      //target.hasOwnProperty("lotNumber") &&
      !target.lotNumber ||
      !target.quantity ||
      !target.measurementType
    ) {
      message.error("Please complete the requested line details.");
      e.target.focus();
      return;
    }
    handleAddRequestLine();
  };

  const getRowBykey = (key, newData) => {
    return (materialRequestLines || newData).filter(
      (item) => item.key === key
    )[0];
  };

  const validateRequestLines = (lines) => {
    let validLines = false;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (
          line.stockType &&
          line.lotNumber &&
          line.quantity &&
          line.measurementType
        ) {
          validLines = true;
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateRequestLines(materialRequestLines)) {
          setIsLoading(true);
          //delete values.stockType;
          //delete values.lotNumber;

          /* values.requestNumber = `${
          selectedCustomer && selectedCustomer.code
        }-${moment(selectedRequestDate).format("MM")}-${values.requestNumber}`; */
          /* values.requestNumber = `${
          selectedCustomer && selectedCustomer.code
        }-MRN-${values.requestNumber}`; */
          values.requestDate = moment(values.requestDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let requestLines = [];

          materialRequestLines.forEach((line) => {
            let requestLine = {};
            requestLine.stockType = { id: line.stockType };
            if (line.lotNumber) {
              requestLine.lotNumber = { id: line.lotNumber };
            }
            requestLine.measurementType = { id: line.measurementType };
            if (["SILUETA"].includes(loggedInCustomerCode)) {
              requestLine.quantity = line.quantity;
            } else {
              requestLine.quantity = line.requestedItems.length;
            }
            requestLine.requestedItems = line.requestedItems.map((item) => ({
              id: item.id,
            }));
            requestLines.push(requestLine);
          });
          values.requestLines = requestLines;

          // console.log("MR: ", values);
          // console.log("MR: ", JSON.stringify(values));

          materialRequestService
            .create(values)
            .then(() => {
              setIsLoading(false);
              history.push(".");
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error);
            });
        } else {
          message.error(
            "Please complete all the request lines with valid details"
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockTypeCode",
      width: "30%",
      render: (text, record) => {
        return (
          <Form.Item
            //name={["stockType", record.key, "id"]}
            name={`stockType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "stockType", record.key)}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      render: (text, record) => {
        return (
          <Form.Item
            //name={["lotNumber", record.key, "id"]}
            name={`lotNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
            >
              {lotNumbers.length > 0 &&
                lotNumbers.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    if (["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Quantity",
        key: "quantity",
        align: "right",
        render: (text, record) => {
          return (
            <Form.Item
              name={`quantity[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: true, message: "Required." }]}
              hasFeedback
            >
              <InputNumber
                placeholder="Quantity"
                min={record._id ? record.receivedUnits : 0}
                onChange={(e) => handleFieldChange(e, "quantity", record.key)}
              />
            </Form.Item>
          );
        },
      });
    } else {
      columns.push({
        title: "Units",
        key: "quantity",
        align: "right",
        render: (text, record) => {
          return record.requestedItems && record.requestedItems.length;
        },
      });
    }
    if (!["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Net Measure",
        dataIndex: "netWeight",
        key: "netWeight",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
      columns.push({
        title: "Gross Measure",
        dataIndex: "grossWeight",
        key: "grossWeight",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`measurementType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "measurementType", record.key)
              }
            >
              {measurementTypes.length > 0 &&
                measurementTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    if (["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "",
        key: "operation",
        render: (text, record) => (
          <Space size="small">
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveRequestLine(record);
              }}
              icon={<CloseOutlined />}
              style={{ marginLeft: 5 }}
            ></Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveRequestLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        ),
      });
    } else {
      columns.push({
        title: "",
        key: "operation",
        render: (text, record) => (
          <Space size="small">
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleSearchAndAddStocks(record);
              }}
              icon={<SearchOutlined />}
            >
              Find Stocks
            </Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveRequestLine(record);
              }}
              icon={<CloseOutlined />}
              style={{ marginLeft: 5 }}
            ></Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveRequestLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        ),
      });
    }

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "20%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (
      selectedCustomer &&
      ["PRYM", "SILUETA"].includes(selectedCustomer.code)
    ) {
      columns.push({
        title: "Roll/Box No:",
        dataIndex: "runningNo",
        key: "runningNo",
        //align: "right",
        defaultSortOrder: "descend",
        sorter: (a, b) => parseInt(a.runningNo) < parseInt(b.runningNo),
      });
    }
    /* columns.push({
      title: "Location",
      key: "storeLocation",
      render: (text, record) => {
        return record.stockBin.storeLocation.address;
      },
    }); */
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    if (selectedCustomer && ["SILUETA"].includes(selectedCustomer.code)) {
      columns.push({
        title: "Pallet No",
        dataIndex: "palletNo",
        key: "palletNo",
      });
    }
    if (selectedCustomer && ["PRYM"].includes(selectedCustomer.code)) {
      columns.push({
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      });
    }
    if (selectedCustomer && ["STR-RM"].includes(selectedCustomer.code)) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      });
    }

    columns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    if (selectedCustomer && ["PRYM"].includes(selectedCustomer.code)) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        align: "right",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "4%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    /* {
      title: "Pack Serial",
      dataIndex: "packSerialNo",
      key: "packSerialNo",
      width: "16%",
    }
    {
      title: "Description",
      key: "stockTypeName",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    } */

    return columns;
  };

  const getActionButtons = () => {
    let buttons = [];
    if (["SILUETA"].includes(loggedInCustomerCode)) {
      buttons.push(
        <Popconfirm
          title="Are you sure to add the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={materialRequestLines.length === 0}
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={materialRequestLines.length === 0}
          >
            Save
          </Button>
        </Popconfirm>
      );
    } else {
      buttons.push(
        <Popconfirm
          title="Are you sure to add the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={
            materialRequestLines.flatMap((item) => item.requestedItems)
              .length === 0
          }
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={
              materialRequestLines.flatMap((item) => item.requestedItems)
                .length === 0
            }
          >
            Save
          </Button>
        </Popconfirm>
      );
    }

    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests/add">New Material Request</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["customer", "id"]}
                  label="Customer"
                  rules={[{ required: true, message: "Customer is required" }]}
                >
                  <Select
                    placeholder="Please select"
                    //onChange={getAddresses}
                  >
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactPersonName"
                  label="Contact Person Name"
                  rules={[
                    { required: true, message: "Contact persion is required" },
                  ]}
                >
                  <Input placeholder="Contact persion" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="requestDate"
                  label="Request Date"
                  rules={[
                    { required: true, message: "Request date is required" },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder="Request date"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    onChange={setSelectedRequestDate}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                {/* <Form.Item
                  name="requestNumber"
                  label="Request No"
                  rules={[
                    { required: true, message: "Request no is required" },
                  ]}
                >
                  <Input
                    prefix={`${
                      selectedCustomer && selectedCustomer.code
                    }-${moment(selectedRequestDate).format("MM")}-`}
                  />
                  <Input
                    prefix={`${selectedCustomer && selectedCustomer.code}-MRN-`}
                  />
                </Form.Item> */}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["deliveryLocation", "id"]}
                  label="Deliver To"
                  rules={[
                    { required: true, message: "Delivery from is required" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {addresses.length > 0 &&
                      addresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactNumber"
                  label="Contact No:"
                  rules={[
                    { required: true, message: "Contact No. is required" },
                  ]}
                >
                  <Input placeholder="Contact number" />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
            {/* <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="stockType" label="Material Type">
                  <Select
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => setMaterialTypeId(e)}
                  >
                    <Option value={null}></Option>
                    {materialTypes.length > 0 &&
                      materialTypes.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.code}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="lotNumber" label="Type of Wash">
                  <Select
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => setLotNumberId(e)}
                  >
                    <Option value={null}></Option>
                    {lotNumbers.length > 0 &&
                      lotNumbers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.number}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<span></span>}>
                  <Button
                    type="primary"
                    onClick={handleShowStocks}
                    loading={availableStocksLoading}
                    disabled={
                      !form.getFieldValue("stockType") ||
                      !form.getFieldValue("lotNumber")
                    }
                  >
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row> */}

            {materialRequestLines.length > 0 && (
              <Table
                bordered
                loading={availableStocksLoading}
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                //expandable={{ expandedRowRender }}
                size="small"
                summary={(pageData) => {
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;

                  pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                  });

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        {selectedCustomer &&
                          !["SILUETA"].includes(selectedCustomer.code) && (
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalQuantity) && totalQuantity}
                              </Text>
                            </Table.Summary.Cell>
                          )}
                        {selectedCustomer &&
                          ["SILUETA"].includes(selectedCustomer.code) && (
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalQuantity) &&
                                  Number(totalQuantity).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                          )}
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalNetWeight) &&
                              Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalGrossWeight) &&
                              Number(totalGrossWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
            {materialRequestLines.length === 0 && (
              <Button
                style={{
                  width: "100%",
                  marginTop: 16,
                  marginBottom: 8,
                  color: "white",
                  backgroundColor: "#13c2c2",
                }}
                type="dashed"
                onClick={handleAddRequestLine}
                icon={<PlusOutlined />}
              >
                Add Item
              </Button>
            )}
          </Form>
          <Modal
            title={`Stocks (${availableStocks.length})`}
            style={{ top: 48 }}
            visible={isStockSelectionVisible}
            okText={`Add Stocks (${selectedStockIds.length})`}
            onOk={handleAddStocks}
            //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
            onCancel={() => setIsStockSelectionVisible(false)}
            width={1300}
          >
            <StockSelection
              stocks={availableStocks}
              selectedIds={selectedStockIds}
              customer={selectedCustomer}
              onSelectChange={handleStockSelection}
            />
          </Modal>
          {materialRequestLines.length > 0 &&
            materialRequestLines.flatMap((item) => item.requestedItems).length >
              0 && (
              <>
                <Divider orientation="left">Stock Items</Divider>
                <Spin tip="Loading..." spinning={stockItemLoading}>
                  <Tabs defaultActiveKey={materialRequestLines[0].key}>
                    {materialRequestLines.map((line, index) => (
                      <Tabs.TabPane
                        tab={`${
                          line.stockTypeObj && line.stockTypeObj.code
                        } / ${line.lotNumberObj && line.lotNumberObj.number} (${
                          line.requestedItems && line.requestedItems.length
                        })`}
                        key={index}
                      >
                        <Table
                          bordered
                          size="small"
                          rowKey="id"
                          columns={getStockItemColumns()}
                          dataSource={line.requestedItems}
                          pagination={false}
                          summary={(pageData) => {
                            let totalQuantity = 0;
                            let totalNetWeight = 0;
                            let totalGrossWeight = 0;
                            let totalGrossValueSqm = 0;

                            pageData.forEach(
                              ({
                                quantity,
                                netWeight,
                                grossWeight,
                                grossValueSqm,
                              }) => {
                                //totalQuantity += quantity ? quantity : 1;
                                totalQuantity += quantity;
                                totalNetWeight += netWeight;
                                totalGrossWeight += grossWeight;
                                totalGrossValueSqm += grossValueSqm;
                              }
                            );

                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell
                                    colSpan={
                                      selectedCustomer &&
                                      ["PRYM"].includes(selectedCustomer.code)
                                        ? getStockItemColumns().length - 6
                                        : getStockItemColumns().length - 5
                                    }
                                  >
                                    <Text strong>Total</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text strong>
                                      {line.requestedItems.length}
                                    </Text>
                                  </Table.Summary.Cell>
                                  {selectedCustomer &&
                                    ["PRYM"].includes(
                                      selectedCustomer.code
                                    ) && (
                                      <Table.Summary.Cell align="right">
                                        <Text strong>{totalQuantity}</Text>
                                      </Table.Summary.Cell>
                                    )}
                                  <Table.Summary.Cell align="right">
                                    <Text strong>
                                      {Number(totalNetWeight).toFixed(2)}
                                    </Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="right">
                                    <Text strong>
                                      {Number(totalGrossWeight).toFixed(2)}
                                    </Text>
                                  </Table.Summary.Cell>
                                  {selectedCustomer &&
                                    ["PRYM"].includes(
                                      selectedCustomer.code
                                    ) && (
                                      <Table.Summary.Cell align="right">
                                        <Text strong>
                                          {Number(totalGrossValueSqm).toFixed(
                                            2
                                          )}
                                        </Text>
                                      </Table.Summary.Cell>
                                    )}
                                  <Table.Summary.Cell colSpan={2} />
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </Spin>
              </>
            )}
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
