import {
  Button,
  Col,
  Form,
  message,
  Row,
  Select,
  Input,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Auth, StockStatuses, InspectionStatuses } from "_helpers";
import {
  lotNumberService,
  companyService,
  materialDetailService,
  materialTypeService,
  binService,
  supplierService,
  sbuService,
  zoneService,
  subLocationService,
} from "_services";

function MaterailDetailSearch(props) {
  const [form] = Form.useForm();
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stores, setStores] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);

  useEffect(() => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
    companyService
      .getSuggestion("?companyType=STORE")
      .then((x) => {
        if (x.content.length === 1) {
          let companyId = Auth.getProfile().comp_id
            ? Auth.getProfile().comp_id
            : x.content[0].id;
          companyService.getById(companyId).then((data) => {
            const storeLocationAddresses = data.content.addresses.filter(
              (item) => item.addressType === "WAREHOUSE"
            );
            setAddresses(storeLocationAddresses);
            if (storeLocationAddresses.length === 1) {
              form.setFieldsValue({
                storeLocationId: storeLocationAddresses[0].id,
              });
            }
          });
          binService.getSuggestion("").then((y) => {
            setStoreBins(y.content);
          });
        }
        setStores(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });

    getSuppliers();
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.packSerialNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `packSerialNo=${encodeURIComponent(values.packSerialNo)}`;
    }
    if (values.serialNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `serialNo=${encodeURIComponent(values.serialNo)}`;
    }
    if (values.stockTypeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockTypeId=${encodeURIComponent(values.stockTypeId)}`;
    }
    if (values.storeId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeId=${encodeURIComponent(values.storeId)}`;
    }
    if (values.storeLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeLocationId=${encodeURIComponent(
        values.storeLocationId
      )}`;
    }
    if (values.purchaseOrderNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `purchaseOrderNumber=${encodeURIComponent(
        values.purchaseOrderNumber
      )}`;
    }
    if (values.mode && values.mode.length > 0) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `modes=${encodeURIComponent(values.mode.toString())}`;
    }
    if (values.lotNumberId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `lotNumberId=${encodeURIComponent(values.lotNumberId)}`;
    }
    if (values.stockBinId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockBinId=${encodeURIComponent(values.stockBinId)}`;
    }
    if (values.dateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.dateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.dateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
    }
    if (values.runningNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `runningNo=${encodeURIComponent(values.runningNo)}`;
    }
    if (values.plantId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `plantId=${encodeURIComponent(values.plantId)}`;
    }
    if (values.palletNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `palletNo=${encodeURIComponent(values.palletNo)}`;
    }
    if (values.supplierId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `supplierId=${encodeURIComponent(values.supplierId)}`;
    }
    if (values.inspectionStatus) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `inspectionStatus=${encodeURIComponent(
        values.inspectionStatus
      )}`;
    }
    if (values.productionDateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromProductionDate=${encodeURIComponent(
        moment(values.productionDateRange[0])
          .startOf("day")
          .format("YYYY-MM-DD")
      )}&toProductionDate=${moment(values.productionDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
    }
    if (values.netWeight) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `netWeight=${encodeURIComponent(values.netWeight)}`;
    }
    if (values.tempNumber) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `tempNumber=${encodeURIComponent(values.tempNumber)}`;
    }
    if (values.subLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `subLocationId=${encodeURIComponent(
        values.subLocationId
      )}`;
    }
    if (values.zoneId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `zoneId=${encodeURIComponent(values.zoneId)}`;
    }
    if (values.sbuId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `sbuId=${encodeURIComponent(values.sbuId)}`;
    }
    if (values.width) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `width=${encodeURIComponent(values.width)}`;
    }
    if (values.colorCode) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `colorCode=${encodeURIComponent(values.colorCode)}`;
    }
    materialDetailService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, data.pagination, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  const onClear = () => {
    props.onSearchResults([], { totalRecords: 0, totalGross: [] }, "");
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="purchaseOrderNumber" label="Invoice No">
            <Input placeholder="Invoice No" allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="storeLocationId" label="Store Location">
            <Select placeholder="Please select" allowClear>
              {addresses.length > 0 &&
                addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="dateRange" label="Received Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="mode" label="Status">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Please select"
              mode="multiple"
            >
              {StockStatuses.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                //onFinish({});
                onClear();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="stockTypeId" label="Material Type">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Type"
            >
              {stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="stockBinId" label="Stock Bin">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Bin"
            >
              {storeBins.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="productionDateRange" label="Production Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="supplierId" label="Supplier">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Supplier"
            >
              {suppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="sbuId" label="SBU">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {businessUnits.length > 0 &&
                businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="lotNumberId" label="Lot Number">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Lot Number"
            >
              {lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="width" label="Width">
            <Input type="string" placeholder="Width" allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="colorCode" label="Color Code">
            <Input type="number" placeholder="COM" allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="inspectionStatus" label="Inspection Status">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Please select"
            >
              {InspectionStatuses.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="zoneId" label="Zone">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {zones.length > 0 &&
                zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="serialNo" label="Box Serial">
            <Input placeholder="Box Serial" allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="tempNumber" label="Box Number">
            <Input type="number" placeholder="Box Number" allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="palletNo" label="Pallet No">
            <Input placeholder="Pallet No" allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="netWeight" label="Net Weight">
            <Input type="number" placeholder="Net Weight" allowClear />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="subLocationId" label="Sub-Location">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              disabled={!selectedZoneId}
            >
              {subLocations.length > 0 &&
                subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default MaterailDetailSearch;
