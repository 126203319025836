import React from "react";
import { Route, Switch } from "react-router-dom";
import Add from "./Add";
import List from "./List";
import Summary from "./Summary";
import { SubBins } from "./sub-bins/Index";
import { BinItems } from "./items/Index";
import { Auth } from "_helpers";
import SiluetaList from "./silueta/List";

function Bins({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaList} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/:id/summary`} component={Summary} />
        <Route path={`${path}/:id/sub-bins`} component={SubBins} />
        <Route path={`${path}/:id/items`} component={BinItems} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/:id/summary`} component={Summary} />
        <Route path={`${path}/:id/sub-bins`} component={SubBins} />
        <Route path={`${path}/:id/items`} component={BinItems} />
      </Switch>
    );
  }
}

export { Bins };
