import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Tag,
  Popconfirm,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Page from '_components/Page';
import { stockCheckService } from '_services';
import Search from './Search';
import { CloseOutlined } from '@ant-design/icons';
import { StockCheckTypes } from '_helpers';

const List = ({ match, history }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState('');

  useEffect(() => {
    getStockCheckRequests(pageNumber, pageSize);
  }, []);

  const getStockCheckRequests = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf('?') === -1 ? '?' : '&';
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    stockCheckService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getStockCheckRequests(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    stockCheckService
      .delete(id)
      .then((x) => {
        message.success('Successfully deleted!');
        getStockCheckRequests(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Link to={`${path}/add`} key='btn-add'>
        <Button>Add</Button>
      </Link>
    );
    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: 'ST Check Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => {
        return <Link to={`/stock-check/${record.id}/summary`}>{text}</Link>;
      },
    });
    columns.push({
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    });
    columns.push({
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text, record) => {
        return moment(text).format('YYYY-MM-DD');
      },
    });
    columns.push({
      title: 'Complete Date',
      dataIndex: 'completeDate',
      key: 'completeDate',
      render: (text, record) => {
        return text && moment(text).format('YYYY-MM-DD');
      },
    });
    columns.push({
      title: 'Type',
      key: 'stockCheckType',
      dataIndex: 'stockCheckType',
      render: (text) => {
        const type = StockCheckTypes.find((type) => type.value === text);
        return type ? type.name : `Undefined (${text})`;
      },
    });
    columns.push({
      title: 'Status',
      dataIndex: 'checkStatus',
      key: 'checkStatus',
      render: (orderStatus) => {
        let color = '';
        if (['COMPLETE'].includes(orderStatus)) {
          color = '#87d068';
        }
        if (orderStatus === 'OPEN') {
          color = '#2db7f5';
        }
        if (['IN_PROGRESS'].includes(orderStatus)) {
          color = '#faad14';
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    });
    columns.push({
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '8%',
    });
    columns.push({
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: '8%',
    });
    columns.push({
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <span>
            {record.checkStatus === 'OPEN' && (
              <Popconfirm
                title='Are you sure to delete this item?'
                onConfirm={() => handleDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const getRowColor = (record) => {
    let rowColor = '';
    if (record && record.orderStage === 'VERIFICATION_INPROGRESS') {
      rowColor = 'mr-row-verification-inprogress';
    } else if (record && record.orderStage === 'VERIFIED') {
      rowColor = 'mr-row-verified';
    }

    return rowColor;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/stock-check'>Stock Check Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={getColumns()}
            rowClassName={(record) => getRowColor(record)}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
