import {
  Breadcrumb,
  Button,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { addressService, companyService } from "_services";
import TabMenu from "../TabMenu";

function Add({ history, match }) {
  const { id } = match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setIsLoading(true);
    companyService.getById(id).then((x) => {
      setCompany(x.content);
      setIsLoading(false);
    });

    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = x.content[0].id;
        companyService.getById(companyId).then((x) => {
          setStoreAddresses(x.content.addresses);
        });
      }
      setStores(x.content);
    });
  }, []);

  const getStoreAddresses = (companyId) => {
    form.resetFields(["storeAddress"]);
    if (companyId) {
      setAddressLoading(true);
      companyService.getById(companyId).then((x) => {
        setStoreAddresses(x.content.addresses.filter(address => address.addressType === 'WAREHOUSE'));
        setAddressLoading(false);
      });
    }
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Val:", values);
        setIsLoading(true);
        addressService
          .link(id, values.storeAddress.id)
          .then(() => {
            message.success("Successfully added.");
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies">Company Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies/add">Company: {company.code}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies/add">Store Addresses</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/companies/add">Link Address</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Add
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <TabMenu menu="store-addresses" id={id} />
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name={["store", "id"]}
                  label="Store"
                  rules={[{ required: true, message: "Store is required" }]}
                >
                  <Select placeholder="Please select" allowClear onChange={getStoreAddresses}>
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["storeAddress", "id"]}
                  label="Store Location"
                  rules={[
                    { required: true, message: "Store Location is required" },
                  ]}
                >
                  <Select placeholder="Please select" allowClear loading={addressLoading}>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
