import { Breadcrumb, Button, PageHeader, Table, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { companyService } from "_services";
import TabMenu from "../TabMenu";
import { Auth } from "_helpers";

function List({ match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [company, setCompany] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    companyService
      .getById(id)
      .then((x) => {
        //console.log("Company:", x.content);
        const company = x.content;
        setCompany(company);
        if (company.companyType === "STORE") {
          setItems(company.addresses);
        } else {
          setItems(
            x.content.addresses.filter(
              (item) => item.addressType !== "WAREHOUSE"
            )
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (addressId) => {
    /* addressService
      .delete(addressId)
      .then((x) => {
        message.success("Successfully deleted!");
        getItems(id);
      })
      .catch((error) => {
        message.error(`${error}`);
      }); */
  };

  const getAdditionalButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      buttons.push(
        <Link to={`${path}/add`.replace(":id", id)} key="1">
          <Button>Add</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => (
        <Link to={`${path}/${record.id}`.replace(":id", id)}>{text}</Link>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDelete(record.id)}
            disabled={!Auth.hasRole(Auth.getProfile(), ["SAU"])}
          >
            <CloseOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/companies">
              <span>Company Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/companies/${company.id}/summary`}>
              <span>Company: {company.code}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Addresses</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="addresses" id={id} />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={items}
            pagination={false}
          />
        </Page>
      </PageHeader>
    </div>
  );
}

export default List;
