import {
    Button,
    DatePicker,
    Form,
    PageHeader,
    Row,
    Select,
    Radio,
    Breadcrumb,
    Popconfirm,
    Col,
    Input,
    message,
    Spin,
  } from 'antd';
  import React, { useEffect, useState } from 'react';
  import { Link } from 'react-router-dom';
  import { CSVLink } from 'react-csv';
  import moment from 'moment';
  import Page from '_components/Page';
  import { Auth } from '_helpers';
  import {
    companyService,
    extraService,
    sbuService,
    stockClassificationService,
    subLocationService,
    supplierService,
    zoneService,
  } from '_services';
  
  const StrUpload = ({ history }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);
    const [storeAddresses, setStoreAddresses] = useState([]);
    const [customers, setcustomers] = useState([]);
    const [customerAddresses, setcustomerAddresses] = useState([]);
    const [selectedcustomer, setSelectedcustomer] = useState(null);
    const [stores, setStores] = useState([]);
    const [materialSuppliers, setMaterialSuppliers] = useState([]);
    const [isDetailedUpload, setIsDetailedUpload] = useState(Date());
    const [base64File, setBase64File] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [stockClasses, setStockClasses] = useState([]);
    const [businessUnits, setBusinessUnits] = useState([]);
    const [zones, setZones] = useState([]);
    const [subLocations, setSubLocations] = useState([]);
    const [selectedSbuId, setSelectedSbuId] = useState(null);
    const [selectedZoneId, setSelectedZoneId] = useState(null);
    const [error, setError] = useState(null);
  
    const [form] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;
    const loggedInCustomerCode = Auth.getProfile().comp_code;
    const isLoggedInCustomer = () => {
      return Auth.hasRole(Auth.getProfile(), ['CAU']);
    };
  
    useEffect(() => {
      setIsLoading(true);
      companyService.getSuggestion('?companyType=STORE').then((x) => {
        if (x.content.length === 1) {
          setSelectedStore(x.content[0]);
          form.setFieldsValue({
            store: x.content[0],
            uploadOption: 'SUMMARY',
          });
        }
        setStores(x.content);
        setIsLoading(false);
      });
  
      let companyId = Auth.getProfile().comp_id;
      setIsLoading(true);
      companyService.getById(companyId).then((data) => {
        const grantedStoreAddresses = data.content.addresses.filter(
          (item) => item.addressType === 'WAREHOUSE'
        );
        const officeAddresses = data.content.addresses.filter(
          (item) => item.addressType === 'OFFICE'
        );
        form.setFieldsValue({
          storeAddress:
            grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
          customer: data.content,
          customerAddress: officeAddresses[0],
        });
        stockClassificationService.getSuggestion().then((x) => {
          setStockClasses(x.content);
        });
        setStoreAddresses(grantedStoreAddresses);
        setcustomers([data.content]);
        setcustomerAddresses(officeAddresses);
        setSelectedcustomer(data.content);
        setIsLoading(false);
      });
      getMaterialSuppliers();
      getBusinessUnits();
      getZones();
      getSubLocations();
    }, []);
  
    const getStoreAddresses = (companyId) => {
      form.resetFields(['storeAddress']);
  
      companyService.getById(companyId).then((x) => {
        setSelectedStore(x.content);
        setStoreAddresses(
          x.content.addresses.filter(
            (address) => address.addressType === 'WAREHOUSE'
          )
        );
      });
    };
  
    const getMaterialSuppliers = () => {
      supplierService
        .getSuggestion()
        .then((x) => {
          setMaterialSuppliers(x.content);
        })
        .catch((errorInfo) => {
          message.error(`${errorInfo}`);
        });
    };
  
    const getBusinessUnits = () => {
      sbuService
        .search('')
        .then((x) => {
          setBusinessUnits(x.content);
        })
        .catch((errorInfo) => {
          message.error(`${errorInfo}`);
        });
    };
  
    const getZones = () => {
      zoneService
        .search('')
        .then((x) => {
          setZones(x.content);
        })
        .catch((errorInfo) => {
          message.error(`${errorInfo}`);
        });
    };
  
    const getSubLocations = () => {
      subLocationService
        .search('')
        .then((x) => {
          setSubLocations(x.content);
        })
        .catch((error) => {
          message.error(`${error}`);
        });
    };
  
    const handleFormSumbit = () => {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
  
          const payload = {
            customerId: values.customer.id,
            storeId: values.store.id,
            supplierId: values.materialSupplier,
            invoiceDate: moment(values.receivedDate).format('YYYY-MM-DD'),
            receivedDate: moment(values.receivedDate).format('YYYY-MM-DD'),
            returnDate: values.returnDate
              ? moment(values.returnDate).format('YYYY-MM-DD')
              : null,
            deliverFrom: values.customerAddress.id,
            deliveryTo: values.storeAddress.id,
            base64FileString: base64File,
            originalFileName: originalFileName,
            remark: values.remark,
            receivingPerson: values.receivingPerson,
            packingStatus: values.packingStatus,
            subLocationId: values.subLocationId,
            refNumber: values.refNumber,
          };
  
          values.invoiceDate = moment(values.orderDate).format('YYYY-MM-DD');
          if (values.uploadOption === 'SUMMARY') {
            extraService
              .uploadSummaryPackingList(payload)
              .then(() => {
                message.success('Successfully uploaded!');
                setIsLoading(false);
                history.push('.');
              })
              .catch((error) => {
                setIsLoading(false);
                setError(error);
              });
          } else {
            extraService
              .uploadPackingList(payload)
              .then(() => {
                message.success('Successfully uploaded!');
                setIsLoading(false);
                history.push('.');
              })
              .catch((error) => {
                setIsLoading(false);
                setError(error);
              });
          }
        })
        .catch((error) => {
          //message.error(`${error}`);
        });
    };
  
    const onCancel = () => {
      history.push('/packing-lists');
    };
  
    const onFileChange = (e) => {
      if (e.target.files[0]) {
        setOriginalFileName(e.target.files[0].name);
        let reader = new FileReader();
        reader.onload = function (e) {
          setBase64File(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  
    return (
      <div>
        <PageHeader
          title={
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/dashboard'>Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to='/packing-lists'>Packing List Search</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Packing List Upload</Breadcrumb.Item>
            </Breadcrumb>
          }
          extra={[
            <Popconfirm
              title='Are you sure to add the changes?'
              onConfirm={handleFormSumbit}
              key='add-confirm'
            >
              <Button
                key='1'
                type='primary'
                htmlType='submit'
                loading={isLoading}
              >
                Save
              </Button>
            </Popconfirm>,
            <Button key='2' htmlType='button' onClick={onCancel}>
              Cancel
            </Button>,
          ]}
        >
          <Page inner error={error}>
            <Spin tip='Loading...' spinning={isLoading}>
              <Form
                layout='vertical'
                form={form}
                name='form-create'
                requiredMark={false}
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      name={['customer', 'id']}
                      label='Customer'
                      rules={[
                        { required: true, message: 'Customer is required' },
                      ]}
                    >
                      <Select placeholder='Please select' disabled allowClear>
                        {customers.length > 0 &&
                          customers.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={['store', 'id']}
                      label='Store'
                      rules={[{ required: true, message: 'Store is required' }]}
                    >
                      <Select
                        placeholder='Please select'
                        allowClear
                        onChange={getStoreAddresses}
                      >
                        {stores.length > 0 &&
                          stores.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name='materialSupplier' label='Material Supplier'>
                      <Select
                        showSearch
                        allowClear
                        showArrow={false}
                        optionFilterProp='children'
                        placeholder='Material Supplier'
                      >
                        {materialSuppliers.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name} ({t.code})
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name='sbuId' label='SBU'>
                      <Select
                        placeholder='Please select'
                        allowClear
                        optionFilterProp='children'
                        showSearch
                        onChange={(e) => {
                          form.setFieldsValue({ zoneId: null });
                          form.setFieldsValue({ subLocationId: null });
                          setSelectedSbuId(e);
                        }}
                        onClear={() => {
                          form.setFieldsValue({ zoneId: null });
                          form.setFieldsValue({ subLocationId: null });
                        }}
                      >
                        {businessUnits.length > 0 &&
                          businessUnits.map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      name={['customerAddress', 'id']}
                      label='Deliver From'
                      rules={[
                        { required: true, message: 'Delivery from is required' },
                      ]}
                    >
                      <Select
                        placeholder='Please select'
                        allowClear
                        disabled={isLoggedInCustomer()}
                      >
                        {customerAddresses.length > 0 &&
                          customerAddresses.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.address}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={['storeAddress', 'id']}
                      label='Delivery To'
                      rules={[
                        {
                          required: true,
                          message: 'Delivery address is required',
                        },
                      ]}
                    >
                      <Select placeholder='Please select' allowClear>
                        {storeAddresses.length > 0 &&
                          storeAddresses.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.address}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='receivedDate'
                      label='Received Date'
                      rules={[
                        { required: true, message: 'Received date is required' },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        placeholder='Received date'
                        format='YYYY-MM-DD'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name='zoneId' label='Zone'>
                      <Select
                        placeholder='Please select'
                        allowClear
                        optionFilterProp='children'
                        showSearch
                        onChange={(e) => {
                          form.setFieldsValue({ subLocationId: null });
                          setSelectedZoneId(e);
                        }}
                        onClear={() => {
                          form.setFieldsValue({ subLocationId: null });
                        }}
                        disabled={!selectedSbuId}
                      >
                        {zones.length > 0 &&
                          zones
                            .filter((item) => item.sbu.id === selectedSbuId)
                            .map((t) => (
                              <Select.Option value={t.id} key={t.id}>
                                {t.name}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item name='remark' label='Remark'>
                      <TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='refNumber'
                      label='Ref. Number'
                      rules={[
                        {
                          required: true,
                          message: 'Ref.Number is required.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='receivingPerson'
                      label='Receiving Person'
                      rules={[
                        {
                          required: true,
                          message: 'Receiving Person is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='subLocationId'
                      label='Sub-Location'
                      rules={[
                        {
                          required: true,
                          message: 'Please select Sub-Location',
                        },
                      ]}
                    >
                      <Select
                        placeholder='Please select'
                        allowClear
                        optionFilterProp='children'
                        showSearch
                        disabled={!selectedZoneId}
                      >
                        {subLocations.length > 0 &&
                          subLocations
                            .filter((item) => item.zone.id === selectedZoneId)
                            .map((t) => (
                              <Select.Option value={t.id} key={t.id}>
                                {t.name}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label='Upload Option' name='uploadOption'>
                      <Radio.Group buttonStyle='solid'>
                        <Radio.Button value='DETAILED'>
                          Detailed Upload
                        </Radio.Button>
                        <Radio.Button value='SUMMARY'>
                          Summary Upload
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='file'
                      label='Packing List File'
                      rules={[
                        {
                          required: true,
                          message: 'Valid excel file is required',
                        },
                      ]}
                      valuePropName='file'
                    >
                      <Input
                        type='file'
                        name='file'
                        onChange={onFileChange}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingTop: 40 }}>
                    <>
                      <CSVLink
                        key='csv-file'
                        data={[]}
                        filename={'Sample-StockIn-Summary-File.csv'}
                        headers={[
                          { label: 'Supplier', key: 'supplier' },
                          { label: 'Total Box', key: 'totalBox' },
                          { label: 'Material', key: 'material' },
                          { label: 'Lot No', key: 'lotNo' },
                          { label: 'Total Net Weight', key: 'totalNetWeight' },
                          {
                            label: 'Total Gross Weight',
                            key: 'totalGrossWeight',
                          },
                          { label: 'Packing Status', key: 'packingStatus' },
                        ]}
                      >
                        Sample Upload File
                      </CSVLink>
                    </>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='packingStatus'
                      label='Packing Status'
                      rules={[
                        {
                          required: true,
                          message: 'Please select Packing Status',
                        },
                      ]}
                    >
                      <Select
                        placeholder='Please select'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        <Option value='NEW' key='NEW'>
                          NEW
                        </Option>
                        <Option value='RETURN' key='RETURN'>
                          RETURN
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Page>
        </PageHeader>
      </div>
    );
  };
  
  export default StrUpload;
  