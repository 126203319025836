import {
  Button,
  InputNumber,
  Select,
  Space,
  message,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { CloseOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const TableForm = ({
  value,
  onChange,
  stockClass,
  materialTypes,
  lotNumbers,
  measurementTypes,
}) => {
  //console.log("Lot Numbers:", lotNumbers);
  // console.log("Measurement: ", measurementTypes);
  const [loading, setLoading] = useState(false);
  //const [stockItemLoading, setStockItemLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(value);
  const precision = 2;

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
  }, []);

  const getRowByKey = (key, newData) =>
    (newData || data)?.filter((item) => item.key === key)[0];
  /* const getRowBykey = (key, newData) => {
    return (data || newData).filter((item) => item.key === key)[0];
  }; */

  const handleAddOrderLine = () => {
    const newData = data?.map((item) => ({ ...item })) || [];
    const defaultMeasurementType = measurementTypes.find(
      (item) => item.name === "Kg"
    );

    newData.push({
      key: `NEW_TEMP_ID_${index}`,
      //stockType: materialTypes.find((item) => item.id === materialTypeId),
      //lotNumber: lotNumbers.find((item) => item.id === lotNumberId),
      quantity: null,
      netWeight: null,
      grossWeight: null,
      measurementType: defaultMeasurementType,
    });

    setIndex(index + 1);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
    //setMaterialTypeId(null);
    //setLotNumberId(null);
  };

  const handleRemoveOrderLine = (key) => {
    if (data.length === 1) {
      return message.error("Sorry, packing list should have at least on receipt line.");
    } else {
      const newData = data?.filter((item) => item.key !== key);
      setData(newData);
      if (onChange) {
        onChange(newData);
      }
    }
  };

  const handleSaveOrderLine = (e, key) => {
    e.persist();
    const target = getRowByKey(key) || {};
    //console.log("Targe:", target);
    if (
      !target.stockType ||
      !target.lotNumber ||
      !target.quantity ||
      //!target.netWeight ||
      !target.measurementType
    ) {
      message.error("Please complete the packing list item information.");
      e.target.focus();
      return;
    } else {
      handleAddOrderLine();
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const newData = [...data];
    const target = getRowByKey(key, newData);
    if (target) {
      if (["stockType", "lotNumber", "measurementType"].includes(fieldName)) {
        target[fieldName] = { id: value };
      } else {
        target[fieldName] = value;
      }

      setData(newData);
    }
  };

  const columns = [
    {
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.stockType &&
        a.stockType.code &&
        b.stockType &&
        b.stockType.code &&
        a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return (
          <Select
            value={record.stockType && record.stockType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "stockType", record.key)}
          >
            {materialTypes.length > 0 &&
              materialTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.code}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lotNumber.number.localeCompare(b.lotNumber.number),
      render: (text, record) => {
        return (
          <Select
            value={record.lotNumber && record.lotNumber.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
          >
            {lotNumbers &&
              lotNumbers.length > 0 &&
              lotNumbers.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.number}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Box",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            min={0}
            placeholder="Box"
          />
        );
      },
    },
    {
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      width: "10%",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "netWeight", record.key)}
            placeholder="Net Weight"
          />
        );
      },
    },
    {
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      width: "10%",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "grossWeight", record.key)}
            placeholder="Gross Weight"
          />
        );
      },
    },
    {
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.measurementType && record.measurementType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) =>
              handleFieldChange(e, "measurementType", record.key)
            }
            disabled
          >
            {measurementTypes.length > 0 &&
              measurementTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.name}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Are you sure to remove?"
              onConfirm={() => handleRemoveOrderLine(record.key)}
              key="remove-confirm"
            >
              <Button
                size="small"
                icon={<CloseOutlined />}
                onClick={() => handleRemoveOrderLine(record.key)}
              ></Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveOrderLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {data.length === 0 && (
        <Button
          style={{
            width: "100%",
            marginTop: 16,
            marginBottom: 8,
            background: "rgb(190, 200, 200)",
          }}
          type="dashed"
          onClick={handleAddOrderLine}
          icon={<PlusOutlined />}
        >
          Add Item
        </Button>
      )}
      <Table
        bordered
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        summary={(pageData) => {
          let totalQuantity = 0;
          let totalNetWeight = 0;
          let totalGrossWeight = 0;
          let measurementTypes = [];

          pageData.forEach(
            ({ quantity, netWeight, grossWeight, measurementType }) => {
              totalQuantity += quantity;
              totalNetWeight += netWeight;
              totalGrossWeight += grossWeight;
              if (
                typeof measurementType === "object" &&
                measurementType !== null
              ) {
                measurementTypes.push(measurementType.id);
              } else {
                measurementTypes.push(measurementType);
              }
            }
          );
          const uniqueMeasurementTypes = [...new Set(measurementTypes)];

          return (
            <>
              {uniqueMeasurementTypes.length === 1 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {totalQuantity}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {Number(totalNetWeight).toFixed(precision)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {Number(totalGrossWeight).toFixed(precision)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} />
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default TableForm;
