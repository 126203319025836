import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { departmentService } from "_services";

function Add({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        departmentService
          .create(values)
          .then(() => {
            message.success("Successfully created!");
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/departments">Department Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/departments/add">New Department</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="code"
                  label="Department Code"
                  rules={[
                    { required: true, message: "Code is required" },
                  ]}
                >
                  <Input placeholder="Department Code" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label="Department Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Department Name" />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
