import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import {Avatar, Badge, Menu, Dropdown} from "antd";
import { Auth } from "_helpers";

const SubMenu = Menu.SubMenu;

const AppHeader = withRouter(({ history }) => (
    <Menu
        key='key'
        mode='horizontal'
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        theme='dark'   style={{     width: 245, height: 62,  }} >

    <SubMenu
      title={
        <Fragment>
          <span style={{ color: "#999", marginRight: 4 }}>
            {Auth.getTitle()}:
          </span>
            <Avatar shape="square" size="large" />
            {/*<Badge count={1000} overflowCount={999}>*/}
            {/*    <span>{Auth.getProfile().name}</span>*/}
            {/*    <Avatar shape="square" size="large" />*/}

            {/*</Badge>*/}

        </Fragment>
      }
    >
      <Menu.Item key="profile">
        <Link to="/account">
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="SignOut"
        onClick={() => {
          Auth.clearSession();
          history.push("/");
        }}
      >
        <span>Sign out</span>
      </Menu.Item>
    </SubMenu>
  </Menu>


));

export default AppHeader;
