import {
  Button,
  Col,
  Form,
  Row,
  Input,
  message,
  DatePicker,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { OrderStatus } from '_helpers';
import {
  binLocationService,
  binService,
  lotNumberService,
  materialTypeService,
  purchaseOrderService,
  supplierService,
} from '_services';

function Search(props) {
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [binLocations, setBinLocations] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getStockTypes();
    getLotNumbers();
    getSuppliers();
    getBinLocations();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getBinLocations = () => {
    binLocationService
      .getSuggestion('')
      .then((x) => {
        setBinLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    if (values.purchaseOrderNumber) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `preReceiptNumber=${encodeURIComponent(
        values.purchaseOrderNumber
      )}`;
    }
    if (values.orderStage) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `orderStages=${encodeURIComponent(
        values.orderStage.toString()
      )}`;
    }
    if (values.orderDateRange) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.orderDateRange[0]).startOf('day').format('YYYY-MM-DD')
      )}&toDate=${moment(values.orderDateRange[1])
        .startOf('day')
        .format('YYYY-MM-DD')}`;
    }
    if (values.stockTypeId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `stockTypeId=${encodeURIComponent(values.stockTypeId)}`;
    }
    if (values.lotNumberId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `lotNumberId=${encodeURIComponent(values.lotNumberId)}`;
    }
    if (values.binLocationId) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `binLocationId=${encodeURIComponent(values.binLocationId)}`;
    }
    purchaseOrderService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='purchaseOrderNumber' label='Invoice Number'>
            <Input.Search placeholder='Order Number' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='orderDateRange' label='Invoice Date'>
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='orderStage' label='Status'>
            <Select allowClear mode='multiple'>
              {OrderStatus.map((t) => (
                <Select.Option value={t.value} key={t.value}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='stockTypeId' label='Material Type'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Material Type'
            >
              {stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='lotNumberId' label='Lot Number'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Lot Number'
            >
              {lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='supplierId' label='Supplier'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Supplier'
            >
              {suppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
        <Form.Item name='binLocationId' label='Bin Location'>
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp='children'
              placeholder='Supplier'
            >
              {binLocations.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
