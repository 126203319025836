import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  PageHeader,
  Popconfirm,
  Table,
  message,
  Spin,
  Space,
  Badge,
} from "antd";
import Page from "_components/Page";
import { binService, materialDetailService } from "_services";
import Search from "./Search";
import { CloseOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";
import Modal from "antd/lib/modal/Modal";
import StockDetails from "./StockDetails";

const List = ({ match }) => {
  const [stockBins, setStockBins] = useState([]);
  const [subBins, setSubBins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [binDetailsLoading, setBinDetailsLoading] = useState(false);
  const [showSubBins, setShowSubBins] = useState(false);
  const [showStockDetails, setShowStockDetails] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [totalStockCount, setTotalStockCount] = useState(0);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const handleSearchResults = (results) => {
    setStockBins(results);
    setLoading(false);
  };

  const handleShowSubBins = (bin) => {
    binService
      .getParentById(bin.id)
      .then((data) => {
        if (data.content.length > 0) {
          //console.log("SubBins:", data.content);
          setSubBins(data.content);
          setShowSubBins(true);
        } else if (bin.totalStocks > 0) {
          setBinDetailsLoading(true);
          materialDetailService
            .search(`?stockBinId=${bin.id}&mode=AVAILABLE&pageNumber=1&pageSize=1000`)
            .then((data) => {
              if (data.content.length > 0) {
                setStocks(data.content);
                setTotalStockCount(data.pagination.totalRecords);
                setShowStockDetails(true);
              }
              setBinDetailsLoading(false);
            })
            .catch((error) => {
              setStocks([]);
              setBinDetailsLoading(false);
            });
        }
      })
      .catch((error) => {});
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stores">Store Location View</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Spin tip="Loading..." spinning={loading || binDetailsLoading}>
            <div style={{ marginTop: 40 }}>
              <Space size={[40, 25]} wrap>
                {stockBins.map((bin, index) => (
                  <a
                    key={`sub-bins-${index}`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowSubBins(bin);
                    }}
                  >
                    <Badge
                      count={bin.totalStocks}
                      overflowCount={1000}
                      key={index}
                      style={{ padding: 0 }}
                    >
                      <span
                        className="head-example"
                        style={{
                          width: 90,
                          height: 30,
                          padding: 6,
                          backgroundColor: "rgb(238, 238, 238)",
                          borderRadius: 4,
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        <b>{bin.code}</b>
                      </span>
                    </Badge>
                  </a>
                ))}
              </Space>
            </div>
          </Spin>
          <Modal
            title="Sub Bins"
            visible={showSubBins}
            onOk={() => {
              setShowSubBins(false);
            }}
            onCancel={() => {
              setShowSubBins(false);
            }}
            class="bin-selection"
            width={1000}
          >
            <Space size={[40, 25]} wrap>
              {subBins.map((bin, index) => (
                <Badge
                  count={bin.totalStocks}
                  overflowCount={1000}
                  key={index}
                  style={{ padding: 0 }}
                >
                  <span
                    className="head-example"
                    style={{
                      width: 115,
                      height: 35,
                      padding: 6,
                      backgroundColor: "rgb(238, 238, 238)",
                      borderRadius: 4,
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    <b>{bin.code}</b>
                  </span>
                </Badge>
              ))}
            </Space>
          </Modal>
          <Modal
            title={`Stocks (${totalStockCount})`}
            style={{ top: 48 }}
            visible={showStockDetails}
            onCancel={() => setShowStockDetails(false)}
            okButtonProps={{ style: { display: "none" } }}
            width={1300}
          >
            <StockDetails
              stocks={stocks}
              totalStockCount={totalStockCount}
              customerCode={loggedInCustomerCode}
            />
          </Modal>
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
