import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/sbu`;

export const sbuService = {
  search,
  getById,
  create,
  update,
  delete: _delete,
  searchAging,
};

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function searchAging(params) {
  return fetchWrapper.get(`${baseUrl}/aging${params}`);
}