import { Button, Col, Form, Row, message, Select, DatePicker } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { reportService } from "_services";

function AvailableStockSearch(props) {
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedSbuCode, setSelectedSbuCode] = useState(null);
  const [form] = Form.useForm();
  let dateFilter = false;
  let fromDate = null;
  let toDate = null;

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    if (values.requestDateRange) {
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.requestDateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.requestDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
      fromDate = moment(values.requestDateRange[0])
        .startOf("day")
        .format("YYYY-MM-DD");
      toDate = moment(values.requestDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD");
      dateFilter = true;
    } else {
      searchParams += `fromDate=${encodeURIComponent(
        moment("2021-01-01").format("YYYY-MM-DD")
      )}&toDate=${moment().format("YYYY-MM-DD")}`;
      dateFilter = false;
    }
    if (values.storeLocationId) {
      searchParams += `&storeLocations=${values.storeLocationId}`;
    } else {
      searchParams += `&storeLocations=${props.addresses
        .map((item) => item.id)
        .join()}`;
    }
    if (values.materialType) {
      searchParams += `&materialType=${values.materialType}`;
    }
    if (values.lotNumber) {
      searchParams += `&lotNumber=${values.lotNumber}`;
    }
    if (values.materialSupplier) {
      searchParams += `&materialSupplier=${values.materialSupplier}`;
    }
    if (values.subLocationId) {
      searchParams += `&subLocation=${values.subLocationId}`;
    }
    if (values.zoneId) {
      searchParams += `&zone=${values.zoneId}`;
    }
    if (values.sbuId) {
      searchParams += `&sbu=${values.sbuId}`;
    }

    reportService
      .getAvailableStocks(searchParams)
      .then((data) => {
        props.onSearchResults(
          selectedSbuCode,
          data.content,
          fromDate,
          toDate,
          dateFilter,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="requestDateRange" label="Receive Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="storeLocationId" label="Store Location">
            <Select placeholder="Please select" allowClear>
              {props.addresses.length > 0 &&
                props.addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="materialSupplier" label="Material Supplier">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Supplier"
            >
              {props.materialSupplierList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="sbuId" label="SBU">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
                const selectedSbu = props.businessUnits.find(
                  (item) => item.id === e
                );
                setSelectedSbuCode(selectedSbu.code);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuCode(null);
              }}
            >
              {props.businessUnits.length > 0 &&
                props.businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}></Col>
        <Col span={5}></Col>
        <Col span={5}>
          <Form.Item name="materialType" label="Material">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Type"
            >
              {props.stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="zoneId" label="Zone">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {props.zones.length > 0 &&
                props.zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}></Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}></Col>
        <Col span={5}></Col>
        <Col span={5}>
          <Form.Item name="lotNumber" label="Lot No">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Lot Number"
            >
              {props.lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="subLocationId" label="Sub-Location">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              disabled={!selectedZoneId}
            >
              {props.subLocations.length > 0 &&
                props.subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}></Col>
      </Row>
    </Form>
  );
}

export default AvailableStockSearch;
