import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { companyService, materialRequestService } from "_services";
import { FilePdfOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [materialRequest, setMaterialRequest] = useState({});
  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [lotNumberId, setLotNumberId] = useState(null);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const { Option } = Select;
  const { Text } = Typography;
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  useEffect(() => {
    setLoading(true);
    materialRequestService
      .getById(id)
      .then((x) => {
        console.log("MR:", x.content);
        let materialRequest = x.content;

        //materialRequest.customer = materialRequest.customer.id;
        //materialRequest.deliveryLocation = materialRequest.deliveryLocation.id;
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = i;
          if (["SILUETA"].includes(loggedInCustomerCode)) {
            item.requestedQuantity = item.quantity;
          } else {
            item.requestedQuantity = item.requestedItems.length;
            if (item.requestedItems && item.requestedItems.length > 0) {
              item.netWeight = item.requestedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next);
              item.grossWeight = item.requestedItems
                .map((item) => item.grossWeight)
                .reduce((prev, next) => prev + next);

              item.requestedItems.forEach((stock) => {
                stock.stockType = item.stockType;
                stock.lotNumber = item.lotNumber;
              });
            }
          }
        });

        //console.log("MR: ", materialRequest);
        setMaterialRequest(materialRequest);
        setMaterialRequestLines(materialRequest.requestLines);
        getCustomerAddresses(materialRequest.customer.id);
        onFill(materialRequest);
        setLoading(false);
        //console.log("FormVal: ", form.getFieldValue("purchaseOrderLines"));
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });

    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
  }, []);

  const getCustomerAddresses = (companyId) => {
    companyService.getById(companyId).then((data) => {
      const officeAddress = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      setCustomerAddresses(officeAddress);
    });
  };

  /* const handleShowStocks = () => {
    let requestLine = materialRequestLines.find(
      (item) =>
        item.stockType.id === materialTypeId &&
        item.lotNumber.id === lotNumberId
    );
    if (requestLine) {
      setSelectedStockIds(requestLine.requestedItems.map((item) => item.id));
    }
  }; */

  /* const expandedRowRender = (value) => {
    const columns = [
      {
        title: "Pack Serial",
        dataIndex: "packSerialNo",
        key: "packSerialNo",
        width: "30%",
      },
      {
        title: "Material Type",
        dataIndex: "stockType",
        key: "stockType",
        render: (text, record) => {
          return record.stockType.code;
        },
      },
      {
        title: "Units",
        dataIndex: "lotNumber",
        key: "lotNumber",
        render: (text, record) => {
          return record.lotNumber && record.lotNumber.number;
        },
      },
      {
        title: "Stock Bin",
        key: "stockBin",
        render: (text, record) => {
          return record.stockBin && record.stockBin.code;
        },
      },
      {
        title: "Status",
        dataIndex: "mode",
        key: "mode",
        render: (text) => {
          let color = "";
          if (text === "AVAILABLE") {
            color = "#87d068";
          }
          if (text === "REQUESTED") {
            color = "#2db7f5";
          }
          if (text === "ISSUED") {
            color = "#108ee9";
          }
          if (text === "DELIVERED") {
            color = "#f50";
          }
          return <Tag color={color}>{text}</Tag>;
        },
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Net Measure",
        dataIndex: "netWeight",
        key: "netWeight",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      },
      {
        title: "Gross Measure",
        dataIndex: "grossWeight",
        key: "grossWeight",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      },
      ,
    ];
    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={value.requestedItems}
        pagination={false}
      />
    );
  }; */

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        /* values.requestLines.forEach((item) => {
          if (!item.requestedQuantity) {
            message.error("Please fill in complete order line.");
            setLoading(false);
            return;
          }
        }); */
        values.requestDate = moment(values.requestDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        //console.log("Update: ", values);
        materialRequestService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setLoading(false);
            history.push(`${path}`.replace(":id", id));
          })
          .catch((error) => {
            setLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((errorInfo) => {});
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    materialRequestService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-issue-request-${materialRequest.requestNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCreateDelivery = () => {
    history.push(`/stock-issue/add?mrn=${id}`);
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="pdf"
        icon={<FilePdfOutlined />}
        onClick={handlePrintPdf}
        loading={loadingPdf}
      ></Button>
    );
    /* buttons.push(
      <Popconfirm
        title="Are you sure to update the changes?"
        onConfirm={handleFormSumbit}
        key="update-confirm"
      >
        <Button key="update" htmlType="submit" loading={loading} type="primary">
          Save
        </Button>
      </Popconfirm>
    ); */
    if (
      ["OPEN", "IN_PROGRESS", "ACCEPTED"].includes(
        materialRequest && materialRequest.orderStage
      )
    ) {
      buttons.push(
        <Button
          key="delivery"
          htmlType="button"
          type="primary"
          onClick={onCreateDelivery}
        >
          Create Delivery
        </Button>
      );
    }
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      key: "stockTypeCode",
      width: "20%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "30%",
      render: (text, record) => {
        return record.stockType.name;
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "20%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Quantity",
        key: "quantity",
        align: "right",
        render: (text, record) => {
          return Number(record.requestedQuantity).toFixed(2);
        },
      });
      columns.push({
        title: "UOM",
        dataIndex: "measurementType",
        key: "measurementType",
        width: "3%",
        render: (text, record) => {
          return record.measurementType && record.measurementType.name;
        },
      });
    } else {
      columns.push({
        title: "Units",
        key: "quantity",
        align: "right",
        render: (text, record) => {
          return record.requestedQuantity;
        },
      });
    }
    if (!["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Net Measure",
        dataIndex: "netWeight",
        key: "netWeight",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
      columns.push({
        title: "Gross Measure",
        dataIndex: "grossWeight",
        key: "grossWeight",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
    }

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    /* columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={record.stockType.name}
          >
            {record.stockType.name}
          </Tooltip>
        );
      },
    }); */
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (
      materialRequest.customer &&
      ["PRYM", "SILUETA"].includes(materialRequest.customer.code)
    ) {
      columns.push({
        title: "Roll/Box No:",
        dataIndex: "runningNo",
        key: "runningNo",
        /* align: "right", */
      });
    }
    /* columns.push({
      title: "Location",
      key: "storeLocation",
      render: (text, record) => {
        return record.stockBin.storeLocation.address;
      },
    }); */
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    if (
      materialRequest.customer &&
      ["SILUETA"].includes(materialRequest.customer.code)
    ) {
      columns.push({
        title: "Pallet No",
        dataIndex: "palletNo",
        key: "palletNo",
      });
    }
    if (
      materialRequest.customer &&
      ["STR-RM"].includes(materialRequest.customer.code)
    ) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      });
    }
    if (
      materialRequest.customer &&
      ["PRYM"].includes(materialRequest.customer.code)
    ) {
      columns.push({
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      });
    }
    columns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    if (
      materialRequest.customer &&
      ["PRYM"].includes(materialRequest.customer.code)
    ) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        align: "right",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag
                  color={`${
                    materialRequest.orderStage === "OPEN"
                      ? "#2db7f5"
                      : ["IN_PROGRESS", "ACCEPTED"].includes(
                          materialRequest.orderStage
                        )
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getCustomerAddresses}
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Request date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestNumber"
                    label="Request No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["deliveryLocation", "id"]}
                    label="Deliver To"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select">
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact No:">
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactPersonName" label="Contact Person">
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              {/* <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="stockType" label="Material Type">
                    <Select
                      placeholder="Please select"
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => setMaterialTypeId(e)}
                    >
                      <Option value={null}></Option>
                      {materialTypes.length > 0 &&
                        materialTypes.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="lotNumber" label="Lot/Batch Number">
                    <Select
                      placeholder="Please select"
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => setLotNumberId(e)}
                    >
                      <Option value={null}></Option>
                      {lotNumbers.length > 0 &&
                        lotNumbers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.number}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={<span></span>}>
                    <Button
                      type="primary"
                      onClick={handleShowStocks}
                      loading={availableStocksLoading}
                      disabled={
                        !form.getFieldValue("stockType") ||
                        !form.getFieldValue("lotNumber")
                      }
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row> */}
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                rowClassName={(record) => (record.lineStage === "COMPLETE" ? "ant-tag-green" : "")}
                //expandable={{ expandedRowRender }}
                size="small"
                summary={(pageData) => {
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;

                  pageData.forEach(
                    ({ requestedQuantity, netWeight, grossWeight }) => {
                      totalQuantity += requestedQuantity;
                      totalNetWeight += netWeight;
                      totalGrossWeight += grossWeight;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {totalQuantity && Number(totalQuantity).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        {!["SILUETA"].includes(loggedInCustomerCode) && (
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {totalNetWeight &&
                                Number(totalNetWeight).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                        )}
                        {!["SILUETA"].includes(loggedInCustomerCode) && (
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {totalGrossWeight &&
                                Number(totalGrossWeight).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
              {!["SILUETA"].includes(loggedInCustomerCode) &&
                materialRequestLines.length > 0 && (
                  <>
                    <Divider orientation="left">Requested Stock Items</Divider>
                    <Tabs defaultActiveKey={materialRequestLines[0].key}>
                      {materialRequestLines.map((line, index) => (
                        <Tabs.TabPane
                          tab={`${line.stockType.code} / ${line.lotNumber.number} (${line.requestedItems.length})`}
                          key={index}
                        >
                          <Table
                            bordered
                            size="small"
                            rowKey="id"
                            columns={getStockItemColumns()}
                            dataSource={line.requestedItems}
                            pagination={false}
                            summary={(pageData) => {
                              let totalQuantity = 0;
                              let totalNetWeight = 0;
                              let totalGrossWeight = 0;

                              pageData.forEach(
                                ({ quantity, netWeight, grossWeight }) => {
                                  //totalQuantity += quantity ? quantity : 1;
                                  totalQuantity += quantity;
                                  totalNetWeight += netWeight;
                                  totalGrossWeight += grossWeight;
                                }
                              );

                              return (
                                <>
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell
                                      colSpan={getStockItemColumns().length - 5}
                                    >
                                      <Text strong>Total</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                      <Text strong style={{ paddingLeft: 12 }}>
                                        {/* totalQuantity */}
                                      </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                      <Text strong>
                                        {Number(totalNetWeight).toFixed(2)}
                                      </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell align="right">
                                      <Text strong>
                                        {Number(totalGrossWeight).toFixed(2)}
                                      </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={2} />
                                  </Table.Summary.Row>
                                </>
                              );
                            }}
                          />
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </>
                )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
