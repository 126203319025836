import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Tooltip,
  Typography,
  message,
  Row,
  Col,
  Statistic,
  Card,
} from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import {
  reportService,
  materialTypeService,
  lotNumberService,
  binService,
  supplierService,
  sbuService,
  zoneService,
  subLocationService,
} from "_services";
import BinWiseBalanceSearch from "./BinWiseBalanceSearch";

const StrRmBinWiseBalance = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingSummaryPdf, setLoadingSummaryPdf] = useState(false);
  const [stockTypes, setStockTypes] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stockBins, setStockBins] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const { Title } = Typography;

  useEffect(() => {
    getStockTypes();
    getLotNumbers();
    getStockBins();
    getSuppliers();
    getBusinessUnits();
    getZones();
    getSubLocations();
    // getItems();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getStockBins = () => {
    binService
      .getSuggestion("")
      .then((x) => {
        setStockBins(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getItems = () => {
    setLoading(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    setSearchQuery(searchParams);
    reportService
      .getBinWiseBalance(searchParams)
      .then((data) => {
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
          });
          //console.log("BinWise:", data.content);
          setItems(data.content);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, searchParams) => {
    setItems(results);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  const handleExportPdf = () => {
    setLoadingPdf(true);
    reportService
      .exportBinWisePdf(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Bin-Wise-Report_${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingPdf(false);
      })
      .catch((error) => {
        setLoadingPdf(false);
        message.error(error);
      });
  };

  const handleExportSummaryPdf = () => {
    setLoadingSummaryPdf(true);
    reportService
      .exportBinWiseSummaryPdf(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Bin-Wise-Summary-Report_${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingSummaryPdf(false);
      })
      .catch((error) => {
        setLoadingSummaryPdf(false);
        message.error(error);
      });
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Bin",
      dataIndex: "bin",
      key: "bin",
      width: "10%",
    });
    /* columns.push({
      title: 'Pallet',
      dataIndex: 'pallet',
      key: 'pallet',
      width: '5%',
    }); */
    columns.push({
      title: "Material Supplier",
      key: "supplier",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.supplier}>
            {record.supplier}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "20%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockTypeName}>
            {record.stockTypeName}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "12%",
    });
    columns.push({
      title: "Box",
      dataIndex: "count",
      key: "count",
      align: "right",
      width: "7%",
    });
    columns.push({
      title: "Cone",
      dataIndex: "totalCone",
      key: "totalCone",
      align: "right",
      width: "7%",
    });
    columns.push({
      title: "Net Weight(kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      width: "7%",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      width: "7%",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Bin Wise Balance</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <BinWiseBalanceSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              stockTypes={stockTypes}
              lotNumberList={lotNumberList}
              stockBins={stockBins}
              suppliers={suppliers}
              subLocations={subLocations}
              zones={zones}
              businessUnits={businessUnits}
            />
            {items && items.length > 0 && (
              <div
                style={{
                  background: "#2d3147",
                  padding: "15px",
                  marginBottom: 15,
                }}
              >
                <Row gutter={24}>
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title="Total Bins"
                        value={items.length}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title="Total Stocks(Box)"
                        value={items
                          .map((item) => item.count)
                          .reduce((prev, next) => prev + next)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title="Total Net Weight (Kg)"
                        value={Number(
                          items
                            .map((item) => item.netWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title="Total Gross Weight (Kg)"
                        value={Number(
                          items
                            .map((item) => item.grossWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title="Total Cone"
                        value={items
                          .map((item) => item.totalCone)
                          .reduce((prev, next) => prev + next)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={handleExportPdf}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FilePdfOutlined />}
                      loading={loadingPdf}
                    >
                      Export to Pdf
                    </Button>
                    <br />
                    <Button
                      type="primary"
                      onClick={handleExportSummaryPdf}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FilePdfOutlined />}
                      loading={loadingSummaryPdf}
                    >
                      Summary Pdf
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={(pageData) => {
                let totalItems = 0;
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(
                  ({ count, totalCone, netWeight, grossWeight }) => {
                    totalItems += count;
                    totalQuantity += totalCone;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                  }
                );

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={getColumns().length - 5}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>{items.length}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>{totalItems}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>{totalQuantity}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {totalNetWeight && Number(totalNetWeight).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Typography.Text strong>
                        {totalGrossWeight &&
                          Number(totalGrossWeight).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default StrRmBinWiseBalance;
