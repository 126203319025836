import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Spin,
  Space,
  InputNumber,
  DatePicker,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { materialDetailService } from "_services";
import { StockStatuses } from "_helpers";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

let itemIndex = 0;

function PrymSplit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [stockItem, setStockItem] = useState({});
  const [newItems, setNewItems] = useState([]);
  const [error, setError] = useState(null);
  const { Text } = Typography;

  useEffect(() => {
    setLoading(true);
    materialDetailService
      .getById(id)
      .then((x) => {
        //console.log("Stock: ", x.content);
        setStockItem(x.content);
        x.content.createdDate = moment(x.content.createdDate);
        x.content.preReceiptNumber =
          x.content.stockPreReceiptLine.stockPreReceipt.preReceiptNumber;
        x.content.deliveryNumber =
          x.content.issuedLine &&
          x.content.issuedLine.stockIssue &&
          x.content.issuedLine.stockIssue.issueNumber;
        onFill(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateSplitItems(newItems)) {
          setLoadingUpdate(true);
          let payload = {
            splitItems: newItems,
          };
          materialDetailService
            .split(id, payload)
            .then(() => {
              message.success("Successfully updated!");
              setLoadingUpdate(false);
              window.location.reload();
              //history.push(`${path}`.replace(":id", id));
            })
            .catch((error) => {
              setLoadingUpdate(false);
              setError(error);
            });
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const validateSplitItems = (items) => {
    let isValid = true;
    if (items && items.length >= 2) {
      const totalQuantity = items
        .map((item) => item.quantity)
        .reduce((prev, next) => prev + next);
      const totalNetWeight = items
        .map((item) => item.netWeight)
        .reduce((prev, next) => prev + next);
      const totalGrossWeight = items
        .map((item) => item.grossWeight)
        .reduce((prev, next) => prev + next);
      const uniqueNumbers = new Set(items.map((item) => item.runningNo));

      if (totalQuantity.toFixed(2) !== stockItem.quantity.toFixed(2)) {
        isValid = false;
        message.error("Total split quantity should be equal to original value");
      }
      if (totalNetWeight.toFixed(2) !== stockItem.netWeight.toFixed(2)) {
        isValid = false;
        message.error(
          "Total split NetWeight should be equal to original value"
        );
      }
      if (totalGrossWeight.toFixed(2) !== stockItem.grossWeight.toFixed(2)) {
        isValid = false;
        message.error(
          "Total split GrossWeight should be equal to original value"
        );
      }
      if (uniqueNumbers.size !== items.length) {
        isValid = false;
        message.error("Roll/Box numbers should not be duplicate.");
      }
    } else {
      isValid = false;
      message.error("Total split items should be minimum 2.");
    }
    return isValid;
  };

  const handleSplitItem = () => {
    const newItem = {};
    newItem.key = itemIndex;
    const items = [...newItems, newItem];
    setNewItems(items);
    itemIndex += 1;
  };

  const handleSaveNewItem = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    if (
      //!target.stockClass ||
      //!target.packingType ||
      !target.runningNo ||
      !target.quantity ||
      !target.netWeight ||
      !target.grossWeight
    ) {
      message.error("Please fill all the information.");
      e.target.focus();
      return;
    }
    handleSplitItem();
  };

  const handleRemoveNewItem = (newItem) => {
    const items = [...newItems];
    if (items.includes(newItem)) {
      items.splice(items.indexOf(newItem), 1);
      setNewItems(items);
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const items = [...newItems];
    const splitItems = items.map((item) => {
      const splitItem = Object.assign({}, item);
      if (splitItem.key === key) {
        splitItem[fieldName] = value;
        return splitItem;
      }
      return splitItem;
    });
    setNewItems(splitItems);
  };

  const getRowBykey = (key, newData) => {
    return (newItems || newData).filter((item) => item.key === key)[0];
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to update the changes?"
        onConfirm={handleFormSumbit}
      >
        <Button key="save" htmlType="submit" loading={loadingUpdate}>
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const newItemColumns = [
    {
      title: "Roll/Box Number",
      dataIndex: "runningNo",
      key: "runningNo",
      align: "left",
      //width: "6%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`runningNo[${record.key}]`}
            key={`runningNo_${record.key}`}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <Input
              placeholder="Roll/Box Number"
              onChange={(e) =>
                handleFieldChange(e.target.value, "runningNo", record.key)
              }
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      //width: "6%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`quantity[${record.key}]`}
            key={`quantity_${record.key}`}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Quantity"
              min={record._id ? record.receivedUnits : 0}
              onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      //width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`netWeight[${record.key}]`}
            key={`netWeight_${record.key}`}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Net Measure"
              onChange={(e) => handleFieldChange(e, "netWeight", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      //width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`grossWeight[${record.key}]`}
            key={`grossWeight_${record.key}`}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
          >
            <InputNumber
              placeholder="Gross Measure"
              onChange={(e) => handleFieldChange(e, "grossWeight", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              size="small"
              icon={<CloseOutlined />}
              onClick={() => handleRemoveNewItem(record)}
              style={{ padding: 5, fontSize: 9 }}
            ></Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveNewItem(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push(`/material-details/${id}/summary`);
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/material-details/${id}/summary`}>
                {stockItem.runningNo}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Split</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="preReceiptNumber" label="Receipt No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["stockType", "code"]} label="Stock Type">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["owner", "code"]} label="Store">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="quantity" label="Quantity">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="deliveryNumber" label="Delivery No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["lotNumber", "number"]} label="Lot Number">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["stockBin", "code"]} label="Stock Bin">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="netWeight" label="Net Measure">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="mode" label="Status">
                    <Select disabled>
                      {StockStatuses.map((t) => (
                        <Select.Option value={t.value} key={t.value}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="createdDate" label="Received Date">
                    <DatePicker disabled style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="runningNo" label="Roll/Box No:">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="grossWeight" label="Gross Measure">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
              </Row>
              {newItems.length === 0 && (
                <Button
                  style={{
                    width: "100%",
                    marginTop: 16,
                    marginBottom: 8,
                    background: "rgb(190, 200, 200)",
                  }}
                  type="dashed"
                  onClick={handleSplitItem}
                  icon={<PlusOutlined />}
                >
                  Split Item
                </Button>
              )}
              {newItems.length > 0 && (
                <Table
                  bordered
                  pagination={false}
                  columns={newItemColumns}
                  dataSource={newItems}
                  size="small"
                  summary={(pageData) => {
                    let totalQuantity = 0;
                    let totalNetWeight = 0;
                    let totalGrossWeight = 0;

                    pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                      totalQuantity += quantity;
                      totalNetWeight += netWeight;
                      totalGrossWeight += grossWeight;
                    });

                    return (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalQuantity) && totalQuantity}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalNetWeight) &&
                              Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalGrossWeight) &&
                              Number(totalGrossWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    );
                  }}
                />
              )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymSplit;
