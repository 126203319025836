import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Popconfirm,
  message,
  Tag,
  Tooltip,
  Typography,
  Form,
  Input,
  Spin,
} from "antd";
import {
  CloseOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { binLocationService, stockCheckService } from "_services";
import TabMenu from "../../TabMenu";
import { Auth } from "_helpers";
import Search from "../Search";

function SiluetaList({ match, history }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingSummaryScan, setLoadingSummaryScan] = useState(false);
  const [loadingLocationDetail, setLoadingLocationDetail] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [stockCheck, setStockCheck] = useState({});
  const [locations, setLocations] = useState([]);
  const [retrievedLocations, setRetrievedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [locationDetail, setLocationDetail] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [error, setError] = useState(null);
  const { Text } = Typography;
  const [scanForm] = Form.useForm();

  useEffect(() => {
    // If it's Full Count, we may need comment out this service call to reduce the loading time.
    /* setLoading(true);

    stockCheckService
      .getById(id)
      .then((data) => {
        setStockCheck(data.content);
        setLocations(data.content.locations);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      }); */
  }, []);

  const getLocationDetail = (expanded, record) => {
    setSelectedLocation(record);
    setLoadingLocationDetail(true);
    stockCheckService
      .getByLocationId(id, record.id)
      .then((data) => {
        setLocationDetail(data.content);
        setLoadingLocationDetail(false);
      })
      .catch((error) => {
        setLoadingLocationDetail(false);
        setError(error);
      });
  };

  const handleSearchResults = (locationData, showAll) => {
    if (showAll) {
      setLocations(retrievedLocations);
    } else {
      if (locationData) {
        const addedLocation = retrievedLocations.find(
          (item) => item.id === locationData.id
        );
        if (!addedLocation) {
          const dbLocations = [...retrievedLocations];
          dbLocations.push(locationData);
          setRetrievedLocations([...retrievedLocations, locationData]);
          // console.log('Resut:', retrievedLocations);
          setLocations([...locations, locationData]);
          /* setLocations(
            dbLocations.filter((item) => item.id === locationData.id)
          ); */
        } else {
          /* setLocations(
            retrievedLocations.filter((item) => item.id === locationData.id)
          ); */
        }
      }
    }
    /* if (locationData) {
      setLocations([...locations, locationData]);
      setLocations(
        locations.filter(
          (item) => item.stockBin.code.toLowerCase() === results.toLowerCase()
        )
      );
    } else {
      setLocations(stockCheck.locations);
    }
    setLoading(false); */
  };

  const handleDelete = (binId) => {
    binLocationService
      .removeBin(id, binId)
      .then((data) => {
        message.success("Successfully deleted!");
        document.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: ["IN_PROGRESS", "COMPLETE"].includes(record.checkStatus),
    }),
  };

  const handleVerification = () => {
    scanForm.validateFields().then((values) => {
      const payload = {
        locations: selectedRowKeys,
        checkedBy: values.checkedBy,
      };

      // console.log("Payload:", payload);
      setLoadingSummaryScan(true);
      setLoadingMessage("Summary scan is in progress...");
      stockCheckService
        .saveSummaryCountScan(id, payload)
        .then(() => {
          setLoadingSummaryScan(false);
          message.success("Stock check summary scan success!");
          setLoadingMessage("Loading...");
          window.location.reload();
        })
        .catch((error) => {
          setLoadingSummaryScan(false);
          setError(error);
          setLoadingMessage("Loading...");
        });
    });
  };

  const handleCancel = () => {
    history.push(`/stock-check/${id}/summary`);
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Link to={`${path}/add`.replace(":id", id)} key="1">
          <Button key="add">Add Locations</Button>
        </Link>
      );
    }
    if (selectedRowKeys.length > 0) {
      buttons.push(
        <Button key="scan" onClick={handleVerification}>
          Verify
        </Button>
      );
    }
    buttons.push(
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const handleLocationPrintPdf = (stockCheckLocation) => {
    setSelectedLocation(stockCheckLocation);
    setLoadingPdf(true);
    stockCheckService
      .printLocationPdf(id, stockCheckLocation.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Check-Locatioin-${stockCheckLocation.stockBin.code}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingPdf(false);
      });
  };

  const handleLocationSummaryScan = (stockCheckLocation) => {
    if (!stockCheckLocation.checkedBy) {
      message.error("Please fill Checked By");
      return;
    }

    setSelectedLocation(stockCheckLocation);

    setLoadingSummaryScan(true);
    stockCheckService
      .getByLocationId(id, stockCheckLocation.id)
      .then((data) => {
        const locationInfo = data.content;
        const openLocationCountInfo = locationInfo.counts.filter(
          (item) => item.checkStatus === "OPEN"
        );
        // console.log('LocationCount:', openLocationCountInfo);
        if (openLocationCountInfo) {
          const openCount = openLocationCountInfo[0];
          openCount.countType = "SUMMARY";
          openCount.checkStatus = "COMPLETE";
          openCount.active = 1;
          openCount.count = locationInfo.availableStocks.length;
          openCount.countedDate = moment().format("YYYY-MM-DD");
          openCount.serials = openCount.serials.map((item) => {
            return { ...item, status: "VERIFIED" };
          });
          openCount.checkedBy = stockCheckLocation.checkedBy;

          // console.log('LocationCount:', openCount);
          setLoadingSummaryScan(true);
          stockCheckService
            .createLocationCount(id, stockCheckLocation.id, openCount)
            .then(() => {
              message.success("Successfully saved!");
              window.location.reload();
            })
            .catch((error) => {
              setLoadingSummaryScan(false);
              setError(error);
            });
        } else {
          setLoadingSummaryScan(false);
        }
        /* const payload = {
          id: null,
          stockCheckLocation: { id: stockCheckLocation.id },
          countType: 'SUMMARY',
          checkStatus: 'COMPLETE',
          count: locationDetail.availableStocks.length,
          countedDate: moment().format('YYYY-MM-DD'),
          serials: locationDetail.availableStocks.map((item) => {
            return {
              id: null,
              stockItem: { id: item.id },
              adjustmentType: null,
            };
          }),
        }; */
        // console.log('Payload: ', payload);
      })
      .catch((error) => {
        setLoadingSummaryScan(false);
        setError(error);
      });
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      width: "18%",
      fixed: "left",
    });
    columns.push({
      title: "Box No:",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "4%",
      fixed: "left",
    });
    /* columns.push({
      title: 'Bin',
      dataIndex: 'stockBin',
      key: 'stockBin',
      width: '7%',
      fixed: 'left',
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    }); */
    /* columns.push({
      title: 'Status',
      dataIndex: 'mode',
      key: 'mode',
      width: '8%',
      fixed: 'left',
      render: (text) => {
        let color = '';
        if (text === 'VERIFIED') {
          color = '#87d068';
        }
        if (text === 'UNVERIFIED') {
          color = '#fa8c16';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    }); */
    /* columns.push({
      title: 'Material Type',
      dataIndex: 'stockType',
      key: 'stockType',
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    }); */
    /* columns.push({
      title: 'LOT',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    }); */
    /* columns.push({
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      width: '7%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType.supplier) {
          return (
            <Tooltip
              placeholder='topLeft'
              title={record.stockType.supplier.name}
            >
              {record.stockType.supplier.code}
            </Tooltip>
          );
        }
      },
    }); */
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "4%",
      fixed: "left",
    });
    /* columns.push({
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      align: 'right',
      width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    }); */
    /* columns.push({
      title: 'Gross Weight',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      align: 'right',
      width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    }); */
    /* columns.push({
      title: 'Adjustment',
      dataIndex: 'adjustmentType',
      key: 'adjustmentType',
      width: '8%',
      fixed: 'left',
      render: (text) => {
        let color = '';
        if (text === 'POSITIVE') {
          color = '#87d068';
        }
        if (text === 'NEGATIVE') {
          color = '#fa8c16';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    }); */

    return columns;
  };

  const expandedLocationRender = (record) => {
    return (
      <Table
        rowKey="id"
        columns={getStockItemColumns()}
        dataSource={
          locationDetail.availableStocks &&
          locationDetail.availableStocks.length > 0 &&
          locationDetail.availableStocks
        }
        pagination={false}
        loading={record.id === selectedLocation.id && loadingLocationDetail}
        summary={(pageData) => {
          let totalNetWeight = 0;
          let totalGrossWeight = 0;
          let totalItems = 0;

          pageData.forEach(({ netWeight, grossWeight }) => {
            totalNetWeight += netWeight;
            totalGrossWeight += grossWeight;
            totalItems += 1;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={1}>
                  <Text strong>Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <Text strong>{totalItems}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <Text strong>{Number(totalGrossWeight).toFixed(2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  };

  const handleFieldChange = (value, fieldName, key) => {
    const tempLocations = [...locations];
    const newLocations = tempLocations.map((item) => {
      const newItem = Object.assign({}, item);
      if (newItem.id === key) {
        newItem[fieldName] = value;
        return newItem;
      }
      return newItem;
    });
    setLocations(newLocations);
  };

  const columns = [
    {
      title: "Bin Code",
      key: "code",
      render: (text, record) => (
        <Link
          to={`/stock-check/${id}/locations/${record.id}/summary`}
          target="_blank"
        >
          {record.stockBin.code}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "checkStatus",
      key: "checkStatus",
      render: (checkStatus) => {
        let color = "";
        if (["COMPLETE"].includes(checkStatus)) {
          color = "#87d068";
        }
        if (checkStatus === "OPEN") {
          color = "#2db7f5";
        }
        if (["IN_PROGRESS"].includes(checkStatus)) {
          color = "#faad14";
        }
        return <Tag color={color}>{checkStatus}</Tag>;
      },
    },
    {
      title: "Stock Type",
      key: "stockType",
      render: (text, record) => record.stockType && record.stockType.code,
    },
    {
      title: "Lot Number",
      key: "lotNumber",
      render: (text, record) => record.lotNumber && record.lotNumber.number,
    },
    {
      title: "Available Stocks",
      dataIndex: "expectedItemQty",
      key: "expectedItemQty",
    },
    {
      title: "Checked By",
      dataIndex: "checkedBy",
      key: "checkedBy",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {record.checkStatus === "COMPLETE" && (
              <Tooltip placeholder="topLeft" title="Pdf">
                <Button
                  icon={<FilePdfOutlined />}
                  title="Download Pdf"
                  type="link"
                  style={{ marginRight: 10 }}
                  onClick={() => handleLocationPrintPdf(record)}
                  loading={record.id === selectedLocation.id && loadingPdf}
                ></Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-check">Stock Check Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockCheck.code} ::{" "}
                <Tag
                  color={`${
                    stockCheck.checkStatus === "COMPLETE"
                      ? "#87d068"
                      : stockCheck.checkStatus === "IN_PROGRESS"
                      ? "#faad14"
                      : "#2db7f5"
                  }`}
                >
                  {stockCheck.checkStatus}
                </Tag>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}/locations`.replace(":id", id)}>Locations</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="locations" id={id} />
          <Spin tip={loadingMessage} spinning={loadingSummaryScan}>
            <Search
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              stockCheckId={id}
            />
            {selectedRowKeys.length > 0 && (
              <Form
                layout="vertical"
                form={scanForm}
                name="scan"
                requiredMark={false}
              >
                <Form.Item
                  name="checkedBy"
                  label="Checked By"
                  rules={[
                    {
                      required: true,
                      message: "Member name is required.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Checked By"
                    allowClear
                    style={{ marginBottom: 15 }}
                  />
                </Form.Item>
              </Form>
            )}
            {locations.length > 0 && (
              <Table
                bordered
                loading={loading}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={locations}
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => expandedLocationRender(record),
                  onExpand: getLocationDetail,
                }}
                rowSelection={rowSelection}
              />
            )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default SiluetaList;
