import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Col,
  Row,
  Checkbox,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { binLocationService } from '_services';

function Add({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {}, []);

  const handleFormSumbit = (e) => {
    form
      .validateFields()
      .then((values) => {
        // console.log("PL:", values);
        binLocationService
          .create(values)
          .then(() => {
            message.success('Successfully created!');
            setIsLoading(false);
            history.push('.');
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push('.');
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/bin-locations'>Bin Location Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/bin-locations/add'>New Bin Location</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            type='primary'
            key='1'
            htmlType='submit'
            loading={isLoading}
            onClick={(e) => handleFormSumbit(e)}
          >
            Save
          </Button>,
          <Button key='2' htmlType='button' onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Form
            layout='vertical'
            form={form}
            name='form-create'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name='name' label='Location Name'>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                {/* <Form.Item
                  name='availableForReporting'
                  label='Available for reporting'
                  valuePropName='checked'
                >
                  <Checkbox />
                </Form.Item> */}
              </Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
