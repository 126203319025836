import {
    Button,
     Form,
    PageHeader,
    Row,
    Breadcrumb,
    Popconfirm,
    Col,
    Input,
    message,
    Spin,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { extraService } from "_services";

const UpdateViaUpload = ({ history, match }) => {
    const { receiptId } = match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [base64File, setBase64File] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [error, setError] = useState(null);

    const [form] = Form.useForm();
    const loggedInCustomerCode = Auth.getProfile().comp_code;
    console.log("Receipt Id: ", receiptId);

     const handleFormSumbit = () => {
        form
            .validateFields()
            .then((values) => {
                //console.log("Values: ", values);
                setIsLoading(true);

                const payload = {
                    customerId: values.customer.id,
                    storeId: values.store.id,
                    invoiceDate: moment(values.orderDate).format("YYYY-MM-DD"),
                    deliverFrom: values.customerAddress.id,
                    deliveryTo: values.storeAddress.id,
                    base64FileString: base64File,
                    originalFileName: originalFileName,
                    remark: values.remark,
                };

                values.invoiceDate = moment(values.orderDate).format("YYYY-MM-DD");

                extraService
                    .uploadPackingList(payload)
                    .then(() => {
                        message.success("Successfully uploaded!");
                        setIsLoading(false);
                        history.push(".");
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error);
                    });
                //}
            })
            .catch((error) => {
                //message.error(`${error}`);
            });
    };

    const onCancel = () => {
        history.push("/material-details");
    };

    const onFileChange = (e) => {
        if (e.target.files[0]) {
            setOriginalFileName(e.target.files[0].name);
            let reader = new FileReader();
            reader.onload = function (e) {
                setBase64File(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    return (
        <div>
            <PageHeader
                title={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/dashboard">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/material-details">Material Details</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Details Update via Upload</Breadcrumb.Item>
                    </Breadcrumb>
                }
                extra={[
                    <Popconfirm
                        title="Are you sure to add the changes?"
                        onConfirm={handleFormSumbit}
                        key="add-confirm"
                    >
                        <Button
                            key="1"
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Upload
                        </Button>
                    </Popconfirm>,
                    <Button key="2" htmlType="button" onClick={onCancel}>
                        Cancel
                    </Button>,
                ]}
            >
                <Page inner error={error}>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <Form
                            layout="vertical"
                            form={form}
                            name="form-create"
                            requiredMark={false}
                        >
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        name="file"
                                        label="Packing List File"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Valid excel file is required",
                                            },
                                        ]}
                                        valuePropName="file"
                                    >
                                        <Input type="file" name="file" onChange={onFileChange} allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ paddingTop: 40 }}>
                                    <>
                                        {["SILUETA"].includes(loggedInCustomerCode) && (
                                            <CSVLink
                                                key="1"
                                                data={[]}
                                                filename={"Sample-StockIn-File.csv"}
                                                headers={[
                                                    { label: "Material", key: "material" },
                                                    { label: "Material Desc", key: "materialDesc" },
                                                    { label: "Vendor Batch", key: "vendorBatch" },
                                                    { label: "Batch", key: "batch" },
                                                    { label: "Qty", key: "Qty" },
                                                    { label: "BNS Location", key: "bNSLocation" },
                                                    { label: "Location", key: "location" },
                                                    { label: "Plant", key: "plant" },
                                                    { label: "UOM", key: "uOM" },
                                                ]}
                                            >
                                                Sample Upload File
                                            </CSVLink>
                                        )}
                                        {["STR_GR"].includes(loggedInCustomerCode) && (
                                            <CSVLink
                                                key="1"
                                                data={[]}
                                                filename={"Sample-StockIn-File.csv"}
                                                headers={[
                                                    { label: "Item Code", key: "itemCode" },
                                                    { label: "Item Desc", key: "itemDesc" },
                                                    { label: "Batch", key: "batch" },
                                                    { label: "No Box", key: "noBox" },
                                                    { label: "Net Weight", key: "netWeight" },
                                                    { label: "Gross Weight", key: "grossWeight" },
                                                    { label: "Location", key: "location" },
                                                ]}
                                            >
                                                Sample Upload File
                                            </CSVLink>
                                        )}
                                    </>
                                </Col>
                                <Col span={6}></Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Spin>
                </Page>
            </PageHeader>
        </div>
    );
};

export default UpdateViaUpload;
