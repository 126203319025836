import axios from "axios";
import { Auth } from "./auth";
import { handleError, handleResponse } from "./ServiceUtil";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchWrapper = {
  get: _get,
  post: _post,
  put: _put,
  delete: _delete,
};

function _get(url, params) {
  if (params) {
    return _axios.get(url, params).then(handleResponse).catch(handleError);
  } else {
    return _axios.get(url).then(handleResponse).catch(handleError);
  }
}

function _post(url, body, params) {
  if (params) {
    return _axios
      .post(url, body, params)
      .then(handleResponse)
      .catch(handleError);
  } else {
    return _axios.post(url, body).then(handleResponse).catch(handleError);
  }
}

function _put(url, body) {
  return _axios.put(url, body).then(handleResponse).catch(handleError);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  return _axios.delete(url).then(handleResponse).catch(handleError);
}

const _axios = axios.create({
  baseURL: baseUrl,
});

// Add a request interceptor for Authorization token header
_axios.interceptors.request.use((config) => {
  if (Auth.loggedIn()) {
    config.headers.Authorization = Auth.getBearerToken();
  }
  return config;
});

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config, response } = error;
    const originalRequest = config;
    if (response && response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        axios
          .post(
            `${baseUrl}/oauth/token`,
            `grant_type=refresh_token&refresh_token=${Auth.getResetToken()}`,
            {
              auth: { username: "SL", password: "Sl43&$^fdgd*+!!@#Agdo4Ged" },
            }
          )
          .then((response) => {
            Auth.setSession(response.data);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(response.data.access_token);
          })
          .catch((error) => {
            Auth.clearSession();
            document.location.reload();
            //return Promise.reject(error);
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((access_token) => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    // If error is due to the other reasons, we just throw it back to the axios;
    return Promise.reject(error);
  }
);

const onAccessTokenFetched = (access_token) => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

/* async function handleResponse(response) {
  if (response.status === 200 || response.status === 201) return response.data;
  if (response.status === 400) {
    // Handle server-side validations
    console.log("Validation: ", response);
    throw new Error("Server-side validation error.");
  }
  throw new Error(`Bad response : ${response.status}`);
} */

/* function handleError(error) {
  console.error("API call failed. " + error);
  throw error;
  //return error;
} */
