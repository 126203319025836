import {
  Descriptions,
  Table,
  Tag,
  Tooltip,
  Typography,
  Input,
  Button,
  Space,
} from "antd";
import React, { useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Auth } from "_helpers";

const StockSelection = (props) => {
  //console.log("SelectedIds:", props.selectedIds);
  const [selectedRowKeys, setSelectedRowKeys] = useState(props.selectedIds);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedStocks, setSelectedStocks] = useState([]);
  const searchInput = useRef(null);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const { Text } = Typography;

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedItems = props.stocks.filter((item) =>
      selectedRowKeys.includes(item.id)
    );
    // console.log("SelectStocks:", selectedItems);
    setSelectedStocks(selectedItems);
    props.onSelectChange(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? dataIndex === "runningNo"
          ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
          : record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(
          () =>
            searchInput && searchInput.current && searchInput.current.select(),
          100
        );
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "8%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    /* columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    }); */
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Invoice Ref#:",
      key: "preReceiptNumber",
      width: "10%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.refNumber
        );
      },
    });
    columns.push({
      title: "Roll/Box No",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "10%",
      defaultSortOrder: "descend",
      sorter: (a, b) => parseInt(a.runningNo) < parseInt(b.runningNo),
      ...getColumnSearchProps("runningNo"),
    });
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "10%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Invoice Date",
      //dataIndex: "createdAt",
      key: "createdAt",
      width: "8%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(record.stockPreReceiptLine.stockPreReceipt.orderDate).format(
            "YYYY-MM-DD"
          )
        );
      },
      /* defaultSortOrder: "ascend",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(), */
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "8%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
      ...getColumnSearchProps("netWeight"),
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
      ...getColumnSearchProps("grossWeight"),
    });
    columns.push({
      title: "Gross (Sq.m)",
      dataIndex: "grossValueSqm",
      key: "grossValueSqm",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
      ...getColumnSearchProps("grossValueSqm"),
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });

    return columns;
  };

  return (
    <div>
      <Table
        bordered
        rowKey="id"
        size="small"
        columns={getColumns()}
        dataSource={props.stocks}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
        rowSelection={rowSelection}
        summary={(pageData) => {
          let totalQuantity = 0;
          let totalNetWeight = 0;
          let totalGrossWeight = 0;
          let totalGrossValueSqm = 0;

          pageData.forEach(
            ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
              totalQuantity += quantity;
              totalNetWeight += netWeight;
              totalGrossWeight += grossWeight;
              totalGrossValueSqm += parseFloat(
                grossValueSqm ? grossValueSqm : 0
              );
            }
          );

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={4}>
                  <Text strong>Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong style={{ paddingLeft: 12 }}>
                    {props.stocks && props.stocks.length}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong style={{ paddingLeft: 12 }}>
                    {totalQuantity}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong style={{ paddingLeft: 12 }}>
                    {!isNaN(totalNetWeight) &&
                      Number(totalNetWeight).toFixed(2)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong style={{ paddingLeft: 12 }}>
                    {!isNaN(totalGrossWeight) &&
                      Number(totalGrossWeight).toFixed(2)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong style={{ paddingLeft: 12 }}>
                    {!isNaN(totalGrossValueSqm) &&
                      Number(totalGrossValueSqm).toFixed(3)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} />
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {selectedRowKeys.length > 0 && (
        <Descriptions title="" bordered style={{ marginTop: 15 }} size="small">
          <Descriptions.Item
            label="Material Type"
            span={3}
            contentStyle={{ fontWeight: "bold" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            {selectedStocks.length > 0 && selectedStocks[0].stockType.code}
          </Descriptions.Item>
          <Descriptions.Item
            label="Material Description"
            span={3}
            contentStyle={{ fontWeight: "bold" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            {selectedStocks.length > 0 && selectedStocks[0].stockType.name}
          </Descriptions.Item>
          <Descriptions.Item
            label="Lot Number"
            span={3}
            contentStyle={{ fontWeight: "bold" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            {selectedStocks.length > 0 && selectedStocks[0].lotNumber.number}
          </Descriptions.Item>
          <Descriptions.Item
            label="# of Rolls/Box"
            span={3}
            contentStyle={{ fontWeight: "bold" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            {selectedStocks.length}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Quantity"
            span={3}
            contentStyle={{ fontWeight: "bold" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            {selectedStocks.length > 0 &&
              Number(
                selectedStocks
                  .map((item) => item.quantity)
                  .reduce((prev, next) => prev + next)
              ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item
            label="Gross Weight(Kg)"
            span={3}
            contentStyle={{ fontWeight: "bold" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            {selectedStocks.length > 0 &&
              Number(
                selectedStocks
                  .map((item) => item.grossWeight)
                  .reduce((prev, next) => prev + next)
              ).toFixed(2)}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
};

export default StockSelection;
