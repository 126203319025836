import { Spin, Space, Input } from "antd";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import Page from "_components/Page";
import stock from "img/dasboard-weight/stock.svg";
import net from "img/dasboard-weight/net.svg";
import gross from "img/dasboard-weight/gross.svg";
import total from "img/dasboard-weight/total.svg";
import {
  materialRequestService,
  reportService,
  sbuService,
  zoneService,
} from "_services";
import NumberInfo from "./NumberInfo";
import { Auth } from "_helpers";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ReTooltip,
  Legend as ReLegend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import timeClock from "../../../img/dasboard-weight/clock.svg";
import "../../../layouts/layout.scss";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function SiluetaHome() {
  const [loading, setLoading] = useState(false);
  const [loadingLocationSummary, setLoadingLocationSummary] = useState(false);
  const [loadingZones, setLoadingZones] = useState(false);
  const [loadingPendingMRNs, setLoadingPendingMRNs] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingAgins, setLoadingAgings] = useState(false);
  const [locationSummary, setLocationSummary] = useState([]);
  const [zones, setZones] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [pendingMRNs, setPendingMRNs] = useState([]);
  const [items, setItems] = useState([]);
  const [locationAgins, setLocationAgins] = useState([]);
  const [zoneAgins, setZoneAgins] = useState([]);
  const [error, setError] = useState(null);
  const { Search } = Input;
  const locationSummaryTitles = [
    "boxs",
    "stock",
    "gross",
    "total_cones",
    "gross",
    "total_cones2",
    "boxs2",
    "stock2",
  ];
  const locationSummaryImages = [stock, net, gross, total];

  // set options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  const [barOptions, setBarOptions] = useState({
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
      },
      scales: {
        xAxes: [
          {
            barThickness: 6, // number (pixels) or 'flex'
            maxBarThickness: 8, // number (pixels)
          },
        ],
      },
    },
  });

  const lineData = [
    {
      date: "2022-10-26",
      in: 4000,
      out: 2400,
    },
    {
      date: "2022-10-24",
      in: 3000,
      out: 1398,
    },
    {
      date: "2022-10-20",
      in: 2000,
      out: 9800,
    },
    {
      date: "2022-10-19",
      in: 2780,
      out: 3908,
    },
    {
      date: "2022-10-18",
      in: 1890,
      out: 4800,
    },
    {
      date: "2022-10-15",
      in: 2390,
      out: 3800,
    },
    {
      date: "2022-10-14",
      in: 3490,
      out: 4300,
    },
  ];
  // const COLORS = ['#00C49F',
  //   '#0088FE', '#FFBB28', '#FF8042', '#e30909'];
  const COLORS = ["#5ea26c", "#47739f", "#b7b443", "#c2714e", "#a64153"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  useEffect(() => {
    setLoadingZones(true);
    zoneService
      .search("")
      .then((data) => {
        setZones(data.content);
        setLoadingZones(false);
      })
      .catch((error) => {
        setLoadingZones(false);
        setError(error);
      });

    /* companyService.getSuggestion('?companyType=STORE').then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === 'WAREHOUSE'
          );
          getItems(storeLocationAddresses.map((item) => item.id).join());
        });
      }
    }); */

    /* const dateRangeForStockInOut = `?fromDate=${encodeURIComponent(
      moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
    )}&toDate=${moment().format('YYYY-MM-DD')}`;
    reportService
      .getStockInOutSummary(dateRangeForStockInOut)
      .then((data) => {
        if (data.content.length > 0) {
          const groupByDate = data.content.reduce((prev, next) => {
            prev[next.invoiceDate] = [...(prev[next.invoiceDate] || []), next];
            return next;
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      }); */

    getPendingMaterialRequests();
    getAgins();
  }, []);

  const getItems = (storeLocationIds) => {
    let netWeights = [];
    let stockTypes = [];
    setLoadingItems(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    searchParams += `fromDate=${encodeURIComponent(
      moment("2021-01-01").format("YYYY-MM-DD")
    )}&toDate=${moment().format(
      "YYYY-MM-DD"
    )}&storeLocations=${storeLocationIds}`;
    reportService
      .getAvailableStocks(searchParams)
      .then((data) => {
        /* const groupByCategory = chain(data.content)
                .groupBy("materialSupplierCode").map(item => item.length).value();
                console.log("ASt:", groupByCategory); */
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
            stockTypes.push(item.stockType);
            netWeights.push(item.netWeight);
          });
          setDataChart({
            labels: stockTypes,
            datasets: [
              {
                label: "Net Weight (Kg)",
                backgroundColor: "rgb(255,140,0)",
                borderColor: "rgb(255,140,0)",
                data: netWeights,
                barPercentage: 0.5,
                barThickness: 12,
                maxBarThickness: 8,
                minBarLength: 2,
              },
            ],
          });
          setItems(data.content);
        }

        setLoadingItems(false);
      })
      .catch((error) => {
        setLoadingItems(false);
      });
  };

  const getPendingMaterialRequests = () => {
    const searchParams =
      "?orderStages=OPEN,VERIFICATION_INPROGRESS,VERIFIED,VERIFICATION_FAILED,IN_PROGRESS";

    setLoadingPendingMRNs(true);
    materialRequestService
      .searchSummary(`${searchParams}`)
      .then((data) => {
        setPendingMRNs(data.content);
        setLoadingPendingMRNs(false);
      })
      .catch((error) => {
        setLoadingPendingMRNs(false);
      });
  };

  const getAgins = () => {
    setLoadingAgings(true);
    zoneService
      .searchAging("")
      .then((data) => {
        setZoneAgins(data.content);
        setLoadingAgings(false);
      })
      .catch((error) => {
        setLoadingAgings(false);
      });
  };

  return (
    <Page className="dashboard" error={error}>
      <Spin tip="Loading..." spinning={loading}>
        <div className="wrapper">
          <div className="widgets">
            <Spin tip="Loading..." spinning={loadingZones}>
              <div className="widget">
                {zones.map((summary, i) => {
                  return (
                    <NumberInfo
                      key={summary.id}
                      title={summary.name}
                      categoryTitle="Total Items"
                      categoryTitle1="Total Quantity"
                      gap={8}
                      totalItems={summary.totalStocks}
                      numberTitle={locationSummaryTitles[i]}
                      iconImage={total}
                      totalQuantity={Number(summary.totalQuantity).toFixed(2)}
                    />
                  );
                })}
              </div>
            </Spin>
          </div>

          {/* <div className="chart">
            <h2 className="h2title">
              <div>Delivery Progress</div>
              <div className="leganProg">
                <div>
                  <span />
                  Open
                </div>
                <div>
                  <span />
                  Verification
                </div>
                <div>
                  <span />
                  Verified
                </div>
                <div>
                  <span />
                  InProgress
                </div>
              </div>
            </h2>
            <Spin tip="Loading..." spinning={loadingPendingMRNs}>
              <div className="deliChart">
                {pendingMRNs.map((mrn) => {
                  if (mrn.orderStage === "OPEN") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="Open">
                          <div />
                        </div>
                        <div toolTip="Verification">
                          <div />
                        </div>
                        <div toolTip="Verified">
                          <div />
                        </div>
                        <div toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  } else if (mrn.orderStage === "VERIFICATION_INPROGRESS") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="open">
                          <div />
                        </div>
                        <div className="verification" toolTip="Verification">
                          <div />
                        </div>
                        <div toolTip="Verified">
                          <div />
                        </div>
                        <div toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  } else if (mrn.orderStage === "VERIFIED") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="Open">
                          <div />
                        </div>
                        <div className="verification" toolTip="Verification">
                          <div />
                        </div>
                        <div className="verified" toolTip="Verified">
                          <div />
                        </div>
                        <div toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  } else if (mrn.orderStage === "IN_PROGRESS") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="Open">
                          <div />
                        </div>
                        <div className="verification" toolTip="Verification">
                          <div />
                        </div>
                        <div className="verified" toolTip="Verified">
                          <div />
                        </div>
                        <div className="inprogress" toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </Spin>
          </div> */}
          {/* <div className="analysis">
            <h2>Material Weights</h2>
            <Spin tip="Loading..." spinning={loadingItems}>
              <div>
                {items.length > 0 && <Bar data={dataChart} options={options} />}
              </div>
            </Spin>
          </div> */}
          {/* <div className="agin">
            <h2>Material Ageing (Days)</h2>
            <Spin tip="Loading..." spinning={loadingAgins}>
              {zoneAgins.map((aging, i) => {
                const stockAgingData = [
                  { name: "<=90", value: aging.ageLt90 },
                  { name: "90-180", value: aging.age90To180 },
                  { name: "180-270", value: aging.age180To270 },
                  { name: "270-360", value: aging.age270To360 },
                  { name: "360<", value: aging.ageGt360 },
                ];
                return (
                  <div className="aginChart">
                    <h1>{aging.name}</h1>
                    <ResponsiveContainer>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={stockAgingData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {stockAgingData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                );
              })}
            </Spin>
          </div> */}
          {/* <div className="delivery">
            <h2>In Out</h2>
            <div>
              <ResponsiveContainer>
                <LineChart
                  data={lineData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <ReTooltip />
                  <ReLegend />
                  <Line
                    type="monotone"
                    dataKey="in"
                    stroke="rgb(100, 234, 145)"
                    activeDot={{ r: 8 }}
                    strokeWidth={3}
                  />
                  <Line
                    type="monotone"
                    dataKey="out"
                    stroke="rgb(246, 152, 153)"
                    strokeWidth={3}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div> */}
        </div>
      </Spin>
    </Page>
  );
}

export default SiluetaHome;
