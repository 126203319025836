import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Table,
  Tag,
  Typography,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { materialDetailService } from "_services";
import MaterailDetailSearch from "./Search";
import {
  BarcodeOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Auth } from "_helpers";
import { CSVLink } from "react-csv";

const PrymList = ({ history, match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingBarcodes, setLoadingBarcodes] = useState(false);
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalGrossMeasure, setTotalGrossMeasure] = useState(0);
  const [searchParams, setSearchParams] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const { Text } = Typography;

  useEffect(() => {
    //getItems(pageNumber, pageSize);
  }, []);

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    materialDetailService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        const grossMeasures = Object.values(data.pagination.totalGross);
        const grossMeasureSum =
          grossMeasures.length > 0 &&
          grossMeasures.reduce((prev, next) => prev + next);
        setTotalGrossMeasure(grossMeasureSum);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const handleSearchResults = (results, pagination, searchParams) => {
    setItems(results);
    if (pagination) {
      setTotalRecords(pagination.totalRecords);
      const grossMeasures = Object.values(pagination.totalGross);
      const grossMeasureSum =
        grossMeasures.length > 0 &&
        grossMeasures.reduce((prev, next) => prev + next);
      setTotalGrossMeasure(grossMeasureSum);
    }
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    //console.log("Page ChANGE:", pageNumber, ", pageSize:", pageSize);
    getItems(pageNumber, pageSize);
  };

  const onSelectChange = (selectedRowKeys) => {
    //console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedItems(
      items
        .filter((item) => selectedRowKeys.includes(item.id))
        .map((item) => ({
          id: item.id,
          preReceiptNumber:
            item.stockPreReceiptLine &&
            item.stockPreReceiptLine.stockPreReceipt &&
            item.stockPreReceiptLine.stockPreReceipt.preReceiptNumber,
          packSerial: item.packSerialNo,
          runningNo: item.runningNo,
          lotNumber: item.lotNumber.number,
          stockType: item.stockType.code,
          quantity: item.quantity,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
          grossValueSqm: item.grossValueSqm,
          uom: item.measurementType && item.measurementType.name,
        }))
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
    /* selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ], */
  };

  /* const handleDelete = (id) => {
    materialDetailService
      .delete(id)
      .then((x) => {
        getItems(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  const handleBinTransfer = () => {
    const stockItems = items.filter(
      (item) => selectedRowKeys.includes(item.id) && item.mode === "AVAILABLE"
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/bin-transfer`,
        store: stockItems[0].owner,
        storeLocation: stockItems[0].stockBin.storeLocation,
        stockItems: stockItems,
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  };

  const handleMaterialTypeTransfer = () => {
    const stockItems = items.filter(
      (item) => selectedRowKeys.includes(item.id) && item.mode === "AVAILABLE"
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/material-type-transfer`,
        stockItems: stockItems,
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  };

  const handleLotNumberTransfer = () => {
    const stockItems = items.filter(
      (item) => selectedRowKeys.includes(item.id) && item.mode === "AVAILABLE"
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/lot-transfer`,
        stockItems: stockItems,
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  };

  const handleGenerateBarcodes = () => {
    if (selectedRowKeys.length > 0) {
      const payload = {
        stockItems: selectedRowKeys.map((item) => ({
          id: item,
        })),
      };
      //console.log("Barcode PL:", payload);
      setLoadingBarcodes(true);
      materialDetailService
        .generateAndDownloadBarcodes(payload)
        .then((response) => {
          /* var blob=new Blob([response], {type:"application/pdf"});
          var link=document.createElement('a');
          link.href=window.URL.createObjectURL(blob);
          link.download="Report_"+new Date()+".pdf";
          link.click(); */

          const url = window.webkitURL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Barcodes_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoadingBarcodes(false);
        })
        .catch((error) => {
          setLoadingBarcodes(false);
          message.error(error);
        });
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    if (selectedRowKeys.length > 0) {
      buttons.push(
        <span style={{ marginLeft: 8 }}>
          Selected {selectedRowKeys.length} items
        </span>
      );
    }
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <CSVLink
          key="download"
          data={selectedItems}
          filename={"stock-details.csv"}
          headers={[
            { label: "Invoice Number", key: "preReceiptNumber" },
            { label: "Item", key: "stockType" },
            { label: "Number", key: "runningNo" },
            { label: "Lot", key: "lotNumber" },
            { label: "UOM", key: "uom" },
            { label: "Qty", key: "quantity" },
            { label: "Net Weight (Kg)", key: "netWeight" },
            { label: "Gross Weight (Kg)", key: "grossWeight" },
            { label: "SQU", key: "grossValueSqm" },
          ]}
        >
          <Button
            icon={<DownloadOutlined />}
            disabled={selectedRowKeys.length === 0}
          ></Button>
        </CSVLink>
      );
      /* buttons.push(
        <Button
          key="update"
          icon={<UploadOutlined />}
          href={`${path}/update-via-upload`}
          loading={loadingBarcodes}
        ></Button>
      ); */
      /* buttons.push(
        <Button
          key="barcode"
          icon={<BarcodeOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={handleGenerateBarcodes}
          loading={loadingBarcodes}
        ></Button>
      ); */
      /* buttons.push(
        <Button
          key="bin-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleBinTransfer}
        >
          Bin Transfer
        </Button>
      ); */
      buttons.push(
        <Button
          key="material-type-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleMaterialTypeTransfer}
        >
          MT Transfer
        </Button>
      );
      buttons.push(
        <Button
          key="lotNumber-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleLotNumberTransfer}
        >
          Lot Transfer
        </Button>
      );
    }
    return buttons;
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return (
          <>
            <Link to={`/material-details/${record.id}/summary`}>
              {record.stockType.code}
            </Link>
            <Input value={record.id} hidden />
          </>
        );
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "R/B #",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "5%",
    });
    columns.push({
      title: "Receipt#",
      key: "preReceiptNumber",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockPreReceiptLine &&
            record.stockPreReceiptLine.stockPreReceipt &&
            record.stockPreReceiptLine.stockPreReceipt.refNumber}>
            {record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.refNumber}
          </Tooltip>
        );
      },
      /* render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.refNumber
        );
      }, */
    });
    columns.push({
      title: "Delivery#",
      key: "deliveryNumber",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.issuedLine &&
            record.issuedLine.stockIssue &&
            record.issuedLine.stockIssue.issueNumber}>
            {record.issuedLine &&
          record.issuedLine.stockIssue &&
          record.issuedLine.stockIssue.issueNumber}
          </Tooltip>
        );
      },
      render: (text, record) => {
        return (
          record.issuedLine &&
          record.issuedLine.stockIssue &&
          record.issuedLine.stockIssue.issueNumber
        );
      },
    });
    columns.push({
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      width: "6%",
      render: (text, record) => {
        return record.stockBin.subLocation.zone.name;
      },
    });
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "6%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "8%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Net (Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross (Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross (Sq.m)",
      dataIndex: "grossValueSqm",
      key: "grossValueSqm",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(3);
      },
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "4%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <MaterailDetailSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
            loggedInCustomerCode={loggedInCustomerCode}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            dataSource={items}
            rowSelection={rowSelection}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000, 1500],
              showSizeChanger: true,
              onChange: handlePaginateChange,
            }}
            summary={(pageData) => {
              let totalQuantity = 0;
              let totalNetWeight = 0;
              let totalGrossWeight = 0;
              let totalGrossSqm = 0;

              pageData.forEach(
                ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                  totalGrossSqm += grossValueSqm;
                }
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={getColumns().length - 5}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text strong style={{ paddingLeft: 12 }}>
                        {totalRecords}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      {!isNaN(totalQuantity) &&
                        Number(totalQuantity).toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      {!isNaN(totalNetWeight) &&
                        Number(totalNetWeight).toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      {!isNaN(totalGrossWeight) &&
                        Number(totalGrossWeight).toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text strong>
                        {!isNaN(totalGrossSqm) &&
                          Number(totalGrossSqm).toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default PrymList;
