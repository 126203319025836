import { CloseOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  Tag,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { materialDetailService, lotNumberService } from "_services";

const PrymEditLotNumber = ({ history, location }) => {
  const { stockItems } = location;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(stockItems);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumbers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }, []);

  const handleRemove = (item) => {
    const stockItems = [...items];
    if (stockItems.includes(item)) {
      stockItems.splice(stockItems.indexOf(item), 1);
      setItems(stockItems);
    }
  };

  const onCancel = () => {
    history.push(".");
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!items || items.length === 0) {
          message.error("No valid stock items available");
        } else {
          values.stockItems = items.map((item) => ({ id: item.id }));
          //console.log("Values: ", JSON.stringify(values));
          setLoading(true);
          materialDetailService
            .lotNumberTransfer(values)
            .then(() => {
              setLoading(false);
              message.success("Lot Number transfer success!!");
              history.push(".");
            })
            .catch((error) => {
              setLoading(false);
              message.error(`${error}`);
            });
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const columns = [
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return record.stockType.code;
      },
    },
    {
      title: "Description",
      key: "stockTypeName",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    },
    {
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record) => {
        return record.lotNumber.number;
      },
    },
    {
      title: "Invoice No:",
      key: "preReceiptNumber",
      width: "10%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber
        );
      },
    },
    {
      title: "Rec. Date",
      key: "receivedDate",
      dataIndex: "receivedDate",
      width: "9%",
    },
    /* {
            title: "Prod Date",
            key: "productionDate",
            dataIndex: "productionDate",
            width: "9%",
        }, */
    {
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "8%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    },
    {
      title: "Box No:",
      dataIndex: "runningNo",
      key: "runningNo",
      width: "%3",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "7%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    },
    {
      title: "Net (Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "7%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    },
    {
      title: "Gross (Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "7%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    },
    {
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "8%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "",
      key: "operation",
      width: "3%",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to remove?"
            onConfirm={() => handleRemove(record)}
          >
            <Button size="small" icon={<CloseOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];

  const getActionButtons = () => {
    let buttons = [];

    buttons.push(
      <Popconfirm
        key="transfer"
        title="Are you sure to update the changes?"
        onConfirm={handleFormSumbit}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Trasfer
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Lot Number Transfer</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["lotNumber", "id"]}
                  label="Lot Number"
                  rules={[
                    { required: true, message: "Lot Number is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Please select"
                  >
                    {lotNumbers.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.number}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={18}></Col>
            </Row>
          </Form>
          <Table
            bordered
            rowKey="id"
            pagination={false}
            columns={columns}
            dataSource={items}
            size="small"
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default PrymEditLotNumber;
