import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Spin,
  InputNumber,
  DatePicker,
  Popconfirm,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { materialDetailService } from "_services";
import { Auth, StockStatuses } from "_helpers";

function PrymSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [stockItem, setStockItem] = useState({});
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  useEffect(() => {
    setLoading(true);
    materialDetailService
      .getById(id)
      .then((x) => {
        //console.log("Stock: ", x.content);
        setStockItem(x.content);
        x.content.createdDate = moment(x.content.createdDate);
        x.content.preReceiptNumber =
          x.content.stockPreReceiptLine.stockPreReceipt.preReceiptNumber;
        x.content.deliveryNumber =
          x.content.issuedLine &&
          x.content.issuedLine.stockIssue &&
          x.content.issuedLine.stockIssue.issueNumber;
        onFill(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingUpdate(true);
        let payload = {
          quantity: values.quantity,
          netWeight: values.netWeight,
          grossWeight: values.grossWeight,
          runningNo: values.runningNo,
        };
        materialDetailService
          .update(id, payload)
          .then(() => {
            message.success("Successfully updated!");
            setLoadingUpdate(false);
            history.push(`${path}`.replace(":id", id));
          })
          .catch((error) => {
            setLoadingUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/material-details");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Button
          key="split"
          type="button"
          href={`/material-details/${id}/split`}
        >
          Split
        </Button>
      );
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
        >
          <Button key="save" htmlType="submit" loading={loadingUpdate}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockItem.runningNo}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="preReceiptNumber" label="Receipt No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["stockType", "code"]} label="Stock Type">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["owner", "code"]} label="Store">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="quantity" label="Quantity">
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="deliveryNumber" label="Delivery No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["lotNumber", "number"]} label="Lot Number">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["stockBin", "code"]} label="Stock Bin">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="netWeight" label="Net Measure">
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="mode" label="Status">
                    <Select disabled>
                      {StockStatuses.map((t) => (
                        <Select.Option value={t.value} key={t.value}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="createdDate" label="Received Date">
                    <DatePicker disabled style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item name="grossWeight" label="Gross Measure">
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item name="runningNo" label="Roll/Box No:">
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymSummary;
