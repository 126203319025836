import {
  Button,
  Divider,
  Form,
  InputNumber,
  Input,
  Progress,
  Select,
  Space,
  Spin,
  Modal,
  message,
  Table,
  Tabs,
  Tag,
  Typography,
  Tooltip,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { materialDetailService } from "_services";
import { Auth } from "_helpers";

const StockItemUpdateTableForm = ({
  value,
  onChange,
  status,
  stockClass,
  onStockItemChange,
  materialTypes,
  lotNumbers,
  measurementTypes,
  loggedInCustomer,
  plants,
}) => {
  //console.log("Values: ", value);
  const [loading, setLoading] = useState(false);
  const [stockItemLoading, setStockItemLoading] = useState(false);
  const [loadingUpdateStocks, setLoadingUpdateStocks] = useState(false);
  const [loadingBarcodes, setLoadingBarcodes] = useState(false);
  const [data, setData] = useState(value);
  //const [receivedTotalQuantity, setReceivedTotalQuantity] = useState(null);
  const [receivedTotalNetMeasure, setReceivedTotalNetMeasure] = useState(null);
  const [receivedTotalGrossMeasure, setReceivedTotalGrossMeasure] =
    useState(null);
  const [showUpdateStocks, setShowUpdateStocks] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const hasSelected = selectedRowKeys.length > 0;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const precision = ["PRYM"].includes(Auth.getProfile().comp_code) ? 3 : 2;

  const [form] = Form.useForm();
  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    /* materialTypeService.getSuggestion().then((x) => {
        setMaterialTypes(x.content);
      });
      lotNumberService.getSuggestion().then((x) => {
        setLotNumbers(x.content);
      });
      stockClassificationService.getSuggestion().then((x) => {
        setStockClasses(x.content);
      });
      packingTypeService.getSuggestion().then((data) => {
        setPackingTypes(data.content);
      });
      measurementTypeService.getSuggestion().then((data) => {
        setMeasurementTypes(data.content);
      }); */

    const fetchStocks = async () => {
      setStockItemLoading(true);
      for (const item of data) {
        const result = await materialDetailService.getSuggestion(
          `?purchaseOrderLineId=${item.id}`
        );
        item.stockItems = result.content;
        item.receivedNetMeasure =
          item.stockItems.length > 0
            ? item.stockItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next)
            : 0;
        item.receivedGrossMeasure =
          item.stockItems.length > 0
            ? item.stockItems
                .map((item) => item.grossWeight)
                .reduce((prev, next) => prev + next)
            : 0;
      }
      setStockItemLoading(false);
      setData(data);
    };

    fetchStocks();
  }, []);

  const handleStockItemFieldChange = (value, fieldName, key) => {
    //const newData = [...data];
    //const target = getRowByKey(key, newData);
    let target = data
      .flatMap((item) => item.stockItems)
      .find((item) => item.id === key);

    if (target) {
      /* if (["runningNo", "quantity"].includes(fieldName)) {
        target[fieldName] = value.target.value;
      } else {
        target[fieldName] = value;
      } */
      if (typeof value === "object" && value !== null) {
        target[fieldName] = value.target.value;
      } else {
        target[fieldName] = value;
      }
      //console.log("Value:", Object.keys(value) ? value.target.value : value);
      //console.log("Target:", target);
      //setData([...data]);
      //onChange(data);
      const totalStockItems = data.flatMap((item) => item.stockItems);
      //console.log("Data: ", data);
      for (const item of data) {
        item.receivedNetMeasure =
          item.stockItems.length > 0 &&
          item.stockItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
        item.receivedGrossMeasure =
          item.stockItems.length > 0 &&
          item.stockItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);
      }
      /* if (fieldName === "grossWeight") {
        setReceivedTotalGrossMeasure(
          totalStockItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next)
        );
      } else if (fieldName === "netWeight") {
        setReceivedTotalNetMeasure(
          totalStockItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next)
        );
      } */
      // console.log("Stocks:", totalStockItems);
      onStockItemChange(totalStockItems);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      //disabled: record.mode !== "AVAILABLE",
    }),
  };

  const getModalContent = () => {
    return (
      <Form form={form} name="stock-update-form" layout="inline">
        <Form.Item
          name="netWeight"
          /* rules={[
            {
              required:
                loggedInCustomer &&
                ["PRYM", "SILUETA"].includes(loggedInCustomer.code)
                  ? false
                  : true,
              message: "Please input valid Net Weight!",
            },
          ]} */
        >
          <InputNumber
            min={0}
            placeholder="Net Measure"
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item
          name="grossWeight"
          /* rules={[
            { required: true, message: "Please input valid Gross Weight!" },
          ]} */
        >
          <InputNumber
            min={0}
            placeholder="Gross Measure"
            style={{ width: 200 }}
          />
        </Form.Item>
        {loggedInCustomer && ["PRYM"].includes(loggedInCustomer.code) && (
          <Form.Item name="grossValueSqm" style={{ marginTop: 10 }}>
            <Input
              min={1}
              placeholder="Gross Measure"
              style={{ width: 200 }}
              addonAfter="Sq.m"
            />
          </Form.Item>
        )}
        {loggedInCustomer &&
          ["PRYM", "SILUETA"].includes(loggedInCustomer.code) && (
            <Form.Item name="firstRollNumber" style={{ marginTop: 10 }}>
              <InputNumber
                min={1}
                placeholder="First Roll Number"
                style={{ width: 200 }}
              />
            </Form.Item>
          )}
        {loggedInCustomer && ["SILUETA"].includes(loggedInCustomer.code) && (
          <Form.Item name="rollNumberSuffix" style={{ marginTop: 10 }}>
            <Input
              placeholder="Roll/Box Number Suffix"
              style={{ width: 200 }}
            />
          </Form.Item>
        )}
        {loggedInCustomer && ["PRYM"].includes(loggedInCustomer.code) && (
          <Form.Item name="quantity" style={{ marginTop: 10 }}>
            <InputNumber min={1} placeholder="Units" style={{ width: 200 }} />
          </Form.Item>
        )}
        {loggedInCustomer && ["SILUETA"].includes(loggedInCustomer.code) && (
          <>
            <Form.Item name="palletNo" style={{ marginTop: 10 }}>
              <Input placeholder="Pallet #" style={{ width: 200 }} />
            </Form.Item>
            <Form.Item name="plant" style={{ marginTop: 10 }}>
              <Select
                placeholder="Plant"
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {plants.length > 0 &&
                  plants.map((t) => (
                    <Option value={t.id} key={t.id}>
                      {t.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    );
  };

  const handleAddEditStockDetails = () => {
    form.validateFields().then((values) => {
      selectedRowKeys.forEach((key, index) => {
        let target = data
          .flatMap((item) => item.stockItems)
          .find((item) => item.id === key);

        if (target) {
          if (values.netWeight) {
            target.netWeight = values.netWeight;
          }
          if (values.grossWeight) {
            target.grossWeight = values.grossWeight;
          }
          if (values.firstRollNumber) {
            target.runningNo = values.firstRollNumber + index;
          }
          if (values.rollNumberSuffix) {
            target.runningNo = `${target.runningNo}${values.rollNumberSuffix}`;
          }
          if (values.grossValueSqm) {
            target.grossValueSqm = values.grossValueSqm;
          }
          if (values.quantity) {
            target.quantity = values.quantity;
          }
          if (values.plant) {
            target.plant = { id: values.plant };
          }
          if (values.palletNo) {
            target.palletNo = values.palletNo;
          }
        }
        //console.log("Data:", target);
      });
      //console.log("Data:",  target);
      const totalStockItems = data.flatMap((item) => item.stockItems);
      for (const item of data) {
        item.receivedNetMeasure = item.stockItems
          .map((item) => item.netWeight)
          .reduce((prev, next) => prev + next);
        item.receivedGrossMeasure = item.stockItems
          .map((item) => item.grossWeight)
          .reduce((prev, next) => prev + next);
      }
      /* setReceivedTotalGrossMeasure(
        totalStockItems
          .map((item) => item.grossWeight)
          .reduce((prev, next) => prev + next)
      );
      setReceivedTotalNetMeasure(
        totalStockItems
          .map((item) => item.netWeight)
          .reduce((prev, next) => prev + next)
      ); */
      onStockItemChange(totalStockItems);
      setSelectedRowKeys([]);
      form.resetFields();
      setShowUpdateStocks(false);
    });
  };

  const handleUpdateStockDetails = () => {
    let stockItems = [];
    const totalStockItems = data.flatMap((item) => item.stockItems);
    for (const key of selectedRowKeys) {
      let target = totalStockItems.find((item) => item.id === key);
      if (target) {
        stockItems.push(target);
      }
    }

    const payload = {
      stockItems: stockItems
        .filter(
          (item) =>
            item.quantity ||
            item.netWeight ||
            item.grossWeight ||
            item.runningNo
        )
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
          runningNo: item.runningNo ? item.runningNo : null,
          grossValueSqm: item.grossValueSqm ? item.grossValueSqm : null,
          plant: item.plant
            ? !!item.plant.id
              ? item.plant
              : { id: item.plant }
            : null,
          palletNo: item.palletNo ? item.palletNo : null,
        })),
    };
    setLoadingUpdateStocks(true);
    //console.log("PL: ", payload);
    materialDetailService
      .bulkUpdate(payload)
      .then((data) => {
        message.success("Successfully updated the stock item details.");
        setLoadingUpdateStocks(false);
        setSelectedRowKeys([]);
      })
      .catch((error) => {
        setLoadingUpdateStocks(false);
        setSelectedRowKeys([]);
        message.error(error);
      });
  };

  const handleGenerateBarcodes = () => {
    let stockItems = [];
    const totalStockItems = data.flatMap((item) => item.stockItems);
    for (const key of selectedRowKeys) {
      let target = totalStockItems.find((item) => item.id === key);
      if (target) {
        stockItems.push(target);
      }
    }
    if (stockItems.length > 0) {
      const payload = {
        stockItems: stockItems.map((item) => ({
          id: item.id,
        })),
      };
      setLoadingBarcodes(true);
      materialDetailService
        .generateAndDownloadBarcodes(payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          //const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Barcodes_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoadingBarcodes(false);
        })
        .catch((error) => {
          setLoadingBarcodes(false);
          message.error(error);
        });
    }
  };

  const getColumns = () => {
    let columns = [];
    /* {
        title: "Stock Class",
        dataIndex: "stockClass",
        key: "stockClass",
        width: "10%",
        render: (text, record) => {
          return (
            <>
              <Select
                value={record.stockClass && record.stockClass.id}
                placeholder="Please select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(e) => handleFieldChange(e, "stockClass", record.key)}
                disabled={status !== "OPEN"}
              >
                {stockClasses.length > 0 &&
                  stockClasses.map((t) => (
                    <Option value={t.id} key={t.id}>
                      {t.name}
                    </Option>
                  ))}
              </Select>
              
            </>
          );
        },
      }, */
    /* {
        title: "Packing Type",
        dataIndex: "packingType",
        key: "packingType",
        width: "10%",
        render: (text, record) => {
          return (
            <Select
              value={record.packingType && record.packingType.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "packingType", record.key)}
              disabled={status !== "OPEN"}
            >
              {packingTypes.length > 0 &&
                packingTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          );
        },
      }, */
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return (
          <>
            <Select
              value={record.stockType && record.stockType.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              disabled={status !== "OPEN"}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
            {status !== "OPEN" && (
              <Progress
                size="small"
                percent={parseInt(
                  record.goodsReceivedLines &&
                    record.goodsReceivedLines.length > 0 &&
                    (record.goodsReceivedLines
                      .map((obj) => obj.acceptedAtQA)
                      .reduce((prev, next) => prev + next) /
                      record.quantity) *
                      100
                )}
              />
            )}
          </>
        );
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lotNumber.number.localeCompare(b.lotNumber.number),
      render: (text, record) => {
        return (
          <Select
            value={record.lotNumber && record.lotNumber.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={status !== "OPEN"}
          >
            {lotNumbers.length > 0 &&
              lotNumbers.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.number}
                </Option>
              ))}
          </Select>
        );
      },
    });
    columns.push({
      title: "Invoiced Units",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            min={0}
            placeholder="Units"
            disabled={status !== "OPEN"}
          />
        );
      },
    });
    if (status !== "OPEN") {
      columns.push({
        title: "Received Units",
        key: "totalAcceptedQA",
        render: (text, record) => {
          return (
            record.goodsReceivedLines &&
            record.goodsReceivedLines.length > 0 &&
            record.goodsReceivedLines
              .map((item) => item.acceptedAtQA)
              .reduce((prev, next) => prev + next)
          );
        },
      });
    }
    columns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      width: "10%",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            placeholder="Net Measure"
            disabled={status !== "OPEN"}
          />
        );
      },
    });
    columns.push({
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      width: "10%",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            placeholder="Gross Measure"
            disabled={status !== "OPEN"}
          />
        );
      },
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.measurementType && record.measurementType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={status !== "OPEN"}
          >
            {measurementTypes.length > 0 &&
              measurementTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.name}
                </Option>
              ))}
          </Select>
        );
      },
    });
    columns.push({
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              size="small"
              icon={<CloseOutlined />}
              disabled={status !== "OPEN"}
            ></Button>
            <Button
              type="primary"
              style={{ padding: 5, fontSize: 12 }}
              disabled={status !== "OPEN"}
            >
              +More
            </Button>
          </Space>
        );
      },
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    if (loggedInCustomer.code && ["PRYM"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Description",
        key: "stockTypeName",
        width: "15%",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => {
          return (
            <Tooltip placeholder="topLeft" title={record.stockType.name}>
              {record.stockType.name}
            </Tooltip>
          );
        },
      });
    }
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (loggedInCustomer && ["PRYM"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Roll/Box No:",
        dataIndex: "runningNo",
        key: "runningNo",
        width: "8%",
        render: (text, record) => {
          return (
            <InputNumber
              value={text}
              onChange={(e) =>
                handleStockItemFieldChange(e, "runningNo", record.id)
              }
              placeholder="Roll/Box No:"
            />
          );
        },
      });
    }
    if (loggedInCustomer && ["SILUETA"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Roll/Box No:",
        dataIndex: "runningNo",
        key: "runningNo",
        width: "8%",
        render: (text, record) => {
          return (
            <>
              <Input
                value={text}
                onChange={(e) =>
                  handleStockItemFieldChange(e, "runningNo", record.id)
                }
                placeholder="Roll/Box No:"
              />
              {/* <span>{record.runningNo}</span> */}
            </>
          );
        },
      });
    }
    if (loggedInCustomer && ["SILUETA"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Plant",
        dataIndex: "plant",
        key: "plant",
        width: "7%",
        render: (text, record) => {
          return (
            <Select
              value={record.plant && record.plant.id}
              placeholder="Plant"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleStockItemFieldChange(e, "plant", record.id)
              }
            >
              {plants.length > 0 &&
                plants.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          );
        },
      });
    }
    if (loggedInCustomer && ["SILUETA"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Location",
        key: "storeLocation",
        ellipsis: {
          showTitle: false,
        },
        width: "10%",
        render: (text, record) => {
          return record.stockBin.storeLocation.address;
        },
      });
    }
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "8%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    if (loggedInCustomer && ["SILUETA"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Pallet No",
        dataIndex: "palletNo",
        key: "palletNo",
        width: "7%",
        render: (text, record) => {
          return (
            <Input
              value={text}
              onChange={(e) =>
                handleStockItemFieldChange(e, "palletNo", record.id)
              }
              min={0}
              placeholder="Pallet #"
            />
          );
        },
      });
    }
    if (
      loggedInCustomer &&
      ["PRYM", "STR-RM"].includes(loggedInCustomer.code)
    ) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        width: "8%",
        render: (text, record) => {
          return (
            <InputNumber
              value={text}
              onChange={(e) =>
                handleStockItemFieldChange(e, "quantity", record.id)
              }
              placeholder="Quantity"
            />
          );
        },
      });
    }
    columns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      render: (text, record) => {
        return (
          <InputNumber
            /* defaultValue={text} */
            value={text}
            onChange={(e) =>
              handleStockItemFieldChange(e, "netWeight", record.id)
            }
            placeholder="Net Measure"
          />
        );
      },
    });
    columns.push({
      title: `Gross Measure`,
      dataIndex: "grossWeight",
      key: "grossWeight",
      render: (text, record) => {
        return (
          <InputNumber
            /* defaultValue={text} */
            value={text}
            onChange={(e) =>
              handleStockItemFieldChange(e, "grossWeight", record.id)
            }
            placeholder="Gross Measure"
          />
        );
      },
    });
    if (loggedInCustomer && ["PRYM"].includes(loggedInCustomer.code)) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        render: (text, record) => {
          return (
            <InputNumber
              /* defaultValue={text} */
              value={text}
              onChange={(e) =>
                handleStockItemFieldChange(e, "grossValueSqm", record.id)
              }
              placeholder="Gross (Sq.m)"
            />
          );
        },
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "4%",
      render: (text, record) => {
        return record.measurementType.name;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "7%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  return (
    <>
      <Table
        bordered
        loading={loading}
        columns={getColumns()}
        dataSource={data}
        pagination={false}
        size="small"
        summary={(pageData) => {
          let totalQuantity = 0;
          let totalNetWeight = 0;
          let totalGrossWeight = 0;
          const totalStockItems = data.flatMap((item) => item.stockItems);
          const totalReceivedQuantity = totalStockItems.length;
          let totalReceivedNetMeasure = 0;
          let totalReceivedGrossMeasure = 0;
          let measurementTypes = [];

          pageData.forEach(
            ({
              quantity,
              netWeight,
              grossWeight,
              receivedNetMeasure,
              receivedGrossMeasure,
              measurementType,
            }) => {
              totalQuantity += quantity;
              totalNetWeight += netWeight;
              totalGrossWeight += grossWeight;
              totalReceivedNetMeasure += receivedNetMeasure;
              totalReceivedGrossMeasure += receivedGrossMeasure;
              measurementTypes.push(measurementType.id);
            }
          );
          const uniqueMeasurementTypes = [...new Set(measurementTypes)];

          return (
            <>
              {uniqueMeasurementTypes.length === 1 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong>Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {totalQuantity}
                    </Text>
                  </Table.Summary.Cell>
                  {status !== "OPEN" && (
                    <Table.Summary.Cell>
                      <Text strong>{totalReceivedQuantity}</Text>
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {Number(totalNetWeight).toFixed(precision)}({" "}
                      {status !== "OPEN" &&
                        Number(totalReceivedNetMeasure).toFixed(precision)}
                      )
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text strong style={{ paddingLeft: 12 }}>
                      {Number(totalGrossWeight).toFixed(precision)}({" "}
                      {status !== "OPEN" &&
                        Number(totalReceivedGrossMeasure).toFixed(precision)}
                      )
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} />
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
      {status !== "OPEN" && (
        <>
          <Divider orientation="left">Stock Items</Divider>
          <Spin tip="Loading..." spinning={stockItemLoading}>
            <Tabs defaultActiveKey={data[0].id.toString()} type="card">
              {data.map((line) => (
                <Tabs.TabPane
                  tab={`${line.stockType.code} / ${
                    line.lotNumber && line.lotNumber.number
                  } (${line.stockItems && line.stockItems.length})`}
                  key={line.id}
                >
                  <div>
                    <div style={{ marginBottom: 16 }}>
                      <Button
                        type="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowUpdateStocks(true);
                        }}
                        disabled={!hasSelected}
                        loading={loading}
                      >
                        Add/Edit Stock Details
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleUpdateStockDetails}
                        disabled={!hasSelected}
                        loading={loadingUpdateStocks}
                        style={{ marginLeft: 8 }}
                      >
                        Update Stock Details
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleGenerateBarcodes}
                        disabled={!hasSelected}
                        loading={loadingBarcodes}
                        style={{ marginLeft: 8 }}
                      >
                        Generate Barcodes
                      </Button>
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected
                          ? `Selected ${selectedRowKeys.length} items`
                          : ""}
                      </span>
                    </div>
                    <Table
                      bordered
                      size="small"
                      rowKey="id"
                      columns={getStockItemColumns()}
                      dataSource={line.stockItems}
                      rowSelection={rowSelection}
                      pagination={false}
                      summary={(pageData) => {
                        let totalQuantity = 0;
                        let totalNetWeight = 0;
                        let totalGrossWeight = 0;
                        let totalGrossValueSqm = 0;

                        pageData.forEach(
                          ({
                            quantity,
                            netWeight,
                            grossWeight,
                            grossValueSqm,
                          }) => {
                            totalQuantity += quantity;
                            totalNetWeight += netWeight;
                            totalGrossWeight += grossWeight;
                            totalGrossValueSqm += parseFloat(
                              grossValueSqm ? grossValueSqm : 0
                            );
                          }
                        );

                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                colSpan={
                                  loggedInCustomer &&
                                  ["PRYM"].includes(loggedInCustomer.code)
                                    ? getStockItemColumns().length - 6
                                    : ["SILUETA"].includes(
                                        loggedInCustomer.code
                                      )
                                    ? 3
                                    : getStockItemColumns().length - 4
                                }
                              >
                                <Text strong>Total</Text>
                              </Table.Summary.Cell>
                              {loggedInCustomer &&
                                ["PRYM", "SILUETA"].includes(
                                  loggedInCustomer.code
                                ) && (
                                  <Table.Summary.Cell>
                                    <Text strong style={{ paddingLeft: 12 }}>
                                      {line.stockItems &&
                                        line.stockItems.length}
                                    </Text>
                                  </Table.Summary.Cell>
                                )}
                              {loggedInCustomer &&
                                ["PRYM"].includes(loggedInCustomer.code) && (
                                  <Table.Summary.Cell>
                                    <Text strong style={{ paddingLeft: 12 }}>
                                      {Number(totalQuantity).toFixed(precision)}
                                    </Text>
                                  </Table.Summary.Cell>
                                )}
                              {loggedInCustomer &&
                                ["SILUETA"].includes(loggedInCustomer.code) && (
                                  <Table.Summary.Cell colSpan={4} />
                                )}
                              <Table.Summary.Cell
                                span={
                                  ["SILUETA"].includes(loggedInCustomer.code)
                                    ? 3
                                    : 1
                                }
                              >
                                <Text strong style={{ paddingLeft: 12 }}>
                                  {!isNaN(totalNetWeight) &&
                                    Number(totalNetWeight).toFixed(precision)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <Text strong style={{ paddingLeft: 12 }}>
                                  {!isNaN(totalGrossWeight) &&
                                    Number(totalGrossWeight).toFixed(precision)}
                                </Text>
                              </Table.Summary.Cell>
                              {loggedInCustomer &&
                                ["PRYM"].includes(loggedInCustomer.code) && (
                                  <Table.Summary.Cell>
                                    <Text strong style={{ paddingLeft: 12 }}>
                                      {!isNaN(totalGrossValueSqm) &&
                                        Number(totalGrossValueSqm).toFixed(
                                          precision
                                        )}
                                    </Text>
                                  </Table.Summary.Cell>
                                )}
                              <Table.Summary.Cell colSpan={2} />
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  </div>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Spin>
          <Modal
            title="Add/Edit Stock Details"
            visible={showUpdateStocks}
            onOk={handleAddEditStockDetails}
            onCancel={(e) => {
              e.preventDefault();
              setShowUpdateStocks(false);
            }}
          >
            {getModalContent()}
          </Modal>
        </>
      )}
    </>
  );
};

export default StockItemUpdateTableForm;
