import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tooltip,
  Tag,
  Tabs,
  Spin,
  Popconfirm,
  Space,
  Modal,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  materialDetailService,
  measurementTypeService,
} from "_services";
import { CloseOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";
import TableForm from "./components/TableForm";
import StockSelection from "./StockSelection";

let lineIndex = 0;

function StrGrEdit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [stockItemLoading, setStockItemLoading] = useState(false);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequest, setMaterialRequest] = useState({});
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);

  const [availableStocks, setAvailableStocks] = useState([]);
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [selectedStockTypeId, setSelectedStockTypeId] = useState(null);
  const [selectedLotNumberId, setSelectedLotNumberId] = useState(null);
  const [selectedLineKey, setSelectedLineKey] = useState(null);
  //const [requestedStocks, setRequestedStocks] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  //let selectedStockIds = [];

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const materialRequestData = await materialRequestService.getById(id);

      if (materialRequestData) {
        let materialRequest = materialRequestData.content;
        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = i;
        });
        const mrCustomer = await companyService.getById(
          materialRequest.customer.id
        );
        const officeAddresses = mrCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );

        materialRequest.requestLines.forEach((line) => {
          line.stockTypeObj = line.stockType;
          line.lotNumberObj = line.lotNumber;
          lineIndex++;
        });
        console.log("MR:", materialRequest);
        setMaterialRequest(materialRequest);
        setMaterialRequestLines([].concat(materialRequest.requestLines));
        setAddresses(officeAddresses);
        onFill(materialRequest);
      }

      setCustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setLoading(false);
    };

    fetchAlldatas();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const validateRequestLines = (lines) => {
    let validLines = true;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (
          !line.stockType ||
          !line.quantity ||
          !line.quantity > 0
        ) {
          validLines = false;
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateRequestLines(values.requestLines)) {
          setUpdating(true);

          values.requestDate = moment(values.requestDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          values.requestLines.forEach((line) => {
            delete line.key;
            line.measurementType = {
              id: measurementTypes.find((item) => item.name === "Kg").id,
            };
          });

          console.log("MR: ", values);
          //console.log("MR: ", JSON.stringify(values));

          materialRequestService
            .update(materialRequest.id, values)
            .then(() => {
              setUpdating(false);
              message.success("Successfully updated!");
              history.push(`${path}`.replace(":id", id));
            })
            .catch((error) => {
              setUpdating(false);
              setError(error);
            });
        } else {
          message.error(
            "Please complete all the request lines with valid details"
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const onCreateDelivery = () => {
    history.push(`/stock-issue/add?mrn=${id}`);
  };

  const onAcceptRequest = () => {
    setAccepting(true);
    const payload = {
      orderStage: "ACCEPTED",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setAccepting(false);
        message.success("Successfully accepted!");
        history.push("/material-requests");
      })
      .catch((error) => {
        setAccepting(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (["OPEN"].includes(materialRequest && materialRequest.orderStage)) {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={
            materialRequest &&
            materialRequest.requestLines.flatMap((item) => item.requestedItems)
              .length === 0
          }
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={updating}
            disabled={
              materialRequest &&
              materialRequest.requestLines.flatMap(
                (item) => item.requestedItems
              ).length === 0
            }
          >
            Update
          </Button>
        </Popconfirm>
      );
    }
    if (
      ["OPEN"].includes(materialRequest && materialRequest.orderStage) &&
      Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])
    ) {
      buttons.push(
        <Button
          key="accepted"
          htmlType="button"
          type="primary"
          loading={accepting}
          onClick={onAcceptRequest}
        >
          Accept
        </Button>
      );
    }
    buttons.push(
      <Button
        key="delivery"
        htmlType="button"
        type="primary"
        onClick={onCreateDelivery}
      >
        Create Delivery
      </Button>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "20%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box S/N",
      dataIndex: "serialNo",
      key: "serialNo",
      //align: "right",
      //defaultSortOrder: "descend",
      //sorter: (a, b) => parseInt(a.runningNo) < parseInt(b.runningNo),
    });
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Net Weight(Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  const getTabContent = (requestedLines) => {
    let tabPanes = [];
    requestedLines.forEach((line, index) => {
      if (line.requestedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockTypeObj && line.stockTypeObj.code} / ${
              line.lotNumberObj && line.lotNumberObj.number ? line.lotNumberObj.number : ""
            } (${line.requestedItems && line.requestedItems.length})`}
            key={index}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.requestedItems}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossValueSqm = 0;

                pageData.forEach(
                  ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                    //totalQuantity += quantity ? quantity : 1;
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossValueSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 4}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{line.requestedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const handleSearchAndAddStocks = (line) => {
    //const stockTypeId = form.getFieldValue("stockType[" + line.key + "]");
    //const lotNumberId = form.getFieldValue("lotNumber[" + line.key + "]");
    const stockTypeId = line.stockType.id;
    const lotNumberId = line.lotNumber && line.lotNumber.id;
    setSelectedStockTypeId(stockTypeId);
    setSelectedLotNumberId(lotNumberId);
    setSelectedLineKey(line.key);

    if (stockTypeId) {
      /* let requestLine = null;
      data.forEach((item) => {
        if (item.hasOwnProperty("lotNumber")) {
          if (
            item.stockType === materialTypeId &&
            item.lotNumber === lotNumberId
          ) {
            requestLine = item;
          }
        } else if (item.stockType === materialTypeId) {
          requestLine = item;
        }
      }); */
      if (line.requestedItems) {
        setSelectedStockIds(line.requestedItems.map((item) => item.id));
        //console.log("SSI:", selectedStockIds);
        //selectedStockIds = line.requestedItems.map((item) => item.id);
      }
      getStocks(stockTypeId, lotNumberId);
    } else {
      message.error("Please select valid material type!");
    }
  };

  const getStocks = (stockTypeId, lotId) => {
    //let searchParams = `?stockTypeId=${materialTypeId}&lotNumberId=${lotNumberId}&status=AVAILABLE&pageNumber=1&pageSize=100`;
    let searchParams = `?stockTypeId=${stockTypeId}&modes=AVAILABLE,REQUESTED&pageNumber=1&pageSize=1000`;
    searchParams = lotId
      ? `${searchParams}&lotNumberId=${lotId}`
      : searchParams;

    setIsAvailableStocksLoading(true);
    materialDetailService
      .search(searchParams)
      .then((data) => {
        setIsAvailableStocksLoading(false);
        if (data.content.length > 0) {
          //console.log("Selected Items: ", selectedStockIds);
          //console.log("Stocks: ", data.content);
          setAvailableStocks(data.content);
          setIsStockSelectionVisible(true);
          //setMaterialTypeId(stockTypeId);
          //setLotNumberId(lotId);
        } else {
          message.info(
            "Sorry, There's no available stocks for the given criteria!"
          );
        }
      })
      .catch((error) => {
        setIsAvailableStocksLoading(false);
        message.error(`${error}`);
      });
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected: ", selectedStockIds);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    console.log("MR: ", form.getFieldValue("requestLines"));
    setStockItemLoading(true);
    if (selectedStocks) {
      let requestLine = form
        .getFieldValue("requestLines")
        .find(
          (line) =>
            line.stockType.id === selectedStockTypeId &&
            (line.lotNumber && line.lotNumber.id === selectedLotNumberId)
        );
      const stockTypeObj = materialTypes.find(
        (item) => item.id === selectedStockTypeId
      );
      const lotNumberObj = lotNumbers.find(
        (item) => item.id === selectedLotNumberId
      );
      if (requestLine) {
        requestLine["stockTypeObj"] = stockTypeObj;
        requestLine["lotNumberObj"] = lotNumberObj;
        requestLine["stockType"] = stockTypeObj;
        requestLine["lotNumber"] = lotNumberObj;
        requestLine["requestedItems"] = selectedStocks;
        requestLine["quantity"] = selectedStocks.length;
        requestLine["netWeight"] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
        requestLine["grossWeight"] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);

        setMaterialRequestLines(form.getFieldValue("requestLines"));
      } else {
        /* const newLine = {
          stockTypeObj: stockTypeObj,
          lotNumberObj: lotNumberObj,
          stockType: stockTypeObj.id,
          lotNumber: lotNumberObj && lotNumberObj.id,
          requestedItems: selectedStocks,
          quantity: selectedStocks.length,
          netWeight: selectedStocks
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next),
          grossWeight: selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next),
        };
        newLine.key = lineIndex;
        const lines = [...materialRequestLines, newLine];
        setMaterialRequestLines(lines);
        lineIndex += 1;

        form.setFieldsValue({ stockType: null, lotNumber: null });
        console.log("RL2: ", materialRequest); */
      }
      //setRequestedStocks([...requestedStocks, ...selectedStocks]);
      //console.log("RequestedStocks:", requestedStocks);
    }
    form.setFieldsValue({ stockType: null, lotNumber: null });
    setSelectedStockTypeId(null);
    setSelectedLotNumberId(null);
    setIsStockSelectionVisible(false);
    setStockItemLoading(false);
    setSelectedStockIds([]);
    //console.log("requestLines:", materialRequestLines);
  };

  const handleLineDelete = () => {
    setMaterialRequestLines(form.getFieldValue("requestLines"));
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag
                  color={`${
                    materialRequest.orderStage === "OPEN"
                      ? "#2db7f5"
                      : ["IN_PROGRESS", "ACCEPTED"].includes(
                          materialRequest.orderStage
                        )
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      //onChange={getAddresses}
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person Name"
                    rules={[
                      {
                        required: true,
                        message: "Contact persion is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact persion" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[
                      { required: true, message: "Request date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Request date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="requestNumber" label="Request No">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["deliveryLocation", "id"]}
                    label="Deliver To"
                    rules={[
                      { required: true, message: "Delivery from is required" },
                    ]}
                  >
                    <Select placeholder="Please select">
                      {addresses.length > 0 &&
                        addresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact No:"
                    rules={[
                      { required: true, message: "Contact No. is required" },
                    ]}
                  >
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>

              {materialRequest.orderStage === "OPEN" &&
                materialTypes.length > 0 &&
                form.getFieldValue("requestLines") && (
                  <Form.Item name="requestLines">
                    <TableForm
                      materialTypes={materialTypes}
                      lotNumbers={lotNumbers}
                      measurementTypes={measurementTypes}
                      onFindStock={handleSearchAndAddStocks}
                      selectedLineKey={selectedLineKey}
                      availableStocksLoading={availableStocksLoading}
                      onDelete={handleLineDelete}
                    />
                  </Form.Item>
                )}
            </Form>
            <Modal
              title={`Stocks (${availableStocks.length})`}
              style={{ top: 48 }}
              visible={isStockSelectionVisible}
              okText={`Add Stocks (${selectedStockIds.length})`}
              onOk={handleAddStocks}
              //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
              onCancel={() => {
                setIsStockSelectionVisible(false);
                setSelectedStockIds([]);
              }}
              width={1300}
              destroyOnClose={true}
            >
              <StockSelection
                stocks={availableStocks}
                selectedIds={selectedStockIds}
                onSelectChange={handleStockSelection}
              />
            </Modal>
            {materialRequestLines.length > 0 &&
              materialRequestLines.flatMap((item) => item.requestedItems)
                .length > 0 && (
                <>
                  <Divider orientation="left">Stock Items</Divider>
                  <Tabs defaultActiveKey={materialRequestLines[0].key}>
                    {getTabContent(materialRequestLines)}
                  </Tabs>
                </>
              )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrGrEdit;
