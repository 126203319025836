import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/stocksIssueRequest`;

export const materialRequestService = {
  getAll,
  getSuggestion,
  search,
  searchSummary,
  searchLine,
  getById,
  printPdf,
  printAvailableStockPdf,
  create,
  update,
  updateLine,
  updateStatus,
  delete: _delete,
  deleteLineItem,
  deleteLine,
  getHistory,
  getDocuments,
  reject,
  rejectLines,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function searchSummary(params) {
  return fetchWrapper.get(`${baseUrl}/summary${params}`);
}

function searchLine(params) {
  return fetchWrapper.get(`${baseUrl}/line${params}`);
}

function getSuggestion(params) {
  return fetchWrapper.get(`${baseUrl}/suggestion${params}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function printPdf(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/printPdf`, {
    responseType: "blob",
  });
}

function printAvailableStockPdf(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/availableStockPdf`, {
    responseType: "blob",
  });
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function updateLine(id, lineId, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/line/${lineId}`, params);
}

function updateStatus(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/updateStatus`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function deleteLineItem(id, stockItemId) {
  return fetchWrapper.delete(`${baseUrl}/${id}/lineItem/${stockItemId}`);
}

function deleteLine(id, lineId) {
  return fetchWrapper.delete(`${baseUrl}/${id}/line/${lineId}`);
}

function getHistory(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/history`);
}

function getDocuments(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/documents`);
}

function reject(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/reject`, params);
}

function rejectLines(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/reject-lines`, params);
}
