import {
  Breadcrumb,
  Button,
  DatePicker,
  Drawer,
  PageHeader,
  Input,
  InputNumber,
  Table,
  Popconfirm,
  message,
  Tag,
  Typography,
  Row,
  Col,
  Descriptions,
  Divider,
  Spin,
  Space,
  Select,
  Form,
  Modal,
} from "antd";
import {
  CloseOutlined,
  ExportOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import {
  binService,
  lotNumberService,
  materialDetailService,
  materialTypeService,
  measurementTypeService,
  stockCheckService,
} from "_services";
import TabMenu from "../../TabMenu";
import moment from "moment";

let newItemIndex = 0;

function SiluetaSummary({ match, history }) {
  const { id, locationId } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingCountedItems, setLoadingCountedItems] = useState(false);
  const [binLoading, setBinLoading] = useState(false);
  const [savingCountDetails, setSavingCountDetails] = useState(false);
  const [savingReCountDetails, setSavingReCountDetails] = useState(false);
  const [savingNegativeAdjustment, setSavingNegativeAdjustment] =
    useState(false);
  const [loadingPositiveAdjustment, setLoadingPositiveAdjustment] =
    useState(false);
  const [verifyingItem, setVerifyingItem] = useState(false);
  const [showCompletedCount, setShowCompletedCount] = useState(false);
  const [stockCheckLocation, setStockCheckLocation] = useState({});
  const [countedStocks, setCountedStocks] = useState([]);
  const [unCountedStocks, setUnCountedStocks] = useState([]);
  const [inProgressCount, setInProgressCount] = useState(null);
  const [completedCountItems, setCompletedCountItems] = useState(null);
  const [mode, setMode] = useState("Save");
  const [isNew, setIsNew] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [unverifiedRowKeys, setUnverifiedRowKeys] = useState([]);
  const [newStockItems, setNewStockItems] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [availableStocks, setAvailableStocks] = useState([]);
  const [positiveAdjustmentDrawerVisible, setPositiveAdjustmentDrawerVisible] =
    useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [isBinTransferModalOpen, setIsBinTransferModalOpen] = useState(false);
  const { Text } = Typography;
  const { Option } = Select;
  const [scanForm] = Form.useForm();
  const [createForm] = Form.useForm();
  const [binTransferForm] = Form.useForm();

  useEffect(() => {
    setLoading(true);

    stockCheckService
      .getByLocationId(id, locationId)
      .then((data) => {
        if (data.content.counts.length > 0) {
          data.content.counts.forEach((item) => {
            if (["IN_PROGRESS", "OPEN"].includes(item.checkStatus)) {
              setCountedStocks([
                ...countedStocks,
                ...item.serials
                  .filter((i) => ["VERIFIED", "NEW"].includes(i.status))
                  .map((t) => {
                    return {
                      ...t.stockItem,
                      serialId: t.id,
                      adjustmentType: t.adjustmentType,
                      status: t.status,
                      movedFromBin: t.movedFromBin,
                    };
                  }),
              ]);

              setMode("Update");
              setIsInProgress(true);
              setInProgressCount(item);
              setUnCountedStocks([
                ...unCountedStocks,
                ...item.serials
                  .filter((i) => i.status === "UNVERIFIED")
                  .map((t) => {
                    return {
                      ...t.stockItem,
                      serialId: t.id,
                      adjustmentType: t.adjustmentType,
                      status: t.status,
                    };
                  }),
              ]);
            }
          });
        }
        setStockCheckLocation(data.content);
        setAvailableStocks(data.content.availableStocks);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    getMaterialTypes();
    getLotNumbers();
    getMeasurementTypes();
  }, []);

  const getMaterialTypes = () => {
    materialTypeService.getSuggestion().then((x) => {
      setMaterialTypes(x.content);
    });
  };

  const getLotNumbers = () => {
    lotNumberService.getSuggestion().then((x) => {
      setLotNumbers(x.content);
    });
  };

  const getMeasurementTypes = () => {
    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content);
    });
  };

  const handleReCount = () => {
    const payload = {
      id: null,
      stockCheckLocation: { id: stockCheckLocation.id },
      countType: "DETAILED",
      active: 1,
      checkStatus: "OPEN",
      count: 0,
      countedDate: moment().format("YYYY-MM-DD"),
      serials: availableStocks.map((item) => {
        return {
          id: null,
          stockItem: { id: item.id },
          adjustmentType: null,
          status: "UNVERIFIED",
        };
      }),
    };

    setSavingReCountDetails(true);
    stockCheckService
      .createLocationCount(
        stockCheckLocation.stockCheck.id,
        stockCheckLocation.id,
        payload
      )
      .then(() => {
        setSavingReCountDetails(false);
        message.success("Successfully saved!");
        window.location.reload();
      })
      .catch((error) => {
        setSavingReCountDetails(false);
        setError(error);
      });
  };

  const handleComplete = () => {
    scanForm
      .validateFields()
      .then((values) => {
        const payload = getSaveOrUpdatePayload();
        payload.checkStatus = "COMPLETE";
        payload.checkedBy = values.checkedBy;

        setSavingCountDetails(true);
        stockCheckService
          .createLocationCount(
            stockCheckLocation.stockCheck.id,
            stockCheckLocation.id,
            payload
          )
          .then(() => {
            setSavingCountDetails(false);
            message.success("Successfully saved!");
            window.location.reload();
          })
          .catch((error) => {
            setSavingCountDetails(false);
            setNewStockItems([]);
            setSelectedRowKeys([]);
            setError(error);
          });
      })
      .catch((error) => {});
  };

  /*const handleAddPositiveAdjustment = () => {
    createForm.validateFields().then((values) => {
      setLoadingPositiveAdjustment(true);
      if (values.serialNo) {
        const positiveAdjustments = {
            new: true,
            id: null,
            serialNo: item.serialNo,
            tempNumber: item.tempNumber,
            stockType: { id: item.stockType },
            lotNumber: { id: item.lotNumber },
            measurementType: { id: item.measurementType },
            netWeight: item.netWeight,
            grossWeight: item.grossWeight,
            quantity: item.quantity,
            productionDate: item.productionDate
              ? moment(item.productionDate).format('YYYY-MM-DD')
              : null,
            packingStatus: item.packingStatus,
            adjustmentType: 'POSITIVE',
            status: 'NEW',
          };
        countedStocks.push(...positiveAdjustments);
      }
      const defaultMeasurementType = measurementTypes.find(
        (item) => item.name === 'Kg'
      );
      const newStockItem =
        defaultMeasurementType === null
          ? {}
          : { measurementType: defaultMeasurementType.id };
      console.log('Positive:', values);
    });
  };*/

  const handlePositiveAdjustment = () => {
    if (newStockItems.length > 0) {
      var isValid = true;
      for (var i = 0; i < newStockItems.length; i++) {
        if (!newStockItems[i].serialNo) {
          if (
            !newStockItems[i].tempNumber ||
            !newStockItems[i].stockType ||
            !newStockItems[i].lotNumber ||
            !newStockItems[i].netWeight ||
            !newStockItems[i].grossWeight ||
            !newStockItems[i].quantity ||
            !newStockItems[i].productionDate ||
            !newStockItems[i].packingStatus
          ) {
            console.log("New Data:", newStockItems[i]);
            message.error(
              "Please fill all the fields!. All fields are required"
            );
            isValid = false;
            return;
          }
        }
      }

      if (isValid) {
        const payload = getSaveOrUpdatePayload();
        // console.log('Payload: ', payload.serials);
        setSavingCountDetails(true);
        stockCheckService
          .createLocationCount(
            stockCheckLocation.stockCheck.id,
            stockCheckLocation.id,
            payload
          )
          .then(() => {
            setSavingCountDetails(false);
            message.success("Successfully saved!");
            window.location.reload();
          })
          .catch((error) => {
            setSavingCountDetails(false);
            setNewStockItems([]);
            setSelectedRowKeys([]);
            setError(error);
          });
      }
    }
  };

  const handleNegativeAdjustment = (item) => {
    item.adjustmentType = "NEGATIVE";
    item.status = "VERIFIED";
    setCountedStocks([...countedStocks, item]);
    const unverified = [...unCountedStocks];
    unverified.splice(unverified.indexOf(item), 1);
    setUnCountedStocks(unverified);
  };

  const handleVerification = () => {
    const unverified = [...unCountedStocks];
    const verifiedKeys = [...selectedRowKeys];
    const matchedItems = [];
    for (var i = 0; i < selectedRowKeys.length; i++) {
      const matchedItem = unCountedStocks.find(
        (item) => item.serialId === selectedRowKeys[i]
      );
      matchedItem.status = "VERIFIED";
      matchedItems.push(matchedItem);
      unverified.splice(unverified.indexOf(matchedItem), 1);
      verifiedKeys.splice(verifiedKeys.indexOf(selectedRowKeys[i]), 1);
    }
    setUnCountedStocks(unverified);
    setCountedStocks([...countedStocks, ...matchedItems]);
    setSelectedRowKeys(verifiedKeys);
  };

  const handleUnverification = () => {
    const verified = [...countedStocks];
    const unverifiedKeys = [...unverifiedRowKeys];
    const matchedItems = [];
    for (var i = 0; i < unverifiedRowKeys.length; i++) {
      const matchedItem = verified.find(
        (item) => item.serialId === unverifiedRowKeys[i]
      );
      matchedItem.adjustmentType = null;
      matchedItem.status = "UNVERIFIED";
      matchedItems.push(matchedItem);
      verified.splice(verified.indexOf(matchedItem), 1);
      unverifiedKeys.splice(unverifiedKeys.indexOf(unverifiedRowKeys[i]), 1);
    }
    setCountedStocks(verified);
    setUnCountedStocks([...unCountedStocks, ...matchedItems]);
    setUnverifiedRowKeys(unverifiedKeys);
  };

  const handleSaveOrUpdateLocationCount = () => {
    const payload = getSaveOrUpdatePayload();
    console.log("Payload: ", payload);
    setSavingCountDetails(true);
    stockCheckService
      .createLocationCount(
        stockCheckLocation.stockCheck.id,
        stockCheckLocation.id,
        payload
      )
      .then(() => {
        setSavingCountDetails(false);
        message.success("Successfully saved!");
        window.location.reload();
      })
      .catch((error) => {
        setSavingCountDetails(false);
        setNewStockItems([]);
        setSelectedRowKeys([]);
        setError(error);
      });
  };

  const getSaveOrUpdatePayload = () => {
    /* const negativeAdjustments = [];
    if (selectedRowKeys.length > 0) {
      for (var i = 0; i < selectedRowKeys.length; i++) {
        const matchedItem = unCountedStocks.find(
          (item) => item.serialId === selectedRowKeys[i]
        );
        negativeAdjustments.push(matchedItem);
        unCountedStocks.splice(unCountedStocks.indexOf(matchedItem), 1);
      }
    } */

    if (newStockItems.length > 0) {
      const positiveAdjustments = newStockItems.map((item) => {
        return {
          new: true,
          id: null,
          serialNo: item.serialNo,
          tempNumber: item.tempNumber,
          stockType: { id: item.stockType },
          lotNumber: { id: item.lotNumber },
          measurementType: { id: item.measurementType },
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
          quantity: item.quantity,
          productionDate: item.productionDate
            ? moment(item.productionDate).format("YYYY-MM-DD")
            : null,
          packingStatus: item.packingStatus,
          adjustmentType: "POSITIVE",
          status: "NEW",
        };
      });
      countedStocks.push(...positiveAdjustments);
    }

    const payload = {
      id: inProgressCount ? inProgressCount.id : null,
      stockCheckLocation: { id: stockCheckLocation.id },
      countType: "DETAILED",
      active: 1,
      checkStatus: countedStocks.length === 0 ? "OPEN" : "IN_PROGRESS",
      count: countedStocks.length,
      countedDate: moment().format("YYYY-MM-DD"),
      serials: countedStocks.map((item) => {
        return {
          id: item.serialId ? item.serialId : null,
          stockItem: item.new
            ? item
            : { id: item.id, stockBin: { id: item.stockBin.id } },
          adjustmentType: item.adjustmentType ? item.adjustmentType : null,
          movedFromBin: item.movedFromBin ? item.movedFromBin : null,
          status: item.status,
        };
      }),
    };
    payload.serials = payload.serials.concat(
      unCountedStocks.map((item) => {
        return {
          id: item.serialId ? item.serialId : null,
          stockItem: { id: item.id },
          adjustmentType: item.adjustmentType,
          status: item.status,
        };
      })
    );
    /* payload.serials = payload.serials.concat(
      negativeAdjustments.map((item) => {
        return {
          id: item.serialId ? item.serialId : null,
          stockItem: { id: item.id },
          adjustmentType: 'NEGATIVE',
          status: 'VERIFIED',
        };
      })
    ); */

    return payload;
  };

  const handleCancel = () => {
    history.push(`/stock-check/${stockCheckLocation.stockCheck.id}/locations`);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onUnverifyChange = (unverifiedRowKeys) => {
    setUnverifiedRowKeys(unverifiedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const unverifyRowSelection = {
    unverifiedRowKeys,
    onChange: onUnverifyChange,
  };

  const getActionButtons = () => {
    let buttons = [];
    if ((isNew || inProgressCount) && selectedRowKeys.length === 0) {
      buttons.push(
        <Button
          key="addOrUpdate"
          onClick={handleSaveOrUpdateLocationCount}
          loading={savingCountDetails}
        >
          {mode}
        </Button>
      );
    }
    buttons.push(
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
    );
    if (
      stockCheckLocation &&
      stockCheckLocation.stockCheck &&
      stockCheckLocation.stockCheck.checkStatus === "IN_PROGRESS" &&
      stockCheckLocation.checkStatus !== 'COMPLETE' &&
      unCountedStocks.length === 0
    ) {
      buttons.push(
        <Button
          key="saveOrUpdate"
          onClick={handleComplete}
          loading={savingCountDetails}
        >
          Complete
        </Button>
      );
    }
    /* if (selectedRowKeys.length > 0) {
      buttons.push(
        <Button
          key="notFound"
          onClick={handleVerification}
          loading={savingCountDetails}
        >
          Verify
        </Button>
      );
    } */
    /* if (unverifiedRowKeys.length > 0) {
      buttons.push(
        <Button key="unverify" onClick={handleUnverification}>
          Unverify
        </Button>
      );
    } */
    /* if (newStockItems.length > 0) {
      buttons.push(
        <Button
          key="notFound"
          onClick={handlePositiveAdjustment}
          loading={savingCountDetails}
        >
          Save +Adjustment
        </Button>
      );
    } */

    return buttons;
  };

  const handleSearchAndVerify = (value) => {
    if (value) {
      setVerifyingItem(true);
      let matchedItem = countedStocks.find(
        (item) => item.serialNo.toLowerCase() === value.toLowerCase()
      );
      if (matchedItem) {
        message.info(`Item already counted! ${value}.`);
        scanForm.setFieldsValue({ verifyItem: null });
        setVerifyingItem(false);
      } else {
        const stockItems = [...unCountedStocks];
        matchedItem = stockItems.find(
          (item) => item.serialNo.toLowerCase() === value.toLowerCase()
        );
        if (matchedItem) {
          matchedItem.status = "VERIFIED";
          message.success(`Item Successfully verified!`);
          stockItems.splice(stockItems.indexOf(matchedItem), 1);
          setUnCountedStocks(stockItems);
          setCountedStocks([...countedStocks, matchedItem]);

          setVerifyingItem(false);
          scanForm.setFieldsValue({ verifyItem: null });
        } else {
          setVerifyingItem(true);
          let searchParams = `?subLocationId=${
            stockCheckLocation.stockCheck.subLocation.id
          }&serialNo=${value.toLowerCase()}&modes=AVAILABLE&pageNumber=1&pageSize=1000`;
          materialDetailService
            .searchAvailable(searchParams)
            .then((data) => {
              setVerifyingItem(false);
              if (data.content.length > 0) {
                message.success(`Item Successfully verified!`);
                matchedItem = data.content[0];
                matchedItem.new = true;
                matchedItem.id = null;
                matchedItem.status = "VERIFIED";
                matchedItem.adjustmentType = "POSITIVE";
                setCountedStocks([...countedStocks, matchedItem]);

                scanForm.setFieldsValue({ verifyItem: null });
              } else {
                message.error(`Sorry, No found against the barcode ${value}.`);
              }
              scanForm.setFieldsValue({ verifyItem: null });
            })
            .catch((error) => {
              setVerifyingItem(false);
              scanForm.setFieldsValue({ verifyItem: null });
              message.info("Sorry, Something went wrong! " + error);
            });
        }
      }
    } else {
      message.error(`Please enter valid item barcode.`);
    }
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      //width: "12%",
      //fixed: "left",
    });
    columns.push({
      title: "Box No:",
      dataIndex: "runningNo",
      key: "runningNo",
      //width: "4%",
      //fixed: "left",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      //width: "8%",
      //fixed: "left",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      //width: "8%",
      //fixed: "left",
      render: (text) => {
        let color = "";
        if (text === "VERIFIED") {
          color = "#87d068";
        }
        if (text === "UNVERIFIED") {
          color = "#fa8c16";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "LOT",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      //width: "4%",
      //fixed: "left",
    });

    return columns;
  };

  const getUnCountedStockItemColumns = () => {
    return [
      ...getStockItemColumns(),
      {
        title: "",
        key: "action",
        width: "8%",
        fixed: "left",
        render: (text, record) => {
          return (
            <Button
              icon={<MinusCircleOutlined />}
              title="Not Found"
              onClick={() => handleNegativeAdjustment(record)}
            ></Button>
          );
        },
      },
    ];
  };

  const getCountedStockItemColumns = () => {
    return [
      ...getStockItemColumns(),
      {
        title: "Adjustment",
        dataIndex: "adjustmentType",
        key: "adjustmentType",
        width: "8%",
        //fixed: "left",
        render: (text) => {
          let color = "";
          if (text === "POSITIVE") {
            color = "#87d068";
          }
          if (text === "NEGATIVE") {
            color = "#fa8c16";
          }
          return <Tag color={color}>{text}</Tag>;
        },
      },
    ];
  };

  const getLocationCountColumns = () => {
    let columns = [];
    columns.push({
      title: "Counted Date",
      dataIndex: "countedDate",
      key: "countedDate",
      render: (text, record) => <Link>{text}</Link>,
    });
    columns.push({
      title: "Type",
      dataIndex: "countType",
      key: "countType",
    });
    columns.push({
      title: "Status",
      dataIndex: "checkStatus",
      key: "checkStatus",
      render: (orderStatus) => {
        let color = "";
        if (["COMPLETE"].includes(orderStatus)) {
          color = "#87d068";
        }
        if (orderStatus === "OPEN") {
          color = "#2db7f5";
        }
        if (["IN_PROGRESS"].includes(orderStatus)) {
          color = "#faad14";
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    });
    columns.push({
      title: "Counted Stocks",
      dataIndex: "count",
      key: "count",
    });
    columns.push({
      title: "Checked By",
      dataIndex: "checkedBy",
      key: "checkedBy",
    });
    columns.push({
      title: "",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            {record.checkStatus === "COMPLETE" && (
              <Button
                title="Show/Hide Details"
                type="link"
                style={{ marginRight: 10 }}
                key="btn-show"
                onClick={(e) => {
                  e.preventDefault();
                  showCompletedCountDetails(record);
                }}
              >
                {`${!showCompletedCount ? "Show Details" : "Hide Details"}`}
              </Button>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const newStockItemColumns = [
    {
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      //align: 'right',
      width: "15%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`serialNo[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <Input
              placeholder="Box Serial"
              onChange={(e) =>
                handleFieldChange(e.target.value, "serialNo", record.key)
              }
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Box Number",
      dataIndex: "tempNumber",
      key: "tempNumber",
      align: "right",
      width: "3%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`tempNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Box Number"
              onChange={(e) => handleFieldChange(e, "tempNumber", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: (
        <>
          <span style={{ marginRight: 5 }}>Material</span>
          <Button
            icon={<ReloadOutlined />}
            onClick={(e) => {
              e.preventDefault();
              getMaterialTypes();
            }}
          />
        </>
      ),
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`stockType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "stockType", record.key)}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: (
        <>
          <span style={{ marginRight: 5 }}>Lot No</span>
          <Button
            icon={<ReloadOutlined />}
            onClick={(e) => {
              e.preventDefault();
              getLotNumbers();
            }}
          />
        </>
      ),
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`lotNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: false, message: "Required." }]}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
            >
              {lotNumbers.length > 0 &&
                lotNumbers.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: "Cones",
      dataIndex: "quantity",
      key: "quantity",
      //align: 'right',
      width: "3%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`quantity[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Box"
              min={0}
              onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      aligh: "right",
      width: "5%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`netWeight[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: false, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Net Measure"
              onChange={(e) => handleFieldChange(e, "netWeight", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      aligh: "right",
      width: "5%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`grossWeight[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
          >
            <InputNumber
              placeholder="Gross Measure"
              onChange={(e) => handleFieldChange(e, "grossWeight", record.key)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Production Date",
      key: "productionDate",
      width: "6%",
      render: (text, record) => {
        return (
          <Form.Item
            name="productionDate"
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Production date is required" }]}
          >
            <DatePicker
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleFieldChange(e, "productionDate", record.key)
              }
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Packing Status",
      key: "packingStatus",
      width: "5%",
      render: (text, record) => {
        return (
          <Form.Item
            name="packingStatus"
            style={{ marginBottom: "0" }}
            rules={[
              { required: true, message: "Please select Packing Status" },
            ]}
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "packingStatus", record.key)
              }
            >
              <Option value="NEW" key="NEW">
                NEW
              </Option>
              <Option value="RETURN" key="RETURN">
                RETURN
              </Option>
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              size="small"
              icon={<CloseOutlined />}
              onClick={() => handleRemoveNewStockItem(record)}
              style={{ padding: 5, fontSize: 9 }}
            ></Button>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveNewStockItem(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleFieldChange = (value, fieldName, key) => {
    const items = [...newStockItems];
    const newItems = items.map((item) => {
      const newItem = Object.assign({}, item);
      if (newItem.key === key) {
        newItem[fieldName] = value;
        return newItem;
      }
      return newItem;
    });
    setNewStockItems(newItems);
  };

  /*const handleSerialNumberChange = (value) => {
    if (value) {
      setMandatory(false);
    } else {
      setMandatory(true);
    }
  };*/

  const handleRemoveNewStockItem = (item) => {
    const items = [...newStockItems];
    if (items.includes(item)) {
      items.splice(items.indexOf(item), 1);
      setNewStockItems(items);
    }
  };

  const handleSaveNewStockItem = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    if (!target.serialNo) {
      if (
        // !target.serialNo ||
        !target.tempNumber ||
        !target.stockType ||
        !target.lotNumber ||
        !target.quantity ||
        !target.netWeight ||
        !target.grossWeight ||
        !target.producitonDate ||
        !target.packingStatus
      ) {
        message.error("Please fill all the fields!. All fields are required");
        e.target.focus();
        return;
      }
    }
    handleAddNewStockItem();
  };

  const getRowBykey = (key, newData) => {
    return (newStockItems || newData).filter((item) => item.key === key)[0];
  };

  /*const showPositiveAdjustmentDrawer = () => {
    setPositiveAdjustmentDrawerVisible(true);
  };*/

  /*const onClosePositiveAdjustmentDrawer = () => {
    if (!loadingPositiveAdjustment) {
      scanForm.resetFields();
      setPositiveAdjustmentDrawerVisible(false);
    }
  };*/

  const handleAddNewStockItem = () => {
    const defaultMeasurementType = measurementTypes.find(
      (item) => item.name === "Kg"
    );
    const newStockItem =
      defaultMeasurementType === null
        ? {}
        : { measurementType: defaultMeasurementType.id };
    newStockItem.key = newItemIndex;
    const items = [...newStockItems, newStockItem];
    setNewStockItems(items);
    newItemIndex += 1;
  };

  const handleBinTransfer = () => {
    binTransferForm.validateFields().then((values) => {
      let searchParams = `?isParent=1&subLocationId=${stockCheckLocation.stockCheck.subLocation.id}&code=${values.transferBinCode}`;
      setBinLoading(true);
      binService
        .search(searchParams)
        .then((data) => {
          setBinLoading(false);
          if (data.content.length > 0) {
            const lostItems = unCountedStocks.filter((item) =>
              selectedRowKeys.includes(item.serialId)
            );

            const newUnCountedStocks = unCountedStocks.filter((lostItem) => {
              return !lostItems.includes(lostItem);
            });
            setUnCountedStocks(newUnCountedStocks);

            const newCountedStocks = lostItems.map((item) => ({
              ...item,
              adjustmentType: "NEGATIVE",
              status: "VERIFIED",
              stockBin: data.content[0],
            }));
            setCountedStocks([...countedStocks, ...newCountedStocks]);

            binTransferForm.resetFields();
            setIsBinTransferModalOpen(false);
            setSelectedRowKeys([]);
          } else {
            message.error("Invalid bin code!");
          }
        })
        .catch((error) => {
          setBinLoading(false);
          message.error(`Sorry, something went wrong!`);
          binTransferForm.resetFields();
          setIsBinTransferModalOpen(false);
          setSelectedRowKeys([]);
        });
    });
  };

  const showCompletedCountDetails = (count) => {
    const countedStocks = count.serials.map((item) => {
      return { ...item.stockItem, adjustmentType: item.adjustmentType };
    });
    setShowCompletedCount(!showCompletedCount);
    setCompletedCountItems(countedStocks);
  };

  const getSummaryContent = (pageData, type) => {
    let totalQuantity = 0;
    let totalItems = 0;
    let colSpan = 5;
    if (type === "unverify") {
      colSpan = 5;
    }

    pageData.forEach(({ quantity }) => {
      totalQuantity += quantity;
      totalItems += 1;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={colSpan}>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{totalItems}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalQuantity).toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell />
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-check">Stock Check Search</Link>
            </Breadcrumb.Item>
            {stockCheckLocation && stockCheckLocation.stockCheck && (
              <Breadcrumb.Item>
                <Link to={`${path}`.replace(":id", id)}>
                  {stockCheckLocation.stockCheck.code} ::{" "}
                  <Tag
                    color={`${
                      stockCheckLocation.stockCheck.checkStatus === "COMPLETE"
                        ? "#87d068"
                        : stockCheckLocation.stockCheck.checkStatus ===
                          "IN_PROGRESS"
                        ? "#faad14"
                        : "#2db7f5"
                    }`}
                  >
                    {stockCheckLocation.stockCheck.checkStatus}
                  </Tag>
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <Link to={`/stock-check/${id}/locations`}>Locations</Link>
            </Breadcrumb.Item>
            {stockCheckLocation && stockCheckLocation.stockBin && (
              <Breadcrumb.Item>
                <span>{stockCheckLocation.stockBin.code}</span>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="locations" id={id} />
          <Spin tip="Loading..." spinning={loading}>
            {stockCheckLocation && stockCheckLocation.stockBin && (
              <Row gutter={24}>
                <Descriptions title="Stock Check Location Info">
                  <Descriptions.Item label="SBU">
                    {stockCheckLocation.stockCheck.subLocation.zone.sbu.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Zone">
                    {stockCheckLocation.stockCheck.subLocation.zone.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Sub Location">
                    {stockCheckLocation.stockCheck.subLocation.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Bin Code">
                    {stockCheckLocation.stockBin.code}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <Tag
                      color={`${
                        stockCheckLocation.checkStatus === "COMPLETE"
                          ? "#87d068"
                          : stockCheckLocation.checkStatus === "IN_PROGRESS"
                          ? "#faad14"
                          : "#2db7f5"
                      }`}
                    >
                      {stockCheckLocation.checkStatus}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Available Stocks">
                    {stockCheckLocation.expectedItemQty}
                  </Descriptions.Item>
                  <Descriptions.Item label="Available Quantity">
                    {stockCheckLocation.expectedQty}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            )}
            {stockCheckLocation &&
              stockCheckLocation.counts &&
              stockCheckLocation.counts.length > 0 && (
                <Table
                  bordered
                  size="small"
                  rowKey="id"
                  columns={getLocationCountColumns()}
                  dataSource={stockCheckLocation.counts}
                  pagination={false}
                  // scroll={{ x: 1300 }}
                  loading={loading}
                  style={{ marginBottom: 20 }}
                />
              )}
            {/* !isInProgress && (
              <Button
                style={{
                  width: "100%",
                  marginTop: 16,
                  marginBottom: 8,
                  color: "white",
                  backgroundColor: "#13c2c2",
                }}
                type="dashed"
                onClick={handleReCount}
                icon={<PlusOutlined />}
                loading={savingReCountDetails}
              >
                Recount
              </Button>
            ) */}
            {showCompletedCount && (
              <>
                <Divider orientation="left">
                  COMPLETED COUNT - {completedCountItems.length}
                </Divider>
                <Table
                  bordered
                  size="small"
                  rowKey="id"
                  columns={getCountedStockItemColumns()}
                  dataSource={completedCountItems}
                  pagination={false}
                  summary={(pageData) => getSummaryContent(pageData)}
                />
              </>
            )}
            {(isNew || inProgressCount) && (
              <>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form
                      layout="vertical"
                      form={scanForm}
                      name="scan"
                      requiredMark={false}
                    >
                      <Form.Item name="verifyItem">
                        <Input.Search
                          loading={verifyingItem}
                          placeholder="Enter valid ITEM barcode"
                          allowClear
                          enterButton="Verify Item"
                          onSearch={(value) => handleSearchAndVerify(value)}
                          style={{ marginBottom: 15 }}
                        />
                      </Form.Item>
                      {unCountedStocks.length === 0 && (
                        <Form.Item
                          name="checkedBy"
                          label="Checked By"
                          rules={[
                            {
                              required: true,
                              message: "Member name is required.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Checked By"
                            allowClear
                            style={{ marginBottom: 15 }}
                          />
                        </Form.Item>
                      )}
                    </Form>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Divider orientation="left">
                      UNVERIFIED - {unCountedStocks.length}{" "}
                      {/* <Button
                        type="primary"
                        disabled={selectedRowKeys.length === 0}
                        icon={<ExportOutlined title="Move Out" />}
                        title="Move Out"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsBinTransferModalOpen(true);
                        }}
                      ></Button> */}
                    </Divider>
                    <Table
                      bordered
                      size="small"
                      rowKey="serialId"
                      columns={getUnCountedStockItemColumns()}
                      dataSource={unCountedStocks}
                      pagination={false}
                      //scroll={{ x: 1300 }}
                      loading={loading}
                      summary={(pageData) =>
                        getSummaryContent(pageData, "unverify")
                      }
                      //rowSelection={rowSelection}
                    />
                  </Col>
                  <Col span={12}>
                    <Divider orientation="left">
                      VERIFIED - {countedStocks.length}
                    </Divider>
                    {countedStocks.length > 0 && (
                      <Table
                        bordered
                        size="small"
                        rowKey="serialId"
                        columns={getCountedStockItemColumns()}
                        dataSource={countedStocks}
                        pagination={false}
                        //scroll={{ x: 1300 }}
                        loading={loadingCountedItems}
                        summary={(pageData) =>
                          getSummaryContent(pageData, "verify")
                        }
                        // rowSelection={unverifyRowSelection}
                      />
                    )}
                  </Col>
                </Row>
                {/* newStockItems.length === 0 && (
                  <Button
                    style={{
                      width: "100%",
                      marginTop: 16,
                      marginBottom: 8,
                      color: "white",
                      backgroundColor: "#13c2c2",
                    }}
                    type="dashed"
                    onClick={handleAddNewStockItem}
                    icon={<PlusOutlined />}
                  >
                    Add Positive Adjustment
                  </Button>
                ) */}
                {newStockItems.length > 0 && (
                  <Form
                    layout="vertical"
                    form={createForm}
                    name="create"
                    requiredMark={false}
                  >
                    <Table
                      bordered
                      pagination={false}
                      columns={newStockItemColumns}
                      dataSource={newStockItems}
                      size="small"
                      summary={(pageData) => {
                        let totalQuantity = 0;
                        let totalNetWeight = 0;
                        let totalGrossWeight = 0;

                        pageData.forEach(
                          ({ quantity, netWeight, grossWeight }) => {
                            totalQuantity += quantity;
                            totalNetWeight += netWeight;
                            totalGrossWeight += grossWeight;
                          }
                        );

                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={3}>
                              <Text strong>Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>{newStockItems.length}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalQuantity) && totalQuantity}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalNetWeight) &&
                                  Number(totalNetWeight).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalGrossWeight) &&
                                  Number(totalGrossWeight).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={3} />
                          </Table.Summary.Row>
                        );
                      }}
                    />
                  </Form>
                )}
              </>
            )}
          </Spin>
          <Modal
            title="Lost Item Transfer"
            open={isBinTransferModalOpen}
            onOk={handleBinTransfer}
            onCancel={(e) => {
              e.preventDefault();
              setIsBinTransferModalOpen(false);
            }}
            confirmLoading={binLoading}
          >
            <Form
              form={binTransferForm}
              name="bin-transfer-form"
              layout="inline"
            >
              <Form.Item
                name="transferBinCode"
                rules={[{ required: true, message: "Required." }]}
              >
                <Input placeholder="Bin Code" style={{ width: 200 }} />
              </Form.Item>
            </Form>
          </Modal>
        </Page>
      </PageHeader>
    </div>
  );
}

export default SiluetaSummary;
