import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { userService, roleService, companyService } from "_services";

function Add({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
    roleService.getSuggestion().then((x) => {
      setRoles(x.content);
    });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        values.roles = values.roles.map((item) => ({ id: item }));
        console.log("Val:", JSON.stringify(values));
        userService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/people">People Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/people/add">New Person</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="username"
                  label="Username (Email)"
                  rules={[
                    { required: true, message: "Username is required" },
                    { type: "email", message: "The input is not valid E-mail" },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="firstName"
                  label="First name"
                  rules={[{ required: true, message: "Firstname is required" }]}
                >
                  <Input placeholder="Firstname" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={["company", "id"]} label="Customer">
                  <Select
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                  >
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="roles" label="Roles">
                  <Select
                    mode="multiple"
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                  >
                    {roles.length > 0 &&
                      roles.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: "Password is required" }]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="lastName" label="Last name">
                  <Input placeholder="Last name" />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
