import React from "react";
import { Route, Switch } from "react-router-dom";

import StrRmAdd from "./str_rm/Add";
import StrRmAddBySelection from "./str_rm/AddBySelection";
import StrRmEdit from "./str_rm/Edit";
import StrRmSummaryWithItems from "./str_rm/SummaryWithItems";
import StrRmPendingLineList from "./str_rm/PendingLineList";
import StrRmVerify from "./str_rm/Verify";
import StrRmList from "./str_rm/List";
import StrUpload from "./str_rm/Upload";
import StrRmHistory from "./str_rm/History";
import StrRmDocument from "./str_rm/Document";

import StrGrAdd from "./str_gr/Add";
import StrGrEdit from "./str_gr/Edit";
import StrGrSummary from "./str_gr/Summary";

import PrymAdd from "./prym/Add";
import PrymEdit from "./prym/Edit";
import PrymSummaryWithItems from "./prym/SummaryWithItems";
import PrymPendingLineList from "./prym/PendingLineList";
import PrymVerify from "./prym/Verify";
import PrymUpload from "./prym/Upload";
import PrymList from "./prym/List";

import Add from "./Add";
import Summary from "./Summary";
import Upload from "./Upload";
import List from "./List";
import Edit from "./Edit";

import { Auth } from "_helpers";

function MaterialRequests({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "STR_GR") {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={StrGrAdd} />
        <Route path={`${path}/upload`} component={Upload} />
        <Route path={`${path}/:id/edit`} component={StrGrEdit} />
        <Route path={`${path}/:id/summary`} component={StrGrSummary} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrRmList} />
        <Route path={`${path}/add`} component={StrRmAdd} />
        <Route
          path={`${path}/add-with-items`}
          component={StrRmAddBySelection}
        />
        <Route path={`${path}/upload`} component={StrUpload} />
        <Route path={`${path}/pending`} component={StrRmPendingLineList} />
        <Route path={`${path}/:id/edit`} component={StrRmEdit} />
        <Route path={`${path}/:id/summary`} component={StrRmSummaryWithItems} />
        <Route path={`${path}/:id/prepare`} component={StrRmVerify} />
        <Route path={`${path}/:id/history`} component={StrRmHistory} />
        <Route path={`${path}/:id/documents`} component={StrRmDocument} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymList} />
        <Route path={`${path}/add`} component={PrymAdd} />
        <Route path={`${path}/upload`} component={PrymUpload} />
        <Route path={`${path}/pending`} component={PrymPendingLineList} />
        <Route path={`${path}/:id/edit`} component={PrymEdit} />
        <Route path={`${path}/:id/summary`} component={PrymSummaryWithItems} />
        <Route path={`${path}/:id/verify`} component={PrymVerify} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/upload`} component={Upload} />
        <Route path={`${path}/:id/edit`} component={Edit} />
        <Route path={`${path}/:id/summary`} component={Summary} />
      </Switch>
    );
  }
}

export { MaterialRequests };
