import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Form,
  PageHeader,
  Popconfirm,
  Table,
  message,
  Row,
  Col,
  Input,
  Tooltip,
  Typography,
  Tag,
} from "antd";
import Page from "_components/Page";
import { binService, materialDetailService } from "_services";
import { CloseOutlined } from "@ant-design/icons";

const StrTransfer = ({ match, history }) => {
  const { id } = match.params;
  const { path } = match;
  const [stockItems, setStockItems] = useState([]);
  const [bin, setBin] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const { Text } = Typography;

  useEffect(() => {
    setLoading(true);
    binService
      .getById(id)
      .then((x) => {
        setLoading(false);
        let selectedBin = x.content;
        setBin(selectedBin);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
        setError(error);
      });
  }, []);

  const onSearch = (values) => {
    if (!values) {
      return;
    } else {
      if (values.barcode) {
        if (
          stockItems.find(
            (item) =>
              item.serialNo.toLowerCase() === values.barcode.toLowerCase()
          )
        ) {
          message.info("Already scanned this item");
          form.setFieldsValue({ barcode: null });
          return;
        }
        setLoading(true);
        const searchParams = `?barcode=${encodeURIComponent(values.barcode)}`;
        materialDetailService
          .getByBarcode(searchParams)
          .then((data) => {
            if (data && data.content) {
              // console.log('Stock result: ', data.content);
              //stockItems.push(data.content);
              setStockItems([...stockItems, data.content]);
              // console.log('Stock results: ', stockItems);
              form.setFieldsValue({ barcode: null });
            } else {
              message.error("Sorry, No item found against the barcode.");
            }
            setLoading(false);
          })
          .catch((error) => {
            form.setFieldsValue({ barcode: null });
            message.error(`${error}`);
            setError(error);
            setLoading(false);
          });
      } else {
        message.error("Please enter valid Stock barcode.");
        return;
      }
    }
  };

  const handleStockTransfer = () => {
    const payload = {
      binBarcode: bin.code,
      serialNos: stockItems.map((item) => item.serialNo),
    };
    setLoadingTransfer(true);
    binService
      .addItems(payload)
      .then((data) => {
        setLoadingTransfer(false);
        message.success("Successfully transferred!");
        history.push("/stock-transfer");
      })
      .catch((error) => {
        setError(error);
        setLoadingTransfer(false);
      });
  };

  const handleRemove = (item) => {
    const items = [...stockItems];
    if (items.includes(item)) {
      items.splice(items.indexOf(item), 1);
      setStockItems(items);
    }
  };

  const onCancel = () => {
    history.push(".");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        key="1"
        title="Are you sure to transfer there stocks?"
        onConfirm={handleStockTransfer}
      >
        <Button type="primary" htmlType="submit" loading={loadingTransfer}>
          Trasfer {stockItems.length > 0 && stockItems.length}
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Serial No.",
      dataIndex: "serialNo",
      key: "serialNo",
      width: "18%",
      fixed: "left",
      render: (text, record) => {
        return (
          <>
            <Link to={`/material-details/${record.id}/summary`}>{text}</Link>
            <Input value={record.id} hidden />
          </>
        );
      },
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "6%",
      fixed: "left",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Sub Location Code",
      key: "subLocation",
      fixed: "left",
      render: (text, record) => {
        return record.stockBin.subLocation.shortCode;
      },
    });
    columns.push({
      title: "Material Code",
      dataIndex: "stockType",
      key: "stockType",
      width: "8%",
      fixed: "left",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "8%",
      fixed: "left",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.lotNumber) {
          return (
            <Tooltip placeholder="topLeft" title={record.lotNumber.number}>
              {record.lotNumber.number}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Rec. Date",
      key: "receivedDate",
      dataIndex: "receivedDate",
      width: "15%",
    });
    /* columns.push({
      title: "Prod Date",
      key: "productionDate",
      dataIndex: "productionDate",
      width: "9%",
    }); */
    columns.push({
      title: "Supplier",
      key: "supplier",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.stockType.supplier.name}
            >
              {record.stockType.supplier.code}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "8%",
      fixed: "right",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      width: "5%",
      fixed: "right",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "6%",
      fixed: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "",
      key: "operation",
      width: "6%",
      fixed: "right",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to remove?"
            onConfirm={() => handleRemove(record)}
          >
            <Button size="small" icon={<CloseOutlined />} />
          </Popconfirm>
        );
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-transfer">Stock Transfer</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                Bin: {bin.code} ({bin.subLocation?.shortCode})
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Transfer</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            form={form}
            onFinish={onSearch}
            layout="vertical"
            className="search-form"
          >
            <Row gutter={24}>
              <Col span={18}>
                <Form.Item name="barcode" label="Stock Item Barcode">
                  <Input placeholder="Stock Item Barcode" allowClear />
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <div>
                  <Button type="primary" htmlType="submit">
                    Scan
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => {
                      form.resetFields();
                      onSearch({});
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            dataSource={stockItems}
            pagination={false}
            summary={(pageData) => {
              let totalQuantity = 0;
              let totalNetWeight = 0;

              pageData.forEach(({ quantity, netWeight }) => {
                totalQuantity += quantity;
                totalNetWeight += netWeight;
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={7}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong style={{ paddingLeft: 12 }}>
                        {stockItems.length}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>{totalQuantity}</Table.Summary.Cell>
                    <Table.Summary.Cell>{totalNetWeight}</Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrTransfer;
