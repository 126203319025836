import {
  Button,
  Form,
  PageHeader,
  Row,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
  Divider,
  Select,
  Table,
  Tag,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Page from "_components/Page";
import {
  sbuService,
  stockCheckService,
  subLocationService,
  zoneService,
} from "_services";

const StrStockItemComparisonUpload = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [storeAddresses, setStoreAddresses] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [itemsMatched, setItemsMatched] = useState([]);
  const [itemsNew, setItemsNew] = useState([]);
  const [itemsNotMatchedAndExistInSystem, setItemsNotMatchedAndExistInSystem] =
    useState([]);
  const [
    itemsNotMatchedAndNotExistInSystem,
    setItemsNotMatchedAndNotExistInSystem,
  ] = useState([]);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setError("");
        setItemsMatched([]);
        setItemsNew([]);
        setItemsNotMatchedAndExistInSystem([]);
        setItemsNotMatchedAndNotExistInSystem([]);

        setIsLoading(true);

        const payload = {
          base64FileString: base64File,
          originalFileName: originalFileName,
          checkedBy: values.checkedBy,
          subLocationId: values.subLocationId,
        };

        stockCheckService
          .uploadStockCheck(payload)
          .then((response) => {
            message.success("Successfully uploaded!");
            setIsLoading(false);
            form.resetFields();
            console.log("Results: ", response.content);
            let result = response.content;
            setItemsMatched(result.stockItemsMatched);
            setItemsNew(result.stockItemsNew);
            setItemsNotMatchedAndExistInSystem(
              result.stockItemsNotMatchedAndExistInSystem
            );
            setItemsNotMatchedAndNotExistInSystem(
              result.stockItemsNotMatchedAndNotExistInSystem
            );
            //history.push(`/reports/item-comparisons/${response.content.id}`);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
            // form.resetFields();
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/reports/item-comparisons");
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setOriginalFileName(e.target.files[0].name);
      let reader = new FileReader();
      reader.onload = function (e) {
        setBase64File(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "Submit Bin Code",
      dataIndex: "binCode",
      key: "binCode",
    });
    columns.push({
      title: "Submit Box Serial",
      dataIndex: "serialNumber",
      key: "serialNumber",
    });
    columns.push({
      title: "System Bin",
      key: "systemStockBin",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "System Serial",
      key: "systemSerial",
      dataIndex: "serialNo",
    });
    columns.push({
      title: "Status",
      key: "mode",
      dataIndex: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/item-comparisons">Stock Item Comparisons</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Stock Item Comparison Upload</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            title="Are you sure?"
            onConfirm={handleFormSumbit}
            key="add-confirm"
          >
            <Button
              key="1"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Save
            </Button>
          </Popconfirm>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={4}>
                  <Form.Item name="sbuId" label="SBU">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="zoneId" label="Zone">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled={!selectedSbuId}
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="subLocationId"
                    label="Sub-Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub-Location",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      disabled={!selectedZoneId}
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="checkedBy"
                    label="Checked By"
                    rules={[
                      {
                        required: true,
                        message: "Checked By User is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="file"
                    label="Stock Item Comparison File"
                    rules={[
                      {
                        required: true,
                        message: "Valid excel file is required",
                      },
                    ]}
                    valuePropName="file"
                  >
                    <Input
                      type="file"
                      name="file"
                      onChange={onFileChange}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ paddingTop: 40 }}>
                  <>
                    <CSVLink
                      key="csv-file"
                      data={[]}
                      filename={"Sample-Stock-Item-Comparison-File.csv"}
                      headers={[
                        { label: "Bin Code", key: "binCode" },
                        { label: "Box Serial", key: "boxSerial" },
                      ]}
                    >
                      Sample Item Comparison File
                    </CSVLink>
                  </>
                </Col>
              </Row>
            </Form>

            <Row gutter={24}>
              {itemsMatched.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">Matched</Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsMatched}
                    pagination={false}
                    scroll={{ x: 400 }}
                    //   summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
              {itemsNew.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">New</Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsNew}
                    pagination={false}
                    scroll={{ x: 400 }}
                    //   summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
            </Row>

            <Row gutter={24}>
              {itemsNotMatchedAndExistInSystem.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">
                    Not Matched & ExistInSystem
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsNotMatchedAndExistInSystem}
                    pagination={false}
                    scroll={{ x: 400 }}
                    //   summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
              {itemsNotMatchedAndNotExistInSystem.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">
                    Not Matched & Not ExistInSystem
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsNotMatchedAndNotExistInSystem}
                    pagination={false}
                    scroll={{ x: 400 }}
                    //   summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
            </Row>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrStockItemComparisonUpload;
