import React, { Component } from "react";
import classnames from "classnames";
import "./index.css";
import { Alert } from "antd";

class Page extends Component {
  render() {
    const {
      className,
      children,
      loading = false,
      inner = false,
      error = null,
    } = this.props;

    const loadingStyle = {
      height: "calc(100vh - 184px)",
      overflow: "hidden",
    };

    let errorMessage = error;
    if (error) {
      errorMessage = error;
      if (error.response) {
        if (
          error.response.data &&
          error.response.data.validationFailures &&
          Array.isArray(error.response.data.validationFailures)
        ) {
          errorMessage = error.response.data.validationFailures
            .map((el) => el.code)
            .join("<br />");
        } else if (error.response.status === 403) {
          errorMessage = error.response.data.error_description;
        } else if (error.response.status === 417) {
          //console.log("Error:",  error.response.data);
          errorMessage = error.response.data.content.message;
        } else if (error.response.status === 500) {
          errorMessage = `Oops, something went wrong(${error.response.data.error}). Please try again later`;
        } else if (error.response.status === 404) {
          errorMessage = `Sorry, requested recourse not found.`;
        }
      } else if (error.request) {
        errorMessage = error.request.responseText
          ? JSON.parse(error.request.responseText).error_description
          : error.request.statusText;
        //JSON.parse(error.request.responseText).error_description ||
        //error.request.statusText;
      }
    } else {
      errorMessage = "";
    }

    return (
      <div
        className={classnames(className, {
          contentInner: inner,
        })}
        style={loading ? loadingStyle : null}
      >
        {error && (
          <Alert
            message="Error"
            description={errorMessage}
            type="error"
            showIcon
            closable
            style={{ marginBottom: 20 }}
          />
        )}

        {children}
      </div>
    );
  }
}

export default Page;
