import {
  Button,
  InputNumber,
  Select,
  Space,
  message,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useState } from "react";

const TableForm = ({
  value,
  onChange,
  materialTypes,
  lotNumbers,
  measurementTypes,
  onFindStock,
  ...props
}) => {
  //console.log("Values: ", value);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(value);
  const precision = 2;
  const { Option } = Select;
  const { Text } = Typography;

  const getRowByKey = (key, newData) =>
    (newData || data)?.filter((item) => item.key === key)[0];

  const handleAddOrderLine = () => {
    const newData = data?.map((item) => ({ ...item })) || [];

    newData.push({
      key: `NEW_TEMP_ID_${index}`,
      quantity: null,
    });

    setIndex(index + 1);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const handleRemoveOrderLine = (key) => {
    const newData = data?.filter((item) => item.key !== key);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const handleSaveOrderLine = (e, key) => {
    e.persist();
    const target = getRowByKey(key) || {};
    //console.log("Targe:", target);
    if (!target.stockType || !target.quantity) {
      message.error("Please complete the requested item information.");
      e.target.focus();
      return;
    } else {
      handleAddOrderLine();
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const newData = [...data];
    const target = getRowByKey(key, newData);
    if (target) {
      if (["stockType", "lotNumber", "measurementType"].includes(fieldName)) {
        target[fieldName] = { id: value };
      } else {
        target[fieldName] = value;
      }

      setData(newData);
    }
  };

  const handleFindStocks = (line) => {
    if (line.stockType) {
      onFindStock(line);
    } else {
      message.error("Please select valid Material Type");
    }
  };

  const columns = [
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.stockType &&
        a.stockType.code &&
        b.stockType &&
        b.stockType.code &&
        a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return (
          <Select
            value={record.stockType && record.stockType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "stockType", record.key)}
          >
            {materialTypes.length > 0 &&
              materialTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.code}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lotNumber.number.localeCompare(b.lotNumber.number),
      render: (text, record) => {
        return (
          <Select
            value={record.lotNumber && record.lotNumber.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
          >
            {lotNumbers &&
              lotNumbers.length > 0 &&
              lotNumbers.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.number}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Boxes",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleFindStocks(record);
              }}
              icon={<SearchOutlined />}
              loading={
                record.key === props.selectedLineKey &&
                props.availableStocksLoading
              }
            >
              Find Stocks
            </Button>
            <Popconfirm
              title="Are you sure to remove?"
              onConfirm={() => handleRemoveOrderLine(record.key)}
              key="remove-confirm"
            >
              <Button
                size="small"
                icon={<CloseOutlined />}
                //onClick={() => handleRemoveOrderLine(record.key)}
              ></Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveOrderLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {data.length === 0 && (
        <Button
          style={{
            width: "100%",
            marginTop: 16,
            marginBottom: 8,
            background: "rgb(190, 200, 200)",
          }}
          type="dashed"
          onClick={handleAddOrderLine}
          icon={<PlusOutlined />}
        >
          Add Item
        </Button>
      )}
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        summary={(pageData) => {
          let totalQuantity = 0;
          let totalNetWeight = 0;
          let totalGrossWeight = 0;

          pageData.forEach(({ quantity, netWeight, grossWeight }) => {
            totalQuantity += quantity;
            totalNetWeight += netWeight;
            totalGrossWeight += grossWeight;
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2}>
                <Text strong>Total</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong style={{ paddingLeft: 12 }}>
                  {totalQuantity}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>
          );
        }}
      />
    </>
  );
};

export default TableForm;
