import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/vehicleTypes`;

export const vehicleTypeService = {
  getSuggestion,
  create,
  delete: _delete,
};

function getSuggestion() {
  return fetchWrapper.get(`${baseUrl}/suggestion`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
