import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Popconfirm,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { binLocationService, binService, subLocationService } from "_services";
import TabMenu from "../TabMenu";
import Search from "./Search";
import { Auth } from "_helpers";

function List({ match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [subLocation, setSubLocation] = useState({});
  const [stockBins, setStockBins] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState(`?subLocationId=${id}`);

  useEffect(() => {
    setLoading(true);

    subLocationService
      .getById(id)
      .then((data) => {
        setSubLocation(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    getStockBins(pageNumber, pageSize);
  }, []);

  const getStockBins = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}&sortDirection=asc`;

    setLoading(true);
    binService
      .searchWithPaging(`${searchParams}${paginationParams}`)
      .then((data) => {
        setStockBins(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setStockBins(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getStockBins(pageNumber, pageSize);
  };

  const handleDelete = (binId) => {
    subLocationService
      .removeBin(id, binId)
      .then((data) => {
        message.success("Successfully deleted!");
        document.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleDeleteBins = () => {
    setDeleting(true);
    subLocationService
      .removeBins(id, selectedRowKeys)
      .then((data) => {
        setDeleting(false);
        message.success("Successfully deleted!");
        document.location.reload();
      })
      .catch((error) => {
        setDeleting(false);
        setError(error);
      });
  };

  const getAdditionalButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Button key="buld-remove" onClick={handleDeleteBins} loading={deleting}>
          Remove Bins ({selectedRowKeys.length})
        </Button>
      );
    }
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Link to={`${path}/add`.replace(":id", id)} key="1">
          <Button>Add Bins</Button>
        </Link>
      );
    }

    return buttons;
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.availableStockQuantity > 0,
    }),
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => (
        <Link to={`/bins/${record.id}/summary`} target="_blank">
          {text}
        </Link>
      ),
    },
    {
      title: "SBU",
      dataIndex: "sbuName",
      key: "sbuName",
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      key: "zoneName",
    },
    {
      title: "Available Stocks",
      dataIndex: "availableStockQuantity",
      key: "availableStockQuantity",
    },
    {
      title: "Available N.Weight",
      dataIndex: "availableNetWeight",
      key: "availableNetWeight",
    },
    {
      title: "Available G.Weight",
      dataIndex: "availableGrossWeight",
      key: "availableGrossWeight",
    },
    {
      title: "Available Cones",
      dataIndex: "availableQuantity",
      key: "availableQuantity",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDelete(record.id)}
            disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
          >
            <CloseOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/sub-locations">
              <span>Sub-Location Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/sub-locations/${subLocation.id}/summary`}>
              <span>
                Sub-Location: {subLocation.name} (
                {subLocation.availableStockQuantity})
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Bins</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="bins" id={id} />
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
            subLocationId={id}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={stockBins}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
            rowSelection={rowSelection}
          />
        </Page>
      </PageHeader>
    </div>
  );
}

export default List;
