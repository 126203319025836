import {
  Button,
  Input,
  Table,
  Modal,
  message,
  Progress,
  Typography,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import StockBinSelection from "../StockBinSelection";
import { Auth } from "_helpers";

const TableForm = ({ value, onAddQuantity, stockBins }) => {
  //console.log("Values: ", value.receiptLines);
  const [loading, setLoading] = useState(false);
  //const [index, setIndex] = useState(0);
  const [data, setData] = useState(value);
  const [isBinSelectionVisible, setIsBinSelectionVisible] = useState(false);
  const [orderLine, setOrderLine] = useState(null);
  const [receiptLines, setReceiptLines] = useState([]);
  const [errorQuantity, setErrorQuantity] = useState(false);
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  //const [stockBins] = useState(stockBinList);

  const { Text } = Typography;

  const showBinSelection = (orderLine) => {
    setOrderLine(orderLine);
    setIsBinSelectionVisible(true);
  };

  const handleBinQuantityChange = (orderLineId, binId, quantity) => {
    //console.log("LineId: ",orderLineId,", binId: ",binId,", Qty: ",quantity);
    let receiptLine = receiptLines.find(
      (item) => item.orderLineId === orderLineId && item.binId === binId
    );
    if (receiptLine) {
      receiptLine.accepted = quantity;
      onAddQuantity(quantity);
    } else {
      let receiptLine = {
        orderLineId: orderLineId,
        binId: binId,
        accepted: quantity,
      };
      receiptLines.push(receiptLine);
      onAddQuantity(quantity);
    }

    data.receiptLines = receiptLines;
    let orderLine = data.find((item) => item.id === orderLineId);
    if (orderLine) {
      orderLine.totalAccepted = receiptLines
        .filter((item) => item.orderLineId === orderLine.id)
        .map((item) => item.accepted)
        .reduce((accumulator, currentValue) => accumulator + currentValue);

      if (orderLine.totalAccepted > orderLine.quantity) {
        setErrorQuantity(true);
      } else {
        setErrorQuantity(false);
      }
    }
  };

  const handleOk = () => {
    if (errorQuantity) {
      message.error("Accepted quantity can't be exceed the ordered quantity");
    } else {
      setIsBinSelectionVisible(false);
    }
  };

  const handleCancel = () => {
    if (errorQuantity) {
      message.error("Accepted quantity can't be exceed the ordered quantity");
    } else {
      setIsBinSelectionVisible(false);
    }
  };

  const columns = [
    {
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return (
          <>
            <span>{record.stockType.code}</span>
            <Progress
              size="small"
              percent={
                parseInt(
                  record.totalAcceptedQA +
                    (record.totalAccepted ? record.totalAccepted : 0) /
                      record.quantity
                ) * 100
              }
            />
          </>
        );
      },
    },
    {
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lotNumber.number.localeCompare(b.lotNumber.number),
      render: (text, record) => {
        return <span>{record.lotNumber && record.lotNumber.number}</span>;
      },
    },
    /* {
      title: "Stock Class",
      dataIndex: "stockClass",
      key: "stockClass",
      //width: "30%",
      render: (text, record) => {
        return <span>{record.stockClass.name}</span>;
      },
    }, */
    /* {
      title: "Packing Type",
      dataIndex: "packingType",
      key: "packingType",
      //width: "30%",
      render: (text, record) => {
        return <span>{record.packingType.name}</span>;
      },
    }, */
    /* {
      title: "Measurement Type",
      dataIndex: "measurementType",
      key: "measurementType",
      //width: "30%",
      render: (text, record) => {
        return <span>{record.measurementType.name}</span>;
      },
    }, */
    {
      title: "Ordered",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    },
    {
      title: "Accepted",
      key: "totalAcceptedQA",
      render: (text, record) => {
        return (
          <Input
            value={
              record.totalAcceptedQA +
              (record.totalAccepted ? record.totalAccepted : 0)
            }
            addonAfter={
              <Button
                type="primary"
                onClick={() => showBinSelection(record)}
                icon={<SettingOutlined />}
                /* disabled={
                  record.totalAcceptedQA +
                    (record.totalAccepted ? record.totalAccepted : 0) ===
                  record.quantity
                } */
              ></Button>
            }
            disabled
            style={{ width: "60%", padding: 0 }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Table
        bordered
        size="small"
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        rowClassName={(record) =>
          record.totalAccepted > 0
            ? "blue-1"
            : record.totalAccepted === record.quantity
            ? "blue-4"
            : ""
        }
        summary={(pageData) => {
          let totalQuantity = 0;
          let totalQuantityAdded =
            receiptLines.length > 0 &&
            receiptLines
              .map((item) => item.accepted)
              .reduce((prev, next) => prev + next);

          pageData.forEach(({ quantity }) => {
            totalQuantity += quantity;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <Text strong>Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <Text strong>{totalQuantity}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong style={{ paddingLeft: 12 }}>
                    {totalQuantityAdded}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <Modal
        title={
          <div>
            Item: {orderLine && orderLine.stockType && orderLine.stockType.code}
            , LOT:{" "}
            {orderLine && orderLine.lotNumber && orderLine.lotNumber.number},
            Bins - [
            {receiptLines.length > 0 &&
              receiptLines.filter((obj) => obj.orderLineId === orderLine.id)
                .length > 0 &&
              receiptLines
                .filter((obj) => obj.orderLineId === orderLine.id)
                .map((obj) => obj.accepted)
                .reduce((prev, next) => prev + next)}
            /{orderLine && orderLine.quantity}]
          </div>
        }
        style={{ top: 48, height: 150 }}
        visible={isBinSelectionVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        class="bin-selection"
        width={2000}
      >
        <StockBinSelection
          onQuantityChange={handleBinQuantityChange}
          orderLine={orderLine}
          receiptLines={receiptLines}
          stockBins={stockBins}
        />
      </Modal>
    </div>
  );
};

export default TableForm;
