import {
  Button,
  Col,
  Form,
  Row,
  message,
  Select,
  DatePicker,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { companyService, reportService } from "_services";
import { Auth } from "_helpers";

function ComparisonSearch(props) {
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [form] = Form.useForm();
  let dateFilter = false;
  let fromDate = null;
  let toDate = null;

  const { Option } = Select;

  useEffect(() => {
    companyService
      .getSuggestion("?companyType=STORE")
      .then((x) => {
        if (x.content.length === 1) {
          let companyId = Auth.getProfile().comp_id
            ? Auth.getProfile().comp_id
            : x.content[0].id;
          companyService.getById(companyId).then((data) => {
            const storeLocationAddresses = data.content.addresses.filter(
              (item) => item.addressType === "WAREHOUSE"
            );
            setAddresses(storeLocationAddresses);
            if (storeLocationAddresses.length === 1) {
              form.setFieldsValue({
                storeLocationId: storeLocationAddresses[0].id,
              });
            }
          });
        }
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }, []);

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";

    if (values.requestDateRange) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `fromDate=${encodeURIComponent(
        moment(values.requestDateRange[0]).startOf("day").format("YYYY-MM-DD")
      )}&toDate=${moment(values.requestDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD")}`;
      fromDate = moment(values.requestDateRange[0])
        .startOf("day")
        .format("YYYY-MM-DD");
      toDate = moment(values.requestDateRange[1])
        .startOf("day")
        .format("YYYY-MM-DD");
      dateFilter = true;
    }
    /* if (values.storeLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `storeLocationId=${encodeURIComponent(
        values.storeLocationId
      )}`;
    } */
    if (values.subLocationId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `&subLocationId=${values.subLocationId}`;
    }
    if (values.zoneId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `&zoneId=${values.zoneId}`;
    }
    if (values.sbuId) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `&sbuId=${values.sbuId}`;
    }

    reportService
      .searchComparisons(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="requestDateRange" label="Receive Date">
            <DatePicker.RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="code" label="Comparison Number">
            <Input placeholder="Comparison Number" allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="compareWith" label="Compare With">
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="FMIS" key="FMIS">
                FMIS
              </Option>
              <Option value="EPICO" key="EPICO">
                EPICO
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="storeLocationId" label="Store Location">
            <Select
              placeholder="Please select"
              allowClear
              disabled={addresses.length === 1}
            >
              {addresses.length > 0 &&
                addresses.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.address}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="sbuId" label="SBU">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
                setSelectedSbuId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ zoneId: null });
                form.setFieldsValue({ subLocationId: null });
              }}
            >
              {props.businessUnits.length > 0 &&
                props.businessUnits.map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="zoneId" label="Zone">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ subLocationId: null });
                setSelectedZoneId(e);
              }}
              onClear={() => {
                form.setFieldsValue({ subLocationId: null });
              }}
              disabled={!selectedSbuId}
            >
              {props.zones.length > 0 &&
                props.zones
                  .filter((item) => item.sbu.id === selectedSbuId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="subLocationId" label="Sub-Location">
            <Select
              placeholder="Please select"
              allowClear
              optionFilterProp="children"
              showSearch
              disabled={!selectedZoneId}
            >
              {props.subLocations.length > 0 &&
                props.subLocations
                  .filter((item) => item.zone.id === selectedZoneId)
                  .map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={4}></Col>
      </Row>
    </Form>
  );
}

export default ComparisonSearch;
