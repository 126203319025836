import {
  Breadcrumb,
  PageHeader,
  Table,
  Typography,
  Statistic,
  Row,
  Col,
  Card,
  Button,
  Divider,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
//import { PDFDownloadLink } from "@react-pdf/renderer";
import Page from '_components/Page';
//import { PdfAvailableStocks } from "./PdfAvailableStocks";
import { reportService } from '_services';
import { FileExcelOutlined } from '@ant-design/icons';
import StockInOutSummarySearch from './StockInOutSummarySearch';

const StockInOutSummary = () => {
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [items, setItems] = useState([]);
  const [inItems, setInItems] = useState([]);
  const [outItems, setOutItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const { Title } = Typography;
  const precision = 2;

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    setLoading(true);
    let searchParams = '';
    searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
    searchParams += `fromDate=${encodeURIComponent(
      moment().format('YYYY-MM-DD')
    )}&toDate=${moment().format('YYYY-MM-DD')}`;
    setDateFilter(true);
    setFromDate(moment().format('YYYY-MM-DD'));
    setToDate(moment().format('YYYY-MM-DD'));
    setSearchQuery(searchParams);
    reportService
      .getStockInOutSummary(searchParams)
      .then((data) => {
        addInOutItems(data.content);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addInOutItems = (items) => {
    if (items && items.length > 0) {
      setInItems(items.filter((item) => item.type === 'IN'));
      setOutItems(items.filter((item) => item.type === 'OUT'));
    } else {
      setInItems([]);
      setOutItems([]);
    }
    setItems(items);
  };

  const handleSearchResults = (
    results,
    fromDate,
    toDate,
    isFilterEnabled,
    searchParams
  ) => {
    addInOutItems(results);
    setFromDate(fromDate);
    setToDate(toDate);
    setDateFilter(isFilterEnabled);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    reportService
      .exportStockInOutSummaryExcel(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Stocks-InOut-Summary_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: 'Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: '8%',
    });
    columns.push({
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: '15%',
    });
    columns.push({
      title: 'MRN Number',
      dataIndex: 'mrnNumber',
      key: 'mrnNumber',
      width: '10%',
    });
    columns.push({
      title: 'Total Box',
      dataIndex: 'totalItems',
      key: 'totalItems',
      align: 'right',
      width: '5%',
    });
    columns.push({
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      align: 'right',
      width: '5%',
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });
    columns.push({
      title: 'Seal Number',
      dataIndex: 'sealNumber',
      key: 'sealNumber',
      align: 'right',
      width: '5%',
    });
    columns.push({
      title: 'Vehicle',
      dataIndex: 'vehicleNumber',
      key: 'vehicleNumber',
      width: '5%',
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/reports'>Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Stock In-Out Summary</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <StockInOutSummarySearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
            />
            {!dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: {moment().format('YYYY-MM-DD')}
              </Title>
            )}
            {dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: From:{moment(fromDate).format('YYYY-MM-DD')} - To:
                {moment(toDate).format('YYYY-MM-DD')}
              </Title>
            )}
            {items && items.length > 0 && (
              <div
                style={{
                  background: '#2d3147',
                  padding: '15px',
                  marginBottom: 15,
                }}
              >
                <Row gutter={24}>
                  <Col span={11}>
                    <Card>
                      <Statistic
                        title='Total In-Stocks(Box)'
                        value={
                          inItems.filter((item) => item.totalItems !== null)
                            .length > 0
                            ? inItems
                                .filter((item) => item.totalItems !== null)
                                .map((item) => item.totalItems)
                                .reduce((prev, next) => prev + next)
                            : 0
                        }
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col span={11}>
                    <Card>
                      <Statistic
                        title='Total Out-Stocks(Box)'
                        value={
                          outItems.filter((item) => item.totalItems !== null)
                            .length > 0
                            ? outItems
                                .filter((item) => item.totalItems !== null)
                                .map((item) => item.totalItems)
                                .reduce((prev, next) => prev + next)
                            : 0
                        }
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col span={2}>
                    <Button
                      type='primary'
                      onClick={handleExportExcel}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FileExcelOutlined />}
                      loading={loadingExcel}
                    >
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <Row gutter={24}>
              <Col span={12}>
                <Divider orientation='left'>IN Stocks</Divider>
                <Table
                  rowKey='id'
                  bordered
                  loading={loading}
                  size='small'
                  columns={getColumns()}
                  dataSource={inItems}
                  pagination={false}
                  // scroll={{ x: 1300 }}
                  summary={(pageData) => {
                    let totalStocks = 0;
                    let totalNetWeight = 0;

                    pageData.forEach(
                      ({ totalItems, netWeight }) => {
                        totalStocks += totalItems;
                        totalNetWeight += netWeight;
                      }
                    );

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={3}>
                            <Typography.Text strong>Total</Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align='right'>
                            <Typography.Text strong>
                              {totalStocks}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align='right'>
                            <Typography.Text strong>
                              {totalNetWeight &&
                                Number(totalNetWeight).toFixed(precision)}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} />
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </Col>
              <Col span={12}>
                <Divider orientation='left'>OUT Stocks</Divider>
                <Table
                  rowKey='id'
                  bordered
                  loading={loading}
                  size='small'
                  columns={getColumns()}
                  dataSource={outItems}
                  pagination={false}
                  // scroll={{ x: 1300 }}
                  summary={(pageData) => {
                    let totalStocks = 0;
                    let totalNetWeight = 0;

                    pageData.forEach(
                      ({ totalItems, netWeight }) => {
                        totalStocks += totalItems;
                        totalNetWeight += netWeight;
                      }
                    );

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={3}>
                            <Typography.Text strong>Total</Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align='right'>
                            <Typography.Text strong>
                              {totalStocks}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align='right'>
                            <Typography.Text strong>
                              {totalNetWeight &&
                                Number(totalNetWeight).toFixed(precision)}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} />
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </Col>
            </Row>
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default StockInOutSummary;
