import {
  Button,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Select,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Page from "_components/Page";
import { Auth } from "_helpers";
import {
  companyService,
  extraService,
  sbuService,
  subLocationService,
  zoneService,
} from "_services";

const StrComparisonUpload = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  useEffect(() => {
    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
      });
      setStoreAddresses(grantedStoreAddresses);
      setIsLoading(false);
    });
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);

        const payload = {
          storeAddressId: values.storeAddress.id,
          base64FileString: base64File,
          originalFileName: originalFileName,
          note: values.note,
          compareWith: values.compareWith,
          subLocationId: values.subLocationId,
        };

        extraService
          .uploadStockComparison(payload)
          .then((response) => {
            message.success("Successfully uploaded!");
            setIsLoading(false);
            history.push(`/reports/comparisons/${response.content.id}`);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/reports/comparisons");
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setOriginalFileName(e.target.files[0].name);
      let reader = new FileReader();
      reader.onload = function (e) {
        setBase64File(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/comparisons">Comparisons</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Comparison Upload</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            title="Are you sure?"
            onConfirm={handleFormSumbit}
            key="add-confirm"
          >
            <Button
              key="1"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Save
            </Button>
          </Popconfirm>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["storeAddress", "id"]}
                    label="Store Address"
                    rules={[
                      {
                        required: true,
                        message: "Delivery address is required",
                      },
                    ]}
                  >
                    <Select placeholder="Please select" disabled>
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sbuId" label="SBU">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="zoneId" label="Zone">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled={!selectedSbuId}
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="subLocationId"
                    label="Sub-Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub-Location",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      disabled={!selectedZoneId}
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="compareWith"
                    label="Compare With"
                    rules={[
                      {
                        required: true,
                        message: "Please select valid value",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="FMIS" key="FMIS">
                        FMIS
                      </Option>
                      <Option value="EPICO" key="EPICO">
                        EPICO
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="note" label="Note">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="file"
                    label="Stock Comparison File"
                    rules={[
                      {
                        required: true,
                        message: "Valid excel file is required",
                      },
                    ]}
                    valuePropName="file"
                  >
                    <Input
                      type="file"
                      name="file"
                      onChange={onFileChange}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingTop: 40 }}>
                  <>
                    <CSVLink
                      key="csv-file"
                      data={[]}
                      filename={"Sample-Stock-Comparison-File.csv"}
                      headers={[
                        { label: "Material", key: "material" },
                        { label: "Lot No", key: "lotNo" },
                        { label: "Quantity", key: "quantity" },
                      ]}
                    >
                      Sample Comparison File
                    </CSVLink>
                  </>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrComparisonUpload;
