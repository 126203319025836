import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Spin,
  Typography,
  Table,
  Tag,
  Divider,
} from "antd";
import Page from "_components/Page";
import { stockCheckService } from "_services";

function StrStockItemComparisonSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [stockComparison, setStockComparison] = useState({});
  const [itemsMatched, setItemsMatched] = useState([]);
  const [itemsNotInBin, setItemsNotInBin] = useState([]);
  const [itemsNotInSystem, setItemsNotInSystem] = useState([]);
  const [itemsNotInBinNotInSystem, setItemsNotInBinNotInSystem] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [error, setError] = useState(null);
  const { Text } = Typography;
  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    stockCheckService
      .getByStockCheckUploadId(id)
      .then((x) => {
        let result = x.content;
        // console.log("Results: ", result);

        setStockComparison(result);

        if (result.serialNos.length > 0) {
          setItemsMatched(
            result.serialNos.filter((item) => item.status === "MATCHED")
          );
          setItemsNotInBin(
            result.serialNos.filter((item) => item.status === "PRESENT_NOT_BIN")
          );
          setItemsNotInSystem(
            result.serialNos.filter((item) => item.status === "NOT_IN_SYSTEM")
          );
          setItemsNotInBinNotInSystem(
            result.serialNos.filter(
              (item) => item.status === "NOT_BIN_AND_NOT_SYSTEM"
            )
          );
        }

        onFill(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        setError(errorInfo);
        // message.error(`${errorInfo}`);
      });
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/reports/item-comparisons");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getColumns = () => {
    let columns = [];
    /* columns.push({
      title: "Submit Bin Code",
      dataIndex: "binCode",
      key: "binCode",
    }); */
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNumber",
      key: "serialNumber",
    });
    columns.push({
      title: "System Bin",
      dataIndex: "systemBinCode",
      key: "systemBinCode",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      key: "mode",
      dataIndex: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  const getSummaryContent = (pageData) => {
    let totalCount = 0;
    let totalNetWeight = 0;

    pageData.forEach(({ netWeight }) => {
      totalCount += 1;
      totalNetWeight += netWeight && netWeight;
    });

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={1}>
            <Text strong>Total</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{totalCount}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right"></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports/item-comparisons">
                Stock Item Comparison Report Search
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {/* <Link to={`${path}`.replace(":id", id)}>
                {stockComparison.code} ::{" "}
                <Tag
                  color={`${
                    stockComparison.status === "COMPLETE"
                      ? "#87d068"
                      : stockComparison.status === "IN_PROGRESS"
                      ? "#faad14"
                      : "#2db7f5"
                  }`}
                >
                  {stockComparison.status}
                </Tag>
              </Link> */}
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip={loadingMessage} spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="stockBinCode" label="Bin Code">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="noBoxScan" label="Scanned Box">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="createdUserId" label="Scanned By">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
            </Form>
            <Row gutter={24}>
              {itemsMatched.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">
                    Matched - {itemsMatched.length}
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsMatched}
                    pagination={false}
                    scroll={{ x: 400 }}
                    summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
              {itemsNotInBin.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">
                    Not In Bin - {itemsNotInBin.length}
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsNotInBin}
                    pagination={false}
                    scroll={{ x: 400 }}
                    summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
            </Row>

            <Row gutter={24}>
              {itemsNotInSystem.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">
                    Not In System - {itemsNotInSystem.length}
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsNotInSystem}
                    pagination={false}
                    scroll={{ x: 400 }}
                    summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
              {itemsNotInBinNotInSystem.length > 0 && (
                <Col span={12}>
                  <Divider orientation="left">
                    Not In Bin & Not In System -{" "}
                    {itemsNotInBinNotInSystem.length}
                  </Divider>
                  <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={getColumns()}
                    dataSource={itemsNotInBinNotInSystem}
                    pagination={false}
                    scroll={{ x: 400 }}
                    summary={(pageData) => getSummaryContent(pageData)}
                  />
                </Col>
              )}
            </Row>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrStockItemComparisonSummary;
