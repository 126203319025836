import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, PageHeader, Spin, Tag, Table } from "antd";
import moment from "moment";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import { stockIssueService } from "_services";

function StrRmHistory({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [stockIssue, setStockIssue] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const issueData = await stockIssueService.getById(id);

      if (issueData) {
        setStockIssue(issueData.content);
      }
      setLoading(false);
    };

    fetchAlldatas();
    getStockIssuetHistory(id);
  }, []);

  const getStockIssuetHistory = (receiptId) => {
    setLoading(true);
    stockIssueService
      .getHistory(receiptId)
      .then((x) => {
        setItems(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        setError(errorInfo);
      });
  };

  const onCancel = () => {
    history.push(`/stock-issue/${id}/summary`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Note",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Stock Issue Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag
                  color={`${
                    stockIssue.orderStage === "OPEN"
                      ? "#2db7f5"
                      : stockIssue.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {stockIssue.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>History</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="history" id={id} status={stockIssue.orderStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Table
              bordered
              rowKey="id"
              size="small"
              columns={columns}
              dataSource={items}
              pagination={false}
            />
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmHistory;
