import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, PageHeader, Popconfirm, Table } from 'antd';
import Page from '_components/Page';
import TabMenu from '../TabMenu';
import { Auth } from '_helpers';
import { binLocationService, binService } from '_services';

const Add = ({ match, history }) => {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [binLocation, setBinLocation] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setLoading(true);

    binLocationService
      .getById(id)
      .then((data) => {
        setBinLocation(data.content);

        let searchParams = `?isParent=1&binLocationId=NONE`;
        binService
          .search(searchParams)
          .then((data) => {
            setItems(data.content);
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const addBins = () => {
    const binList = selectedRowKeys.map((item) => ({
      id: item,
    }));
    const payload = { stockBins: binList };
    // console.log("Add Subbins: ", JSON.stringify(payload));
    setUpdating(true);
    binLocationService
      .addBins(id, payload)
      .then((data) => {
        setUpdating(false);
        history.push('.');
      })
      .catch((error) => {
        setError(error);
        setUpdating(false);
      });
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    /* getCheckboxProps: (record) => ({
      disabled: record.parent !== null || record.id === id,
    }), */
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Zone',
      dataIndex: 'zoneName',
      key: 'zoneName',
    },
    {
      title: 'Available Stocks',
      dataIndex: 'availableStockQuantity',
      key: 'availableStockQuantity',
    },
    {
      title: 'Available N.Weight',
      dataIndex: 'availableNetWeight',
      key: 'availableNetWeight',
    },
    {
      title: 'Available G.Weight',
      dataIndex: 'availableGrossWeight',
      key: 'availableGrossWeight',
    },
    {
      title: 'Available Cones',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
    },
  ];

  const getAdditionalButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Popconfirm
          key='update-confirm'
          title='Are you sure to update the changes?'
          onConfirm={addBins}
        >
          <Button
            key='add'
            type='primary'
            htmlType='submit'
            disabled={selectedRowKeys.length === 0}
            loading={updating}
          >
            Add to {binLocation.name}
          </Button>
        </Popconfirm>
      );
    }
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href='/dashboard'>
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/bin-locations'>
              <span>Bin Location Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bin-locations/${binLocation.id}/summary`}>
              <span>Bin Location: {binLocation.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bin-locations/${binLocation.id}/bins`}>
              <span>Bins</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Add Bins</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu='sub-bins' id={id} />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={items}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default Add;
