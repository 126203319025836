import React from "react";
import { Route, Switch } from "react-router-dom";
import List from "./List";
import SiluetaList from "./silueta/List";
import PrymList from "./prym/List";
import StrGrList from "./str_gr/List";
import StrRmList from "./str_rm/List";
import { Auth } from "_helpers";

function Stores({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaList} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymList} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR_GR") {
    return (
      <Switch>
        <Route exact path={path} component={StrGrList} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrRmList} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        {/* <Route path={`${path}/:id/summary`} component={Summary} /> */}
      </Switch>
    );
  }
  
}

export { Stores };
