import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Tag,
  Space,
  Spin,
} from "antd";
import { EditOutlined, FormOutlined, UndoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { materialRequestService } from "_services";
import StrRmSearch from "./Search";
import { Auth } from "_helpers";

const StrRmList = ({ match, history }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState(
    "?orderStages=OPEN,VERIFICATION,PREPARE,READY,ASSIGNED,PROCESSING"
  );

  useEffect(() => {
    //setSearchParams("?orderStage=OPEN");
    getMaterialRequests(pageNumber, pageSize);
  }, []);

  const getMaterialRequests = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    materialRequestService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getMaterialRequests(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    materialRequestService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getMaterialRequests(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleUndo = (id) => {
    setSelectedId(id);
    setLoadingUndo(true);
    const payload = {
      orderStage: "OPEN",
    };
    materialRequestService
      .updateStatus(id, payload)
      .then(() => {
        setLoadingUndo(false);
        message.success("Successfully opened!");
        window.location.reload();
      })
      .catch((error) => {
        setLoadingUndo(false);
        setError(error);
      });
  };

  const onCreateDelivery = (materialRequestId) => {
    history.push(`/stock-issue/add?mrn=${materialRequestId}`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Link to={`${path}/add`} key="btn-add">
        <Button>Add</Button>
      </Link>
    );
    /* buttons.push(
      <Link to={`${path}/add-with-items`} key='btn-add-with-items'>
        <Button>Add with items</Button>
      </Link>
    ); */
    buttons.push(
      <Link to={`${path}/upload`} key="btn-upload">
        <Button>Upload</Button>
      </Link>
    );
    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Request No",
      dataIndex: "requestNumber",
      key: "requestNumber",
      render: (text, record) => {
        return (
          <Link to={`/material-requests/${record.id}/summary`}>{text}</Link>
        );
      },
    });
    columns.push({
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text, record) => {
        return moment(text).format("YYYY-MM-DD");
      },
    });
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      columns.push({
        title: "Customer",
        key: "customer",
        render: (text, record) => {
          return record.customer.name;
        },
      });
    }
    columns.push({
      title: "No. Lines",
      key: "numberOfLines",
      render: (text, record) => record.requestLines.length,
    });
    columns.push({
      title: "Status",
      dataIndex: "orderStage",
      key: "orderStage",
      render: (orderStatus) => {
        let color = "";
        if (orderStatus === "OPEN") {
          color = "#69b1ff";
        } else if (orderStatus === "VERIFICATION") {
          color = "#bae0ff";
        } else if (orderStatus === "PREPARE") {
          color = "#87e8de";
        } else if (orderStatus === "READY") {
          color = "#b7eb8f";
        } else if (orderStatus === "PROCESSING") {
          color = "#73d13d";
        } else if (orderStatus === "COMPLETE") {
          color = "#389e0d";
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    });
    columns.push({
      title: "Deliveries",
      dataIndex: "issues",
      key: "issues",
      width: "20%",
      render: (issues) => (
        <span>
          {issues.map((issue, index) => (
            <Space key={index}>
              <Link
                to={`/stock-issue/${issue.id}/${
                  ["PROCESSING"].includes(issue.orderStage) ? "edit" : "summary"
                }`}
              >
                {issue.issueNumber}
              </Link>
              <span>({moment(issue.issuedDate).format("YYYY-MM-DD")})</span>
            </Space>
          ))}
        </span>
      ),
    });
    columns.push({
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "8%",
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.createdBy;
      }, */
    });
    columns.push({
      title: "Modified By",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      width: "8%",
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.modifiedBy;
      }, */
    });
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <span>
            {["OPEN", "VERIFICATION", "PREPARE"].includes(
              record.orderStage
            ) && (
              <Button
                icon={<EditOutlined />}
                title="Edit"
                type="link"
                style={{ marginRight: 10 }}
                href={`/material-requests/${record.id}/edit`}
              ></Button>
            )}
          </span>
        );
      },
    });

    return columns;
  };

  const expandedRowRender = (materialRequest) => {
    const getLineColumns = () => {
      let columns = [];

      columns.push({
        title: "MRN No.",
        dataIndex: "mrnNumber",
        key: "mrnNumber",
        /* render: (text, record) => {
          return (
            <Link to={`/material-request-lines/${record.id}/summary`}>
              {text}
            </Link>
          );
        }, */
      });
      if (materialRequest?.subLocation?.shortCode?.includes("GP")) {
        columns.push({
          title: "Supplier",
          key: "supplier",
          render: (text, record) => {
            return record.supplier && record.supplier.name;
          },
        });
      }
      columns.push({
        title: "Department",
        key: "deparment",
        render: (text, record) => {
          return record.department && record.department.code;
        },
      });
      columns.push({
        title: "Req. Time",
        dataIndex: "requestTime",
        key: "requestTime",
        width: "12%",
      });
      columns.push({
        title: "Material",
        key: "stockTypeCode",
        render: (text, record) => {
          return record.stockType.code;
        },
      });
      columns.push({
        title: "Lot No",
        dataIndex: "lotNumber",
        key: "lotNumber",
        render: (text, record, index) => {
          return record.lotNumber && record.lotNumber.number;
        },
      });
      columns.push({
        title: "Status | Priority",
        key: "lineStage",
        dataIndex: "lineStage",
        render: (text, record) => {
          let color = "";
          let priorityColor = "#fff566";
          if (text === "OPEN") {
            color = "#87d068";
          }
          if (text === "UPDATE") {
            color = "#2db7f5";
          }
          if (text === "VERIFICATION") {
            color = "#108ee9";
          }
          if (text === "PREPARE") {
            color = "#f50";
          }
          if (text === "READY") {
            color = "#faad14";
          }
          if (text === "ASSIGNED") {
            color = "#ffd666";
          }
          if (text === "PROCESSING") {
            color = "#ffd666";
          }
          if (text === "COMPLETE") {
            color = "#ffd666";
          }
          if (text === "REJECTED") {
            color = "#ffd666";
          }
          if (text === "CANCEL") {
            color = "#ffd666";
          }
          if (record.priorityStatus === "GIT") {
            priorityColor = "#f50";
          }
          return (
            <>
              <Tag color={color}>{text}</Tag> |{" "}
              <Tag color={priorityColor}>{record.priorityStatus}</Tag>
            </>
          );
        },
      });
      columns.push({
        title: "Box",
        dataIndex: "totalItems",
        key: "totalItems",
        align: "right",
      });
      columns.push({
        title: "Net Weight",
        key: "netWeight",
        align: "right",
        render: (text, record) => {
          if (record.netWeight) {
            return (
              !isNaN(record.netWeight) && Number(record.netWeight).toFixed(2)
            );
          } else if (record.qty) {
            return "";
          } else if (record.totalItems) {
            return "";
          } else {
            return (
              !isNaN(record.quantity) && Number(record.quantity).toFixed(2)
            );
          }
        },
      });
      if (materialRequest?.subLocation?.shortCode?.includes("GP")) {
        columns.push({
          title: "Width",
          dataIndex: "width",
          key: "width",
          align: "right",
        });
        columns.push({
          title: "C.Code",
          dataIndex: "colorCode",
          key: "colorCode",
          align: "right",
          render: (text, record) => {
            return record.colorCode && `COM${record.colorCode}`;
          },
        });
      } else {
        columns.push({
          title: "Cone",
          dataIndex: "qty",
          key: "qty",
          align: "right",
        });
      }
      
      /* if (materialRequest.orderStage !== "OPEN") {
        columns.push({
          title: "Veri. Box",
          key: "verifiedBox",
          align: "right",
          render: (text, record) => {
            return record.requestedItems.length;
          },
        });
        columns.push({
          title: "Veri. Net.W",
          key: "quantity",
          align: "right",
          render: (text, record) => {
            return (
              record.requestedItems.length &&
              Number(
                record.requestedItems
                  .map((item) => item.netWeight)
                  .reduce((prev, next) => prev + next)
              ).toFixed(2)
            );
          },
        });
      } */
      /* if (["OPEN", "VERIFICATION"].includes(materialRequest.orderStage)) {
        columns.push({
          title: "Reason",
          dataIndex: "reason",
          key: "reason",
          align: "right",
          render: (text, record) => {
            return record.reason?.name;
          },
        });
      } */

      return columns;
    };

    return (
      <Table
        rowKey="id"
        columns={getLineColumns()}
        dataSource={materialRequest.requestLines}
        pagination={false}
      />
    );
  };

  const getRowColor = (record) => {
    let rowColor = "";
    if (record && record.orderStage === "OPEN") {
      rowColor = "request-open";
    } else if (record && record.orderStage === "VERIFICATION") {
      rowColor = "request-verification";
    } else if (record && record.orderStage === "PREPARE") {
      rowColor = "request-prepare";
    } else if (record && record.orderStage === "READY") {
      rowColor = "request-ready";
    } else if (record && record.orderStage === "PROCESSING") {
      rowColor = "request-processing";
    } else if (record && record.orderStage === "COMPLETE") {
      rowColor = "request-complete";
    }

    return rowColor;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <StrRmSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Spin tip="Loading..." spinning={loading}></Spin>
          {items.length > 0 && (
            <Table
              bordered
              loading={loading}
              rowKey="id"
              size="small"
              columns={getColumns()}
              rowClassName={(record) => getRowColor(record)}
              dataSource={items}
              expandable={{ expandedRowRender, defaultExpandAllRows: true }}
              pagination={{
                current: pageNumber,
                pageSize: pageSize,
                total: totalRecords,
                showTotal: (total) => `Total ${total} items`,
                showSizeChanger: false,
                onChange: handlePaginateChange,
              }}
            />
          )}
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrRmList;
