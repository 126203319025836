import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { lotNumberService, stockClassificationService } from "_services";
import TabMenu from "./TabMenu";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [lotNumber, setLotNumber] = useState({});
  const [stockClasses, setStockClasses] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  useEffect(() => {
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        lotNumberService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setIsLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/lotnumbers");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={handleFormSumbit}
        >
          Save
        </Button>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  useEffect(() => {
    setIsLoading(true);
    lotNumberService
      .getById(id)
      .then((x) => {
        setIsLoading(false);
        setLotNumber(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        setError(error);
      });
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/lotnumbers">
                {`${
                  ["SILUETA"].includes(loggedInCustomerCode)
                    ? "Vendor Batch"
                    : "Lot/Batch Number"
                }`}{" "}
                Search
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>
                {`${
                  ["SILUETA"].includes(loggedInCustomerCode)
                    ? "Vendor Batch"
                    : "Lot/Batch Number"
                }`}
                : {lotNumber.number}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} />
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="number"
                  label={`${
                    ["SILUETA"].includes(loggedInCustomerCode)
                      ? "Vendor Batch"
                      : "Lot/Batch Number"
                  }`}
                  rules={[{ required: true, message: "Lot/Batch Number is required" }]}
                >
                  <Input
                    placeholder={`${
                      ["SILUETA"].includes(loggedInCustomerCode)
                        ? "Vendor Batch"
                        : "Lot/Batch Number"
                    }`}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["stockClass", "id"]}
                  label="Stock Classification"
                  rules={[
                    {
                      required: true,
                      message: "Stock classification is required",
                    },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {stockClasses.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
