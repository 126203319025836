import React from "react";
import { Route, Switch } from "react-router-dom";
import BinSelection from "./BinSelection";
import StrBinSelection from "./str/BinSelection";
import Transfer from "./Transfer";
import SiluetaTransfer from "./silueta/Transfer";
import StrTransfer from "./str/Transfer";
import StrList from "./str/List";
import StrBulkTransfer from "./str/BulkTransfer";
import { Auth } from "_helpers";

function StockTransfer({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={BinSelection} />
        <Route path={`${path}/:id/transfer`} component={SiluetaTransfer} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrList} />
        <Route path={`${path}/do-transfer`} component={StrBinSelection} />
        <Route path={`${path}/bulk-transfer`} component={StrBulkTransfer} />
        <Route path={`${path}/:id/transfer`} component={StrTransfer} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={BinSelection} />
        <Route path={`${path}/:id/transfer`} component={Transfer} />
      </Switch>
    );
  }
}

export { StockTransfer };
