import React from "react";
import "../components/NumberInfo/index.css";
import "../components/NumberInfo/number.scss";
const NumberInfo = ({
  theme,
  title,
  categoryTitle,
  categoryTitle1,
  totalItems,
  totalQuantity,
  status,
  numberTitle,
  iconImage,
  suffix,
  gap,
  ...rest
}) => (
  <div>
    <div>
      {title && (
        <div
          className={numberTitle}
          title={typeof title === "string" ? title : ""}
        >
          <div>{title}</div>
          <div>
            {" "}
            <img src={iconImage} alt="{iconImage}" />
          </div>
        </div>
      )}
      <div className="numberValue">
        <div>
          <span className="suffix">{totalItems?.toLocaleString()}</span>
          <div className="title">{categoryTitle}</div>
        </div>
        <div>
          <span className="suffix">
            {totalQuantity &&
              totalQuantity.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </span>
          <div className="title">{categoryTitle1}</div>
        </div>
      </div>
    </div>
  </div>
);

export default NumberInfo;
