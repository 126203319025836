import { Spin, Space, Input } from "antd";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import Page from "_components/Page";
import stock from "img/dasboard-weight/stock.svg";
import net from "img/dasboard-weight/net.svg";
import gross from "img/dasboard-weight/gross.svg";
import total from "img/dasboard-weight/total.svg";
import {
  companyService,
  dashboardService,
  materialRequestService,
  reportService,
  sbuService,
} from "_services";
import NumberInfo from "../components/NumberInfo";
import { Auth } from "_helpers";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ReTooltip,
  Legend as ReLegend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  BarChart,
  Rectangle,
} from "recharts";
import ReactECharts from "echarts-for-react";
import timeClock from "../../../img/dasboard-weight/clock.svg";
import "../../../layouts/layout.scss";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function StrRmHome() {
  const [loading, setLoading] = useState(false);
  const [loadingLocationSummary, setLoadingLocationSummary] = useState(false);
  const [loadingBusinessUnits, setLoadingBusinessUnits] = useState(false);
  const [loadingPendingMRNs, setLoadingPendingMRNs] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingAgins, setLoadingAgings] = useState(false);
  const [loadingDashboardData, setLoadingDashboardData] = useState(false);
  const [locationSummary, setLocationSummary] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [pendingMRNs, setPendingMRNs] = useState([]);
  const [items, setItems] = useState([]);
  const [locationAgins, setLocationAgins] = useState([]);
  const [sbuAgins, setSbuAgins] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [error, setError] = useState(null);
  const { Search } = Input;
  const locationSummaryTitles = [
    "boxs",
    "stock",
    "gross",
    "total_cones",
    "gross",
    "total_cones2",
    "boxs2",
    "stock2",
  ];
  const locationSummaryImages = [stock, net, gross, total];

  // set options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  const [barOptions, setBarOptions] = useState({
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
      },
      scales: {
        xAxes: [
          {
            barThickness: 6, // number (pixels) or 'flex'
            maxBarThickness: 8, // number (pixels)
          },
        ],
      },
    },
  });

  const lineData = [
    {
      date: "2022-10-26",
      in: 4000,
      out: 2400,
    },
    {
      date: "2022-10-24",
      in: 3000,
      out: 1398,
    },
    {
      date: "2022-10-20",
      in: 2000,
      out: 9800,
    },
    {
      date: "2022-10-19",
      in: 2780,
      out: 3908,
    },
    {
      date: "2022-10-18",
      in: 1890,
      out: 4800,
    },
    {
      date: "2022-10-15",
      in: 2390,
      out: 3800,
    },
    {
      date: "2022-10-14",
      in: 3490,
      out: 4300,
    },
  ];
  // const COLORS = ['#00C49F',
  //   '#0088FE', '#FFBB28', '#FF8042', '#e30909'];
  const COLORS = ["#5ea26c", "#47739f", "#b7b443", "#c2714e", "#a64153"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const getBarChartOption = () => {
    const codes = dashboardData?.dashboard?.weeklySummary?.map(item => item.code);
    const grnValues = dashboardData?.dashboard?.weeklySummary?.map(item => item.grn);
    const mrnValues = dashboardData?.dashboard?.weeklySummary?.map(item => item.mrn);

    const option = {
      title: {
        text: 'Weekly Summary',
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['GRN', 'MRN']
      },
      xAxis: {
        type: 'category',
        data: codes
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'GRN',
          type: 'bar',
          data: grnValues,
          itemStyle: {
            color: 'lightgreen'
          }
        },
        {
          name: 'MRN',
          type: 'bar',
          data: mrnValues,
          itemStyle: {
            color: 'yellow'
          }
        }
      ]
    };

    return option;
  }

  useEffect(() => {
    setLoadingBusinessUnits(true);
    sbuService
      .search("?displayOnDashboard=1")
      .then((data) => {
        setBusinessUnits(data.content);
        setLoadingBusinessUnits(false);
      })
      .catch((error) => {
        setLoadingBusinessUnits(false);
        setError(error);
      });

    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          getItems(storeLocationAddresses.map((item) => item.id).join());
        });
      }
      //setCompanies(x.content);
    });

    /* const dateRangeForStockInOut = `?fromDate=${encodeURIComponent(
      moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD")
    )}&toDate=${moment().format("YYYY-MM-DD")}`;
    reportService
      .getStockInOutSummary(dateRangeForStockInOut)
      .then((data) => {
        if (data.content.length > 0) {
          const groupByDate = data.content.reduce((prev, next) => {
            prev[next.invoiceDate] = [...(prev[next.invoiceDate] || []), next];
            return next;
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      }); */

    getPendingMaterialRequests();
    getAgins();
    getDashboardData();
  }, []);

  const getItems = (storeLocationIds) => {
    let netWeights = [];
    let stockTypes = [];
    setLoadingItems(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    searchParams += `fromDate=${encodeURIComponent(
      moment("2021-01-01").format("YYYY-MM-DD")
    )}&toDate=${moment().format(
      "YYYY-MM-DD"
    )}&storeLocations=${storeLocationIds}`;
    reportService
      .getAvailableStocks(searchParams)
      .then((data) => {
        /* const groupByCategory = chain(data.content)
                .groupBy("materialSupplierCode").map(item => item.length).value();
                console.log("ASt:", groupByCategory); */
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
            stockTypes.push(item.stockType);
            netWeights.push(item.netWeight);
          });
          setDataChart({
            labels: stockTypes,
            datasets: [
              {
                label: "Net Weight (Kg)",
                backgroundColor: "rgb(255,140,0)",
                borderColor: "rgb(255,140,0)",
                data: netWeights,
                barPercentage: 0.5,
                barThickness: 12,
                maxBarThickness: 8,
                minBarLength: 2,
              },
            ],
          });
          setItems(data.content);
        }

        setLoadingItems(false);
      })
      .catch((error) => {
        setLoadingItems(false);
      });
  };

  const getPendingMaterialRequests = () => {
    const searchParams =
      "?orderStages=OPEN,VERIFICATION,PREPARE,READY,ASSIGNED,PROCESSING";

    setLoadingPendingMRNs(true);
    materialRequestService
      .searchSummary(`${searchParams}`)
      .then((data) => {
        setPendingMRNs(data.content);
        setLoadingPendingMRNs(false);
      })
      .catch((error) => {
        setLoadingPendingMRNs(false);
      });
  };

  const getAgins = () => {
    setLoadingAgings(true);
    sbuService
      .searchAging("?displayOnDashboard=1")
      .then((data) => {
        setSbuAgins(data.content);
        setLoadingAgings(false);
      })
      .catch((error) => {
        setLoadingAgings(false);
      });
  };

  const getDashboardData = () => {
    setLoadingDashboardData(true);
    dashboardService
      .getSbusWithDashboard()
      .then((data) => {
        setDashboardData(data.content);
        setLoadingDashboardData(false);
      })
      .catch((error) => {
        setLoadingDashboardData(false);
      });
  };

  const userProfile = true;

  return (
    <Page className="dashboard" error={error}>
      <Spin tip="Loading..." spinning={loading}>
        <div className="wrapper">
          <div className="widgets">
            <Spin tip="Loading..." spinning={loadingBusinessUnits}>
              <div className="widget">
                {businessUnits.map((summary, i) => {
                  return (
                    <NumberInfo
                      key={summary.id}
                      title={summary.name}
                      categoryTitle="Total Box"
                      categoryTitle1="Total Net Weight"
                      categoryTitle2="Total Gross Weight"
                      categoryTitle3="Total Cones"
                      gap={8}
                      totalItems={summary.totalStocks}
                      numberTitle={locationSummaryTitles[i]}
                      iconImage={total}
                      totalNetWeight={summary.totalNetWeight}
                      totalGrossWeight={summary.totalGrossWeight}
                      totalQuantity={summary.totalQuantity}
                    />
                  );
                })}
              </div>
            </Spin>
          </div>

          <div className="chart">
            <h2 className="h2title">
              <div>Delivery Progress</div>
              <div className="leganProg">
                <div>
                  <span />
                  Open
                </div>
                <div>
                  <span />
                  Verification
                </div>
                <div>
                  <span />
                  Verified
                </div>
                <div>
                  <span />
                  InProgress
                </div>
              </div>
            </h2>
            <Spin tip="Loading..." spinning={loadingDashboardData}>
              <div className="deliChart">
                {dashboardData?.dashboard?.pendingRequests?.map((mrn) => {
                  if (mrn.orderStage === "OPEN") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="Open">
                          <div />
                        </div>
                        <div toolTip="Verification">
                          <div />
                        </div>
                        <div toolTip="Verified">
                          <div />
                        </div>
                        <div toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  } else if (mrn.orderStage === "VERIFICATION") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="Open">
                          <div />
                        </div>
                        <div className="verification" toolTip="Verification">
                          <div />
                        </div>
                        <div className="verified" toolTip="Verified">
                          <div />
                        </div>
                        <div toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  } else if (mrn.orderStage === "IN_PROGRESS") {
                    return (
                      <div>
                        <div>
                          {" "}
                          <img src={timeClock} />
                          <span>
                            {moment().diff(moment(mrn.requestDate), "hours")} hr
                            over
                          </span>
                        </div>
                        <div>{mrn.requestNumber}</div>
                        <div className="open" toolTip="Open">
                          <div />
                        </div>
                        <div className="verification" toolTip="Verification">
                          <div />
                        </div>
                        <div className="verified" toolTip="Verified">
                          <div />
                        </div>
                        <div className="inprogress" toolTip="In Progress">
                          <div />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </Spin>
          </div>
          <div className="map">
            <div className="search">
              <Space direction="vertical">
                <Search placeholder="Search of the Container VEHICLE Number1" />
              </Space>
            </div>

            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width="600"
                  id="gmap_canvas"
                  src="https://gps.trackmycar.lk/index.php?au=E34E2FA829F36F44C2F454FCA6A77D6C"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="delivery">
          <h2>Week Summary</h2>
          <Spin tip="Loading..." spinning={loadingDashboardData}>
            <ReactECharts option={getBarChartOption()} />
            </Spin>
          {/* 
            <ResponsiveContainer>
              <BarChart
                    width={500}
                    height={300}
                    data={dashboardData?.dashboard?.weeklySummary}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="code" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="grn"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                    />
                    <Bar
                      dataKey="mrn"
                      fill="#82ca9d"
                      activeBar={<Rectangle fill="gold" stroke="purple" />}
                    />
                  </BarChart>
            </ResponsiveContainer>
             */}
          </div>
          {/* <div className="deliChart">
            <h2 className="h2title">
              <div>DIFOT</div>
            </h2>
            <div>
              <div>
                <div>Planned Batches:</div>
                <div>
                  {
                    dashboardData?.dashboard?.deliverySummary
                      ?.totalPlannedBatches
                  }
                </div>
              </div>
              <div>
                <div>Delivered Batches From BNS:</div>
                <div>
                  {
                    dashboardData?.dashboard?.deliverySummary
                      ?.totalDeliveredBatches
                  }
                </div>
              </div>
              <div>
                <div>Delivered Batches From Substores:</div>
                <div>
                  {
                    dashboardData?.dashboard?.deliverySummary
                      ?.totalDeliveredBatchesFromSubstore
                  }
                </div>
              </div>
              <div>
                <div>Out of Stocks:</div>
                <div>
                  {dashboardData?.dashboard?.deliverySummary?.totalOutOfStock}
                </div>
              </div>
              <div>
                <div>Failed Batches:</div>
                <div>
                  {
                    dashboardData?.dashboard?.deliverySummary
                      ?.totalFailedBatches
                  }
                </div>
              </div>
              <div>
                <div>
                  DIFT-
                  {
                    dashboardData?.dashboard?.deliverySummary
                      ?.deliverySuccessRate
                  }
                  %
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='analysis'>
            <h2>Material Weights</h2>
            <Spin tip='Loading...' spinning={loadingItems}>
              <div>
                {items.length > 0 && <Bar data={dataChart} options={options} />}
              </div>
            </Spin>
          </div> */}
          <div className="agin">
            <h2>Material Ageing (Days)</h2>
            <Spin tip="Loading..." spinning={loadingAgins}>
              {sbuAgins.map((aging, i) => {
                const stockAgingData = [
                  { name: "<=90", value: aging.ageLt90 },
                  { name: "90-180", value: aging.age90To180 },
                  { name: "180-270", value: aging.age180To270 },
                  { name: "270-360", value: aging.age270To360 },
                  { name: "360<", value: aging.ageGt360 },
                ];
                return (
                  <div className="aginChart">
                    <h1>{aging.name}</h1>
                    <ResponsiveContainer>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={stockAgingData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {stockAgingData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                );
              })}
            </Spin>
          </div>
          
        </div>
      </Spin>
    </Page>
  );
}

export default StrRmHome;
