import React from "react";
import { Route, Switch } from "react-router-dom";
import { Auth } from "_helpers";
import AvailableStocks from "./AvailableStocks";
import BinWiseBalance from "./BinWiseBalance";
import SupplierWiseBalance from "./SupplierWiseBalance";
import StockInOutSummary from "./StockInOutSummary";
import PrymAvailableStocks from "./prym/AvailableStocks";
import SiluetaAvailableStocks from "./silueta/AvailableStocks";
import StrGrAvailableStocks from "./str_gr/AvailableStocks";
import StrRmAvailableStocks from "./str_rm/AvailableStocks";
import StrRmBinWiseBalance from "./str_rm/BinWiseBalance";
import StrRmSupplierWiseBalance from "./str_rm/SupplierWiseBalance";
import StrRequestDeliveryLines from "./str_rm/RequestDeliveryLines";
import StrComparisonList from "./str_rm/ComparisonList";
import StrComparisonUpload from "./str_rm/ComparisonUpload";
import StrComparisonSummary from "./str_rm/ComparisonSummary";
import PrymBinWiseBalance from "./prym/BinWiseBalance";
import StrItemComparisonUpload from "./str_rm/StockItemComparisonUpload";
import StrItemComparisonList from "./str_rm/StockItemComparisonList";
import StrItemComparisonSummary from "./str_rm/StockItemComparisonSummary";
import NotFoundItems from "./str_rm/NotFoundItems";

function Reports({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaAvailableStocks} />
        <Route
          path={`${path}/available-stocks`}
          component={SiluetaAvailableStocks}
        />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymAvailableStocks} />
        <Route
          path={`${path}/available-stocks`}
          component={PrymAvailableStocks}
        />
        <Route
          path={`${path}/bin-wise-balance`}
          component={PrymBinWiseBalance}
        />
        <Route path={`${path}/inout-summary`} component={StockInOutSummary} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR_GR") {
    return (
      <Switch>
        <Route exact path={path} component={StrGrAvailableStocks} />
        <Route
          path={`${path}/available-stocks`}
          component={StrGrAvailableStocks}
        />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrRmAvailableStocks} />
        <Route
          path={`${path}/available-stocks`}
          component={StrRmAvailableStocks}
        />
        <Route
          path={`${path}/bin-wise-balance`}
          component={StrRmBinWiseBalance}
        />
        <Route
          path={`${path}/supplier-wise-balance`}
          component={StrRmSupplierWiseBalance}
        />
        <Route path={`${path}/inout-summary`} component={StockInOutSummary} />
        <Route path={`${path}/request-delivery-lines`} component={StrRequestDeliveryLines} />
        <Route path={`${path}/comparisons/:id`} component={StrComparisonSummary} />
        <Route path={`${path}/comparisons`} component={StrComparisonList} />
        <Route path={`${path}/comparison-upload`} component={StrComparisonUpload} />
        <Route path={`${path}/item-comparisons/:id`} component={StrItemComparisonSummary} />
        <Route path={`${path}/item-comparisons`} component={StrItemComparisonList} />
        <Route path={`${path}/item-comparison-upload`} component={StrItemComparisonUpload} />
        <Route path={`${path}/notfound-items`} component={NotFoundItems} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={AvailableStocks} />
        <Route path={`${path}/available-stocks`} component={AvailableStocks} />
        <Route path={`${path}/bin-wise-balance`} component={BinWiseBalance} />
        <Route
          path={`${path}/supplier-wise-balance`}
          component={SupplierWiseBalance}
        />
        <Route path={`${path}/inout-summary`} component={StockInOutSummary} />
      </Switch>
    );
  }
}

export { Reports };
