import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Tag,
} from "antd";
import { FilePdfOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import Page from "_components/Page";
import {
  addressService,
  companyService,
  lotNumberService,
  materialDetailService,
  materialTypeService,
  measurementTypeService,
  purchaseOrderService,
  extraService,
} from "_services";
import TableForm from "./components/TableForm";
import StockItemUpdateTableForm from "./components/StockItemUpdateTableForm";

function StrRmEdit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingUpdateStocks, setLoadingUpdateStocks] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [order, setOrder] = useState({});
  //const [orderLines, setOrderLines] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setcustomerAddresses] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const storeData = await companyService.getSuggestion(
        "?companyType=STORE"
      );
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const purchaseOrderData = await purchaseOrderService.getById(id);

      if (purchaseOrderData) {
        let purchaseOrder = purchaseOrderData.content;
        purchaseOrder.store = purchaseOrder.store.id;
        purchaseOrder.storeAddress = purchaseOrder.storeAddress.id;
        purchaseOrder.customer = purchaseOrder.customer.id;
        purchaseOrder.customerAddress = purchaseOrder.customerAddress.id;
        purchaseOrder.orderDate = moment(purchaseOrder.orderDate);
        purchaseOrder.stockPreReceiptLines.forEach((orderItem, i) => {
          orderItem.key = orderItem.id;
        });
        const poCustomer = await companyService.getById(purchaseOrder.customer);
        const grantedStoreAddresses = poCustomer.content.addresses.filter(
          (item) => item.addressType === "WAREHOUSE"
        );
        const officeAddresses = poCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );

        setOrder(purchaseOrder);
        //console.log("Customer:", grantedStoreAddresses, ", ", officeAddresses);
        //getStoreAddresses(purchaseOrder.store);
        //getcustomerAddresses(purchaseOrder.customer);
        setCustomer(poCustomer.content);
        setStoreAddresses(grantedStoreAddresses);
        setcustomerAddresses(officeAddresses);
        onFill(purchaseOrder);
      }

      setStores(storeData.content);
      setcustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setLoading(false);
    };

    fetchAlldatas();
  }, []);

  const getStoreAddresses = (companyId) => {
    addressService.getAllByCompany(companyId).then((x) => {
      setStoreAddresses(x.content);
    });
  };

  const getcustomerAddresses = (companyId) => {
    addressService.getAllByCompany(companyId).then((x) => {
      setcustomerAddresses(x.content);
    });
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    purchaseOrderService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Stock-in-${order.preReceiptNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        //console.log("Update: ", values);
        let orderLinesValidation = true;
        values.stockPreReceiptLines.forEach((item) => {
          if (!item.stockType || !item.quantity || !item.measurementType) {
            orderLinesValidation = false;
            message.error("Please fill in complete order line.");
            setLoading(false);
            return;
          }
        });
        if (orderLinesValidation) {
          values.store = { id: values.store };
          values.storeAddress = { id: values.storeAddress };
          values.customer = { id: values.customer };
          values.customerAddress = { id: values.customerAddress };
          values.orderDate = moment(values.orderDate).format("YYYY-MM-DD");
          console.log("Order Update: ", values);
          purchaseOrderService
            .update(id, values)
            .then(() => {
              message.success("Successfully updated!");
              setLoading(false);
              history.push(`${path}`.replace(":id", id));
            })
            .catch((error) => {
              setLoading(false);
              setError(error);
            });
        }
      })
      .catch((errorInfo) => { });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/packing-lists");
  };

  const handleStockReceipt = () => {
    history.push(`/stock-receipt/${id}`);
  };

  const handleStockItemFormSumbit = () => {
    const payload = {
      stockItems: stockItems
        .filter((item) => item.quantity || item.netWeight || item.grossWeight)
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
        })),
    };
    setLoadingUpdateStocks(true);
    //console.log("PL: ", payload);
    materialDetailService
      .bulkUpdate(payload)
      .then((data) => {
        message.success("Successfully updated the stock item details.");
        setLoadingUpdateStocks(false);
      })
      .catch((error) => {
        setLoadingUpdateStocks(false);
        message.error(error);
      });
  };

  const handleStockItemUpdate = (stocks) => {
    if (stocks) {
      setStockItems(stocks);
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="pdf"
        icon={<FilePdfOutlined />}
        onClick={handlePrintPdf}
        loading={loadingPdf}
      ></Button>
    );
    if (order.orderStage === "OPEN") {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
          key="update-confirm"
        >
          <Button key="update" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Popconfirm>
      );
    } else {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleStockItemFormSumbit}
          key="update-confirm"
        >
          <Button key="update" htmlType="submit" loading={loadingUpdateStocks}>
            Update Stocks
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button
        key="update"
        icon={<UploadOutlined />}
        href={`/material-details/update-via-upload`}
        target="_blank"
      ></Button>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Packing List Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {order.preReceiptNumber} ::{" "}
                <Tag
                  color={`${order.orderStage === "OPEN"
                    ? "#2db7f5"
                    : order.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                    }`}
                >
                  {order.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="customer"
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getcustomerAddresses}
                      disabled
                    >
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="store"
                    label={<Text strong>Store</Text>}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={getStoreAddresses}
                      disabled
                    >
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="orderDate"
                    label="Invoice Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Invoice date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="preReceiptNumber"
                    label="Packing List No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="customerAddress"
                    label="Deliver From"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="storeAddress"
                    label="Delivery To"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select">
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
              {order.orderStage === "OPEN" &&
                materialTypes.length > 0 &&
                lotNumbers.length > 0 &&
                form.getFieldValue("stockPreReceiptLines") && (
                  <Form.Item name="stockPreReceiptLines">
                    <TableForm
                      stockClass={order.stockClass}
                      materialTypes={materialTypes}
                      lotNumbers={lotNumbers}
                      measurementTypes={measurementTypes}
                    />
                  </Form.Item>
                )}
              {/* form.getFieldValue("stockPreReceiptLines") && (
                <Form.Item name="stockPreReceiptLines">
                  <StockItemUpdateTableForm
                    status={order.orderStage}
                    stockClass={order.stockClass}
                    onStockItemChange={handleStockItemUpdate}
                    materialTypes={materialTypes}
                    lotNumbers={lotNumbers}
                    measurementTypes={measurementTypes}
                    order={order}
                  />
                </Form.Item>
              ) */}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmEdit;
