import {
  Button,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Select,
  Typography,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import Page from "_components/Page";
import { Auth } from "_helpers";
import {
  companyService,
  extraService,
  materialRequestService,
} from "_services";

const Upload = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [stores, setStores] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [error, setError] = useState(null);
  const [isRequestInclude, setRequestInclude] = useState(false);
  const [materialRequests, setMaterialRequests] = useState([]);

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const isLoggedInCustomer = () => {
    return Auth.hasRole(Auth.getProfile(), ["CAU"]);
  };

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        setSelectedStore(x.content[0]);
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        customerAddress: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setcustomers([data.content]);
      //setSelectedCustomer(data.content[0]);
      setCustomerAddresses(officeAddresses);
      setSelectedCustomer(data.content);
      setIsLoading(false);
    });

    materialRequestService.getSuggestion("").then((x) => {
      setMaterialRequests(
        x.content.filter((item) =>
          ["OPEN", "IN_PROGRESS"].includes(item.orderStage)
        )
      );
    });
  }, []);

  const getStoreAddresses = (companyId) => {
    form.resetFields(["storeAddress"]);

    companyService.getById(companyId).then((x) => {
      setSelectedStore(x.content);
      setStoreAddresses(
        x.content.addresses.filter(
          (address) => address.addressType === "WAREHOUSE"
        )
      );
    });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        const payload = {
          issueNumber: `${selectedStore && selectedStore.code}-${selectedCustomer && selectedCustomer.code
            }-${values.issueNumber}`,
          issueDate: moment(values.issueDate).format("YYYY-MM-DD HH:mm:ss"),
          customerId: values.customer.id,
          customerAddressId: values.customerAddress.id,
          storeId: values.store.id,
          storeAddressId: values.storeAddress.id,
          requestNumber: values.requestNumber
            ? `${selectedCustomer && selectedCustomer.code}-MRN-${values.requestNumber
            }`
            : null,
          requestDate: moment(values.requestDate).format("YYYY-MM-DD HH:mm:ss"),
          deliverFrom: values.storeAddress.id,
          deliverTo: values.customerAddress.id,
          contactPersonName: values.contactPersonName,
          contactNumber: values.contactNumber,
          driverName: values.driverName,
          dispatchNumber: values.dispatchNumber,
          sealNumber: values.sealNumber,
          driverNic: values.driverNic,
          stockRequestId: values.stockRequestId,
          remark: values.remark,
          base64FileString: base64File,
          originalFileName: originalFileName,
        };
        console.log("PL: ", payload);
        extraService
          .uploadStockIssue(payload)
          .then(() => {
            message.success("Successfully uploaded!");
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setOriginalFileName(e.target.files[0].name);
      let reader = new FileReader();
      reader.onload = function (e) {
        setBase64File(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onRequestIncludeChange = (e) => {
    setRequestInclude(e.target.checked);
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Stock Delivery Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Delivery Upload</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            title="Are you sure to add the changes?"
            onConfirm={handleFormSumbit}
            key="add-confirm"
          >
            <Button
              key="1"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Upload
            </Button>
          </Popconfirm>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: true, message: "Store is required" }]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      onChange={getStoreAddresses}
                    >
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" disabled allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issueDate"
                    label="Issue Date"
                    rules={[
                      { required: true, message: "Issue date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issueNumber"
                    label="Delivery No"
                    rules={[
                      { required: true, message: "Delivery is required" },
                    ]}
                  >
                    <Input
                      prefix={`${selectedStore && selectedStore.code}-${selectedCustomer && selectedCustomer.code
                        }-`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["storeAddress", "id"]}
                    label="Delivery From"
                    rules={[
                      {
                        required: true,
                        message: "Delivery address is required",
                      },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customerAddress", "id"]}
                    label="Deliver To"
                    rules={[
                      { required: true, message: "Delivery from is required" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      disabled={isLoggedInCustomer()}
                    >
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverName"
                    label="Driver Name"
                    rules={[
                      {
                        required: true,
                        message: "Contact persion is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact persion" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact Number"
                    rules={[
                      { required: true, message: "Contact No. is required" },
                    ]}
                  >
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="driverNic"
                    label="Driver NIC"
                    rules={[
                      { required: false, message: "Driver NIC is required" },
                    ]}
                  >
                    <Input placeholder="Driver NIC" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="sealNumber"
                    label="Seal No:"
                    rules={[
                      { required: false, message: "Seal number is required" },
                    ]}
                  >
                    <Input placeholder="Seal No:" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="dispatchNumber"
                    label="Dispatch No:"
                    rules={[
                      {
                        required: false,
                        message: "Dispatch number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Dispatch No:" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="isRequestInclude" valuePropName="checked">
                    <Checkbox onChange={onRequestIncludeChange}>
                      Include Request
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  {isRequestInclude && (
                    <Form.Item
                      name="stockRequestId"
                      label="Select Material Request"
                    >
                      <Select
                        placeholder="Please select"
                        allowClear={true}
                        showSearch
                        optionFilterProp="children"
                      >
                        {materialRequests.length > 0 &&
                          materialRequests.map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.requestNumber} [{t.orderStage}]
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  {!isRequestInclude && (
                    <Form.Item
                      name="requestDate"
                      label="Request Date"
                      rules={[
                        { required: true, message: "Request date is required" },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        placeholder="Request date"
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}>
                  {!isRequestInclude && (
                    <Form.Item
                      name="requestNumber"
                      label="Request No"
                      rules={[
                        { required: true, message: "Request No: is required" },
                      ]}
                    >
                      <Input
                        prefix={`${selectedCustomer && selectedCustomer.code
                          }-MRN-`}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}>
                  {!isRequestInclude && (
                    <Form.Item
                      name="contactPersonName"
                      label="Contact Name"
                      rules={[
                        {
                          required: true,
                          message: "Contact Name is required",
                        },
                      ]}
                    >
                      <Input placeholder="Contact Name" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="file"
                    label="Delivery Detail File"
                    rules={[
                      {
                        required: true,
                        message: "Valid excel file is required",
                      },
                    ]}
                    valuePropName="file"
                  >
                    <Input type="file" name="file" onChange={onFileChange} allowClear />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingTop: 40 }}>
                  <>
                    {["SILUETA"].includes(loggedInCustomerCode) && (
                      <CSVLink
                        key="1"
                        data={[]}
                        filename={"Sample-StockIssue-File.csv"}
                        headers={[
                          { label: "Material", key: "material" },
                          { label: "Vendor Batch", key: "vendorBatch" },
                          { label: "Batch", key: "batch" },
                        ]}
                      >
                        Sample Upload File
                      </CSVLink>
                    )}
                    {["STR_GR"].includes(loggedInCustomerCode) && (
                      <CSVLink
                        key="1"
                        data={[]}
                        filename={"Sample-StockIssue-File.csv"}
                        headers={[
                          { label: "Item Code", key: "itemCode" },
                          { label: "Batch", key: "batch" },
                          { label: "Box Serial", key: "serialNo" },
                          { label: "Net Weight (Kg)", key: "netWeight" },
                          { label: "Bin Code", key: "binCode" },
                          { label: "UOM", key: "uom" },
                          { label: "Stock Class", key: "stockClass" },
                          { label: "Packing Type", key: "packingType" },
                        ]}
                      >
                        Sample Upload File
                      </CSVLink>
                    )}
                  </>
                </Col>
                <Col span={12}>
                  <Form.Item name="remark" label="Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default Upload;
