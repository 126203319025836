import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { Auth } from '_helpers';
import {
  extraService,
  materialTypeService,
  packingTypeService,
  stockClassificationService,
  supplierService,
  vehicleService,
  vehicleTypeService,
} from '_services';
import TabMenu from './TabMenu';

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setIsLoading(true);
    vehicleService
      .getById(id)
      .then((x) => {
        let data = x.content;
        setIsLoading(false);
        setVehicle(x.content);
        onFill(data);
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        message.error(`${errorInfo}`);
      });
    getVehicleTypes();
  }, []);

  const getVehicleTypes = () => {
    vehicleTypeService
      .getSuggestion()
      .then((x) => {
        setVehicleTypes(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        vehicleService
          .update(id, values)
          .then(() => {
            message.success('Successfully updated!');
            setIsLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        // message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push('/vehicles');
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Popconfirm
          key='update-confirm'
          title='Are you sure to update the changes?'
          onConfirm={handleFormSumbit}
        >
          <Button key='1' type='primary' htmlType='submit' loading={isLoading}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key='2' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/vehicles'>Vehicle Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>Vehicle: {vehicle.number}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu='summary' id={id} />
          <Form
            form={form}
            name='form-create'
            layout='vertical'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name='number'
                  label='Vehicle Number'
                  rules={[
                    { required: true, message: 'Vehicle Number is required' },
                  ]}
                >
                  <Input placeholder='Number' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['type', 'id']}
                  label='Vehicle Type'
                  rules={[
                    {
                      required: true,
                      message: 'Vehicle Type is required',
                    },
                  ]}
                >
                  <Select placeholder='Please select' allowClear>
                    {vehicleTypes.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
