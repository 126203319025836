export const CompanyTypes = [
  { name: "Store", value: "STORE" },
  { name: "Customer", value: "CUSTOMER" },
  { name: "Supplier", value: "SUPPLIER" },
];

export const AddressTypes = [
  { name: "Warehouse", value: "WAREHOUSE" },
  { name: "Office", value: "OFFICE" },
];

/* export const StockClasses = [
  { name: "YARN", value: "YARN" },
  { name: "FABRIC", value: "FABRIC" },
]; */

export const OrderStatus = [
  { name: "Open", value: "OPEN" },
  { name: "In-Progress", value: "IN_PROGRESS" },
  { name: "Complete", value: "COMPLETE" },
  { name: "Processing", value: "PROCESSING" },
  { name: "Verified", value: "VERIFIED" },
];

export const ReceiptStatus = [
  { name: "Open", value: "OPEN" },
  { name: "Update", value: "UPDATE" },
  { name: "Barcoding", value: "BARCODING" },
  { name: "Validate", value: "VALIDATE" },
  { name: "Bin Movement", value: "BIN_MOVE" },
  { name: "Complete", value: "COMPLETE" },
  { name: "Cancel", value: "CANCEL" },
];

export const StockIssueOrderStatus = [
  { name: "Open", value: "OPEN" },
  { name: "Loading", value: "LOADING" },
  { name: "Ready", value: "READY" },
  { name: "Issue", value: "ISSUED" },
  { name: "Confirmation", value: "CONFIRMATION" },
  { name: "Complete", value: "COMPLETE" },
  { name: "Processing", value: "PROCESSING" },
];

export const MaterialRequestStatus = [
  { name: "Open", value: "OPEN" },
  { name: "Verification", value: "VERIFICATION" },
  { name: "Prepare", value: "PREPARE" },
  { name: "Ready", value: "READY" },
  { name: "Assigned", value: "ASSIGNED" },
  { name: "Processing", value: "PROCESSING" },
  { name: "Complete", value: "COMPLETE" },
];

export const StockStatuses = [
  { name: "Pending", value: "PENDING" },
  { name: "Receipt Scanned", value: "RECEIPT_SCANNED" },
  { name: "Available", value: "AVAILABLE" },
  { name: "Request Scanned", value: "REQUEST_SCANNED" },
  { name: "Ready", value: "READY" },
  { name: "Assigned", value: "ASSIGNED" },
  { name: "Issue Scanned", value: "ISSUE_SCANNED" },
  { name: "Delivery Ready", value: "DELIVERY_READY" },
  { name: "Issued", value: "ISSUED" },
  { name: "Delivered", value: "DELIVERED" },
  { name: "Signoff", value: "SIGNOFF" },
  { name: "Writeoff", value: "WRITEOFF" },
];

export const InspectionStatuses = [
  { name: "Unrestricted", value: "UR" },
  { name: "Damage", value: "D" },
  { name: "Rejected", value: "R" },
  { name: "Write off", value: "W" },
  { name: "QA Pending", value: "Q" },
  { name: "Sample", value: "S" },
];

export const PriorityStatuses = [
  { name: "GIT", value: "GIT" },
  { name: "COMMON", value: "COMMON" },
  { name: "UNCOMMON", value: "UNCOMMON" },
];

export const StockCheckTypes = [
  { name: "Cycle Count", value: "CYCLE_COUNT" },
  { name: "Full Count", value: "FULL_COUNT" },
];

export const StockCheckStatus = [
  { name: "Open", value: "OPEN" },
  { name: "In progress", value: "IN_PROGRESS" },
  { name: "Complete", value: "COMPLETE" },
];

export const ReasonForCancel = [{ name: "Not found", value: "NOT_FOUND" }];

export const PackingStatus = [
  { name: "NEW", value: "NEW" },
  { name: "RETURN", value: "RETURN" },
];

export const ReasonTypes = [
  { name: "TRANSFER", value: "TRANSFER" },
  { name: "REQUEST", value: "REQUEST" },
];

export const QAStatuses = [
  { name: "QA_PENDING", value: "QA_PENDING" },
  { name: "QA_PASS", value: "QA_PASS" },
];

export const ReceiptTypes = [
  { name: "LOCAL", value: "LOCAL" },
  { name: "IMPORT", value: "IMPORT" },
  { name: "SBU TRANSFER", value: "SBU TRANSFER" },
];

export const StockAdjustmentStatuses = [
  { name: "Issue", value: "ISSUED" },
  { name: "Writeoff", value: "WRITEOFF" },
  { name: "Signoff", value: "SIGNOFF" },
];
