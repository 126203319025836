import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Tag,
  Popconfirm,
  Typography,
  Tooltip,
  Divider,
  Tabs,
  Modal,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  materialRequestService,
  stockIssueService,
} from "_services";
import StockSelection from "./StockSelection";

let lineIndex = 0;

function StrGrAdd({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [materialRequests, setMaterialRequests] = useState([]);
  const [materialRequestId, setMaterialRequestId] = useState(null);
  const [addedMaterialRequestIds, setAddedMaterialRequestIds] = useState([]);
  const [stockIssueLines, setStockIssueLines] = useState([]);
  //const [selectedStore, setSelectedStore] = useState(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState(Date());
  //const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchMRLoading, setSearchMRLoading] = useState(false);
  const [addedStocks, setAddedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [stockSearchForm] = Form.useForm();
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [availableStocks, setAvailableStocks] = useState([]);
  const [availableStocksLoading, setAvailableStocksLoading] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);
  //const [lotNumbers, setLotNumbers] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumberId, setLotNumberId] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;
  const queryString = useLocation().search;

  const [stockIssue, setStockIssue] = useState({
    store: { id: null },
    customer: { id: null },
    issuedDate: moment(),
    vehicleNumber: null,
    driverName: null,
    contactNumber: null,
    driverNic: null,
    sealNumber: null,
    dispatchNumber: null,
    deliveryRemark: null,
    remark: null,
    issuedLines: [],
    requestedLines: [],
  });
  const [showRequestLines, setShowRequestLines] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedRequestLineId, setSelectedRequestLineId] = useState(null);
  const [issuedLines, setIssuedLines] = useState([]);
  const [requestedLines, setRequestedLines] = useState([]);

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        //setSelectedStore(x.content[0]);
        //form.setFieldsValue({ store: x.content[0] });
        stockIssue.store.id = x.content[0].id;
      }
      setStores(x.content);
    });
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      if (x.content.length === 1) {
        stockIssue.customer.id = x.content[0].id;
        // form.setFieldsValue({
        //   customer: x.content[0],
        // });
        // setSelectedCustomer(x.content[0]);
      }
      setCustomers(x.content);
    });

    materialRequestService.getSuggestion("").then((x) => {
      setMaterialRequests(
        x.content.filter((item) =>
          ["OPEN", "IN_PROGRESS", "ACCEPTED"].includes(item.orderStage)
        )
      );
    });

    const queryParamValueMap = getQueryParams(queryString);
    const matReqId = queryParamValueMap.get("mrn");
    if (matReqId) {
      materialRequestService
        .getById(matReqId)
        .then((data) => {
          form.setFieldsValue({
            materialRequest: data.content.id,
          });
          showMaterialRequestLines(matReqId);
        })
        .catch((error) => {
          message.error(`${error}`);
        });
    }

    /* materialTypeService.getSuggestion("").then((x) => {
      setMaterialTypes(x.content);
    });
    lotNumberService.getSuggestion("").then((x) => {
      setLotNumbers(x.content);
    }); */

    onFill(stockIssue);
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const addRequestLineToDelivery = (requestLineId) => {
    if (
      requestLineId &&
      !requestedLines.map((item) => item.id).includes(requestLineId)
    ) {
      setSearchMRLoading(true);

      let requestLine = selectedRequest.requestLines.find(
        (item) => item.id === requestLineId
      );
      if (requestLine && requestLine.lineStage === "COMPLETE") {
        setSearchMRLoading(false);
        message.error("Sorry, This line is already completed!");
        return;
      }
      requestLine.issueRequest = selectedRequest;

      const issuedLine = {
        key: `${requestLine.stockType.id}_${requestLine.lotNumber ? requestLine.lotNumber.id : ""}`,
        stockType: requestLine.stockType,
        lotNumber: requestLine.lotNumber,
        requestLine: requestLine,
        measurementType: requestLine.measurementType,
        requestNumber: selectedRequest.requestNumber,
        requestedQuantity: requestLine.requestedItems.length,
        requestedTotalQuantity: requestLine.quantity,
        requestedGrossMeasure:
          requestLine.requestedItems.length > 0 &&
          requestLine.requestedItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next),
        issuedItems: requestLine.requestedItems
          .filter((item) => item.mode === "REQUESTED")
          .map((t) => ({
            ...t,
            added: false,
            requestLineId: requestLine.id,
          })),
        issuedQuantity: 0,
        issuedNetWeight: 0,
        issuedGrossWeight: 0,
      };

      //setStockIssue(stockIssue.issuedLines.push(issuedLine));
      //setStockIssue(stockIssue.requestedLines.push(requestLine));
      //setActiveTabKey(issuedLine.key);
      //setSelectedIssueLineKeys([issuedLine.key]);
      setIssuedLines([...issuedLines, issuedLine]);
      setRequestedLines([...requestedLines, requestLine]);
      setSearchMRLoading(false);
      form.setFieldsValue({ materialRequestLine: null });
      /* setSelectedRequest(null);
      setSelectedRequestLineId(null);
      setShowRequestLines(false); */
      if (activeTabKey === null) {
        //setActiveTabKey(issuedLine.key);
        handleTabChange(issuedLine.key);
      }
    } else {
      message.info("Already added to the delivery");
    }
  };

  const searchAndAddMaterialRequest = (matRelId) => {
    if (matRelId && !addedMaterialRequestIds.includes(matRelId)) {
      setSearchMRLoading(true);
      materialRequestService
        .getById(matRelId)
        .then((data) => {
          //console.log("MR:", data.content);
          setAddedMaterialRequestIds([
            ...addedMaterialRequestIds,
            data.content.id,
          ]);
          //setMaterialRequest(data.content);

          let issueLines = [];
          for (var i = 0; i < data.content.requestLines.length; i++) {
            const newIssueLine = {
              key: lineIndex,
              requestLine: data.content.requestLines[i],
              requestNumber: data.content.requestNumber,
              stockType: data.content.requestLines[i].stockType,
              lotNumber: data.content.requestLines[i].lotNumber,
              measurementType: data.content.requestLines[i].measurementType,
              requestedQuantity:
                data.content.requestLines[i].requestedItems.length,
              requestedTotalQuantity: data.content.requestLines[i].quantity,
              requestedGrossMeasure:
                data.content.requestLines[i].requestedItems.length > 0 &&
                data.content.requestLines[i].requestedItems
                  .map((item) => item.grossWeight)
                  .reduce((prev, next) => prev + next),
              issuedItems: data.content.requestLines[i].requestedItems
                .filter((item) => item.mode === "REQUESTED")
                .map((t) => ({
                  ...t,
                  added: false,
                  requestLineId: data.content.requestLines[i].id,
                })),
              issuedQuantity: 0,
              issuedNetWeight: 0,
              issuedGrossWeight: 0,
            };
            issueLines.push(newIssueLine);
            lineIndex += 1;
          }
          //console.log("IssueLines:", issueLines);
          setStockIssueLines([...stockIssueLines, ...issueLines]);
          setMaterialRequestId(null);
          form.setFieldsValue({ materialRequest: null });
          setSearchMRLoading(false);
        })
        .catch((error) => {
          setSearchMRLoading(false);
          message.error(`${error}`);
        });
    }
  };

  const onSearchAndAddStockItem = (value) => {
    // Barcode should contain MaterialTypeCode|LotNumber|RunningNo
    if (value) {
      setLoadingStockItem(true);
      materialDetailService
        .search(`?serialNo=${encodeURIComponent(value)}`)
        .then((data) => {
          if (data.content && data.content[0]) {
            let stock = data.content[0];
            let stockAdded = false;

            if (!selectedRowKeys.includes(stock.id)) {
              issuedLines.forEach((line) => {
                console.log("Line:", line);
                console.log("Stock:", stock);
                if (
                  line.stockType.id === stock.stockType.id &&
                  //(line.lotNumber && stock.lotNumber && line.lotNumber.id === stock.lotNumber.id) &&
                  !line.issuedItems.map((item) => item.id).includes(stock.id)
                ) {
                  stockAdded = true;
                  line.issuedItems.push(stock);
                  onSelectChange(
                    line.issuedItems.map((item) => item.id),
                    ...stock.id
                  );
                  /* stockAdded = true;
                  line.issuedQuantity += 1;
                  line.issuedNetWeight += stock.netWeight;
                  line.issuedGrossWeight += stock.grossWeight; */

                  // if (
                  //   !line.issuedItems.map((item) => item.id).includes(stock.id)
                  // ) {
                  //   stockAdded = true;
                  //   stock.added = true;
                  //   stock.mode = "READY";
                  //   setAddedStocks([...addedStocks, stock]);
                  //   setSelectedRowKeys([...selectedRowKeys, stock.id]);
                  //   line.issuedItems.push(stock);
                  // }
                }
              });
            }
            if (!stockAdded) {
              message.error(
                "Sorry, No item found against the added delivery lines or already added."
              );
            } else {
              message.success("Successfully added to the delivery");
            }
            form.setFieldsValue({ stockItem: null });
          } else {
            message.error("Sorry, No item found against the barcode.");
          }
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        //const validated = validateDelivery();
        //if (validated.isValid) {
        setIsLoading(true);
        delete values.stockItem;
        delete values.materialRequest;

        values.issuedDate = moment(values.issuedDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        values.issuedLines = issuedLines.map((line) => ({
          requestLine: { id: line.requestLine.id },
          quantity: line.issuedItems.filter((item) => item.added).length,
          stockType: { id: line.stockType.id },
          lotNumber: line.lotNumber ? { id: line.lotNumber.id } : null,
          issuedItems: line.issuedItems
            .filter((item) => item.added)
            .map((item) => ({
              id: item.id,
            })),
        }));

        //console.log("OUT: ", values);
        //console.log("OUT: ", JSON.stringify(values));
        stockIssueService
          .create(values)
          .then((data) => {
            setIsLoading(false);
            history.push(`/stock-issue/${data.content.id}/edit`);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
        // } else {
        //   message.error(
        //     `Sorry!, Your order can not be proceed. Requested Quantity (${Number(
        //       validated.totalRequestedQuantity
        //     ).toFixed(2)}) and Issued Quantity (${Number(
        //       validated.totalIssuedQuantity
        //     ).toFixed(2)}) are mismatched. `
        //   );
        // }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onSelectChange = (rowKeys) => {
    //console.log("RowKeys:", rowKeys);
    setSelectedRowKeyMap(
      selectedRowKeyMap.set(activeTabKey.toString(), rowKeys)
    );
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    //console.log("all row keys:", allSelectedKeys);
    setSelectedRowKeys(allSelectedKeys);
    const totalRequestedStocks = issuedLines.flatMap(
      (item) => item.issuedItems
    );
    let loadedStocks = [];
    for (let item of totalRequestedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.added = true;
        item.mode = "READY";
        loadedStocks.push(item);
      } else {
        item.added = false;
        item.mode = "REQUESTED";
      }
    }
    setAddedStocks(loadedStocks);

    issuedLines.forEach((line) => {
      line.issuedQuantity = 0;
      line.issuedNetWeight = 0;
      line.issuedGrossWeight = 0;
      loadedStocks.forEach((stock) => {
        if (line.requestLine.id === stock.requestLineId) {
          line.issuedQuantity += 1;
          line.issuedNetWeight += stock.netWeight;
          line.issuedGrossWeight += stock.grossWeight;
        }
      });
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      handleTabChange(selectedIssueLineKeys[0]);
    },
  };

  const onCancel = () => {
    history.push(".");
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
  };

  const showMaterialRequestLines = (requestId) => {
    setShowRequestLines(false);
    if (requestId) {
      setSearchMRLoading(true);
      materialRequestService
        .getById(requestId)
        .then((data) => {
          setSearchMRLoading(false);
          setSelectedRequest(data.content);
          setShowRequestLines(true);
        })
        .catch((error) => {
          setSearchMRLoading(false);
          message.error(`${error}`);
        });
    }
  };

  const getQueryParams = (s) => {
    if (!s || typeof s !== "string" || s.length < 2) {
      return new Map();
    }

    const a = s
      .substr(1) // remove `?`
      .split("&") // split by `&`
      .map((x) => {
        const a = x.split("=");
        return [a[0], a[1]];
      }); // split by `=`

    return new Map(a);
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Request No:",
      dataIndex: "requestNumber",
      key: "requestNumber",
      with: "10%",
    });
    columns.push({
      title: "Material Type",
      key: "stockTypeCode",
      width: "15%",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.stockType.code.localeCompare(b.stockType.code),
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Requested Boxes",
      dataIndex: "requestedQuantity",
      key: "requestedQuantity",
      align: "right",
    });
    columns.push({
      title: "Requested Gross Weight(Kg)",
      dataIndex: "requestedGrossMeasure",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Loaded Gross Weight(Kg)",
      dataIndex: "issuedGrossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Loaded Boxes",
      dataIndex: "issuedQuantity",
      key: "quantity",
      align: "right",
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box S/N",
      dataIndex: "serialNo",
      key: "serialNo",
    });
    columns.push({
      title: "Stock Bin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "LOADED") {
          color = "#87d068";
        }
        if (text === "READY") {
          color = "#87d068";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Net Weight(Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    if (selectedStocks) {
      issuedLines.forEach((line) => {
        if (
          line.stockType.id === materialTypeId &&
          line.lotNumber.id === lotNumberId
        ) {
          line.issuedItems = selectedStocks.filter(
            (item) =>
              item.stockType.id === materialTypeId &&
              item.lotNumber.id === lotNumberId
          );
          /* line.issuedQuantity = line.issuedItems.length;
          line.issuedNetWeight = line.issuedItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
          line.issuedGrossWeight = line.issuedItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next); */
        }
      });
    }
    setIsStockSelectionVisible(false);
    setSelectedStockIds([]);
    setMaterialTypeId(null);
    setLotNumberId(null);
  };

  const getTabContent = (stockIssueLines) => {
    let tabPanes = [];
    stockIssueLines.forEach((line, index) => {
      if (line.issuedItems && line.issuedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType.code} / ${line.lotNumber ? line.lotNumber.number : ""} (${line.issuedQuantity})`}
            key={`${line.stockType.id}_${line.lotNumber ? line.lotNumber.id : ""}`}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => (record.added ? "ant-tag-green" : "")}
              rowSelection={rowSelection}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossValueSqm = 0;

                pageData.forEach(
                  ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                    totalQuantity += quantity ? quantity : 1;
                    //totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossValueSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 2}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong style={{ paddingLeft: 12 }}>
                          {totalQuantity}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to save these changes?"
        onConfirm={handleFormSumbit}
        key="update-confirm"
        disabled={addedStocks.length === 0}
      >
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={addedStocks.length === 0}
        >
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue/add">Issue Stocks</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["store", "id"]}
                  label="Store"
                  rules={[{ required: false, message: "Store is required" }]}
                >
                  <Select placeholder="Please select">
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["customer", "id"]}
                  label="Customer"
                  rules={[{ required: false, message: "Customer is required" }]}
                >
                  <Select placeholder="Please select" allowClear>
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="issuedDate"
                  label="Issue Date"
                  rules={[
                    { required: false, message: "Issue date is required" },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder="Issue date"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    onChange={setSelectedIssueDate}
                  />
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="vehicleNumber"
                  label="Vehicle Number"
                  rules={[
                    { required: false, message: "Vehicle Number is required" },
                  ]}
                >
                  <Input placeholder="Vehicle Number" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="driverName"
                  label="Driver Name"
                  rules={[
                    { required: false, message: "Driver Name is required" },
                  ]}
                >
                  <Input placeholder="Driver Name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactNumber"
                  label="Contact Number"
                  rules={[
                    { required: false, message: "Contact Number is required" },
                  ]}
                >
                  <Input placeholder="Contact Number" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="driverNic"
                  label="Driver NIC"
                  rules={[
                    { required: false, message: "Driver NIC is required" },
                  ]}
                >
                  <Input placeholder="Driver NIC" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="materialRequest"
                  label="Select Material Request"
                >
                  <Select
                    placeholder="Please select"
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => showMaterialRequestLines(e)}
                    loading={searchMRLoading}
                  >
                    {materialRequests.length > 0 &&
                      materialRequests.map((t) => (
                        <Option
                          value={t.id}
                          key={t.id}
                          style={{
                            color: `${addedMaterialRequestIds.includes(t.id)
                                ? "red"
                                : ""
                              }`,
                          }}
                        >
                          {t.requestNumber} [{t.orderStage}]
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
              <Col span={6}>
                <Form.Item
                  name="sealNumber"
                  label="Seal No:"
                  rules={[
                    { required: false, message: "Seal number is required" },
                  ]}
                >
                  <Input placeholder="Seal No:" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="dispatchNumber"
                  label="Dispatch No:"
                  rules={[
                    { required: false, message: "Dispatch number is required" },
                  ]}
                >
                  <Input placeholder="Dispatch No:" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                {showRequestLines && (
                  <Form.Item
                    name="materialRequestLine"
                    label="Select Material Request Line"
                  >
                    <Select
                      placeholder="Please select"
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => setSelectedRequestLineId(e)}
                    >
                      {selectedRequest.requestLines.length > 0 &&
                        selectedRequest.requestLines.map((t) => (
                          <Option
                            value={t.id}
                            key={t.id}
                            style={{
                              color: `${requestedLines.find((item) => item.id === t.id) || t.lineStage === "COMPLETE"
                                  ? "red"
                                  : ""
                                }`,
                            }}
                          >
                            {t.stockType.code} | {t.lotNumber ? t.lotNumber.number : ""} (
                            {t.quantity})
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={6}>
                {showRequestLines && (
                  <Form.Item label={<span></span>}>
                    <Button
                      type="primary"
                      onClick={() => {
                        addRequestLineToDelivery(selectedRequestLineId);
                      }}
                      loading={searchMRLoading}
                      disabled={!form.getFieldValue("materialRequestLine")}
                    >
                      Add
                    </Button>
                  </Form.Item>
                )}
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
            </Row>
            {issuedLines.length > 0 &&
              issuedLines.map((item) => item.issuedItems) &&
              issuedLines.map((item) => item.issuedItems).length > 0 && (
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item name="stockItem">
                      <Input.Search
                        loading={loadingStockItem}
                        placeholder="Enter valid barcode"
                        allowClear
                        enterButton="Search & Add Stock"
                        onSearch={onSearchAndAddStockItem}
                        style={{ marginBottom: 20 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            {issuedLines.length > 0 && (
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={issuedLines}
                //expandable={{ expandedRowRender, defaultExpandAllRows: true }}
                rowSelection={{
                  type: "radio",
                  ...issueLineRowSelection,
                }}
                rowClassName={(record) =>
                  record.requestedQuantity === record.issuedQuantity
                    ? "ant-tag-green"
                    : record.issuedQuantity > 0
                      ? "ant-tag-red"
                      : ""
                }
                size="small"
                summary={(pageData) => {
                  let totalRequestedQuantity = 0;
                  let totalRequestedQuantityWithoutStocks = 0;
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;
                  let totalRequestedGrossMeasure = 0;

                  pageData.forEach(
                    ({
                      requestedQuantity,
                      requestedTotalQuantity,
                      issuedQuantity,
                      issuedNetWeight,
                      issuedGrossWeight,
                      requestedGrossMeasure,
                    }) => {
                      totalRequestedQuantity += requestedQuantity;
                      totalRequestedQuantityWithoutStocks +=
                        requestedTotalQuantity;
                      totalQuantity += issuedQuantity;
                      totalNetWeight += issuedNetWeight;
                      totalGrossWeight += issuedGrossWeight;
                      totalRequestedGrossMeasure += requestedGrossMeasure;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalRequestedQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalRequestedGrossMeasure) &&
                              Number(totalRequestedGrossMeasure).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalGrossWeight) &&
                              Number(totalGrossWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalQuantity}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
          </Form>
          {issuedLines.length > 0 &&
            issuedLines.map((item) => item.issuedItems) &&
            issuedLines
              .map((item) => item.issuedItems)
              .reduce((prev, next) => prev + next).length > 0 && (
              <>
                <Divider orientation="left">
                  Stock Items -{" "}
                  {/* <Checkbox onChange={handleAllLoadChange}>Load All</Checkbox> */}
                </Divider>
                <Tabs
                  //defaultActiveKey={issuedLines[0].key}
                  onChange={handleTabChange}
                  activeKey={activeTabKey}
                >
                  {getTabContent(issuedLines)}
                </Tabs>
              </>
            )}
          <Modal
            title={`Stocks (${availableStocks.length})`}
            style={{ top: 48 }}
            visible={isStockSelectionVisible}
            okText={`Add Stocks (${selectedStockIds.length})`}
            onOk={handleAddStocks}
            //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
            onCancel={() => setIsStockSelectionVisible(false)}
            //destroyOnClose={true}
            width={1300}
          >
            <StockSelection
              stocks={availableStocks}
              selectedIds={selectedStockIds}
              //customer={selectedCustomer}
              onSelectChange={handleStockSelection}
            />
          </Modal>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrGrAdd;
