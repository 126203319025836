import API from "_helpers/API";
import { handleError, handleResponse } from "_helpers/ServiceUtil";

const baseUrl = `${process.env.REACT_APP_API_URL}/oauth/token`;

export const loginservice = {
  login,
};

function login(username, password) {
  /* return fetchWrapper.post(
    baseUrl,
    `grant_type=password&username=${username}&password=${password}`,
    {
      auth: { username: "SL", password: "Sl43&$^fdgd*+!!@#Agdo4Ged" },
    }
  ); */
  return API.post(
    baseUrl,
    `grant_type=password&username=${username}&password=${password}`,
    {
      auth: { username: "SL", password: "Sl43&$^fdgd*+!!@#Agdo4Ged" },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}
