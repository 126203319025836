import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import {
  departmentService,
  lotNumberService,
  materialRequestLineService,
  materialTypeService,
  measurementTypeService,
  reasonService,
} from "_services";
import { CloseOutlined } from "@ant-design/icons";
import { PriorityStatuses } from "_helpers";

function StrSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [requestLine, setRequestLine] = useState({});
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [error, setError] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    getRequestLine(id);
    getMaterialTypes();
    getLotNumbers();
    getMeasurementTypes();
    getDepartments();
    getRequestReasons();
  }, []);

  const getRequestLine = (lineId) => {
    setLoading(true);
    materialRequestLineService
      .getById(lineId)
      .then((data) => {
        setLoading(false);
        const line = data.content;
        if (line.lineStage === "PREPARE") {
          history.push(`/material-request-lines/${line.id}/prepare`);
        }

        line.requestTime = moment(line.requestTime);
        if (line.uom === "BOX") {
          line.quantity = line.totalItems;
        } else if (line.uom === "CONE") {
          line.quantity = line.qty;
        } else if (line.uom === "KG") {
          line.quantity = line.quantity;
        }
        setRequestLine(line);
        onFill(line);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  };

  const getRequestReasons = () => {
    reasonService
      .search("?type=REQUEST")
      .then((x) => {
        setReasons(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getMaterialTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((data) => {
        setMaterialTypes(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((data) => {
        setLotNumbers(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getMeasurementTypes = () => {
    measurementTypeService
      .getSuggestion()
      .then((data) => {
        setMeasurementTypes(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getDepartments = () => {
    departmentService
      .getSuggestion()
      .then((data) => {
        setDepartments(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setUpdating(true);

        const payload = { reason: values.reason };
        //console.log("Update: ", values);
        materialRequestLineService
          .reject(id, payload)
          .then(() => {
            message.success("Successfully rejected!");
            setUpdating(false);
            window.location.reload();
          })
          .catch((error) => {
            setUpdating(false);
            setError(error);
          });
      })
      .catch((errorInfo) => {});
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const handleStatusUpdate = () => {
    setLoadingStatusUpdate(true);
    let newStatus = requestLine.lineStage;
    if (requestLine.lineStage === "OPEN") {
      newStatus = "VERIFICATION";
    } else if (requestLine.lineStage === "VERIFICATION") {
      newStatus = "PREPARE";
    } else if (requestLine.lineStage === "PREPARE") {
      newStatus = "READY";
    } else if (requestLine.lineStage === "PROCESSING") {
      newStatus = "COMPLETE";
    }
    const statusUpdatePayload = {
      requestStatus: newStatus,
    };
    materialRequestLineService
      .updateStatus(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingStatusUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to reject this line?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
      >
        <Button key="1" type="primary" htmlType="submit" loading={updating}>
          Reject
        </Button>
      </Popconfirm>
    );
    if (requestLine.lineStage !== "COMPLETE") {
      let statusButtonText = "";
      if (requestLine.lineStage === "OPEN") {
        statusButtonText = "Start Verification";
      } else if (requestLine.lineStage === "VERIFICATION") {
        statusButtonText = "Start Scan";
      } else if (requestLine.lineStage === "PREPARE") {
        statusButtonText = "Complete Scan";
      }
      buttons.push(
        <Popconfirm
          title={`Are you sure to ${statusButtonText} it?`}
          onConfirm={handleStatusUpdate}
          key="con-complete"
        >
          <Button
            key="btn-complete"
            htmlType="button"
            loading={loadingStatusUpdate}
          >
            {statusButtonText}
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Prod Date",
      dataIndex: "productionDate",
      key: "productionDate",
      width: "8%",
    });
    columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "8%",
    });
    columns.push({
      title: "Inspect.St",
      dataIndex: "inspectionStatus",
      key: "inspectionStatus",
      width: "5%",
    });
    columns.push({
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: "7%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType.supplier) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.stockType.supplier.name}
            >
              {record.stockType.supplier.code}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "7%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Pallet",
      dataIndex: "palletNo",
      key: "palletNo",
      align: "right",
    });
    columns.push({
      title: "B/No",
      dataIndex: "tempNumber",
      key: "tempNumber",
      width: "4%",
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "LOADED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "2%",
      render: (text, record) => {
        return (
          <span>
            {/* ["REQUESTED"].includes(record.mode) && (
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={() => handleStockItemDelete(record.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            ) */}
          </span>
        );
      },
    });

    return columns;
  };

  const getRowColor = (record) => {
    let color = "";
    if (record) {
      const text = record.lineStage;
      if (text === "OPEN") {
        color = "#87d068";
      }
      if (text === "UPDATE") {
        color = "#2db7f5";
      }
      if (text === "VERIFICATION") {
        color = "#108ee9";
      }
      if (text === "PREPARE") {
        color = "#f50";
      }
      if (text === "READY") {
        color = "#faad14";
      }
      if (text === "ASSIGNED") {
        color = "#ffd666";
      }
      if (text === "PROCESSING") {
        color = "#ffd666";
      }
      if (text === "COMPLETE") {
        color = "#ffd666";
      }
      if (text === "REJECTED") {
        color = "#ffd666";
      }
      if (text === "CANCEL") {
        color = "#ffd666";
      }
    }

    return color;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {requestLine?.issueRequest?.requestNumber} ::{" "}
                {requestLine.mrnNumber} ::{" "}
                <Tag color={getRowColor(requestLine)}>
                  {requestLine.lineStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} status={requestLine.lineStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="mrnNumber" label="MRN No.">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["department", "id"]} label="Department">
                    <Select placeholder="Please select" disabled>
                      {departments.length > 0 &&
                        departments.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["stockType", "id"]} label="Material">
                    <Select placeholder="Please select" disabled>
                      {materialTypes.length > 0 &&
                        materialTypes.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["lotNumber", "id"]} label="Lot No">
                    <Select placeholder="Please select" disabled>
                      {lotNumbers.length > 0 &&
                        lotNumbers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.number}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="requestTime" label="Req. Time">
                    <DatePicker
                      placeholder="Req. Time"
                      style={{ width: "100%" }}
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["measurementType", "id"]}
                    label="Measurement"
                  >
                    <Select placeholder="Please select" disabled>
                      {measurementTypes.length > 0 &&
                        measurementTypes
                          .filter((item) =>
                            ["BOX", "CONE", "KG"].includes(item.name)
                          )
                          .map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.name}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="quantity" label="Quantity">
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="priorityStatus" label="Priority Status">
                    <Select placeholder="Please select" disabled>
                      {PriorityStatuses.map((t) => (
                        <Option value={t.value} key={t.value}>
                          {t.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="width" label="Width">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="colorCode" label="Color Code">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["reason", "id"]}
                    label="Reason for Reject"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                    >
                      {reasons.length > 0 &&
                        reasons.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>

              {requestLine &&
                requestLine.requestedItems &&
                requestLine.requestedItems.length > 0 && (
                  <>
                    <Divider orientation="left">Verified Stock Items</Divider>
                    <Table
                      bordered
                      size="small"
                      rowKey="id"
                      columns={getStockItemColumns()}
                      dataSource={requestLine.requestedItems}
                      pagination={false}
                      summary={(pageData) => {
                        let totalQuantity = 0;
                        let totalNetWeight = 0;
                        let totalGrossWeight = 0;

                        pageData.forEach(
                          ({ quantity, netWeight, grossWeight }) => {
                            totalQuantity += quantity;
                            totalNetWeight += netWeight;
                            totalGrossWeight += grossWeight;
                          }
                        );

                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                colSpan={getStockItemColumns().length - 6}
                              >
                                <Text strong>Total</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {requestLine.requestedItems.length}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>{totalQuantity}</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {Number(totalNetWeight).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong>
                                  {Number(totalGrossWeight).toFixed(2)}
                                </Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell colSpan={2} />
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  </>
                )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrSummary;
