import React from "react";
import { Route, Switch } from "react-router-dom";
import Summary from "./Summary";
import SiluetaSummary from "./silueta/Summary";
import List from "./List";
import StrGrList from "./str_gr/List";
import StrRmList from "./str_rm/List";
import SiluetaList from "./silueta/List";
import PrymList from "./prym/List";
import BinTransfer from "./BinTransfer";
import PrymBinTransfer from "./prym/BinTransfer";
import StrGrBinTransfer from "./str_gr/BinTransfer";
import UpdateViaUpload from "./UpdateViaUpload";
import PrymUpdateViaUpload from "./prym/UpdateViaUpload";
import StrRmUpdateViaUpload from "./str_rm/UpdateViaUpload";
import StrRmEditMaterialType from "./str_rm/EditMaterialType";
import StrRmEditLotNumber from "./str_rm/EditLotNumber";
import PrymEditLotNumber from "./prym/EditLotNumber";
import StrRmBinTransfer from "./str_rm/BinTransfer";
import StrRmSummary from "./str_rm/Summary";
import StrRmHistory from "./str_rm/History";
import PrymSummary from "./prym/Summary";
import EditMaterialType from "./EditMaterialType";
import PrymEditMaterialType from "./prym/EditMaterialType";
import PrymSplit from "./prym/Split";
import { Auth } from "_helpers";

function MaterialDetails({ match }) {
  const { path } = match;
  const { id } = match.params;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaList} />
        <Route path={`${path}/bin-transfer`} component={BinTransfer} />
        <Route path={`${path}/:id/summary`} component={SiluetaSummary} />
        <Route path={`${path}/update-via-upload`} component={UpdateViaUpload} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR_GR") {
    return (
      <Switch>
        <Route exact path={path} component={StrGrList} />
        <Route path={`${path}/bin-transfer`} component={StrGrBinTransfer} />
        <Route path={`${path}/:id/summary`} component={Summary} />
        <Route path={`${path}/update-via-upload`} component={UpdateViaUpload} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrRmList} />
        <Route path={`${path}/bin-transfer`} component={StrRmBinTransfer} />
        <Route path={`${path}/:id/summary`} component={StrRmSummary} />
        <Route path={`${path}/:id/history`} component={StrRmHistory} />
        <Route path={`${path}/update-via-upload/:receiptId`} component={StrRmUpdateViaUpload} />
        <Route path={`${path}/material-type-transfer`} component={StrRmEditMaterialType} />
        <Route path={`${path}/lot-transfer`} component={StrRmEditLotNumber} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymList} />
        <Route path={`${path}/bin-transfer`} component={PrymBinTransfer} />
        <Route path={`${path}/:id/summary`} component={PrymSummary} />
        <Route path={`${path}/:id/split`} component={PrymSplit} />
        <Route path={`${path}/update-via-upload`} component={PrymUpdateViaUpload} />
        <Route path={`${path}/material-type-transfer`} component={PrymEditMaterialType} />
        <Route path={`${path}/lot-transfer`} component={PrymEditLotNumber} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/bin-transfer`} component={BinTransfer} />
        <Route path={`${path}/material-type-transfer`} component={EditMaterialType} />
        <Route path={`${path}/:id/summary`} component={Summary} />
        <Route path={`${path}/update-via-upload`} component={UpdateViaUpload} />
      </Switch>
    );
  }
}

export { MaterialDetails };
