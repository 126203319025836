import { Button, Col, Form, message, Row, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import { lotNumberService, stockClassificationService } from "_services";

function LotNumberSearch(props) {
  const [form] = Form.useForm();
  const [stockClasses, setStockClasses] = useState([]);

  useEffect(() => {
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
  }, []);

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.number) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `number=${encodeURIComponent(values.number)}`;
    }
    if (values.stockClass) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockClassId=${encodeURIComponent(values.stockClass)}`;
    }
    lotNumberService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="number" label="Lot/Batch Number">
            <Input placeholder="Number" allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="stockClass" label="Stock Clasification">
            <Select
              showSearch
              allowClear={true}
              showArrow={false}
              placeholder="Please select"
            >
              {stockClasses.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default LotNumberSearch;
