import {
  Breadcrumb,
  PageHeader,
  Table,
  message,
  Tooltip,
  Input,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { binService } from '_services';
import TabMenu from '../TabMenu';

function List({ match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [bin, setBin] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState('');

  useEffect(() => {
    setLoading(true);

    getItems(pageNumber, pageSize);
    binService
      .getById(id)
      .then((data) => {
        setBin(data.content);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf('?') === -1 ? '?' : '&';
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    binService
      .getStockItems(id, `${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const getAdditionalButtons = () => {
    let buttons = [];
    return buttons;
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    //console.log("Page ChANGE:", pageNumber, ", pageSize:", pageSize);
    getItems(pageNumber, pageSize);
  };

  const getColumns = () => {
    let columns = [];
    /* columns.push({
      title: 'Serial No.',
      dataIndex: 'serialNo',
      key: 'serialNo',
      width: '18%',
      fixed: 'left',
      render: (text, record) => {
        return (
          <>
            <Link to={`/material-details/${record.id}/summary`}>{text}</Link>
            <Input value={record.id} hidden />
          </>
        );
      },
    }); */
    columns.push({
      title: 'Material Code',
      dataIndex: 'stockType',
      key: 'stockType',
      width: '8%',
      fixed: 'left',
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: 'Lot No',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      width: '10%',
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.lotNumber) {
          return (
            <Tooltip placeholder='topLeft' title={record.lotNumber.number}>
              {record.lotNumber.number}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: 'Receipt No:',
      key: 'preReceiptNumber',
      width: '12%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt
        ) {
          return (
            <Tooltip
              placeholder='topLeft'
              title={
                record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber
              }
            >
              <Link
                to={`/packing-lists/${record.stockPreReceiptLine.stockPreReceipt.id}/summary`}
                target='_blank'
              >
                {record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber}
              </Link>
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: 'MRN No:',
      key: 'mrnNumber',
      width: '8%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.requestedLine && record.requestedLine.issueRequest) {
          return (
            <Tooltip
              placeholder='topLeft'
              title={`${
                record.requestedLine.mrnNumber
                  ? record.requestedLine.mrnNumber
                  : ''
              } (${record.requestedLine.issueRequest.requestNumber})`}
            >
              <Link
                to={`/material-requests/${record.requestedLine.issueRequest.id}/summary`}
                target='_blank'
              >
                {`${
                  record.requestedLine.mrnNumber
                    ? record.requestedLine.mrnNumber
                    : ''
                } (${record.requestedLine.issueRequest.requestNumber})`}
              </Link>
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: 'Delivery No:',
      key: 'deliveryNumber',
      width: '10%',
      render: (text, record) => {
        if (
          record.issuedLine &&
          record.issuedLine.stockIssue &&
          record.issuedLine.stockIssue.issueNumber
        ) {
          return (
            <Tooltip
              placeholder='topLeft'
              title={record.issuedLine.stockIssue.issueNumber}
            >
              <Link
                to={`/stock-issue/${record.issuedLine.stockIssue.id}/summary`}
                target='_blank'
              >
                {record.issuedLine.stockIssue.issueNumber}
              </Link>
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: 'Rec. Date',
      key: 'receivedDate',
      dataIndex: 'receivedDate',
      width: '15%',
    });
    columns.push({
      title: 'Prod Date',
      key: 'productionDate',
      dataIndex: 'productionDate',
      width: '9%',
    });
    columns.push({
      title: 'Supplier',
      key: 'supplier',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType) {
          return (
            <Tooltip
              placeholder='topLeft'
              title={record.stockType.supplier.name}
            >
              {record.stockType.supplier.code}
            </Tooltip>
          );
        }
      },
    });
    columns.push({
      title: 'Bin',
      dataIndex: 'stockBin',
      key: 'stockBin',
      width: '6%',
      fixed: 'right',
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: 'Plt No',
      dataIndex: 'palletNo',
      key: 'palletNo',
      width: '4%',
      fixed: 'right',
    });
    columns.push({
      title: 'Box No:',
      dataIndex: 'tempNumber',
      key: 'tempNumber',
      width: '5%',
      fixed: 'right',
    });
    columns.push({
      title: 'Status',
      dataIndex: 'mode',
      key: 'mode',
      width: '8%',
      fixed: 'right',
      render: (text) => {
        let color = '';
        if (text === 'AVAILABLE') {
          color = '#87d068';
        }
        if (text === 'REQUESTED') {
          color = '#2db7f5';
        }
        if (text === 'ISSUED') {
          color = '#108ee9';
        }
        if (text === 'DELIVERED') {
          color = '#f50';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: 'Cone',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '5%',
      fixed: 'right',
    });
    columns.push({
      title: 'Net.W',
      dataIndex: 'netWeight',
      key: 'netWeight',
      align: 'right',
      width: '6%',
      fixed: 'right',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href='/dashboard'>
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/bins'>
              <span>Bin Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bins/${bin.id}/summary`}>
              <span>
                Bin: {bin.code} ({bin.availableStockQuantity})
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Sub Bins</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu='sub-bins' id={id} />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={getColumns()}
            dataSource={items}
            scroll={{ x: 1500 }}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: true,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
}

export default List;
