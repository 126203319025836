import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import {
  departmentService,
  lotNumberService,
  materialRequestLineService,
  materialTypeService,
  measurementTypeService,
  reasonService,
} from "_services";
import { CloseOutlined } from "@ant-design/icons";
import { PriorityStatuses } from "_helpers";

function StrEdit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [requestLine, setRequestLine] = useState({});
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    getRequestLine(id);
    getMaterialTypes();
    getLotNumbers();
    getMeasurementTypes();
    getDepartments();
  }, []);

  const getRequestLine = (lineId) => {
    setLoading(true);
    materialRequestLineService
      .getById(lineId)
      .then((data) => {
        setLoading(false);
        const line = data.content;
        if (!["OPEN", "UPDATE"].includes(line.lineStage)) {
          history.push(`/material-request-lines/${line.id}/summary`);
        }

        line.requestTime = moment(line.requestTime);
        if (line.uom === "BOX") {
          line.quantity = line.totalItems;
        } else if (line.uom === "CONE") {
          line.quantity = line.qty;
        } else if (line.uom === "KG") {
          line.quantity = line.quantity;
        }
        setRequestLine(line);
        onFill(line);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  };

  const getMaterialTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((data) => {
        setMaterialTypes(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((data) => {
        setLotNumbers(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getMeasurementTypes = () => {
    measurementTypeService
      .getSuggestion()
      .then((data) => {
        setMeasurementTypes(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getDepartments = () => {
    departmentService
      .getSuggestion()
      .then((data) => {
        setDepartments(data.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setUpdating(true);

        values.requestTime = moment(values.requestTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        values.issueRequest = { id: requestLine.issueRequest.id };
        const measurementType = measurementTypes.find(
          (item) => item.id === values.measurementType.id
        );
        if (measurementType.name === "BOX") {
          values.totalItems = values.quantity;
        } else if (measurementType.name === "CONE") {
          values.qty = values.quantity;
        } else if (measurementType.name === "KG") {
          values.quantity = values.quantity;
        }

        //console.log("Update: ", values);
        materialRequestLineService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setUpdating(false);
            window.location.reload();
          })
          .catch((error) => {
            setUpdating(false);
            setError(error);
          });
      })
      .catch((errorInfo) => {});
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push(`/material-request-lines/${requestLine.id}/summary`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to apply the changes?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
      >
        <Button key="1" type="primary" htmlType="submit" loading={updating}>
          Update
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getRowColor = (record) => {
    let color = "";
    if (record) {
      const text = record.lineStage;
      if (text === "OPEN") {
        color = "#87d068";
      }
      if (text === "UPDATE") {
        color = "#2db7f5";
      }
      if (text === "VERIFICATION") {
        color = "#108ee9";
      }
      if (text === "PREPARE") {
        color = "#f50";
      }
      if (text === "READY") {
        color = "#faad14";
      }
      if (text === "ASSIGNED") {
        color = "#ffd666";
      }
      if (text === "PROCESSING") {
        color = "#ffd666";
      }
      if (text === "COMPLETE") {
        color = "#ffd666";
      }
      if (text === "REJECTED") {
        color = "#ffd666";
      }
      if (text === "CANCEL") {
        color = "#ffd666";
      }
    }

    return color;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {requestLine?.issueRequest?.requestNumber} ::{" "}
                {requestLine.mrnNumber} ::{" "}
                <Tag color={getRowColor(requestLine)}>
                  {requestLine.lineStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="summary" id={id} status={requestLine.lineStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="mrnNumber"
                    label="MRN No."
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["department", "id"]}
                    label="Department"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    >
                      {departments.length > 0 &&
                        departments.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["stockType", "id"]}
                    label="Material"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    >
                      {materialTypes.length > 0 &&
                        materialTypes.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.code}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["lotNumber", "id"]}
                    label="Lot No"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    >
                      {lotNumbers.length > 0 &&
                        lotNumbers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.number}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="requestTime"
                    label="Req. Time"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Req. Time"
                      style={{ width: "100%" }}
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["measurementType", "id"]}
                    label="Measurement"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    >
                      {measurementTypes.length > 0 &&
                        measurementTypes
                          .filter((item) =>
                            ["BOX", "CONE", "KG"].includes(item.name)
                          )
                          .map((t) => (
                            <Option value={t.id} key={t.id}>
                              {t.name}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="quantity"
                    label="Quantity"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="priorityStatus"
                    label="Priority Status"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      showSearch
                      disabled={
                        !["OPEN", "UPDATE"].includes(requestLine.lineStage)
                      }
                    >
                      {PriorityStatuses.map((t) => (
                        <Option value={t.value} key={t.value}>
                          {t.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrEdit;
