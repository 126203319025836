import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/stocks`;

export const materialDetailService = {
  getAll,
  getSuggestion,
  getDailyStat,
  search,
  searchAvailable,
  getById,
  getByBarcode,
  create,
  update,
  bulkUpdate,
  transfer,
  split,
  stockTypeTransfer,
  lotNumberTransfer,
  generateAndDownloadBarcodes,
  exportExcel,
  updateScannedItems,
  getHistory,
  delete: _delete,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function searchAvailable(params) {
  return fetchWrapper.get(`${baseUrl}/available${params}`);
}

function getDailyStat() {
  return fetchWrapper.get(`${baseUrl}/summary`);
}

function getSuggestion(params) {
  return fetchWrapper.get(`${baseUrl}/suggestion${params}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getByBarcode(barcode) {
  return fetchWrapper.get(`${baseUrl}/findByBarcode${barcode}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function bulkUpdate(params) {
  return fetchWrapper.put(`${baseUrl}/bulk`, params);
}

function transfer(params) {
  return fetchWrapper.put(`${baseUrl}/transfer`, params);
}

function split(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/split`, params);
}

function stockTypeTransfer(params) {
  return fetchWrapper.put(`${baseUrl}/stock-type-transfer`, params);
}

function lotNumberTransfer(params) {
  return fetchWrapper.put(`${baseUrl}/lot-transfer`, params);
}

function generateAndDownloadBarcodes(params) {
  /* return fetchWrapper.post(`${baseUrl}/barcodes`, params, {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    },
    responseType: "blob"
}); */
  return fetchWrapper.post(`${baseUrl}/excel`, params, {
    responseType: "blob",
  });
}

function exportExcel(params) {
  return fetchWrapper.get(`${baseUrl}/export-excel${params}`, {
    responseType: "blob",
  });
}

function updateScannedItems(params) {
  return fetchWrapper.put(`${baseUrl}/scan`, params);
}

function getHistory(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/history`);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
