import React from "react";
import { Route, Switch } from "react-router-dom";
import List from "./List";
import Add from "./Add";
import SiluetaAdd from "./silueta/Add";
import Summary from "./Summary";
import SiluetaSummary from "./silueta/Summary";
import SiluetaList from "./silueta/List";
import { Auth } from "_helpers";

function StockCheckLocations({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaList} />
        <Route path={`${path}/add`} component={SiluetaAdd} />
        <Route path={`${path}/:locationId/summary`} component={SiluetaSummary} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/:locationId/summary`} component={Summary} />
      </Switch>
    );
  }
}

export { StockCheckLocations };
