import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tooltip,
  Tag,
  Tabs,
  Spin,
  Popconfirm,
  Space,
  Modal,
  Badge,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  materialDetailService,
  measurementTypeService,
  sbuService,
  zoneService,
  subLocationService,
  reasonService,
} from "_services";
import { ScanOutlined, UndoOutlined } from "@ant-design/icons";
import StockSelection from "./StockSelection";
import TabMenu from "./TabMenu";

let lineIndex = 0;

function StrRmVerify({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [stockItemLoading, setStockItemLoading] = useState(false);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const [loadingCompleteVerification, setLoadingCompleteVerification] =
    useState(false);
  const [loadingScanUpdate, setLoadingScanUpdate] = useState(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequest, setMaterialRequest] = useState({});
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);

  const [availableStocks, setAvailableStocks] = useState([]);
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [selectedStockTypeId, setSelectedStockTypeId] = useState(null);
  const [selectedLotNumberId, setSelectedLotNumberId] = useState(null);
  const [selectedLineKey, setSelectedLineKey] = useState(null);
  const [selectedMrLineKeys, setSelectedMrLineKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [activeRequestLine, setActiveRequestLine] = useState(null);
  const [enableVerify, setEnableVerify] = useState(false);
  const [error, setError] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [scannedPackSerialNos, setScannedPackSerialNos] = useState([]);
  const [scannedItems, setScannedItems] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [rejectedLines, setRejectedLines] = useState([]);
  const [form] = Form.useForm();

  //let selectedStockIds = [];

  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const materialTypeData = await materialTypeService.getSuggestion();
      const lotNumberData = await lotNumberService.getSuggestion();
      const measurementTypeData = await measurementTypeService.getSuggestion();
      const materialRequestData = await materialRequestService.getById(id);

      if (materialRequestData) {
        let materialRequest = materialRequestData.content;
        if (materialRequest.orderStage !== "PREPARE") {
          history.push(`/material-requests/${materialRequest.id}/summary`);
        }

        materialRequest.requestDate = moment(materialRequest.requestDate);
        materialRequest.requestLines.forEach((item, i) => {
          item.key = item.id;
        });
        const mrCustomer = await companyService.getById(
          materialRequest.customer.id
        );
        const officeAddresses = mrCustomer.content.addresses.filter(
          (item) => item.addressType === "OFFICE"
        );

        materialRequest.requestLines.forEach((line) => {
          line.stockTypeObj = line.stockType;
          line.lotNumberObj = line.lotNumber;
          lineIndex++;
        });
        materialRequest.subLocationId = materialRequest.subLocation?.id;
        materialRequest.zoneId = materialRequest.subLocation?.zone?.id;
        materialRequest.sbuId = materialRequest.subLocation?.zone?.sbu?.id;
        // console.log("MR:", materialRequest);
        setMaterialRequest(materialRequest);
        setMaterialRequestLines([].concat(materialRequest.requestLines));
        setAddresses(officeAddresses);
        setSelectedZoneId(materialRequest.zoneId);
        setSelectedSbuId(materialRequest.sbuId);
        onFill(materialRequest);
        const scannedSerialNumbers = materialRequest.requestLines
          .flatMap((item) => item.requestedItems)
          .filter((item) => item.mode === "REQUEST_SCANNED")
          .map((item) => item.serialNo);
        const scannedPackSerialNumbers = materialRequest.requestLines
          .flatMap((item) => item.requestedItems)
          .filter((item) => item.mode === "REQUEST_SCANNED")
          .filter((item) => item.packSerialNo != null)
          .map((item) => item.packSerialNo);
        setScannedBarcodes([...scannedBarcodes, ...scannedSerialNumbers]);
        setScannedPackSerialNos([
          ...scannedPackSerialNos,
          ...scannedPackSerialNumbers,
        ]);
        setScannedItems([
          ...scannedItems,
          ...materialRequest.requestLines
            .flatMap((item) => item.requestedItems)
            .filter((item) => item.mode === "REQUEST_SCANNED"),
        ]);
        if (
          activeTabKey === null &&
          materialRequest.requestLines &&
          materialRequest.requestLines.length > 0
        ) {
          handleTabChange(materialRequest.requestLines[0].key);
        }
      }

      setCustomers(customerData.content);
      setMaterialTypes(materialTypeData.content);
      setLotNumbers(lotNumberData.content);
      setMeasurementTypes(measurementTypeData.content);
      setLoading(false);
    };

    fetchAlldatas();
    getBusinessUnits();
    getZones();
    getSubLocations();
    getRequestReasons();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getRequestReasons = () => {
    reasonService
      .search("?type=REQUEST")
      .then((x) => {
        setReasons(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const validateRequestLines = (lines) => {
    let validLines = true;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (!line.stockType || !line.quantity || !line.quantity > 0) {
          validLines = false;
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        //if (validateRequestLines(values.requestLines)) {
        setUpdating(true);
        // console.log(", requestLines:", materialRequestLines);

        values.requestDate = moment(values.requestDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        values.requestLines = materialRequestLines.map((line) => ({
          id: line.id,
          stockType: { id: line.stockType.id },
          lotNumber: line.lotNumber ? { id: line.lotNumber.id } : null,
          department: line.department ? { id: line.department.id } : null,
          machine: line.machine ? { id: line.machine.id } : null,
          mrnNumber: line.mrnNumber,
          requestTime: line.requestTime,
          priorityStatus: line.priorityStatus,
          quantity: line.quantity || line.netWeight || line.qty,
          totalItems: line.totalItems,
          measurementType: { id: line.measurementType.id },
          requestedItems: line.requestedItems.map((item) => ({
            id: item.id,
          })),
        }));
        // console.log("Submit Val: ", values);
        /* values.requestLines.forEach((line) => {
                    delete line.key;
                    line.measurementType = {
                        id: measurementTypes.find((item) => item.name === "Kg").id,
                    };
                }); */

        //console.log("MR: ", JSON.stringify(values));

        materialRequestService
          .update(materialRequest.id, values)
          .then(() => {
            setUpdating(false);
            message.success("Successfully updated!");
            //history.push(`${path}`.replace(":id", id));
            window.location.reload();
            setEnableVerify(false);
          })
          .catch((error) => {
            setUpdating(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const validateVerifiationCompletion = () => {
    let validation = true;
    materialRequestLines.forEach((line) => {
      if (
        line.requestedItems.length === 0 ||
        (line.uom === "Kg" &&
          line.netWeight >
            line.requestedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next)) ||
        (line.uom === "BOX" && line.totalItems > line.requestedItems.lenght) ||
        (line.uom === "CONE" &&
          line.qty >
            line.requestedItems
              .map((item) => item.quantity)
              .reduce((prev, next) => prev + next))
      ) {
        validation = false;
      }
    });

    return validation;
  };

  const onCompleteVerification = () => {
    // Removed validation as per request
    // if (validateVerifiationCompletion()) {
    setLoadingCompleteVerification(true);
    const payload = {
      orderStage: "VERIFIED",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setLoadingCompleteVerification(false);
        message.success("Successfully completed verificaiton!");
        history.push(`/material-requests/${id}/summary`);
      })
      .catch((error) => {
        setLoadingCompleteVerification(false);
        message.error(`${error}`);
      });
    // } else {
    //   message.error('Sorry, some of the request lines are not yet verified!');
    // }
  };

  const onCancel = () => {
    history.push(`/material-requests/${materialRequest.id}/summary`);
  };

  const handleStockItemUnVerified = (stockItemId) => {
    setLoadingScanUpdate(true);
    const scanUpdatePayload = {
      refId: materialRequest.id,
      type: "REQUEST",
      isCancelled: 1,
      stockItems: [
        { id: stockItemId, requestedLine: { id: activeRequestLine.id } },
      ],
    };
    materialDetailService
      .updateScannedItems(scanUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingScanUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingScanUpdate(false);
        setError(error);
      });
    /* materialRequestService
      .deleteLineItem(id, stockItemId)
      .then((x) => {
        message.success("Successfully unverified!");
        window.location.reload();
      })
      .catch((error) => {
        message.error(`${error}`);
      }); */
  };

  const handleRequestLineUnVerified = (requestLineId) => {
    materialRequestService
      .updateLine(id, requestLineId, { lineStage: "VERIFICATION_INPROGRESS" })
      .then((x) => {
        message.success("Successfully unverified!");
        window.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleRequestLineVerified = (requestLineId) => {
    materialRequestService
      .updateLine(id, requestLineId, { lineStage: "VERIFIED" })
      .then((x) => {
        message.success("Successfully verified!");
        window.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleRequestLineDelete = (requestLineId) => {
    setLoading(true);
    materialRequestService
      .deleteLine(id, requestLineId)
      .then((x) => {
        setLoading(false);
        message.success("Successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const onAcceptRequest = () => {
    setAccepting(true);
    const payload = {
      orderStage: "ACCEPTED",
    };
    materialRequestService
      .updateStatus(materialRequest.id, payload)
      .then(() => {
        setAccepting(false);
        message.success("Successfully accepted!");
        history.push("/material-requests");
      })
      .catch((error) => {
        setAccepting(false);
        setError(error);
      });
  };

  const handleScanUpdate = () => {
    setLoadingScanUpdate(true);
    const scanUpdatePayload = {
      refId: materialRequest.id,
      type: "REQUEST",
      stockItems: scannedItems
        .filter((item) => item.mode === "AVAILABLE")
        .map((item) => ({
          id: item.id,
          requestedLine: { id: item.requestedLine.id },
        })),
    };
    materialDetailService
      .updateScannedItems(scanUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingScanUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingScanUpdate(false);
        setError(error);
      });
  };

  const handleStatusUpdate = () => {
    setLoadingStatusUpdate(true);
    const statusUpdatePayload = {
      requestStatus: "READY",
    };
    materialRequestService
      .updateStatus(id, statusUpdatePayload)
      .then(() => {
        message.success("Successfully status changed!");
        setLoadingStatusUpdate(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingStatusUpdate(false);
        setError(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (materialRequest.orderStage === "PREPARE") {
      let statusButtonText = "Complete Scan";
      buttons.push(
        <Popconfirm
          title={`Are you sure to ${statusButtonText} it?`}
          onConfirm={handleStatusUpdate}
          key="con-complete"
          disabled={
            scannedItems.filter((item) => item.mode === "AVAILABLE").length > 0
          }
        >
          <Button
            key="btn-complete"
            type="primary"
            htmlType="button"
            loading={loadingStatusUpdate}
            disabled={
              scannedItems.filter((item) => item.mode === "AVAILABLE").length >
              0
            }
          >
            {statusButtonText}
          </Button>
        </Popconfirm>
      );
    }
    if (rejectedLines.length > 0) {
      buttons.push(
        <Popconfirm
          title="Are you sure to reject reason updated lines?"
          onConfirm={handleRejectLines}
          key="reject"
        >
          <Button
            key="btn-reject"
            htmlType="submit"
            loading={loadingReject}
            type="primary"
          >
            Reject Lines
          </Button>
        </Popconfirm>
      );
    }
    if (materialRequest.orderStage === "PREPARE") {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleScanUpdate}
          key="scan-confirm"
          disabled={
            scannedItems.filter((item) => item.mode === "AVAILABLE").length ===
            0
          }
        >
          <Button
            key="scan"
            type="primary"
            loading={loadingScanUpdate}
            disabled={
              scannedItems.filter((item) => item.mode === "AVAILABLE")
                .length === 0
            }
          >
            Update Scan
          </Button>
        </Popconfirm>
      );
    }
    if (
      ["VERIFICATION_INPROGRESS"].includes(
        materialRequest && materialRequest.orderStage
      )
    ) {
      buttons.push(
        <Popconfirm
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
          key="add-confirm"
          disabled={
            materialRequest &&
            materialRequest.requestLines.flatMap((item) => item.requestedItems)
              .length === 0
          }
        >
          <Button
            key="1"
            type="primary"
            htmlType="submit"
            loading={updating}
            disabled={
              materialRequest &&
              materialRequest.requestLines.flatMap(
                (item) => item.requestedItems
              ).length === 0
            }
          >
            Update
          </Button>
        </Popconfirm>
      );
      buttons.push(
        <Popconfirm
          title="Are you sure to complete verification?"
          onConfirm={onCompleteVerification}
          key="verify-success"
          disabled={enableVerify}
        >
          <Button
            key="btn-verify-success"
            htmlType="submit"
            loading={loadingCompleteVerification}
            type="primary"
            disabled={enableVerify}
          >
            Verified
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const handleFieldChange = (value, fieldName, key) => {
    const items = [...materialRequestLines];
    const newItems = items.map((item) => {
      const newItem = Object.assign({}, item);
      if (newItem.key === key) {
        newItem[fieldName] = value;

        if (fieldName === "reason") {
          if (value) {
            setRejectedLines([...rejectedLines, newItem]);
          } else {
            const rejectedLineItems = [...rejectedLines];
            const foundItem = rejectedLineItems.find(
              (item) => item.id === newItem.id
            );
            if (foundItem) {
              rejectedLineItems.splice(rejectedLineItems.indexOf(foundItem), 1);
              setRejectedLines(rejectedLineItems);
            }
          }
        }
        return newItem;
      }
      return newItem;
    });
    //setMaterialRequestLines(newItems);
  };

  const handleRejectLines = () => {
    if (rejectedLines.length > 0) {
      setLoadingReject(true);

      const payload = {
        requestLines: rejectedLines.map((line) => {
          return {
            id: line.id,
            reason: {
              id: line.reason,
            },
          };
        }),
      };
      //console.log("Update: ", payload);
      materialRequestService
        .rejectLines(id, payload)
        .then(() => {
          message.success("Successfully rejected requested lines!");
          setLoadingReject(false);
          window.location.reload();
        })
        .catch((error) => {
          setLoadingReject(false);
          message.error(`${error}`);
        });
    }
  };

  const getLineColumns = () => {
    let columns = [];
    if (materialRequest.subLocation?.shortCode.includes("GP")) {
      columns.push({
        title: "Supplier",
        key: "supplier",
        render: (text, record) => {
          return record.supplier && record.supplier.name;
        },
      });
    }
    columns.push({
      title: "Department",
      key: "deparment",
      width: "10%",
      render: (text, record) => {
        return record.department && record.department.code;
      },
    });
    columns.push({
      title: "MRN No.",
      dataIndex: "mrnNumber",
      key: "mrnNumber",
      width: "6%",
    });
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      width: "12%",
    });
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "15%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Status",
      key: "lineStage",
      dataIndex: "lineStage",
      render: (text) => {
        let color = "";
        if (text === "OPEN") {
          color = "#87d068";
        }
        if (text === "UPDATE") {
          color = "#2db7f5";
        }
        if (text === "VERIFICATION") {
          color = "#108ee9";
        }
        if (text === "PREPARE") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "ASSIGNED") {
          color = "#ffd666";
        }
        if (text === "PROCESSING") {
          color = "#ffd666";
        }
        if (text === "COMPLETE") {
          color = "#ffd666";
        }
        if (text === "CANCEL") {
          color = "#ffd666";
        }
        if (text === "REJECTED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Req. Box",
      dataIndex: "totalItems",
      key: "totalBox",
      width: "4%",
      align: "right",
    });
    columns.push({
      title: "Req. Net.W",
      key: "netWeight",
      width: "6%",
      align: "right",
      render: (text, record) => {
        return record.netWeight && Number(record.netWeight).toFixed(2);
      },
    });
    if (!materialRequest.subLocation?.shortCode.includes("GP")) {
      columns.push({
        title: "Req. Cone",
        dataIndex: "qty",
        key: "qty",
        width: "4%",
        align: "right",
      });
    }
    columns.push({
      title: "Veri. Box",
      key: "verifiedBox",
      //width: "4%",
      align: "right",
      render: (text, record) => {
        return record.requestedItems.length;
      },
    });
    columns.push({
      title: "Veri. Net.W",
      key: "verifyNetWeight",
      //width: "6%",
      align: "right",
      render: (text, record) => {
        return (
          record.requestedItems.length &&
          Number(
            record.requestedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next)
          ).toFixed(2)
        );
      },
    });
    if (!materialRequest.subLocation?.shortCode.includes("GP")) {
      columns.push({
        title: "Veri. Cone",
        key: "verifyCone",
        //width: "6%",
        align: "right",
        render: (text, record) => {
          return (
            record.requestedItems.length &&
            record.requestedItems
              .map((item) => item.quantity)
              .reduce((prev, next) => prev + next)
          );
        },
      });
    }
    columns.push({
      title: "",
      key: "operation",
      //width: "6%",
      render: (text, record) => {
        if (
          record.requestedItems.length === 0 &&
          record.lineStage !== "REJECTED"
        ) {
          return (
            <Form.Item
              name={`reason[${record.key}]`}
              key={record.key}
              style={{ marginBottom: "0" }}
              rules={[{ required: false, message: "Required." }]}
              hasFeedback
            >
              <Select
                placeholder="Please select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(e) => handleFieldChange(e, "reason", record.key)}
              >
                {reasons.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        } else {
          return <span></span>;
        }
        /* return (
          <Space size="small">
            {record.requestedItems.length === 0 &&
              record.lineStage !== "REJECTED" && (
                <Popconfirm
                  title="Are you sure to cancel this line?"
                  onConfirm={() => handleRequestLineDelete(record.id)}
                >
                  <DeleteOutlined title="Cancel" />
                </Popconfirm>
              )}
          </Space>
        ); */
      },
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      //width: "6%",
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      //width: "6%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Prod Date",
      dataIndex: "productionDate",
      key: "productionDate",
      //width: "5%",
    });
    /* columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "5%",
    }); */
    /* columns.push({
      title: "Supplier",
      key: "supplier",
      width: "5%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.stockType.supplier) {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.stockType.supplier.name}
            >
              {record.stockType.supplier.code}
            </Tooltip>
          );
        }
      },
    }); */
    columns.push({
      title: "Box #",
      dataIndex: "tempNumber",
      key: "tempNumber",
      width: "3%",
    });
    columns.push({
      title: "Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      //width: "5%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Pallet",
      dataIndex: "palletNo",
      key: "palletNo",
      align: "right",
      //width: "3%",
    });
    columns.push({
      title: "Cone",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      //width: "3%",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      //width: "5%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "6%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUEST_SCANNED") {
          color = "#2db7f5";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        if (text === "LOADED") {
          color = "#ffd666";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "",
      key: "action",
      width: "2%",
      render: (text, record) => {
        return (
          <span>
            {["PREPARE"].includes(materialRequest.orderStage) &&
              ["REQUEST_SCANNED"].includes(record.mode) && (
                <Popconfirm
                  title="Are you sure to cancel this scan?"
                  onConfirm={() => handleStockItemUnVerified(record.id)}
                >
                  <UndoOutlined title="UnVerify" />
                </Popconfirm>
              )}
          </span>
        );
      },
    });

    return columns;
  };

  const mrLineRowSelection = {
    selectedRowKeys: selectedMrLineKeys,
    onChange: (selectedMrLineKeys) => {
      //onTabChange(selectedMrLineKeys[0]);
      handleTabChange(selectedMrLineKeys[0]);
    },
  };

  const getTabContent = (requestedLines) => {
    let tabPanes = [];
    requestedLines.forEach((line, index) => {
      if (line.requestedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockTypeObj && line.stockTypeObj.code} / ${
              line.lotNumberObj && line.lotNumberObj.number
                ? line.lotNumberObj.number
                : ""
            } (${line.requestedItems && line.requestedItems.length})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.requestedItems}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 7}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{line.requestedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell align="right">
                        <Text strong>{totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const handleSearchAndAddStocks = (line) => {
    //const stockTypeId = form.getFieldValue("stockType[" + line.key + "]");
    //const lotNumberId = form.getFieldValue("lotNumber[" + line.key + "]");
    const stockTypeId = line.stockType.id;
    const lotNumberId = line.lotNumber && line.lotNumber.id;
    setSelectedStockTypeId(stockTypeId);
    setSelectedLotNumberId(lotNumberId);
    setSelectedLineKey(line.key);
    handleTabChange(line.key);
    setEnableVerify(false);
    // console.log("Enable: ", enableVerify);

    if (stockTypeId) {
      /* let requestLine = null;
            data.forEach((item) => {
              if (item.hasOwnProperty("lotNumber")) {
                if (
                  item.stockType === materialTypeId &&
                  item.lotNumber === lotNumberId
                ) {
                  requestLine = item;
                }
              } else if (item.stockType === materialTypeId) {
                requestLine = item;
              }
            }); */
      if (line.requestedItems) {
        setSelectedStockIds(
          line.requestedItems
            .filter((item) => ["AVAILABLE"].includes(item.mode))
            .map((item) => item.id)
        );
        //console.log("SSI:", selectedStockIds);
        //selectedStockIds = line.requestedItems.map((item) => item.id);
      }
      getStocks(stockTypeId, lotNumberId);
    } else {
      message.error("Please select valid material type!");
    }
  };

  const getStocks = (
    subLocationId,
    stockTypeId,
    lotId,
    width,
    colorCode,
    supplierId
  ) => {
    let searchParams = `?subLocationId=${subLocationId}&stockTypeId=${stockTypeId}&modes=AVAILABLE&pageNumber=1&pageSize=1000`;
    searchParams = lotId
      ? `${searchParams}&lotNumberId=${lotId}`
      : searchParams;
    searchParams = width ? `${searchParams}&width=${width}` : searchParams;
    searchParams = colorCode
      ? `${searchParams}&colorCode=${colorCode}`
      : searchParams;
    searchParams = supplierId
      ? `${searchParams}&supplierId=${supplierId}`
      : searchParams;

    setIsAvailableStocksLoading(true);
    materialDetailService
      .searchAvailable(searchParams)
      .then((data) => {
        setIsAvailableStocksLoading(false);
        if (data.content.length > 0) {
          setAvailableStocks(data.content);
        }
      })
      .catch((error) => {
        setIsAvailableStocksLoading(false);
        message.error(`${error}`);
      });
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected: ", selectedStockIds);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    console.log("MR: ", form.getFieldValue("requestLines"));
    setEnableVerify(true);
    setStockItemLoading(true);
    if (selectedStocks) {
      let requestLine;
      if (selectedLotNumberId) {
        requestLine = form
          .getFieldValue("requestLines")
          .find(
            (line) =>
              line.id === activeTabKey &&
              line.stockType.id === selectedStockTypeId &&
              line.lotNumber &&
              line.lotNumber.id === selectedLotNumberId
          );
      } else {
        requestLine = form
          .getFieldValue("requestLines")
          .find(
            (line) =>
              line.id === activeTabKey &&
              line.stockType.id === selectedStockTypeId
          );
      }
      const stockTypeObj = materialTypes.find(
        (item) => item.id === selectedStockTypeId
      );
      const lotNumberObj = lotNumbers.find(
        (item) => item.id === selectedLotNumberId
      );
      if (requestLine) {
        requestLine["stockTypeObj"] = stockTypeObj;
        requestLine["lotNumberObj"] = lotNumberObj;
        requestLine["stockType"] = stockTypeObj;
        requestLine["lotNumber"] = lotNumberObj;
        requestLine["requestedItems"] = [
          ...requestLine.requestedItems.filter((item) =>
            ["REQUESTED"].includes(item.mode)
          ),
          ...selectedStocks,
        ];
        /* requestLine["netWeight"] =
                    selectedStocks.length > 0 &&
                    selectedStocks
                        .map((item) => item.netWeight)
                        .reduce((prev, next) => prev + next); */
        requestLine["grossWeight"] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);

        setMaterialRequestLines(form.getFieldValue("requestLines"));
        materialRequest.requestLines = form.getFieldValue("requestLines");
        handleTabChange(requestLine.id);
      }
    }
    form.setFieldsValue({ stockType: null, lotNumber: null });
    setSelectedStockTypeId(null);
    setSelectedLotNumberId(null);
    setIsStockSelectionVisible(false);
    setStockItemLoading(false);
    setSelectedStockIds([]);
    //console.log("requestLines:", materialRequestLines);
  };

  const handleItemScan = (barcode) => {
    const activeLine = form
      .getFieldValue("requestLines")
      .find((line) => line.id === activeTabKey);
    if (activeLine && barcode) {
      if (["REJECTED", "CANCEL"].includes(activeLine.lineStage)) {
        form.setFieldsValue({ itemScan: "" });
        return message.error("Line is already rejected/cancelled!");
      }
      let scannedItem = scannedBarcodes.find(
        (item) => item.toLowerCase() === barcode.toLowerCase()
      );
      if (scannedItem) {
        form.setFieldsValue({ itemScan: "" });
        return message.error("Barcode is already scanned!");
      } else {
        scannedItem = scannedPackSerialNos.find(
          (item) => item.toLowerCase() === barcode.toLowerCase()
        );
        if (scannedItem) {
          form.setFieldsValue({ itemScan: "" });
          return message.error("Barcode is already scanned!");
        }
      }

      setLoadingStockItem(true);
      const matchingStock = availableStocks.find(
        (item) =>
          item.serialNo.toLowerCase() === barcode.toLowerCase() ||
          (item.packSerialNo &&
            item.packSerialNo.toLowerCase() === barcode.toLowerCase())
      );
      if (matchingStock) {
        matchingStock.requestedLine = activeLine;
        activeLine.requestedItems = [
          ...activeLine.requestedItems,
          matchingStock,
        ];

        if (
          matchingStock.supplier &&
          matchingStock.supplier.code === "GP_STR"
        ) {
          setScannedBarcodes([...scannedBarcodes, matchingStock.packSerialNo]);
        } else {
          setScannedBarcodes([...scannedBarcodes, matchingStock.serialNo]);
        }

        setScannedItems([...scannedItems, matchingStock]);

        activeLine["netWeight"] = activeLine.requestedItems
          .map((item) => item.netWeight)
          .reduce((prev, next) => prev + next);
        activeLine["grossWeight"] = activeLine.requestedItems
          .map((item) => item.grossWeight)
          .reduce((prev, next) => prev + next);

        setMaterialRequestLines(form.getFieldValue("requestLines"));
        materialRequest.requestLines = form.getFieldValue("requestLines");
      } else {
        message.error(
          "Sorry, No item found against the barcode & selected request line."
        );
      }
      setLoadingStockItem(false);
      form.setFieldsValue({ itemScan: "" });
      //console.log("ActiveLine: ", activeLine);
    } else {
      form.setFieldsValue({ itemScan: "" });
      message.error("Please enter valid barcode.");
    }
  };

  /* const handleLineDelete = () => {
    setMaterialRequestLines(form.getFieldValue("requestLines"));
  }; */

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedMrLineKeys([activeKey.toString()]);
    setAvailableStocks([]);
    const activeLine = form
      .getFieldValue("requestLines")
      .find((line) => line.id === activeKey);
    const subLocation = form.getFieldValue("subLocation");

    const stockTypeId = activeLine && activeLine.stockType.id;
    const lotNumberId =
      activeLine && activeLine.lotNumber && activeLine.lotNumber.id;
    const width = activeLine && activeLine.width;
    const colorCode = activeLine && activeLine.colorCode;
    const supplierId = activeLine && activeLine.supplier?.id;

    setActiveRequestLine(activeLine);
    console.log("ActiveLine: ", subLocation.id);
    subLocation &&
      stockTypeId &&
      getStocks(
        subLocation.id,
        stockTypeId,
        lotNumberId,
        width,
        colorCode,
        supplierId
      );
  };

  const getRequestStageColor = (orderStage) => {
    let color = "#87d068";
    if (orderStage === "OPEN") {
      color = "#2db7f5";
    } else if (["IN_PROGRESS", "ACCEPTED"].includes(orderStage)) {
      color = "#108ee9";
    } else if (orderStage === "VERIFICATION_INPROGRESS") {
      color = "#d4b106";
    }

    return color;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequest.requestNumber} ::{" "}
                <Tag color={getRequestStageColor(materialRequest.orderStage)}>
                  {materialRequest.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="prepare" id={id} status={materialRequest.orderStage} />
          <Spin tip="Loading..." spinning={loading || availableStocksLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={4}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[
                      { required: true, message: "Request date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Request date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="requestNumber" label="Request No">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="sbuId" label="SBU">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="zoneId" label="Zone">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="subLocationId"
                    label="Sub-Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub-Location",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      disabled
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={23}>
                  <Form.Item name="itemScan">
                    <Input.Search
                      loading={loadingStockItem}
                      placeholder="Enter valid barcode"
                      allowClear
                      enterButton={<ScanOutlined />}
                      onSearch={handleItemScan}
                      style={{ marginBottom: 20 }}
                      disabled={
                        !activeTabKey &&
                        activeRequestLine &&
                        !["REJECTED", "CANCEL"].includes(
                          activeRequestLine.lineStage
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Space>
                    <Badge
                      count={scannedBarcodes.length}
                      showZero
                      color="#faad14"
                      overflowCount={1000}
                    />
                  </Space>
                </Col>
              </Row>

              {materialRequest &&
                materialRequest.requestLines &&
                materialRequest.requestLines.length > 0 && (
                  <Table
                    bordered
                    columns={getLineColumns()}
                    dataSource={materialRequest.requestLines}
                    pagination={false}
                    rowClassName={(record) =>
                      ["VERIFIED", "COMPLETED"].includes(record.lineStage)
                        ? "ant-tag-green"
                        : ""
                    }
                    rowSelection={{
                      type: "radio",
                      ...mrLineRowSelection,
                    }}
                    size="small"
                    summary={(pageData) => {
                      let totalQuantity = 0;
                      let totalNetWeight = 0;
                      let totalCone = 0;
                      let totalBoxes = 0;
                      let totalVerifiedBox = 0;
                      let totalVerifiedNetWeight = 0;
                      let totalVerifyedCone = 0;

                      pageData.forEach(
                        ({
                          quantity,
                          netWeight,
                          qty,
                          totalItems,
                          requestedItems,
                        }) => {
                          totalQuantity += quantity;
                          totalNetWeight += netWeight;
                          totalBoxes += totalItems;
                          totalCone += qty;
                          totalVerifiedBox += requestedItems.length;
                          totalVerifiedNetWeight +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.netWeight)
                              .reduce((prev, next) => prev + next);
                          totalVerifyedCone +=
                            requestedItems.length > 0 &&
                            requestedItems
                              .map((item) => item.quantity)
                              .reduce((prev, next) => prev + next);
                        }
                      );

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={7}>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong style={{ paddingLeft: 12 }}>
                              {!isNaN(totalBoxes) && totalBoxes}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {Number(totalQuantity).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>{totalCone}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>{totalVerifiedBox}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {Number(totalVerifiedNetWeight).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                          {!materialRequest.subLocation?.shortCode.includes(
                            "GP"
                          ) && (
                            <Table.Summary.Cell align="right">
                              <Text strong>{totalVerifyedCone}</Text>
                            </Table.Summary.Cell>
                          )}
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                  />
                )}
            </Form>
            <Modal
              title={`Stocks (${availableStocks.length}) - Req. Box=${
                activeRequestLine && activeRequestLine.totalItems
                  ? activeRequestLine.totalItems
                  : "-"
              }, Req. Net.W=${
                activeRequestLine &&
                Number(activeRequestLine.netWeight).toFixed(2)
              }, Req. Cone=${
                activeRequestLine && activeRequestLine.qty
                  ? activeRequestLine.qty
                  : "-"
              } `}
              style={{ top: 48 }}
              visible={isStockSelectionVisible}
              okText={`Add Stocks (${selectedStockIds.length})`}
              onOk={handleAddStocks}
              //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
              onCancel={() => {
                setIsStockSelectionVisible(false);
                setSelectedStockIds([]);
              }}
              width={1300}
              destroyOnClose={true}
            >
              <StockSelection
                stocks={availableStocks}
                selectedIds={selectedStockIds}
                onSelectChange={handleStockSelection}
              />
            </Modal>
            {materialRequestLines.length > 0 &&
              materialRequestLines.flatMap((item) => item.requestedItems)
                .length > 0 && (
                <>
                  <Divider orientation="left">Verified Stock Items</Divider>
                  <Tabs
                    onChange={handleTabChange}
                    activeKey={activeTabKey}
                    type="card"
                  >
                    {getTabContent(materialRequestLines)}
                  </Tabs>
                </>
              )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmVerify;
