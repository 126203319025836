import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Popconfirm,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { materialTypeService, vehicleService } from '_services';
import Search from './Search';
import { Auth } from '_helpers';

const List = ({ match }) => {
  const { path } = match;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    getItems(pageNumber, pageSize);
  }, []);

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf('?') === -1 ? '?' : '&';
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    vehicleService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, total, searchParams) => {
    let totalResults = total ? total : totalRecords;
    setItems(results);
    setTotalRecords(totalResults);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getItems(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    materialTypeService
      .delete(id)
      .then((x) => {
        message.success('Successfully deleted!');
        getItems(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Link to={`${path}/add`} key='1'>
          <Button>Add</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      width: '10%',
      render: (text, record) => (
        <Link to={`/vehicles/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      render: (text, record) => text && text.type,
    },
    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (text, record) =>
        Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU']) && (
          <Popconfirm
            title='Are you sure to delete this item?'
            onConfirm={() => handleDelete(record.id)}
          >
            <CloseOutlined />
          </Popconfirm>
        ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/vehicles'>Vehicle Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
