import { Button, Col, Form, Row, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { binService } from "_services";

function AddSearch(props) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("sbuName", props.subLocation?.zone?.sbu?.name);
    form.setFieldValue("zoneName", props.subLocation?.zone?.name);
    form.setFieldValue("subLocationName", props.subLocation?.name);
    form.setFieldValue("storeLocation", props.storeLocation?.address);
    // getBinLocations();
  }, []);

  /* const getBinLocations = () => {
    binLocationService
      .getSuggestion('')
      .then((x) => {
        setBinLocations(x.content);
        form.setFieldsValue({ binLocationId: x.content[0].id });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = `?isParent=1&subLocationId=${props.subLocation?.id}&storeLocationId=${props.storeLocation?.id}`;
    if (values.code) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    binService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, null, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={5}>
        <Form.Item name="storeLocation" label="Store Address">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="sbuName" label="SBU">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="zoneName" label="Zone">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="subLocationName" label="Sub-Location">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="code" label="Bin Code">
            <Input placeholder="Bin Code" allowClear />
          </Form.Item>
        </Col>
        <Col span={19}></Col>
      </Row>
    </Form>
  );
}

export default AddSearch;
