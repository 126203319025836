import { FilePdfOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  PageHeader,
  Table,
  Tooltip,
  Typography,
  message,
  Row,
  Col,
  Statistic,
  Card,
  Button,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Page from '_components/Page';
import {
  reportService,
  materialTypeService,
  lotNumberService,
  supplierService,
  sbuService,
  zoneService,
  subLocationService,
} from '_services';
import StrRmSupplierWiseBalanceSearch from './SupplierWiseBalanceSearch';

const StrRmSupplierWiseBalance = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingSummaryPdf, setLoadingSummaryPdf] = useState(false);
  const [stockTypes, setStockTypes] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [materialSuppliers, setMaterialSuppliers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  useEffect(() => {
    getStockTypes();
    getLotNumbers();
    getMaterialSuppliers();
    getBusinessUnits();
    getZones();
    getSubLocations();
    // getItems();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getMaterialSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setMaterialSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search('')
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getItems = () => {
    setLoading(true);
    let searchParams = '';
    searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
    setSearchQuery(searchParams);
    reportService
      .getSupplierWiseBalance(searchParams)
      .then((data) => {
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
          });
          //console.log("BinWise:", data.content);
          setItems(data.content);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, searchParams) => {
    setItems(results);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  const handleExportPdf = () => {
    setLoadingPdf(true);
    reportService
      .exportSupplierWisePdf(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Supplier-Wise-Report_${moment(new Date()).format('YYYY-MM-DD')}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingPdf(false);
      })
      .catch((error) => {
        setLoadingPdf(false);
        message.error(error);
      });
  };

  const handleExportSummaryPdf = () => {
    setLoadingSummaryPdf(true);
    reportService
      .exportSupplierWiseSummaryPdf(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Supplier-Wise-Summary-Report_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingSummaryPdf(false);
      })
      .catch((error) => {
        setLoadingSummaryPdf(false);
        message.error(error);
      });
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: 'Material Supplier',
      key: 'supplier',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder='topLeft' title={record.supplier}>
            {record.supplier}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: 'Material Type',
      dataIndex: 'stockType',
      key: 'stockType',
      width: '11%',
    });
    columns.push({
      title: 'Description',
      key: 'stockTypeName',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder='topLeft' title={record.stockTypeName}>
            {record.stockTypeName}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: 'Lot No',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      width: '19%',
    });
    columns.push({
      title: 'Boxes',
      dataIndex: 'availableStockQuantity',
      key: 'availableStockQuantity',
      align: 'right',
      width: '10%',
    });
    columns.push({
      title: 'Cones',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      width: '10%',
    });
    columns.push({
      title: 'Net Weight(kg)',
      dataIndex: 'netWeight',
      key: 'netWeight',
      align: 'right',
      width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: 'Gross Weight(kg)',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      align: 'right',
      width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/reports'>Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Supplier Wise Balance</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <StrRmSupplierWiseBalanceSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              stockTypes={stockTypes}
              lotNumberList={lotNumberList}
              materialSupplierList={materialSuppliers}
              subLocations={subLocations}
              zones={zones}
              businessUnits={businessUnits}
            />
            {items && items.length > 0 && (
              <div
                style={{
                  background: '#2d3147',
                  padding: '15px',
                  marginBottom: 15,
                }}
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title='Total Stocks(Box)'
                        value={items
                          .map((item) => item.availableStockQuantity)
                          .reduce((prev, next) => prev + next)}
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title='Total Net Weight (Kg)'
                        value={Number(
                          items
                            .map((item) => item.netWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title='Total Gross Weight (Kg)'
                        value={Number(
                          items
                            .map((item) => item.grossWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Card>
                      <Statistic
                        title='Total Cone'
                        value={items
                          .map((item) => item.quantity)
                          .reduce((prev, next) => prev + next)}
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Button
                      type='primary'
                      onClick={handleExportPdf}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FilePdfOutlined />}
                      loading={loadingPdf}
                    >
                      Export to Pdf
                    </Button>
                    <br />
                    <Button
                      type='primary'
                      onClick={handleExportSummaryPdf}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FilePdfOutlined />}
                      loading={loadingSummaryPdf}
                    >
                      Summary Pdf
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <Table
              bordered
              loading={loading}
              size='small'
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalBox = 0;

                pageData.forEach(
                  ({
                    quantity,
                    netWeight,
                    grossWeight,
                    availableStockQuantity,
                  }) => {
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalBox += availableStockQuantity;
                  }
                );

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={getColumns().length - 4}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>{totalBox}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>{totalQuantity}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>
                        {totalNetWeight && Number(totalNetWeight).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>
                        {totalGrossWeight &&
                          Number(totalGrossWeight).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default StrRmSupplierWiseBalance;
