import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { Auth } from '_helpers';
import { sbuService } from '_services';

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    sbuService
      .getById(id)
      .then((x) => {
        setLoading(false);
        setItem(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        sbuService
          .update(id, values)
          .then(() => {
            message.success('Successfully updated!');
            setLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push('/sbu');
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Popconfirm
          title='Are you sure to update the changes?'
          onConfirm={handleFormSumbit}
        >
          <Button key='1' type='primary' htmlType='submit' loading={loading}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key='2' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/sbu'>SBU Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>SBU: {item.code}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Form
            form={form}
            name='form-create'
            layout='vertical'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name='code' label='Code'>
                  <Input placeholder='Code' disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='name' label='Name'>
                  <Input
                    placeholder='Name'
                    disabled={!Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='description' label='Description'>
                  <Input
                    placeholder='Description'
                    disabled={!Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='shortCode' label='Short Code'>
                  <Input
                    placeholder='Short Code'
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
