import {
  Breadcrumb,
  PageHeader,
  Table,
  message,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { stockCheckService } from "_services";
import ItemComparisonSearch from "./StockItemComparisonSearch";

const StrStockItemComparisonList = ({ match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  useEffect(() => {
    getItems(1, 50);
  }, []);

  const getItems = () => {
    setLoading(true);
    stockCheckService
      .searchStockCheckUpload("")
      .then((data) => {
        setItems(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const handleSearchResults = (results, searchParams) => {
    setItems(results);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Link to={`/reports/item-comparison-upload`} key="btn-upload">
        <Button>Upload</Button>
      </Link>
    );

    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Bin Code",
      dataIndex: "stockBinCode",
      key: "stockBinCode",
      render: (text, record) => {
        return (
          <Link to={`/reports/item-comparisons/${record.id}`}>{text}</Link>
        );
      },
    });
    columns.push({
      title: "Scanned Box",
      dataIndex: "noBoxScan",
      key: "noBoxScan",
    });
    columns.push({
      title: "Scanned By",
      dataIndex: "createdUserId",
      key: "createdUserId",
    });
    columns.push({
      title: "Uploaded File Name",
      dataIndex: "originalFileName",
      key: "originalFileName",
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Stock Item Comparison Reports</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <>
            <ItemComparisonSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
            />

            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default StrStockItemComparisonList;
