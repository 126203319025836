import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Spin,
  Popconfirm,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import { binService, stockReceiptService } from "_services";
import TableForm from "./components/TableForm";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [acceptedTotalQuantity, setAcceptedTotalQuantity] = useState(0);
  const [stockBins, setStockBins] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    stockReceiptService
      .getByOrderId(id)
      .then((x) => {
        let stockPreReceipt = x.content;
        stockPreReceipt.orderDate = moment(stockPreReceipt.orderDate);
        stockPreReceipt.deliveryNote = stockPreReceipt.preReceiptNumber;
        //console.log("PO: ", stockPreReceipt);

        binService
          .getSuggestion(
            `?isParent=1&storeLocationId=${stockPreReceipt.storeAddress.id}`
          )
          .then((data) => {
            //console.log("StockBins:", data.content);
            setStockBins(data.content);
          })
          .catch((error) => {});

        setOrder(stockPreReceipt);
        onFill(stockPreReceipt);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        let payload = {};
        payload.goodReceivedNote = {
          deliveryNote: values.deliveryNote,
          sealNumber: values.sealNumber,
          vehicleNumber: values.vehicleNumber,
          tellerNumber: values.tellerNumber,
          containerNumber: values.containerNumber,
          driverName: values.driverName,
          contactNumber: values.contactNumber,
          driverNic: values.driverNic,
        };
        payload.stockPreReceipt = { id: order.id };
        let lines = [];
        values.stockPreReceiptLines.receiptLines.forEach((item) => {
          let line = {
            accepted: item.accepted,
            rejected: 0,
            acceptedAtQA: item.accepted,
            rejectedAtQA: 0,
            orderLine: { id: item.orderLineId },
            destinationBin: { id: item.binId },
          };
          lines.push(line);
        });
        payload.lines = lines;
        //console.log("StockReceipts: ", payload);
        stockReceiptService
          .create(payload)
          .then(() => {
            setLoading(false);
            message.success("Stock receipted successfully!");
            history.push(`/packing-lists/${id}/summary`);
          })
          .catch((error) => {
            setLoading(false);
            setError(error);
          });
      })
      .catch((errorInfo) => {
        //message.error(`${errorInfo}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleAddQuantity = (quantity) => {
    let newQuantity = acceptedTotalQuantity;
    if (quantity === 0) {
      newQuantity = newQuantity - 1;
    } else {
      newQuantity = newQuantity + quantity;
    }
    setAcceptedTotalQuantity(newQuantity);
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Packing List Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/packing-lists/${order.id}/summary`}>
                {order.preReceiptNumber}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/stock-receipt/${id}`}>Stock Receipt</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            title="Are you sure to confirm this received?"
            onConfirm={handleFormSumbit}
            key="update-confirm"
          >
            <Button
              key="1"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={
                acceptedTotalQuantity === null || acceptedTotalQuantity == 0
              }
            >
              Receive Stock
            </Button>
          </Popconfirm>,
        ]}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "name"]}
                    label="Customer"
                    fieldKey
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["store", "name"]} label="Store" fieldKey>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="orderDate" label="Order Date">
                    <DatePicker
                      placeholder="Order date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="preReceiptNumber" label="Packing List No">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["customerAddress", "address"]}
                    label="Deliver From"
                    fieldKey
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["storeAddress", "address"]}
                    label="Delivery To"
                    fieldKey
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="deliveryNote"
                    label="Receive Note"
                    rules={[
                      { required: true, message: "Receive Note is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sealNumber" label="Seal No:">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="vehicleNumber" label="Vehicle No:">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="tellerNumber" label="C. Trailer No:">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="containerNumber" label="Container No:">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverName" label="Driver Name">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact No:">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
              {form.getFieldValue("stockPreReceiptLines") && (
                <Form.Item name="stockPreReceiptLines">
                  <TableForm
                    onAddQuantity={handleAddQuantity}
                    stockBins={stockBins.length > 0 && stockBins}
                  />
                </Form.Item>
              )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
