import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { stockClassificationService } from "_services";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [form] = Form.useForm();

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        stockClassificationService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/stock-classifications");
  };

  useEffect(() => {
    setLoading(true);
    stockClassificationService
      .getById(id)
      .then((x) => {
        setLoading(false);
        setItem(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-classifications">
                Stock Classification Search
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>Role: {item.name}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Popconfirm
            title="Are you sure to update the changes?"
            onConfirm={handleFormSumbit}
          >
            <Button key="1" type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Popconfirm>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
