import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Popconfirm,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { binService } from "_services";
import TabMenu from "../TabMenu";
import { Auth } from "_helpers";

function List({ match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [bin, setBin] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    binService
      .getParentById(id)
      .then((x) => {
        setItems(x.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    binService
      .getById(id)
      .then((data) => {
        setBin(data.content);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const handleDelete = (subBinId) => {
    binService
      .removeParent(id, subBinId)
      .then((data) => {
        message.success("Successfully deleted!");
        //setItems(data.content);
        document.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getAdditionalButtons = () => {
    let buttons = [];
    /* if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Link to={`${path}/add`.replace(":id", id)} key="1">
          <Button>Add</Button>
        </Link>
      );
    } */
    return buttons;
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Available Stocks",
      dataIndex: "availableStockQuantity",
      key: "availableStockQuantity",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDelete(record.id)}
            disabled={!Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])}
          >
            <CloseOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/bins">
              <span>Bin Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bins/${bin.id}/summary`}>
              <span>Bin: {bin.code}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Sub Bins</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="sub-bins" id={id} />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={items}
            pagination={false}
          />
        </Page>
      </PageHeader>
    </div>
  );
}

export default List;
