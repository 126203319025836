import { Breadcrumb, Button, PageHeader, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { stockTransferService } from "_services";
import StrRmSearch from "./Search";

const StrList = ({ match, history }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState("");

  useEffect(() => {
    getStockTransfers(pageNumber, pageSize);
  }, []);

  const getStockTransfers = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    stockTransferService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getStockTransfers(pageNumber, pageSize);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Link to={`${path}/do-transfer`} key="btn-add">
        <Button>Start Transfer</Button>
      </Link>
    );
    buttons.push(
      <Link to={`${path}/bulk-transfer`} key="btn-bulk-transfer">
        <Button>Bulk Transfer</Button>
      </Link>
    );
    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Tansfer No",
      dataIndex: "transferNumber",
      key: "transferNumber",
      render: (text, record) => {
        return <Link to={`/stock-transfer/${record.id}/summary`}>{text}</Link>;
      },
    });
    columns.push({
      title: "SBU",
      dataIndex: "destinationSubLocation",
      key: "destinationSbu",
      render: (text, record) => record?.destinationSubLocation?.zone?.sbu?.name,
    });
    columns.push({
      title: "Zone",
      dataIndex: "destinationSubLocation",
      key: "destinationZone",
      render: (text, record) => record?.destinationSubLocation?.zone?.name,
    });
    columns.push({
      title: "Sub-Location",
      dataIndex: "destinationSubLocation",
      key: "destinationZone",
      render: (text, record) => record?.destinationSubLocation?.name,
    });
    columns.push({
      title: "Bin",
      dataIndex: "destinationBin",
      key: "destinationBin",
      render: (text, record) => record?.destinationBin?.code,
    });
    columns.push({
      title: "Transfer Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (text, record) => {
        return moment(text).format("YYYY-MM-DD");
      },
    });
    columns.push({
      title: "No. Boxes",
      key: "total",
      dataIndex: "total",
    });
    columns.push({
      title: "No. Cones",
      key: "quantity",
      dataIndex: "quantity",
    });
    columns.push({
      title: "Net.W",
      key: "netWeight",
      render: (text, record) => Number(record.netWeight).toFixed(2),
    });
    columns.push({
      title: "Gross.W",
      key: "grossWeight",
      dataIndex: "grossWeight",
      render: (text, record) => Number(record.grossWeight).toFixed(2),
    });
    columns.push({
      title: "Transferred By",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      width: "8%",
      /* render: (text, record) => {
        return record.issueRequest && record.issueRequest.modifiedBy;
      }, */
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-transfer">Stock Transfer Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <StrRmSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
            <Table
              bordered
              rowKey="id"
              size="small"
              loading={loading}
              columns={getColumns()}
              dataSource={items}
              pagination={{
                current: pageNumber,
                pageSize: pageSize,
                total: totalRecords,
                showTotal: (total) => `Total ${total} items`,
                showSizeChanger: false,
                onChange: handlePaginateChange,
              }}
            />
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrList;
