import React from "react";
import { Route, Switch } from "react-router-dom";

import StrSummary from "./str/Summary";
import StrEdit from "./str/Edit";
import StrVerify from "./str/Verify";
import StrHistory from "./str/History";
import StrDocument from "./str/Document";

import { Auth } from "_helpers";

function MaterialRequestLines({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route path={`${path}/:id/summary`} component={StrSummary} />
        <Route path={`${path}/:id/edit`} component={StrEdit} />
        <Route path={`${path}/:id/prepare`} component={StrVerify} />
        <Route path={`${path}/:id/history`} component={StrHistory} />
        <Route path={`${path}/:id/documents`} component={StrDocument} />
      </Switch>
    );
  }
}

export { MaterialRequestLines };
