import React from "react";
import { Radio, Steps } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";

function TabMenu(props) {
  let history = useHistory();

  const handleMenuChange = (e) => {
    history.push(`/stock-issue/${props.id}/${e.target.value}`);
  };

  return (
    <div>
      <Steps
        style={{ marginBottom: 20 }}
        size="small"
        items={[
          {
            title: "Open",
            status: props.status === "OPEN" ? "process" : "finish",
            icon: props.status === "OPEN" ? <LoadingOutlined /> : false,
          },
          {
            title: "Loading",
            status:
              props.status === "LOADING"
                ? "process"
                : props.status === "OPEN"
                ? "wait"
                : "finish",
            icon: props.status === "LOADING" ? <LoadingOutlined /> : false,
          },
          {
            title: "Ready",
            status:
              props.status === "READY"
                ? "process"
                : ["OPEN", "LOADING"].includes(props.status)
                ? "wait"
                : "finish",
            icon: props.status === "READY" ? <LoadingOutlined /> : false,
          },
          {
            title: "Issued",
            status:
              props.status === "ISSUED"
                ? "process"
                : ["OPEN", "LOADING", "READY"].includes(props.status)
                ? "wait"
                : "finish",
            icon: props.status === "ISSUED" ? <LoadingOutlined /> : false,
          },
          {
            title: "Confirmation",
            status:
              props.status === "CONFIRMATION"
                ? "process"
                : ["OPEN", "LOADING", "READY", "ISSUED"].includes(props.status)
                ? "wait"
                : "finish",
            icon: props.status === "CONFIRMATION" ? <LoadingOutlined /> : false,
          },
          {
            title: "Complete",
            status: props.status === "COMPLETE" ? "finish" : "wait",
            icon: false,
          },
        ]}
      />
      <Radio.Group
        value={props.menu}
        onChange={handleMenuChange}
        style={{ marginBottom: 10 }}
      >
        <Radio.Button value="summary" style={{ marginRight: 5 }}>
          Summary
        </Radio.Button>
        {Auth.hasRole(Auth.getProfile(), ["CAU"]) && (
          <>
            <Radio.Button
              value="loading"
              style={{ marginRight: 5 }}
              disabled={["READY"].includes(props.status)}
            >
              Loading
            </Radio.Button>
            <Radio.Button value="ready" style={{ marginRight: 5 }}>
              Ready
            </Radio.Button>
            <Radio.Button value="history" style={{ marginRight: 5 }}>
              History
            </Radio.Button>
          </>
        )}
        <Radio.Button value="documents" style={{ marginRight: 5 }}>
          Documents
        </Radio.Button>
      </Radio.Group>
    </div>
  );
}

export default TabMenu;
