import React from "react";
import { Route, Switch } from "react-router-dom";
import { Auth } from "_helpers";
import Home from "./Home";
import StrGrHome from "./str_gr/Home";
import PrymHome from "./prym/Home";
import StrRmHomeAdmin from "./str_rm/HomeAdmin";
import StrRmHome from "./str_rm/Home";
import SiluetaHome from "./silueta/Home";

function Homes({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "STR_GR") {
    return (
      <Switch>
        <Route exact path={path} component={StrGrHome} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    /* if (Auth.hasRole(Auth.getProfile(), ['TVA'])) {
      return (
        <Switch>
          <Route exact path={path} component={StrRmHomeAdmin} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route exact path={path} component={StrRmHome} />
        </Switch>
      );
    } */
    return (
      <Switch>
        <Route exact path={path} component={StrRmHome} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymHome} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaHome} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={Home} />
      </Switch>
    );
  }
}

export { Homes };
