import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  PageHeader,
  Popconfirm,
  Table,
  Typography,
  message,
} from 'antd';
import Page from '_components/Page';
import { binLocationService } from '_services';
import Search from './Search';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Auth } from '_helpers';

const List = ({ match }) => {
  const { path } = match;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const { Title } = Typography;

  useEffect(() => {
    getBinLocations();
  }, []);

  const getBinLocations = () => {
    setLoading(true);
    binLocationService
      .search(`${searchParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setSearchParams(searchParams);
    setLoading(false);
  };

  const handleDelete = (id) => {
    binLocationService
      .delete(id)
      .then((x) => {
        message.success('Successfully deleted!');
        getBinLocations();
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Link to={`${path}/add`} key='1'>
          <Button>Add</Button>
        </Link>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: 'Location Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link to={`/bin-locations/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: 'Total Stocks',
      dataIndex: 'totalStocks',
      key: 'totalStocks',
      align: 'right',
    },
    {
      title: 'Total Net Weight(Kg)',
      dataIndex: 'totalNetWeight',
      key: 'totalNetWeight',
      align: 'right',
    },
    {
      title: 'Total Gross Weight(Kg)',
      dataIndex: 'totalGrossWeight',
      key: 'totalGrossWeight',
      align: 'right',
    },
    {
      title: 'Total Qty',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',
      align: 'right',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Button
              icon={<EditOutlined />}
              title='Edit'
              type='link'
              style={{ marginRight: 10 }}
              href={`/bin-locations/${record.id}/summary`}
              key='btn-edit'
            ></Button>
            {Auth.hasRole(Auth.getProfile(), ['SAU']) && (
              <Popconfirm
                title='Are you sure to delete this item?'
                onConfirm={() => handleDelete(record.id)}
                disabled={Auth.hasRole(Auth.getProfile(), ['SAU'])}
                key='pop-close'
              >
                <CloseOutlined />
              </Popconfirm>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/bin-locations'>Bin Location Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={items}
            pagination={false}
            summary={(pageData) => {
              let totalStocksSum = 0;
              let totalNetWeightSum = 0;
              let totalGrossWeightSum = 0;
              let totalQuantitySum = 0;

              pageData.forEach(
                ({
                  totalStocks,
                  totalNetWeight,
                  totalGrossWeight,
                  totalQuantity,
                }) => {
                  totalStocksSum += totalStocks;
                  totalNetWeightSum += totalNetWeight;
                  totalGrossWeightSum += totalGrossWeight;
                  totalQuantitySum += totalQuantity;
                }
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>{totalStocksSum}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>
                        {Number(totalNetWeightSum).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>
                        {Number(totalGrossWeightSum).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align='right'>
                      <Typography.Text strong>
                        {Number(totalQuantitySum).toFixed(2)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
