import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  stockIssueService,
} from "_services";
import { ControlOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";
import Checkbox from "antd/lib/checkbox/Checkbox";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [stockIssue, setStockIssue] = useState({});
  const [stockIssueLines, setStockIssueLines] = useState([]);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [issuedStocks, setIssuedStocks] = useState([]);
  const [receivedStocks, setReceivedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  useEffect(() => {
    setLoading(true);
    stockIssueService
      .getById(id)
      .then((x) => {
        let stockIssue = x.content;
        //console.log("Issue:", stockIssue.deliveredDate);
        stockIssue.issuedDate = moment(stockIssue.issuedDate);
        stockIssue.deliveredDate = stockIssue.deliveredDate
          ? moment(stockIssue.deliveredDate)
          : moment();
        stockIssue.issuedLines.forEach((item, i) => {
          item.key = i;
          item.deliveredQuantity = 0;

          if (["SILUETA"].includes(loggedInCustomerCode)) {
            item.requestedGrossMeasure = item.requestLine.quantity;
            item.requestedQuantity = item.requestLine.quantity;
          } else {
            item.requestedQuantity =
              item.requestLine.issueRequest.requestedItems.length;
            item.requestedGrossMeasure =
              item.requestLine.issueRequest.requestedItems.length > 0 &&
              item.requestLine.issueRequest.requestedItems
                .map((item) => item.grossWeight)
                .reduce((prev, next) => prev + next);
          }

          /* for (const stock of item.issuedItems) {
            if (item.mode === "ISSUED") {
              item.quantity = item.quantity
                ? item.quantity + 1
                : 1;
              item.netWeight = item.netWeight
                ? item.netWeight + stock.netWeight
                : stock.netWeight;
              item.grossWeight = item.grossWeight
                ? item.grossWeight + stock.grossWeight
                : stock.grossWeight;
            }
          } */
          item.quantity = item.issuedItems.length;
          item.netWeight =
            item.issuedItems.length > 0 &&
            item.issuedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next);
          item.grossWeight =
            item.issuedItems.length > 0 &&
            item.issuedItems
              .map((item) => item.grossWeight)
              .reduce((prev, next) => prev + next);
          setIssuedStocks([...issuedStocks, ...item.issuedItems]);
        });

        //console.log("Issue Lines", stockIssue);
        setStockIssue(stockIssue);
        setStockIssueLines(stockIssue.issuedLines);
        setSelectedCustomer(stockIssue.customer);
        onFill(stockIssue);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });

    companyService.getSuggestion("?companyType=STORE").then((x) => {
      setStores(x.content);
    });
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
  }, []);

  const onSearchAndAddStockItem = (value) => {
    if (value && value.split("|").length === 4) {
      setLoadingStockItem(true);
      materialDetailService
        .search(`?barcode=${encodeURIComponent(value)}`)
        .then((data) => {
          if (data.content && data.content[0]) {
            let stock = data.content[0];
            //console.log("ST: ", stock);

            if (!selectedRowKeys.includes(stock.id)) {
              stockIssueLines.forEach((line) => {
                let issuedItemIds = line.issuedItems.map((item) => item.id);
                console.log(
                  "Lines: ",
                  issuedItemIds.includes(stock.id.toString())
                );
                if (
                  line.requestLine.id === stock.requestedLine.id &&
                  line.deliveredQuantity < line.quantity &&
                  !receivedStocks.includes(stock)
                ) {
                  line.deliveredQuantity += 1;
                  setReceivedStocks([...receivedStocks, stock]);

                  line.issuedItems.forEach((item) => {
                    if (item.packSerialNo === stock.packSerialNo) {
                      item.id = stock.id;
                      item.added = true;
                      item.status = "DELIVERED";
                      item.mode = "UNLOADED";
                      setSelectedRowKeys([...selectedRowKeys, stock.id]);
                    }
                  });
                }

                if (line.quantity === line.deliveredQuantity) {
                  line.addedAll = true;
                }
              });
            } else {
              message.error("Sorry, No item found against the barcode.");
            }

            form.setFieldsValue({ stockItem: null });
          }
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  /* const expandedRowRender = (value) => {
    const columns = [
      {
        title: "Pack Serial",
        dataIndex: "packSerialNo",
        key: "packSerialNo",
        width: "43%",
      },
      {
        title: "Stock Bin",
        key: "stockBin",
        width: "15%",
        render: (text, record) => {
          return record.stockBin && record.stockBin.code;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "10%",
        render: (status) => {
          return (
            <Tag color={`${status === "ISSUED" ? "green" : "#3f6600"}`}>
              {status}
            </Tag>
          );
        },
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: "10%",
      },
      {
        title: "Net Weight",
        dataIndex: "netWeight",
        key: "netWeight",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      },
      {
        title: "Gross Weight",
        dataIndex: "grossWeight",
        key: "grossWeight",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      },
      ,
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={value.issuedItems}
        rowClassName={(record) => (record.added ? "ant-tag-green" : "")}
        pagination={false}
      />
    );
  }; */

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingUpdate(true);
        delete values.issueNumber;
        delete values.issuedDate;
        delete values.store;
        delete values.vehicleNumber;
        delete values.stockItem;

        values.issuedLines = stockIssueLines.map((line) => ({
          issuedItems: line.issuedItems
            .filter((item) => item.added)
            .map((item) => ({
              id: item.id,
              mode: "DELIVERED",
            })),
        }));
        values.deliveredDate = moment(values.deliveredDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log("Update: ", values);
        stockIssueService
          .deliveryUpdate(id, values)
          .then(() => {
            setLoadingUpdate(false);
            message.success("Delivery success!");
            history.push("..");
          })
          .catch((error) => {
            setLoadingUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((errorInfo) => {});
  };

  const onSelectChange = (rowKeys) => {
    setSelectedRowKeyMap(selectedRowKeyMap.set(activeTabKey, rowKeys));
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    setSelectedRowKeys(allSelectedKeys);
    const totalIssuedStocks = stockIssueLines.flatMap(
      (item) => item.issuedItems
    );
    let unLoadedStocks = [];
    for (let item of totalIssuedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.added = true;
        item.mode = "UNLOADED";
        unLoadedStocks.push(item);
      } else {
        item.added = false;
        item.mode = "ISSUED";
      }
    }
    setReceivedStocks(unLoadedStocks);
    //console.log("IssuedLines:", stockIssueLines);
    //console.log("Unloaded:", unLoadedStocks);

    stockIssueLines.forEach((line) => {
      line.receivedQuantity = 0;
      line.receivedNetWeight = 0;
      line.receivedGrossWeight = 0;
      line.addedAll = false;
      unLoadedStocks.forEach((stock) => {
        if (["SILUETA"].includes(loggedInCustomerCode)) {
          if (
            line.requestLine.stockType.id === stock.stockType.id &&
            line.requestLine.lotNumber.id === stock.lotNumber.id
          ) {
            line.receivedQuantity += 1;
            line.receivedNetWeight += stock.netWeight;
            line.receivedGrossWeight += stock.grossWeight;
          }
        } else {
          if (line.requestLine.id === stock.requestedLine.id) {
            line.receivedQuantity += 1;
            line.receivedNetWeight += stock.netWeight;
            line.receivedGrossWeight += stock.grossWeight;
          }
        }
      });
      console.log(
        "Line Qty:",
        line.quantity,
        ", Got Qty:",
        line.receivedQuantity
      );
      if (line.quantity === line.receivedQuantity) {
        line.addedAll = true;
      }
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.mode === "DELIVERED",
    }),
  };

  const issueLineSelection = {
    /* selectedRowKeys: selectedIssueLineKeys,
    onChange: onIssueLineSelectChange, */
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setActiveTabKey(parseInt(selectedRowKeys));
    },
  };

  const onIssueLineSelectChange = (rowKeys) => {
    console.log("Selected: ", rowKeys);
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(parseInt(activeKey));
  };

  const handlePrintPdf = () => {
    setLoadingPdf(true);
    stockIssueService.printPdf(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Delivery-${stockIssue.issueNumber}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingPdf(false);
    });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleAllUnloadChange = (e) => {
    if (e.target.checked) {
      stockIssueLines.forEach((line, index) => {
        setSelectedRowKeyMap(
          selectedRowKeyMap.set(
            index,
            line.issuedItems.map((item) => item.id)
          )
        );
      });

      const totalIssuedStocks = stockIssueLines.flatMap(
        (item) => item.issuedItems
      );
      let unLoadedStocks = [];
      for (let item of totalIssuedStocks) {
        item.added = true;
        item.mode = "UNLOADED";
        unLoadedStocks.push(item);
      }
      setReceivedStocks(unLoadedStocks);

      stockIssueLines.forEach((line) => {
        line.receivedQuantity = 0;
        line.receivedNetWeight = 0;
        line.receivedGrossWeight = 0;
        line.addedAll = false;
        unLoadedStocks.forEach((stock) => {
          if (["SILUETA"].includes(loggedInCustomerCode)) {
            if (
              line.requestLine.stockType.id === stock.stockType.id &&
              line.requestLine.lotNumber.id === stock.lotNumber.id
            ) {
              line.receivedQuantity += 1;
              line.receivedNetWeight += stock.netWeight;
              line.receivedGrossWeight += stock.grossWeight;
            }
          } else {
            if (line.requestLine.id === stock.requestedLine.id) {
              line.receivedQuantity += 1;
              line.receivedNetWeight += stock.netWeight;
              line.receivedGrossWeight += stock.grossWeight;
            }
          }
        });

        if (line.quantity === line.receivedQuantity) {
          line.addedAll = true;
        }
      });
    } else {
      setSelectedRowKeyMap(new Map());
      const totalIssuedStocks = stockIssueLines.flatMap(
        (item) => item.issuedItems
      );
      for (let item of totalIssuedStocks) {
        item.added = false;
        item.mode = "ISSUED";
      }
      setReceivedStocks([]);
      stockIssueLines.forEach((line) => {
        line.receivedQuantity = 0;
        line.receivedNetWeight = 0;
        line.receivedGrossWeight = 0;
        line.addedAll = false;
      });
    }
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="pdf"
        icon={<FilePdfOutlined />}
        onClick={handlePrintPdf}
        loading={loadingPdf}
      ></Button>
    );
    buttons.push(
      <Popconfirm
        title="Are you sure to confirm this delivery?"
        onConfirm={handleFormSumbit}
        key="update-confirm"
      >
        {stockIssue.orderStatus !== "OPEN" && (
          <Button
            key="update"
            htmlType="submit"
            type="primary"
            loading={loadingUpdate}
            disabled={
              stockIssueLines.flatMap((item) => item.issuedItems).length !=
              receivedStocks.length
            }
          >
            Delivery Confirm
          </Button>
        )}
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getLineColumns = () => {
    const lineColumns = [];
    lineColumns.push({
      title: "Request No.",
      key: "requestNumber",
      width: "10%",
      render: (text, record) => {
        return record.requestLine.issueRequest.requestNumber;
      },
    });
    lineColumns.push({
      title: "Material Type",
      key: "stockTypeCode",
      width: "15%",
      render: (text, record) => {
        return record.requestLine.stockType.code;
      },
    });
    lineColumns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={record.requestLine.stockType.name}
          >
            {record.requestLine.stockType.name}
          </Tooltip>
        );
      },
    });
    lineColumns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record, index) => {
        return (
          record.requestLine.lotNumber && record.requestLine.lotNumber.number
        );
      },
    });
    if (!["SILUETA"].includes(loggedInCustomerCode)) {
      lineColumns.push({
        title: "Requested Units",
        dataIndex: "requestedQuantity",
        key: "requestedQuantity",
        width: "10%",
        align: "right",
      });
    }
    lineColumns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Issued Rolls"
          : "Issued Units"
      }`,
      key: "quantity",
      width: "10%",
      align: "right",
      render: (text, record) => {
        return record.quantity;
      },
    });
    if (stockIssue.orderStage === "OPEN") {
      lineColumns.push({
        title: `${
          ["SILUETA"].includes(loggedInCustomerCode)
            ? "Received Rolls"
            : "Received Units"
        }`,
        key: "deliveredQuantity",
        width: "10%",
        align: "right",
        render: (text, record) => {
          return record.receivedQuantity && record.receivedQuantity;
        },
      });
    }
    /* lineColumns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    }); */
    lineColumns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Requested Quantity"
          : "Requested Gross M."
      }`,
      dataIndex: "requestedGrossMeasure",
      key: "requestedGrossMeasure",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    lineColumns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Issued Quantity"
          : "Issued Gross M."
      }`,
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    if (stockIssue.orderStage === "OPEN") {
      lineColumns.push({
        title: `${
          ["SILUETA"].includes(loggedInCustomerCode)
            ? "Received Quantity"
            : "Received Gross M."
        }`,
        dataIndex: "receivedGrossWeight",
        key: "receivedGrossWeight",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
    } else {
      lineColumns.push({
        title: `${
          ["SILUETA"].includes(loggedInCustomerCode)
            ? "Received Quantity"
            : "Received Gross M."
        }`,
        dataIndex: "grossWeight",
        key: "grossWeight",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
    }

    return lineColumns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (["PRYM", "SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Roll/Box No:",
        dataIndex: "runningNo",
        key: "runningNo",
        /* align: "right", */
      });
    }
    /* columns.push({
      title: "Location",
      key: "storeLocation",
      render: (text, record) => {
        return record.stockBin.storeLocation.address;
      },
    }); */
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    if (["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Pallet No",
        dataIndex: "palletNo",
        key: "palletNo",
      });
    }
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "UNLOADED") {
          color = "#87d068";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    if (["STR-RM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      });
    }
    if (["PRYM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
      });
    }
    columns.push({
      title: "Net Measure",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Measure",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    if (["PRYM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        align: "right",
        render: (text, record) => {
          return text && Number(text).toFixed(2);
        },
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag
                  color={`${
                    stockIssue.orderStage === "OPEN"
                      ? "#2db7f5"
                      : stockIssue.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {stockIssue.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issuedDate"
                    label="Issue Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issueNumber"
                    label="Issue No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="vehicleNumber" label="Vehicle Number">
                    <Input placeholder="Vehicle Number" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverName" label="Driver Name">
                    <Input placeholder="Driver Name" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact Number">
                    <Input placeholder="Contact Number" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverNic" label="Driver NIC">
                    <Input placeholder="Driver NIC" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="sealNumber" label="Seal No:">
                    <Input placeholder="Seal No:" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="dispatchNumber" label="Dispatch No:">
                    <Input placeholder="Dispatch No:" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  {stockIssue.orderStatus !== "OPEN" && (
                    <Form.Item
                      name="deliveredDate"
                      label="Received Date/Time"
                      rules={[
                        {
                          required: true,
                          message: "Delivered date is required",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        placeholder="Issue date"
                        format="YYYY-MM-DD hh:mm:ss"
                        style={{ width: "100%" }}
                        showTime
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}>
                  <Form.Item name="remark" label="Notes">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item name="stockItem">
                    <Input.Search
                      loading={loadingStockItem}
                      placeholder="Enter valid barcode"
                      allowClear
                      enterButton="Search & Add Stock"
                      onSearch={onSearchAndAddStockItem}
                      style={{ marginBottom: 20 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={stockIssueLines}
                //rowSelection={{ type: "radio", ...issueLineSelection }}
                size="small"
                rowClassName={(record) =>
                  record.addedAll ? "ant-tag-green" : ""
                }
                summary={(pageData) => {
                  let totalRequestedQuantity = 0;
                  let totalRequestedGrossMeasure = 0;
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  let totalGrossWeight = 0;
                  let totalReceivedQuantity = 0;
                  let totalReceivedGrossMeasure = 0;

                  pageData.forEach(
                    ({
                      requestedQuantity,
                      requestedGrossMeasure,
                      quantity,
                      netWeight,
                      grossWeight,
                      receivedQuantity,
                      receivedGrossWeight,
                    }) => {
                      totalRequestedQuantity += requestedQuantity;
                      totalRequestedGrossMeasure += requestedGrossMeasure;
                      totalQuantity += quantity;
                      totalNetWeight += netWeight;
                      totalGrossWeight += grossWeight;
                      totalReceivedQuantity += receivedQuantity;
                      totalReceivedGrossMeasure += receivedGrossWeight;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={4}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        {!["SILUETA"].includes(loggedInCustomerCode) && (
                          <Table.Summary.Cell align="right">
                            <Text strong>{totalRequestedQuantity}</Text>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell align="right">
                          <Text strong>{totalQuantity}</Text>
                        </Table.Summary.Cell>
                        {stockIssue.orderStage === "OPEN" && (
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalReceivedQuantity) &&
                                totalReceivedQuantity}
                            </Text>
                          </Table.Summary.Cell>
                        )}
                        {/* <Table.Summary.Cell align="right">
                          <Text strong>
                            {totalNetWeight &&
                              Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell> */}
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {totalRequestedGrossMeasure &&
                              Number(totalRequestedGrossMeasure).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {totalGrossWeight &&
                              Number(totalGrossWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        {stockIssue.orderStage === "OPEN" && (
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalReceivedGrossMeasure) &&
                                Number(totalReceivedGrossMeasure).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                        )}
                        {stockIssue.orderStage !== "OPEN" && (
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalGrossWeight) &&
                                Number(totalGrossWeight).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                        )}
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
              {stockIssueLines.length > 0 && (
                <>
                  <Divider orientation="left">
                    Stock Items -{" "}
                    {stockIssue.orderStage === "OPEN" && (
                      <Checkbox onChange={handleAllUnloadChange}>
                        Unload All
                      </Checkbox>
                    )}
                  </Divider>
                  <Tabs
                    defaultActiveKey={stockIssueLines[0].key}
                    onChange={handleTabChange}
                  >
                    {stockIssueLines.map((line, index) => (
                      <Tabs.TabPane
                        tab={`${line.requestLine.stockType.code} / ${line.requestLine.lotNumber.number} (${line.quantity})`}
                        key={index}
                      >
                        <Table
                          bordered
                          size="small"
                          rowKey="id"
                          columns={getStockItemColumns()}
                          dataSource={line.issuedItems}
                          pagination={false}
                          rowClassName={(record) =>
                            record.added ? "ant-tag-green" : ""
                          }
                          rowSelection={rowSelection}
                          summary={(pageData) => {
                            let totalQuantity = 0;
                            let totalNetWeight = 0;
                            let totalGrossWeight = 0;
                            let totalGrossValueSqm = 0;

                            pageData.forEach(
                              ({
                                quantity,
                                netWeight,
                                grossWeight,
                                grossValueSqm,
                              }) => {
                                //totalQuantity += quantity ? quantity : 1;
                                totalQuantity += quantity;
                                totalNetWeight += netWeight;
                                totalGrossWeight += grossWeight;
                                totalGrossValueSqm += grossValueSqm;
                              }
                            );

                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell
                                    colSpan={
                                      ["PRYM"].includes(loggedInCustomerCode)
                                        ? getStockItemColumns().length - 4
                                        : getStockItemColumns().length - 3
                                    }
                                  >
                                    <Text strong>Total</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="right">
                                    <Text strong>{/* totalQuantity */}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="right">
                                    <Text strong>
                                      {Number(totalNetWeight).toFixed(2)}
                                    </Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="right">
                                    <Text strong>
                                      {Number(totalGrossWeight).toFixed(2)}
                                    </Text>
                                  </Table.Summary.Cell>
                                  {["PRYM"].includes(loggedInCustomerCode) && (
                                    <Table.Summary.Cell align="right">
                                      <Text strong>
                                        {Number(totalGrossValueSqm).toFixed(2)}
                                      </Text>
                                    </Table.Summary.Cell>
                                  )}
                                  <Table.Summary.Cell />
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </>
              )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
