import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Form,
  PageHeader,
  Table,
  message,
  Row,
  Col,
  Input,
} from 'antd';
import Page from '_components/Page';
import { binService, companyService } from '_services';
import { BarcodeOutlined, CloseOutlined } from '@ant-design/icons';
import { Auth } from '_helpers';

const StrBinSelection = ({ match }) => {
  const { path } = match;
  const [stockBins, setStockBins] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    companyService.getSuggestion('?companyType=STORE').then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        setLoading(true);
        companyService
          .getById(companyId)
          .then((data) => {
            const storeLocationAddresses = data.content.addresses.filter(
              (item) => item.addressType === 'WAREHOUSE'
            );
            setAddresses(storeLocationAddresses);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    });
  }, []);

  const onSearch = (values) => {
    if (!values) {
      setStockBins([]);
    } else {
      let searchParams = '?isParent=1';
      if (values.barcode) {
        /* const barcodeValues = values.barcode.split("|");

        const storeLocation = addresses.find(
          (item) => item.code === barcodeValues[0]
        );

        if (storeLocation) {
          searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
          searchParams += `storeLocationId=${encodeURIComponent(
            storeLocation.id
          )}`;
        } */
        searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
        searchParams += `code=${encodeURIComponent(values.barcode)}`;

        setLoading(true);
        binService
          .search(searchParams)
          .then((data) => {
            if (data.content && data.content[0]) {
              setStockBins(data.content);
            } else {
              message.error('Sorry, No item found against the barcode.');
            }
            setLoading(false);
          })
          .catch((error) => {
            message.error(`${error}`);
            setLoading(false);
          });
      } else {
        message.error('Please enter valid bin barcode.');
        return;
      }
    }
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <Link to={`/stock-transfer/${record.id}/transfer`}>{text}</Link>
      ),
    },
    {
      title: 'SBU',
      dataIndex: 'sbuName',
      key: 'sbuName',
    },
    {
      title: 'Zone',
      dataIndex: 'zoneName',
      key: 'zoneName',
    },
    {
      title: 'Sub Location',
      dataIndex: 'subLocationName',
      key: 'subLocationName',
    },
    {
      title: 'Available Stocks',
      dataIndex: 'availableStockQuantity',
      key: 'availableStockQuantity',
    },
    {
      title: 'Available N.weight',
      dataIndex: 'availableNetWeight',
      key: 'availableNetWeight',
    },
    {
      title: 'Available G.Weight',
      dataIndex: 'availableGrossWeight',
      key: 'availableGrossWeight',
    },
    {
      title: 'Available Cones',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/stock-transfer'>Stock Transfer</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='#'>Bin Selection</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <Form
            form={form}
            onFinish={onSearch}
            layout='vertical'
            className='search-form'
          >
            <Row gutter={24}>
              <Col span={18}>
                <Form.Item name='barcode' label='Bin Barcode'>
                  <Input autoFocus placeholder='Barcode' allowClear />
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Search
                  </Button>
                  <Button
                    style={{ margin: '0 8px' }}
                    onClick={() => {
                      form.resetFields();
                      onSearch();
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={stockBins}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrBinSelection;
