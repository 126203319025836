import React from "react";
import { Route, Switch } from "react-router-dom";
import Add from "./Add";
import List from "./List";
import Summary from "./Summary";

function Roles({ match }) {
  const { path } = match;
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/add`} component={Add} />
      <Route path={`${path}/:id/summary`} component={Summary} />
    </Switch>
  );
}

export { Roles };
