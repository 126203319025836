import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Popconfirm,
  Space,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  measurementTypeService,
} from "_services";
import { Auth, Utils } from "_helpers";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

let lineIndex = 0;

function PrymAdd({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        deliveryLocation: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setCustomers([data.content]);
      setCustomerAddresses(officeAddresses);
      setSelectedCustomer(data.content);
      setIsLoading(false);
    });

    /* companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      if (x.content.length === 1) {
        let companyId = x.content[0].id;
        setSelectedCustomer(x.content[0]);
        companyService.getById(companyId).then((data) => {
          const officeAddress = data.content.addresses.filter(
            (item) => item.addressType === "OFFICE"
          );
          form.setFieldsValue({
            customer: x.content[0],
            deliveryLocation: officeAddress[0],
          });
          setAddresses(officeAddress);
        });
      }
      setCustomers(x.content);
    }); */
    materialTypeService.getSuggestion("").then((x) => {
      setMaterialTypes(x.content);
    });
    lotNumberService.getSuggestion("").then((x) => {
      setLotNumbers(x.content);
    });
    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content);
    });
  }, []);

  const handleFieldChange = (value, fieldName, key) => {
    const lines = [...materialRequestLines];
    const newRequestLines = lines.map((line) => {
      const newLine = Object.assign({}, line);
      if (newLine.key === key) {
        if (fieldName === "stockType") {
          newLine[fieldName] = materialTypes.find((item) => item.id === value);
          return newLine;
        } else {
          newLine[fieldName] = value;
          return newLine;
        }
      }
      return newLine;
    });
    //console.log("Lines: ", groupByLines);
    setMaterialRequestLines(newRequestLines);
  };

  const handleAddRequestLine = () => {
    //console.log("Index:", lineIndex);
    const defaultMeasurementType = measurementTypes.find(
      (item) => item.name === "m"
    );

    const newLine =
      defaultMeasurementType === null
        ? { requestedItems: [] }
        : {
            requestedItems: [],
            measurementType: defaultMeasurementType.id,
          };
    newLine.key = lineIndex;
    const lines = [...materialRequestLines, newLine];
    //console.log("Lines:", lines);
    setMaterialRequestLines(lines);
    lineIndex += 1;
  };

  const handleRemoveRequestLine = (line) => {
    //console.log("Remove:", materialRequestLines);
    const lines = [...materialRequestLines];
    if (lines.includes(line)) {
      lines.splice(lines.indexOf(line), 1);
      setMaterialRequestLines(lines);
    }
  };

  const handleSaveRequestLine = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    //console.log("Target:", target);
    if (
      !target.stockType ||
      //target.hasOwnProperty("lotNumber") &&
      !target.lotNumber ||
      !(target.quantity || target.totalItems) ||
      !target.measurementType
    ) {
      message.error("Please complete the requested line details.");
      e.target.focus();
      return;
    }
    handleAddRequestLine();
  };

  const getRowBykey = (key, newData) => {
    return (materialRequestLines || newData).filter(
      (item) => item.key === key
    )[0];
  };

  const validateRequestLines = (lines) => {
    let validLines = false;
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        if (
          line.stockType &&
          line.lotNumber &&
          (line.quantity || line.totalItems) &&
          line.measurementType
        ) {
          validLines = true;
        }
      });
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateRequestLines(materialRequestLines)) {
          setIsLoading(true);
          //delete values.stockType;
          //delete values.lotNumber;

          /* values.requestNumber = `${
          selectedCustomer && selectedCustomer.code
        }-${moment(selectedRequestDate).format("MM")}-${values.requestNumber}`; */
          values.requestNumber = `${
            selectedCustomer && selectedCustomer.code
          }-MRN-${values.requestNumber}`;
          values.requestDate = moment(values.requestDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let requestLines = [];

          materialRequestLines.forEach((line) => {
            let requestLine = {};
            requestLine.stockType = { id: line.stockType.id };
            if (line.lotNumber) {
              requestLine.lotNumber = { id: line.lotNumber };
            }
            requestLine.measurementType = { id: line.measurementType };
            requestLine.quantity = line.quantity;
            requestLine.totalItems = line.totalItems;
            requestLine.requestedItems = line.requestedItems.map((item) => ({
              id: item.id,
            }));
            requestLines.push(requestLine);
          });
          values.requestLines = requestLines;

          // console.log("MR: ", values);
          // console.log("MR: ", JSON.stringify(values));

          materialRequestService
            .create(values)
            .then(() => {
              setIsLoading(false);
              history.push(".");
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error);
            });
        } else {
          message.error(
            "Please complete all the request lines with valid details"
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockTypeCode",
      width: "30%",
      render: (text, record) => {
        return (
          <Form.Item
            //name={["stockType", record.key, "id"]}
            name={`stockType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "stockType", record.key)}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "30%",
      render: (text, record) => {
        return (
          <Form.Item
            //name={["lotNumber", record.key, "id"]}
            name={`lotNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
            >
              {lotNumbers.length > 0 &&
                lotNumbers.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Rolls/Box",
      key: "totalItems",
      align: "right",
      render: (text, record) => {
        return (
          <Form.Item
            name={`totalItems[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            hasFeedback
          >
            <InputNumber
              placeholder="Total Items"
              min={record._id ? record.totalItems : 0}
              onChange={(e) => handleFieldChange(e, "totalItems", record.key)}
            />
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "Quantity",
      key: "quantity",
      align: "right",
      render: (text, record) => {
        return (
          <Form.Item
            name={`quantity[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            /* rules={[{ required: true, message: "Required." }]} */
            hasFeedback
          >
            <InputNumber
              placeholder="Quantity"
              min={record._id ? record.quantity : 0}
              onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            />
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "10%",
      render: (text, record) => {
        return (
          <Form.Item
            name={`measurementType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: "0" }}
            rules={[{ required: true, message: "Required." }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "measurementType", record.key)
              }
            >
              {measurementTypes.length > 0 &&
                measurementTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: "",
      key: "operation",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveRequestLine(record);
            }}
            icon={<CloseOutlined />}
            style={{ marginLeft: 5 }}
          ></Button>
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSaveRequestLine(e, record.key);
            }}
            style={{ padding: 5, fontSize: 12 }}
          >
            +More
          </Button>
        </Space>
      ),
    });

    return columns;
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to add the changes?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
        disabled={materialRequestLines.length === 0}
      >
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={materialRequestLines.length === 0}
        >
          Save
        </Button>
      </Popconfirm>
    );

    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests/add">New Material Request</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            layout="vertical"
            form={form}
            name="form-create"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["store", "id"]}
                  label="Store"
                  rules={[{ required: true, message: "Store is required" }]}
                >
                  <Select
                    placeholder="Please select"
                    allowClear
                  >
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["customer", "id"]}
                  label="Customer"
                  rules={[{ required: true, message: "Customer is required" }]}
                >
                  <Select
                    placeholder="Please select"
                    //onChange={getAddresses}
                  >
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="requestDate"
                  label="Request Date"
                  rules={[
                    { required: true, message: "Request date is required" },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder="Request date"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="requestNumber"
                  label="Request No"
                  rules={[
                    { required: true, message: "Request no is required" },
                  ]}
                >
                  <Input
                    prefix={`${selectedCustomer && selectedCustomer.code}-MRN-`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={["storeAddress", "id"]}
                  label="Delivery From"
                  rules={[
                    {
                      required: true,
                      message: "Delivery address is required",
                    },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["deliveryLocation", "id"]}
                  label="Deliver To"
                  rules={[
                    { required: true, message: "Delivery from is required" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {customerAddresses.length > 0 &&
                      customerAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactPersonName"
                  label="Contact Person Name"
                  rules={[
                    { required: true, message: "Contact persion is required" },
                  ]}
                >
                  <Input placeholder="Contact persion" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="contactNumber"
                  label="Contact No:"
                  rules={[
                    { required: true, message: "Contact No. is required" },
                  ]}
                >
                  <Input placeholder="Contact number" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="remark" label="Remark">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>

            {materialRequestLines.length > 0 && (
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                size="small"
                summary={(pageData) => {
                  let rollSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let spoolSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxPcsSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxNosSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let boxConeSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let bundlesSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  let defaultSummary = {
                    totalItems: 0,
                    totalQty: 0,
                    uom: "",
                    measurementType: null,
                  };
                  const groupByPackingType = Utils.groupBy(
                    pageData,
                    (line) =>
                      line.stockType &&
                      line.stockType.packingType &&
                      line.stockType.packingType.name
                  );

                  groupByPackingType.forEach((value, key) => {
                    if (key === "ROLL") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          rollSummary.totalItems += totalItems;
                          rollSummary.totalQty += quantity;
                          rollSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "BOX") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          boxSummary.totalItems += totalItems;
                          boxSummary.totalQty += quantity;
                          boxSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "SPOOL") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          spoolSummary.totalItems += totalItems;
                          spoolSummary.totalQty += quantity;
                          spoolSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "BOX_PCS") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          boxPcsSummary.totalItems += totalItems;
                          boxPcsSummary.totalQty += quantity;
                          boxPcsSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "BOX_NOS") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          boxNosSummary.totalItems += totalItems;
                          boxNosSummary.totalQty += quantity;
                          boxNosSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "BOX_CONE") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          boxConeSummary.totalItems += totalItems;
                          boxConeSummary.totalQty += quantity;
                          boxConeSummary.measurementType = measurementType;
                        }
                      );
                    } else if (key === "BUNDLES") {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          bundlesSummary.totalItems += totalItems;
                          bundlesSummary.totalQty += quantity;
                          bundlesSummary.measurementType = measurementType;
                        }
                      );
                    } else {
                      value.forEach(
                        ({ totalItems, quantity, measurementType }) => {
                          defaultSummary.totalItems += totalItems;
                          defaultSummary.totalQty += quantity;
                          defaultSummary.measurementType = measurementType;
                        }
                      );
                    }
                  });
                  measurementTypes.forEach((item) => {
                    if (item.id === rollSummary.measurementType) {
                      rollSummary.uom = item.name;
                    }
                    if (item.id === boxSummary.measurementType) {
                      boxSummary.uom = item.name;
                    }
                    if (item.id === spoolSummary.measurementType) {
                      spoolSummary.uom = item.name;
                    }
                    if (item.id === boxPcsSummary.measurementType) {
                      boxPcsSummary.uom = item.name;
                    }
                    if (item.id === boxNosSummary.measurementType) {
                      boxNosSummary.uom = item.name;
                    }
                    if (item.id === boxConeSummary.measurementType) {
                      boxConeSummary.uom = item.name;
                    }
                    if (item.id === bundlesSummary.measurementType) {
                      bundlesSummary.uom = item.name;
                    }
                    if (item.id === defaultSummary.measurementType) {
                      defaultSummary.uom = item.name;
                    }
                  });

                  return (
                    <>
                      {rollSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - ROLL</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(rollSummary.totalItems) &&
                                rollSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(rollSummary.totalQty) &&
                                rollSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{rollSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {boxSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - BOX</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxSummary.totalItems) &&
                                boxSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxSummary.totalQty) &&
                                boxSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{boxSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {spoolSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - SPOOL</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(spoolSummary.totalItems) &&
                                spoolSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(spoolSummary.totalQty) &&
                                spoolSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{spoolSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {boxPcsSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - BOX(PCS)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxPcsSummary.totalItems) &&
                                boxPcsSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxPcsSummary.totalQty) &&
                                boxPcsSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{boxPcsSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {boxNosSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - BOX(NOS)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxNosSummary.totalItems) &&
                                boxNosSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxNosSummary.totalQty) &&
                                boxNosSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{boxNosSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {boxConeSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - BOX(CONE)</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxConeSummary.totalItems) &&
                                boxConeSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(boxConeSummary.totalQty) &&
                                boxConeSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{boxConeSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {bundlesSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total - BUNDLES</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(bundlesSummary.totalItems) &&
                                bundlesSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(bundlesSummary.totalQty) &&
                                bundlesSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{bundlesSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                      {defaultSummary.totalItems > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(defaultSummary.totalItems) &&
                                defaultSummary.totalItems}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(defaultSummary.totalQty) &&
                                defaultSummary.totalQty}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>{defaultSummary.uom}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      )}
                    </>
                  );
                }}
              />
            )}
            {materialRequestLines.length === 0 && (
              <Button
                style={{
                  width: "100%",
                  marginTop: 16,
                  marginBottom: 8,
                  color: "white",
                  backgroundColor: "#13c2c2",
                }}
                type="dashed"
                onClick={handleAddRequestLine}
                icon={<PlusOutlined />}
              >
                Add Item
              </Button>
            )}
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymAdd;
