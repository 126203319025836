import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/companies`;
const addressUrl = "addresses";

export const addressService = {
  getAllByCompany,
  search,
  getById,
  create,
  update,
  link,
  delete: _delete,
};

function getAllByCompany(companyId) {
  return fetchWrapper.get(`${baseUrl}/${companyId}/${addressUrl}`);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function getById(companyId, id) {
  return fetchWrapper.get(`${baseUrl}/${companyId}/${addressUrl}/${id}`);
}

function create(companyId, params) {
  return fetchWrapper.post(`${baseUrl}/${companyId}/${addressUrl}`, params);
}

function update(companyId, id, params) {
  return fetchWrapper.put(
    `${baseUrl}/${companyId}/${addressUrl}/${id}`,
    params
  );
}

function link(companyId, id) {
  return fetchWrapper.put(`${baseUrl}/${companyId}/${addressUrl}/${id}/link`);
}

function _delete(companyId, id) {
  return fetchWrapper.delete(`${baseUrl}/${companyId}/${addressUrl}/${id}`);
}
