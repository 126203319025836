import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Typography,
  Divider,
  Tooltip,
  Tag,
  extarea,
  Tabs,
  Spin,
  Popconfirm,
  Space,
  Modal,
} from 'antd';
import moment from 'moment';
import Page from '_components/Page';
import {
  companyService,
  materialTypeService,
  lotNumberService,
  materialRequestService,
  materialDetailService,
  measurementTypeService,
  sbuService,
  zoneService,
  subLocationService,
} from '_services';
import StockSelection from './StockSelection';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Auth } from '_helpers';

let lineIndex = 0;

function StrRmAddBySelection({ history, match }) {
  const [isLoading, setIsLoading] = useState(false);
  const [stockItemLoading, setStockItemLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [selectedcustomer, setSelectedcustomer] = useState(null);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [lotNumberId, setLotNumberId] = useState(null);
  const [materialRequestLines, setMaterialRequestLines] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRequestDate, setSelectedRequestDate] = useState(Date());
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [availableStocks, setAvailableStocks] = useState([]);
  const [availableStocksLoading, setIsAvailableStocksLoading] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [requestedStocks, setRequestedStocks] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion('?companyType=STORE').then((x) => {
      if (x.content.length === 1) {
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === 'WAREHOUSE'
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === 'OFFICE'
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        deliveryLocation: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setCustomers([data.content]);
      setCustomerAddresses(officeAddresses);
      setSelectedcustomer(data.content);
      setIsLoading(false);
    });

    materialTypeService.getSuggestion('').then((x) => {
      setMaterialTypes(x.content);
    });
    lotNumberService.getSuggestion('').then((x) => {
      setLotNumbers(x.content);
    });
    measurementTypeService.getSuggestion().then((data) => {
      setMeasurementTypes(data.content.filter((item) => item.name === 'KG'));
    });
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search('')
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFieldChange = (value, fieldName, key) => {
    const lines = [...materialRequestLines];
    const newRequestLines = lines.map((line) => {
      const newLine = Object.assign({}, line);
      if (newLine.key === key) {
        newLine[fieldName] = value;
        return newLine;
      }
      return newLine;
    });
    // console.log("Lines: ", newRequestLines);
    setMaterialRequestLines(newRequestLines);
  };

  const getStoreAddresses = (companyId) => {
    form.resetFields(['storeAddress']);

    companyService.getById(companyId).then((x) => {
      // setSelectedStore(x.content);
      setStoreAddresses(
        x.content.addresses.filter(
          (address) => address.addressType === 'WAREHOUSE'
        )
      );
    });
  };

  const getStocks = (stockTypeId, lotId) => {
    //let searchParams = `?stockTypeId=${materialTypeId}&lotNumberId=${lotNumberId}&status=AVAILABLE&pageNumber=1&pageSize=100`;
    let searchParams = `?stockTypeId=${stockTypeId}&mode=AVAILABLE&pageNumber=1&pageSize=1000`;
    searchParams = lotId
      ? `${searchParams}&lotNumberId=${lotId}`
      : searchParams;

    setIsAvailableStocksLoading(true);
    materialDetailService
      .searchAvailable(searchParams)
      .then((data) => {
        setIsAvailableStocksLoading(false);
        if (data.content.length > 0) {
          setAvailableStocks(data.content);
          setIsStockSelectionVisible(true);
          setMaterialTypeId(stockTypeId);
          setLotNumberId(lotId);
        } else {
          message.info(
            "Sorry, There's no available stocks for the given criteria!"
          );
        }
      })
      .catch((error) => {
        setIsAvailableStocksLoading(false);
        message.error(`${error}`);
      });
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected: ", selectedStockIds);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    //console.log("SelectedST: ", materialRequestLines);
    setStockItemLoading(true);
    if (selectedStocks) {
      let requestLine = null;
      materialRequestLines.forEach((item) => {
        //console.log("Item: ", item);
        if (item.hasOwnProperty('lotNumber')) {
          if (
            item.stockType === materialTypeId &&
            item.lotNumber === lotNumberId
          ) {
            requestLine = item;
          }
        } else if (item.stockType === materialTypeId) {
          requestLine = item;
        }
      });
      const stockTypeObj = materialTypes.find(
        (item) => item.id === materialTypeId
      );
      const lotNumberObj = lotNumbers.find((item) => item.id === lotNumberId);
      if (requestLine) {
        requestLine['stockTypeObj'] = stockTypeObj;
        requestLine['lotNumberObj'] = lotNumberObj;
        requestLine['requestedItems'] = selectedStocks;
        requestLine['quantity'] = selectedStocks.length;
        requestLine['netWeight'] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
        requestLine['grossWeight'] =
          selectedStocks.length > 0 &&
          selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);
      } else {
        const newLine = {
          stockTypeObj: stockTypeObj,
          lotNumberObj: lotNumberObj,
          stockType: stockTypeObj.id,
          lotNumber: lotNumberObj && lotNumberObj.id,
          requestedItems: selectedStocks,
          quantity: selectedStocks.length,
          netWeight: selectedStocks
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next),
          grossWeight: selectedStocks
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next),
        };
        newLine.key = lineIndex;
        const lines = [...materialRequestLines, newLine];
        setMaterialRequestLines(lines);
        lineIndex += 1;
        setMaterialTypeId(null);
        setLotNumberId(null);
        form.setFieldsValue({ stockType: null, lotNumber: null });
      }
      setRequestedStocks([...requestedStocks, ...selectedStocks]);
      //console.log("RequestedStocks:", requestedStocks);
    }
    form.setFieldsValue({ stockType: null, lotNumber: null });
    setIsStockSelectionVisible(false);
    setStockItemLoading(false);
    setSelectedStockIds([]);
    //console.log("requestLines:", materialRequestLines);
  };

  const handleSearchAndAddStocks = (line) => {
    const selectedStockTypeId = form.getFieldValue(
      'stockType[' + line.key + ']'
    );
    const selectedLotNumberId = form.getFieldValue(
      'lotNumber[' + line.key + ']'
    );
    setMaterialTypeId(selectedStockTypeId);
    setLotNumberId(selectedLotNumberId);

    if (selectedStockTypeId) {
      let requestLine = null;
      materialRequestLines.forEach((item) => {
        if (item.hasOwnProperty('lotNumber')) {
          if (
            item.stockType === materialTypeId &&
            item.lotNumber === lotNumberId
          ) {
            requestLine = item;
          }
        } else if (item.stockType === materialTypeId) {
          requestLine = item;
        }
      });
      if (requestLine && requestLine.requestedItems) {
        setSelectedStockIds(requestLine.requestedItems.map((item) => item.id));
      }
      getStocks(selectedStockTypeId, selectedLotNumberId);
    } else {
      message.error('Please select valid material type!');
    }
  };

  const handleAddRequestLine = () => {
    //console.log("Index:", lineIndex);
    const newLine = { requestedItems: [] };
    newLine.key = lineIndex;
    const lines = [...materialRequestLines, newLine];
    setMaterialRequestLines(lines);
    lineIndex += 1;
    //console.log("Lines:", materialRequestLines);
  };

  const handleRemoveRequestLine = (line) => {
    //console.log("Remove:", materialRequestLines);
    const lines = [...materialRequestLines];
    if (lines.includes(line)) {
      lines.splice(lines.indexOf(line), 1);
      setMaterialRequestLines(lines);
    }
  };

  const handleSaveRequestLine = (e, key) => {
    e.persist();
    const target = getRowBykey(key) || {};
    //console.log("Target:", target);
    if (!target.stockType || !target.quantity) {
      message.error('Please complete the requested line details.');
      e.target.focus();
      return;
    }
    handleAddRequestLine();
  };

  const getRowBykey = (key, newData) => {
    return (materialRequestLines || newData).filter(
      (item) => item.key === key
    )[0];
  };

  const validateRequestLines = (lines) => {
    let validLines = false;
    if (lines && lines.length > 0) {
      for (const line of lines) {
        if (line.stockType && line.quantity && line.quantity > 0) {
          validLines = true;
        } else {
          validLines = false;
          break;
        }
      }
    }
    return validLines;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        if (validateRequestLines(materialRequestLines)) {
          setIsLoading(true);

          /* values.requestNumber = `${
            selectedCustomer && selectedCustomer.code
          }-MRN-${values.requestNumber}`; */
          values.requestDate = moment(values.requestDate).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          let requestLines = [];

          materialRequestLines.forEach((line) => {
            let requestLine = {};
            requestLine.stockType = { id: line.stockType };
            if (line.lotNumber) {
              requestLine.lotNumber = { id: line.lotNumber };
            }
            requestLine.measurementType = {
              id: measurementTypes.find((item) => item.name === 'Kg').id,
            };
            requestLine.quantity = line.netWeight;
            requestLine.totalItems = line.requestedItems.length;
            requestLine.requestedItems = line.requestedItems.map((item) => ({
              id: item.id,
            }));
            requestLines.push(requestLine);
          });
          values.requestLines = requestLines;
          const subLocation = subLocations.find(
            (item) => item.id === values.subLocationId
          );
          values.subLocation = {
            id: subLocation.id,
            shortCode: subLocation.shortCode,
          };

          // console.log("MR: ", values);
          // console.log("MR: ", JSON.stringify(values));

          materialRequestService
            .create(values)
            .then(() => {
              setIsLoading(false);
              history.push('.');
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error);
            });
        } else {
          message.error(
            'Please complete all the request lines with valid details(Material Type & Number of boxes > 0)'
          );
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push('.');
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: 'Material Type',
      dataIndex: 'stockType',
      key: 'stockTypeCode',
      width: '30%',
      render: (text, record) => {
        return (
          <Form.Item
            //name={["stockType", record.key, "id"]}
            name={`stockType[${record.key}]`}
            key={record.key}
            style={{ marginBottom: '0' }}
            rules={[{ required: true, message: 'Required.' }]}
            initialValue={text}
            hasFeedback
          >
            <Select
              placeholder='Please select'
              allowClear
              showSearch
              optionFilterProp='children'
              onChange={(e) => handleFieldChange(e, 'stockType', record.key)}
            >
              {materialTypes.length > 0 &&
                materialTypes.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      width: '30%',
      render: (text, record) => {
        return (
          <Form.Item
            name={`lotNumber[${record.key}]`}
            key={record.key}
            style={{ marginBottom: '0' }}
            rules={[{ required: false, message: 'Required.' }]}
            hasFeedback
          >
            <Select
              placeholder='Please select'
              allowClear
              showSearch
              optionFilterProp='children'
              onChange={(e) => handleFieldChange(e, 'lotNumber', record.key)}
            >
              {lotNumbers.length > 0 &&
                lotNumbers.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        );
      },
    });
    columns.push({
      title: 'Boxes',
      key: 'quantity',
      align: 'right',
      render: (text, record) => {
        return record.requestedItems && record.requestedItems.length;
      },
    });
    columns.push({
      title: 'Net Weight(Kg)',
      dataIndex: 'netWeight',
      key: 'netWeight',
      align: 'right',
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    /* columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    }); */
    columns.push({
      title: '',
      key: 'operation',
      render: (text, record) => (
        <Space size='small'>
          <Button
            type='primary'
            onClick={(e) => {
              e.stopPropagation();
              handleSearchAndAddStocks(record);
            }}
            icon={<SearchOutlined />}
          >
            Find Stocks
          </Button>
          <Button
            type='primary'
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveRequestLine(record);
            }}
            icon={<CloseOutlined />}
            style={{ marginLeft: 5 }}
          ></Button>
          <Button
            type='primary'
            onClick={(e) => {
              e.preventDefault();
              handleSaveRequestLine(e, record.key);
            }}
            style={{ padding: 5, fontSize: 12 }}
          >
            +More
          </Button>
        </Space>
      ),
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: 'Material',
      dataIndex: 'stockType',
      key: 'stockType',
      render: (text, record) => {
        return record.stockType.code;
      },
    });
    columns.push({
      title: 'Description',
      key: 'stockTypeName',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder='topLeft' title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: 'Lot',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: 'Prod Date',
      dataIndex: 'productionDate',
      key: 'productionDate',
      width: '8%',
    });
    /* columns.push({
      title: "Recv Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: "8%",
    }); */
    columns.push({
      title: 'Inspect.St',
      dataIndex: 'inspectionStatus',
      key: 'inspectionStatus',
      width: '5%',
    });
    columns.push({
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      width: '6%',
      render: (text, record) => {
        return record.supplier && record.supplier.name;
      },
    });
    columns.push({
      title: 'Box #',
      dataIndex: 'runningNo',
      key: 'runningNo',
      width: '5%',
    });
    columns.push({
      title: 'Bin',
      dataIndex: 'stockBin',
      key: 'stockBin',
      width: '6%',
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: 'Cone',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      width: '5%',
    });
    columns.push({
      title: 'Net Weight(Kg)',
      dataIndex: 'netWeight',
      key: 'netWeight',
      align: 'right',
      width: '10%',
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    /* columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    }); */
    columns.push({
      title: 'Status',
      dataIndex: 'mode',
      key: 'mode',
      width: '8%',
      render: (text) => {
        let color = '';
        if (text === 'AVAILABLE') {
          color = '#87d068';
        }
        if (text === 'REQUESTED') {
          color = '#2db7f5';
        }
        if (text === 'ISSUED') {
          color = '#108ee9';
        }
        if (text === 'DELIVERED') {
          color = '#f50';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });

    return columns;
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title='Are you sure to add the changes?'
        onConfirm={handleFormSumbit}
        key='add-confirm'
        disabled={
          materialRequestLines.flatMap((item) => item.requestedItems).length ===
          0
        }
      >
        <Button
          key='1'
          type='primary'
          htmlType='submit'
          loading={isLoading}
          disabled={
            materialRequestLines.flatMap((item) => item.requestedItems)
              .length === 0
          }
        >
          Save
        </Button>
      </Popconfirm>
    );

    buttons.push(
      <Button key='2' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const getTabContent = (requestedLines) => {
    let tabPanes = [];
    requestedLines.forEach((line, index) => {
      if (line.requestedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockTypeObj && line.stockTypeObj.code} / ${
              line.lotNumberObj && line.lotNumberObj.number
                ? line.lotNumberObj.number
                : ''
            } (${line.requestedItems && line.requestedItems.length})`}
            key={index}
          >
            <Table
              bordered
              size='small'
              rowKey='id'
              columns={getStockItemColumns()}
              dataSource={line.requestedItems}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ netWeight, grossWeight, quantity }) => {
                  totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={7}>
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{line.requestedItems.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{line.totalQuantity}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align='right'>
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                      {/* <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/material-requests'>Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/material-requests/add'>New Material Request</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            layout='vertical'
            form={form}
            name='form-create'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={['store', 'id']}
                  label='Store'
                  rules={[{ required: true, message: 'Store is required' }]}
                >
                  <Select
                    placeholder='Please select'
                    allowClear
                    onChange={getStoreAddresses}
                  >
                    {stores.length > 0 &&
                      stores.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['customer', 'id']}
                  label='Customer'
                  rules={[{ required: true, message: 'Customer is required' }]}
                >
                  <Select placeholder='Please select'>
                    {customers.length > 0 &&
                      customers.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='requestDate'
                  label='Request Date'
                  rules={[
                    { required: true, message: 'Request date is required' },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    placeholder='Request date'
                    format='YYYY-MM-DD'
                    style={{ width: '100%' }}
                    onChange={setSelectedRequestDate}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='sbuId' label='SBU'>
                  <Select
                    placeholder='Please select'
                    allowClear
                    optionFilterProp='children'
                    showSearch
                    onChange={(e) => {
                      form.setFieldsValue({ zoneId: null });
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedSbuId(e);
                    }}
                    onClear={() => {
                      form.setFieldsValue({ zoneId: null });
                      form.setFieldsValue({ subLocationId: null });
                    }}
                  >
                    {businessUnits.length > 0 &&
                      businessUnits.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={['storeAddress', 'id']}
                  label='Delivery From'
                  rules={[
                    {
                      required: true,
                      message: 'Delivery address is required',
                    },
                  ]}
                >
                  <Select placeholder='Please select' allowClear>
                    {storeAddresses.length > 0 &&
                      storeAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['deliveryLocation', 'id']}
                  label='Deliver To'
                  rules={[
                    { required: true, message: 'Delivery from is required' },
                  ]}
                >
                  <Select placeholder='Please select'>
                    {customerAddresses.length > 0 &&
                      customerAddresses.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.address}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='contactPersonName'
                  label='Contact Person Name'
                  rules={[
                    { required: true, message: 'Contact persion is required' },
                  ]}
                >
                  <Input placeholder='Contact persion' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='zoneId' label='Zone'>
                  <Select
                    placeholder='Please select'
                    allowClear
                    optionFilterProp='children'
                    showSearch
                    onChange={(e) => {
                      form.setFieldsValue({ subLocationId: null });
                      setSelectedZoneId(e);
                    }}
                    onClear={() => {
                      form.setFieldsValue({ subLocationId: null });
                    }}
                    disabled={!selectedSbuId}
                  >
                    {zones.length > 0 &&
                      zones
                        .filter((item) => item.sbu.id === selectedSbuId)
                        .map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}></Col>
              <Col span={6}>
                <Form.Item name='remark' label='Remark'>
                  <TextArea />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='contactNumber'
                  label='Contact No:'
                  rules={[
                    { required: true, message: 'Contact No. is required' },
                  ]}
                >
                  <Input placeholder='Contact number' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='subLocationId'
                  label='Sub-Location'
                  rules={[
                    {
                      required: true,
                      message: 'Please select Sub-Location',
                    },
                  ]}
                >
                  <Select
                    placeholder='Please select'
                    allowClear
                    optionFilterProp='children'
                    showSearch
                    disabled={!selectedZoneId}
                  >
                    {subLocations.length > 0 &&
                      subLocations
                        .filter((item) => item.zone.id === selectedZoneId)
                        .map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {materialRequestLines.length > 0 && (
              <Table
                bordered
                loading={availableStocksLoading}
                pagination={false}
                columns={getLineColumns()}
                dataSource={materialRequestLines}
                //expandable={{ expandedRowRender }}
                size='small'
                summary={(pageData) => {
                  let totalQuantity = 0;
                  let totalNetWeight = 0;
                  //let totalGrossWeight = 0;

                  pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    //totalGrossWeight += grossWeight;
                  });

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                          <Text strong>
                            {!isNaN(totalQuantity) && totalQuantity}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                          <Text strong>
                            {!isNaN(totalNetWeight) &&
                              Number(totalNetWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        {/* <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalGrossWeight) &&
                              Number(totalGrossWeight).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell> */}
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
            {materialRequestLines.length === 0 && (
              <Button
                style={{
                  width: '100%',
                  marginTop: 16,
                  marginBottom: 8,
                  color: 'white',
                  backgroundColor: '#13c2c2',
                }}
                type='dashed'
                onClick={handleAddRequestLine}
                icon={<PlusOutlined />}
              >
                Add Item
              </Button>
            )}
          </Form>
          <Modal
            title={`Stocks (${availableStocks.length})`}
            style={{ top: 48 }}
            visible={isStockSelectionVisible}
            okText={`Add Stocks (${selectedStockIds.length})`}
            onOk={handleAddStocks}
            //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
            onCancel={() => setIsStockSelectionVisible(false)}
            width={1300}
            destroyOnClose={false}
          >
            <StockSelection
              stocks={availableStocks}
              selectedIds={selectedStockIds}
              customer={selectedCustomer}
              onSelectChange={handleStockSelection}
            />
          </Modal>
          {materialRequestLines.length > 0 &&
            materialRequestLines.flatMap((item) => item.requestedItems).length >
              0 && (
              <>
                <Divider orientation='left'>Stock Items</Divider>
                <Spin tip='Loading...' spinning={stockItemLoading}>
                  <Tabs defaultActiveKey={materialRequestLines[0].key}>
                    {getTabContent(materialRequestLines)}
                  </Tabs>
                </Spin>
              </>
            )}
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmAddBySelection;
