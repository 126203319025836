import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import React from 'react';
import { sbuService } from '_services';

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    if (values.code) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.name) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    if (values.description) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `description=${encodeURIComponent(values.description)}`;
    }
    sbuService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='code' label='Code'>
            <Input placeholder='Code' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='name' label='Name'>
            <Input placeholder='First name' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='description' label='Description'>
            <Input placeholder='Description' />
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
