import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/stockTypes`;

export const materialTypeService = {
  getAll,
  getSuggestion,
  search,
  getById,
  create,
  update,
  transfer,
  delete: _delete,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function search(params) {
  return fetchWrapper.get(`${baseUrl}${params}`);
}

function getSuggestion() {
  return fetchWrapper.get(`${baseUrl}/suggestion`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function transfer(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/transfer`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
