import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Popconfirm,
  message,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { binLocationService, binService } from '_services';
import TabMenu from '../TabMenu';
import { Auth } from '_helpers';

function List({ match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [binLocation, setBinLocation] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setLoading(true);

    binLocationService
      .getById(id)
      .then((data) => {
        setBinLocation(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleDelete = (binId) => {
    binLocationService
      .removeBin(id, binId)
      .then((data) => {
        message.success('Successfully deleted!');
        document.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleDeleteBins = () => {
    setDeleting(true);
    binLocationService
      .removeBins(id, selectedRowKeys)
      .then((data) => {
        setDeleting(false);
        message.success('Successfully deleted!');
        document.location.reload();
      })
      .catch((error) => {
        setDeleting(false);
        setError(error);
      });
  };

  const getAdditionalButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Button key='buld-remove' onClick={handleDeleteBins} loading={deleting}>
          Remove Bins ({selectedRowKeys.length})
        </Button>
      );
    }
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Link to={`${path}/add`.replace(':id', id)} key='1'>
          <Button>Add Bins</Button>
        </Link>
      );
    }

    return buttons;
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <Link to={`/bins/${record.id}/summary`} target='_blank'>
          {text}
        </Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Zone',
      dataIndex: 'zoneName',
      key: 'zoneName',
    },
    {
      title: 'Available Stocks',
      dataIndex: 'availableStockQuantity',
      key: 'availableStockQuantity',
    },
    {
      title: 'Available N.Weight',
      dataIndex: 'availableNetWeight',
      key: 'availableNetWeight',
    },
    {
      title: 'Available G.Weight',
      dataIndex: 'availableGrossWeight',
      key: 'availableGrossWeight',
    },
    {
      title: 'Available Cones',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <Popconfirm
            title='Are you sure to delete this item?'
            onConfirm={() => handleDelete(record.id)}
            disabled={!Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])}
          >
            <CloseOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item href='/dashboard'>
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/bin-locations'>
              <span>Bin Location Search</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/bin-locations/${binLocation.id}/summary`}>
              <span>
                Bin Location: {binLocation.name} (
                {binLocation.availableStockQuantity})
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Bins</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu='sub-bins' id={id} />
          <Table
            bordered
            loading={loading}
            rowKey='id'
            size='small'
            columns={columns}
            dataSource={binLocation.stockBins}
            pagination={false}
            rowSelection={rowSelection}
          />
        </Page>
      </PageHeader>
    </div>
  );
}

export default List;
