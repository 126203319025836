import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Typography,
  Popconfirm,
  Table,
  Tag,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  stockIssueService,
} from "_services";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";
import Checkbox from "antd/lib/checkbox/Checkbox";

function PrymSummary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [loadingSummaryPdf, setLoadingSummaryPdf] = useState(false);
  const [loadingDetailedPdf, setLoadingDetailedPdf] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDetailsUpdate, setLoadingDetailsUpdate] = useState(false);
  const [stockIssue, setStockIssue] = useState({});
  const [stockIssueLines, setStockIssueLines] = useState([]);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [issuedStocks, setIssuedStocks] = useState([]);
  const [receivedStocks, setReceivedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);
  const { Option } = Select;
  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    setLoading(true);
    stockIssueService
      .getById(id)
      .then((x) => {
        let stockIssue = x.content;
        //console.log("Issue:", stockIssue.deliveredDate);
        stockIssue.issuedDate = moment(stockIssue.issuedDate);
        stockIssue.deliveredDate = stockIssue.deliveredDate
          ? moment(stockIssue.deliveredDate)
          : moment();
        stockIssue.issuedLines.forEach((line, i) => {
          line.key = `${line.stockType.id}_${line.lotNumber.id}`;
          line.receivedQuantity = 0;
          line.receivedQty = 0;

          line.requestedTotalItems = line.requestLine.totalItems;
          line.requestedQty = line.requestLine.quantity;
          line.measurementType = line.requestLine.measurementType;

          for (const item of line.issuedItems) {
            if (item.mode === "ISSUED") {
              line.issuedQuantity = line.issuedQuantity
                ? line.issuedQuantity + 1
                : 1;
              line.issuedNetWeight = line.issuedNetWeight
                ? line.issuedNetWeight + item.netWeight
                : item.netWeight;
              line.issuedGrossWeight = line.issuedGrossWeight
                ? line.issuedGrossWeight + item.grossWeight
                : item.grossWeight;
              line.issuedQty = line.issuedQty
                ? line.issuedQty + item.quantity
                : item.quantity;
            } else if (item.mode === "DELIVERED") {
              line.issuedQuantity = line.issuedQuantity
                ? line.issuedQuantity + 1
                : 1;
              line.issuedNetWeight = line.issuedNetWeight
                ? line.issuedNetWeight + item.netWeight
                : item.netWeight;
              line.issuedGrossWeight = line.issuedGrossWeight
                ? line.issuedGrossWeight + item.grossWeight
                : item.grossWeight;
              line.issuedQty = line.issuedQty
                ? line.issuedQty + item.quantity
                : item.quantity;
              line.receivedQuantity = line.receivedQuantity + 1;
              line.receivedQty = line.issuedQty;
            }
          }
          setIssuedStocks([...issuedStocks, ...line.issuedItems]);

          if (!stockIssueLines.map((line) => line.key).includes(line.key)) {
            stockIssueLines.push(line);
          }
        });

        //console.log("Issue Lines", stockIssue);
        setStockIssue(stockIssue);
        setStockIssueLines([].concat(stockIssueLines));
        setSelectedCustomer(stockIssue.customer);
        onFill(stockIssue);

        if (stockIssueLines.length > 0) {
          handleTabChange(stockIssueLines[0].key);
        }

        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });

    companyService.getSuggestion("?companyType=STORE").then((x) => {
      setStores(x.content);
    });
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
  }, []);

  const onSearchAndAddStockItem = (value) => {
    if (value) {
      setLoadingStockItem(true);
      materialDetailService
        .search(`?barcode=${encodeURIComponent(value)}`)
        .then((data) => {
          if (data.content && data.content[0]) {
            let stock = data.content[0];
            let matchedIssueLine = null;

            const deliveryItem = stockIssueLines
              .flatMap((line) => line.issuedItems)
              .find((item) => item.id === stock.id);
            if (deliveryItem) {
              if (deliveryItem.added) {
                message.info("Already loaded this stock item");
              } else {
                for (const line of stockIssueLines) {
                  if (
                    line.issuedItems &&
                    line.issuedItems.find((item) => item.id === stock.id)
                  ) {
                    handleTabChange(line.key);
                    matchedIssueLine = line;
                  }
                }

                const lineKey = `${matchedIssueLine.stockType.id}_${matchedIssueLine.lotNumber.id}`;
                if (selectedRowKeyMap.has(lineKey)) {
                  selectedRowKeyMap.set(lineKey, [
                    ...selectedRowKeyMap.get(lineKey),
                    stock.id,
                  ]);
                } else {
                  selectedRowKeyMap.set(lineKey, [stock.id]);
                }

                updateStockItemStatus();
                message.success("Successfully loaded to the delivery");
              }
            } else {
              message.error("Sorry, No item found against the added stocks");
            }

            form.setFieldsValue({ stockItem: null });
          } else {
            message.error("Sorry, No item found against the barcode.");
          }
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingUpdate(true);
        delete values.issueNumber;
        delete values.issuedDate;
        delete values.store;
        delete values.vehicleNumber;
        delete values.stockItem;

        values.issuedLines = stockIssueLines.map((line) => ({
          issuedItems: line.issuedItems
            .filter((item) => item.added)
            .map((item) => ({
              id: item.id,
              mode: "DELIVERED",
            })),
        }));
        values.deliveredDate = moment(values.deliveredDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log("Update: ", values);
        stockIssueService
          .deliveryUpdate(id, values)
          .then(() => {
            setLoadingUpdate(false);
            message.success("Delivery success!");
            history.push("..");
          })
          .catch((error) => {
            setLoadingUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((errorInfo) => {});
  };

  const onSelectChange = (rowKeys) => {
    setSelectedRowKeyMap(selectedRowKeyMap.set(activeTabKey, rowKeys));
    updateStockItemStatus();
  };

  const updateStockItemStatus = () => {
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    setSelectedRowKeys(allSelectedKeys);
    const totalIssuedStocks = stockIssueLines.flatMap(
      (item) => item.issuedItems
    );
    let unLoadedStocks = [];
    for (let item of totalIssuedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.added = true;
        item.mode = "UNLOADED";
        unLoadedStocks.push(item);
      } else {
        item.added = false;
        item.mode = "ISSUED";
      }
    }
    setReceivedStocks(unLoadedStocks);

    stockIssueLines.forEach((line) => {
      line.receivedQuantity = 0;
      line.receivedNetWeight = 0;
      line.receivedGrossWeight = 0;
      line.receivedQty = 0;
      line.addedAll = false;
      unLoadedStocks.forEach((stock) => {
        if (
          line.requestLine.stockType.id === stock.stockType.id &&
          line.requestLine.lotNumber.id === stock.lotNumber.id
        ) {
          line.receivedQuantity += 1;
          line.receivedNetWeight += stock.netWeight;
          line.receivedGrossWeight += stock.grossWeight;
          line.receivedQty += stock.quantity;
        }
      });
      // console.log(
      //   "Line Qty:",
      //   line.quantity,
      //   ", Got Qty:",
      //   line.receivedQuantity
      // );
      if (line.quantity === line.receivedQuantity) {
        line.addedAll = true;
      }
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.mode === "DELIVERED",
    }),
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
  };

  const handlePrintPdf = (summaryFlag) => {
    if (summaryFlag) {
      setLoadingSummaryPdf(true);
    } else {
      setLoadingDetailedPdf(true);
    }

    stockIssueService.printPdf(id, summaryFlag).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Stock-Delivery-${summaryFlag ? "Summary_" : "Detailed_"}${
          stockIssue.issueNumber
        }.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      if (summaryFlag) {
        setLoadingSummaryPdf(false);
      } else {
        setLoadingDetailedPdf(false);
      }
    });
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    stockIssueService
      .printExcel(id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Issue_${stockIssue.issueNumber}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const handleUpdateDetails = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingDetailsUpdate(true);
        const payLoad = {
          vehicle: values.vehicle,
        };
        stockIssueService
          .updateDetails(id, payLoad)
          .then(() => {
            message.success("Successfully updated!");
            setLoadingDetailsUpdate(false);
            history.push(`${path}`.replace(":id", id));
          })
          .catch((error) => {
            setLoadingDetailsUpdate(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleAllUnloadChange = (e) => {
    if (e.target.checked) {
      stockIssueLines.forEach((line, index) => {
        setSelectedRowKeyMap(
          selectedRowKeyMap.set(
            line.key,
            line.issuedItems.map((item) => item.id)
          )
        );
      });

      updateStockItemStatus();
    } else {
      setSelectedRowKeyMap(new Map());
      const totalIssuedStocks = stockIssueLines.flatMap(
        (item) => item.issuedItems
      );
      for (let item of totalIssuedStocks) {
        item.added = false;
        item.mode = "ISSUED";
      }
      setReceivedStocks([]);
      stockIssueLines.forEach((line) => {
        line.receivedQuantity = 0;
        line.receivedNetWeight = 0;
        line.receivedGrossWeight = 0;
        line.receivedQty = 0;
        line.addedAll = false;
      });
    }
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      handleTabChange(selectedIssueLineKeys[0]);
    },
  };

  const getTabContent = (stockIssueLines) => {
    let tabPanes = [];
    stockIssueLines.forEach((line) => {
      if (line.issuedItems && line.issuedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.requestLine.stockType.code} / ${line.requestLine.lotNumber.number} (${line.quantity})`}
            key={line.key}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => (record.added ? "ant-tag-green" : "")}
              rowSelection={rowSelection}
              summary={(pageData) => {
                let totalItems = 0;
                let totalQty = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossValueSqm = 0;

                pageData.forEach(
                  ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                    totalItems += 1;
                    totalQty += quantity ? quantity : 1;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossValueSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3}>
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong style={{ paddingLeft: 12 }}>
                          {totalItems}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} />
                      <Table.Summary.Cell align="right">
                        <Text strong style={{ paddingLeft: 12 }}>
                          {Number(totalQty).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossValueSqm).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (
      Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"]) &&
      ["ISSUED", "COMPLETE"].includes(stockIssue.orderStage)
    ) {
      buttons.push(
        <Tooltip key="tp-summary-pdf" placement="topLeft" title="Summary PDF">
          <Button
            key="summary-pdf"
            icon={<FilePdfOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handlePrintPdf(true);
            }}
            loading={loadingSummaryPdf}
          ></Button>
        </Tooltip>
      );
      buttons.push(
        <Tooltip key="tp-detailed-pdf" placement="topLeft" title="Detaild PDF">
          <Button
            key="detailed-pdf"
            icon={<FilePdfOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handlePrintPdf(false);
            }}
            loading={loadingDetailedPdf}
          ></Button>
        </Tooltip>
      );
      /* buttons.push(
        <Tooltip
          key="tp-detailed-excel"
          placement="topLeft"
          title="Detaild Excel"
        >
          <Button
            key="excel"
            icon={<FileExcelOutlined />}
            onClick={handleExportExcel}
            loading={loadingExcel}
          ></Button>
        </Tooltip>
      ); */
      /* buttons.push(
        <Popconfirm
          title="Are you sure to update?"
          onConfirm={handleUpdateDetails}
          key="con-complete"
        >
          <Button
            key="btn-complete"
            htmlType="button"
            loading={loadingDetailsUpdate}
          >
            Update
          </Button>
        </Popconfirm>
      ); */
    }
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Popconfirm
          title="Are you sure to confirm this delivery?"
          onConfirm={handleFormSumbit}
          key="update-confirm"
        >
          {stockIssue.orderStatus !== "ISSUED" && (
            <Button
              key="update"
              htmlType="submit"
              type="primary"
              loading={loadingUpdate}
              disabled={
                stockIssueLines.flatMap((item) => item.issuedItems).length !=
                receivedStocks.length
              }
            >
              Delivery Confirm
            </Button>
          )}
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const getLineColumns = () => {
    const lineColumns = [];
    lineColumns.push({
      title: "Request No.",
      key: "requestNumber",
      width: "10%",
      render: (text, record) => {
        return record.requestLine.issueRequest.requestNumber;
      },
    });
    lineColumns.push({
      title: "Material Type",
      key: "stockTypeCode",
      width: "15%",
      render: (text, record) => {
        return record.requestLine.stockType.code;
      },
    });
    lineColumns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={record.requestLine.stockType.name}
          >
            {record.requestLine.stockType.name}
          </Tooltip>
        );
      },
    });
    lineColumns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record, index) => {
        return (
          record.requestLine.lotNumber && record.requestLine.lotNumber.number
        );
      },
    });
    lineColumns.push({
      title: "Req. Rolls/Box",
      dataIndex: "requestedTotalItems",
      key: "requestedTotalItems",
      align: "right",
    });
    lineColumns.push({
      title: "Req. Qty",
      dataIndex: "requestedQty",
      key: "requestedQty",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    lineColumns.push({
      title: "Issued Rolls/Box",
      key: "issuedBoxes",
      width: "10%",
      align: "right",
      render: (text, record) => {
        return record.issuedQuantity;
      },
    });
    lineColumns.push({
      title: "Issued Quantity",
      dataIndex: "issuedQty",
      key: "issuedQty",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    if (["ISSUED", "COMPLETE"].includes(stockIssue.orderStage)) {
      lineColumns.push({
        title: "Received Rolls/Box",
        key: "receivedQuantity",
        width: "10%",
        align: "right",
        render: (text, record) => {
          return record.receivedQuantity && record.receivedQuantity;
        },
      });
    }
    if (["ISSUED", "COMPLETE"].includes(stockIssue.orderStage)) {
      lineColumns.push({
        title: "Received Quantity",
        dataIndex: "receivedQty",
        key: "receivedQty",
        align: "right",
        render: (text, record) => {
          return !isNaN(text) && Number(text).toFixed(2);
        },
      });
    }
    lineColumns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "5%",
      render: (text, record, index) => {
        return record.measurementType && record.measurementType.name;
      },
    });

    return lineColumns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Roll/Box No:",
      dataIndex: "runningNo",
      key: "runningNo",
      /* align: "right", */
    });
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "UNLOADED") {
          color = "#87d068";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Net Weight",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross (Sq.m)",
      dataIndex: "grossValueSqm",
      key: "grossValueSqm",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "UOM",
      //dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag
                  color={`${
                    stockIssue.orderStage === "ISSUED"
                      ? "#2db7f5"
                      : stockIssue.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {stockIssue.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[{ required: true }]}
                  >
                    <Select placeholder="Please select" disabled>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issuedDate"
                    label="Issue Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issueNumber"
                    label="Issue No"
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="vehicleNumber" label="Vehicle Number">
                    <Input placeholder="Vehicle Number" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverName" label="Driver Name">
                    <Input placeholder="Driver Name" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="contactNumber" label="Contact Number">
                    <Input placeholder="Contact Number" disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="driverNic" label="Driver NIC">
                    <Input placeholder="Driver NIC" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={3}>
                  <Form.Item name="sealNumber" label="Seal No:">
                    <Input placeholder="Seal No:" disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="dispatchNumber" label="Dispatch No:">
                    <Input placeholder="Dispatch No:" disabled />
                  </Form.Item>
                </Col>

                <Col span={18}>
                  <Form.Item name="remark" label="Delivery Remark">
                    <TextArea disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  {stockIssue.orderStatus !== "ISSUED" && (
                    <Form.Item
                      name="deliveredDate"
                      label="Received Date/Time"
                      rules={[
                        {
                          required: true,
                          message: "Delivered date is required",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        placeholder="Issue date"
                        format="YYYY-MM-DD hh:mm:ss"
                        style={{ width: "100%" }}
                        showTime
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={18}>
                  <Form.Item name="deliveryRemark" label="Received Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item name="stockItem">
                    <Input.Search
                      loading={loadingStockItem}
                      placeholder="Enter valid barcode"
                      allowClear
                      enterButton="Search & Add Stock"
                      onSearch={onSearchAndAddStockItem}
                      style={{ marginBottom: 20 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Table
                bordered
                pagination={false}
                columns={getLineColumns()}
                dataSource={stockIssueLines}
                rowSelection={{
                  type: "radio",
                  ...issueLineRowSelection,
                }}
                size="small"
                rowClassName={(record) =>
                  record.addedAll ? "ant-tag-green" : ""
                }
                summary={(pageData) => {
                  let totalRequestedItems = 0;
                  let totalRequestedQty = 0;
                  let totalIssuedItems = 0;
                  let totalIssuedQty = 0;
                  let totalReceivedItems = 0;
                  let totalReceivedQty = 0;

                  pageData.forEach(
                    ({
                      requestedTotalItems,
                      requestedQty,
                      issuedQuantity,
                      issuedQty,
                      receivedQuantity,
                      receivedQty,
                    }) => {
                      totalRequestedItems += requestedTotalItems;
                      totalRequestedQty += requestedQty;
                      totalIssuedItems += issuedQuantity;
                      totalIssuedQty += issuedQty;
                      totalReceivedItems += receivedQuantity;
                      totalReceivedQty += receivedQty;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Text strong>Total</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalRequestedItems) && totalRequestedItems}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalRequestedQty) &&
                              Number(totalRequestedQty).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalIssuedItems) && totalIssuedItems}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalIssuedQty) &&
                              Number(totalIssuedQty).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalReceivedItems) && totalReceivedItems}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text strong>
                            {!isNaN(totalReceivedQty) &&
                              Number(totalReceivedQty).toFixed(2)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
              {stockIssueLines.length > 0 && (
                <>
                  <Divider orientation="left">
                    Stock Items -{" "}
                    {stockIssue.orderStage === "ISSUED" && (
                      <Checkbox onChange={handleAllUnloadChange}>
                        Unload All
                      </Checkbox>
                    )}
                  </Divider>
                  <Tabs onChange={handleTabChange} activeKey={activeTabKey}>
                    {getTabContent(stockIssueLines)}
                  </Tabs>
                </>
              )}
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default PrymSummary;
