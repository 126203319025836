import {
  Button,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Select,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { companyService, extraService } from "_services";

const Upload = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [selectedcustomer, setSelectedcustomer] = useState(null);
  const [stores, setStores] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const isLoggedInCustomer = () => {
    return Auth.hasRole(Auth.getProfile(), ["CAU"]);
  };

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        setSelectedStore(x.content[0]);
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        customerAddress: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setCustomerAddresses(officeAddresses);
      setcustomers([data.content]);
      setSelectedcustomer(data.content);
      setIsLoading(false);
    });
  }, []);

  const getStoreAddresses = (companyId) => {
    form.resetFields(["storeAddress"]);

    companyService.getById(companyId).then((x) => {
      setSelectedStore(x.content);
      setStoreAddresses(
        x.content.addresses.filter(
          (address) => address.addressType === "WAREHOUSE"
        )
      );
    });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);

        const payload = {
          counterRefNumber: values.counterRefNumber
            ? `${selectedcustomer && selectedcustomer.code}-COUNT-${values.counterRefNumber
            }`
            : null,
          customerId: values.customer.id,
          customerAddressId: values.customerAddress.id,
          storeId: values.store.id,
          storeAddressId: values.storeAddress.id,
          base64FileString: base64File,
          originalFileName: originalFileName,
          counterDate: moment(values.counterDate).format("YYYY-MM-DD HH:mm:ss"),
          remark: values.remark,
        };

        console.log("PL: ", payload);
        extraService
          .uploadStockCount(payload)
          .then(() => {
            message.success("Successfully uploaded!");
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    document.location.reload();
    //history.push("/stock-count");
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setOriginalFileName(e.target.files[0].name);
      let reader = new FileReader();
      reader.onload = function (e) {
        setBase64File(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to proceed?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
      >
        <Button
          key="btn-upload"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Upload
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Stock Count</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: true, message: "Store is required" }]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      onChange={getStoreAddresses}
                    >
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" disabled allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="counterDate"
                    label="Stock Count Date"
                    rules={[
                      { required: true, message: "Counter date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Counter date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="counterRefNumber"
                    label="Ref Number"
                    rules={[{ required: true, message: "Ref Number" }]}
                  >
                    <Input
                      prefix={`${selectedcustomer && selectedcustomer.code
                        }-COUNT-`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["storeAddress", "id"]}
                    label="Store Address"
                    rules={[
                      {
                        required: true,
                        message: "Store address is required",
                      },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customerAddress", "id"]}
                    label="Customer Address"
                    rules={[
                      {
                        required: true,
                        message: "Customer Address is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      disabled={isLoggedInCustomer()}
                    >
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="remark" label="Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="file"
                    label="Stock Counter File"
                    rules={[
                      {
                        required: true,
                        message: "Valid excel file is required",
                      },
                    ]}
                    valuePropName="file"
                  >
                    <Input type="file" name="file" onChange={onFileChange} allowClear />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingTop: 40 }}>
                  <>
                    {["SILUETA"].includes(loggedInCustomerCode) && (
                      <CSVLink
                        key="1"
                        data={[]}
                        filename={"Sample-StockCounter-File.csv"}
                        headers={[
                          { label: "Material", key: "material" },
                          { label: "Material Desc", key: "materialDesc" },
                          { label: "Vendor Batch", key: "vendorBatch" },
                          { label: "Batch", key: "batch" },
                          { label: "Qty", key: "Qty" },
                          { label: "BNS Location", key: "bNSLocation" },
                          { label: "Location", key: "location" },
                          { label: "Plant", key: "plant" },
                          { label: "UOM", key: "uOM" },
                          { label: "Stock Class", key: "class" },
                          { label: "Packing Type", key: "packingType" },
                        ]}
                      >
                        Sample Upload File
                      </CSVLink>
                    )}
                    {["STR_GR"].includes(loggedInCustomerCode) && (
                      <CSVLink
                        key="1"
                        data={[]}
                        filename={"Sample-StockIn-File.csv"}
                        headers={[
                          { label: "Item Code", key: "itemCode" },
                          { label: "Item Desc", key: "itemDesc" },
                          { label: "Batch", key: "batch" },
                          { label: "No Box", key: "noBox" },
                          { label: "Net Weight", key: "netWeight" },
                          { label: "Gross Weight", key: "grossWeight" },
                          { label: "Location", key: "location" },
                        ]}
                      >
                        Sample Upload File
                      </CSVLink>
                    )}
                  </>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default Upload;
