import {
  Button,
  Form,
  PageHeader,
  Row,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
  Table,
  Typography,
  Drawer,
  InputNumber,
  Divider,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { extraService } from "_services";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import moment from "moment";

const StrRmImageUpload = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFailedImageSelected, setIsFailedImageSelected] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [failedItems, setFailedItems] = useState([]);
  const [error, setError] = useState(null);
  const { Text } = Typography;

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const getColumns = () => {
    let columns = [];
    columns.push({
      title: "TUB Number",
      dataIndex: "tubNo",
      key: "tubNo",
    });
    columns.push({
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
    });
    columns.push({
      title: "Machine",
      dataIndex: "loom",
      key: "loom",
    });
    columns.push({
      title: "Production Date",
      dataIndex: "productionDate",
      key: "productionDate",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
    });
    columns.push({
      title: "Gross.W",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
    });
    columns.push({
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    });
    columns.push({
      title: "",
      key: "action",
      width: "6%",
      render: (text, record) => {
        return (
          <span>
            <Button
              icon={<EditOutlined />}
              title="Edit"
              type="link"
              style={{ marginRight: 10 }}
              onClick={() => handleEdit(record.id)}
            ></Button>
          </span>
        );
      },
    });

    return columns;
  };

  const getFailedColumns = () => {
    let columns = [];
    columns.push({
      title: "TUB Number",
      dataIndex: "tubNo",
      key: "tubNo",
    });
    columns.push({
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
    });
    columns.push({
      title: "Machine",
      dataIndex: "loom",
      key: "loom",
    });
    columns.push({
      title: "Production Date",
      dataIndex: "productionDate",
      key: "productionDate",
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
    });
    columns.push({
      title: "Gross.W",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
    });
    columns.push({
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    });
    columns.push({
      title: "",
      key: "action",
      width: "6%",
      render: (text, record) => {
        return (
          <span>
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() => handleFailedItemDelete(record.id)}
              key="pop-failed-del"
              style={{ marginRight: 10 }}
            >
              <CloseOutlined />
            </Popconfirm>
            <Button
              icon={<EditOutlined />}
              title="Edit"
              type="link"
              style={{ marginRight: 10 }}
              onClick={() => handleEditFailedImage(record.id)}
            ></Button>
          </span>
        );
      },
    });

    return columns;
  };

  useEffect(() => {
    setIsLoading(true);
    extraService
      .getUploadedImages()
      .then((data) => {
        setIsLoading(false);
        setItems(data.content);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
    extraService
      .getFailedUploadedImages()
      .then((data) => {
        setIsLoading(false);
        setFailedItems(data.content);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (!selectedFiles) {
          return message.error("Please select files first.");
        }

        setIsUploading(true);

        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("files", selectedFiles[i]);
        }

        extraService
          .uploadImages(formData)
          .then(() => {
            message.success("Successfully uploaded!");
            setIsUploading(false);
            window.location.reload();
          })
          .catch((error) => {
            setIsUploading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onSearch = (values) => {
    setIsSearching(true);
    let searchParams = "";
    if (values.tubNo) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `tubNo=${encodeURIComponent(values.tubNo)}`;
    }
    if (values.quality) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `quality=${encodeURIComponent(values.quality)}`;
    }
    if (values.loom) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `loom=${encodeURIComponent(values.loom)}`;
    }

    extraService
      .searchUploadedImages(searchParams)
      .then((data) => {
        setItems(data.content);
        setIsSearching(false);
      })
      .catch((error) => {
        message.error(`${error}`);
        setItems([]);
        setIsSearching(false);
      });
  };

  const handleEdit = (rowId) => {
    const productImage = items.find((line) => line.id === rowId);
    editForm.setFieldsValue(productImage);
    setDrawerVisible(true);
  };

  const handleEditFailedImage = (rowId) => {
    setIsFailedImageSelected(true);
    const productImage = failedItems.find((line) => line.id === rowId);
    editForm.setFieldsValue(productImage);
    setDrawerVisible(true);
  };

  const handleFailedItemDelete = (id) => {
    extraService
      .deleteFailedUploadedImage(id)
      .then((x) => {
        message.success("Successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleDeleteAllFailedItems = (id) => {
    extraService
      .deleteAllFailedUploadedImages()
      .then((x) => {
        message.success("Successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleUpdateItem = () => {
    editForm
      .validateFields()
      .then((values) => {
        setIsUpdating(true);
        values.productionDate = moment(values.productionDate).format(
          "YYYY-MM-DD"
        );

        if (isFailedImageSelected) {
          extraService
            .updateFailedUploadedImage(values.id, values)
            .then((data) => {
              message.success("Successfully updated!");
              setIsUpdating(false);
              onCloseDrawer();
              window.location.reload();
            })
            .catch((error) => {
              message.error(`${error}`);
              setIsUpdating(false);
              onCloseDrawer();
            });
        } else {
          extraService
            .updateUploadedImage(values.id, values)
            .then((data) => {
              message.success("Successfully updated!");
              setIsUpdating(false);
              onCloseDrawer();
              window.location.reload();
            })
            .catch((error) => {
              message.error(`${error}`);
              setIsUpdating(false);
              onCloseDrawer();
            });
        }
      })
      .catch((errorInfo) => {});
  };

  const handleDownloadFile = (documentObj) => {
    setIsDownloading(true);
    extraService
      .downloadImageDetails()
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock_Receipt_Details_${new Date().toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        setError(error);
      });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    extraService
      .deleteUploadedImages()
      .then((x) => {
        setIsDeleting(false);
        message.success("Successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];

    if (failedItems.length > 0) {
      buttons.push(
        <Popconfirm
          title="Are you sure to delete all these records?"
          onConfirm={handleDeleteAllFailedItems}
          key="delete-all-confirm"
        >
          <Button
            key="delete-all"
            type="primary"
            htmlType="submit"
            loading={isDeleting}
          >
            Clear All (Failed)
          </Button>
        </Popconfirm>
      );
    }
    if (items.length > 0) {
      buttons.push(
        <Popconfirm
          title="Are you sure to delete all these records?"
          onConfirm={handleDelete}
          key="delete-confirm"
        >
          <Button
            key="delete"
            type="primary"
            htmlType="submit"
            loading={isDeleting}
          >
            Clear All (Success)
          </Button>
        </Popconfirm>
      );
      buttons.push(
        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          onClick={handleDownloadFile}
          loading={isDownloading}
        ></Button>
      );
    }
    buttons.push(
      <Popconfirm
        title="Are you sure to add the changes?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
      >
        <Button key="1" type="primary" htmlType="submit" loading={isUploading}>
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const onCloseDrawer = () => {
    if (!isUpdating) {
      setIsFailedImageSelected(false);
      editForm.resetFields();
      setDrawerVisible(false);
    }
  };

  const onCancel = () => {
    history.push("/packing-lists");
  };

  const onFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Stock Receipt Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Receipt Image Upload</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Form
            form={searchForm}
            onFinish={onSearch}
            layout="vertical"
            className="search-form"
          >
            <Row gutter={24}>
              <Col gutter={5}>
                <Form.Item name="tubNo" label="TUB ID">
                  <Input.Search placeholder="TUB ID" allowClear />
                </Form.Item>
              </Col>
              <Col gutter={5}>
                <Form.Item name="quality" label="Quality">
                  <Input.Search placeholder="Quality" allowClear />
                </Form.Item>
              </Col>
              <Col gutter={5}>
                <Form.Item name="loom" label="Machine">
                  <Input.Search placeholder="Machine" allowClear />
                </Form.Item>
              </Col>
              <Col gutter={5}></Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <div>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => {
                      searchForm.resetFields();
                      onSearch({});
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="file"
                    label="Image Files"
                    rules={[
                      {
                        required: true,
                        message: "Valid image file/s is required",
                      },
                    ]}
                    valuePropName="file"
                  >
                    <Input
                      type="file"
                      name="file"
                      multiple
                      onChange={onFileChange}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
            </Form>

            {failedItems.length > 0 && (
              <>
                <Divider orientation="left">
                  Failed Images - ({failedItems.length})
                </Divider>
                <Table
                  bordered
                  loading={isLoading}
                  rowKey="id"
                  size="small"
                  columns={getFailedColumns()}
                  dataSource={failedItems}
                  pagination={false}
                />
              </>
            )}

            <Divider orientation="left">
              Success Images - ({items.length})
            </Divider>
            <Table
              bordered
              loading={isLoading}
              rowKey="id"
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={(pageData) => {
                let totalNetWeightSum = 0;
                let totalGrossWeightSum = 0;

                pageData.forEach(({ netWeight, grossWeight }) => {
                  if (netWeight) {
                    let cleanedNetWeight = null;
                    if (typeof netWeight === "string") {
                      cleanedNetWeight = Number(netWeight.replace(",", "."));
                    } else {
                      cleanedNetWeight = Number(netWeight);
                    }

                    if (!isNaN(cleanedNetWeight)) {
                      totalNetWeightSum += cleanedNetWeight;
                    } else {
                      totalNetWeightSum += 0; // Add 0 for invalid netWeight values
                    }
                  } else {
                    totalNetWeightSum += 0; // Add 0 for null or undefined netWeight
                  }

                  if (grossWeight) {
                    let cleanedGrossWeight = null;
                    if (typeof grossWeight === "string") {
                      cleanedGrossWeight = Number(
                        grossWeight.replace(",", ".")
                      );
                    } else {
                      cleanedGrossWeight = Number(grossWeight);
                    }

                    if (!isNaN(cleanedGrossWeight)) {
                      totalGrossWeightSum += cleanedGrossWeight;
                    } else {
                      totalGrossWeightSum += 0; // Add 0 for invalid netWeight values
                    }
                  } else {
                    totalGrossWeightSum += 0; // Add 0 for null or undefined netWeight
                  }
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3}>
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{items.length}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        {totalNetWeightSum.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        {totalGrossWeightSum.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Spin>
          <Drawer
            title={`Edit ${
              isFailedImageSelected ? "Failure" : "Success"
            } Record`}
            width={360}
            onClose={onCloseDrawer}
            visible={drawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              name="edit-row"
              layout="vertical"
              hideRequiredMark
              form={editForm}
            >
              <Form.Item hidden name="id">
                <Input type="hidden" />
              </Form.Item>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="tubNo"
                    label="TUB ID"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid TUB ID",
                      },
                    ]}
                  >
                    <Input placeholder="TUB ID" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="quality"
                    label="Quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid quantity",
                      },
                    ]}
                  >
                    <Input placeholder="Quantity" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="loom"
                    label="Machine"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Machine",
                      },
                    ]}
                  >
                    <Input placeholder="Machine" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="productionDate"
                    label="Production Date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Production Date",
                      },
                    ]}
                  >
                    {/* <DatePicker
                      placeholder="Production Date"
                      style={{ width: "100%" }}
                    /> */}
                    <Input placeholder="Production Date" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="netWeight"
                    label="Net Weight"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Net Weight",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Net Weight" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="grossWeight"
                    label="Gross Weight"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Gross Weight",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gross Weight" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button onClick={onCloseDrawer} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button
                onClick={handleUpdateItem}
                type="primary"
                loading={isUpdating}
              >
                Submit
              </Button>
            </div>
          </Drawer>
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrRmImageUpload;
