import { fetchWrapper } from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}`;

export const stockCheckService = {
  search,
  create,
  getById,
  getByBinId,
  getByPalletId,
  update,
  verify,
  verifyItem,
  verifyPallet,
  verifyBin,
  printPdf,
  printSummaryPdf,
  delete: _delete,
  stockItemSearch,
  exportExcel,
  addLocations,
  getByLocationId,
  createLocationCount,
  createLocationRecount,
  printLocationPdf,
  performAdjustment,
  performSignOff,
  getLocationByBinCode,
  saveSummaryCountScan,
  refresh,
  uploadStockCheck,
  searchStockCheckUpload,
  getByStockCheckUploadId,
  getNotFoundItems,
};

function search(params) {
  return fetchWrapper.get(`${baseUrl}/stockCheck${params}`);
}

function stockItemSearch(stockCheckId, params) {
  return fetchWrapper.get(
    `${baseUrl}/stockItemCheck/${stockCheckId}/search${params}`
  );
}

function create(params) {
  return fetchWrapper.post(`${baseUrl}/stockCheck`, params);
}

function addLocations(id, params) {
  return fetchWrapper.post(`${baseUrl}/stockCheck/${id}/locations`, params);
}

function createLocationCount(id, locationId, params) {
  return fetchWrapper.post(`${baseUrl}/stockCheck/${id}/locations/${locationId}/count`, params);
}

function createLocationRecount(id, locationId) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/locations/${locationId}/recount`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}`);
}

function getByBinId(stockCheckId, binId) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${stockCheckId}/bin/${binId}`);
}

function getByPalletId(stockCheckId, palletId) {
  return fetchWrapper.get(
    `${baseUrl}/stockCheck/${stockCheckId}/pallet/${palletId}`
  );
}

function getByLocationId(id, locationId) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/locations/${locationId}`);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/stockCheck/${id}`, params);
}

function verify(type, stockCheckId, params) {
  if (type === 'Item') {
    return verifyItem(stockCheckId, params);
  } else if (type === 'Pallet') {
    return verifyPallet(params);
  } else if (type === 'Bin') {
    return verifyBin(params);
  }
}

function verifyItem(stockCheckId, params) {
  return fetchWrapper.get(`${baseUrl}/stockItemCheck/${stockCheckId}${params}`);
}

function verifyPallet(params) {
  return fetchWrapper.get(`${baseUrl}/stockItemCheck/pallet${params}`);
}

function verifyBin(params) {
  return fetchWrapper.get(`${baseUrl}/stockItemCheck/bin${params}`);
}

function printPdf(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/pdf`, {
    responseType: 'blob',
  });
}

function printSummaryPdf(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/summary-pdf`, {
    responseType: 'blob',
  });
}

function printLocationPdf(id, locationId) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/locations/${locationId}/pdf`, {
    responseType: 'blob',
  });
}

function exportExcel(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/excel`, {
    responseType: 'blob',
  });
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/stockCheck/${id}`);
}

function performAdjustment(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/adjust`);
}

function performSignOff(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/signoff`);
}

function getLocationByBinCode(id, binCode) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/locations/bin/${binCode}`);
}

function saveSummaryCountScan(id, params) {
  return fetchWrapper.put(`${baseUrl}/stockCheck/${id}/locationCount`, params);
}

function refresh(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/${id}/refresh`);
}

function uploadStockCheck(params) {
  return fetchWrapper.post(`${baseUrl}/stockCheck/upload`, params);
}

function searchStockCheckUpload(params) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/upload${params}`);
}

function getByStockCheckUploadId(id) {
  return fetchWrapper.get(`${baseUrl}/stockCheck/upload/${id}`);
}

function getNotFoundItems() {
  return fetchWrapper.get(`${baseUrl}/stockCheck/notMatchedAndExistInSystem`);
}
