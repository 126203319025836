import {
  Breadcrumb,
  PageHeader,
  Table,
  Tooltip,
  Typography,
  message,
  Statistic,
  Row,
  Col,
  Card,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
//import { PDFDownloadLink } from "@react-pdf/renderer";
import Page from "_components/Page";
//import { PdfAvailableStocks } from "./PdfAvailableStocks";
import {
  reportService,
  companyService,
  materialTypeService,
  lotNumberService,
  supplierService,
  sbuService,
  zoneService,
  subLocationService,
} from "_services";
import { Auth } from "_helpers";
import AvailableStockSearch from "./AvailableStockSearch";
import { BarcodeOutlined, FileExcelOutlined } from "@ant-design/icons";

/* const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
}); */

const StrRmAvailableStocks = () => {
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingBarcodes, setLoadingBarcodes] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [materialSuppliers, setMaterialSuppliers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [selectedSbuCode, setSelectedSbuCode] = useState(null);
  const { Title } = Typography;
  const precision = 2;

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setAddresses(storeLocationAddresses);
          // getItems(storeLocationAddresses.map((item) => item.id).join());
        });
      }
      //setCompanies(x.content);
    });
    getStockTypes();
    getLotNumbers();
    getMaterialSuppliers();
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getMaterialSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setMaterialSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getItems = (storeLocationIds) => {
    setLoading(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    searchParams += `fromDate=${encodeURIComponent(
      moment("2021-01-01").format("YYYY-MM-DD")
    )}&toDate=${moment().format(
      "YYYY-MM-DD"
    )}&storeLocations=${storeLocationIds}`;
    setSearchQuery(searchParams);
    reportService
      .getAvailableStocks(searchParams)
      .then((data) => {
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
          });
          setItems(data.content);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (
    sbuCode,
    results,
    fromDate,
    toDate,
    isFilterEnabled,
    searchParams
  ) => {
    setSelectedSbuCode(sbuCode);
    setItems(results);
    setFromDate(fromDate);
    setToDate(toDate);
    setDateFilter(isFilterEnabled);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    reportService
      .exportExcel(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Available-Stock-Report_${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const handleExportBarcodeExcel = () => {
    setLoadingBarcodes(true);
    reportService
      .exportBarcodeExcel(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Barcodes-Stock-Report_${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBarcodes(false);
      })
      .catch((error) => {
        setLoadingBarcodes(false);
        message.error(error);
      });
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Material",
      dataIndex: "stockTypeCode",
      key: "stockTypeCode",
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
    });
    columns.push({
      title: "Supplier",
      dataIndex: "materialSupplier",
      key: "materialSupplier",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={`${record.materialSupplier} (${record.materialSupplierCode})`}
          >
            {record.materialSupplier}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Boxes",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "10%",
    });
    if (selectedSbuCode === "GP") {
      columns.push({
        title: "Width",
        dataIndex: "width",
        key: "width",
        align: "right",
        width: "10%",
      });
      columns.push({
        title: "Color Code",
        dataIndex: "colorCode",
        key: "colorCode",
        align: "right",
        width: "10%",
        render: (text, record) => {
          return text && `COM${text}`;
        },
      });
    } else {
      columns.push({
        title: "Cones",
        dataIndex: "grossQuantity",
        key: "grossQuantity",
        align: "right",
        width: "10%",
      });
    }
    columns.push({
      title: "Net Weight (Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "10%",
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });
    columns.push({
      title: "Gross Weight (Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "10%",
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Available Stocks</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <AvailableStockSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              addresses={addresses}
              stockTypes={stockTypes}
              lotNumberList={lotNumberList}
              materialSupplierList={materialSuppliers}
              subLocations={subLocations}
              zones={zones}
              businessUnits={businessUnits}
            />
            {!dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: {moment().format("YYYY-MM-DD")}
              </Title>
            )}
            {dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: From:{moment(fromDate).format("YYYY-MM-DD")} - To:
                {moment(toDate).format("YYYY-MM-DD")}
              </Title>
            )}
            {items && items.length > 0 && (
              <div
                style={{
                  background: "#2d3147",
                  padding: "15px",
                  marginBottom: 15,
                }}
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Total Stocks(Box)"
                        value={items
                          .map((item) => item.quantity)
                          .reduce((prev, next) => prev + next)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Total Net Weight (Kg)"
                        value={Number(
                          items
                            .map((item) => item.netWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Statistic
                        title="Total Gross Weight (Kg)"
                        value={Number(
                          items
                            .map((item) => item.grossWeight)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                        valueStyle={{ color: "#3f8600" }}
                      />
                    </Card>
                  </Col>
                  <Col span={3}>
                    {selectedSbuCode !== "GP" && (
                      <Card>
                        <Statistic
                          title="Total Cones"
                          value={items
                            .map((item) => item.grossQuantity)
                            .reduce((prev, next) => prev + next)}
                          valueStyle={{ color: "#3f8600" }}
                        />
                      </Card>
                    )}
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={handleExportExcel}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FileExcelOutlined />}
                      loading={loadingExcel}
                    >
                      Export to Excel
                    </Button>
                    <br />
                    <Button
                      type="primary"
                      onClick={handleExportBarcodeExcel}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<BarcodeOutlined />}
                      loading={loadingBarcodes}
                    >
                      Barcodes
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalCone = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(
                  ({ quantity, grossQuantity, netWeight, grossWeight }) => {
                    totalQuantity += quantity;
                    totalCone += grossQuantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={getColumns().length - 4}>
                        <Typography.Text strong>Total</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalQuantity}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      {selectedSbuCode !== "GP" && (
                        <Table.Summary.Cell align="right">
                          <Typography.Text strong>{totalCone}</Typography.Text>
                        </Table.Summary.Cell>
                      )}
                      {selectedSbuCode === "GP" && (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalNetWeight &&
                            Number(totalNetWeight).toFixed(precision)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalGrossWeight &&
                            Number(totalGrossWeight).toFixed(precision)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default StrRmAvailableStocks;
