import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  message,
  Popconfirm,
  Tag,
  Modal,
  Tooltip,
} from "antd";
import {
  CloseOutlined,
  FileExcelOutlined,
  BarcodeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Page from "_components/Page";
import { extraService, purchaseOrderService } from "_services";
import Search from "./Search";
import ExportExcel from "_helpers/ExportExcel";
import "../style.css";

const StrRmList = ({ match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingBarcode, setLoadingBarcode] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState(
    "?orderStages=OPEN,UPDATE,BARCODING,VALIDATE,BIN_MOVE"
  );
  const [barcodeContent, setBarcodeContent] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const componentRef = useRef();

  useEffect(() => {
    getPurchaseOrders(pageNumber, pageSize);
  }, []);

  const getPurchaseOrders = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    purchaseOrderService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        setPageNumber(pageNumber);
      })
      .catch((error) => {
        //this.setState({ loading: false, error });
        setLoading(false);
      });
  };

  const handleSearchResults = (results, totalRecords, searchParams) => {
    setItems(results);
    setTotalRecords(totalRecords);
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    getPurchaseOrders(pageNumber, pageSize);
  };

  const handleDelete = (id) => {
    purchaseOrderService
      .delete(id)
      .then((x) => {
        message.success("Successfully deleted!");
        getPurchaseOrders(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handlePrintBarcode = (stockPreReceipt) => {
    setSelectedItem(stockPreReceipt);
    setLoadingBarcode(true);
    /* purchaseOrderService
      .printBarcode(stockPreReceipt.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Barcodes_${stockPreReceipt.preReceiptNumber}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBarcode(false);
      })
      .catch(() => {
        setLoadingBarcode(false);
      }); */
    extraService
      .getBarcode()
      .then((respose) => {
        setBarcodeContent(respose.content);
        setIsModalVisible(true);
        setLoadingBarcode(false);
      })
      .catch(() => {
        setLoadingBarcode(false);
      });
  };

  /* const handleOk = () => {
    window.print();
    setIsModalVisible(false);
  }; */
  const handleOk = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePrintExcel = async (packingListId) => {
    const purchaseOrderData = await purchaseOrderService.getById(packingListId);
    const columns = [
      {
        title: "Material",
        dataIndex: "stockTypeCode",
      },
      {
        title: "Material Desc",
        dataIndex: "stockTypeName",
      },
      {
        title: "Plant",
        dataIndex: "plant",
      },
      {
        title: "Batch",
        dataIndex: "runningNo",
      },
      {
        title: "Vendor Batch",
        dataIndex: "lotNumber",
      },
      {
        title: "Quantity",
        dataIndex: "grossWeight",
      },
      {
        title: "Location",
        dataIndex: "storeLocation",
      },
      {
        title: "Bin",
        dataIndex: "stockBin",
      },
      {
        title: "Status",
        dataIndex: "mode",
      },
    ];
    /* const totalIssuedStocks = stockIssueLines.flatMap(
      (item) => item.issuedItems
    );
    const data = totalIssuedStocks.map((item) => {
      const container = {};
      container.stockTypeCode = item.stockType.code;
      container.stockTypeName = item.stockType.name;
      container.plant = item.plant.name;
      container.lotNumber = item.lotNumber.number;
      container.runningNo = item.runningNo;
      container.mode = item.mode;
      container.grossWeight = item.grossWeight;
      container.storeLocation = item.stockBin.storeLocation.code;
      container.stockBin = item.stockBin.code;

      return container;
    });
    ExportExcel(
      columns,
      data,
      `Stock-Delivery-${stockIssue.issueNumber}`,
      `${stockIssue.issueNumber}`
    ); */
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    purchaseOrderService
      .exportExcel(searchParams)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Stock-Receipt_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        key="excel"
        icon={<FileExcelOutlined />}
        onClick={handleExportExcel}
        loading={loadingExcel}
      ></Button>
    );
    /* buttons.push(
      <Link to={`${path}/add`} key='btn-add'>
        <Button>Add</Button>
      </Link>
    ); */
    /* buttons.push(
      <Link to={`${path}/upload-variance`} key='btn-varience-upload'>
        <Button>Upload Varience</Button>
      </Link>
    ); */
    buttons.push(
      <Link to={`${path}/upload`} key="btn-upload">
        <Button>Upload</Button>
      </Link>
    );
    buttons.push(
      <Link to={`${path}/image-upload`} key="btn-img-upload">
        <Button>Image Upload</Button>
      </Link>
    );
    /* buttons.push(<ReactToPrint
      trigger={() => <button>Print this out!</button>}
      content={() => componentRef.current}
    />) */
    return buttons;
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "preReceiptNumber",
      key: "preReceiptNumber",
      width: "14%",
      render: (text, record) => (
        <Link to={`/packing-lists/${record.id}/summary`}>{text}</Link>
      ),
    },
    {
      title: "Supplier",
      key: "supplier",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        if (record.supplier) {
          return (
            <Tooltip placeholder="topLeft" title={record.supplier.name}>
              {record.supplier.code.includes('GP') ? record.supplier.name : record.supplier.code}
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "packingStatus",
      key: "packingStatus",
    },
    {
      title: "Received Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: "8%",
      render: (text, record) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Receiving Person",
      dataIndex: "receivingPerson",
      key: "receivingPerson",
    },
    {
      title: "Ref.Number",
      key: "refNumber",
      dataIndex: "refNumber",
    },
    {
      title: "Inv. Boxes",
      key: "numberOfItems",
      align: "right",
      render: (text, record) =>
        record.stockPreReceiptLines
          .map((item) => item.quantity)
          .reduce((prev, next) => prev + next),
    },
    {
      title: "Inv. Net.W",
      key: "netWeight",
      align: "right",
      render: (text, record) =>
        Number(
          record.stockPreReceiptLines
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    },
    {
      title: "Inv. Gross.W",
      key: "grossWeight",
      align: "right",
      render: (text, record) =>
        Number(
          record.stockPreReceiptLines
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    },
    {
      title: "Rec’d. Boxes",
      key: "receivedItems",
      align: "right",
      render: (text, record) =>
        record.stockPreReceiptLines
          .map((item) => item.receivedQuantity)
          .reduce((prev, next) => prev + next),
    },
    {
      title: "Rec’d. Net.W",
      key: "receivedNetWeight",
      align: "right",
      render: (text, record) =>
        Number(
          record.stockPreReceiptLines
            .map((item) => item.receivedNetWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    },
    {
      title: "Rec’d. Gross.W",
      key: "receivedGrossWeight",
      align: "right",
      render: (text, record) =>
        Number(
          record.stockPreReceiptLines
            .map((item) => item.receivedGrossWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2),
    },
    {
      title: "Diff Net.W",
      key: "diffNetWeight",
      align: "right",
      render: (text, record) => {
        const invoicedNet = Number(
          record.stockPreReceiptLines
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2);
        const receivedNetWeight = Number(
          record.stockPreReceiptLines
            .map((item) => item.receivedNetWeight)
            .reduce((prev, next) => prev + next)
        ).toFixed(2);
        return <b>{Number(receivedNetWeight - invoicedNet).toFixed(2)}</b>;
      },
    },
    {
      title: "Status",
      dataIndex: "orderStage",
      key: "orderStage",
      render: (orderStatus) => {
        let color = "";
        if (orderStatus === "OPEN") {
          color = "#1677ff";
        }
        if (orderStatus === "UPDATE") {
          color = "#ffc53d";
        }
        if (orderStatus === "BARCODING") {
          color = "#bae637";
        }
        if (orderStatus === "VALIDATE") {
          color = "#36cfc9";
        }
        if (orderStatus === "BIN_MOVE") {
          color = "#b7eb8f";
        }
        if (orderStatus === "COMPLETE") {
          color = "#52c41a";
        }
        return <Tag color={color}>{orderStatus}</Tag>;
      },
    },
    /* {
      title: "Action",
      key: "action",
      width: "3%",
      render: (text, record) => {
        return <span></span>;
      },
    }, */
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Stock Receipt Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <Search
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: false,
              onChange: handlePaginateChange,
            }}
          />
          {/* <Modal
            title='Print Barcode'
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText='Print'
          >
            <div ref={componentRef}>
              <img src={barcodeContent} />
            </div>
          </Modal> */}
        </Page>
      </PageHeader>
    </div>
  );
};

export default StrRmList;
