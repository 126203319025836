import React, { useState } from "react";
import {
  Form,
  InputNumber,
  Input,
  List,
  Button,
  Progress,
  Modal,
  Row,
  Skeleton,
  Col,
} from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";

const StockSubBinSelection = (props) => {
  const orderLine = props.orderLine;
  const receiptLines = props.receiptLines;
  const binStocks = receiptLines.filter(
    (item) => item.orderLineId === orderLine.id
  );
  const [receiptBins] = useState(binStocks);
  const parentBin = props.parentBin;
  const stockBins = parentBin.stockBins;
  //const [stockBins, setStockBins] = useState(parentBin.stockBins);
  //console.log("ParentBin:", props.parentBin.stockBins);
  //console.log("OrderLineId: -----", orderLine.id);
  const [showAddQuantity, setShowAddQuantity] = useState(false);
  const [showAddSubBinQuantity, setShowAddSubBinQuantity] = useState(false);
  const [selectedBin, setSelectedBin] = useState(null);
  const [selectedSubBin, setSelectedSubBin] = useState(null);
  let newlyAddedParentQuantity = 0;
  const modifiedBins = props.parentBin.stockBins.filter(
    (bin) => bin.isNewlyAdded
  );
  if (modifiedBins.length > 0) {
    newlyAddedParentQuantity = modifiedBins
      .map((bin) => bin.totalStocks)
      .reduce((prev, next) => prev + next);
  }
  //console.log("newlyAddedParentQuantity:", newlyAddedParentQuantity);
  const [parentBinQuantity, setParentBinQuantity] = useState(5);
  //console.log("Parent Qty:", parentBinQuantity);
  const [form] = Form.useForm();

  const handleShowAddQuantity = () => {
    /* if (!bin.hasOwnProperty("addedQuantity")) {
      bin.addedQuantity = 0;
    }
    setSelectedBin(bin); */
    setShowAddQuantity(true);
  };

  const handleAcceptedQuantity = (parentBinId, binId, value) => {
    //console.log("BinId: ", binId, ", Qty: ", value);
    let receipt = receiptBins.find((item) => item.binId === binId);
    if (receipt) {
      receipt.accepted = value;
    }
    props.onQuantityChange(parentBinId, orderLine.id, binId, value);
  };

  const handleAddQuantityCancel = () => {
    form.resetFields();
    //setSelectedBin(null);
    setShowAddQuantity(false);
  };

  const handleAddQuantity = () => {
    form
      .validateFields()
      .then((values) => {
        /* let bin = stockBins.find((item) => item.id === selectedBin.id);
        if (bin) {
          let total = values.quantity;
          if (props.receiptLines.length > 0) {
            let totalAccepted = 0;
            props.receiptLines.forEach((obj) => {
              if (obj.binId === bin.id && obj.orderLineId !== orderLine.id) {
                totalAccepted += obj.accepted;
              }
            });
            total =
              totalAccepted === 0 ? total : totalAccepted + values.quantity;
          }

          bin.totalStocks = total + bin.availableStockQuantity;
          handleAcceptedQuantity(parentBin.id, selectedBin.id, values.quantity);
          setSelectedBin(null);
          setShowAddQuantity(false);
        } */
        for (const bin of stockBins) {
          if (
            bin.orderLineId === orderLine.id &&
            bin.isNewlyAdded &&
            bin.totalStocks === 1
          ) {
            bin.totalStocks = 0;
            bin.isNewlyAdded = false;
            handleAcceptedQuantity(parentBin.id, bin.id, 0);
          }
        }
        let quantity = values.quantity;
        if (quantity !== 0) {
          for (const bin of stockBins) {
            if (quantity !== 0 && bin.totalStocks === 0) {
              quantity--;
              bin.totalStocks = 1;
              bin.isNewlyAdded = true;
              bin.orderLineId = orderLine.id;
              handleAcceptedQuantity(parentBin.id, bin.id, 1);
            }
          }
        }
        form.resetFields();
        setShowAddQuantity(false);
      })
      .catch((error) => {});
  };

  /* const handleAddParentBinQuantity = () => {
    let quantity = parseInt(parentBinQuantity);
    for (const bin of stockBins) {
      if (bin.isNewlyAdded && bin.totalStocks === 1) {
        bin.totalStocks = 0;
        handleAcceptedQuantity(parentBin.id, bin.id, 0);
      }
    }
    setStockBins(stockBins);

    console.log("Qauntity:", quantity);
    if (quantity !== 0) {
      for (const bin of stockBins) {
        if (quantity !== 0 && bin.totalStocks === 0) {
          quantity--;
          bin.totalStocks = 1;
          bin.isNewlyAdded = true;
          handleAcceptedQuantity(parentBin.id, bin.id, 1);
        }
      }
    }

    setStockBins(stockBins);
  }; */

  const getModalContent = () => {
    let quantity = null;

    //console.log("receiptBins:", binStocks, " ,Selected:", parentBin);
    if (binStocks && parentBin) {
      parentBin.stockBins.forEach((subBin) => {
        let receipt = binStocks.find((item) => item.binId === subBin.id);
        if (receipt) {
          quantity += receipt.accepted;
          //console.log("Qty: ", quantity);
        }
      });
    }
    //console.log("Render sub bin quantity:", quantity);

    form.setFieldsValue({ quantity: quantity });
    return (
      <Form form={form} name="stock-add-form" layout="inline">
        <Form.Item
          name="quantity"
          rules={[{ required: true, message: "Please input valid quantity!" }]}
        >
          <InputNumber min={0} placeholder="Quantity" />
        </Form.Item>
      </Form>
    );
  };

  const getModalContentForSubBin = () => {
    let quantity = null;
    //console.log("receiptBins:", binStocks);
    if (binStocks && selectedSubBin) {
      let receipt = binStocks.find(
        (item) =>
          item.orderLineId === orderLine.id && item.binId === selectedSubBin.id
      );
      if (receipt) {
        quantity = receipt.accepted;
      }
    }

    form.setFieldsValue({ quantity: quantity });
    return (
      <Form form={form} name="stock-add-form" layout="inline">
        <Form.Item
          name="quantity"
          rules={[{ required: true, message: "Please input valid quantity!" }]}
        >
          <InputNumber min={0} placeholder="Quantity" />
        </Form.Item>
      </Form>
    );
  };

  const handleAddSubBinQuantity = () => {
    form
      .validateFields()
      .then((values) => {
        let quantity = values.quantity;
        if (quantity >= 0) {
          let receipt = binStocks.find(
            (item) =>
              item.orderLineId === orderLine.id &&
              item.binId === selectedSubBin.id
          );
          if (receipt) {
            selectedSubBin.totalStocks =
              selectedSubBin.totalStocks - receipt.accepted;
            handleAcceptedQuantity(
              parentBin.id,
              selectedSubBin.id,
              -receipt.accepted
            );
          }
          selectedSubBin.totalStocks += quantity;
          selectedSubBin.isNewlyAdded = true;
          handleAcceptedQuantity(parentBin.id, selectedSubBin.id, quantity);
        }
        form.resetFields();
        setShowAddSubBinQuantity(false);
      })
      .catch((error) => {});
  };

  const handleAddSubBinQuantityCancel = () => {
    form.resetFields();
    setSelectedSubBin(null);
    setShowAddSubBinQuantity(false);
  };

  const handleShowAddSubBinQuantity = (subBin) => {
    //console.log("Selected Sub Bin::", subBin);
    setSelectedSubBin(subBin);
    setShowAddSubBinQuantity(true);
  };

  /* const onParentBinQuantityChange = (e) => {
    e.target.value && setParentBinQuantity(e.target.value);
  }; */

  /* const handleAddOrRemoveSubBinQuantity = (subBin, isAdd) => {
    if (isAdd) {
      if (subBin.totalStocks === 0) {
        subBin.totalStocks = 1;
        subBin.isNewlyAdded = true;
        handleAcceptedQuantity(parentBin.id, subBin.id, 1);
      }
    } else {
      if (subBin.isNewlyAdded && subBin.totalStocks === 1) {
        subBin.totalStocks = 0;
        subBin.isNewlyAdded = false;
        handleAcceptedQuantity(parentBin.id, subBin.id, 0);
      }
    }
  }; */

  return (
    <div>
      {/* <Input.Search
        allowClear
        onSearch={onSearch}
        style={{ width: "100%", marginBottom: 10 }}
        placeholder="Enter Bin code and search"
      /> */}
      <Progress
        percent={parseInt(
          receiptLines.length > 0 &&
            receiptLines.filter((obj) => obj.orderLineId === orderLine.id)
              .length > 0 &&
            (receiptLines
              .filter((obj) => obj.orderLineId === orderLine.id)
              .map((obj) => obj.accepted)
              .reduce((prev, next) => prev + next) /
              props.orderLine.quantity) *
              100
        )}
      />
      <Row style={{ marginBottom: 5 }}>
        <Col>
          {/* <Input
            type="number"
            min="0"
            addonAfter={
              <PlusOutlined
                style={{ cursor: "pointer" }}
                onClick={handleAddParentBinQuantity}
              />
            }
            onChange={onParentBinQuantityChange}
            value={parentBinQuantity}
          /> */}
          {Auth.getProfile().comp_code &&
            ["STR-GR"].includes(Auth.getProfile().comp_code) && (
              <Button
                key="1"
                type="primary"
                htmlType="button"
                onClick={handleShowAddQuantity}
              >
                Add Quantity to Parent Bin({parentBin.code})
              </Button>
            )}
        </Col>
      </Row>
      <List
        size="small"
        loading={false}
        itemLayout="horizontal"
        dataSource={props.parentBin.stockBins}
        renderItem={(bin) => (
          <List.Item
            style={
              bin.vacant
                ? bin.isNewlyAdded
                  ? { backgroundColor: "#e6f7ff" }
                  : { backgroundColor: "#f6ffed" }
                : { backgroundColor: "#8c8c8c" }
            }
          >
            <Skeleton title={false} loading={false}>
              <List.Item.Meta title={bin.code} />
              <div>
                <Input
                  value={bin.totalStocks}
                  disabled
                  addonAfter={
                    Auth.getProfile().comp_code &&
                    ["PRYM"].includes(Auth.getProfile().comp_code) ? (
                      <PlusOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowAddSubBinQuantity(bin);
                        }}
                      />
                    ) : (
                      <span></span>
                    )
                  }
                  /* addonBefore={
                    <MinusOutlined
                      style={{ cursor: "pointer" }}
                    />
                  } */
                  style={{ width: "50%" }}
                />
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
      {/* <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 12,
          xxl: 3,
        }}
        style={{ padding: 10 }}
        dataSource={stockBins}
        renderItem={(bin) => (
          <List.Item>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleShowAddQuantity(bin);
              }}
            >
              <Badge count={bin.totalStocks}>
                <span
                  className="head-example"
                  style={{
                    width: 110,
                    height: 35,
                    padding: 6,
                    backgroundColor: "rgb(238, 238, 238)",
                    borderRadius: 4,
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <b>{bin.code}</b>
                </span>
              </Badge>
            </a>
          </List.Item>
        )}
      /> */}
      {/* <Space size={[40, 25]} wrap>
        {stockBins.map((bin, index) => (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleShowAddQuantity(bin);
            }}
            key={`sub-bins-${index}`}
          >
            <Badge count={bin.totalStocks} key={index} style={{ padding: 0 }}>
              <span
                className="head-example"
                style={{
                  width: 110,
                  height: 35,
                  padding: 6,
                  backgroundColor: "rgb(238, 238, 238)",
                  borderRadius: 4,
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                <b>{bin.code}</b>
              </span>
            </Badge>
          </a>
        ))}
      </Space> */}
      <Modal
        title={`Add Quantity to Parent Bin(${parentBin.code})`}
        visible={showAddQuantity}
        onOk={handleAddQuantity}
        onCancel={handleAddQuantityCancel}
      >
        {getModalContent()}
      </Modal>
      <Modal
        title={`Add Quantity to Sub Bin(${
          selectedSubBin && selectedSubBin.code
        })`}
        visible={showAddSubBinQuantity}
        onOk={handleAddSubBinQuantity}
        onCancel={handleAddSubBinQuantityCancel}
      >
        {getModalContentForSubBin()}
      </Modal>
    </div>
  );
};

export default StockSubBinSelection;
