import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React from "react";
import { reasonService } from "_services";
import { ReasonTypes } from "_helpers";

function Search(props) {
  const [form] = Form.useForm();
  const { Option } = Select;

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.name) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    if (values.type) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `type=${encodeURIComponent(values.type)}`;
    }
    reasonService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="name" label="Name">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="type" label="Reason Type">
            <Select placeholder="Please select">
              {ReasonTypes.map((t) => (
                <Option value={t.value} key={t.value}>
                  {t.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}></Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
