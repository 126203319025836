import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Spin,
  Popconfirm,
  Tag,
} from 'antd';
import moment from 'moment';
import Page from '_components/Page';
import TabMenu from './TabMenu';
import { purchaseOrderService, vehicleService } from '_services';

function SiluetaGrn({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const purchaseOrderData = await purchaseOrderService.getById(id);

      if (purchaseOrderData) {
        let purchaseOrder = purchaseOrderData.content;
        setOrder(purchaseOrder);
        if (purchaseOrder.goodsReceivedNote) {
          purchaseOrder.goodsReceivedNote.dateReceived = moment(
            purchaseOrder.goodsReceivedNote.dateReceived
          );
        }
        onFill(purchaseOrder.goodsReceivedNote);
      }

      setLoading(false);
    };

    fetchAlldatas();
    getVehicles();
  }, []);

  const getVehicles = () => {
    vehicleService
      .getSuggestion()
      .then((x) => {
        setVehicles(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        values.dateReceived = moment(values.dateReceived).format('YYYY-MM-DD');
        const payload = {
          preReceiptNumber: order.preReceiptNumber,
          goodsReceivedNote: values,
        };

        purchaseOrderService
          .update(id, payload)
          .then(() => {
            message.success('Successfully updated!');
            setLoading(false);
            history.push(`/packing-lists/${id}/summary`);
          })
          .catch((error) => {
            setLoading(false);
            setError(error);
          });
      })
      .catch((errorInfo) => {});
  };

  const onCancel = () => {
    history.push(`/packing-lists/${id}/summary`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title='Are you sure to save?'
        onConfirm={handleFormSumbit}
        key='con-complete'
        //disabled={addedStocks.length === 0}
      >
        <Button key='btn-complete' htmlType='button' loading={loading}>
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key='btn-cancel' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/packing-lists'>Packing List Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(':id', id)}>
                {order.preReceiptNumber} ::{' '}
                <Tag
                  color={`${
                    order.orderStage === 'OPEN'
                      ? '#2db7f5'
                      : order.orderStage === 'IN_PROGRESS'
                      ? '#108ee9'
                      : '#87d068'
                  }`}
                >
                  {order.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>GRN</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu='grn' id={id} />
          <Spin tip='Loading...' spinning={loading}>
            <Form
              layout='vertical'
              form={form}
              name='form-create'
              requiredMark={false}
            >
              <Form.Item hidden name='id'>
                <Input type='hidden' />
              </Form.Item>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={['vehicle', 'id']}
                    label="Vehicle"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp='children'
                      placeholder='Vehicle'
                    >
                      {vehicles.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='driverName'
                    label='Driver Name'
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='contactNumber'
                    label='Contact Number'
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='dateReceived'
                    label='Received Date'
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder='Received Date'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name='containerNumber' label='Container No:'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name='tellerNumber' label='C. Trailer No:'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name='sealNumber' label='Seal No:'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default SiluetaGrn;
