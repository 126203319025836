import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  extraService,
  materialTypeService,
  packingTypeService,
  stockClassificationService,
  supplierService,
} from "_services";

function Search(props) {
  const [form] = Form.useForm();
  const [packingTypes, setPackingTypes] = useState([]);
  const [stockClasses, setStockClasses] = useState([]);
  const [materialSuppliers, setMaterialSuppliers] = useState([]);
  const [qualityTypes, setQualityTypes] = useState([]);

  useEffect(() => {
    packingTypeService.getSuggestion().then((x) => {
      setPackingTypes(x.content);
    });
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
    getMaterialSuppliers();
    getStockQualityTypes();
  }, []);

  const getMaterialSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setMaterialSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getStockQualityTypes = () => {
    extraService
      .getQualityTypes()
      .then((x) => {
        setQualityTypes(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    if (values.code) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.name) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    if (values.stockClass) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `stockClassId=${encodeURIComponent(values.stockClass)}`;
    }
    if (values.packingType) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `packingTypeId=${encodeURIComponent(values.packingType)}`;
    }
    if (values.qualityType) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `qualityTypeId=${encodeURIComponent(values.qualityType)}`;
    }
    if (values.supplier) {
      searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
      searchParams += `supplierId=${encodeURIComponent(values.supplier)}`;
    }
    materialTypeService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="code" label="Code">
            <Input placeholder="Code" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="Name">
            <Input placeholder="Name" allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="stockClass" label="Stock Clasification">
            <Select placeholder="Please select" allowClear>
              {stockClasses.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}></Col>
        <Col span={6}>
          <Form.Item name="supplier" label="Material Supplier">
            <Select showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Supplier">
              {materialSuppliers.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="packingType" label="Packing Type">
            <Select placeholder="Please select" allowClear>
              {packingTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="qualityType" label="Quality Type">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Quality Type"
            >
              {qualityTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name} ({t.code})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
