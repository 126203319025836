import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  Popconfirm,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import {
  extraService,
  materialTypeService,
  packingTypeService,
  stockClassificationService,
  supplierService,
} from "_services";
import TabMenu from "./TabMenu";

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [materialType, setMaterialType] = useState({});
  const [packingTypes, setPackingTypes] = useState([]);
  const [stockClasses, setStockClasses] = useState([]);
  const [materialSuppliers, setMaterialSuppliers] = useState([]);
  const [qualityTypes, setQualityTypes] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setIsLoading(true);
    materialTypeService
      .getById(id)
      .then((x) => {
        let data = x.content;
        setIsLoading(false);
        setMaterialType(x.content);
        onFill(data);
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        message.error(`${errorInfo}`);
      });
    packingTypeService.getSuggestion().then((x) => {
      setPackingTypes(x.content);
    });
    stockClassificationService.getSuggestion().then((x) => {
      setStockClasses(x.content);
    });
    getMaterialSuppliers();
    getStockQualityTypes();
  }, []);

  const getMaterialSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setMaterialSuppliers(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getStockQualityTypes = () => {
    extraService
      .getQualityTypes()
      .then((x) => {
        setQualityTypes(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        materialTypeService
          .update(id, values)
          .then(() => {
            message.success("Successfully updated!");
            setIsLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        // message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push("/material-types");
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Popconfirm
          key="update-confirm"
          title="Are you sure to update the changes?"
          onConfirm={handleFormSumbit}
        >
          <Button key="1" type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-types">Material Type Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>Material Type: {materialType.code}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu="summary" id={id} />
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[{ required: true, message: "Code is required" }]}
                >
                  <Input placeholder="Code" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["stockClass", "id"]}
                  label="Stock Classification"
                  rules={[
                    {
                      required: true,
                      message: "Stock classification is required",
                    },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {stockClasses.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={["supplier", "id"]} label="Material Supplier" rules={[
                  {
                    required: true,
                    message: "Material Supplier is required",
                  },
                ]}>
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Material Supplier"
                  >
                    {materialSuppliers.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.name} ({t.code})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["packingType", "id"]}
                  label="Packing Type"
                  rules={[
                    { required: true, message: "Packing type is required" },
                  ]}
                >
                  <Select placeholder="Please select" allowClear>
                    {packingTypes.length > 0 &&
                      packingTypes.map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={["qualityType", "id"]} label="Quality Type" rules={[
                  {
                    required: false,
                    message: "Quality Type is required",
                  },
                ]}>
                  <Select
                    showSearch
                    allowClear
                    showArrow={false}
                    optionFilterProp="children"
                    placeholder="Quality Type"
                  >
                    {qualityTypes.map((t) => (
                      <Select.Option value={t.id} key={t.id}>
                        {t.name} ({t.code})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
