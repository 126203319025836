import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Popconfirm,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { Auth } from '_helpers';
import { sbuService, subLocationService, zoneService } from '_services';
import TabMenu from './TabMenu';

function Summary({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    subLocationService
      .getById(id)
      .then((x) => {
        setLoading(false);
        setItem(x.content);
        onFill(x.content);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(`${errorInfo}`);
      });
    getBusinessUnits();
    getZones();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        sbuService
          .update(id, values)
          .then(() => {
            message.success('Successfully updated!');
            setLoading(false);
            history.push(`${path}`);
          })
          .catch((error) => {
            setLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const onCancel = () => {
    history.push('/sub-locations');
  };

  const getActionButtons = () => {
    let buttons = [];
    if (Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])) {
      buttons.push(
        <Popconfirm
          title='Are you sure to update the changes?'
          onConfirm={handleFormSumbit}
        >
          <Button key='1' type='primary' htmlType='submit' loading={loading}>
            Save
          </Button>
        </Popconfirm>
      );
    }
    buttons.push(
      <Button key='2' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/sub-locations'>Sub-Locations Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`}>Sub-Location: {item.name}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <TabMenu menu='summary' id={id} />
          <Form
            form={form}
            name='form-create'
            layout='vertical'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name={['zone', 'id']} label='Zone'>
                  <Select placeholder='Please select' disabled>
                    {zones.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='code' label='Code'>
                  <Input placeholder='Code' disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='name' label='Name'>
                  <Input
                    placeholder='Name'
                    disabled={!Auth.hasRole(Auth.getProfile(), ['SAU', 'CAU'])}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='shortCode' label='Short Code'>
                  <Input placeholder='Short Code' disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={['zone', 'sbu', 'id']}
                  label='SBU'
                  rules={[
                    {
                      required: true,
                      message: 'SBU is required',
                    },
                  ]}
                >
                  <Select placeholder='Please select' allowClear disabled>
                    {businessUnits.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={18}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Summary;
