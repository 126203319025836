import { Button, Col, Form, Row, message, Select } from "antd";
import React, { useState } from "react";
import { reportService } from "_services";

function BinWiseBalanceSearch(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    if (values.materialType) {
      searchParams += `&stockTypeId=${values.materialType}`;
    }
    if (values.lotNumber) {
      searchParams += `&lotNumberId=${values.lotNumber}`;
    }
    if (values.bin) {
      searchParams += `&stockBinId=${values.bin}`;
    }
    if (values.zone) {
      searchParams += `&zone=${values.zone}`;
    }

    reportService
      .getBinWiseBalance(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([]);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="search-form"
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="materialType" label="Material Type">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Type"
            >
              {props.stockTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="lotNumber" label="Lot No">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Lot No"
            >
              {props.lotNumberList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="zone" label="Zone">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Zone"
            >
              {props.zones.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item name="bin" label="Stock Bin">
            <Select
              showSearch
              allowClear
              showArrow={false}
              optionFilterProp="children"
              placeholder="Material Bin"
            >
              {props.stockBins.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4} style={{ textAlign: "right" }}>
          <div>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default BinWiseBalanceSearch;
