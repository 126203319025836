import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, PageHeader, Spin, Tag, Table } from "antd";
import moment from "moment";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import { materialRequestLineService } from "_services";

function StrHistory({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [materialRequestLine, setMaterialRequestLine] = useState({});

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const requestLineData = await materialRequestLineService.getById(id);

      if (requestLineData) {
        setMaterialRequestLine(requestLineData.content);
      }
      setLoading(false);
    };

    fetchAlldatas();
    getStockHistory(id);
  }, []);

  const getStockHistory = (requestLineId) => {
    setLoading(true);
    materialRequestLineService
      .getHistory(requestLineId)
      .then((x) => {
        setItems(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        setError(errorInfo);
      });
  };

  const onCancel = () => {
    history.push(`/material-request-lines/${id}/summary`);
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Note",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
    },
  ];

  const getRowColor = (record) => {
    let color = "";
    if (record) {
      const text = record.lineStage;
      if (text === "OPEN") {
        color = "#87d068";
      }
      if (text === "UPDATE") {
        color = "#2db7f5";
      }
      if (text === "VERIFICATION") {
        color = "#108ee9";
      }
      if (text === "PREPARE") {
        color = "#f50";
      }
      if (text === "READY") {
        color = "#faad14";
      }
      if (text === "ASSIGNED") {
        color = "#ffd666";
      }
      if (text === "PROCESSING") {
        color = "#ffd666";
      }
      if (text === "COMPLETE") {
        color = "#ffd666";
      }
      if (text === "REJECTED") {
        color = "#ffd666";
      }
      if (text === "CANCEL") {
        color = "#ffd666";
      }
    }

    return color;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {materialRequestLine?.issueRequest?.requestNumber} ::{" "}
                {materialRequestLine.mrnNumber} ::{" "}
                <Tag color={getRowColor(materialRequestLine)}>
                  {materialRequestLine.lineStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>History</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu
            menu="history"
            id={id}
            status={materialRequestLine.lineStage}
          />
          <Spin tip="Loading..." spinning={loading}>
            <Table
              bordered
              rowKey="id"
              size="small"
              columns={columns}
              dataSource={items}
              pagination={false}
            />
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrHistory;
