import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Col,
  Row,
  InputNumber,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { Auth } from "_helpers";
import {
  binService,
  companyService,
  sbuService,
  subLocationService,
  zoneService,
} from "_services";

function Add({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    setIsDataLoading(true);
    companyService
      .getSuggestion("?companyType=STORE")
      .then((x) => {
        setIsDataLoading(false);
        if (x.content.length === 1) {
          let companyId = Auth.getProfile().comp_id
            ? Auth.getProfile().comp_id
            : x.content[0].id;
          companyService.getById(companyId).then((data) => {
            const warehouseLocations = data.content.addresses.filter(
              (item) => item.addressType === "WAREHOUSE"
            );
            setAddresses(warehouseLocations);
            if (warehouseLocations.length === 1) {
              form.setFieldsValue({ storeLocation: warehouseLocations[0] });
            }
          });
          /* addressService.getAllByCompany(companyId).then((y) => {
          form.setFieldsValue({
            store: x.content[0],
            storeLocation: y.content[0],
          });
          setAddresses(y.content);
        }); */
          form.setFieldsValue({ store: x.content[0] });
        }
        setCompanies(x.content);
      })
      .catch(() => {
        setIsDataLoading(false);
      });
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      if (x.content.length === 1) {
        const customer = x.content.find(
          (item) => item.id === Auth.getProfile().comp_id
        );
        form.setFieldsValue({
          tenant: customer,
        });
      }
      setCustomers(x.content);
    });
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    setIsDataLoading(true);
    sbuService
      .search("")
      .then((x) => {
        setIsDataLoading(false);
        setBusinessUnits(x.content);
        if (x.content.length === 1) {
          form.setFieldsValue({ sbuId: x.content[0].id });
          setSelectedSbuId(x.content[0].id);
        }
      })
      .catch((errorInfo) => {
        setIsDataLoading(false);
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    setIsDataLoading(true);
    zoneService
      .search("")
      .then((x) => {
        setIsDataLoading(false);
        setZones(x.content);
      })
      .catch((errorInfo) => {
        setIsDataLoading(false);
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    setIsDataLoading(true);
    subLocationService
      .search("")
      .then((x) => {
        setIsDataLoading(false);
        setSubLocations(x.content);
      })
      .catch((error) => {
        setIsDataLoading(false);
        message.error(`${error}`);
      });
  };

  /* const getCompanyAddresses = (companyId) => {
    setAddressLoading(true);
    addressService.getAllByCompany(companyId).then((x) => {
      setAddresses(x.content);
      setAddressLoading(false);
    });
  }; */

  const handleFormSumbit = (e) => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        //values.numberOfBin = 501;
        //values.storeLocation = { id: values.storeLocation.id, name: "BNS-2" };
        // console.log("PL:", values);
        values.subLocation = {
          id: values.subLocationId,
        };
        binService
          .create(values)
          .then(() => {
            message.success("Successfully created!");
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Button
        type="primary"
        key="save"
        htmlType="submit"
        loading={isLoading}
        onClick={(e) => handleFormSumbit(e)}
      >
        Save
      </Button>
    );
    buttons.push(
      <Button key="cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/bins">Bin Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/bins/add">New Bin</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Initial data loading..." spinning={isDataLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="code"
                    label="Code"
                    rules={[{ required: true, message: "Code is required" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: true, message: "Store is required" }]}
                  >
                    <Select
                      placeholder="Please select"
                      //onChange={getCompanyAddresses}
                      allowClear
                    >
                      {companies.length > 0 &&
                        companies.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="sbuId" label="SBU">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name="name" label="Name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={["tenant", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="zoneId" label="Zone">
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled={!selectedSbuId}
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name="note" label="Remarks">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={["storeLocation", "id"]}
                    label="Location"
                    rules={[
                      { required: true, message: "Store location is required" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      loading={addressLoading}
                      allowClear
                    >
                      {addresses.length > 0 &&
                        addresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="subLocationId"
                    label="Sub-Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub-Location",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      optionFilterProp="children"
                      showSearch
                      disabled={!selectedZoneId}
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
