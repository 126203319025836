import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Tag,
  Popconfirm,
  Typography,
  Tooltip,
  Divider,
  Tabs,
  Modal,
  Spin,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  materialRequestService,
  stockIssueService,
} from "_services";
import StockSelection from "./StockSelection";
import { CloseOutlined } from "@ant-design/icons";

function StrGrEdit({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [materialRequests, setMaterialRequests] = useState([]);
  const [addedMaterialRequestIds, setAddedMaterialRequestIds] = useState([]);
  const [stockIssue, setStockIssue] = useState({});
  const [selectedIssueDate, setSelectedIssueDate] = useState(Date());
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchMRLoading, setSearchMRLoading] = useState(false);
  const [addedStocks, setAddedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [availableStocks, setAvailableStocks] = useState([]);
  const [availableStocksLoading, setAvailableStocksLoading] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumberId, setLotNumberId] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;

  const [showRequestLines, setShowRequestLines] = useState(false);
  const [loadedRequests, setLoadedRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedRequestLineId, setSelectedRequestLineId] = useState(null);
  const [requestedLines, setRequestedLines] = useState([]);
  const [issuedLines, setIssuedLines] = useState([]);
  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);

  useEffect(() => {
    getStockIssue(id);
    getStores();
    getCustomers();
    getMaterialRequests();
  }, []);

  const getStockIssue = (issueId) => {
    setIsLoading(true);
    stockIssueService
      .getById(issueId)
      .then(async (data) => {
        if (data) {
          let persistedStockIssue = data.content;
          //console.log("StockIssue: ", persistedStockIssue);

          persistedStockIssue.issuedDate = moment(
            persistedStockIssue.issuedDate
          );
          persistedStockIssue.deliveredDate = persistedStockIssue.deliveredDate
            ? moment(persistedStockIssue.deliveredDate)
            : moment();
          for (const line of persistedStockIssue.issuedLines) {
            line.key = `${line.stockType.id}_${line.lotNumber ? line.lotNumber.id : ""}`;
            line.deliveredQuantity = 0;
            console.log("Issue:", line.requestLine.requestedItems);

            line.requestNumber = line.requestLine.issueRequest.requestNumber;
            line.requestedQuantity = line.requestLine.requestedItems.length;
            line.requestedGrossMeasure =
              line.requestLine.requestedItems.length > 0 &&
              line.requestLine.requestedItems
                .map((item) => item.grossWeight)
                .reduce((prev, next) => prev + next);
            line.requestedNetMeasure =
              line.requestLine.requestedItems.length > 0 &&
              line.requestLine.requestedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next);
            //setRequestedLines([...requestedLines, line.requestLine]);
            requestedLines.push(line.requestLine);
            let issueRequest = line.requestLine.issueRequest;
            const loadedRequest = loadedRequests.find(
              (item) => item.id === issueRequest.id
            );
            if (!loadedRequest) {
              const materialRequestResponse = await getMaterialRequestById(
                issueRequest.id
              );
              if (materialRequestResponse) {
                issueRequest = materialRequestResponse.content;
                setLoadedRequests([...loadedRequests, issueRequest]);
              }
            } else {
              issueRequest = loadedRequest;
            }
            /* line.requestLine = issueRequest.requestLines.find(
              (item) => item.id === line.requestLine.id
            ); */
            setSelectedRequest(issueRequest);

            line.issuedQuantity = line.issuedItems.length;
            line.issuedNetWeight =
              line.issuedItems.length > 0 &&
              line.issuedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next);
            line.issuedGrossWeight =
              line.issuedItems.length > 0 &&
              line.issuedItems
                .map((item) => item.grossWeight)
                .reduce((prev, next) => prev + next);
            // Added all the requested items to issued items and mark it as added=false,
            // if it's not in issued items
            const issuedItemIds = line.issuedItems.map((item) => item.id);
            const issueRequestLine = issueRequest.requestLines.find(
              (item) => item.id === line.requestLine.id
            );
            for (const requestedItem of issueRequestLine.requestedItems) {
              if (issuedItemIds.includes(requestedItem.id)) {
                let readyStock = line.issuedItems.find(
                  (item) => item.id === requestedItem.id
                );
                readyStock.mode = "READY";
                readyStock.added = true;
                readyStock.requestLineId = line.requestLine.id;
              } else {
                requestedItem.requestLineId = issueRequestLine.id;
                line.issuedItems.push(requestedItem);
              }
            }
            /* line.issuedItems.map(
              (item) => (
                (item.mode = "READY"),
                (item.requestLineId = line.requestLine.id),
                (item.added = true)
              )
            ); */
            setAddedStocks([
              ...addedStocks,
              ...line.issuedItems.filter((item) => item.added),
            ]);
            setAvailableStocks([...availableStocks, ...line.issuedItems]);

            issuedLines.push(line);
            selectedRowKeyMap.set(
              line.key,
              line.issuedItems
                .filter((item) => item.added)
                .map((item) => item.id)
            );
          }

          setSelectedRowKeyMap(selectedRowKeyMap);

          //console.log("Issue:", persistedStockIssue);
          //console.log("Issued Lines:", issuedLines);
          setStockIssue(persistedStockIssue);

          //setIssuedLines([...issuedLines, ...persistedStockIssue.issuedLines]);
          //setIssuedLines(issuedLines);
          setIssuedLines([].concat(issuedLines));
          setSelectedCustomer(persistedStockIssue.customer);
          onFill(persistedStockIssue);

          showMaterialRequestLinesByRequestNumber(
            persistedStockIssue.issuedLines[0].requestLine.issueRequest
              .requestNumber
          );
          if (activeTabKey === null) {
            handleTabChange(issuedLines[0].key);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setIsLoading(false);
        //setError(error);
      });
  };

  const getStores = () => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      setStores(x.content);
    });
  };

  const getCustomers = () => {
    companyService.getSuggestion("?companyType=CUSTOMER").then((x) => {
      setCustomers(x.content);
    });
  };

  const getMaterialRequests = () => {
    materialRequestService.getSuggestion("").then((x) => {
      setMaterialRequests(
        x.content.filter((item) =>
          ["OPEN", "IN_PROGRESS", "ACCEPTED"].includes(item.orderStage)
        )
      );
    });
  };

  const getMaterialRequestById = async (id) => {
    const request = await materialRequestService.getById(id);
    return request;
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        const issuedItems = stockIssue.issuedLines.flatMap(
          (line) => line.issuedItems
        );
        if (issuedItems && issuedItems.length === 0) {
          setError("Delivery should have at least one delivery item");
        } else {
          setUpdating(true);
          delete values.stockItem;
          delete values.materialRequest;

          values.issuedDate = moment(values.issuedDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          ///console.log("Issued Line:", issuedLines);
          values.issuedLines = issuedLines.map((line) => ({
            id: line.id,
            requestLine: { id: line.requestLine.id },
            quantity: line.issuedItems.filter((item) => item.added).length,
            stockType: { id: line.stockType.id },
            lotNumber: line.lotNumber ? { id: line.lotNumber.id } : null,
            issuedItems: line.issuedItems
              .filter((item) => item.added)
              .map((item) => ({
                id: item.id,
              })),
          }));

          console.log("OUT: ", values);
          //console.log("OUT: ", JSON.stringify(values));
          stockIssueService
            .update(id, values)
            .then(() => {
              setUpdating(false);
              message.success("Successfully updated!");
              //history.push("/stock-issue");
              window.location.reload();
            })
            .catch((error) => {
              setUpdating(false);
              setError(error);
            });
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onSelectChange = (rowKeys) => {
    console.log("RowKeys:", rowKeys);
    setSelectedRowKeyMap(selectedRowKeyMap.set(activeTabKey, rowKeys));
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    console.log("all row keys:", allSelectedKeys);
    setSelectedRowKeys(allSelectedKeys);
    const totalRequestedStocks = issuedLines.flatMap(
      (item) => item.issuedItems
    );
    let loadedStocks = [];
    for (let item of totalRequestedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.added = true;
        item.mode = "READY";
        loadedStocks.push(item);
      } else {
        item.added = false;
        item.mode = "REQUESTED";
      }
    }
    setAddedStocks(loadedStocks);

    issuedLines.forEach((line) => {
      line.issuedQuantity = 0;
      line.issuedNetWeight = 0;
      line.issuedGrossWeight = 0;
      loadedStocks.forEach((stock) => {
        if (line.requestLine.id === stock.requestLineId) {
          line.issuedQuantity += 1;
          line.issuedNetWeight += stock.netWeight;
          line.issuedGrossWeight += stock.grossWeight;
        }
      });
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
  };

  const handleSearchAndAddStocks = (line) => {
    if (line.stockType.id && line.lotNumber.id) {
      let issuedLine = issuedLines.find(
        (issueLine) =>
          issueLine.stockType.id === line.stockType.id &&
          issueLine.lotNumber.id === line.lotNumber.id
      );
      if (issuedLine) {
        setSelectedStockIds(issuedLine.issuedItems.map((item) => item.id));
      }

      let searchParams = `?stockTypeId=${line.stockType.id}&mode=AVAILABLE&pageNumber=1&pageSize=100`;
      searchParams = `${searchParams}&lotNumberId=${line.lotNumber.id}`;

      setAvailableStocksLoading(true);
      setMaterialTypeId(line.stockType.id);
      setLotNumberId(line.lotNumber.id);
      materialDetailService
        .search(searchParams)
        .then((data) => {
          setAvailableStocksLoading(false);
          if (data.content.length > 0) {
            setAvailableStocks(data.content);
            setIsStockSelectionVisible(true);
            setMaterialTypeId(line.stockType.id);
            setLotNumberId(line.lotNumber.id);
          } else {
            setMaterialTypeId(null);
            setLotNumberId(null);
            message.info(
              "Sorry, There's no available stocks for the given criteria!"
            );
          }
          setAvailableStocksLoading(false);
        })
        .catch((error) => {
          setAvailableStocksLoading(false);
          message.error(`${error}`);
        });
    } else {
      message.error("Invalid Material Code or Vendor Batch!");
    }
  };

  const showMaterialRequestLines = (requestId) => {
    setShowRequestLines(false);
    if (requestId) {
      setSearchMRLoading(true);
      materialRequestService
        .getById(requestId)
        .then((data) => {
          setSearchMRLoading(false);
          setSelectedRequest(data.content);
          setShowRequestLines(true);
        })
        .catch((error) => {
          setSearchMRLoading(false);
          message.error(`${error}`);
        });
    }
  };

  const showMaterialRequestLinesByRequestNumber = (requestNumber) => {
    setShowRequestLines(false);
    if (requestNumber) {
      setSearchMRLoading(true);
      materialRequestService
        .search(`?requestNumber=${requestNumber}`)
        .then((data) => {
          setSearchMRLoading(false);
          //console.log("MRN:", data.content[0]);
          setSelectedRequest(data.content[0]);
          form.setFieldsValue({
            materialRequest: data.content[0].id,
          });
          setShowRequestLines(true);
        })
        .catch((error) => {
          setSearchMRLoading(false);
          message.error(`${error}`);
        });
    }
  };

  const addRequestLineToDelivery = (requestLineId) => {
    if (
      requestLineId &&
      !requestedLines.map((item) => item.id).includes(requestLineId)
    ) {
      setSearchMRLoading(true);

      const issueRequest = loadedRequests.find(
        (item) => item.id === selectedRequest.id
      );
      let requestLine = issueRequest.requestLines.find(
        (item) => item.id === requestLineId
      );

      requestLine.issueRequest = issueRequest;

      const issuedLine = {
        key: `${requestLine.stockType.id}_${requestLine.lotNumber ? requestLine.lotNumber.id : ""}`,
        stockType: requestLine.stockType,
        lotNumber: requestLine.lotNumber,
        requestLine: requestLine,
        measurementType: requestLine.measurementType,
        requestNumber: issueRequest.requestNumber,
        requestedQuantity:
          typeof requestLine.requestedItems !== "undefined"
            ? requestLine.requestedItems.length
            : requestLine.quantity,
        requestedTotalQuantity: requestLine.quantity,
        requestedGrossMeasure:
          typeof requestLine.requestedItems !== "undefined"
            ? requestLine.requestedItems.length > 0 &&
            requestLine.requestedItems
              .map((item) => item.grossWeight)
              .reduce((prev, next) => prev + next)
            : requestLine.quantity,
        issuedItems:
          typeof requestLine.requestedItems !== "undefined"
            ? requestLine.requestedItems
              .filter((item) => item.mode === "REQUESTED")
              .map((t) => ({
                ...t,
                added: false,
                requestLineId: requestLine.id,
              }))
            : [],
        issuedQuantity: 0,
        issuedNetWeight: 0,
        issuedGrossWeight: 0,
      };

      //stockIssue.issuedLines.push(issuedLine);
      //issuedLines.push(issuedLine);
      setStockIssue(stockIssue);
      setIssuedLines([...issuedLines, issuedLine]);
      setRequestedLines([...requestedLines, requestLine]);
      setSearchMRLoading(false);
      form.setFieldsValue({ materialRequestLine: null });
      //console.log("Issued Lines:", issuedLines);

      handleTabChange(issuedLine.key);
    } else {
      message.info("Already added to the delivery");
    }
  };

  const onSearchAndAddStockItem = (value) => {
    // Barcode should contain MaterialTypeCode|LotNumber|RunningNo
    if (value) {
      setLoadingStockItem(true);
      materialDetailService
        .search(`?barcode=${encodeURIComponent(value)}`)
        .then((data) => {
          if (data.content && data.content[0]) {
            let stock = data.content[0];
            let stockAdded = false;

            if (!selectedRowKeys.includes(stock.id)) {
              issuedLines.forEach((line) => {
                console.log("Line:", line);
                if (
                  line.stockType.id === stock.stockType.id &&
                  line.lotNumber.id === stock.lotNumber.id &&
                  !line.issuedItems.map((item) => item.id).includes(stock.id)
                ) {
                  stockAdded = true;
                  line.issuedItems.push(stock);
                  onSelectChange(
                    line.issuedItems.map((item) => item.id),
                    ...stock.id
                  );
                  /* stockAdded = true;
                  line.issuedQuantity += 1;
                  line.issuedNetWeight += stock.netWeight;
                  line.issuedGrossWeight += stock.grossWeight; */

                  // if (
                  //   !line.issuedItems.map((item) => item.id).includes(stock.id)
                  // ) {
                  //   stockAdded = true;
                  //   stock.added = true;
                  //   stock.mode = "READY";
                  //   setAddedStocks([...addedStocks, stock]);
                  //   setSelectedRowKeys([...selectedRowKeys, stock.id]);
                  //   line.issuedItems.push(stock);
                  // }
                }
              });
            }
            if (!stockAdded) {
              message.error(
                "Sorry, No item found against the added delivery lines or already added."
              );
            } else {
              message.success("Successfully added to the delivery");
            }
            form.setFieldsValue({ stockItem: null });
          } else {
            message.error("Sorry, No item found against the barcode.");
          }
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      //console.log("Selected:", selectedIssueLineKeys);
      handleTabChange(selectedIssueLineKeys[0]);
    },
  };

  const onCancel = () => {
    history.push("/stock-issue");
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
    //console.log("Active:", activeTabKey);
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleDelete = (line) => {
    const tmpIssuedlines = [...issuedLines];
    if (tmpIssuedlines.length === 1) {
      message.error(
        "Sorry, You can't delete all the delivery line. Needs to have atleast one line."
      );
    } else {
      const tmpRequestedLines = [...requestedLines];
      if (tmpIssuedlines.includes(line)) {
        tmpIssuedlines.splice(tmpIssuedlines.indexOf(line), 1);
        setIssuedLines(tmpIssuedlines);
      }
      if (tmpRequestedLines.includes(line.requestLine)) {
        tmpRequestedLines.splice(
          tmpRequestedLines.indexOf(line.requestLine),
          1
        );
        setRequestedLines(tmpRequestedLines);
      }

      handleTabChange(tmpIssuedlines[0].key);
    }
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Request No:",
      dataIndex: "requestNumber",
      key: "requestNumber",
      with: "15%",
    });
    columns.push({
      title: "Material Type",
      key: "stockTypeCode",
      width: "12%",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a.stockType &&
        a.stockType.code.localeCompare(b.stockType && b.stockType.code),
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={record.stockType && record.stockType.name}
          >
            {record.stockType && record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "8%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Requested Boxes",
      dataIndex: "requestedQuantity",
      key: "requestedQuantity",
      align: "right",
    });
    columns.push({
      title: "Requested Net Weight(Kg)",
      dataIndex: "requestedNetMeasure",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Loaded Boxes",
      dataIndex: "issuedQuantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Loaded Net Weight(Kg)",
      dataIndex: "issuedNetWeight",
      key: "issueNetWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "",
      key: "operation",
      width: "5%",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure to delete this delivery line?"
          onConfirm={() => handleDelete(record)}
        >
          <CloseOutlined />
        </Popconfirm>
      ),
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      /* align: "right", */
    });
    columns.push({
      title: "Stock Bin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "LOADED") {
          color = "#87d068";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Net Weight(Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected:", selectedStockIds);
    //console.log("Available:", availableStocks);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    if (selectedStocks) {
      issuedLines.forEach((line) => {
        if (
          line.stockType.id === materialTypeId &&
          line.lotNumber.id === lotNumberId
        ) {
          line.issuedItems = selectedStocks.filter(
            (item) =>
              item.stockType.id === materialTypeId &&
              item.lotNumber.id === lotNumberId
          );
          line.issuedQuantity = line.issuedItems.length;
          line.issuedGrossWeight = line.issuedItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next);
          line.issuedNetWeight = line.issuedItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
          handleTabChange(line.key);
          /* line.issuedQuantity = line.issuedItems.length;
          line.issuedNetWeight = line.issuedItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
          line.issuedGrossWeight = line.issuedItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next); */
        }
      });
    }
    setIsStockSelectionVisible(false);
    setSelectedStockIds([]);
    setMaterialTypeId(null);
    setLotNumberId(null);
  };

  const getTabContent = (stockIssueLines) => {
    //console.log("IssuedLinesTab:", stockIssueLines);
    let tabPanes = [];
    stockIssueLines.forEach((line, index) => {
      if (line.issuedItems && line.issuedItems.length > 0) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType && line.stockType.code} / ${line.lotNumber && line.lotNumber.number ? line.lotNumber.number : ""
              } (${line.issuedQuantity})`}
            key={`${line.stockType.id}_${line.lotNumber ? line.lotNumber.id : ""}`}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => (record.added ? "ant-tag-green" : "")}
              rowSelection={rowSelection}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossValueSqm = 0;

                pageData.forEach(
                  ({ quantity, netWeight, grossWeight, grossValueSqm }) => {
                    totalQuantity += quantity ? quantity : 1;
                    //totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossValueSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={5}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong style={{ paddingLeft: 12 }}>
                          {totalQuantity}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to issue these stocks?"
        onConfirm={handleConfirm}
        key="confirm"
      //disabled={addedStocks.length === 0}
      >
        <Button
          key="confirm"
          type="dashed"
          htmlType="submit"
        //disabled={addedStocks.length === 0}
        >
          Ready To Deliver
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Popconfirm
        title="Are you sure to save these stocks?"
        onConfirm={handleFormSumbit}
        key="update-confirm"
        disabled={addedStocks.length === 0}
      >
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={updating}
          disabled={addedStocks.length === 0}
        >
          Update
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  const handleConfirm = () => {
    history.push(`/stock-issue/${id}/confirm`);
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag color="#108ee9">{stockIssue.orderStage}</Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: false, message: "Store is required" }]}
                  >
                    <Select placeholder="Please select">
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: false, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issuedDate"
                    label="Issue Date"
                    rules={[
                      { required: false, message: "Issue date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      onChange={setSelectedIssueDate}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="vehicleNumber"
                    label="Vehicle Number"
                    rules={[
                      {
                        required: false,
                        message: "Vehicle Number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Vehicle Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverName"
                    label="Driver Name"
                    rules={[
                      { required: false, message: "Driver Name is required" },
                    ]}
                  >
                    <Input placeholder="Driver Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact Number"
                    rules={[
                      {
                        required: false,
                        message: "Contact Number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverNic"
                    label="Driver NIC"
                    rules={[
                      { required: false, message: "Driver NIC is required" },
                    ]}
                  >
                    <Input placeholder="Driver NIC" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="materialRequest"
                    label="Select Material Request"
                  >
                    <Select
                      placeholder="Please select"
                      allowClear={true}
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => showMaterialRequestLines(e)}
                      loading={searchMRLoading}
                    >
                      {materialRequests.length > 0 &&
                        materialRequests.map((t) => (
                          <Option
                            value={t.id}
                            key={t.id}
                            style={{
                              color: `${addedMaterialRequestIds.includes(t.id)
                                ? "red"
                                : ""
                                }`,
                            }}
                          >
                            {t.requestNumber} [{t.orderStage}]
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item
                    name="sealNumber"
                    label="Seal No:"
                    rules={[
                      { required: false, message: "Seal number is required" },
                    ]}
                  >
                    <Input placeholder="Seal No:" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="dispatchNumber"
                    label="Dispatch No:"
                    rules={[
                      {
                        required: false,
                        message: "Dispatch number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Dispatch No:" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  {showRequestLines && (
                    <Form.Item
                      name="materialRequestLine"
                      label="Select Material Request Line"
                    >
                      <Select
                        placeholder="Please select"
                        allowClear={true}
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => setSelectedRequestLineId(e)}
                      >
                        {selectedRequest.requestLines &&
                          selectedRequest.requestLines.length > 0 &&
                          selectedRequest.requestLines.map((t) => (
                            <Option
                              value={t.id}
                              key={t.id}
                              style={{
                                color: `${requestedLines.find(
                                  (item) => item.id === t.id
                                )
                                  ? "red"
                                  : ""
                                  }`,
                              }}
                            >
                              {t.stockType.code} | {t.lotNumber ? t.lotNumber.number : ""} (
                              {t.quantity})
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}>
                  {showRequestLines && (
                    <Form.Item label={<span></span>}>
                      <Button
                        type="primary"
                        onClick={() => {
                          addRequestLineToDelivery(selectedRequestLineId);
                        }}
                        loading={searchMRLoading}
                        disabled={!form.getFieldValue("materialRequestLine")}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
              {/* issuedLines.length > 0 &&
                issuedLines.map((item) => item.issuedItems) &&
                issuedLines.map((item) => item.issuedItems).length >
                  0 && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item name="stockItem">
                        <Input.Search
                          loading={loadingStockItem}
                          placeholder="Enter valid barcode"
                          allowClear
                          enterButton="Search & Add Stock"
                          onSearch={onSearchAndAddStockItem}
                          style={{ marginBottom: 20 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ) */}

              {issuedLines.length > 0 && (
                <Table
                  bordered
                  pagination={false}
                  columns={getLineColumns()}
                  dataSource={issuedLines}
                  //expandable={{ expandedRowRender, defaultExpandAllRows: true }}
                  rowSelection={{
                    type: "radio",
                    ...issueLineRowSelection,
                  }}
                  rowClassName={(record) =>
                    record.requestedQuantity <= record.issuedQuantity
                      ? "ant-tag-green"
                      : record.issuedGrossWeight > 0
                        ? "ant-tag-red"
                        : ""
                  }
                  size="small"
                  summary={(pageData) => {
                    let totalRequestedQuantity = 0;
                    let totalRequestedNetMeasure = 0;
                    let totalQuantity = 0;
                    let totalNetWeight = 0;

                    pageData.forEach(
                      ({
                        requestedQuantity,
                        requestedNetMeasure,
                        issuedQuantity,
                        issuedNetWeight,
                      }) => {
                        totalRequestedQuantity += requestedQuantity;
                        totalRequestedNetMeasure += requestedNetMeasure;
                        totalQuantity += issuedQuantity;
                        totalNetWeight += issuedNetWeight;
                      }
                    );

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5}>
                            <Text strong>Total</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>{totalRequestedQuantity}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalRequestedNetMeasure) &&
                                Number(totalRequestedNetMeasure).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>{totalQuantity}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align="right">
                            <Text strong>
                              {!isNaN(totalNetWeight) &&
                                Number(totalNetWeight).toFixed(2)}
                            </Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              )}
            </Form>
            {
              /* stockIssue &&
              stockIssue.issuedLines && */
              issuedLines.length > 0 &&
              issuedLines.map((item) => item.issuedItems) &&
              issuedLines
                .map((item) => item.issuedItems)
                .reduce((prev, next) => prev + next).length > 0 && (
                <>
                  <Divider orientation="left">
                    Stock Items -{" "}
                    {/* <Checkbox onChange={handleAllLoadChange}>Load All</Checkbox> */}
                  </Divider>
                  <Tabs
                    //defaultActiveKey={issuedLines[0].key}
                    onChange={handleTabChange}
                    activeKey={activeTabKey}
                  >
                    {getTabContent(issuedLines)}
                  </Tabs>
                </>
              )
            }
            <Modal
              title={`Stocks (${availableStocks.length})`}
              style={{ top: 48 }}
              visible={isStockSelectionVisible}
              okText={`Add Stocks (${selectedStockIds.length})`}
              onOk={handleAddStocks}
              //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
              onCancel={() => setIsStockSelectionVisible(false)}
              //destroyOnClose={true}
              width={1300}
            >
              <StockSelection
                stocks={availableStocks}
                selectedIds={selectedStockIds}
                //customer={selectedCustomer}
                onSelectChange={handleStockSelection}
              />
            </Modal>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrGrEdit;
