import { Breadcrumb, PageHeader, Table, message, Button, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { stockCheckService } from "_services";
import ItemComparisonSearch from "./StockItemComparisonSearch";

const NotFoundItems = ({ match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getItems(1, 50);
  }, []);

  const getItems = () => {
    setLoading(true);
    stockCheckService
      .getNotFoundItems()
      .then((data) => {
        setItems(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const getActionButtons = () => {
    let buttons = [];
    return buttons;
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Bin Code",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
    });
    columns.push({
      title: "Status",
      key: "mode",
      dataIndex: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Net.W",
      dataIndex: "netWeight",
      key: "netWeight",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Not Found in BNS-EP_RM_MAI</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <>
            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default NotFoundItems;
