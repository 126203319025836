import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Table,
  Tag,
  Typography,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "_components/Page";
import { materialDetailService } from "_services";
import MaterailDetailSearch from "./Search";
import { BarcodeOutlined } from "@ant-design/icons";
import { Auth } from "_helpers";

const List = ({ history, match }) => {
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [loadingBarcodes, setLoadingBarcodes] = useState(false);
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalGrossMeasure, setTotalGrossMeasure] = useState(0);
  const [searchParams, setSearchParams] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const loggedInCustomerCode = Auth.getProfile().comp_code;

  const { Text } = Typography;

  useEffect(() => {
    //getItems(pageNumber, pageSize);
  }, []);

  const getItems = (pageNumber, pageSize) => {
    let paginationParams = searchParams.indexOf("?") === -1 ? "?" : "&";
    paginationParams += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    materialDetailService
      .search(`${searchParams}${paginationParams}`)
      .then((data) => {
        setItems(data.content);
        setLoading(false);
        setTotalRecords(data.pagination.totalRecords);
        const grossMeasures = Object.values(data.pagination.totalGross);
        const grossMeasureSum =
          grossMeasures.length > 0 &&
          grossMeasures.reduce((prev, next) => prev + next);
        setTotalGrossMeasure(grossMeasureSum);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
      })
      .catch((error) => {
        setLoading(false);
        message.error(`${error}`);
      });
  };

  const handleSearchResults = (results, pagination, searchParams) => {
    setItems(results);
    if (pagination) {
      setTotalRecords(pagination.totalRecords);
      const grossMeasures = Object.values(pagination.totalGross);
      const grossMeasureSum =
        grossMeasures.length > 0 &&
        grossMeasures.reduce((prev, next) => prev + next);
      setTotalGrossMeasure(grossMeasureSum);
    }
    setSearchParams(searchParams);
    setPageNumber(1);
    setLoading(false);
  };

  const handlePaginateChange = (pageNumber, pageSize) => {
    //console.log("Page ChANGE:", pageNumber, ", pageSize:", pageSize);
    getItems(pageNumber, pageSize);
  };

  const onSelectChange = (selectedRowKeys) => {
    //console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedItems(
      items
        .filter((item) => selectedRowKeys.includes(item.id))
        .map((item) => ({
          id: item.id,
          packSerial: item.packSerialNo,
          runningNo: item.runningNo,
          mergeNo: item.lotNumber.number,
          itemCode: item.stockType.code,
          quantity: item.quantity,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
        }))
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
    /* selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ], */
  };

  /* const handleDelete = (id) => {
    materialDetailService
      .delete(id)
      .then((x) => {
        getItems(pageNumber, pageSize);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  }; */

  const handleBinTransfer = () => {
    const stockItems = items.filter(
      (item) => selectedRowKeys.includes(item.id) && item.mode === "AVAILABLE"
    );

    if (stockItems.length > 0 && selectedRowKeys.length === stockItems.length) {
      history.push({
        pathname: `${path}/bin-transfer`,
        store: stockItems[0].owner,
        storeLocation: stockItems[0].stockBin.storeLocation,
        stockItems: stockItems,
      });
    } else {
      message.info("Please select only available stocks to be transfer");
    }
  };

  const handleGenerateBarcodes = () => {
    if (selectedRowKeys.length > 0) {
      const payload = {
        stockItems: selectedRowKeys.map((item) => ({
          id: item,
        })),
      };
      //console.log("Barcode PL:", payload);
      setLoadingBarcodes(true);
      materialDetailService
        .generateAndDownloadBarcodes(payload)
        .then((response) => {
          /* var blob=new Blob([response], {type:"application/pdf"});
          var link=document.createElement('a');
          link.href=window.URL.createObjectURL(blob);
          link.download="Report_"+new Date()+".pdf";
          link.click(); */

          const url = window.webkitURL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Barcodes_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLoadingBarcodes(false);
        })
        .catch((error) => {
          setLoadingBarcodes(false);
          message.error(error);
        });
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    if (selectedRowKeys.length > 0) {
      buttons.push(
        <span style={{ marginLeft: 8 }}>
          Selected {selectedRowKeys.length} items
        </span>
      );
    }
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      /* buttons.push(
        <CSVLink
          key="1"
          data={selectedItems}
          filename={"barcodes.csv"}
          headers={[
            { label: "Pack Serial", key: "packSerial" },
            { label: "Running No", key: "runningNo" },
            { label: "Type of Wash", key: "mergeNo" },
            { label: "Material Type", key: "itemCode" },
            { label: "Roll No", key: "quantity" },
            { label: "Roll Length", key: "netWeight" },
          ]}
        >
          <Button
            icon={<BarcodeOutlined />}
            disabled={selectedRowKeys.length === 0}
          ></Button>
        </CSVLink>
      ); */
      buttons.push(
        <Button
          key="barcode"
          icon={<BarcodeOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={handleGenerateBarcodes}
          loading={loadingBarcodes}
        ></Button>
      );
      buttons.push(
        <Button
          key="bin-transfer"
          disabled={selectedRowKeys.length === 0}
          onClick={handleBinTransfer}
        >
          Bin Transfer
        </Button>
      );
    }
    return buttons;
  };

  const getColumns = () => {
    let columns = [];
    /* columns.push({
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "16%",
      render: (text, record) => (
        <Link to={`/material-details/${record.id}/summary`}>{text}</Link>
      ),
    }); */
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      width: "8%",
      render: (text, record) => {
        return (
          <>
            <Link to={`/material-details/${record.id}/summary`}>
              {record.stockType.code}
            </Link>
            <Input value={record.id} hidden />
          </>
        );
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockType.name}>
            {record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "7%",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    if (
      loggedInCustomerCode &&
      ["PRYM", "SILUETA"].includes(loggedInCustomerCode)
    ) {
      columns.push({
        title: "Roll/Box No:",
        dataIndex: "runningNo",
        key: "runningNo",
        width: `${
          loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)
            ? "7%"
            : "%3"
        }`,
      });
    }
    if (loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Plant",
        dataIndex: "plant",
        key: "plant",
        width: "4%",
        render: (text, record) => {
          return record.plant && record.plant.name;
        },
      });
    }
    columns.push({
      title: "Invoice No:",
      key: "preReceiptNumber",
      width: "10%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          record.stockPreReceiptLine.stockPreReceipt.preReceiptNumber
        );
      },
    });
    if (loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Location",
        key: "storeLocation",
        width: "6%",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => {
          return (
            <Tooltip
              placeholder="topLeft"
              title={record.stockBin.storeLocation.address}
            >
              {record.stockBin.storeLocation.address}
            </Tooltip>
          );
        },
      });
    }
    columns.push({
      title: "Stock Bin",
      dataIndex: "stockBin",
      key: "stockBin",
      width: "6%",
      render: (text, record) => {
        return record.stockBin.code;
      },
    });
    if (loggedInCustomerCode && ["SILUETA"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Pallet No",
        dataIndex: "palletNo",
        key: "palletNo",
        width: "4%",
      });
    }
    /* columns.push({
      title: "Invoice Date",
      key: "createdAt",
      width: "7%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(record.stockPreReceiptLine.stockPreReceipt.orderDate).format(
            "YYYY-MM-DD"
          )
        );
      },
    }); */
    columns.push({
      title: "Delivery No:",
      key: "deliveryNumber",
      width: "10%",
      render: (text, record) => {
        return (
          record.issuedLine &&
          record.issuedLine.stockIssue &&
          record.issuedLine.stockIssue.issueNumber
        );
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      width: "8%",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    if (loggedInCustomerCode && ["STR-RM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Units",
        dataIndex: "quantity",
        key: "quantity",
        width: "5%",
      });
    }
    if (loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: "3%",
      });
    }
    columns.push({
      title: `Net Measure ${
        loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)
          ? "(Kg)"
          : ""
      }`,
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: `Gross Measure ${
        loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)
          ? "(Kg)"
          : ""
      }`,
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    if (loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        width: "5%",
        render: (text, record) => {
          return text && Number(text).toFixed(3);
        },
      });
    }
    columns.push({
      title: "UOM",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "4%",
      render: (text, record) => {
        return record.measurementType && record.measurementType.name;
      },
    });
    columns.push({
      title: "Due Days",
      key: "dueDays",
      width: "5%",
      render: (text, record) => {
        return (
          record.stockPreReceiptLine &&
          record.stockPreReceiptLine.stockPreReceipt &&
          moment(moment()).diff(
            record.stockPreReceiptLine.stockPreReceipt.orderDate,
            "days"
          )
        );
      },
    });
    /* 
    {
      title: "Owner",
      key: "owner",
      render: (text, record) => {
        return record.owner.code;
      },
    }, */

    return columns;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-details">Material Detail Search</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner>
          <MaterailDetailSearch
            showSearchLoading={setLoading}
            onSearchResults={handleSearchResults}
            loggedInCustomerCode={loggedInCustomerCode}
          />
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={getColumns()}
            dataSource={items}
            rowSelection={rowSelection}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: totalRecords,
              showTotal: (total) => `Total ${total} items`,
              showSizeChanger: true,
              onChange: handlePaginateChange,
            }}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      colSpan={
                        loggedInCustomerCode &&
                        ["PRYM"].includes(loggedInCustomerCode)
                          ? getColumns().length - 6
                          : ["SILUETA"].includes(loggedInCustomerCode)
                          ? 4
                          : getColumns().length - 3
                      }
                    >
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong style={{ paddingLeft: 12 }}>
                        {totalRecords}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    {loggedInCustomerCode &&
                      ["SILUETA"].includes(loggedInCustomerCode) && (
                        <Table.Summary.Cell colSpan={4}></Table.Summary.Cell>
                      )}
                    <Table.Summary.Cell>
                      <Text strong>
                        {!isNaN(totalGrossMeasure) &&
                          Number(totalGrossMeasure).toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
};

export default List;
