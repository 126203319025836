import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { sbuService, subLocationService, zoneService } from '_services';

function Search(props) {
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getBusinessUnits();
    getZones();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleSbuClear = () => {
    form.setFieldsValue({ zone: null });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    if (values.code) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.name) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `name=${encodeURIComponent(values.name)}`;
    }
    if (values.zone) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `zoneId=${encodeURIComponent(values.zone)}`;
    }
    if (values.sbu) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `sbuId=${encodeURIComponent(values.sbu)}`;
    }
    subLocationService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='sbu' label='SBU'>
            <Select
              placeholder='Please select'
              allowClear
              onChange={(e) => {
                form.setFieldsValue({ zone: null });
                setSelectedSbuId(e);
              }}
              onClear={handleSbuClear}
            >
              {businessUnits.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='code' label='Code'>
            <Input placeholder='Code' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='name' label='Name'>
            <Input placeholder='First name' />
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='zone' label='Zone'>
            <Select
              placeholder='Please select'
              allowClear
              disabled={!selectedSbuId}
            >
              {zones
                .filter((item) => item.sbu.id === selectedSbuId)
                .map((t) => (
                  <Select.Option value={t.id} key={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}></Col>
      </Row>
    </Form>
  );
}

export default Search;
