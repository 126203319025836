export const Utils = {
  groupBy,
};

function groupBy(list, keyGetter) {
  //console.log("Groupby...");
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    if (key) {
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    }
  });
  //console.log("Groupby...", map);
  return map;
}
