import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Table,
  Tag,
  Popconfirm,
  Typography,
  Tooltip,
  Divider,
  Tabs,
  Modal,
  Checkbox,
  Spin,
} from "antd";
import moment from "moment";
import Page from "_components/Page";
import {
  companyService,
  materialDetailService,
  materialRequestService,
  stockIssueService,
} from "_services";
import { Auth } from "_helpers";
import StockSelection from "./StockSelection";

function StrGrConfirm({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [isLoading, setIsLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loadingStockItem, setLoadingStockItem] = useState(false);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [stockIssue, setStockIssue] = useState({});
  const [selectedIssueDate, setSelectedIssueDate] = useState(Date());
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchMRLoading, setSearchMRLoading] = useState(false);
  const [addedStocks, setAddedStocks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [selectedRowKeyMap, setSelectedRowKeyMap] = useState(new Map());
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [stockSearchForm] = Form.useForm();
  const [isStockSelectionVisible, setIsStockSelectionVisible] = useState(false);
  const [availableStocks, setAvailableStocks] = useState([]);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [lotNumberId, setLotNumberId] = useState(null);
  const { Option } = Select;
  const { Text } = Typography;

  const [showRequestLines, setShowRequestLines] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedRequestLineId, setSelectedRequestLineId] = useState(null);
  const [requestedLines, setRequestedLines] = useState([]);
  const [issuedLines, setIssuedLines] = useState([]);

  const [selectedIssueLineKeys, setSelectedIssueLineKeys] = useState([]);

  const allSelectedRequests = [];

  useEffect(() => {
    const fetchAlldatas = async () => {
      setIsLoading(true);
      const customerData = await companyService.getSuggestion(
        "?companyType=CUSTOMER"
      );
      const storeData = await companyService.getSuggestion(
        "?companyType=STORE"
      );
      const stockIssueData = await stockIssueService.getById(id);
      if (stockIssueData) {
        let persistedStockIssue = stockIssueData.content;

        persistedStockIssue.issuedDate = moment(persistedStockIssue.issuedDate);
        persistedStockIssue.deliveredDate = persistedStockIssue.deliveredDate
          ? moment(persistedStockIssue.deliveredDate)
          : moment();
        persistedStockIssue.issuedLines.forEach((line, i) => {
          line.key = `${line.stockType.id}_${line.lotNumber ? line.lotNumber.id : ""}`;
          line.requestLineAdded = true;
          line.deliveredQuantity = 0;

          line.requestNumber = line.requestLine.issueRequest.requestNumber;
          line.requestedQuantity = line.requestLine.requestedItems.length;
          line.requestedNetMeasure =
            line.requestLine.requestedItems.length > 0 &&
            line.requestLine.requestedItems
              .map((item) => item.netWeight)
              .reduce((prev, next) => prev + next);

          if (
            !allSelectedRequests.find(
              (req) => req.id === line.requestLine.issueRequest.id
            )
          ) {
            allSelectedRequests.push(line.requestLine.issueRequest);
          }

          for (const item of line.issuedItems) {
            if (item.mode === "ISSUED") {
              line.issuedQuantity = line.issuedQuantity
                ? line.issuedQuantity + 1
                : 1;
              line.issuedNetWeight = line.issuedNetWeight
                ? line.issuedNetWeight + item.netWeight
                : item.netWeight;
              line.issuedGrossWeight = line.issuedGrossWeight
                ? line.issuedGrossWeight + item.grossWeight
                : item.grossWeight;
            }
          }

          setAvailableStocks([...availableStocks, ...line.issuedItems]);

          /* if (activeTabKey === null) {
            handleTabChange(line.key);
          } */
          issuedLines.push(line);
        });

        for (const issuedRequest of allSelectedRequests) {
          const requestData = await materialRequestService.getById(
            issuedRequest.id
          );

          if (requestData.content && requestData.content.requestLines) {
            for (const requestLine of requestData.content.requestLines) {
              const issuedLineForRequestLine =
                persistedStockIssue.issuedLines.find(
                  (issuedLine) => issuedLine.requestLine.id === requestLine.id
                );
              // console.log(
              //   "issuedLines:",
              //   persistedStockIssue.issuedLines
              // );
              // console.log("Request lines: ", requestData.content.requestLines);
              if (!issuedLineForRequestLine) {
                //console.log("Matched:", issuedLine.requestLine.id, ", ReqId:", requestLine.id);
                let notAddedIssuedLine = {};
                notAddedIssuedLine.key = `${requestLine.stockType.id}_${requestLine.lotNumber.id}`;
                notAddedIssuedLine.requestLineAdded = false;
                notAddedIssuedLine.deliveredQuantity = 0;
                notAddedIssuedLine.issuedQuantity = 0;
                notAddedIssuedLine.stockType = requestLine.stockType;
                notAddedIssuedLine.lotNumber = requestLine.lotNumber;
                notAddedIssuedLine.measurementType =
                  requestLine.measurementType;

                notAddedIssuedLine.requestNumber =
                  requestData.content.requestNumber;
                notAddedIssuedLine.requestedQuantity =
                  requestLine.requestedItems.length;
                notAddedIssuedLine.requestedNetMeasure =
                  requestLine.requestedItems.length > 0 &&
                  requestLine.requestedItems
                    .map((item) => item.netWeight)
                    .reduce((prev, next) => prev + next);

                issuedLines.push(notAddedIssuedLine);
              }
            }
          }
        }

        setStockIssue(persistedStockIssue);
        setIssuedLines([].concat(issuedLines));
        setSelectedCustomer(persistedStockIssue.customer);
        onFill(persistedStockIssue);
        form.setFieldsValue({
          materialRequest: selectedRequest && selectedRequest.id,
        });
        if (activeTabKey === null) {
          handleTabChange(issuedLines[0].key);
        }
      }

      setStores(storeData.content);
      setCustomers(customerData.content);
      // console.log("Issued Lines", issuedLines);
      setIsLoading(false);
    };

    fetchAlldatas();
  }, []);

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        const issuedItems = stockIssue.issuedLines.flatMap(
          (line) => line.issuedItems
        );
        if (issuedItems && issuedItems.length === 0) {
          setError("Delivery should have at least one delivery item");
        } else {
          setUpdating(true);
          delete values.stockItem;
          delete values.materialRequest;

          values.issueNumber = stockIssue.issueNumber;
          values.issuedDate = moment(values.issuedDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          values.issuedLines = issuedLines
            .filter((line) => line.requestLineAdded)
            .map((line) => ({
              id: line.id,
              requestLine: { id: line.requestLine.id },
              quantity: line.issuedItems.filter((item) => item.added).length,
              stockType: { id: line.stockType.id },
              lotNumber: line.lotNumber ? { id: line.lotNumber.id } : null,
              issuedItems: line.issuedItems
                .filter((item) => item.added)
                .map((item) => ({
                  id: item.id,
                })),
            }));

          //console.log("OUT: ", values);
          // console.log("OUT: ", JSON.stringify(values));
          stockIssueService
            .confirm(id, values)
            .then((data) => {
              console.log("Response:", data.content);
              setUpdating(false);
              if (data.content.orderStage === "PROCESSING") {
                message.success("Successfully updated!");
                window.location.reload();
              } else {
                message.success("Successfully completed!");
                history.push(`/stock-issue/${id}/summary`);
              }
            })
            .catch((error) => {
              setUpdating(false);
              setError(error);
            });
        }
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onSelectChange = (rowKeys) => {
    // console.log("RowKeys:", rowKeys);
    setSelectedRowKeyMap(selectedRowKeyMap.set(activeTabKey, rowKeys));
    updateStockItemStatus();
  };

  const handleAllLoadChange = (e) => {
    if (e.target.checked) {
      issuedLines.forEach((line, index) => {
        setSelectedRowKeyMap(
          selectedRowKeyMap.set(
            line.key,
            line.issuedItems.map((item) => item.id)
          )
        );
      });
      updateStockItemStatus();
    } else {
      setSelectedRowKeyMap(new Map());
      const totalIssuedStocks = issuedLines.flatMap((item) => item.issuedItems);
      for (let item of totalIssuedStocks) {
        item.added = false;
        item.mode = "REQUESTED";
      }
      setAddedStocks([]);
      issuedLines.forEach((line) => {
        line.issuedQuantity = 0;
        line.issuedNetWeight = 0;
      });
    }
  };

  const updateStockItemStatus = () => {
    let allSelectedKeys = [];
    for (let value of selectedRowKeyMap.values()) {
      allSelectedKeys.push(...value);
    }

    // console.log("all row keys:", allSelectedKeys);
    setSelectedRowKeys(allSelectedKeys);
    const totalRequestedStocks = issuedLines
      .filter((line) => line.requestLineAdded)
      .flatMap((item) => item.issuedItems);
    let loadedStocks = [];
    for (let item of totalRequestedStocks) {
      if (allSelectedKeys.includes(item.id)) {
        item.added = true;
        item.mode = "LOADED";
        loadedStocks.push(item);
      } else {
        item.added = false;
        item.mode = "REQUESTED";
      }
    }
    setAddedStocks(loadedStocks);

    issuedLines.forEach((line) => {
      line.issuedQuantity = 0;
      line.issuedNetWeight = 0;
      loadedStocks.forEach((stock) => {
        if (line.requestLine.id === stock.requestedLine.id) {
          line.issuedQuantity += 1;
          line.issuedNetWeight += stock.netWeight;
        }
      });
    });
    // console.log("Issued LInes: ", issuedLines);
    // console.log("Loaded Stocks:", loadedStocks);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeyMap.get(activeTabKey),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.mode === "ISSUED",
    }),
  };

  const addRequestLineToDelivery = (requestLineId) => {
    if (
      requestLineId &&
      !requestedLines.map((item) => item.id).includes(requestLineId)
    ) {
      setSearchMRLoading(true);

      let requestLine = selectedRequest.requestLines.find(
        (item) => item.id === requestLineId
      );
      requestLine.issueRequest = selectedRequest;

      const issuedLine = {
        key: `${requestLine.stockType.id}_${requestLine.lotNumber.id}`,
        stockType: requestLine.stockType,
        lotNumber: requestLine.lotNumber,
        requestLine: requestLine,
        measurementType: requestLine.measurementType,
        requestNumber: selectedRequest.requestNumber,
        requestedQuantity:
          typeof requestLine.requestedItems !== "undefined"
            ? requestLine.requestedItems.length
            : requestLine.quantity,
        requestedTotalQuantity: requestLine.quantity,
        requestedNetMeasure:
          typeof requestLine.requestedItems !== "undefined"
            ? requestLine.requestedItems.length > 0 &&
              requestLine.requestedItems
                .map((item) => item.netWeight)
                .reduce((prev, next) => prev + next)
            : requestLine.quantity,
        issuedItems:
          typeof requestLine.requestedItems !== "undefined"
            ? requestLine.requestedItems
                .filter((item) => item.mode === "REQUESTED")
                .map((t) => ({
                  ...t,
                  added: false,
                  requestLineId: requestLine.id,
                }))
            : [],
        issuedQuantity: 0,
        issuedNetWeight: 0,
        issuedGrossWeight: 0,
      };

      //stockIssue.issuedLines.push(issuedLine);
      //issuedLines.push(issuedLine);
      setStockIssue(stockIssue);
      setIssuedLines([...issuedLines, issuedLine]);
      setRequestedLines([...requestedLines, requestLine]);
      setSearchMRLoading(false);
      form.setFieldsValue({ materialRequestLine: null });
      console.log("Issued Lines:", issuedLines);

      if (activeTabKey === null) {
        handleTabChange(issuedLine.key);
      }
    } else {
      message.info("Already added to the delivery");
    }
  };

  const onSearchAndAddStockItem = (value) => {
    // Barcode should contain MaterialTypeCode|LotNumber|RunningNo
    if (value) {
      setLoadingStockItem(true);
      materialDetailService
        .search(`?serialNo=${encodeURIComponent(value)}`)
        .then((data) => {
          if (data.content && data.content[0]) {
            let stock = data.content[0];
            let matchedIssueLine = null;

            const deliveryItem = issuedLines
              .flatMap((line) => line.issuedItems)
              .find((item) => item.id === stock.id);
            if (deliveryItem) {
              if (deliveryItem.added) {
                message.info("Already loaded this stock item");
              } else {
                for (const line of issuedLines) {
                  if (line.issuedItems.find((item) => item.id === stock.id)) {
                    handleTabChange(line.key);
                    matchedIssueLine = line;
                  }
                }
                //setSelectedRowKeyMap([...selectedRowKeys, stock.id]);
                //selectedRowKeyMap.get(activeTabKey).push(stock.id);
                //rowSelection.selectedRowKeys = [];
                const lineKey = `${matchedIssueLine.stockType.id}_${matchedIssueLine.lotNumber ? matchedIssueLine.lotNumber.id : ""}`;
                if (selectedRowKeyMap.has(lineKey)) {
                  selectedRowKeyMap.set(lineKey, [
                    ...selectedRowKeyMap.get(lineKey),
                    stock.id,
                  ]);
                } else {
                  selectedRowKeyMap.set(lineKey, [stock.id]);
                }
                updateStockItemStatus();
                //console.log("Row Map: ", selectedRowKeyMap, ", LineKye:", lineKey);
                //console.log("Row Map: ", selectedRowKeyMap.get(lineKey ));
                message.success("Successfully loaded to the delivery");
              }
            } else {
              message.error("Sorry, No item found against the added stocks");
            }
            form.setFieldsValue({ stockItem: null });
          } else {
            message.error("Sorry, No item found against the barcode.");
          }
          setLoadingStockItem(false);
        })
        .catch((error) => {
          message.error(`${error}`);
          setLoadingStockItem(false);
        });
    } else {
      message.error("Please enter valid barcode.");
    }
  };

  const issueLineRowSelection = {
    selectedRowKeys: selectedIssueLineKeys,
    onChange: (selectedIssueLineKeys) => {
      //console.log("Selected:", selectedIssueLineKeys);
      handleTabChange(selectedIssueLineKeys[0]);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.requestLineAdded,
      //name: record.name,
    }),
  };

  const onCancel = () => {
    history.push(`/stock-issue/${id}/edit`);
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey.toString());
    setSelectedIssueLineKeys([activeKey.toString()]);
  };

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const getLineColumns = () => {
    let columns = [];
    columns.push({
      title: "Request No:",
      dataIndex: "requestNumber",
      key: "requestNumber",
      with: "15%",
    });
    columns.push({
      title: "Material Type",
      key: "stockTypeCode",
      width: "12%",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a.stockType &&
        a.stockType.code.localeCompare(b.stockType && b.stockType.code),
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "15%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip
            placeholder="topLeft"
            title={record.stockType && record.stockType.name}
          >
            {record.stockType && record.stockType.name}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "10%",
      render: (text, record, index) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Requested Boxes",
      dataIndex: "requestedQuantity",
      key: "requestedQuantity",
      align: "right",
    });
    columns.push({
      title: "Requested Net Weight(Kg)",
      dataIndex: "requestedNetMeasure",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Loaded Boxes",
      dataIndex: "issuedQuantity",
      key: "quantity",
      align: "right",
    });
    columns.push({
      title: "Loaded Net Weight(Kg)",
      dataIndex: "issuedNetWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return !isNaN(text) && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  const getStockItemColumns = () => {
    let columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
      render: (text, record) => {
        return record.stockType && record.stockType.code;
      },
    });
    columns.push({
      title: "Batch Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      render: (text, record) => {
        return record.lotNumber && record.lotNumber.number;
      },
    });
    columns.push({
      title: "Box Serial",
      dataIndex: "serialNo",
      key: "serialNo",
      /* align: "right", */
    });
    columns.push({
      title: "Stock Bin",
      key: "stockBin",
      render: (text, record) => {
        return record.stockBin && record.stockBin.code;
      },
    });
    columns.push({
      title: "Status",
      dataIndex: "mode",
      key: "mode",
      render: (text) => {
        let color = "";
        if (text === "AVAILABLE") {
          color = "#87d068";
        }
        if (text === "REQUESTED") {
          color = "#2db7f5";
        }
        if (text === "ISSUED") {
          color = "#108ee9";
        }
        if (text === "DELIVERED") {
          color = "#f50";
        }
        if (text === "LOADED") {
          color = "#87d068";
        }
        if (text === "READY") {
          color = "#faad14";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Net Weight(Kg)",
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });
    columns.push({
      title: "Gross Weight(Kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(2);
      },
    });

    return columns;
  };

  const handleStockSelection = (stockIds) => {
    setSelectedStockIds(stockIds);
  };

  const handleAddStocks = () => {
    //console.log("Selected:", selectedStockIds);
    //console.log("Available:", availableStocks);
    let selectedStocks = availableStocks.filter((item) =>
      selectedStockIds.includes(item.id)
    );
    if (selectedStocks) {
      issuedLines.forEach((line) => {
        if (
          line.stockType.id === materialTypeId &&
          line.lotNumber.id === lotNumberId
        ) {
          line.issuedItems = selectedStocks.filter(
            (item) =>
              item.stockType.id === materialTypeId &&
              item.lotNumber.id === lotNumberId
          );
          line.issuedQuantity = line.issuedItems.length;
          line.issuedNetWeight = line.issuedItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
          handleTabChange(line.key);
          /* line.issuedQuantity = line.issuedItems.length;
          line.issuedNetWeight = line.issuedItems
            .map((item) => item.netWeight)
            .reduce((prev, next) => prev + next);
          line.issuedGrossWeight = line.issuedItems
            .map((item) => item.grossWeight)
            .reduce((prev, next) => prev + next); */
        }
      });
    }
    setIsStockSelectionVisible(false);
    setSelectedStockIds([]);
    setMaterialTypeId(null);
    setLotNumberId(null);
  };

  const getTabContent = (stockIssueLines) => {
    //console.log("IssuedLinesTab:", stockIssueLines);
    let tabPanes = [];
    stockIssueLines.forEach((line, index) => {
      if (
        line.requestLineAdded &&
        line.issuedItems &&
        line.issuedItems.length > 0
      ) {
        tabPanes.push(
          <Tabs.TabPane
            tab={`${line.stockType && line.stockType.code} / ${
              line.lotNumber && line.lotNumber.number ? line.lotNumber.number : "" 
            } (${line.issuedQuantity ? line.issuedQuantity : 0})`}
            key={`${line.stockType.id}_${line.lotNumber ? line.lotNumber.id : ""}`}
          >
            <Table
              bordered
              size="small"
              rowKey="id"
              columns={getStockItemColumns()}
              dataSource={line.issuedItems}
              pagination={false}
              rowClassName={(record) => (record.added ? "ant-tag-green" : "")}
              rowSelection={rowSelection}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;

                pageData.forEach(({ quantity, netWeight, grossWeight }) => {
                  totalQuantity += quantity ? quantity : 1;
                  //totalQuantity += quantity;
                  totalNetWeight += netWeight;
                  totalGrossWeight += grossWeight;
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={getStockItemColumns().length - 2}
                      >
                        <Text strong>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong style={{ paddingLeft: 12 }}>
                          {totalQuantity}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>{Number(totalNetWeight).toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text strong>
                          {Number(totalGrossWeight).toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Tabs.TabPane>
        );
      }
    });

    return tabPanes;
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to issue these stocks?"
        onConfirm={handleFormSumbit}
        key="update-confirm"
        //disabled={addedStocks.length === 0}
      >
        <Button
          key="1"
          type="primary"
          htmlType="submit"
          loading={updating}
          //disabled={addedStocks.length === 0}
        >
          Confirm
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="2" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Issued Stocks Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag color="#108ee9">{stockIssue.orderStage}</Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: false, message: "Store is required" }]}
                  >
                    <Select placeholder="Please select">
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: false, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="issuedDate"
                    label="Issue Date"
                    rules={[
                      { required: false, message: "Issue date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Issue date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      onChange={setSelectedIssueDate}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="vehicleNumber"
                    label="Vehicle Number"
                    rules={[
                      {
                        required: false,
                        message: "Vehicle Number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Vehicle Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverName"
                    label="Driver Name"
                    rules={[
                      { required: false, message: "Driver Name is required" },
                    ]}
                  >
                    <Input placeholder="Driver Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact Number"
                    rules={[
                      {
                        required: false,
                        message: "Contact Number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="driverNic"
                    label="Driver NIC"
                    rules={[
                      { required: false, message: "Driver NIC is required" },
                    ]}
                  >
                    <Input placeholder="Driver NIC" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item
                    name="sealNumber"
                    label="Seal No:"
                    rules={[
                      { required: false, message: "Seal number is required" },
                    ]}
                  >
                    <Input placeholder="Seal No:" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="dispatchNumber"
                    label="Dispatch No:"
                    rules={[
                      {
                        required: false,
                        message: "Dispatch number is required",
                      },
                    ]}
                  >
                    <Input placeholder="Dispatch No:" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  {showRequestLines && (
                    <Form.Item
                      name="materialRequestLine"
                      label="Select Material Request Line"
                    >
                      <Select
                        placeholder="Please select"
                        allowClear={true}
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => setSelectedRequestLineId(e)}
                      >
                        {selectedRequest.requestLines &&
                          selectedRequest.requestLines.length > 0 &&
                          selectedRequest.requestLines.map((t) => (
                            <Option
                              value={t.id}
                              key={t.id}
                              style={{
                                color: `${
                                  requestedLines.find(
                                    (item) => item.id === t.id
                                  )
                                    ? "red"
                                    : ""
                                }`,
                              }}
                            >
                              {t.stockType.code} | {t.lotNumber.number} (
                              {t.quantity} {t.measurementType.name})
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}>
                  {showRequestLines && (
                    <Form.Item label={<span></span>}>
                      <Button
                        type="primary"
                        onClick={() => {
                          addRequestLineToDelivery(selectedRequestLineId);
                        }}
                        loading={searchMRLoading}
                        disabled={!form.getFieldValue("materialRequestLine")}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  )}
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
              {issuedLines.length > 0 &&
                issuedLines.map((item) => item.issuedItems) &&
                issuedLines.map((item) => item.issuedItems).length > 0 && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item name="stockItem">
                        <Input.Search
                          loading={loadingStockItem}
                          placeholder="Enter valid barcode"
                          allowClear
                          enterButton="Search & Add Stock"
                          onSearch={onSearchAndAddStockItem}
                          style={{ marginBottom: 20 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

              {
                /* stockIssue &&
                stockIssue.issuedLines &&
                stockIssue.issuedLines.length > 0 && */ issuedLines.length >
                  0 && (
                  <Table
                    bordered
                    pagination={false}
                    columns={getLineColumns()}
                    dataSource={issuedLines}
                    //expandable={{ expandedRowRender, defaultExpandAllRows: true }}
                    rowSelection={{
                      type: "radio",
                      ...issueLineRowSelection,
                    }}
                    rowClassName={(record) =>
                      record.requestLineAdded
                        ? record.requestedQuantity <= record.issuedQuantity
                          ? "ant-tag-green"
                          : record.issuedNetWeight > 0
                          ? "ant-tag-red"
                          : ""
                        : "ant-tag-purple"
                    }
                    size="small"
                    summary={(pageData) => {
                      let totalRequestedQuantity = 0;
                      let totalRequestedNetMeasure = 0;
                      let totalQuantity = 0;
                      let totalNetWeight = 0;

                      pageData.forEach(
                        ({
                          requestedQuantity,
                          issuedQuantity,
                          issuedNetWeight,
                          requestedNetMeasure,
                        }) => {
                          totalRequestedQuantity += requestedQuantity;
                          totalQuantity += issuedQuantity;
                          totalNetWeight += issuedNetWeight;
                          totalRequestedNetMeasure += requestedNetMeasure;
                        }
                      );

                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={5}>
                              <Text strong>Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>{totalRequestedQuantity}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalRequestedNetMeasure) &&
                                  Number(totalRequestedNetMeasure).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalQuantity) && totalQuantity}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong>
                                {!isNaN(totalNetWeight) &&
                                  Number(totalNetWeight).toFixed(2)}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                )
              }
            </Form>
            {
              /* stockIssue &&
              stockIssue.issuedLines && */
              issuedLines.length > 0 &&
                issuedLines.map((item) => item.issuedItems) &&
                issuedLines
                  .map((item) => item.issuedItems)
                  .reduce((prev, next) => prev + next).length > 0 && (
                  <>
                    <Divider orientation="left">
                      Stock Items -{" "}
                      <Checkbox onChange={handleAllLoadChange}>
                        Load All
                      </Checkbox>
                    </Divider>
                    <Tabs
                      //defaultActiveKey={issuedLines[0].key}
                      onChange={handleTabChange}
                      activeKey={activeTabKey}
                    >
                      {getTabContent(issuedLines)}
                    </Tabs>
                  </>
                )
            }
            <Modal
              title={`Stocks (${availableStocks.length})`}
              style={{ top: 48 }}
              visible={isStockSelectionVisible}
              okText={`Add Stocks (${selectedStockIds.length})`}
              onOk={handleAddStocks}
              //okButtonProps={{ disabled: selectedStockIds.length === 0 }}
              onCancel={() => setIsStockSelectionVisible(false)}
              //destroyOnClose={true}
              width={1300}
            >
              <StockSelection
                stocks={availableStocks}
                selectedIds={selectedStockIds}
                //customer={selectedCustomer}
                onSelectChange={handleStockSelection}
              />
            </Modal>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrGrConfirm;
