import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { sbuService, subLocationService, zoneService } from '_services';

function Add({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getBusinessUnits();
    getZones();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        console.log('Val:', JSON.stringify(values));
        subLocationService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push('.');
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        // message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push('.');
  };

  const handleSbuClear = () => {
    form.setFieldsValue({ zone: null });
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/sub-locations'>Sub-Location Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/sub-locations/add'>New Sub-Location</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key='1'
            htmlType='submit'
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key='2' htmlType='button' onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name='form-create'
            layout='vertical'
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name={['sbu', 'id']}
                  label='SBUs'
                  rules={[
                    {
                      required: true,
                      message: 'SBU is required',
                    },
                  ]}
                >
                  <Select
                    placeholder='Please select'
                    allowClear
                    onChange={(e) => {
                      form.setFieldsValue({ zone: null });
                      setSelectedSbuId(e);
                    }}
                    onClear={handleSbuClear}
                  >
                    {businessUnits.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['zone', 'id']}
                  label='Zone'
                  rules={[
                    {
                      required: true,
                      message: 'Zone is required',
                    },
                  ]}
                >
                  <Select
                    placeholder='Please select'
                    allowClear
                    disabled={!selectedSbuId}
                  >
                    {zones
                      .filter((x) => x.sbu.id === selectedSbuId)
                      .map((t) => (
                        <Option value={t.id} key={t.id}>
                          {t.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='code'
                  label='Code'
                  rules={[{ required: true, message: 'Code is required' }]}
                >
                  <Input placeholder='Code' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='name'
                  label='Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder='Name' />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
