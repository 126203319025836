import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { vehicleService, vehicleTypeService } from '_services';

function Search(props) {
  const [form] = Form.useForm();
  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(() => {
    getVehicleTypes();
  }, []);

  const getVehicleTypes = () => {
    vehicleTypeService
      .getSuggestion()
      .then((x) => {
        setVehicleTypes(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = '';
    if (values.number) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `number=${encodeURIComponent(values.number)}`;
    }
    if (values.vehicleType) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `typeId=${encodeURIComponent(values.vehicleType)}`;
    }
    vehicleService
      .search(searchParams)
      .then((data) => {
        props.onSearchResults(
          data.content,
          data.pagination.totalRecords,
          searchParams
        );
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='number' label='Vehicle Number'>
            <Input placeholder='Code' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='vehicleType' label='Vehicle Type'>
            <Select placeholder='Please select' allowClear>
              {vehicleTypes.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
