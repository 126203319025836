import {
  Button,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Select,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Page from '_components/Page';
import { Auth, StockCheckTypes } from '_helpers';
import {
  binService,
  companyService,
  extraService,
  lotNumberService,
  materialTypeService,
  sbuService,
  stockCheckService,
  subLocationService,
  supplierService,
  zoneService,
} from '_services';

const Add = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [selectedcustomer, setSelectedcustomer] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [error, setError] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState('Loading...');
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [selectedSbuId, setSelectedSbuId] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const isLoggedInCustomer = () => {
    return Auth.hasRole(Auth.getProfile(), ['CAU']);
  };

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion('?companyType=STORE').then((x) => {
      if (x.content.length === 1) {
        setSelectedStore(x.content[0]);
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === 'WAREHOUSE'
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === 'OFFICE'
      );
      form.setFieldsValue({
        storeLocation:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        customerAddress: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setCustomerAddresses(officeAddresses);
      setcustomers([data.content]);
      setSelectedcustomer(data.content);

      getStockTypes();
      getLotNumbers();
      getSuppliers();
      getBusinessUnits();
      getZones();
      getSubLocations();
      //getStoreBins();

      setIsLoading(false);
    });
  }, []);

  const getStoreAddresses = (companyId) => {
    form.resetFields(['storeAddress']);

    companyService.getById(companyId).then((x) => {
      setSelectedStore(x.content);
      setStoreAddresses(
        x.content.addresses.filter(
          (address) => address.addressType === 'WAREHOUSE'
        )
      );
    });
  };

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getSuppliers = () => {
    supplierService
      .getSuggestion()
      .then((x) => {
        setSuppliers(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getStoreBins = () => {
    binService
      .getSuggestion('')
      .then((y) => {
        setStoreBins(y.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search('')
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search('')
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingMessage(
          'Stock check creation is in progress. Please check the status later...'
        );
        setIsLoading(true);

        values.startDate = moment(values.startDate).format('YYYY-MM-DD');
        /* values.fromDate = moment().format('YYYY-MM-DD');
        values.toDate = moment().format('YYYY-MM-DD');
        if (!values.stockType.id) {
          delete values.stockType;
        }
        if (!values.lotNumber.id) {
          delete values.lotNumber;
        }
        if (values.stockBins) {
          values.stockBins = values.stockBins.map((binId) => ({ id: binId }));
        }
        if (!values.supplier.id) {
          delete values.supplier;
        } */

        // console.log('PL: ', values);
        stockCheckService
          .create(values)
          .then(() => {
            setLoadingMessage('Loading...');
            message.success('Successfully created!');
            setIsLoading(false);
            history.push('.');
          })
          .catch((error) => {
            setLoadingMessage('Loading...');
            setIsLoading(false);
            setError(error);
          });

        setTimeout(() => {
          setLoadingMessage('Loading...');
          setIsLoading(false);
          history.push('.');
        }, 60000);
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    // document.location.reload();
    history.push('/stock-check');
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title='Are you sure to proceed?'
        onConfirm={handleFormSumbit}
        key='add-confirm'
      >
        <Button
          key='btn-upload'
          type='primary'
          htmlType='submit'
          loading={isSaving}
        >
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key='btn-cancel' htmlType='button' onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Stock Check</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip={loadingMessage} spinning={isLoading}>
            <Form
              layout='vertical'
              form={form}
              name='form-create'
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={['customer', 'id']}
                    label='Customer'
                    rules={[
                      { required: true, message: 'Customer is required' },
                    ]}
                  >
                    <Select placeholder='Please select' disabled allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='stockCheckType'
                    label='Stock Check Type'
                    rules={[
                      {
                        required: true,
                        message: 'Stock Check Type is required',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp='children'
                      placeholder='Check Type'
                    >
                      {StockCheckTypes.map((t) => (
                        <Select.Option value={t.value} key={t.value}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='startDate'
                    label='Start Date'
                    rules={[
                      { required: true, message: 'Start date is required' },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder='Start date'
                      format='YYYY-MM-DD'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name='sbuId' label='SBU' rules={[
                      { required: true, message: 'SBU is required' },
                    ]}>
                    <Select
                      placeholder='Please select'
                      allowClear
                      optionFilterProp='children'
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedSbuId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ zoneId: null });
                        form.setFieldsValue({ subLocationId: null });
                      }}
                    >
                      {businessUnits.length > 0 &&
                        businessUnits.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* <Form.Item
                    name='counterDate'
                    label='Stock Count Date'
                    rules={[
                      { required: true, message: 'Counter date is required' },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder='Counter date'
                      format='YYYY-MM-DD'
                      style={{ width: '100%' }}
                    />
                  </Form.Item> */}

                <Col span={4}></Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={['storeLocation', 'id']}
                    label='Store Address'
                    rules={[
                      {
                        required: true,
                        message: 'Store address is required',
                      },
                    ]}
                  >
                    <Select placeholder='Please select' allowClear>
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  {/* <Form.Item
                    name='stockBins'
                    label='Bin'
                    rules={[{ required: false, message: 'Bin is required' }]}
                  >
                    <Select
                      placeholder='Please select'
                      allowClear
                      optionFilterProp='children'
                      showSearch
                      mode='multiple'
                    >
                      {storeBins.length > 0 &&
                        storeBins.map((t) => (
                          <Select.Option value={t.id} key={t.id}>
                            {t.code}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item> */}
                  <Form.Item
                    name='description'
                    label='Description'
                    rules={[
                      { required: true, message: 'Description is required' },
                    ]}
                  >
                    <Input placeholder='Description' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name='note' label='Note'>
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name='zoneId' label='Zone' rules={[
                      { required: true, message: 'Zone is required' },
                    ]}>
                    <Select
                      placeholder='Please select'
                      allowClear
                      optionFilterProp='children'
                      showSearch
                      onChange={(e) => {
                        form.setFieldsValue({ subLocationId: null });
                        setSelectedZoneId(e);
                      }}
                      onClear={() => {
                        form.setFieldsValue({ subLocationId: null });
                      }}
                      disabled={!selectedSbuId}
                    >
                      {zones.length > 0 &&
                        zones
                          .filter((item) => item.sbu.id === selectedSbuId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={6}>
                  <Form.Item name='palletNo' label='Pallet No'>
                    <Input placeholder='Pallet No' allowClear={true} />
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={24}>
                <Col span={4}>
                  {/* <Form.Item name={['stockType', 'id']} label='Material'>
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp='children'
                      placeholder='Material'
                    >
                      {stockTypes.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </Col>
                <Col span={4}>
                  {/* <Form.Item
                    name={['lotNumber', 'id']}
                    label='Lot Number'
                    rules={[
                      { required: false, message: 'Lot Number is required' },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp='children'
                      placeholder='Lot Number'
                    >
                      {lotNumberList.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </Col>
                <Col span={4}>
                  {/* <Form.Item name={['supplier', 'id']} label='Supplier'>
                    <Select
                      showSearch
                      allowClear
                      showArrow={false}
                      optionFilterProp='children'
                      placeholder='Supplier'
                    >
                      {suppliers.map((t) => (
                        <Select.Option value={t.id} key={t.id}>
                          {t.name} ({t.code})
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </Col>
                <Col span={6}>
                  <Form.Item name='stockBinCode' label='Stock Bin'>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='subLocationId'
                    label='Sub-Location'
                    rules={[
                      {
                        required: true,
                        message: 'Please select Sub-Location',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Please select'
                      allowClear
                      optionFilterProp='children'
                      showSearch
                      disabled={!selectedZoneId}
                    >
                      {subLocations.length > 0 &&
                        subLocations
                          .filter((item) => item.zone.id === selectedZoneId)
                          .map((t) => (
                            <Select.Option value={t.id} key={t.id}>
                              {t.name}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default Add;
