import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  PageHeader,
  Spin,
  Tag,
  Table,
  message,
  Tooltip,
} from "antd";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import { documentService, stockIssueService } from "_services";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import moment from "moment";

function StrRmDocument({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loadingSummaryPdf, setLoadingSummaryPdf] = useState(false);
  const [stockIssue, setStockIssue] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const issueData = await stockIssueService.getById(id);

      if (issueData) {
        setStockIssue(issueData.content);
      }
      setLoading(false);
    };

    fetchAlldatas();
    getStockIssueDocuments(id);
  }, []);

  const getStockIssueDocuments = (receiptId) => {
    setLoading(true);
    stockIssueService
      .getDocuments(receiptId)
      .then((x) => {
        setItems(x.content);
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        setError(errorInfo);
      });
  };

  const onCancel = () => {
    history.push(`/stock-issue/${id}/summary`);
  };

  const getActionButtons = () => {
    let buttons = [];
    if (items.length === 0) {
      buttons.push(
        <Tooltip key="tp-summary-pdf" placement="topLeft" title="PDF">
          <Button
            key="summary-pdf"
            icon={<FilePdfOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handlePrintPdf();
            }}
            loading={loadingSummaryPdf}
          ></Button>
        </Tooltip>
      );
    }
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  const handleDownloadFile = (documentObj) => {
    setDownloading(true);
    documentService
      .downloadDocument(documentObj.id)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${documentObj.documentName}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        setError(error);
      });
  };

  const handlePrintPdf = () => {
    setLoadingSummaryPdf(true);
    stockIssueService
      .printPdf(id)
      .then((response) => {
        message.success("Successfully created!");
        setLoadingSummaryPdf(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoadingSummaryPdf(false);
        setError(error);
      });
  };

  const columns = [
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      key: "documentName",
    },
    {
      title: "",
      key: "downloadFile",
      render: (text, record) => {
        return (
          <span>
            <Button
              icon={<DownloadOutlined />}
              title="Download Document"
              type="link"
              style={{ marginRight: 10 }}
              loading={downloading}
              onClick={() => handleDownloadFile(record)}
            ></Button>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-issue">Stock Issue Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockIssue.issueNumber} ::{" "}
                <Tag
                  color={`${
                    stockIssue.orderStage === "OPEN"
                      ? "#2db7f5"
                      : stockIssue.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {stockIssue.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>History</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="documents" id={id} status={stockIssue.orderStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Table
              bordered
              rowKey="id"
              size="small"
              columns={columns}
              dataSource={items}
              pagination={false}
            />
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmDocument;
