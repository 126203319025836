import {
  Breadcrumb,
  PageHeader,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
//import { PDFDownloadLink } from "@react-pdf/renderer";
import Page from "_components/Page";
//import { PdfAvailableStocks } from "./PdfAvailableStocks";
import {
  reportService,
  companyService,
  materialTypeService,
  lotNumberService,
} from "_services";
import { Auth } from "_helpers";
import AvailableStockSearch from "./AvailableStockSearch";
import ExportExcel from "_helpers/ExportExcel";
import { Button } from "antd";


/* const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
}); */

const AvailableStocks = () => {
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [items, setItems] = useState([]);
  const { Title } = Typography;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  const precision = ["PRYM"].includes(Auth.getProfile().comp_code) ? 3 : 2;

  useEffect(() => {
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        let companyId = Auth.getProfile().comp_id
          ? Auth.getProfile().comp_id
          : x.content[0].id;
        companyService.getById(companyId).then((data) => {
          const storeLocationAddresses = data.content.addresses.filter(
            (item) => item.addressType === "WAREHOUSE"
          );
          setAddresses(storeLocationAddresses);
          getItems(storeLocationAddresses.map((item) => item.id).join());
          /* form.setFieldsValue({
            storeId: x.content[0].id,
            storeLocationId: storeLocationAddresses[0].id,
          }); */
        });
      }
      //setCompanies(x.content);
    });
    getStockTypes();
    getLotNumbers();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getItems = (storeLocationIds) => {
    setLoading(true);
    let searchParams = "";
    searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
    searchParams += `fromDate=${encodeURIComponent(
      moment("2021-01-01").format("YYYY-MM-DD")
    )}&toDate=${moment().format(
      "YYYY-MM-DD"
    )}&storeLocations=${storeLocationIds}`;
    reportService
      .getAvailableStocks(searchParams)
      .then((data) => {
        if (data.content.length > 0) {
          let index = 0;
          data.content.forEach((item) => {
            item.key = index++;
          });
          setItems(data.content);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearchResults = (results, fromDate, toDate, isFilterEnabled) => {
    setItems(results);
    setFromDate(fromDate);
    setToDate(toDate);
    setDateFilter(isFilterEnabled);
    setLoading(false);
  };

  /* const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  ); */

  const handlePrintExel = () => {
    ExportExcel(['Material Type', 'Description', 'Vendor Batch', 'Rolls', 'Net Measure', 'Gross Measure'], items);
  }

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "Material Type",
      dataIndex: "stockType",
      key: "stockType",
    });
    columns.push({
      title: "Description",
      key: "stockTypeName",
      width: "30%",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placeholder="topLeft" title={record.stockTypeName}>
            {record.stockTypeName}
          </Tooltip>
        );
      },
    });
    columns.push({
      title: `${
        ["SILUETA"].includes(loggedInCustomerCode)
          ? "Vendor Batch"
          : "Lot/Batch Number"
      }`,
      dataIndex: "lotNumber",
      key: "lotNumber",
    });
    if (Auth.hasRole(Auth.getProfile(), ["SAU"])) {
      columns.push({
        title: "Customer",
        dataIndex: "supplier",
        key: "supplier",
      });
    }
    columns.push({
      title: `${
        loggedInCustomerCode &&
        ["PRYM", "SILUETA"].includes(loggedInCustomerCode)
          ? "Rolls/Box"
          : "Units"
      }`,
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: "7%"
    });
    if (loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Gross Qty",
        dataIndex: "grossQuantity",
        key: "grossQuantity",
        align: "right",
        render: (text, record) => {
          return text && Number(text).toFixed(precision);
        },
      });
    }
    columns.push({
      title: `Net ${
        loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)
          ? "(Kg)"
          : "Measure"
      }`,
      dataIndex: "netWeight",
      key: "netWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });
    columns.push({
      title: `Gross ${
        loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)
          ? "(Kg)"
          : "Measure"
      }`,
      dataIndex: "grossWeight",
      key: "grossWeight",
      align: "right",
      render: (text, record) => {
        return text && Number(text).toFixed(precision);
      },
    });
    if (loggedInCustomerCode && ["PRYM"].includes(loggedInCustomerCode)) {
      columns.push({
        title: "Gross (Sq.m)",
        dataIndex: "grossValueSqm",
        key: "grossValueSqm",
        align: "right",
        render: (text, record) => {
          return text && Number(text).toFixed(precision);
        },
      });
    }

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Available Stocks</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <AvailableStockSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              addresses={addresses}
              stockTypes={stockTypes}
              lotNumberList={lotNumberList}
            />
            {!dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: {moment().format("YYYY-MM-DD")}
              </Title>
            )}
            {dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: From:{moment(fromDate).format("YYYY-MM-DD")} - To:
                {moment(toDate).format("YYYY-MM-DD")}
              </Title>
            )}
            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={(pageData) => {
                let totalQuantity = 0;
                let totalNetWeight = 0;
                let totalGrossWeight = 0;
                let totalGrossQuantity = 0;
                let totalGrossSqm = 0;

                pageData.forEach(
                  ({
                    quantity,
                    netWeight,
                    grossWeight,
                    grossQuantity,
                    grossValueSqm,
                  }) => {
                    totalQuantity += quantity;
                    totalNetWeight += netWeight;
                    totalGrossWeight += grossWeight;
                    totalGrossQuantity += grossQuantity;
                    totalGrossSqm += grossValueSqm;
                  }
                );

                return (
                  <>
                  <div style={{width:"100%"}} >
                  <Button type="primary">Export to Excel</Button>
                  </div>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        colSpan={
                          loggedInCustomerCode &&
                          ["PRYM"].includes(loggedInCustomerCode)
                            ? getColumns().length - 5
                            : getColumns().length - 3
                        }
                      >
                        <Typography.Text strong>Total</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalQuantity}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      {loggedInCustomerCode &&
                        ["PRYM"].includes(loggedInCustomerCode) && (
                          <Table.Summary.Cell align="right">
                            <Typography.Text strong>
                              {Number(totalGrossQuantity).toFixed(precision)}
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalNetWeight &&
                            Number(totalNetWeight).toFixed(precision)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>
                          {totalGrossWeight &&
                            Number(totalGrossWeight).toFixed(precision)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                      {loggedInCustomerCode &&
                        ["PRYM"].includes(loggedInCustomerCode) && (
                          <Table.Summary.Cell align="right">
                            <Typography.Text strong>
                              {totalGrossSqm &&
                                Number(totalGrossSqm).toFixed(precision)}
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default AvailableStocks;
