import React from "react";
import { Radio, Steps } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

function TabMenu(props) {
  let history = useHistory();

  const handleMenuChange = (e) => {
    history.push(`/material-requests/${props.id}/${e.target.value}`);
  };

  return (
    <div>
      <Steps style={{marginBottom: 20}} size="small"
        items={[
          {
            title: "Open",
            status: props.status === 'OPEN' ? "process" : "finish",
            icon: props.status === 'OPEN' ? <LoadingOutlined /> : false,
          },
          {
            title: "Verification",
            status: props.status === 'VERIFICATION' ? "process" : props.status === 'OPEN' ? "wait" : "finish",
            icon: props.status === 'VERIFICATION' ? <LoadingOutlined /> : false,
          },
          {
            title: "Prepare",
            status: props.status === 'PREPARE' ? "process" : ['VERIFICATION', "OPEN"].includes(props.status) ? "wait" : "finish",
            icon: props.status === 'PREPARE' ? <LoadingOutlined /> : false,
          },
          {
            title: "Ready",
            status: props.status === 'READY' ? "process" : ["OPEN", 'VERIFICATION', "PREPARE"].includes(props.status) ? "wait" : "finish",
            icon: props.status === 'READY' ? <LoadingOutlined /> : false,
          },
          {
            title: "Processing",
            status: props.status === 'PROCESSING' ? "process" : ["OPEN", 'VERIFICATION', "PREPARE", "READY"].includes(props.status) ? "wait" : "finish",
            icon: props.status === 'PROCESSING' ? <LoadingOutlined /> : false,
          },
          {
            title: "Complete",
            status: props.status === 'COMPLETE' ? "finish" : "wait",
            icon: false,
          },
        ]}
      />
      <Radio.Group
        value={props.menu}
        onChange={handleMenuChange}
        style={{ marginBottom: 10 }}
      >
        <Radio.Button value="summary" style={{ marginRight: 5 }}>
          Summary
        </Radio.Button>
        <Radio.Button value="edit" style={{ marginRight: 5 }} disabled={!["OPEN", "VERIFICATION", "PREPARE"].includes(
            props.status
          )}>
          Update
        </Radio.Button>
        <Radio.Button value="prepare" style={{ marginRight: 5 }} disabled={props.status !== 'PREPARE'}>
          Prepare
        </Radio.Button>
        <Radio.Button value="history" style={{ marginRight: 5 }}>
          History
        </Radio.Button>
        <Radio.Button value="documents" style={{ marginRight: 5 }}>
          Documents
        </Radio.Button>
      </Radio.Group>
    </div>
  );
}

export default TabMenu;
