import {
  Breadcrumb,
  Button,
  PageHeader,
  Table,
  Popconfirm,
  message,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import {
  binLocationService,
  binService,
  sbuService,
  stockCheckService,
  subLocationService,
  zoneService,
} from "_services";
import TabMenu from "../../TabMenu";
import { Auth } from "_helpers";
import AddSearch from "../AddSearch";

function SiluetaAdd({ match, history }) {
  const { id } = match.params;
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [stockCheck, setStockCheck] = useState({});
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const { Text } = Typography;

  useEffect(() => {
    setLoading(true);

    stockCheckService
      .getById(id)
      .then((data) => {
        setStockCheck(data.content);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleAddLocations = () => {
    const payload = {
      locations: selectedRowKeys.map((itemId) => {
        return { stockBin: { id: itemId }, stockType: null };
      }),
    };
    // console.log('PL: ', payload);

    setSavingData(true);
    stockCheckService
      .addLocations(stockCheck.id, payload)
      .then(() => {
        setSavingData(false);
        history.push(`/stock-check/${stockCheck.id}/locations`);
      })
      .catch((error) => {
        setSavingData(false);
        setError(error);
      });
  };

  const handleSearchResults = (results) => {
    setLocations(results);
    setLoading(false);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
    getCheckboxProps: (record) => ({
      disabled:
        stockCheck &&
        stockCheck.locations &&
        stockCheck.locations.find((item) => item.stockBin.id === record.id),
    }),
  };

  const getAdditionalButtons = () => {
    let buttons = [];
    if (selectedRowKeys.length > 0) {
      buttons.push(
        <span key="stat" style={{ marginLeft: 8 }}>
          Selected {selectedRowKeys.length} bins
        </span>
      );
    }
    if (Auth.hasRole(Auth.getProfile(), ["SAU", "CAU"])) {
      buttons.push(
        <Button
          key="add"
          disabled={selectedRowKeys.length === 0}
          onClick={handleAddLocations}
          loading={savingData}
        >
          Save
        </Button>
      );
    }
    return buttons;
  };

  const columns = [
    {
      title: "Bin Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "SBU",
      dataIndex: "sbuName",
      key: "sbuName",
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      key: "zoneName",
    },
    {
      title: "Sub-Location",
      dataIndex: "subLocationName",
      key: "subLocationName",
    },
    {
      title: "Available Stocks",
      dataIndex: "availableStocks",
      key: "availableStocks",
    },
    {
      title: "Available Quantity",
      dataIndex: "availableQuantity",
      key: "availableQuantity",
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/stock-check">Stock Check Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {stockCheck.code} ::{" "}
                <Tag
                  color={`${
                    stockCheck.checkStatus === "COMPLETE"
                      ? "#87d068"
                      : stockCheck.checkStatus === "IN_PROGRESS"
                      ? "#faad14"
                      : "#2db7f5"
                  }`}
                >
                  {stockCheck.checkStatus}
                </Tag>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/stock-check/${id}/locations`}>Locations</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Add</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getAdditionalButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="locations" id={id} />
          {!loading && (
            <AddSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              businessUnits={businessUnits}
              zones={zones}
              subLocations={subLocations}
              subLocation={stockCheck.subLocation}
            />
          )}
          <Table
            bordered
            loading={loading}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={locations}
            pagination={false}
            rowSelection={rowSelection}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={4}>
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text strong>{locations.length}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {locations.length > 0 &&
                        locations
                          .map((item) => item.availableStocks)
                          .reduce((prev, next) => prev + next)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {locations.length > 0 &&
                        Number(
                          locations
                            .map((item) => item.availableQuantity)
                            .reduce((prev, next) => prev + next)
                        ).toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Page>
      </PageHeader>
    </div>
  );
}

export default SiluetaAdd;
