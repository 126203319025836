import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Page from "_components/Page";
import { reasonService } from "_services";
import { ReasonTypes } from "_helpers";

function Add({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;


  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        console.log("Val:", JSON.stringify(values));
        reasonService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        // message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push(".");
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reason">Reason Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reason/add">New Reason</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key="1"
            htmlType="submit"
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key="2" htmlType="button" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name="form-create"
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[{ required: true, message: "Code is required" }]}
                >
                  <Input placeholder="Code" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="type"
                  label="Reason Type"
                  rules={[{ required: true, message: "Type is required" }]}
                >
                  <Select placeholder="Please select">
                    {ReasonTypes.map((t) => (
                      <Option value={t.value} key={t.value}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
