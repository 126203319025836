import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Space,
  message,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import { PriorityStatuses } from "_helpers";

const RequestTableForm = ({
  value,
  onChange,
  materialTypes,
  lotNumbers,
  measurementTypes,
  onFindStock,
  ...props
}) => {
  //console.log("Values: ", value);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(value);
  const precision = 2;
  const { Option } = Select;
  const { Text } = Typography;

  const getRowByKey = (key, newData) =>
    (newData || data)?.filter((item) => item.key === key)[0];

  const handleAddOrderLine = () => {
    const newData = data?.map((item) => ({ ...item })) || [];

    newData.push({
      key: `NEW_TEMP_ID_${index}`,
      quantity: null,
    });

    setIndex(index + 1);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const handleRemoveOrderLine = (key) => {
    const newData = data?.filter((item) => item.key !== key);
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const handleSaveOrderLine = (e, key) => {
    e.persist();
    const target = getRowByKey(key) || {};
    // console.log("Targe:", target);
    if (props.sbu === "GP") {
      if (
        !target.supplier ||
        !target.department ||
        !target.mrnNumber ||
        !target.requestTime ||
        !target.stockType ||
        !target.lotNumber ||
        !target.measurementType ||
        !target.quantity
      ) {
        message.error("Please complete the requested item information.");
        e.target.focus();
        return;
      } else {
        handleAddOrderLine();
      }
    } else {
      if (
        !target.department ||
        !target.mrnNumber ||
        !target.requestTime ||
        !target.stockType ||
        !target.lotNumber ||
        !target.measurementType ||
        !target.quantity
      ) {
        message.error("Please complete the requested item information.");
        e.target.focus();
        return;
      } else {
        handleAddOrderLine();
      }
    }
  };

  const handleFieldChange = (value, fieldName, key) => {
    const newData = [...data];
    const target = getRowByKey(key, newData);
    if (target) {
      if (
        [
          "department",
          "stockType",
          "lotNumber",
          "measurementType",
          "machine",
          "supplier",
        ].includes(fieldName)
      ) {
        target[fieldName] = { id: value };
      } else if (fieldName === "quantity") {
        target[fieldName] = value;
        if (target.uom === "KG") {
          target.netWeight = value;
        } else if (target.uom === "BOX") {
          target.totalItems = value;
        } else if (target.uom === "CONE") {
          target.qty = value;
        }
      } else {
        target[fieldName] = value;
      }
      // console.log('Data:', newData);
      setData(newData);
    }
  };

  const getLineColumns = () => {
    let columns = [];
    if (props.sbu === "GP") {
      columns.push({
        title: "Supplier",
        dataIndex: "supplier",
        key: "supplier",
        width: "5%",
        render: (text, record) => {
          return (
            <Select
              value={record.supplier && record.supplier.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "supplier", record.key)}
            >
              {props.suppliers.length > 0 &&
                props.suppliers
                  .filter((item) => item.code.includes("GP_"))
                  .map((t) => (
                    <Option value={t.id} key={t.id}>
                      {t.name}
                    </Option>
                  ))}
            </Select>
          );
        },
      });
    }
    columns.push({
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.department && record.department.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "department", record.key)}
          >
            {props.departments.length > 0 &&
              props.departments.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.code}
                </Option>
              ))}
          </Select>
        );
      },
    });
    columns.push({
      title: "MRN No.",
      dataIndex: "mrnNumber",
      key: "mrnNumber",
      render: (text, record) => {
        return (
          <InputNumber
            value={text}
            onChange={(e) => handleFieldChange(e, "mrnNumber", record.key)}
            min={0}
            placeholder="MRN No"
          />
        );
      },
    });
    columns.push({
      title: "Req. Time",
      dataIndex: "requestTime",
      key: "requestTime",
      width: "17%",
      render: (text, record) => {
        return (
          <DatePicker
            value={moment(text)}
            onChange={(e) => handleFieldChange(e, "requestTime", record.key)}
            placeholder="Request date"
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
          />
        );
      },
    });
    columns.push({
      title: "Material",
      dataIndex: "stockType",
      key: "stockType",
      width: "10%",
      render: (text, record) => {
        return (
          <Select
            value={record.stockType && record.stockType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "stockType", record.key)}
          >
            {materialTypes.length > 0 &&
              materialTypes.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.code}
                </Option>
              ))}
          </Select>
        );
      },
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
      width: "15%",
      render: (text, record) => {
        return (
          <Select
            value={record.lotNumber && record.lotNumber.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => handleFieldChange(e, "lotNumber", record.key)}
          >
            {lotNumbers &&
              lotNumbers.length > 0 &&
              lotNumbers.map((t) => (
                <Option value={t.id} key={t.id}>
                  {t.number}
                </Option>
              ))}
          </Select>
        );
      },
    });
    columns.push({
      title: "Measurement",
      dataIndex: "measurementType",
      key: "measurementType",
      width: "3%",
      render: (text, record) => {
        return (
          <Select
            value={record.measurementType && record.measurementType.id}
            placeholder="Please select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) =>
              handleFieldChange(e, "measurementType", record.key)
            }
          >
            {measurementTypes &&
              measurementTypes.length > 0 &&
              measurementTypes
                .filter((item) => ["BOX", "CONE", "KG"].includes(item.name))
                .map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.name}
                  </Option>
                ))}
          </Select>
        );
      },
    });
    columns.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        return (
          <InputNumber
            value={
              record.uom === "Kg"
                ? record.netWeight
                : record.uom === "BOX"
                ? record.totalItems
                : record.uom === "CONE"
                ? record.qty
                : null
            }
            onChange={(e) => handleFieldChange(e, "quantity", record.key)}
            min={0}
            placeholder="Box"
          />
        );
      },
    });
    if (props.sbu !== "GP") {
      columns.push({
        title: "Priority Status",
        dataIndex: "priorityStatus",
        key: "priorityStatus",
        width: "4%",
        render: (text, record) => {
          return (
            <Select
              value={text}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) =>
                handleFieldChange(e, "priorityStatus", record.key)
              }
            >
              {PriorityStatuses.map((t) => (
                <Option value={t.value} key={t.value}>
                  {t.value}
                </Option>
              ))}
            </Select>
          );
        },
      });
      columns.push({
        title: "Machine",
        dataIndex: "machine",
        key: "machine",
        width: "4%",
        render: (text, record) => {
          return (
            <Select
              value={record.machine && record.machine.id}
              placeholder="Please select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleFieldChange(e, "machine", record.key)}
            >
              {props.machines &&
                props.machines.length > 0 &&
                props.machines.map((t) => (
                  <Option value={t.id} key={t.id}>
                    {t.code}
                  </Option>
                ))}
            </Select>
          );
        },
      });
    }
    if (props.sbu === "GP") {
      columns.push({
        title: "Width",
        dataIndex: "width",
        key: "widht",
        width: "10%",
        render: (text, record) => {
          return (
            <Input
              value={text}
              onChange={(e) =>
                handleFieldChange(e.target.value, "width", record.key)
              }
              min={0}
              placeholder="Width"
              allowClear
            />
          );
        },
      });
      columns.push({
        title: "C.Code",
        dataIndex: "colorCode",
        key: "colorCode",
        width: "2%",
        render: (text, record) => {
          return (
            <InputNumber
              value={text}
              onChange={(e) => handleFieldChange(e, "colorCode", record.key)}
              min={0}
              placeholder="Color Code"
              allowClear
            />
          );
        },
      });
    }
    columns.push({
      title: "",
      key: "operation",
      render: (text, record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Are you sure to remove?"
              onConfirm={() => handleRemoveOrderLine(record.key)}
              key="remove-confirm"
            >
              <Button
                size="small"
                icon={<CloseOutlined />}
                //onClick={() => handleRemoveOrderLine(record.key)}
              ></Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveOrderLine(e, record.key);
              }}
              style={{ padding: 5, fontSize: 12 }}
            >
              +More
            </Button>
          </Space>
        );
      },
    });

    return columns;
  };

  return (
    <>
      {data.length === 0 && (
        <Button
          style={{
            width: "100%",
            marginTop: 16,
            marginBottom: 8,
            background: "rgb(190, 200, 200)",
          }}
          type="dashed"
          onClick={handleAddOrderLine}
          icon={<PlusOutlined />}
        >
          Add Item
        </Button>
      )}
      <Table
        bordered
        columns={getLineColumns()}
        dataSource={data}
        pagination={false}
        size="small"
        summary={(pageData) => {
          let totalBox = 0;
          let totalNetWeight = 0;

          pageData.forEach(({ quantity, totalItems }) => {
            totalBox += totalItems;
            totalNetWeight += quantity;
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={9}>
                <Text strong>Total</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong style={{ paddingLeft: 12 }}>
                  {pageData.length}
                </Text>
              </Table.Summary.Cell>
              {/* <Table.Summary.Cell>
                <Text strong style={{ paddingLeft: 12 }}>
                  {Number(totalNetWeight).toFixed(2)}
                </Text>
              </Table.Summary.Cell> */}
              <Table.Summary.Cell />
            </Table.Summary.Row>
          );
        }}
      />
    </>
  );
};

export default RequestTableForm;
