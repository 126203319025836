import React from "react";
import { Route, Switch } from "react-router-dom";
import Add from "./Add";
import Upload from "./Upload";
import List from "./List";
import Summary from "./Summary";
import UploadVarience from "./UploadVarience";

import StrRmAdd from "./str_rm/Add";
import StrRmUpload from "./str_rm/Upload";
import StrRmEdit from "./str_rm/Edit";
import StrRmList from "./str_rm/List";
import StrRmSummary from "./str_rm/Summary";
import StrRmGrn from "./str_rm/Grn";
import StrRmHistory from "./str_rm/History";
import StrRmDocument from "./str_rm/Document";
import StrRmValidate from "./str_rm/Validate";
import StrRmRelease from "./str_rm/Release";
import StrRmImageUpload from "./str_rm/ImageUpload";

import PrymUpload from "./prym/Upload";
import PrymAdd from "./prym/Add";
import PrymList from "./prym/List";
import PrymGrn from "./prym/Grn";
import PrymSummary from "./prym/Summary";

import { Auth } from "_helpers";
import SiluetaList from "./silueta/List";
import SiluetaAdd from "./silueta/Add";
import SiluetaGrn from "./silueta/Grn";
import SiluetaUpload from "./silueta/Upload";
import SiluetaSummary from "./silueta/Summary";

function PurchaseOrders({ match }) {
  const { path } = match;
  const loggedInCustomerCode = Auth.getProfile().comp_code;
  if (loggedInCustomerCode === "SILUETA") {
    return (
      <Switch>
        <Route exact path={path} component={SiluetaList} />
        <Route path={`${path}/add`} component={SiluetaAdd} />
        <Route path={`${path}/upload`} component={SiluetaUpload} />
        <Route path={`${path}/upload-variance`} component={UploadVarience} />
        <Route path={`${path}/:id/summary`} component={SiluetaSummary} />
        <Route path={`${path}/:id/grn`} component={SiluetaGrn} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "PRYM") {
    return (
      <Switch>
        <Route exact path={path} component={PrymList} />
        <Route path={`${path}/add`} component={PrymAdd} />
        <Route path={`${path}/upload`} component={PrymUpload} />
        <Route path={`${path}/upload-variance`} component={UploadVarience} />
        <Route path={`${path}/:id/summary`} component={PrymSummary} />
        <Route path={`${path}/:id/grn`} component={PrymGrn} />
      </Switch>
    );
  } else if (loggedInCustomerCode === "STR") {
    return (
      <Switch>
        <Route exact path={path} component={StrRmList} />
        <Route path={`${path}/add`} component={StrRmAdd} />
        <Route path={`${path}/upload`} component={StrRmUpload} />
        <Route path={`${path}/upload-variance`} component={UploadVarience} />
        <Route path={`${path}/:id/summary`} component={StrRmSummary} />
        <Route path={`${path}/:id/validate`} component={StrRmValidate} />
        <Route path={`${path}/:id/grn`} component={StrRmGrn} />
        <Route path={`${path}/:id/edit`} component={StrRmEdit} />
        <Route path={`${path}/:id/history`} component={StrRmHistory} />
        <Route path={`${path}/:id/documents`} component={StrRmDocument} />
        <Route path={`${path}/:id/release`} component={StrRmRelease} />
        <Route path={`${path}/image-upload`} component={StrRmImageUpload} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/upload`} component={Upload} />
        <Route path={`${path}/upload-variance`} component={UploadVarience} />
        <Route path={`${path}/:id/summary`} component={Summary} />
      </Switch>
    );
  }
}

export { PurchaseOrders };
