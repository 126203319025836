export async function handleResponse(response) {
    if (response.status === 200 || response.status === 201) return response.data;
    if (response.status === 400) {
      // Handle server-side validations
      console.log("Validation: ", response);
      throw new Error("Server-side validation error.");
    }
    throw new Error(`Bad response : ${response.status}`);
  }
  
  export function handleError(error) {
    console.error("API call failed. " + error);
    throw error;
    //return error;
  }
  