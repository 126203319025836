import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, message, Checkbox } from 'antd';
import { binService } from '_services';

function Search(props) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.showSearchLoading(true);
    let searchParams = `?subLocationId=${props.subLocationId}&isParent=1`;
    if (values.code) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `code=${encodeURIComponent(values.code)}`;
    }
    if (values.isEmpty) {
      searchParams += searchParams.indexOf('?') === -1 ? '?' : '&';
      searchParams += `isEmpty=${encodeURIComponent(values.isEmpty)}`;
    }
    binService
      .searchWithPaging(searchParams)
      .then((data) => {
        props.onSearchResults(data.content, data.pagination.totalRecords, searchParams);
      })
      .catch((error) => {
        message.error(`${error}`);
        props.onSearchResults([], null, searchParams);
      });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className='search-form'
    >
      <Row gutter={24}>
        <Col span={20}>
          <Form.Item name='code' label='Code'>
            <Input placeholder='Code' allowClear />
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <div>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields();
                onFinish({});
              }}
            >
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Search;
