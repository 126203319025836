import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from "moment";
import Page from "_components/Page";
import { companyService, materialDetailService, reportService } from "_services";
import NumberInfo from "../components/NumberInfo";
import { Auth } from "_helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function StrGrHome() {
    const [loading, setLoading] = useState(false);
    const [dailySummary, setDailySummary] = useState({});
    const [dataChart, setDataChart] = useState({});
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    // set options
    const [barOptions, setBarOptions] = useState({
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Chart.js Bar Chart',
                },
            }
        }
    });

    useEffect(() => {
        setLoading(true);
        materialDetailService
            .getDailyStat()
            .then((data) => {
                setDailySummary(data.content);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
            });

        companyService.getSuggestion("?companyType=STORE").then((x) => {
            if (x.content.length === 1) {
                let companyId = Auth.getProfile().comp_id
                    ? Auth.getProfile().comp_id
                    : x.content[0].id;
                companyService.getById(companyId).then((data) => {
                    const storeLocationAddresses = data.content.addresses.filter(
                        (item) => item.addressType === "WAREHOUSE"
                    );
                    getItems(storeLocationAddresses.map((item) => item.id).join());
                });
            }
            //setCompanies(x.content);
        });
    }, []);

    const getItems = (storeLocationIds) => {
        let netWeights = [];
        let stockTypes = [];
        setLoading(true);
        let searchParams = "";
        searchParams += searchParams.indexOf("?") === -1 ? "?" : "&";
        searchParams += `fromDate=${encodeURIComponent(
            moment("2021-01-01").format("YYYY-MM-DD")
        )}&toDate=${moment().format(
            "YYYY-MM-DD"
        )}&storeLocations=${storeLocationIds}`;
        reportService
            .getAvailableStocks(searchParams)
            .then((data) => {
                if (data.content.length > 0) {
                    let index = 0;
                    data.content.forEach((item) => {
                        item.key = index++;
                        stockTypes.push(item.stockType);
                        netWeights.push(item.netWeight);
                    });
                    setDataChart({
                        labels: stockTypes,
                        datasets: [{
                            label: 'Net Weight (Kg)',
                            backgroundColor: 'rgb(255,165,0)',
                            borderColor: 'rgb(255,165,0)',
                            data: netWeights
                        }],
                    });
                    setItems(data.content);
                }

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <Page className="dashboard" error={error}>
            <Spin tip="Loading..." spinning={loading}>
                <Row gutter={24}>
                    {items.length > 0 && (
                        <Col span={6} style={{ marginBottom: 24 }}>
                            <NumberInfo
                                title="Total Stocks(Box)"
                                subTitle=""
                                gap={8}
                                total={items
                                    .map((item) => item.quantity)
                                    .reduce((prev, next) => prev + next)}
                                subTotal={17.1}
                            />
                        </Col>
                    )}
                    {items.length > 0 && (
                        <Col span={6} style={{ marginBottom: 24 }}>
                            <NumberInfo
                                title="Total Net Weight (Kg)"
                                subTitle=""
                                gap={8}
                                total={Number(
                                    items
                                        .map((item) => item.netWeight)
                                        .reduce((prev, next) => prev + next)
                                ).toFixed(2)}
                                subTotal={17.1}
                            />
                        </Col>
                    )}
                    {dailySummary.totalReceived && (
                        <Col span={4} style={{ marginBottom: 24 }}>
                            <NumberInfo
                                title="Today's Stocks-IN"
                                subTitle=""
                                gap={8}
                                total={dailySummary.totalReceived.quantity}
                                subTotal={17.1}
                                footer={`Net: ${dailySummary.totalReceived.netWeight !== null
                                    ? dailySummary.totalReceived.netWeight
                                    : 0
                                    } | Gross: ${dailySummary.totalReceived.grossWeight !== null
                                        ? dailySummary.totalReceived.grossWeight
                                        : 0
                                    }`}
                            />
                        </Col>
                    )}
                    {dailySummary.totalRequested && (
                        <Col span={4} style={{ marginBottom: 24 }}>
                            <NumberInfo
                                title="Today' Stocks-REQUESTED"
                                subTitle=""
                                gap={8}
                                total={dailySummary.totalRequested.quantity}
                                subTotal={17.1}
                                footer={`Net: ${dailySummary.totalRequested.netWeight !== null
                                    ? dailySummary.totalRequested.netWeight
                                    : 0
                                    } | Gross: ${dailySummary.totalRequested.grossWeight != null
                                        ? dailySummary.totalRequested.grossWeight
                                        : 0
                                    }`}
                            />
                        </Col>
                    )}
                    {dailySummary.totalIssued && (
                        <Col span={4} style={{ marginBottom: 24 }}>
                            <NumberInfo
                                title="Today' Stocks-OUT"
                                subTitle=""
                                gap={8}
                                total={dailySummary.totalIssued.quantity}
                                subTotal={17.1}
                                footer={`Net: ${dailySummary.totalIssued.netWeight !== null
                                    ? dailySummary.totalIssued.netWeight
                                    : 0
                                    } | Gross: ${dailySummary.totalIssued?.grossWeight !== null
                                        ? dailySummary.totalIssued?.grossWeight
                                        : 0
                                    }`}
                            />
                        </Col>
                    )}
                </Row>
                {items.length > 0 && <Bar data={dataChart} options={barOptions} />}
            </Spin>

        </Page>
    );
}

export default StrGrHome;

