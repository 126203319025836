import {
  Breadcrumb,
  PageHeader,
  Table,
  Tooltip,
  Typography,
  message,
  Statistic,
  Row,
  Col,
  Card,
  Button,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
//import { PDFDownloadLink } from "@react-pdf/renderer";
import Page from "_components/Page";
//import { PdfAvailableStocks } from "./PdfAvailableStocks";
import {
  reportService,
  companyService,
  materialTypeService,
  lotNumberService,
  supplierService,
  sbuService,
  zoneService,
  subLocationService,
} from "_services";
import { Auth } from "_helpers";
import AvailableStockSearch from "./AvailableStockSearch";
import { BarcodeOutlined, FileExcelOutlined } from "@ant-design/icons";
import RequestDeliveryLinesSearch from "./RequestDeliveryLinesSearch";

const StrRequestDeliveryLines = () => {
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [stockTypes, setStockTypes] = useState([]);
  const [lotNumberList, setLotNumberList] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const { Title } = Typography;
  const precision = 2;

  useEffect(() => {
    getStockTypes();
    getLotNumbers();
    getBusinessUnits();
    getZones();
    getSubLocations();
  }, []);

  const getStockTypes = () => {
    materialTypeService
      .getSuggestion()
      .then((x) => {
        setStockTypes(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const getLotNumbers = () => {
    lotNumberService
      .getSuggestion()
      .then((x) => {
        setLotNumberList(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getBusinessUnits = () => {
    sbuService
      .search("")
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getZones = () => {
    zoneService
      .search("")
      .then((x) => {
        setZones(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getSubLocations = () => {
    subLocationService
      .search("")
      .then((x) => {
        setSubLocations(x.content);
      })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  const handleSearchResults = (
    results,
    fromDate,
    toDate,
    isFilterEnabled,
    searchParams
  ) => {
    setItems(results);
    setFromDate(fromDate);
    setToDate(toDate);
    setDateFilter(isFilterEnabled);
    setSearchQuery(searchParams);
    setLoading(false);
  };

  const handleExportExcel = () => {
    setLoadingExcel(true);
    reportService
      .exportRequestDeliveryLinesExcel(searchQuery)
      .then((response) => {
        const url = window.webkitURL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Request_Delivery_Lines-Report_${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingExcel(false);
      })
      .catch((error) => {
        setLoadingExcel(false);
        message.error(error);
      });
  };

  const getColumns = () => {
    const columns = [];
    columns.push({
      title: "SBU",
      dataIndex: "sbuName",
      key: "sbuName",
    });
    columns.push({
      title: "Zone",
      dataIndex: "zoneName",
      key: "zoneName",
    });
    columns.push({
      title: "Sub-Location",
      dataIndex: "subLocationName",
      key: "subLocationName",
    });
    columns.push({
      title: "MRN #",
      dataIndex: "mrnNumber",
      key: "mrnNumber",
    });
    columns.push({
      title: "Material",
      dataIndex: "stockTypeCode",
      key: "stockTypeCode",
    });
    columns.push({
      title: "Lot No",
      dataIndex: "lotNumber",
      key: "lotNumber",
    });
    columns.push({
      title: "Request Date",
      dataIndex: "requestTime",
      key: "requestTime",
    });
    columns.push({
      title: "Req. UOM",
      dataIndex: "measurement",
      key: "measurement",
    });
    columns.push({
      title: "Requested Qty",
      dataIndex: "requestQuantity",
      key: "requestQuantity",
      align: "right",
    });
    columns.push({
      title: "Req. Status",
      dataIndex: "requestLineStatus",
      key: "requestLineStatus",
      render: (text) => {
        let color = "";
        if (text === "COMPLETE") {
          color = "#87d068";
        }
        if (text === "OPEN") {
          color = "#2db7f5";
        }
        if (text === "IN_PROGRESS") {
          color = "#108ee9";
        }
        if (["VERIFIED", "VERIFICATION_INPROGRESS"].includes(text)) {
          color = "#f50";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    });
    columns.push({
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
    });
    columns.push({
      title: "Delivered Qty",
      dataIndex: "deliveryQuantity",
      key: "deliveryQuantity",
      align: "right",
      //width: '10%',
      render: (text, record) => {
        if (["CONE", "BOX"].includes(record.measurement)) {
          return text && Number(text).toFixed(0);
        } else {
          return text && Number(text).toFixed(precision);
        }
      },
    });

    return columns;
  };

  return (
    <>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/reports">Reports</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Request/Delivery Line Report</Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Page inner>
          <>
            <RequestDeliveryLinesSearch
              showSearchLoading={setLoading}
              onSearchResults={handleSearchResults}
              stockTypes={stockTypes}
              lotNumberList={lotNumberList}
              subLocations={subLocations}
              zones={zones}
              businessUnits={businessUnits}
            />
            {!dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: {moment().format("YYYY-MM-DD")}
              </Title>
            )}
            {dateFilter && (
              <Title level={5} style={{ marginTop: 20 }}>
                As of: From:{moment(fromDate).format("YYYY-MM-DD")} - To:
                {moment(toDate).format("YYYY-MM-DD")}
              </Title>
            )}
            {items && items.length > 0 && (
              <div
                style={{
                  background: "#2d3147",
                  padding: "15px",
                  marginBottom: 15,
                }}
              >
                <Row gutter={24}>
                  <Col span={21}></Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={handleExportExcel}
                      style={{
                        marginBottom: 20,
                      }}
                      icon={<FileExcelOutlined />}
                      loading={loadingExcel}
                    >
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <Table
              bordered
              loading={loading}
              size="small"
              columns={getColumns()}
              dataSource={items}
              pagination={false}
              summary={() => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={getColumns().length - 1}>
                        <Typography.Text strong>Total</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Typography.Text strong>{items.length}</Typography.Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </>
        </Page>
      </PageHeader>
    </>
  );
};

export default StrRequestDeliveryLines;
