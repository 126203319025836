import {
  Button,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Select,
  Typography,
  Breadcrumb,
  Popconfirm,
  Col,
  Input,
  message,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import Page from "_components/Page";
import { Auth } from "_helpers";
import { companyService, extraService } from "_services";

const PrymUpload = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [storeAddresses, setStoreAddresses] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [customerAddresses, setcustomerAddresses] = useState([]);
  const [selectedcustomer, setSelectedcustomer] = useState(null);
  const [stores, setStores] = useState([]);
  const [base64File, setBase64File] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const isLoggedInCustomer = () => {
    return Auth.hasRole(Auth.getProfile(), ["CAU"]);
  };

  useEffect(() => {
    setIsLoading(true);
    companyService.getSuggestion("?companyType=STORE").then((x) => {
      if (x.content.length === 1) {
        form.setFieldsValue({
          store: x.content[0],
        });
      }
      setStores(x.content);
      setIsLoading(false);
    });

    let companyId = Auth.getProfile().comp_id;
    setIsLoading(true);
    companyService.getById(companyId).then((data) => {
      const grantedStoreAddresses = data.content.addresses.filter(
        (item) => item.addressType === "WAREHOUSE"
      );
      const officeAddresses = data.content.addresses.filter(
        (item) => item.addressType === "OFFICE"
      );
      form.setFieldsValue({
        storeAddress:
          grantedStoreAddresses.length === 1 ? grantedStoreAddresses[0] : null,
        customer: data.content,
        customerAddress: officeAddresses[0],
      });
      setStoreAddresses(grantedStoreAddresses);
      setcustomers([data.content]);
      setcustomerAddresses(officeAddresses);
      setSelectedcustomer(data.content);
      setIsLoading(false);
    });
  }, []);

  const getStoreAddresses = (companyId) => {
    form.resetFields(["storeAddress"]);

    companyService.getById(companyId).then((x) => {
      setStoreAddresses(
        x.content.addresses.filter(
          (address) => address.addressType === "WAREHOUSE"
        )
      );
    });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setUpLoading(true);
        // const formData = new FormData();
        // formData.append("file", values.file);
        /* let formData = new FormData();
          Object.keys(values).map((item) => {
            formData.append(item, values[item]);
          }); */

        const payload = {
          requestNumber: values.requestNumber
            ? `${selectedcustomer && selectedcustomer.code}-MRN-${
                values.requestNumber
              }`
            : null,
          customerId: values.customer.id,
          storeId: values.store.id,
          storeAddressId: values.storeAddress.id,
          requestDate: moment(values.requestDate).format("YYYY-MM-DD HH:mm:ss"),
          deliverFrom: values.storeAddress.id,
          deliverTo: values.customerAddress.id,
          contactPersonName: values.contactPersonName,
          contactNumber: values.contactNumber,
          base64FileString: base64File,
          originalFileName: originalFileName,
        };
        //console.log("PL: ", payload);
        extraService
          .uploadMaterialRequest(payload)
          .then(() => {
            message.success("Successfully uploaded!");
            setUpLoading(false);
            history.push(".");
          })
          .catch((error) => {
            setUpLoading(false);
            setError(error);
          });
      })
      .catch((error) => {
        //message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push("/material-requests");
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setOriginalFileName(e.target.files[0].name);
      let reader = new FileReader();
      reader.onload = function (e) {
        setBase64File(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    buttons.push(
      <Popconfirm
        title="Are you sure to add the changes?"
        onConfirm={handleFormSumbit}
        key="add-confirm"
      >
        <Button key="1" type="primary" htmlType="submit" loading={uploading}>
          Save
        </Button>
      </Popconfirm>
    );
    buttons.push(
      <Button key="cencel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );

    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/material-requests">Material Request</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Request Upload</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <Spin tip="Loading..." spinning={isLoading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["store", "id"]}
                    label="Store"
                    rules={[{ required: true, message: "Store is required" }]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      onChange={getStoreAddresses}
                    >
                      {stores.length > 0 &&
                        stores.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customer", "id"]}
                    label="Customer"
                    rules={[
                      { required: true, message: "Customer is required" },
                    ]}
                  >
                    <Select placeholder="Please select" disabled allowClear>
                      {customers.length > 0 &&
                        customers.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="requestDate"
                    label="Request Date"
                    rules={[
                      { required: true, message: "Request date is required" },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      placeholder="Request date"
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="requestNumber" label="Request No">
                    <Input
                      prefix={`${
                        selectedcustomer && selectedcustomer.code
                      }-MRN-`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name={["storeAddress", "id"]}
                    label="Delivery From"
                    rules={[
                      {
                        required: true,
                        message: "Delivery address is required",
                      },
                    ]}
                  >
                    <Select placeholder="Please select" allowClear>
                      {storeAddresses.length > 0 &&
                        storeAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={["customerAddress", "id"]}
                    label="Deliver To"
                    rules={[
                      { required: true, message: "Delivery from is required" },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      disabled={isLoggedInCustomer()}
                    >
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((t) => (
                          <Option value={t.id} key={t.id}>
                            {t.address}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person Name"
                    rules={[
                      {
                        required: true,
                        message: "Contact persion is required",
                      },
                    ]}
                  >
                    <Input placeholder="Contact persion" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact No:"
                    rules={[
                      { required: true, message: "Contact No. is required" },
                    ]}
                  >
                    <Input placeholder="Contact number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="file"
                    label="Material Request File"
                    rules={[
                      {
                        required: true,
                        message: "Valid excel file is required",
                      },
                    ]}
                    valuePropName="file"
                  >
                    <Input
                      type="file"
                      name="file"
                      onChange={onFileChange}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingTop: 40 }}>
                  <>
                    <CSVLink
                      key="1"
                      data={[]}
                      filename={"Sample-Material-Request-File.csv"}
                      headers={[
                        { label: "Item", key: "stockType" },
                        { label: "LOT", key: "lotNumber" },
                        { label: "Qty", key: "qty" },
                        { label: "UOM", key: "uom" },
                        { label: "Rolls/Box", key: "totalItems" },
                      ]}
                    >
                      Sample Upload File
                    </CSVLink>
                  </>
                </Col>
                <Col span={12}>
                  <Form.Item name="remark" label="Remark">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
};

export default PrymUpload;
