import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Select,
  Form,
  Row,
  Col,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '_components/Page';
import { sbuService, stockClassificationService, zoneService } from '_services';

function Add({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [stockClasses, setStockClasses] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getBusinessUnits();
    getStockClasses();
  }, []);

  const getBusinessUnits = () => {
    sbuService
      .search('')
      .then((x) => {
        setBusinessUnits(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const getStockClasses = () => {
    stockClassificationService
      .getSuggestion()
      .then((x) => {
        setStockClasses(x.content);
      })
      .catch((errorInfo) => {
        message.error(`${errorInfo}`);
      });
  };

  const handleFormSumbit = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        console.log('Val:', JSON.stringify(values));
        zoneService
          .create(values)
          .then(() => {
            setIsLoading(false);
            history.push('.');
          })
          .catch((error) => {
            setIsLoading(false);
            message.error(`${error}`);
          });
      })
      .catch((error) => {
        // message.error(`${error}`);
      });
  };

  const onCancel = () => {
    history.push('.');
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/dashboard'>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/zones'>Zones Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/zones/add'>New Zone</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          <Button
            key='1'
            htmlType='submit'
            loading={isLoading}
            onClick={handleFormSumbit}
          >
            Save
          </Button>,
          <Button key='2' htmlType='button' onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Page inner>
          <Form
            form={form}
            name='form-create'
            layout='vertical'
            requiredMark={false}
          >
            <Row gutter={24}>
            <Col span={5}>
                <Form.Item
                  name={['sbu', 'id']}
                  label='SBUs'
                  rules={[
                    {
                      required: true,
                      message: 'SBU is required',
                    },
                  ]}
                >
                  <Select placeholder='Please select' allowClear>
                    {businessUnits.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name='code'
                  label='Code'
                  rules={[{ required: true, message: 'Code is required' }]}
                >
                  <Input placeholder='Code' />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='name'
                  label='Name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder='Name' />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name='description'
                  label='Description'
                  rules={[
                    { required: true, message: 'Description is required' },
                  ]}
                >
                  <Input placeholder='Description' />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name={['stockClassification', 'id']}
                  label='Stock Classification'
                  rules={[
                    {
                      required: true,
                      message: 'Stock classification is required',
                    },
                  ]}
                >
                  <Select placeholder='Please select' allowClear>
                    {stockClasses.map((t) => (
                      <Option value={t.id} key={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Page>
      </PageHeader>
    </div>
  );
}

export default Add;
