import React from "react";
import { Route, Switch } from "react-router-dom";
import Summary from "./profile/Summary";
import { ChangePassword } from "./change-password/Index";

function Account({ match }) {
  const { path } = match;
  return (
    <Switch>
      <Route exact path={path} component={Summary} />
      <Route exact path={`${path}/profile`} component={Summary} />
      <Route path={`${path}/change-password`} component={ChangePassword} />
    </Switch>
  );
}

export { Account };
