import {
    Button,
    Form,
    PageHeader,
    Row,
    Breadcrumb,
    Popconfirm,
    Col,
    Input,
    message,
    Spin,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Page from "_components/Page";
import { extraService } from "_services";

const StrRmUpdateViaUpload = ({ history, match }) => {
    const { receiptId } = match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [base64File, setBase64File] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [error, setError] = useState(null);

    const [form] = Form.useForm();
 
    const handleFormSumbit = () => {
        form
            .validateFields()
            .then((values) => {
                setIsLoading(true);

                const payload = {
                    base64FileString: base64File,
                    originalFileName: originalFileName,
                    receiptId: receiptId,
                };

                extraService
                    .uploadStockDetailsUpdate(payload)
                    .then(() => {
                        message.success("Successfully uploaded!");
                        setIsLoading(false);
                        history.push(`/packing-lists/${receiptId}/summary`);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error);
                    });
            })
            .catch((error) => {
                //message.error(`${error}`);
            });
    };

    const onCancel = () => {
        history.push("/material-details");
    };

    const onFileChange = (e) => {
        if (e.target.files[0]) {
            setOriginalFileName(e.target.files[0].name);
            let reader = new FileReader();
            reader.onload = function (e) {
                setBase64File(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const getActionButtons = () => {
        let buttons = [];
        buttons.push(<Popconfirm
            title="Are you sure to add the changes?"
            onConfirm={handleFormSumbit}
            key="add-confirm"
        >
            <Button
                key="1"
                type="primary"
                htmlType="submit"
                loading={isLoading}
            >
                Upload
            </Button>
        </Popconfirm>);

        buttons.push(<Button key="cancel" htmlType="button" onClick={onCancel}>
            Cancel
        </Button>);

        return buttons;
    }

    return (
        <div>
            <PageHeader
                title={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/dashboard">Dashboard</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/material-details">Material Details</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Details Update via Upload</Breadcrumb.Item>
                    </Breadcrumb>
                }
                extra={getActionButtons()}
            >
                <Page inner error={error}>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <Form
                            layout="vertical"
                            form={form}
                            name="form-create"
                            requiredMark={false}
                        >
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        name="file"
                                        label="Stock Details Update File"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Valid excel file is required",
                                            },
                                        ]}
                                        valuePropName="file"
                                    >
                                        <Input type="file" name="file" onChange={onFileChange} allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ paddingTop: 40 }}>
                                    <CSVLink
                                        key="sample"
                                        data={[]}
                                        filename={"Sample-StockDetails-Update.csv"}
                                        headers={[
                                            { label: "Invoice Number", key: "invoiceNumber" },
                                            { label: "Item", key: "item" },
                                            { label: "Number", key: "number" },
                                            { label: "Lot", key: "lot" },
                                            { label: "UOM", key: "uom" },
                                            { label: "Qty", key: "qty" },
                                            { label: "Net Weight (Kg)", key: "netWeight" },
                                            { label: "Gross Weight (Kg)", key: "grossWeight" },
                                            { label: "SQU", key: "squ" },
                                        ]}
                                    >
                                        Sample Upload File
                                    </CSVLink>

                                </Col>
                                <Col span={6}></Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Spin>
                </Page>
            </PageHeader>
        </div>
    );
};

export default StrRmUpdateViaUpload;
