import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  PageHeader,
  Form,
  Row,
  Col,
  Spin,
  Typography,
  Tag,
  Table,
  Divider,
  Space,
  Badge,
} from "antd";
import { ScanOutlined } from "@ant-design/icons";
import Page from "_components/Page";
import TabMenu from "./TabMenu";
import { purchaseOrderService } from "_services";

function StrRmRelease({ history, match }) {
  const { id } = match.params;
  const { path } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [error, setError] = useState(null);
  const [receiptLines, setReceiptLines] = useState([]);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [scannedBarcodeResults, setScannedBarcodeResults] = useState([]);
  const [loadingScanUpdate, setLoadingScanUpdate] = useState(false);
  const { Text } = Typography;

  useEffect(() => {
    const fetchAlldatas = async () => {
      setLoading(true);
      const purchaseOrderData = await purchaseOrderService.getById(id);

      if (purchaseOrderData) {
        let purchaseOrder = purchaseOrderData.content;
        if (["COMPLETE"].includes(purchaseOrder.orderStage)) {
          history.push(`/packing-lists/${id}/summary`);
        }

        purchaseOrder.stockPreReceiptLines.forEach((orderItem, i) => {
          orderItem.key = orderItem.id;
        });
        setOrder(purchaseOrder);
        onFill(purchaseOrder);
        setLoading(false);
      }
    };

    fetchAlldatas();
  }, []);

  const onFill = (data) => {
    form.setFieldsValue(data);
  };

  const handleScanUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingScanUpdate(true);
        const scanUpdatePayload = {
          serialNumbers: scannedBarcodes,
          releaseNote: values.note,
        };
        purchaseOrderService
          .updateReleaseItems(order.id, scanUpdatePayload)
          .then((data) => {
            message.success("Successfully released urgent items!");
            setLoadingScanUpdate(false);
            form.resetFields();
            setScannedBarcodes([]);
            setScannedBarcodeResults([
              ...scannedBarcodeResults,
              ...data.content.scanResults,
            ]);
          })
          .catch((error) => {
            setLoadingScanUpdate(false);
            setError(error);
          });
      })
      .catch((error) => {});
  };

  const onCancel = () => {
    history.push("/packing-lists");
  };

  const handleItemScan = (barcode) => {
    if (barcode) {
      if (
        scannedBarcodes.includes(barcode) ||
        scannedBarcodeResults.map((item) => item.serialNumber).includes(barcode)
      ) {
        form.setFieldsValue({ itemScan: "" });
        return message.error("Barcode is already scanned!");
      }

      setScannedBarcodes([...scannedBarcodes, barcode]);
      form.setFieldsValue({ itemScan: "" });
    } else {
      form.setFieldsValue({ itemScan: "" });
      message.error("Please enter valid barcode.");
    }
  };

  const getActionButtons = () => {
    let buttons = [];
    if (order.orderStage !== "COMPLETE") {
      buttons.push(
        <Button
          key="pdf"
          onClick={handleScanUpdate}
          loading={loadingScanUpdate}
          disabled={scannedBarcodes.length === 0}
          type="primary"
        >
          Release Urgent Items
        </Button>
      );
    }
    buttons.push(
      <Button key="btn-cancel" htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    );
    return buttons;
  };

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/packing-lists">Stock Receipt Search</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${path}`.replace(":id", id)}>
                {order.preReceiptNumber} ::{" "}
                <Tag
                  color={`${
                    order.orderStage === "OPEN"
                      ? "#2db7f5"
                      : order.orderStage === "IN_PROGRESS"
                      ? "#108ee9"
                      : "#87d068"
                  }`}
                >
                  {order.orderStage}
                </Tag>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={getActionButtons()}
      >
        <Page inner error={error}>
          <TabMenu menu="release" id={id} status={order.orderStage} />
          <Spin tip="Loading..." spinning={loading}>
            <Form
              layout="vertical"
              form={form}
              name="form-create"
              requiredMark={false}
            >
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="note"
                    label="Remarks"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter valid reason for this urgent release",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={23}>
                  <Form.Item name="itemScan">
                    <Input.Search
                      placeholder="Enter valid barcode"
                      allowClear
                      enterButton={<ScanOutlined />}
                      onSearch={handleItemScan}
                      style={{ marginBottom: 20 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Space>
                    <Badge
                      count={scannedBarcodes.length}
                      overflowCount={1000}
                      showZero
                      color="#faad14"
                    />
                  </Space>
                </Col>
              </Row>
            </Form>
            <Space size={[8, 16]} wrap>
              {scannedBarcodes.map((barcode, index) => (
                <Button key={index}>{barcode}</Button>
              ))}
            </Space>
            {scannedBarcodeResults.length > 0 && (
              <div>
                <Divider orientation="left">Scan Results</Divider>
                <Space size={[8, 16]} wrap>
                  {scannedBarcodeResults.map((item, index) => (
                    <Button
                      key={item.serialNumber}
                      style={{
                        background: item.verified ? "#87d068" : "#ff4d4f",
                      }}
                    >
                      {item.serialNumber}
                    </Button>
                  ))}
                </Space>
              </div>
            )}
          </Spin>
        </Page>
      </PageHeader>
    </div>
  );
}

export default StrRmRelease;
