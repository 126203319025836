import { fetchWrapper } from "_helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/stocksRequestLine`;

export const materialRequestLineService = {
  getById,
  update,
  reject,
  updateStatus,
  delete: _delete,
  getHistory,
  getDocuments,
};

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function reject(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}/reject`, params);
  }

function updateStatus(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}/updateStatus`, params);
}

function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function getHistory(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/history`);
}

function getDocuments(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/documents`);
}
