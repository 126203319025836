import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Alert, message, Spin } from "antd";
import { Auth } from "_helpers";
import { loginservice, userService } from "_services";
import Footer from "_components/Footer";
import logo from "logo.svg";
import "./style.css";

function Login({ match }) {
  const [loginErrorVisible, setLoginErrorVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onFinish = (values) => {
    if (values.email && values.password) {
      setLoading(true);
      loginservice
        .login(values.email, values.password)
        .then((data) => {
          if (data) {
            Auth.setSession(data);
            userService
              .getById(Auth.getProfile().user_id)
              .then((data) => {
                Auth.setTitle(data.content.firstName, data.content.lastName);

                setLoading(false);
                history.push("/");
              })
              .catch((error) => {
                setLoginErrorVisible(true);
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          setLoginErrorVisible(true);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="LoginForm">
        <Spin tip="Loading..." spinning={loading}>
          <div className="LoginForm-logo">
            <img alt="logo" src={logo} />
            <span>Fingertips Admin</span>
          </div>
          {loginErrorVisible && (
            <Alert
              message="Bad credentials"
              type="error"
              showIcon
              style={{ marginBottom: 10 }}
            />
          )}
          <Form onFinish={onFinish}>
            <Form.Item label="" name="email" rules={[{ required: true }]}>
              <Input onPressEnter={onFinish} placeholder="Email address" />
            </Form.Item>
            <Form.Item label="" name="password" rules={[{ required: true }]}>
              <Input
                type="password"
                onPressEnter={onFinish}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit">
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
}

export default Login;
